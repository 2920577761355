<template>
  <section>
    <div v-if="Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <organization-header
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organizationHeaderInfo"
        :organization-type="organizationType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
        @set-organization-following="
          organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
        "
      />
    </div>
    <div v-if="Object.keys(organization).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <organization-overview
        :organization-type="organizationType"
        :model="model"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organization"
      />
      <organization-video
        v-if="showVideoCard"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organization"
      />
      <organization-governance
        v-if="organization.governance_info"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organization"
      />
      <model-attachments
        v-if="organization.attachments.length > 0"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :attachments="organization.attachments"
        :total-attachments="organization.attachments_count"
      />
      <organization-partners
        v-if="organization.partners.length > 0"
        :model-id="modelId"
        :model-pluralize="modelPluralize"
        :is-admin="isAdmin"
        :organization="organization"
      />
    </div>
  </section>
</template>

<script>
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ModelAttachments from '../../../../components/shared/ModelAttachments.vue';
import OrganizationGovernance from './OrganizationGovernance.vue';
import OrganizationHeader from '../../sharedComponents/OrganizationHeader.vue';
import OrganizationOverview from './OrganizationOverview.vue';
import OrganizationPartners from './OrganizationPartners.vue';
import OrganizationVideo from './OrganizationVideo.vue';
import { fallbackApi } from '../../../../api/config';
import { modelMixin } from '../../../../mixins';

export default {
  components: {
    ModelAttachments,
    OrganizationGovernance,
    OrganizationHeader,
    OrganizationOverview,
    OrganizationPartners,
    OrganizationVideo,
    LoadingIndicator
  },
  mixins: [modelMixin],
  data() {
    return {
      model: '',
      modelPluralize: '',
      modelId: '',
      organization: {}
    };
  },
  computed: {
    showVideoCard() {
      return (
        this.organization?.about_video || this.organization?.about_video_url || (
          this.organization?.social_media_links &&
          Object.values(this.organization?.social_media_links).some((link) => link !== '')
        )
      );
    }
  },
  mounted() {
    this.fetchOrganizationInfo();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#model-about');
      this.getModelDetails(modelInfo);
    },
    fetchOrganizationInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/about`,
        dataSetter: ({ organization }) => this.organization = organization
      });
    }
  }
};
</script>
