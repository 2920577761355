import SearchContainer from './components/SearchContainer.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-search')) {
    const app = createApp(SearchContainer);
    app.use(store);
    app.mount('#js-search');
  }
});

