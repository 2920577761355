<template>
  <transition name="fade">
    <div
      v-if="show"
      class="toast"
      @mouseenter="clearDismissal"
      @mouseleave="setDismissal"
    >
      <div class="toast-body">
        <i
          class="grayscaleOffBlack"
          :class="`fas fa-${variant === 'success' ? 'check' : 'times'}-circle`"
          aria-hidden="true"
        />
        <span class="toast-message MobileLinkSmall grayscaleOffBlack"> {{ message }} </span>
        <button type="button" class="close" @click="hide">&times;</button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'success'
    },
    autohide: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number,
      default: 5000
    }
  },
  emits: ['dismiss'],
  data() {
    return {
      show: true,
      dismissal: undefined
    };
  },
  mounted() {
    this.setDismissal();
  },
  methods: {
    setDismissal() {
      if (this.autohide) {
        this.dismissal = setTimeout(() => {
        this.hide();
        }, this.delay);
      }
    },
    clearDismissal() {
      if (this.autohide)
        clearTimeout(this.dismissal);
    },
    hide() {
      this.show = false;
      setTimeout(() => {
        this.$emit('dismiss');
      }, 300);
    }
  }
};
</script>

<style scoped>
.toast {
  background-color: white;
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.toast:hover {
  border: 1px solid;
}

.toast-message {
  margin-left: 15px;
  margin-right: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
