class SupportHelper {
  static setSupportPopupListeners() {
    $('.open-form-popup').click(function(){
      $('.form-popup').css('display', 'block');
    });

    $('.close-form-popup').click(function(){
      $('.form-popup').css('display', 'none');
    });

    $('.submit-support-form').click(function(e){
      e.preventDefault();
      var $valid = $('.helpy-form').valid();
      if (!$valid) {
        $validator.focusInvalid();
        return false;
      }
      // Submit form
      arkUI.loadingIndicator.show();
      const form = document.querySelector('.helpy-form');
      const formURL = form.action;
      const formData = new FormData(form);
      fetch(formURL, {
        method: form.method,
        body: formData
      }).then((response) => {
        $('.support-form-input').val('');
        let notice_html = 'Thank you for reaching out! Someone will get back to you soon.';
        arkUI.loadingIndicator.hide();
        if ($('.form-popup').length > 0) {
          $('.form-popup').css('display', 'none');
          arkUI.alert.show({
            html: notice_html,
          }); 
        } else {
          arkUI.alert.show({
            html: notice_html,
            redirect: true,
            redirect_location: '/',
            buttonText: 'Create another ticket'
          });
        }
      }).catch((error) => {
        arkUI.loadingIndicator.hide();
        Sentry && Sentry.captureException(error);
        let error_html = 'An error occurred while trying to contact support.<br >Please, try again or email us at support@theark.co';
        arkUI.loadingIndicator.hide();
        arkUI.alert.show({
          html: error_html
        });
      })
    });
  }

}

SupportHelper.setSupportPopupListeners();
