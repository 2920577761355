<template>
  <avl-modal
    :is-open="modalOpen"
    size="900px"
    header-title=""
    footer
    @avleventclose="closeModal"
  >
    <section class="DesktopTextSmall grayscaleOffBlack text-left mt-3">
      Select an area file to upload. The following file types are allowed:
      <ul>
        <li>
          <strong class="DesktopLinkSmall">Shapefile</strong>
          (.zip) containing the main geometry files (.shp, .shx, .dbf, .prj)
          and optionally any other geometry files (.sbn, .sbx, .fbn, .fbx, .xml).
        </li>
        <li>
          <strong class="DesktopLinkSmall">GeoJSON</strong> file (.json or .geojson).
        </li>
      </ul>
      The file should represent a single layer, containing one or more geographic features.
      <input
        ref="fileInput"
        type="file"
        hidden
        @input="selectFile"
      >
    </section>
    <footer slot="footer" class="pr-3">
      <div
        v-if="error"
        class="row d-flex justify-content-center mt-2"
      >
        <div class="col-12 text-right">
          <error-message
            :error-condition="error"
            :error-message="error"
          />
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-1">
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn primaryButton DesktopLinkXSmall modalButton"
            @click="openFileExplorer"
          >
            Select file
          </button>
        </div>
      </div>
    </footer>
  </avl-modal>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
  name: 'UploadFileModal',
  components: {
    ErrorMessage
  },
  props: {
    modalOpen: {
      type: Boolean,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  emits: ['modalClosedEvent'],
  data() {
    return {
      error: '',
      allowedMimeTypes: [
        'application/zip',
        'application/x-zip-compressed',
        'application/json',
        'application/geo+json',
        'application/geo+json-seq'
      ],
      allowedExtensions: ['zip', 'json', 'geojson']
    };
  },
  methods: {
    closeModal(file) {
      this.$emit('modalClosedEvent', file);
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    selectFile() {
      const file = this.$refs.fileInput.files[0];
      const extension = file?.name?.split('.')?.pop();
      if (
        !this.allowedMimeTypes.includes(file.type) &&
        !this.allowedExtensions.includes(extension)
      ) {
        this.error = 'This file type is not allowed.';
        return;
      }

      if (file.size > 157286400) {
        this.error = 'Maximum allowed size is 150MB.';
        return;
      }

      this.closeModal(file);
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>
<style scoped>
avl-modal {
  --modal-box-shadow-header: 0px;
  --modal-box-shadow-footer: 0px;
}

.modalButton {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
</style>

