<template>
  <div class="apply-form">
    <header class="row justify-content-center mt-3">
      <div class="col-12 text-center">
        <h1 class="DesktopDisplaySmall pt-5">Help minimise threats to biodiversity</h1>
      </div>
    </header>
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <form class="arkCard text-center py-5 mb-2">
          <div class="text-left">
            <label
              for="firstNameInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              First Name *
            </label>
            <input
              id="firstNameInput"
              v-model="form.first_name"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="firstNameError"
              :aria-invalid="!!errors.first_name"
              :class="{ notValid: errors.first_name }"
              @blur="validate"
            >
            <error-message
              id="firstNameError"
              :error-condition="!!errors.first_name"
              :error-message="errors.first_name"
            />
          </div>
          <div class="text-left mt-4">
            <label
              for="lastNameInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Last Name *
            </label>
            <input
              id="lastNameInput"
              v-model="form.last_name"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="lastNameError"
              :aria-invalid="!!errors.last_name"
              :class="{ notValid: errors.last_name }"
              @blur="validate"
            >
            <error-message
              id="lastNameError"
              :error-condition="!!errors.last_name"
              :error-message="errors.last_name"
            />
          </div>
          <button
            type="submit"
            class="
              btn
              DesktopLinkXSmall
              primaryButton
              w-100
              px-5
              mt-4
            "
            :disabled="!form.first_name || !form.last_name"
            @click.prevent="submit"
          >
            Continue
          </button>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  first_name: yup.string().required('First name is a required field.'),
  last_name: yup.string().required('Last name is a required field.')
});

export default {
  components: { ErrorMessage },
  emits: ['submit'],
  data() {
    return {
      showErrors: false,
      form: {
        first_name: '',
        last_name: ''
      },
      errors: {
        first_name: '',
        last_name: ''
      }
    };
  },
  methods: {
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.$emit('submit', this.form);
      }
    }
  }
};

</script>

<style scoped>
.apply-form {
  padding-top: 75px;
}

.arkCard {
  padding-left: 15%;
  padding-right: 15%;
}
</style>
