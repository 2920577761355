import ResetPassword from './components/ResetPassword.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#reset-password')){
    const app = createApp(ResetPassword);
    app.use(store);
    app.mount('#reset-password');
  }
});
