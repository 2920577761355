<template>
  <video
    class="video"
    ref="videoRef"
    autoplay
    muted
  >
    <source :src="sectionVideoURL" type="video/mp4" />
    <!-- Add other video formats as needed, e.g., WebM and Ogg -->
    <!-- <source :src="webmSource" type="video/webm" />
    <source :src="oggSource" type="video/ogg" /> -->
    Your browser does not support the video tag.
  </video>
</template>

<script>

export default {
  props: {
    sectionVideoURL: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVideoInView: false,
    };
  },
  mounted() {
    // Initialize the Intersection Observer when the component is mounted
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25, // Adjust this threshold as needed
    };
    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.$refs.videoRef);
  },
  methods: {
    handleIntersection(entries) {
      const entry = entries[0];
      // If the video enters the viewport, start playing
      // If it exits the viewport, pause the video
      if (entry.isIntersecting) {
        this.$refs.videoRef.play();
      } else {
        this.$refs.videoRef.pause();
      }
    },
  },
  beforeDestroy() {
    // Clean up the Intersection Observer when the component is destroyed
    if (this.observer) {
      this.observer.unobserve(this.$refs.videoRef);
      this.observer = null;
    }
  },
};
</script>

<style scoped>
.video {
  width: 90%;
  object-fit: cover;
  border-radius: 16px;
  border: 1.51355px solid #efefef;
}
</style>