const actions = {
  addToast(context, toast) {
    context.commit('ADD_TOAST', toast);
  },
  removeToast(context, id) {
    context.commit('REMOVE_TOAST', id);
  }
};

export default actions;
