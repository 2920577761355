
<template>
  <div :class="{ greyed: !modelValue && readonly }">
    <button
      v-if="!readonly"
      class="btn-rounded"
      :disabled="modelValue - step < min"
      aria-label="subtract an item"
      @click="subtract"
    >
      <i
        class="fa fas fa-minus"
        aria-hidden="true"
      />
    </button>
    <input
      id="numberField"
      :key="reloadCounter"
      ref="input"
      class="textField grayscaleOffBlack"
      type="number"
      name="number"
      aria-label="number of items"
      hide-details
      :step="step"
      :value="modelValue"
      :min="min"
      :max="max"
      :disabled="disabled"
      @input="validationHandler($event.target.value)"
      @click="$refs.input.select()"
    >

    <button
      v-if="!readonly"
      class="btn-rounded"
      :disabled="modelValue + step > max"
      aria-label="add an item"
      @click="add"
    >
      <i
        class="fa fas fa-plus"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'StepperInput',
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 99
    },
    size: {
      type: Number,
      default: 35
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      /*
        Use this value to reload input element
        and force reactivity on edge cases ( i.e erase input ).
      */
      reloadCounter: 0
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: 'validationHandler'
    }
  },
  methods: {
    validationHandler(newValue) {
      // Ensure the input is a valid number
      if (this.isNumeric(newValue)) {
        // Count decimal part digits
        const decimalPart = this.step.toString().split('.');
        const digits = decimalPart.length === 2 && decimalPart[1].length || 0;
        // Convert the valid number to a JavaScript Number type and round up
        const numericValue = this.roundUpToDecimalPlaces(Number(newValue), digits);
        // Check if the numeric value is within the specified range
        if (numericValue >= this.min && numericValue <= this.max) {
          this.$emit('update:modelValue', numericValue);
          return;
        } else if (numericValue < this.min) {
          this.$emit('update:modelValue', this.min);
        } else {
          this.$emit('update:modelValue', this.max);
        }
      } else {
        // If the input is not a valid number
        this.$emit('update:modelValue', this.min);
      }
      this.reloadCounter ++;
    },
    add() {
      if (this.modelValue + this.step <= this.max) {
        let result = this.modelValue + this.step;
        this.validationHandler(result);
      }
    },
    subtract() {
      if (this.modelValue - this.step >= this.min) {
        let result = this.modelValue - this.step;
        this.validationHandler(result);
      }
    },
    isNumeric(str) {
      return !isNaN(str) && !isNaN(parseFloat(str));
    },
    roundUpToDecimalPlaces(number, decimalPlaces) {
      const multiplier = Math.pow(10, decimalPlaces);
      const roundedNumber = Math.ceil(number * multiplier) / multiplier;
      return roundedNumber;
    }
  }
};
</script>

<style scoped>
.btn-rounded {
  background: transparent;
  border-radius: 9999px;
  height: v-bind(size);
  width: v-bind(size);
  cursor: pointer;
}
.textField {
  min-height: 1rem;
  width: 2.5rem;
  border-radius: 9999px;
  text-align: center;
  outline: none;
  border: none;
}
.greyed {
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
.textField::-webkit-outer-spin-button,
.textField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.textField[type="number"] {
  -moz-appearance: textfield !important;
  appearance: textfield; /* Remove default styling on other browsers */
  -webkit-appearance: none; /* Remove default styling on Safari */
}
</style>
