<template>
  <div v-if="isInitialLoading" class="mt-4 text-center">
    <div class="spinner-border" />
  </div>
  <div v-else-if="error" class="mt-4 ark-p7 text-center">
    Something went wrong...
  </div>
  <div class="h-100" v-else>
    <div :class="{ 'scroll-container': controlButtons === 'bottom' }">
      <div v-if="controlButtons === 'top'" class="container">
        <div class="row">
          <div class="col-7 ark-p11">
            <button
              type="button"
              class="btn filter-button"
              @click="closeFilterBar"
            >
              <i class="fa fa-arrow-left" aria-hidden="true" />
            </button>
          </div>
          <div class="col-2 mt-1 justify-content-center">
            <button
              class="btn btn-link m-0 p-0 textTransformNone"
              :class="getDisabledButtonClass(
                isSecondaryLoading ||
                  !Object.keys(checkedItems).length)
              "
              @click="clearCheckboxes"
            >
              Clear
            </button>
          </div>
          <div class="col-2 mt-1 ml-3 justify-content-start">
            <button
              class="btn btn-link m-0 p-0 textTransformNone"
              :class="getDisabledButtonClass(isSecondaryLoading)"
              @click="closeFilterBar"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <filter-sublist
        title="Legal entity type"
        type="legal_type_ids"
        :checked-items="[]"
        :data="legalTypeData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
      <filter-sublist
        title="Asset class"
        type="asset_class_ids"
        :checked-items="[]"
        :data="assetClassData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
      <filter-sublist
        title="Species"
        type="species_ids"
        :checked-items="[]"
        :data="speciesData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
      <filter-sublist
        title="Impact Category"
        type="iris_category_ids"
        :checked-items="[]"
        :data="irisData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
      <filter-sublist
        title="SDGs"
        type="sdg_goal_codes"
        :checked-items="[]"
        :data="sdgData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
      <filter-sublist
        title="IUCN Threat"
        type="iucn_threat_ids"
        :checked-items="[]"
        :data="threatData"
        :disabled="isSecondaryLoading"
        :clear-filters="clearButtonPressed"
        @checkbox-click="checkboxClick"
      />
    </div>
    <div v-if="controlButtons === 'bottom'">
      <div class="container text-right px-3 pt-2">
        <button
          class="btn btn-link m-0 p-0 textTransformNone"
          :class="getDisabledButtonClass(
            isSecondaryLoading ||
              !Object.keys(checkedItems).length)
          "
          @click="clearCheckboxes"
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSublist from './FilterSublist.vue';
import { fallbackApi } from '../../../api/config';
import { mapCardThemes } from '../../../domain/enumerationHelper';
import { symbolToString } from '../../../utilities/enumeration';

export default {
  name: 'FilterSidebarContent',
  components: { FilterSublist },
  props: {
    applyFiltering: {
      type: Boolean,
      required: true
    },
    entityType: {
      type: Symbol,
      required: true,
      default: mapCardThemes.organization
    },
    investmentReadyFilter: {
      type: Boolean,
      required: true
    },
    organizationTypeFilter: {
      type: String,
      required: false,
      default() {
        return null;
      }
    },
    initialIds: {
      type: Array,
      required: true
    },
    controlButtons: {
      type: String,
      required: false,
      default() {
        return 'top';
      }
    }
  },
  emits: ['close', 'results'],
  data() {
    return {
      isInitialLoading: true,
      isSecondaryLoading: false,
      error: undefined,
      clearButtonPressed: false,
      initialFetchedIDs: [],
      currentFetchedIDs: [],
      legalTypeData: [],
      assetClassData: [],
      speciesData: [],
      irisData: [],
      sdgData: [],
      threatData: [],
      checkedItems: {}
    };
  },
  watch: {
    applyFiltering() {
      this.fetchData();
    },
    investmentReadyFilter() {
      this.fetchData();
    },
    organizationTypeFilter() {
      this.fetchData();
    }
  },
  methods: {
    getDisabledButtonClass(disabledCondition) {
      return `
        DesktopLinkXSmall
        primaryDefault
        ${disabledCondition? 'grayscalePlaceholder disabled' : 'pointer'}
      `;
    },
    getFirstValue: (inputObject) => inputObject[Object.keys(inputObject)[0]],
    getCheckedIDs() {
      const checkedIDs = {};
      Object.entries(this.checkedItems).forEach((entry) => {
        const [key, value] = entry;
        checkedIDs[key] = value.map((item) =>
          item[key === 'sdg_goal_codes' ? 'sdg_goal_code' : 'id']
        );
      });
      return checkedIDs;
    },
    fetchData() {
      if (!this.applyFiltering) return;

      const filters = this.getCheckedIDs();

      if (this.investmentReadyFilter) {
        filters.investment_ready = this.investmentReadyFilter;
      }
      filters.organization_type = this.organizationTypeFilter;

      const payload = {
        json: {
          filters: {
            ...filters,
            [`${symbolToString(this.entityType)}_ids`]:
              this.initialIds.map((obj) => obj.id)
          }
        }
      };

      const dataSetter = (data) => {
        this.legalTypeData = data.legal_types || [];
        this.assetClassData = data.asset_classes || [];
        this.speciesData = data.species || [];
        this.irisData = data.iris_categories || [];
        this.sdgData = data.sdg_goals || [];
        this.threatData = data.iucn_threats || [];
        const fetchedIDs = data.organizations || data.projects || data.tech_orgs || [];
        const checkboxesSelected = Object.keys(this.checkedItems).length > 0;
        this.$emit('results', fetchedIDs, checkboxesSelected);
      };

      const loadingSetter = (status) => {
        if (status) {
          this.isSecondaryLoading = true;
        } else {
          this.isInitialLoading = false;
          this.isSecondaryLoading = false;
        }
      };

      fallbackApi({
        url: `${symbolToString(this.entityType)}s/map_filter`,
        payload,
        httpVerb: 'post',
        dataSetter,
        loadingSetter,
        errorSetter: (error) => this.error = error
      });
    },
    checkboxClick(item, type, buttonGotChecked) {
      const key = type === 'sdg_goal_codes' ? 'sdg_goal_code' : 'id';

      if (!this.checkedItems[type]) this.checkedItems[type] = [];

      if (buttonGotChecked) {
        this.checkedItems[type] = [...this.checkedItems[type], item];
      } else {
        this.checkedItems[type] = this.checkedItems[type].filter(
          (element) => {
            return element[key] !== item[key];
          }
        );

        if (!this.checkedItems[type].length) {
          delete this.checkedItems[type];
        }
      }

      this.fetchData();
    },
    clearCheckboxes() {
      this.clearButtonPressed = !this.clearButtonPressed;
      this.checkedItems = {};
      this.fetchData();
    },
    closeFilterBar() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.filter-button {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  color: #24422b;
  font-weight: 800;
}

.scroll-container {
  height: calc(100% - 24px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
