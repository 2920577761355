<template>
  <section class="arkCard">
    <header class="row mb-2">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">Overview</h2>
      </section>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <a
          class="p-0 m-0 pointer"
          aria-label="Edit overview section"
          :href="`/${modelPluralize}/${modelId}/edit?tab=info`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <section class="row py-2">
      <article class="col-12">
        <p class="DesktopTextSmall grayscaleBody m-0">
          {{ organization?.about }}
        </p>
      </article>
    </section>
    <aside
      v-if="organization?.full_url"
      class="row"
      :aria-label="organization?.name + ' website URL'"
    >
      <div class="col-12">
        <h3 class="DesktopLinkSmall secondary7_ark">Website</h3>
      </div>
      <div class="col-12">
        <a
          class="DesktopTextSmall warningDefault pointer"
          :href="organization?.full_url"
          target="_blank"
        >
          {{ organization?.full_url }}
        </a>
      </div>
    </aside>
    <aside class="row" aria-label="Organization's additional info">
      <div class="col-12">
        <h3 class="DesktopLinkSmall secondary7_ark">Organisation type</h3>
      </div>
      <div class="col-12">
        <span class="DesktopTextSmall grayscaleBody">
          {{ organizationType }}
        </span>
      </div>
    </aside>
  </section>
</template>

<script>
export default {
  props: {
    model: {
      type: String,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    organizationType: {
      type: String,
      required: true
    }
  }
};
</script>
