<template>
  <avl-modal
    :is-open="deleteAssociationModalOpen"
    size="fit-content"
    header-title=""
    @avleventclose="closeDeleteModal"
  >
    <div v-if="metricInfo?.title" class="DesktopTextMedium p-2">
      {{ `Remove metric: ${metricInfo.title}?` }}
    </div>
    <div class="DesktopTextSmall grayscaleBody mt-2 p-3">
      Are you sure you want to remove this metric from the "{{
        metricInfo.goalName
      }}" goal?
    </div>
    <div
      v-if="errorMessageFromBackend"
      class="row d-flex justify-content-center mt-2"
    >
      <div class="col-12 text-right">
        <error-message
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-3">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-link grayscaleBody ark-bold"
          @click="closeDeleteModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link secondary7_ark ark-bold"
          @click="deleteAssociation"
        >
          Remove
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>
/* global Sentry */

import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import api from '../../../api/config';

export default {
  name: 'DeleteAssociationModal',
  components: { ErrorMessage },
  props: {
    deleteAssociationModalOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    metricInfo: {
      type: Object,
      required: true,
      default: null
    }
  },
  emits: ['deleteAssociationModalClosedEvent', 'entryDataChanged', 'onDelete'],
  data() {
    return {
      errorMessageFromBackend: '',
      deletedId: null
    };
  },
  methods: {
    deleteAssociation() {
      this.onDelete();

      const data =
        this.metricInfo.irisOrCustom === 'iris_metrics'
          ? { iris_metric_id: this.metricInfo.id }
          : { ark_metric_def_id: this.metricInfo.id };

      api
        .delete(`goals/${this.metricInfo.goalId}/delete_goal_metric`, {
          json: data
        })
        .then(() => {
          this.errorMessageFromBackend = '';
          this.deletedId = { ...this.metricInfo };
          this.entryDataChanged('Delete Metric');
        })
        .catch((error) => {
          Sentry && Sentry.captureException(error);

          error.response.json().then((body) => {
            this.errorMessageFromBackend = body.error;
          });
          console.warn(error);
          this.deletedId = null;
        })
        .finally(() => this.closeDeleteModal());
    },
    closeDeleteModal() {
      this.$emit(
        'deleteAssociationModalClosedEvent',
        this.deletedId,
        this.metricInfo.irisOrCustom
      );
    },
    entryDataChanged(reason) {
      this.$emit('entryDataChanged', reason);
    },
    onDelete() {
      this.$emit('onDelete');
    }
  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
