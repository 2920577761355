class SearchHelper {
  static primary1_ark = '#C5BD74';
  static primary2_ark = '#172722';
  static primary3_ark = '#A6CFD5';
  static primary4_ark = '#5E768A';

  static renderSection(button) {
    button.addEventListener('click', () => {
      let moreItems = button.parentElement.querySelector('.more-items');
      let navbarLink = document.querySelector(button.getAttribute('data-nav'));
      if (moreItems.classList.contains('d-none')) {
        button.innerHTML= 'See Less';
      } else {
        button.innerHTML= 'See All';
      }
      moreItems.classList.toggle('d-none');
      if (navbarLink) {
        navbarLink.classList.toggle('active');
      }
      return false;
    })
  }

  static renderMapCountriesOfSpecies(mapboxClient, map, species) {
    species.country_occurrence.forEach((co, i) => {
      mapboxClient.geocoding
      .forwardGeocode({
        query: co.country,
        types: ['country'],
        limit: 1
      })
      .send()
      .then((response) => {
        if(
          response &&
          response.body &&
          response.body.features &&
          response.body.features.length
        ) {
          var feature = response.body.features[0];

          // Center map to first point
          if(i === 0) {
            map.easeTo({ center: feature.center });
          }

          // Create popup
          var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setText(co.country);

          // Create marker
          var marker = new mapboxgl.Marker({ color: this.primary1_ark }).setLngLat(feature.center).setPopup(popup);
          marker.getElement().style.cursor = 'pointer';
          marker.addTo(map);
        }
      });
    });
  }

  static renderMapAllSpecies(mapboxClient, map, countriesSpecies) {
    let allCountries = Object.values(countriesSpecies).map(elm => { return elm.code })
    map.setFilter(
      'countries',
      ['in', 'ADM0_A3_IS'].concat(allCountries),
    ); // This line lets us filter by country codes.
    map.on('click', 'countries', function(mapElement) {
      // Do not show country popup if click was on a marker
      if(mapElement.originalEvent.target.tagName !== 'CANVAS') {
        return;
      }
      const countryCode = mapElement.features[0].properties.ADM0_A3_IS; // Grab the country code from the map properties.
      // Create popup
      let country_data = Object.values(countriesSpecies).filter(data => data.code === countryCode)[0];
      var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false })
        .setLngLat(mapElement.lngLat)
        .setHTML('<strong>' + country_data.country + '</strong><br>' + country_data.species.join('<br>'))
        .addTo(map);
    });
  };

  static renderMapOrganizations(mapboxClient, map, organizations) {
    organizations.forEach(({link, location_url, address}) => {

      // Regex: get '@lat,long' part of url
      var coords = location_url.match(/@-?(0|[1-9]\d*)(\.\d+)?,-?(0|[1-9]\d*)(\.\d+)?/);

      // If coords can be extracted from url use them, else use address
      if(coords && coords[0][0] === '@') {
        coords = coords[0].substring(1).split(',').reverse();
        // Create popup
        var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);
        // Create marker
        var marker = new mapboxgl.Marker({ color: this.primary1_ark }).setLngLat(coords).setPopup(popup);
        marker.getElement().style.cursor = 'pointer';
        marker.addTo(map);
      }
      else {
        mapboxClient.geocoding
        .forwardGeocode({
          query: address,
          autocomplete: true,
          limit: 1
        })
        .send()
        .then((response) => {
          if(
            response &&
            response.body &&
            response.body.features &&
            response.body.features.length
          ) {
            var feature = response.body.features[0];

            // Create popup
            var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);

            // Create marker
            var marker = new mapboxgl.Marker({ color: this.primary1_ark }).setLngLat(feature.center).setPopup(popup);
            marker.getElement().style.cursor = 'pointer';
            marker.addTo(map);
          }
        });
      }

    });
  }

  static renderMapTechOrgs(mapboxClient, map, organizations) {
    organizations.forEach(({link, location_url, address}) => {

      // Regex: get '@lat,long' part of url
      var coords = location_url.match(/@-?(0|[1-9]\d*)(\.\d+)?,-?(0|[1-9]\d*)(\.\d+)?/);

      // If coords can be extracted from url use them, else use address
      if(coords && coords[0][0] === '@') {
        coords = coords[0].substring(1).split(',').reverse();
        // Create popup
        var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);
        // Create marker
        var marker = new mapboxgl.Marker({ color: this.primary4_ark }).setLngLat(coords).setPopup(popup);
        marker.getElement().style.cursor = 'pointer';
        marker.addTo(map);
      }
      else {
        mapboxClient.geocoding
        .forwardGeocode({
          query: address,
          autocomplete: true,
          limit: 1
        })
        .send()
        .then((response) => {
          if(
            response &&
            response.body &&
            response.body.features &&
            response.body.features.length
          ) {
            var feature = response.body.features[0];

            // Create popup
            var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);

            // Create marker
            var marker = new mapboxgl.Marker({ color: this.primary4_ark }).setLngLat(feature.center).setPopup(popup);
            marker.getElement().style.cursor = 'pointer';
            marker.addTo(map);
          }
        });
      }

    });
  }


  static renderMapProjects(mapboxClient, map, projects) {
    projects.forEach(({link, location_url, address}) => {

      // Regex: get '@lat,long' part of url
      var coords = location_url.match(/@-?(0|[1-9]\d*)(\.\d+)?,-?(0|[1-9]\d*)(\.\d+)?/);

      // If coords can be extracted from url use them, else use address
      if(coords && coords[0][0] === '@') {
        coords = coords[0].substring(1).split(',').reverse();
        // Create popup
        var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);
        // Create marker
        var marker = new mapboxgl.Marker({ color: this.primary3_ark }).setLngLat(coords).setPopup(popup);
        marker.getElement().style.cursor = 'pointer';
        marker.addTo(map);
      }
      else {
        mapboxClient.geocoding
        .forwardGeocode({
          query: address,
          autocomplete: true,
          limit: 1
        })
        .send()
        .then((response) => {
          if(
            response &&
            response.body &&
            response.body.features &&
            response.body.features.length
          ) {
            var feature = response.body.features[0];

            // Create popup
            var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setHTML(link);

            // Create marker
            var marker = new mapboxgl.Marker({ color: this.primary3_ark }).setLngLat(feature.center).setPopup(popup);
            marker.getElement().style.cursor = 'pointer';
            marker.addTo(map);
          }
        });
      }
    });
  }

  static renderSearchMap(searchMapContainer) {
    // Initialize client for geocoder
    let mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
    let _self = this;
    let map = new mapboxgl.Map({
      container: searchMapContainer.id,
      style: 'mapbox://styles/mapbox/light-v10',
      zoom: 1,
    });
    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());
    map.on('load', () => {

      map.addLayer({
        //here we are adding a layer containing the tileset we just uploaded
        id: 'countries', //this is the name of our layer, which we will need later
        source: {
          type: 'vector',
          url: 'mapbox://avlos.7gfpdi3p', // <--- Add the Map ID you copied here
        },
        'source-layer': 'ne_10m_admin_0_countries-828sbj', // <--- Add the source layer name you copied here
        type: 'fill',
        paint: {
          'fill-color': '#24422B', //this is the color you want your tileset to have
          'fill-outline-color': '#F2F2F2', //this helps us distinguish individual countries a bit better by giving them an outline
        },
      }, 'admin-0-boundary-bg');
      const species = JSON.parse(searchMapContainer.getAttribute('data-species'));
      if (species) {
        // We are in the species page
      } else {
        // Add legend
        const types = ['Organisations', 'Projects', 'Technologies'];
        const colors = [this.primary1_ark, this.primary3_ark, this.primary4_ark];
        for (let i = 0; i < types.length; i++) {
          var item = document.createElement('div');
          var key = document.createElement('span');
          key.className = 'legend-key';
          key.style.backgroundColor = colors[i];

          var value = document.createElement('span');
          value.innerHTML = types[i];
          item.appendChild(key);
          item.appendChild(value);
          legend.appendChild(item);
        }
      }

      // Add species, organization, and project markers
      const countriesSpecies = JSON.parse(searchMapContainer.getAttribute('data-countries-species'));
      if(countriesSpecies) {
        _self.renderMapAllSpecies(mapboxClient, map, countriesSpecies);
      }
      const organizations = JSON.parse(searchMapContainer.getAttribute('data-organizations'));
      if(organizations) {
        _self.renderMapOrganizations(mapboxClient, map, organizations);
      }
      const projects = JSON.parse(searchMapContainer.getAttribute('data-projects'));
      if(projects) {
        _self.renderMapProjects(mapboxClient, map, projects);
      }
      const tech_orgs = JSON.parse(searchMapContainer.getAttribute('data-tech-orgs'));
      if(tech_orgs) {
        _self.renderMapTechOrgs(mapboxClient, map, tech_orgs);
      }
    })
  }
}


let seeAllButtons = document.querySelectorAll('.js-search-see-all');
seeAllButtons.forEach(button => SearchHelper.renderSection(button));

let searchMapDiv = document.getElementById('search-map');
if (searchMapDiv) {
  SearchHelper.renderSearchMap(searchMapDiv);
}
