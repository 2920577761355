class AnalyticsHelper {
  static renderUploadsChart(uploads, htmlId, title) {
    var dataUploads = JSON.parse(uploads);
    var chartLabels = Object.keys(dataUploads).map(x => x.split(' ')[0]);
    var chartScoreValues = [];
    for (let upload in dataUploads) {
      chartScoreValues.push(dataUploads[upload]);
    }

    Highcharts.chart(htmlId, {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: `${title} uploads`
      },
      xAxis: {
        categories: chartLabels,
        title: {
          text: null
        }  
      },
      yAxis: {
        labels: {
          overflow: 'justify'
        },
        title: {
          text: null
        }
      },
      tooltip: {},
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          },
          colorByPoint: false
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        name: 'Count',
        data: chartScoreValues
      },
      ]
    });
  }

}

let htmlMediaUploadsIds = {
  book: 'book_uploads_chart',
  film: 'film_uploads_chart',
  podcast: 'podcast_uploads_chart',
  paper: 'paper_uploads_chart',
  image: 'image_uploads_chart',
}

for (const key in htmlMediaUploadsIds) {
  let htmlId = htmlMediaUploadsIds[key];
  let htmlUploads = document.getElementById(htmlId);
  let datasetUploads = htmlUploads && htmlUploads.dataset.uploads;
  if (datasetUploads) {
    AnalyticsHelper.renderUploadsChart(datasetUploads, htmlId, key);
  }
}
