<template>
  <search-tabs
    tab="projects"
    :investment-ready="investmentReady"
    :close-map-button="showMap"
    @tab-changed="tabChanged"
    @all-filters="toggleFilters"
    @investment-ready="toggleInvestmentReady"
    @close-map="closeMap"
  />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-3">
        <map-side-bar
          class="filterSidebar"
          :open-side-bar="sidebarOpen"
          :visible-side-bar-button="showMap"
          :open-second-side-bar="false"
          :back-button-on-second-sidebar="false"
          :exit-button-on-first-sidebar="showMap"
          @side-bar-status="sideBarStatus"
          @bottom-of-container="loadMore"
        >
          <template #insideOfSideBar>
            <section v-show="!showMap" class="mapButtonSection">
              <div class="mapButtonContainer px-3">
                <map-button :redirect="false" @go-to-map="goToMap" />
              </div>
            </section>
            <section v-show="filtersOpen" :class="showMap ? 'h-100' : 'filtersSection'">
              <filter-sidebar-content
                :apply-filtering="!loading"
                :entity-type="mapCardThemes.project"
                :initial-ids="allResults"
                :investment-ready-filter="investmentReady"
                control-buttons="bottom"
                @results="fetchFilterEntities"
                @close="filtersOpen = false"
              />
            </section>
            <section v-show="showMap && !filtersOpen">
              <div v-if="paginatedData?.length > 0">
                <div v-for="item in paginatedData" :key="item.id">
                  <a
                    :href="`/projects/${item.id}`"
                    target="_blank"
                    class="row d-flex justify-content-center"
                  >
                    <map-sidebar-card
                      :image-src="item.last_updated_image || item.default_banner"
                      :title="item.title"
                      :goals="item.goals_count"
                      :projects="item.projects_count"
                      :species="item.species_count"
                      :theme="mapCardThemes.project"
                      :item="item"
                    />
                  </a>
                </div>
              </div>
              <div v-else>
                <h3 class="text-center mt-0 DesktopLinkMedium">
                  Sorry, no results
                </h3>
                <p class="px-4 DesktopTextSmall">
                  No results were found for your search.
                  Make sure all words are spelled correctly or try using different keywords.
                </p>
              </div>
            </section>
          </template>
        </map-side-bar>
      </div>
      <main v-if="!showMap" class="col-12 col-lg-6">
        <div v-if="loading">
          <loading-indicator
            :skeletons="[
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'}
            ]"
          />
        </div>
        <section v-else-if="results.projects.length > 0" class="arkCard">
          <h3 class="DesktopTextMedium primaryDefault mt-0">Projects</h3>
          <project-card
            v-for="project in paginatedData"
            :key="project.id"
            :project="project"
          />
        </section>
        <section v-else class="arkCard">
          <no-data-card
            first-message="Sorry, no results"
            second-message="No results were found for your search. Make sure all words are spelled correctly or try using different keywords."
            :show-button="false"
          />
        </section>
      </main>
      <main v-else class="col-12 p-0 mapContainer">
        <search-map :items="results.projects || []" />
      </main>
    </div>
  </div>
</template>

<script>
import FilterSidebarContent from '../../../features/map/components/FilterSidebarContent.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import MapButton from '../../../components/mapLink/components/MapButton.vue';
import MapSideBar from '../../map/components/MapSideBar.vue';
import MapSidebarCard from '../../map/components/MapSidebarCard.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import ProjectCard from '../../../components/listItems/ProjectCard.vue';
import SearchMap from './SearchMap.vue';
import SearchTabs from './SearchTabs.vue';
import { mapCardThemes } from '../../../domain/enumerationHelper';
import { searchResultsMixin } from '../../../mixins';

export default {
  name: 'ProjectResults',
  components: {
    FilterSidebarContent,
    LoadingIndicator,
    MapButton,
    MapSideBar,
    MapSidebarCard,
    NoDataCard,
    ProjectCard,
    SearchMap,
    SearchTabs
  },
  mixins: [searchResultsMixin],
  data() {
    return {
      tab: 'projects',
      filtersOpen: window.innerWidth >= 1700,
      sidebarOpen: window.innerWidth >= 1700,
      investmentReady: false,
      showMap: false,
      mapCardThemes
    };
  },
  methods: {
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      if (!this.sidebarOpen) {
        this.filtersOpen = true;
        this.sidebarOpen = true;
      }

      if (!this.showMap) {
        this.sidebarOpen = this.filtersOpen;
      }
    },
    sideBarStatus(statusOfSidebar, _statusOfSecondSidebar, _statusOfFiltersBar) {
      this.sidebarOpen = statusOfSidebar;
      if (!this.showMap) {
        this.filtersOpen = statusOfSidebar;
      }
    },
    toggleInvestmentReady(investmentReady) {
      this.investmentReady = investmentReady;
    },
    fetchFilterEntities(resultIds) {
      this.results.projects = this.allResults.filter((project) => {
        return resultIds.includes(project.id);
      });
      this.page = 1;
      this.loadMore();
    },
    goToMap() {
      this.showMap = true;
      this.filtersOpen = false;

      // Close sidebar when showing map if screen is too small
      if (window.innerWidth < 1700) {
        this.sidebarOpen = false;
      }
    },
    closeMap() {
      this.showMap = false;
      this.filtersOpen = this.sidebarOpen;
    }
  }
};
</script>

<style scoped>
.filterSidebar {
  --sidebarTop: 140px;
  --visibleSideBarButtonTop: 50vh - 140px;
}

.mapContainer {
  height: calc(100vh - 145px);
}

.mapButtonSection {
  height: 15%;
  max-height: 100px;
}

.mapButtonContainer {
  height: 80px;
  max-height: 100%;
}

.filtersSection {
  height: 85%;
}
</style>
