<template>
  <div id="riskHeatmap" class="arkCard">
    <div class="row">
      <div class="col-12">
        <div class="DesktopTextMedium secondary7_ark">Risk assessment</div>
      </div>
      <div class="col-12">
        <figure class="highcharts-figure">
          <div id="heat-map" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { heatmapTemplate } from '../../../../domain/constants';
import { snakeCaseToTitleCase } from '../../../../utilities/genericMethods';

const template = {
  ...heatmapTemplate,
  plotOptions: {
    series: {
      borderWidth: 1,
      pointPadding: 0.2,
      dataLabels: [
        {
          enabled: false
        },
        {
          enabled: true,
          formatter() {
            if (this.point.value === 1) return `${this.point.value} risk`;
            else if (this.point.value > 1) return `${this.point.value} risks`;
            else return '';
          },
          style: {
            color: '#000000',
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '24px',
            fontFamily: 'Poppins'
          }
        }
      ]
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    labels: {
      style: {
        color: '#616374',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: 'Poppins',
        padding: '5px'
      }
    },
    title: {
      text: 'Severity',
      style: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '24px',
        fontFamily: 'Poppins',
        padding: '5px'
      }
    },
    opposite: true
  },
  yAxis: {
    labels: {
      style: {
        color: '#616374',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '24px',
        fontFamily: 'Poppins',
        padding: '5px'
      }
    },
    title: {
      text: 'Likelihood',
      style: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '24px',
        fontFamily: 'Poppins',
        padding: '5px'
      }
    }
  },
  tooltip: {
    formatter() {
      return (
        '<b>' +
        (this.point.value !== 1 ? this.point.value + ' risks ' : '1 risk ') +
        '</b> <br/>'+ 'with Severity: <b>' +
        this.series.xAxis.categories[this.point.x] +
        '</b><br/> and Likelihood: <b>' +
        this.series.yAxis.categories[this.point.y] +
        '</b>'
      );
    }
  },
  accessibility: {
    point: {
      valueSuffix: 'risks'
    }
  }
};

export default {
  props: {
    likelihoodOptions: {
      type: Array,
      required: true
    },
    severityOptions: {
      type: Array,
      required: true
    },
    risks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      heatMap: undefined
    };
  },
  watch: {
    risks(newRiskValue) {
      this.fillHeatmap(newRiskValue);
    }
  },
  mounted() {
    this.fillHeatmap(this.risks);
  },
  methods: {
    getRiskCount(data, col, row) {
      return Object.values(data).filter((item) => {
        return snakeCaseToTitleCase(item.likelihood).toLowerCase() ===
          this.likelihoodOptions[row].toLowerCase() &&
          snakeCaseToTitleCase(item.severity).toLowerCase() ===
            this.severityOptions[col].toLowerCase();
      }).length;
    },
    fillHeatmap(data) {
      let lowArray = [];
      let mediumArray = [];
      let highArray = [];
      let veryHighArray = [];
      let criticalArray = [];

      for (let col = 0; col < 5; col++) {
        for (let row = 0; row < 5; row++) {
          switch (col * row) {
            case 16:
              criticalArray = [...criticalArray, [col, row, this.getRiskCount(data, col, row)]];
              break;
            case 12:
              veryHighArray = [...veryHighArray, [col, row, this.getRiskCount(data, col, row)]];
              break;
            case 9: case 8: case 6:
              highArray = [...highArray, [col, row, this.getRiskCount(data, col, row)]];
              break;
            case 4: case 3:
              mediumArray = [...mediumArray, [col, row, this.getRiskCount(data, col, row)]];
              break;
            default: lowArray = [...lowArray, [col, row, this.getRiskCount(data, col, row)]];
            break;
          }
        }
      }

      this.renderHeatMap(
        lowArray,
        mediumArray,
        highArray,
        veryHighArray,
        criticalArray
      );
    },
    renderHeatMap(
      lowArray,
      mediumArray,
      highArray,
      veryHighArray,
      criticalArray
    ) {
      if (this.risks.length === 0) {
        this.heatMap = undefined;
        return;
      }

      this.heatMap = Highcharts.chart('heat-map', template);
      this.heatMap.series[0].remove();

      const seriesTemplates = [
        {
          name: 'Low',
          data: lowArray,
          color: '#e0f3dc'
        },
        {
          name: 'Medium',
          data: mediumArray,
          color: '#f0eea280'
        },
        {
          name: 'High',
          data: highArray,
          color: '#ffdbbd'
        },
        {
          name: 'Very High',
          data: veryHighArray,
          color: '#c43a4980'
        },
        {
          name: 'Critical',
          data: criticalArray,
          color: '#61637480'
        }
      ];

      seriesTemplates.forEach(
        (seriesTemplate) => this.heatMap.addSeries({ ...seriesTemplate, borderColor: '#BDBFD2' })
      );

      this.heatMap.xAxis[0].setCategories(this.severityOptions);
      this.heatMap.yAxis[0].setCategories(this.likelihoodOptions);
    }
  }
};
</script>
