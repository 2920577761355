<template>
  <section class="arkCard">
    <header class="row mb-2">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">Details</h2>
      </section>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <a
          class="p-0 m-0 pointer"
          aria-label="Edit overview section"
          :href="`/${modelPluralize}/${modelId}/edit?tab=details`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <div class="row">
      <div class="col-12">
        <video
          v-if="organization?.about_video"
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered  vjs-16-9"
          controls
          width="auto"
          height="auto"
          preload="auto"
          data-setup="{}"
        >
          <source
            v-for="(video, index) in organization?.videos"
            :key="index + video.url"
            :src="video.url"
            :type="video.type"
          >
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener">
              supports HTML5 video
            </a>
          </p>
        </video>
        <video
          v-else-if="organization?.about_video_url"
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
          controls
          width="auto"
          height="auto"
          preload="auto"
          tech-order="['youtube', 'html5']"
          data-setup="{}"
        >
          <source
            :src="organization?.about_video_url"
            type="video/youtube"
          >
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener">
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
    </div>
    <section class="row">
      <header class="col-12">
        <h3 class="DesktopLinkSmall secondary7_ark">Social links</h3>
      </header>
      <nav
        class="d-flex align-items-center col-12"
        :aria-label="'Social links for ' + organization?.name"
      >
        <a
          v-if="organization?.social_media_links?.linkedin_link"
          class="p-0 m-0 pr-3"
          :aria-label="organization?.name + '\'s Linkedin account'"
          target="_blank"
          :href="organization?.social_media_links?.linkedin_link"
          rel="noopener"
        >
          <i
            class="fa fa-linkedin-square secondaryDefault mediaIcon"
            aria-hidden="true"
          />
        </a>
        <a
          v-if="organization?.social_media_links?.facebook_link"
          class="p-0 m-0 pr-3"
          :aria-label="organization?.name + '\'s Facebook account'"
          target="_blank"
          :href="organization?.social_media_links?.facebook_link"
          rel="noopener"
        >
          <i
            class="fa fa-facebook-square secondaryDefault mediaIcon"
            aria-hidden="true"
          />
        </a>
        <a
          v-if="organization?.social_media_links?.youtube_link"
          class="p-0 m-0 pr-3"
          :aria-label="organization?.name + '\'s Youtube account'"
          target="_blank"
          :href="organization?.social_media_links?.youtube_link"
          rel="noopener"
        >
          <i
            class="fa fa-youtube-play secondaryDefault mediaIcon"
            aria-hidden="true"
          />
        </a>
        <a
          v-if="organization?.social_media_links?.twitter_link"
          class="p-0 m-0 pr-3"
          :aria-label="organization?.name + '\'s Twitter account'"
          target="_blank"
          :href="organization?.social_media_links?.twitter_link"
          rel="noopener"
        >
          <i
            class="fa fa-twitter secondaryDefault mediaIcon"
            aria-hidden="true"
          />
        </a>
        <a
          v-if="organization?.social_media_links?.instagram_link"
          class="p-0 m-0 pr-3"
          :aria-label="organization?.name + '\'s Instagram account'"
          target="_blank"
          :href="organization?.social_media_links?.instagram_link"
          rel="noopener"
        >
          <i
            class="fa fa-instagram secondaryDefault mediaIcon"
            aria-hidden="true"
          />
        </a>
      </nav>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    if (this.$refs.videoPlayer) this.player = videojs(this.$refs.videoPlayer);
  },
  beforeUnmount() {
    if (this.player) this.player.dispose();
  }
};
</script>

<style scoped>
.mediaIcon {
  font-size: 20px !important;
}
</style>
