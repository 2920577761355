<template>
  <section class="my-3">
    <h3 class="DesktopLinkSmall primaryDefault title-size m-0">
      <i class="fas fa-book mr-1" aria-hidden="true" /> {{ book.title }}
    </h3>
    <p class="DesktopTextXSmall grayscaleBody mb-1">
      {{ book.author }} <span v-if="book.author && releaseYear">·</span> {{ releaseYear }}
    </p>
    <a
      v-if="book.book_url"
      class="btn btn-sm DesktopLinkXSmall borderedButton transition-02 btn-round"
      :href="book.book_url"
      target="_blank"
      rel="noreferrer"
    >
      Preview
    </a>
  </section>
</template>

<script>
export default {
  props: {
    book: {
      type: Object,
      required: true
    }
  },
  computed: {
    releaseYear() {
      return this.book.release_date && new Date(this.book.release_date).getFullYear();
    }
  }
};
</script>

<style scoped>
.title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
