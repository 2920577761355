<template>
  <div class="slider d-flex align-items-center">
    <div class="slide-track" :style="{ animation: scrollAnimation }">
      <div v-for="(logo, index) in logosWithDuplicates" :key="index" class="slide m-auto d-flex align-items-center justify-content-center">
        <img :src="logo" alt="" class="slide-logo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        "https://cdn.theark.co/css/theark_partner_logo_1.png",
        "https://cdn.theark.co/css/theark_partner_logo_2.png",
        "https://cdn.theark.co/css/theark_partner_logo_3.png",
        "https://cdn.theark.co/css/theark_partner_logo_4.png",
        "https://cdn.theark.co/css/theark_partner_logo_5.png",
        "https://cdn.theark.co/css/theark_partner_logo_6.png",
        "https://cdn.theark.co/css/theark_partner_logo_7.png",
        "https://cdn.theark.co/css/theark_partner_logo_8.png",
        "https://cdn.theark.co/css/theark_partner_logo_9.png",
        "https://cdn.theark.co/css/theark_partner_logo_10.png",
      ],
      inViewport: false,
    };
  },
  computed: {
    logosWithDuplicates() {
      // Duplicate the logos array to create a seamless loop
      return this.logos.concat(this.logos);
    },
    scrollAnimation() {
      const animationDuration = this.logosWithDuplicates.length * 2;
      return this.inViewport ? `scroll ${animationDuration}s linear infinite` : 'none';
    },
  },
  mounted() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
      const observer = new IntersectionObserver(this.handleIntersection, options);
      observer.observe(this.$el);
    },
    handleIntersection(entries) {
      this.inViewport = entries[0].isIntersecting;
    },
  },
};
</script>

<style>

.slide-logo {
  max-height:40px;
  max-width:100px;
}

.slider {
  height: 150px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #172822;
}

.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  display: flex;
  width: calc(150px * 20); /* Adjust to the correct length */
}

.slider .slide {
  height: 100px;
  width: 150px;
  padding-left: 20px;
}

/* Custom CSS animation for the carousel */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 10)); /* Adjust to the correct length */
  }
}
</style>
