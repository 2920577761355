import ConfirmEmail from './components/ConfirmEmail.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#applicant-confirmation')){
    const app = createApp(ConfirmEmail);
    app.use(store);
    app.mount('#applicant-confirmation');
  }
});
