class TechOrgHelper {
  static checkSpecies(techOrgSpeciesAllButton) {
    techOrgSpeciesAllButton.addEventListener("click", () => {
      document.querySelector("#all-species-btn").classList.add("active");
      document.querySelector("#all-species-btn").style.cssText = "text-decoration: underline";
      document.querySelector("#none-species-btn").style.cssText = "text-decoration: none";
      document.querySelector("#none-species-btn").classList.remove("active");
      let allButton = document.querySelector("#all-species-btn");
      let speciesCheckbox = document.querySelectorAll("#speciesCheckbox");
      for (var i = 0; i < speciesCheckbox.length; i++) {
        speciesCheckbox[i].setAttribute("checked", "true");
      }
    });
  }

  static uncheckSpecies(techOrgSpeciesNoneButton) {
    techOrgSpeciesNoneButton.addEventListener("click", () => {
      document.querySelector("#none-species-btn").classList.add("active");
      document.querySelector("#none-species-btn").style.cssText = "text-decoration: underline";
      document.querySelector("#all-species-btn").style.cssText = "text-decoration: none";
      document.querySelector("#all-species-btn").classList.remove("active");
      let noneButton = document.querySelector("#none-species-btn");
      let speciesCheckbox = document.querySelectorAll("#speciesCheckbox");
      for (var i = 0; i < speciesCheckbox.length; i++) {
        speciesCheckbox[i].removeAttribute("checked", "true");
      }
    });
  }
}

let techOrgSpeciesAllButton = document.getElementById('all-species-btn');
if (techOrgSpeciesAllButton) {
  TechOrgHelper.checkSpecies(techOrgSpeciesAllButton);
}

let techOrgSpeciesNoneButton = document.getElementById('none-species-btn');
if (techOrgSpeciesNoneButton) {
  TechOrgHelper.uncheckSpecies(techOrgSpeciesNoneButton);
}
