<template>
  <div class="arkCard">
    <div class="text-center">
      <div class="card-title DesktopLinkMedium primaryDefault mb-5 mt-5 px-5">
        <span v-for="(skeleton, index) in skeletons" :key="index">
          <skeleton-box
            :height="skeleton.height"
            :width="skeleton.width"
            :class="{'one-skeleton': skeletons.length === 1}"
          />
          <br>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonBox from './SkeletonBox.vue';

export default {
  components: {
    SkeletonBox
  },
  props: {
    skeletons: {
      type: Array,
      required: false,
      default: () => {
        return [
          { width: null, height: null },
          { width: null, height: null }
        ];
      }
    }
  }
};
</script>

<style scoped>
.one-skeleton {
  margin-left: 20%;
}
</style>
