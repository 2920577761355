<template>
  <search-tabs tab="top_news" @tab-changed="tabChanged" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-3" />
      <main class="col-12 col-lg-6">
        <div v-if="loading">
          <loading-indicator
            :skeletons="[
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'}
            ]"
          />
        </div>
        <section v-else-if="results.top_news.length > 0" class="arkCard">
          <h3 class="DesktopTextMedium primaryDefault mt-0">Posts</h3>
          <ark-post
            v-for="post in paginatedData"
            :key="post.id"
            :post="post"
            :author="{}"
            @post-deleted="postDeleted"
            @edit-post="updatePost"
            @add-comment="addComment"
            @edit-comment="editComment"
            @comment-deleted="deleteComment"
            @share-post="sharingPost"
          />
          <create-post
            :show-add-post-input="false"
            :edit-post="editPost"
            :share-post="sharePost"
            :author="{}"
            @post-created="newPost"
            @post-deleted="postDeleted"
            @post-updated="updatePosts"
            @comment-created="createComment"
            @comment-updated="updatePostComments"
            @modal-closed="createUpdatePostModalClosed"
          />
        </section>
        <section v-else class="arkCard">
          <no-data-card
            first-message="Sorry, no results"
            second-message="No results were found for your search. Make sure all words are spelled correctly or try using different keywords."
            :show-button="false"
          />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import { postsMixin, searchResultsMixin } from '../../../mixins';
import ArkPost from '../../../components/listItems/ArkPost.vue';
import CreatePost from '../../../components/shared/CreatePost.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import SearchTabs from './SearchTabs.vue';
import { nextTick } from 'vue';

export default {
  name: 'PostResults',
  components: { ArkPost, CreatePost, LoadingIndicator, NoDataCard, SearchTabs },
  mixins: [postsMixin, searchResultsMixin],
  data() {
    return {
      tab: 'top_news'
    };
  },
  watch: {
    loading() {
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    }
  },
  methods: {
    postDeleted(postId) {
      this.results.top_news = this.results.top_news?.filter((post) => {
        return post.id !== postId;
      });
      this.page--;
      this.loadMore();
    },
    createUpdatePostModalClosed() {
      this.editPost = {};
      this.sharePost = {};
    },
    sharingPost(post) {
      this.sharePost = post.parent || post;
    },
    newPost(post) {
      this.sharePost = {};
      this.results.top_news = [post, ...this.results.top_news];
      this.loadMore();
    },
    updatePosts(updatedPost) {
      const news = this.results.top_news;
      news.find((post) => {
        if (post.id === this.editPost.id) {
          post.content = updatedPost.content;
          post.url_attachment = updatedPost.url_attachment;
        }
      });
      this.results.top_news = news;
      this.loadMore();
      this.editPost = {};
    },
    createComment(comment) {
      const updatePost = this.results.top_news.find((post) => post.id === comment.top_news_id);
      updatePost.comments = updatePost.comments ? [comment, ...updatePost.comments] : [comment];
      updatePost.comments_count += 1;
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    updatePostComments(updatedComment) {
      const updatePost = this.results.top_news.find((post) => {
        return post.id === this.editPost.top_news_id;
      });
      const updateComment = updatePost.comments.find((comment) => comment.id === updatedComment.id);
      updateComment.content = updatedComment.content;
      updateComment.url_attachment = updatedComment.url_attachment;

      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      this.editPost = {};
    },
    deleteComment(deletedComment) {
      const updatePost = this.results.top_news.find((post) => {
        return post.id === deletedComment.top_news_id;
      });
      const comments = updatePost.comments.filter((comment) => deletedComment.id !== comment.id);
      updatePost.comments = comments;
      updatePost.comments_count -= 1;
    }
  }
};
</script>

