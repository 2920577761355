<template>
  <ApplyFormStep1
    v-if="step === 1"
    :logoImage="logoImage"
    @submit="updateForm"
  />
  <ApplyFormStep2
    v-else-if="step === 2"
    @submit="updateForm"
  />
  <ApplyFormStep3
    v-else-if="step === 3"
    :images="images"
    @submit="updateForm"
  />
  <ApplyFormStep4
    v-else-if="step === 4"
    :loading="loading"
    :error-message="errorMessageFromBackend"
    @submit="updateForm"
  />
  <CheckInbox
    v-else
    :email="form.email"
  />
</template>

<script>
import ApplyFormStep1 from './ApplyFormStep1.vue';
import ApplyFormStep2 from './ApplyFormStep2.vue';
import ApplyFormStep3 from './ApplyFormStep3.vue';
import ApplyFormStep4 from './ApplyFormStep4.vue';
import CheckInbox from '../../sharedComponents/CheckInbox.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { ApplyFormStep1, ApplyFormStep2, ApplyFormStep3, ApplyFormStep4, CheckInbox },
  data() {
    return {
      logoImage: '',
      images: {},
      organizations: [],
      step: 1,
      loading: false,
      form: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        user_group: '',
        position: '',
        organization: '',
        linkedin_link: ''
      },
      errorMessageFromBackend: ''
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      const data = document.querySelector('#apply-form');
      this.logoImage = data?.dataset?.imgLogo;
      this.images = {
        collaborate: data?.dataset?.imgCollaborate,
        invest: data?.dataset?.imgInvest,
        data: data?.dataset?.imgData,
        discover: data?.dataset?.imgDiscover
      };
      // Temporarily disable this fxnality
      // this.organizations = JSON.parse(data?.dataset?.organizations) || [];
    },
    updateForm(fields) {
      this.form = {...this.form, ...fields};
      if (this.step > 3) {
        this.submit();
        return;
      }
      this.step++;
    },
    submit() {
      this.loading = true;
      fallbackApi({
        url: 'applicants',
        payload: {
          json: {
            applicant: this.form
          }
        },
        httpVerb: 'post',
        dataSetter: () => {
          this.loading = false;
          this.step++;
        }
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again later.';
        this.loading = false;
      });
    }
  }
};
</script>
