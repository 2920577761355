import { mapCardThemes } from '../../../domain/enumerationHelper';
import { symbolToString } from '../../../utilities/enumeration';

const uniqueValueInfoCreator = (value, icon, color) => {
  return {
    value: value,
    symbol: {
      type: 'cim',
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMPictureMarker',
              enable: true,
              anchorPoint: {
                x: 0,
                y: -5
              },
              size: 12,
              scaleX: 1,
              tintColor: [255, 255, 255, 255],
              url: icon
            },
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPoint: {
                x: 0,
                y: -0.5
              },
              dominantSizeAxis3D: 'Y',
              size: 35,
              billboardMode3D: 'FaceNearPlane',
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 21,
                ymax: 21
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    'rings': [
                      [
                        [
                          17.17,
                          14.33
                        ],
                        [
                          16.97,
                          12.96
                        ],
                        [
                          16.38,
                          11.37
                        ],
                        [
                          12.16,
                          3.98
                        ],
                        [
                          11.2,
                          1.94
                        ],
                        [
                          10.5,
                          0
                        ],
                        [
                          9.8,
                          1.96
                        ],
                        [
                          8.84,
                          4.02
                        ],
                        [
                          4.61,
                          11.41
                        ],
                        [
                          4.02,
                          12.98
                        ],
                        [
                          3.83,
                          14.33
                        ],
                        [
                          3.96,
                          15.63
                        ],
                        [
                          4.34,
                          16.88
                        ],
                        [
                          4.95,
                          18.03
                        ],
                        [
                          5.78,
                          19.04
                        ],
                        [
                          6.8,
                          19.88
                        ],
                        [
                          7.95,
                          20.49
                        ],
                        [
                          9.2,
                          20.87
                        ],
                        [
                          10.5,
                          21
                        ],
                        [
                          11.8,
                          20.87
                        ],
                        [
                          13.05,
                          20.5
                        ],
                        [
                          14.2,
                          19.88
                        ],
                        [
                          15.22,
                          19.05
                        ],
                        [
                          16.05,
                          18.03
                        ],
                        [
                          16.66,
                          16.88
                        ],
                        [
                          17.04,
                          15.63
                        ],
                        [
                          17.17,
                          14.33
                        ]
                      ]
                    ]
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Round',
                        joinStyle: 'Round',
                        lineStyle3D: 'Strip',
                        miterLimit: 10,
                        width: 0,
                        color: [
                          110,
                          110,
                          110,
                          255
                        ]
                      },
                      {
                        type: 'CIMSolidFill',
                        enable: true,
                        color
                      }
                    ]
                  }
                }
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true
            }
          ],
          scaleX: 1,
          angleAlignment: 'Display',
          version: '2.0.0',
          build: '8933'
        }
      }
    }
  };
};

const clusterSymbolConfigCreator = (color) => {
  return {
    symbol: {
      type: 'cim',
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPoint: {
                x: 0,
                y: 0
              },
              dominantSizeAxis3D: 'Y',
              size: 30,
              billboardMode3D: 'FaceNearPlane',
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 21,
                ymax: 21
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    'rings': [
                      [
                        [
                          17.17,
                          14.33
                        ],
                        [
                          16.97,
                          12.96
                        ],
                        [
                          16.38,
                          11.37
                        ],
                        [
                          12.16,
                          3.98
                        ],
                        [
                          11.2,
                          1.94
                        ],
                        [
                          10.5,
                          0
                        ],
                        [
                          9.8,
                          1.96
                        ],
                        [
                          8.84,
                          4.02
                        ],
                        [
                          4.61,
                          11.41
                        ],
                        [
                          4.02,
                          12.98
                        ],
                        [
                          3.83,
                          14.33
                        ],
                        [
                          3.96,
                          15.63
                        ],
                        [
                          4.34,
                          16.88
                        ],
                        [
                          4.95,
                          18.03
                        ],
                        [
                          5.78,
                          19.04
                        ],
                        [
                          6.8,
                          19.88
                        ],
                        [
                          7.95,
                          20.49
                        ],
                        [
                          9.2,
                          20.87
                        ],
                        [
                          10.5,
                          21
                        ],
                        [
                          11.8,
                          20.87
                        ],
                        [
                          13.05,
                          20.5
                        ],
                        [
                          14.2,
                          19.88
                        ],
                        [
                          15.22,
                          19.05
                        ],
                        [
                          16.05,
                          18.03
                        ],
                        [
                          16.66,
                          16.88
                        ],
                        [
                          17.04,
                          15.63
                        ],
                        [
                          17.17,
                          14.33
                        ]
                      ]
                    ]
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Round',
                        joinStyle: 'Round',
                        lineStyle3D: 'Strip',
                        miterLimit: 10,
                        width: 0,
                        color: [
                          110,
                          110,
                          110,
                          255
                        ]
                      },
                      {
                        type: 'CIMSolidFill',
                        enable: true,
                        color
                      }
                    ]
                  }
                }
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true
            }
          ],
          scaleX: 1,
          angleAlignment: 'Display',
          version: '2.0.0',
          build: '8933'
        }
      }
    }
  };
};

const pointSymbolConfig = {
  type: 'cim',
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPointSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          anchorPoint: {
            x: 0,
            y: -0.9
          },
          anchorPointUnits: 'Relative',
          dominantSizeAxis3D: 'Y',
          size: 7,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [
                      0,
                      5
                    ],
                    [
                      0.87,
                      4.92
                    ],
                    [
                      1.71,
                      4.7
                    ],
                    [
                      2.5,
                      4.33
                    ],
                    [
                      3.21,
                      3.83
                    ],
                    [
                      3.83,
                      3.21
                    ],
                    [
                      4.33,
                      2.5
                    ],
                    [
                      4.7,
                      1.71
                    ],
                    [
                      4.92,
                      0.87
                    ],
                    [
                      5,
                      0
                    ],
                    [
                      4.92,
                      -0.87
                    ],
                    [
                      4.7,
                      -1.71
                    ],
                    [
                      4.33,
                      -2.5
                    ],
                    [
                      3.83,
                      -3.21
                    ],
                    [
                      3.21,
                      -3.83
                    ],
                    [
                      2.5,
                      -4.33
                    ],
                    [
                      1.71,
                      -4.7
                    ],
                    [
                      0.87,
                      -4.92
                    ],
                    [
                      0,
                      -5
                    ],
                    [
                      -0.87,
                      -4.92
                    ],
                    [
                      -1.71,
                      -4.7
                    ],
                    [
                      -2.5,
                      -4.33
                    ],
                    [
                      -3.21,
                      -3.83
                    ],
                    [
                      -3.83,
                      -3.21
                    ],
                    [
                      -4.33,
                      -2.5
                    ],
                    [
                      -4.7,
                      -1.71
                    ],
                    [
                      -4.92,
                      -0.87
                    ],
                    [
                      -5,
                      0
                    ],
                    [
                      -4.92,
                      0.87
                    ],
                    [
                      -4.7,
                      1.71
                    ],
                    [
                      -4.33,
                      2.5
                    ],
                    [
                      -3.83,
                      3.21
                    ],
                    [
                      -3.21,
                      3.83
                    ],
                    [
                      -2.5,
                      4.33
                    ],
                    [
                      -1.71,
                      4.7
                    ],
                    [
                      -0.87,
                      4.92
                    ],
                    [
                      0,
                      5
                    ]
                  ]
                ]
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [
                      36,
                      66,
                      43,
                      255
                    ]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        },
        {
          type: 'CIMVectorMarker',
          enable: true,
          colorLocked: true,
          anchorPoint: {
            x: 0,
            y: -0.5
          },
          dominantSizeAxis3D: 'Y',
          size: 30,
          billboardMode3D: 'FaceNearPlane',
          frame: {
            xmin: 0,
            ymin: 0,
            xmax: 21,
            ymax: 21
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [
                      17.17,
                      14.33
                    ],
                    [
                      16.97,
                      12.96
                    ],
                    [
                      16.38,
                      11.37
                    ],
                    [
                      12.16,
                      3.98
                    ],
                    [
                      11.2,
                      1.94
                    ],
                    [
                      10.5,
                      0
                    ],
                    [
                      9.8,
                      1.96
                    ],
                    [
                      8.84,
                      4.02
                    ],
                    [
                      4.61,
                      11.41
                    ],
                    [
                      4.02,
                      12.98
                    ],
                    [
                      3.83,
                      14.33
                    ],
                    [
                      3.96,
                      15.63
                    ],
                    [
                      4.34,
                      16.88
                    ],
                    [
                      4.95,
                      18.03
                    ],
                    [
                      5.78,
                      19.04
                    ],
                    [
                      6.8,
                      19.88
                    ],
                    [
                      7.95,
                      20.49
                    ],
                    [
                      9.2,
                      20.87
                    ],
                    [
                      10.5,
                      21
                    ],
                    [
                      11.8,
                      20.87
                    ],
                    [
                      13.05,
                      20.5
                    ],
                    [
                      14.2,
                      19.88
                    ],
                    [
                      15.22,
                      19.05
                    ],
                    [
                      16.05,
                      18.03
                    ],
                    [
                      16.66,
                      16.88
                    ],
                    [
                      17.04,
                      15.63
                    ],
                    [
                      17.17,
                      14.33
                    ]
                  ]
                ]
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    capStyle: 'Round',
                    joinStyle: 'Round',
                    lineStyle3D: 'Strip',
                    miterLimit: 10,
                    width: 0,
                    color: [
                      36,
                      66,
                      43,
                      255
                    ]
                  },
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [
                      215,
                      222,
                      192,
                      200
                    ]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        }
      ],
      haloSize: 1,
      scaleX: 1,
      angleAlignment: 'Display',
      version: '2.0.0',
      build: '8933'
    }
  }
};

const sitePointSymbolConfigCreator = (color) => {
  return {
    type: 'simple',
    symbol: {
      type: 'cim',
      data: {
        type: 'CIMSymbolReference',
        symbol: {
          type: 'CIMPointSymbol',
          symbolLayers: [
            {
              type: 'CIMVectorMarker',
              enable: true,
              anchorPoint: {
                x: 0,
                y: -0.5
              },
              anchorPointUnits: 'Relative',
              dominantSizeAxis3D: 'Y',
              size: 15,
              billboardMode3D: 'FaceNearPlane',
              frame: {
                xmin: 0,
                ymin: 0,
                xmax: 21,
                ymax: 21
              },
              markerGraphics: [
                {
                  type: 'CIMMarkerGraphic',
                  geometry: {
                    rings: [
                      [
                        [
                          17.17,
                          14.33
                        ],
                        [
                          16.97,
                          12.96
                        ],
                        [
                          16.38,
                          11.37
                        ],
                        [
                          12.16,
                          3.98
                        ],
                        [
                          11.2,
                          1.94
                        ],
                        [
                          10.5,
                          0
                        ],
                        [
                          9.8,
                          1.96
                        ],
                        [
                          8.84,
                          4.02
                        ],
                        [
                          4.61,
                          11.41
                        ],
                        [
                          4.02,
                          12.98
                        ],
                        [
                          3.83,
                          14.33
                        ],
                        [
                          3.96,
                          15.63
                        ],
                        [
                          4.34,
                          16.88
                        ],
                        [
                          4.95,
                          18.03
                        ],
                        [
                          5.78,
                          19.04
                        ],
                        [
                          6.8,
                          19.88
                        ],
                        [
                          7.95,
                          20.49
                        ],
                        [
                          9.2,
                          20.87
                        ],
                        [
                          10.5,
                          21
                        ],
                        [
                          11.8,
                          20.87
                        ],
                        [
                          13.05,
                          20.5
                        ],
                        [
                          14.2,
                          19.88
                        ],
                        [
                          15.22,
                          19.05
                        ],
                        [
                          16.05,
                          18.03
                        ],
                        [
                          16.66,
                          16.88
                        ],
                        [
                          17.04,
                          15.63
                        ],
                        [
                          17.17,
                          14.33
                        ]
                      ]
                    ]
                  },
                  symbol: {
                    type: 'CIMPolygonSymbol',
                    symbolLayers: [
                      {
                        type: 'CIMSolidStroke',
                        enable: true,
                        capStyle: 'Round',
                        joinStyle: 'Round',
                        lineStyle3D: 'Strip',
                        miterLimit: 10,
                        width: 2,
                        color: [255, 255, 255, 255]
                      },
                      {
                        type: 'CIMSolidFill',
                        enable: true,
                        color
                      }
                    ]
                  }
                }
              ],
              scaleSymbolsProportionally: true,
              respectFrame: true
            }
          ]
        }
      }
    }
  };
}

const sitePolygonSymbolConfigCreator = (color) => {
  return {
    type: 'simple',
    symbol: {
      type: 'simple-fill',
      color: [...color.slice(0, 3), 0.2],
      outline: {
        type: 'simple-line',
        color,
        width: 1
      }
    }
  };
};

const sitePolylineSymbolConfigCreator = (color) => {
  return {
    type: 'simple',
    symbol: {
      type: 'simple-line',
      color,
      width: 1
    }
  };
};

const clusterConfiguration = (
    organizationKey,
    projectKey,
    techOrgKey,
    color
  ) => {
  const conf = clusterSymbolConfigCreator(color);
  return {
    type: 'cluster',
    clusterRadius: '100px',
    clusterMinSize: '40px',
    clusterMaxSize: '60px',
    popupTemplate: {
      content: `
        <br>
        <ul class="listStyleNone DesktopLinkSmall primaryDefault">
          <li class="pb-1">{expression/Projects}</li>
          <li class="pb-1">{expression/Organizations}</li>
          <li>{expression/TechOrgs}</li>
        </ul>
      `,
      /**
       * Source: https://developers.arcgis.com/javascript/latest/arcade/
       */
      expressionInfos: [
        {
          name: 'Organizations',
          title: 'Organizations',
          expression: `
            var organizations = Count(
              Filter($aggregatedFeatures, "ArkType = '${organizationKey}'")
            );
            var word = IIf(organizations == 1, " Wildlife Organisation", " Wildlife Organisations");
            return IIf(organizations == 0, "", organizations + word);
          `
        },
        {
          name: 'Projects',
          title: 'Projects',
          expression: `
            var projects = Count(Filter($aggregatedFeatures, "ArkType = '${projectKey}'"));
            var word = IIf(projects == 1, " Project", " Projects");
            return IIf(projects == 0, "", projects + word);
          `
        },
        {
          name: 'TechOrgs',
          title: 'TechOrgs',
          expression: `
            var techOrgs = Count(Filter($aggregatedFeatures, "ArkType = '${techOrgKey}'"));
            var word = IIf(techOrgs == 1, " Technology", " Technologies");
            return IIf(techOrgs == 0, "", techOrgs + word);
          `
        }
      ]
    },
    labelingInfo: [
      {
        deconflictionStrategy: 'none',
        labelExpressionInfo: {
          expression: "Text($feature.cluster_count, '#,###')"
        },
        symbol: {
          type: 'text',
          color: [23, 39, 34],
          font: {
            weight: 'bold',
            family: 'sans-serif',
            size: '18px'
          },
          xoffset: 0,
          yoffset: -24
        },
        labelPlacement: 'above-center'
      }
    ],
    ...conf
  };
};

// Layer attributes can be found in the layer definition
// https://services5.arcgis.com/Mj0hjvkNtV7NRhA7/arcgis/rest/services/WDPA_v0/FeatureServer/layers
const protectedAreasPopupTemplate = {
  title: '{NAME}',
  content: [
    {
      type: 'text',
      text: `
        <p class="DesktopTextXSmall grayscaleLabel">{DESIG_ENG}</p>
        <p class="DesktopTextXSmall warningDarkmode">{DESIG_TYPE} Type{expression/statusInfo}</p>
        <p class="DesktopTextXSmall warningDarkmode">Managing Authority {MANG_AUTH}</p>
      `
    },
    {
      type: 'custom',
      creator: () => {
        const popupDiv = document.createElement('div');
        popupDiv.innerHTML = `
          <button id="select-area-btn" class="btn btn-round DesktopLinkXSmall borderedButton transition-02 w-100">Select area</button>
        `;

        return popupDiv;
      }
    }
  ],
  expressionInfos: [{
    name: 'statusInfo',
    expression: `
      return IIf($feature.STATUS_YR > 0, "; " + $feature.STATUS + " in " + $feature.STATUS_YR, "");
    `
  }]
};

const setSubtitle = (type, subType) => {
  if (type === symbolToString(mapCardThemes.tech_org) || subType === 'TechOrg') {
    return 'Technology';
  } else if (subType === 'ReportingProject') {
    return 'Report';
  } else if (type === symbolToString(mapCardThemes.project)) {
    return 'Project';
  } else {
    return 'Project Developer';
  }
};

const getURL = (type, id) => {
  if (type === 'tech_org' || type === 'organization') {
    return `/organizations/${id}`;
  } else {
    return `/projects/${id}`;
  }
};

const customPopupHtml = (feature) => {
  const image = feature.graphic.attributes.ArkImage ?
    `<img
      style="width: 100%; object-fit: cover; height: 120px"
      class="mb-2"
      src="${feature.graphic.attributes.ArkImage}"
      alt="Banner or latest image"
    />` : '';

  const title = `
    <p class="secondary7_ark DesktopLinkSmall mb-1 mx-3 popup-title">
      ${feature.graphic.attributes.Name}
    </p>
  `;

  const subtitle = `
    <p class="grayscaleBody DesktopTextXSmall mb-1 mx-3">
      ${setSubtitle(feature.graphic.attributes.ArkType, feature.graphic.attributes.ArkSubType)}
    </p>
  `;

  const goalsLabel = feature.graphic.attributes.ArkGoalsCount > 0 ?
    `<p class="warningDarkmode DesktopTextXSmall mb-1 mx-3">
      <span class="badgeGoals">
        ${feature.graphic.attributes.ArkGoalsCount} goals
      </span>
    </p>` : '';

  const speciesLabel = feature.graphic.attributes.ArkSpeciesCount ?
    feature.graphic.attributes.ArkSpeciesCount + ' species' : '';

  const projectsLabel = feature.graphic.attributes.ArkProjectsCount ?
    `<span class="mr-2">
      ${feature.graphic.attributes.ArkProjectsCount} projects
    </span>` : '';
  const link = `
    <a
      href="${getURL(feature.graphic.attributes.ArkType, feature.graphic.attributes.ArkId)}"
      class="btn btn-round DesktopLinkXSmall borderedButton transition-02 w-100"
    >
      View detail
    </a>
  `;

  const html = `
    ${image}
    <div>
      ${title}
      ${subtitle} ${goalsLabel}
      <p class="warningDarkmode DesktopTextXSmall mb-1 mx-3">
        ${projectsLabel} ${speciesLabel}
      </p>
      <p class="mb-1  mx-3 d-flex justify-content-center">
        ${link}
      </p>
    </div>
  `;

  return html;
}

export {
  uniqueValueInfoCreator,
  clusterSymbolConfigCreator,
  pointSymbolConfig,
  sitePointSymbolConfigCreator,
  sitePolygonSymbolConfigCreator,
  sitePolylineSymbolConfigCreator,
  clusterConfiguration,
  protectedAreasPopupTemplate,
  customPopupHtml
};
