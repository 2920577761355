<template>
  <div class="apply-form white-background">
    <header class="row justify-content-center mt-3">
      <div class="col-12 text-center">
        <h1 class="DesktopDisplaySmall pt-5">Help minimise threats to biodiversity</h1>
      </div>
    </header>
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <form class="arkCard text-center py-5 mb-2">
          <div class="text-left">
            <label
              for="positionInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Job title *
            </label>
            <input
              id="positionInput"
              v-model="form.position"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="positionError"
              :aria-invalid="!!errors.position"
              :class="{ notValid: errors.position }"
              @blur="validate"
              @input="showOrganizationLinkedin"
            >
            <error-message
              id="positionError"
              :error-condition="!!errors.position"
              :error-message="errors.position"
            />
          </div>
          <div v-show="showOrganization" class="text-left mt-4">
            <label
              for="organizationInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Company or organisation *
            </label>
            <input
              id="organizationInput"
              v-model="form.organization"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="organizationError"
              :aria-invalid="!!errors.organization"
              :class="{ notValid: errors.organization }"
              @blur="validate"
              @input="showLinkedinLink = true"
            >
            <error-message
              id="organizationError"
              :error-condition="!!errors.organization"
              :error-message="errors.organization"
            />
          </div>
          <div v-show="showLinkedinLink" class="text-left mt-4">
            <label
              for="linkedInInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              LinkedIn URL
            </label>
            <input
              id="linkedInInput"
              v-model="form.linkedin_link"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              aria-errormessage="linkedInError"
              :aria-invalid="!!errors.linkedin_link"
              :class="{ notValid: errors.linkedin_link }"
              @blur="validate"
            >
            <error-message
              id="linkedInError"
              :error-condition="!!errors.linkedin_link"
              :error-message="errors.linkedin_link"
            />
          </div>
          <button
            type="submit"
            class="
              btn
              DesktopLinkXSmall
              primaryButton
              w-100
              px-5
              mt-4
            "
            :disabled="loading || !form.position || !form.organization"
            @click.prevent="submit"
          >
            Next
          </button>
          <error-message
            :error-condition="errorMessage"
            :error-message="errorMessage"
          />
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  position: yup.string().required('Job title is a required field'),
  organization: yup.string().required('Organisation or company name is a required field'),
  linkedin_link: yup.string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,{excludeEmptyString: true, message: 'Please enter a valid url'}
            )
});

export default {
  components: { ErrorMessage, Multiselect },
  props: {
    // organizations: {
    //   type: Array,
    //   required: true
    // },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['submit'],
  data() {
    return {
      showErrors: false,
      showOrganization: false,
      showLinkedinLink: false,
      form: {
        position: '',
        organization: null,
        linkedin_link: ''
      },
      errors: {
        position: '',
        organization: '',
        linkedin_link: ''
      }
    };
  },
  mounted() {
    this.fillOrganization();
  },
  methods: {
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    fillOrganization() {
      const params = new URLSearchParams(document.location.search);
      this.form.organization = params?.get('info');
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        const form = {...this.form};
        this.$emit('submit', form);
      }
    },
    showOrganizationLinkedin() {
      this.showOrganization = true;
      this.showLinkedinLink = !!this.form.organization;
    }
  }
};

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.apply-form {
  padding-top: 75px;
}

.arkCard {
  padding-left: 15%;
  padding-right: 15%;
}

.white-background {
  min-height: 100vh;
  background-color: #FFFFFF;
}
</style>
