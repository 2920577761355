<template>
  <span
    class="skeleton-loader"
    :style="{
      height: height === null ? '1em' : height,
      width: computedWidth }"
  />
</template>

<script>
const maxWidth = 100;

export default {
  name: 'SkeletonBox',
  props: {
    minWidth: {
      default: 60,
      type: Number,
      required: false
    },
    height: {
      default: '1em',
      type: String,
      required: false
    },
    width: {
      default: null,
      type: String,
      required: false
    }
  },
  computed: {
    computedWidth() {
      return this.width ||
        `${Math.floor((Math.random() * (maxWidth - this.minWidth)) + this.minWidth)}%`;
    }
  }
};
</script>

<style scoped>
.skeleton-loader:empty {
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}
</style>
