<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Areas of knowledge:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Areas of knowledge</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="expertiseInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Skills
              </label>
              <Multiselect
                id="expertiseInput"
                v-model="form.expertise"
                placeholder="Select or enter your skills"
                :options="expertiseAvailableOptions"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                track-by="name"
                label="name"
                :disabled="loading"
                class="multiselect-ark"
                :create-option="true"
              />
              <error-message
                id="aboutError"
                :error-condition="!!errors.expertise"
                :error-message="errors.expertise"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="interestsInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Interests
              </label>
              <Multiselect
                id="interestsInput"
                v-model="form.interests"
                placeholder="Select or enter your interests"
                :options="interestsAvailableOptions"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                track-by="name"
                label="name"
                :disabled="loading"
                class="multiselect-ark"
                :create-option="true"
              />
              <error-message
                id="aboutError"
                :error-condition="!!errors.interests"
                :error-message="errors.interests"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  expertise: yup.array(),
  skills: yup.array()
});

export default {
  components: { ErrorMessage, Multiselect },
  props: {
    user: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
  },
  emits: ['save-user'],
  data() {
    return {
      loading: false,
      showErrors: false,
      expertiseAvailableOptions: [],
      interestsAvailableOptions: [],
      form: {
        expertise: [],
        interests: []
      },
      errors: {
        expertise: '',
        interests: '',
      },
      errorMessageFromBackend: ''
    };
  },
  computed: {
    dataChanged() {
      if (this.user?.user.expertise && this.user?.user.interests) {
        return this.form.expertise.length !== this.user?.user.expertise.length || this.form.interests.length !== this.user?.user.interests.length;
      } else if (this.user?.user.expertise) {
        return this.form.expertise.length !== this.user?.user.expertise.length;
      } else if (this.user?.user.interests) {
        return this.form.interests.length !== this.user?.user.interests.length;
      } else {
        return this.form.expertise || this.form.interests;
      }
    }
  },
  watch: {
    user() {
      this.getUserInfo();
    }
  },
  mounted() {
    this.getOptions();
    this.getUserInfo();
  },
  methods: {
    //** Get default options
    getOptions() {
      fallbackApi({
        url: 'categories_and_tags',
        dataSetter: ({ categories_n_tags }) => {
          this.expertiseAvailableOptions = categories_n_tags;
          this.interestsAvailableOptions = categories_n_tags;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    //** Show existing user data
    getUserInfo() {
      const user_expertise = this.user?.user.expertise;
      const user_interests = this.user?.user.interests;

      this.form = {
        expertise: user_expertise,
        interests: user_interests
      };
      if (user_expertise) {
        this.expertiseAvailableOptions = [...new Set([...this.expertiseAvailableOptions, ...user_expertise])];
      }
      if (user_interests) {
        this.interestsAvailableOptions = [...new Set([...this.interestsAvailableOptions, ...user_interests])];
      }
    },
    async validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: 'knowledge',
          payload: {
            json:
              {
                user: {
                  id: this.user?.user.id,
                  expertise: this.form.expertise,
                  interests: this.form.interests
                }
              }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.$emit('save-user', response.data.user);
          this.showErrors = false;
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
