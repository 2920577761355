import NewUser from './components/NewUser.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#new-user')){
    const app = createApp(NewUser);
    app.use(store);
    app.mount('#new-user');
  }
});

