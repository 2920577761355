export const contributionsMixin = {
  data() {
    return {
      contributionRecipientId: '',
      contributionRecipientName: '',
      createContributionModalOpen: false,
      contributionButtonText: 'Invest'
    };
  },
  methods: {
    openCreateContributionFormModal(entity, type, buttonText) {
      this.createContributionModalOpen = true;
      this.contributionRecipientId = entity.id;
      this.contributionRecipientName = type === "project" ? entity.title : entity.name;
      this.contributionButtonText = buttonText;
    },
    closeCreateContributionFormModal() {
      this.createContributionModalOpen = false;
    }
  }
}
