<template>
  <search-tabs :tab="tab" @tab-changed="tabChanged" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-3" />
      <main class="col-12 col-lg-6">
        <div v-if="loading">
          <loading-indicator
            :skeletons="[
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'}
            ]"
          />
        </div>
        <section v-else-if="results[tab]?.length > 0" class="arkCard">
          <h3 class="DesktopTextMedium primaryDefault mt-0">{{ selectedTab?.name }}</h3>
          <component
            :is="entityComponent"
            v-for="item in paginatedData"
            :key="`${tab}-${item.id}`"
            :[selectedTab.singular]="item"
          />
        </section>
        <section v-else class="arkCard">
          <no-data-card
            first-message="Sorry, no results"
            second-message="No results were found for your search. Make sure all words are spelled correctly or try using different keywords."
            :show-button="false"
          />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import SearchTabs from './SearchTabs.vue';
import { defineAsyncComponent } from 'vue';
import { searchResultsMixin } from '../../../mixins';

export default {
  name: 'EntityResults',
  components: { LoadingIndicator, NoDataCard, SearchTabs },
  mixins: [searchResultsMixin],
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  emits: ['tabChanged'],
  data() {
    return {
      tabOptions: [
        { alias: 'users', singular: 'user', name: 'People', component: 'UserCard' },
        { alias: 'books', singular: 'book', name: 'Books', component: 'BookCard' },
        { alias: 'films', singular: 'film', name: 'Films', component: 'FilmCard' },
        { alias: 'podcasts', singular: 'podcast', name: 'Webinars', component: 'PodcastCard' },
        { alias: 'papers', singular: 'paper', name: 'Research papers', component: 'PaperCard' }
      ]
    };
  },
  computed: {
    selectedTab() {
      return this.tabOptions.find((option) => option.alias === this.tab);
    },
    entityComponent() {
      const component = this.selectedTab?.component;
      return defineAsyncComponent(() => import(`../../../components/listItems/${component}.vue`));
    }
  },
  watch: {
    tab() {
      this.fetchResults();
    }
  }
};
</script>

