<template>
  <section v-if="!editMode" class="backNav pb-3 mb-2">
    <div class="row justify-content-center mx-0">
      <nav class="col-12 col-lg-10">
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/organizations/${organizationId}/projects`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center mx-0">
      <div class="col-12 col-lg-10 d-flex align-items-center">
        <img
          :src="image"
          class="mr-2 noDataImage"
          alt="Globe"
          height="50"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">
          Let’s get started with a few details about your {{ projectTypeTitle }}.
        </h1>
      </div>
    </header>
  </section>
  <main class="row justify-content-center mx-0">
    <section class="col-12 col-lg-10">
      <span v-if="!editMode" class="DesktopTextXSmall">* indicates required</span>
      <form class="text-center">
        <section class="mt-3 text-left" :class="{'arkCard': !editMode}">
          <fieldset>
            <legend class="sr-only">{{ projectTypeTitle }} info:</legend>
            <div class="row mb-3">
              <h3 v-if="!editMode" class="col-12 mt-0 DesktopTextMedium grayscaleOffBlack text-capitalize">{{ projectTypeTitle }} details</h3>
              <div :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="nameInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Name *
                </label>
                <input
                  id="nameInput"
                  v-model="form.name"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  required
                  placeholder="Name *"
                  aria-errormessage="nameError"
                  :aria-invalid="!!errors.name"
                  :class="{ notValid: errors.name }"
                  @blur="validate"
                >
                <error-message
                  id="nameError"
                  :error-condition="!!errors.name"
                  :error-message="errors.name"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="text-left" :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="startDateInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Start date
                </label>
                <v-date-picker
                  id="startDateInput"
                  v-model="form.startDate"
                  :min-date="minStartDate"
                  :max-date="form.endDate"
                  :disabled="loading"
                  :model-config="{
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                  }"
                  :masks="{
                    type: 'string',
                    input: 'YYYY-MM-DD'
                  }"
                  color="yellow"
                  :popover="{ visibility: 'click' }"
                >
                  <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div
                      class="input-group input-group-dynamic"
                      :class="{ notValid: errors.startDate }"
                    >
                      <input
                        class="form-control ark DesktopTextSmall noBorderRight"
                        aria-label="Start Date"
                        type="text"
                        placeholder="Start Date"
                        :value="inputValue"
                        :disabled="loading"
                        aria-describedby="startDateIcon"
                        aria-errormessage="startDateError"
                        :aria-invalid="!!errors.startDate"
                        :class="{ notValid: errors.startDate }"
                        v-on="inputEvents"
                        @blur="validate"
                      >
                      <span
                        id="startDateIcon"
                        role="button"
                        tabindex="0"
                        class="input-group-text inputIcon pointer"
                        aria-label="Click to open the calendar and select start date"
                        @click="togglePopover()"
                        @keypress.prevent="togglePopover()"
                      >
                        <i
                          class="fa fa-calendar-o fa-lg"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </template>
                </v-date-picker>
                <error-message
                  id="startDateError"
                  :error-condition="!!errors.startDate"
                  :error-message="errors.startDate"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="text-left" :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="endDateInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  End date
                </label>
                <v-date-picker
                  id="endDateInput"
                  v-model="form.endDate"
                  :min-date="minEndDate"
                  :max-date="maxEndDate"
                  :disabled="loading"
                  :model-config="{
                    type: 'string',
                    mask: 'YYYY-MM-DD'
                  }"
                  :masks="{
                    type: 'string',
                    input: 'YYYY-MM-DD'
                  }"
                  :popover="{ visibility: 'click' }"
                  color="yellow"
                >
                  <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div
                      class="input-group input-group-dynamic"
                      :class="{ notValid: errors.endDate }"
                    >
                      <input
                        class="form-control ark DesktopTextSmall noBorderRight"
                        aria-label="End Date"
                        type="text"
                        placeholder="End Date"
                        :value="inputValue"
                        :disabled="loading"
                        aria-describedby="endDateIcon"
                        aria-errormessage="endDateError"
                        :aria-invalid="!!errors.endDate"
                        :class="{ notValid: errors.endDate }"
                        v-on="inputEvents"
                        @blur="validate"
                      >
                      <span
                        id="endDateIcon"
                        role="button"
                        tabindex="0"
                        class="input-group-text inputIcon pointer"
                        aria-label="Click to open the calendar and select end date"
                        @click="togglePopover()"
                        @keypress.prevent="togglePopover()"
                      >
                        <i
                          class="fa fa-calendar-o fa-lg"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                  </template>
                </v-date-picker>
                <error-message
                  :error-condition="!!errors.endDate"
                  :error-message="errors.endDate"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="locationInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  {{ locationLabel }}
                </label>
                <div
                  class="input-group noBorderRight pointer"
                  :class="{ notValid: errors.latitude || errors.longitude }"
                  @click="setLocation"
                  @blur="validate"
                >
                  <input
                    id="locationInput"
                    class="form-control ark DesktopTextSmall noBorderRight"
                    :class="{ notValid: errors.latitude || errors.longitude }"
                    :value="form.locationName || coordinates"
                    disabled
                    :placeholder="locationLabel"
                    aria-errormessage="locationError"
                    :aria-invalid="!!errors.latitude || !!errors.longitude"
                  >
                  <span
                    type="button"
                    tabindex="0"
                    class="input-group-text inputIcon pointer"
                    aria-label="Go to set location view"
                    @click="setLocation"
                    @keypress.prevent="setLocation"
                  >
                    <i class="fal fa-map-marker-alt" aria-hidden="true" />
                  </span>
                </div>
                <error-message
                  id="locationError"
                  :error-condition="!!errors.latitude || !!errors.longitude"
                  :error-message="errors.latitude || errors.longitude"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="emailInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Email *
                </label>
                <input
                  id="emailInput"
                  v-model="form.email"
                  type="email"
                  placeholder="Email *"
                  class="form-control ark DesktopTextSmall mb-2"
                  aria-errormessage="emailError"
                  required
                  :aria-invalid="!!errors.email"
                  :class="{ notValid: errors.email }"
                  @blur="validate"
                >
                <error-message
                  id="emailError"
                  :error-condition="!!errors.email"
                  :error-message="errors.email"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div :class="editMode ? 'col-12' : 'col-12 col-md-6'">
                <label
                  v-if="editMode"
                  for="budgetInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Budget
                </label>
                <input
                  id="budgetInput"
                  v-model="form.budget"
                  type="number"
                  placeholder="Budget"
                  aria-errormessage="budgetError"
                  class="form-control ark DesktopTextSmall mb-2"
                  :aria-invalid="!!errors.budget"
                  :class="{ notValid: errors.budget }"
                  @blur="validate"
                >
                <error-message
                  id="budgetError"
                  :error-condition="!!errors.budget"
                  :error-message="errors.budget"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label
                  v-if="editMode"
                  for="description"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Description *
                </label>
                <section class="news-post-editor">
                  <trix-editor
                    ref="trix"
                    input="description"
                    class="w-100 text-left form-control ark trix-form-control DesktopTextSmall mb-2"
                    rows="5"
                    required
                    placeholder="Description *"
                    aria-describedby="descriptionHint"
                    aria-errormessage="descriptionError"
                    :aria-invalid="!!errors.description"
                    :class="{ notValid: errors.description }"
                    @blur="validate"
                    @trix-change="handleContentChange"
                    @trix-attachment-add="handleAttachmentChanges"
                  />
                  <input id="description" :value="form.description" type="hidden" name="content">
                </section>
                <span
                  id="descriptionHint"
                  class="DesktopTextSmall grayscaleLabel d-block"
                >
                  Use your description to briefly describe what problem you are trying to solve.
                  This can be changed later.
                </span>
                <error-message
                  id="descriptionError"
                  :error-condition="!!errors.description"
                  :error-message="errors.description"
                />
              </div>
            </div>

            <div class="d-flex align-items-center">
              <input
                id="hiddenInput"
                v-model="form.hidden"
                type="checkbox"
              >
              <label class="DesktopTextSmall grayscaleLabel ml-3 mb-0" for="hiddenInput">
                Hide this {{ projectTypeTitle }} from users outside your Organization
              </label>
            </div>

            <div class="row mb-3">
              <div
                class="text-left mt-3"
                :class="editMode ? 'col-12' : 'col-sm-12 col-md-6 col-lg-5'"
              >
                <label
                  v-if="editMode"
                  for="assetClassesInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Asset classes
                </label>
                <Multiselect
                  id="assetClassesInput"
                  v-model="form.selectedAssetClasses"
                  placeholder="Search for asset classes"
                  :options="assetClasses"
                  value-prop="id"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  :disabled="loading"
                  class="multiselect-ark"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div
                class="text-left mt-3"
                :class="editMode ? 'col-12' : 'col-sm-12 col-md-6 col-lg-5'"
              >
                <label
                  v-if="editMode"
                  for="categoriesInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Categories *
                </label>
                <Multiselect
                  id="categoriesInput"
                  v-model="form.selectedCategories"
                  placeholder="Search for categories *"
                  :options="categories"
                  value-prop="id"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  required
                  :disabled="loading"
                  :class="
                    !!errors.selectedCategories
                      ? 'multiselect-ark multiselect-ark-error'
                      : 'multiselect-ark'
                  "
                />
                <error-message
                  :error-condition="!!errors.selectedCategories"
                  :error-message="errors.selectedCategories"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div
                class="text-left mt-3"
                :class="editMode ? 'col-12' : 'col-sm-12 col-md-6 col-lg-5'"
              >
                <label
                  v-if="editMode"
                  for="tagsInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Tags *
                </label>
                <Multiselect
                  id="tagsInput"
                  v-model="form.selectedTags"
                  :placeholder="`Search for ${projectTypeTitle} tags *`"
                  :options="tags"
                  value-prop="id"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  required
                  :disabled="loading"
                  :class="
                    !!errors.selectedTags
                      ? 'multiselect-ark multiselect-ark-error'
                      : 'multiselect-ark'
                  "
                />
                <error-message
                  :error-condition="!!errors.selectedTags"
                  :error-message="errors.selectedTags"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div
                class="text-left mt-3"
                :class="editMode ? 'col-12' : 'col-sm-12 col-md-6 col-lg-5'"
              >
                <label
                  v-if="editMode"
                  for="speciesInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Species
                </label>
                <Multiselect
                  id="speciesInput"
                  v-model="form.selectedSpecies"
                  placeholder="Search for species"
                  :options="species"
                  value-prop="id"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  :disabled="loading"
                  class="multiselect-ark"
                />
              </div>
            </div>
          </fieldset>
        </section>

        <error-message
          class="d-block"
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />

        <div
          class="saveCancelContainer d-flex mb-4"
          :class="editMode ? 'justify-content-end' : 'justify-content-center'"
        >
          <button
            v-if="dataChanged && editMode"
            type="button"
            class="btn DesktopLinkXSmall cancelButton"
            @click="discardChanges()"
          >
            Cancel
          </button>
          <div
            v-if="loading"
            class="ml-2 d-flex justify-content-center"
          >
            <div
              class="
                d-flex
                align-content-center
                spinner-border
                primary2_ark
                align-self-center
              "
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
              Saving
            </p>
          </div>
          <button
            v-else
            class="
              btn ml-2
              DesktopLinkXSmall
              grayscaleOffWhite
              primaryButton
            "
            :disabled="!dataChanged && editMode"
            @click="submit"
          >
            Save
          </button>
        </div>
      </form>
    </section>
  </main>
</template>

<script>
import * as yup from 'yup';
import { DirectUpload } from '@rails/activestorage/src/direct_upload';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { iframely } from '@iframely/embed.js';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  name: yup.string().required(),
  projectType: yup.string(),
  locationName: yup.string(),
  latitude: yup.number()
    .typeError('Location is a required field.')
    .when('projectType', (projectType, schema) => {
      if (projectType === 'ReportingProject') return schema.nullable();
      return schema.required('Location is a required field.');
    }),
  longitude: yup.number()
    .typeError('Location is a required field.')
    .when('projectType', (projectType, schema) => {
      if (projectType === 'ReportingProject') return schema.nullable();
      return schema.required('Location is a required field.');
    }),
  description: yup.string().required(),
  startDate: yup.string().nullable(true),
  endDate: yup.string().nullable(true),
  selectedCategories: yup.array().min(1).required()
    .typeError('Categories is a required field.')
    .required('Categories is a required field.'),
  selectedTags: yup.array().min(1).required()
    .typeError('Project tags is a required field.')
    .required('Project tags is a required field.'),
  hidden: yup.boolean(),
  email: yup.string().required().email(),
  budget: yup.number()
    .notRequired()
    .min(0, 'Number must be non-negative')
    .nullable()
    .transform((value) => value ? value : null)
});

export default {
  components: { ErrorMessage, Multiselect },

  props: {
    image: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    assetClasses: {
      type: Array,
      required: true
    },
    organizationId: {
      type: String,
      required: false,
      default: null
    },
    formInfo: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    },
    projectId: {
      type: Number,
      required: false,
      default: null
    },
    projectType: {
      type: String,
      required: true,
      default: 'ConservationProject'
    },
    location: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['set-location', 'update-project'],
  data() {
    return {
      categories: [],
      species: [],
      savedDescription: '',
      form: {
        name: '',
        locationName: '',
        latitude: null,
        longitude: null,
        country: null,
        countryCode: null,
        city: null,
        zip: null,
        description: '',
        startDate: '',
        endDate: '',
        email: '',
        selectedCategories: [],
        selectedSpecies: [],
        selectedTags: [],
        selectedAssetClasses: [],
        hidden: this.projectType === 'ReportingProject' ? true : false,
        budget: null,
        projectType: null
      },
      errors: {
        name: '',
        locationName: '',
        latitude: '',
        longitude: '',
        description: '',
        startDate: '',
        endDate: '',
        selectedCategories: '',
        selectedTags: '',
        hidden: '',
        email: '',
        budget: ''
      },
      submitted: false,
      loading: false,
      errorMessageFromBackend: ''
    };
  },
  computed: {
    projectTypeTitle() {
      if (this.projectType === 'ConservationProject') return 'project';
      else return 'report';
    },
    locationLabel() {
      return this.projectType === 'ReportingProject' ? 'Location' : 'Location *';
    },
    coordinates() {
      return this.form.latitude && this.form.longitude ?
        `${this.form.latitude}, ${this.form.longitude}` :
        '';
    },
    minStartDate() {
      if (!this.form.endDate) {
        return null;
      }

      const minDate = new Date(this.form.endDate);
      minDate.setFullYear(minDate.getFullYear() - 30);
      return minDate;
    },
    maxEndDate() {
      if (!this.form.startDate) {
        return null;
      }

      const maxDate = new Date(this.form.startDate);
      maxDate.setFullYear(maxDate.getFullYear() + 30);
      return maxDate;
    },
    minEndDate() {
      return this.form.startDate || new Date();
    },
    dataChanged() {
      // location info may have different "empty" values
      const latitudeChanged =
        this.form.latitude != this.formInfo?.latitude &&
        (this.form.latitude !== 0 || this.formInfo?.latitude !== 0);

      const longitudeChanged =
        this.form.longitude != this.formInfo?.longitude &&
        (this.form.longitude !== 0 || this.formInfo?.longitude !== 0);

      return (
        this.form.name !== this.formInfo?.name ||
        this.form.locationName !== this.formInfo?.locationName ||
        latitudeChanged ||
        longitudeChanged ||
        this.form.country !== this.formInfo?.country ||
        this.form.city !== this.formInfo?.city ||
        this.form.zip !== this.formInfo?.zip ||
        this.form.email !== this.formInfo?.email ||
        this.form.hidden !== this.formInfo?.hidden ||
        this.form.description !== this.savedDescription ||
        this.form.startDate !== this.formInfo?.startDate ||
        this.form.endDate !== this.formInfo?.endDate ||
        this.form.budget !== this.formInfo?.budget ||
        !this.equalArrays(
          Object.values(this.form.selectedCategories), this.formInfo.selectedCategories
          ) ||
        !this.equalArrays(
          Object.values(this.form.selectedSpecies), this.formInfo.selectedSpecies
          ) ||
        !this.equalArrays(
          Object.values(this.form.selectedTags), this.formInfo.selectedTags
          ) ||
        !this.equalArrays(
          Object.values(this.form.selectedAssetClasses), this.formInfo.selectedAssetClasses
          )
      );
    }
  },
  updated() {
    iframely.load();
  },
  mounted() {
    this.getCategories();
    this.getSpecies();
    if (this.location) {
      this.form = {...this.form, ...this.formInfo, ...this.location};
    } else {
      this.form = {...this.form, ...this.formInfo};
    }

    this.form.projectType = this.projectType;
    document.querySelector('trix-editor').editor.setSelectedRange([0, 0]);
    document.querySelector('trix-editor').editor.insertHTML(this.form.description);
    document.querySelector('trix-editor').blur();
    this.savedDescription = this.form.description;
  },
  methods: {
    equalArrays(array1, array2) {
      if (array1.length === array2.length) {
        return array1.every((element) => {
          if (array2.includes(element)) {
            return true;
          }
          return false;
        });
      }
      return false;
    },
    getCategories() {
      fallbackApi({
        url: 'categories',
        dataSetter: (data) => this.categories = data
      });
    },
    getSpecies() {
      fallbackApi({
        url: 'species',
        dataSetter: (data) => this.species = data
      });
    },
    setLocation(event) {
      event.stopPropagation();
      this.$emit('set-location', this.form);
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.submitted);
    },
    handleContentChange(event) {
      this.form.description = event.srcElement ? event.srcElement.value : event.target.value;
    },
    handleAttachmentChanges(event) {
      event.stopPropagation();
      const token = document.querySelector("meta[name='api-token']").getAttribute('content');

      //   upload file to remote server
      const railsActiveStorageDirectUploadsUrl = '/api/v1/direct_uploads';
      const upload = new DirectUpload(
        event.attachment.file,
        railsActiveStorageDirectUploadsUrl,
        {
          directUploadWillCreateBlobWithXHR: (xhr) => {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        }
      );

      if (!upload.file) return;

      // create a blob in the remote server and update attachment's attributes to connect it to it
      upload.create((error, blob) => {
        if (error) {
          this.errorMessageFromBackend = error;
        } else {
          this.errorMessageFromBackend = '';
          const url = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
          let attributes = {
            url: url,
            sgid: blob.attachable_sgid
          };
          event.attachment.setAttributes(attributes);
        }
      });
    },
    async submit() {
      this.submitted = true;
      this.loading = true;
      const valid = await this.validate();
      let httpVerb;
      let data;
      let url;

      if (this.editMode) {
        httpVerb = 'patch';
        url = `projects/${this.projectId}`;
        data = {
          title: this.form.name,
          location: this.form.locationName,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          country: this.form.country,
          country_code: this.form.countryCode,
          city: this.form.city,
          zip: this.form.zip,
          hidden: this.form.hidden,
          start_date: this.form.startDate,
          end_date: this.form.endDate,
          tag_ids: this.form.selectedTags,
          category_ids: this.form.selectedCategories,
          species_ids: this.form.selectedSpecies,
          asset_class_ids: this.form.selectedAssetClasses,
          email: this.form.email,
          description: this.form.description,
          budget: this.form.budget
        };
      } else {
        httpVerb = 'post';
        url = 'projects';
        data = {
          title: this.form.name,
          location: this.form.locationName,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          country: this.form.country,
          country_code: this.form.countryCode,
          city: this.form.city,
          zip: this.form.zip,
          hidden: this.form.hidden,
          start_date: this.form.startDate,
          end_date: this.form.endDate,
          tag_ids: this.form.selectedTags,
          category_ids: this.form.selectedCategories,
          species_ids: this.form.selectedSpecies,
          asset_class_ids: this.form.selectedAssetClasses,
          organization_id: this.organizationId,
          email: this.form.email,
          description: this.form.description,
          budget: this.form.budget,
          type: this.projectType
        };
      }

      if (valid) {
        fallbackApi({
          url: url,
          payload: {
            json: {
              project: data
            }
          },
          httpVerb: httpVerb
        })
        .then((data) => {
          this.errorMessageFromBackend = '';
          if (this.editMode) {
            this.$emit('update-project', data.data);
          } else {
            this.$store.dispatch('updateUser', this.$store.auth?.user?.id);
            location.assign(`/projects/${data.data.project.id}`);
          }
        })
        .catch(async (error) => {
          if (error?.error?.response) {
            const errorMessage = await error.error.response.json();
            this.errorMessageFromBackend = errorMessage.error;
          } else {
            this.errorMessageFromBackend = error?.error?.message ||
              'Error creating project.';
          }
        })
        .finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    },
    discardChanges() {
      this.form = {};
      this.form = {...this.form, ...this.formInfo};
      document.querySelector('trix-editor').editor.loadHTML('');
      document.querySelector('trix-editor').editor.setSelectedRange([0, 0]);
      document.querySelector('trix-editor').editor.insertHTML(this.formInfo.description);
      document.querySelector('trix-editor').blur();
      this.validate();
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
body {
  font-family: Poppins, sans-serif;
}

.backNav {
  margin-top: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .backNav {
      margin-top: 10px;
  }
}

.backTag {
  color: #24422B !important;
}

.inputBorder {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

#locationInput {
  pointer-events: none;
}

.inputIcon {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 4px 4px 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.trix-form-control {
  height: auto;
}

</style>
