import UserProjects from './components/UserProjects.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#user-projects')){
    const app = createApp(UserProjects);
    app.use(store);
    app.mount('#user-projects');
  }
});
