<template>
  <div class="reset-password">
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <form class="arkCard text-center textBodyColor py-5 mb-2">
          <div class="text-left">
            <h2 class="DesktopTextMedium mb-3">Choose a password that is hard to guess</h2>
            <span class="DesktopTextSmall">
              Password must include at least 8 characters including at least 1 number and 1 special symbol.
            </span>
            <label
              for="passwordInput"
              class="DesktopTextSmall grayscaleOffBlack sr-only"
            >
              New password *
            </label>
            <input
              id="passwordInput"
              v-model="form.password"
              type="password"
              placeholder="New password"
              class="form-control ark DesktopTextSmall py-4 px-3 mt-2"
              required
              aria-errormessage="passwordError"
              :aria-invalid="!!errors.password"
              :class="{ notValid: errors.password }"
              @blur="validate"
            >
            <error-message
              id="passwordError"
              :error-condition="!!errors.password"
              :error-message="errors.password"
            />
          </div>
          <button
            type="submit"
            class="
              btn
              DesktopLinkXSmall
              primaryButton
              px-5
              mt-5
            "
            :disabled="!form.password || loading"
            @click.prevent="submit"
          >
            Submit
          </button>
          <br>
          <error-message
            :error-condition="errorMessageFromBackend"
            :error-message="errorMessageFromBackend"
          />
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  password: yup.string().required()
    .min(8)
    // Regexp extracted from https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    .matches(
      /(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/g,
      'Password must include at least 1 letter, 1 number and 1 special character'
    )
});

export default {
  components: { ErrorMessage },
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        reset_password_token: '',
        password: ''
      },
      errors: {
        password: ''
      },
      errorMessageFromBackend: ''
    };
  },
  beforeMount() {
    this.getResetToken();
  },
  methods: {
    getResetToken() {
      const data = document.querySelector('#reset-password');
      this.form.reset_password_token = data?.dataset?.resetToken;
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (!valid) return;

      this.loading = true;
      fallbackApi({
        url: 'users/password',
        httpVerb: 'put',
        payload: {
          json: this.form
        },
        resolveData: false
      })
      .then(() => {
        this.errorMessageFromBackend = '';
        window.location.replace('/users/sign_in');
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again later.';
      })
      .finally(() => this.loading = false);
    }
  }
};

</script>

<style scoped>
.reset-password {
  padding-top: 75px;
}

.arkCard {
  padding-left: 15%;
  padding-right: 15%;
}
</style>

