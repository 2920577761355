<template>
  <div>
    <div class="text-center">
      <div
        class="
          card-title
          DesktopLinkMedium
          primaryDefault
          mb-5 mt-5
        "
      >
        {{ firstMessage }}
      </div>
      <button
        v-if="showButton"
        class="
          DesktopLinkSmall
          grayscaleOffWhite
          mb-5
          primaryButton
          pointer
          transition-02
        "
        @click="buttonClick"
      >
        {{ buttonText }}
      </button>
      <p
        class="
          DesktopTextSmall
          grayscaleOffBlack
          pr-5 pl-5 mb-5
        "
      >
        {{ secondMessage }}
      </p>
      <img
        src="https://cdn.theark.co/img/header_photo_filter.png"
        class="mb-5"
        alt="No Data Image"
        style="max-width: 330px;max-height: 200px"
      >
    </div>
  </div>
</template>


<script>
export default {
  props: {
    firstMessage: {
      type: String,
      required: false,
      default: 'You have not added any goals yet'
    },
    secondMessage: {
      type: String,
      required: false,
      default: ''
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Add a goal'
    },
    showButton: {
      type: Boolean,
      required: true
    }
  },
  emits: ['buttonClick'],
  methods: {
    buttonClick() {
      this.$emit('buttonClick');
    }
  }
};
</script>
