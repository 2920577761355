<template>
  <section class="d-flex">
    <figure class="align-self-start d-flex mb-2">
      <img
        v-if="partner.logo"
        :src="partner.logo"
        :class="showAll ? 'logoImage' : 'logoImageMax'"
        :alt="partner.name + 's logo'"
      >
    </figure>
    <article class="pl-4">
      <h3 class="DesktopLinkSmall primaryDefault mb-1 mt-0">
        {{ partner.name }}
      </h3>
      <p class="DesktopTextSmall grayscaleBody mb-2">
        {{ organizationType(partner.type) }}
      </p>
      <div
        v-if="partner.in_ark"
      >
        <span
          class="
            btn
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            follow-unfollow-button
          "
          @click.prevent="
            followUnfollow(partner?.is_following ? 'unfollow' : 'follow', partner?.partneree_id)
          "
        >
          <i
            v-if="partner?.is_following"
            class="far fa-check mr-2"
            aria-hidden="true"
          />
          {{ partner?.is_following ? 'Following': 'Follow' }}
        </span>
      </div>
      <div v-else class="mb-4"></div>
    </article>
  </section>
</template>

<script>
import ShowMore from '../../../../components/shared/ShowMore.vue';
import { fallbackApi } from '../../../../api/config';
import { sharedMixin } from '../../../../mixins';
import PartnerItem from './PartnerItem.vue';

export default {
  components: { ShowMore, PartnerItem },
  mixins: [sharedMixin],
  props: {
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false
    },
    organization: {
      type: Object,
      required: true
    },
    partner: {
      type: Object,
      required: true
    }
  },
  methods: {
    followUnfollow(status, id) {
      fallbackApi({
        url: `${this.modelPluralize}/${id}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        this.partner.is_following = !this.partner.is_following
      })
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/lists.css"></style>