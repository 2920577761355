<template>
  <section class="arkCard">
    <header class="row justify-content-between mb-2">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark mb-0">Attachments</h2>
      </section>
      <section v-if="isAdmin" class="col-3 text-right">
        <a
          class="p-0 m-0 pointer"
          aria-label="Edit attachments section"
          :href="`/${modelPluralize}/${modelId}/edit?tab=attachments`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <ul class="attachmentList row py-2 px-0">
      <li
        v-for="(attachment, i) in attachments"
        :key="`${attachment.filename}-${i}`"
        class="col-6 col-sm-4 col-md-3 mb-2"
      >
        <a
          :href="attachment.url"
          target="_blank"
          rel="noopener noreferrer"
          class="attachmentLink"
          :aria-label="`Open document ${attachment.filename}`"
        >
          <figure class="attachmentContainer" aria-hidden="true">
            <img :src="attachment.preview" :alt="attachment.filename" class="attachmentImage">
            <figcaption class="attachmentCaption MobileTextSmall grayscaleOffBlack">
              <i class="fa fa-file-pdf fa-lg" aria-hidden="true" />
              {{ attachment.filename }}
            </figcaption>
          </figure>
        </a>
      </li>
    </ul>
    <show-more
      v-if="totalAttachments > 4 && !showAll"
      :text="`Show all ${totalAttachments} attachments`"
      :link="`/${modelPluralize}/${modelId}/attachments`"
    />
  </section>
</template>

<script>
import ShowMore from './ShowMore.vue';

export default {
  components: { ShowMore },
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    attachments: {
      type: Object,
      required: true
    },
    totalAttachments: {
      type: Number,
      required: true
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
.mediaIcon {
  font-size: 20px !important;
}

.attachmentList {
  list-style: none;
}

.attachmentImage {
  width: 100%;
  aspect-ratio: 1.7 / 1;
  object-fit: cover;
}

.attachmentCaption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  border-top: 1px solid #CED4DA;
}

.attachmentCaption i {
  color: #FF0000;
  margin: 1px;
}

.attachmentContainer {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #CED4DA;
}

.attachmentLink:hover .attachmentImage, .attachmentLink:focus-visible .attachmentImage {
  filter: brightness(0.7);
}

</style>
