<template>
  <section class="d-flex row arkCardNoPadding">
    <div class="col logocoL">
      <figure>
        <img
          v-if="organization.logo"
          :src="organization.logo"
          class="logoImageMax objectFitContain"
          :alt="organization.name + 's logo'"
        >
      </figure>
    </div>
    <div class="col">
      <a
        class="DesktopLinkSmall primaryDefault mt-0 title-size"
        :href="`/organizations/${organization.id}`"
      >
        {{ organization.name }}
      </a>
      <p class="DesktopTextXSmall grayscaleBody mb-0">
        {{ orgType }}
      </p>
      <p class="MobileTextXSmall grayscaleLabel description-size col-12 col-sm-9 p-0">
        {{ organization.about }}
      </p>
    </div>
  </section>
</template>

<script>
import { sharedMixin } from '../../mixins/sharedMixin.js';

export default {
  props: {
    organization: {
      type: Object,
      required: true
    }
  },
  mixins: [sharedMixin],
  computed: {
    orgType() {
      return this.organizationType(this.organization.type);
    }
  }
};
</script>

<style scoped>
.logocoL {
  flex-grow: inherit;
}

.logoImageMax {
  width: 60px;
  height: 60px;
  border-radius: 3px;
}

.logoImageSmall {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.description-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
</style>

