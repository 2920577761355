<template>
  <avl-modal
    :is-open="modalOpen"
    size="fit-content"
    header-title=""
    @avleventclose="closeModal"
  >
    <div class="DesktopTextMedium p-2">
      Metric reporting frequency changed
    </div>
    <div class="DesktopTextSmall grayscaleBody mt-2 p-3">
      All entries reported under this metric will be deleted. This is a permanent action.
      Are you sure you want to proceed with the update?
    </div>
    <div class="row d-flex justify-content-center mt-3">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-link grayscaleBody ark-bold"
          @click="closeModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link text-danger ark-bold"
          @click="confirm"
        >
          Update
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>

export default {
  name: 'DeleteEntriesModal',
  props: {
    modalOpen: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  emits: ['closeModal', 'confirm'],
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped src="../../stylesheet/forms.css"></style>
