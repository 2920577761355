<template>
  <EmailForm
    v-if="!resetEmail"
    :loading="loading"
    :error-message="errorMessageFromBackend"
    :logoImage="logoImage"
    @submit="submitEmail"
  />
  <CheckInboxPassword
    v-else
    :loading="loading"
    :email="resetEmail"
    @resend-email="sendEmail"
  />
</template>

<script>
import CheckInboxPassword from './CheckInboxPassword.vue';
import EmailForm from './EmailForm.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { EmailForm, CheckInboxPassword },
  data() {
    return {
      logoImage: '',
      loading: false,
      resetEmail: '',
      errorMessageFromBackend: ''
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      const data = document.querySelector('#forgot-password');
      this.logoImage = data?.dataset?.imgLogo;
    },
    submitEmail(email) {
      this.resetEmail = email;
      this.sendEmail();
    },
    sendEmail() {
      this.loading = true;
      fallbackApi({
        url: 'users/reset_password',
        httpVerb: 'post',
        payload: {
          json: {
            email: this.resetEmail
          }
        },
        resolveData: false
      })
      .then(() => {
        this.errorMessageFromBackend = '';
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again later.';
      })
      .finally(() => this.loading = false);
    }
  }
};
</script>
