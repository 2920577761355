import OrganizationSubscriptions from './components/OrganizationSubscriptions.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#subscriptions')){
    const app = createApp(OrganizationSubscriptions);
    app.use(store);
    app.mount('#subscriptions');
  }
});