<template>
  <avl-modal
    :is-open="modalOpen"
    size="700px"
    header-title=""
    @avleventclose="closeModal"
  >
    <header>
      <div class="text-left DesktopLinkSmall grayscaleOffBlack mb-4">
        Add annual seats
      </div>
      <div class="text-left DesktopTextSmall black mb-4">
        Add additional annual seats to your plan if you’re planning ahead as your team grows.
      </div>
    </header>
    <section class="row justify-content-between mb-5 px-3">
      <article>
        <stepper-input v-model.number="seats" :step="1" :min="1" :max="30" />
      </article>
      <article class="text-left">
        <div class="DesktopTextSmall black">THE ARK organization seats.</div>
        <div class="MobileTextSmall grayscaleLabel">{{ priceInfo }}</div>
      </article>
      <span class="MobileTextSmall grayscaleOffBlack">
        € {{ total }}
      </span>
    </section>
    <aside class="text-right mt-5">
      <button
        class="btn btn-link grayscaleBody ark-bold mr-4"
        @click="closeModal"
      >
        Cancel
      </button>
      <button
        class="btn btn-link secondary7_ark ark-bold"
        @click="addSeats"
      >
        Add seats to annual plan
      </button>
    </aside>
  </avl-modal>
</template>

<script>
import { DateTime } from 'luxon';
import StepperInput from '../../../../components/shared/StepperInput.vue';
import { fallbackApi } from '../../../../api/config';

  export default {
    name: 'AddSeatsModal',
    components: {
      StepperInput
    },
    props: {
      modalOpen: {
        type: Boolean,
        required: true
      },
      subscription: {
        type: Object,
        required: true
      }
    },
    emits: ['modalClosed'],
    data() {
      return {
        seats: 1
      };
    },
    computed: {
      total() {
        const price = this.subscription?.type?.seat_price_monthly;
        const months = this.monthsUntilExpiration;
        return this.seats * price * months;
      },
      monthsUntilExpiration() {
        const currentDate = DateTime.now();
        const expiration = DateTime.fromISO(this.subscription?.expired_at);
        return Math.ceil(expiration.diff(currentDate, ['months']).months);
      },
      priceInfo() {
        return `€${Math.trunc(this.subscription?.type?.seat_price_monthly)}
        x
        ${this.seats} seats
        x
        ${this.monthsUntilExpiration} remaining months`;
      }
    },
    methods: {
      closeModal() {
        this.$emit('modalClosed');
      },
      addSeats() {
        fallbackApi({
        url: `subscriptions/${this.subscription?.id}/add_seats`,
        payload: {
          json: {
            seats: this.seats
          }
        },
        httpVerb: 'post'
      })
      .then((response) => {
        this.$emit('modalClosed', response.data.subscription);
      })
      .catch((error) => {
        console.warn(error);
      });
      }
    }
  };
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
