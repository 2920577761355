<template>
  <section v-if="user?.about" class="arkCard">
    <header class="row mb-2">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">About</h2>
      </section>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <a
          aria-label="Edit about section"
          :href="`/users/${user?.id}/edit?tab=details`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <section class="row py-2">
      <article class="col-12">
        <p class="DesktopTextSmall grayscaleBody m-0">
          {{ user?.about }}
        </p>
      </article>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
};
</script>
