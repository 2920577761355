import 'v-calendar/dist/style.css';
import Team from './components/Team.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#model-team')){
    const app = createApp(Team);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#model-team');
  }
});