class SpeciesHelper {
  static renderThreatsChart(threats) {
    var dataThreats = JSON.parse(threats);

    var chartLabels = Object.keys(dataThreats);
    var chartScoreValues = [];
    var pieDataObj = {};
    for (let threat in dataThreats) {
      let scoreArr = dataThreats[threat]['score'].split(" ");
      let score = Number(scoreArr[scoreArr.length - 1]);
      chartScoreValues.push(score);

      let scope = dataThreats[threat]['scope']
      if (scope in pieDataObj) {
        pieDataObj[scope]['threat_num'] += 1;
        pieDataObj[scope]['threats'].push(threat);
      } else {
        pieDataObj[scope] = {
          'threat_num': 1,
          'threats': [threat]
        };
      }
    }

    if (Object.keys(pieDataObj).length != 1 && Object.keys(pieDataObj)[0] != null){
      const pieData = Object.entries(pieDataObj).map((scope, value) => {
        return { name: scope[0], y: scope[1]['threat_num'], threats: scope[1]['threats'] };
      });
      Highcharts.chart('threats_pie', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: 'Threat scope',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.threats}</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>'
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'Threats',
          colorByPoint: true,
          data: pieData
        }]
      });
    }

    Highcharts.chart('threats_chart', {
      chart: {
        type: 'bar'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: 'Impact score'
      },
      xAxis: {
        categories: chartLabels,
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        max: 12,
        title: {
          text: 'Source: <a href="https://www.iucn.org/">IUCN.org</a>',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {},
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          },
          colorByPoint: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        showInLegend: false,
        name: 'Impact score',
        data: chartScoreValues
      },
      ]
    });
  }

  static renderAboutButton(aboutButton) {
    let taxonomic = document.querySelector(".taxonomic");
    let scrollHeight = taxonomic.scrollHeight;
    let currentHeight = taxonomic.offsetHeight;
    let taxonomicButton = document.querySelector("#taxonomic-btn");
    console.log(taxonomicButton.innerHTML);
    if (scrollHeight > currentHeight) {
      aboutButton.addEventListener("click", () => {
        if (taxonomicButton.classList.contains("read-more")) {
          taxonomic.classList.toggle("no-height");
          taxonomicButton.innerHTML = "Read Less";
          taxonomicButton.classList.toggle("read-more");
        } else {
          taxonomic.classList.toggle("no-height");
          taxonomicButton.innerHTML = "Read More";
          taxonomicButton.classList.toggle("read-more");
        }
        return false;
      });
    } else {
      taxonomicButton.classList.remove("read-more");
      taxonomicButton.classList.remove("btn");
      taxonomicButton.classList.remove("btn-info");
      taxonomicButton.innerHTML = "";
    }
  }

  static renderOrganization(organizationButton) {
    organizationButton.addEventListener("click", () => {
      let button = document.querySelector("#organization-btn");
      let moreOrganization = document.querySelector(".more-organizations");
      if (moreOrganization.classList.contains('hide-organization')) {
        moreOrganization.classList.toggle("hide-organization");
        button.innerHTML = "See Less";
      } else {
        moreOrganization.classList.toggle("hide-organization");
        button.innerHTML = "See All";
      }
    });
  }

}

let htmlThreats = document.getElementById('threats_chart');
let datasetThreats = htmlThreats && htmlThreats.dataset.threats;
if (datasetThreats) {
  SpeciesHelper.renderThreatsChart(datasetThreats);
}

let aboutButton = document.getElementById('taxonomic-btn');
if (aboutButton) {
    SpeciesHelper.renderAboutButton(aboutButton);
}

let organizationButton = document.getElementById('organization-btn');
if (organizationButton) {
  SpeciesHelper.renderOrganization(organizationButton);
}
