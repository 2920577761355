<template>
  <section class="arkCardNoPadding">
    <input
      ref="bannerInput"
      type="file"
      :accept="imageMimeTypes.join(', ')"
      hidden
      @input="addBanner"
    >
    <div
      class="coverPhotoContainer"
      :style="{ 'background-image': `url('${organization?.banner}')` }"
      aria-hidden="true"
    />
    <section v-if="isAdmin" class="d-flex justify-content-end">
      <button
        class="btn btn-link p-0 m-0 editCoverPhotoButton"
        aria-label="Edit banner menu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          class="fa fa-pencil edit-icon-pencil grayscaleBody"
          aria-hidden="true"
        />
      </button>
      <div class="d-flex justify-content-end">
        <div class="dropdown-menu dropdown-primary" role="menu">
          <button
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="updateImage('banner')"
          >
            <i class="far fa-camera mr-2" aria-hidden="true" /> Upload banner image
          </button>
          <button
            v-show="organization.banner"
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="openEditImageModal('banner')"
          >
            <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust banner image
          </button>
          <button
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="deleteImage('banner')"
          >
            Delete
          </button>
        </div>
      </div>
    </section>
    <section class="px-4 pt-4">
      <input
        ref="logoInput"
        type="file"
        :accept="imageMimeTypes.join(', ')"
        hidden
        @input="addLogo"
      >
      <div class="logoContainer">
        <img
          v-show="organization.logo"
          :src="organization?.logo"
          class="logoImage"
          :alt="`${organization?.name} logo`"
        >
        <button
          v-if="isAdmin"
          class="btn btn-link p-0 m-0 align-self-end editLogoBtn"
          style="margin-top: -23px !important"
          aria-label="Edit logo menu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </button>
        <div class="d-flex justify-content-end">
          <div class="dropdown-menu dropdown-primary" role="menu">
            <button
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="updateImage('logo')"
            >
              <i class="far fa-camera mr-2" aria-hidden="true" /> Upload logo image
            </button>
            <button
              v-show="organization.logo"
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="openEditImageModal('logo')"
            >
              <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust logo image
            </button>
            <button
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="deleteImage('logo')"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="px-4 pt-3">
      <header>
        <h1 class="DesktopLinkMedium grayscaleOffBlack mb-0">
          {{ organization?.name }}
          <avl-badge
            v-if="organization?.hidden"
            label="Hidden"
            size="medium"
            type="default"
            class="MobileTextXSmall grayscaleBody ml-2"
          />
        </h1>
      </header>
      <section class="d-sm-flex align-items-center grayscaleLabel">
        <h2 class="MobileTextXSmall mb-0">
          {{ organizationType }}
        </h2>
        <svg
          v-if="organization?.city_address"
          class="mx-1 d-none d-sm-block"
          height="2"
          width="2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1" cy="1" r="1" fill="#757789" />
        </svg>
        <h2
          v-if="organization?.city_address !== ''"
          class="MobileTextXSmall mb-0"
        >
          {{ organization?.city_address }}
        </h2>
      </section>
    </section>
    <section class="px-4 py-3 mb-2 d-flex justify-content-between">
      <div>
        <a
          v-if="isAdmin"
          class="btn btn-sm DesktopLinkXSmall primaryButton transition-02 mr-2"
          :href="`/${modelPluralize}/${modelId}/edit`"
        >
          <i
            class="fa fa-pencil grayscaleOffWhite pr-2 align-self-center"
            aria-hidden="true"
          />
          Edit
        </a>
        <button
          v-else
          type="button"
          class="
            DesktopLinkXSmall
            primaryButton
            mr-2
            btn
            btn-sm
          "
          @click="
            followUnfollow(organization?.is_following ? 'unfollow' : 'follow', organization?.id)
          "
        >
          {{ organization?.is_following ? 'Following': 'Follow' }}
        </button>
        <a
          v-if="isAdmin"
          type="button"
          class="btn btn-sm DesktopLinkXSmall borderedButton transition-02 mr-2"
          :href="`/organizations/${organization.id}/subscriptions`"
        >
          Admin console
        </a>
        <button
          v-if="organization?.full_url"
          type="button"
          class="btn btn-sm DesktopLinkXSmall borderedButton transition-02"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          More
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <a
            class="dropdown-item ark-link-2"
            :href="organization?.full_url"
            target="_blank"
          >
            Visit website
          </a>
          <button
            v-if="organization?.can_invest"
            class="dropdown-item ark-link-2"
            @click="
              openCreateContributionFormModal(organization, 'organization', investOrDonateButton)
            "
          >
            {{ investOrDonateButton }}
          </button>
        </div>
      </div>
      <export-options v-if="isAdmin" :model-pluralize="modelPluralize" :model-id="modelId" />
    </section>
    <hr class="p-0 m-0">
    <nav class="ml-3 pt-2px">
      <a
        class="DesktopLinkXSmall pointer transition-02 mr-1 d-inline-block"
        :class="activeTab === 'about' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}`"
      >
        About
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'news' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/news`"
      >
        News
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'projects' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/projects`"
      >
        Projects
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'reports' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/reports`"
      >
        Reports
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'impact' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/impact`"
      >
        Impact
      </a>
      <!-- Hide for now - ESG -->
      <!-- <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'esg' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/esg`"
      >
        ESG Profile
      </a> -->
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'team' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/${modelPluralize}/${modelId}/team`"
      >
        Team
      </a>
    </nav>
  </section>
  <avl-modal
    :is-open="invalidLogoModalOpen"
    size="fit-content"
    header-title=""
    close-button-icon="fa fa-times"
    @avleventclose="invalidLogoModalOpen = false"
  >
    <div class="DesktopTextSmall grayscaleBody mt-2 p-3">
      Invalid image type selected. Please use one of JPG, JPEG, or PNG.
    </div>
  </avl-modal>
  <CreateContributionForm
    :modal-open="createContributionModalOpen"
    :contribution-recipient-id="contributionRecipientId"
    contribution-recipient-type="organization"
    :contribution-recipient-name="contributionRecipientName"
    :contribution-button-text="contributionButtonText"
    @close-modal-event="closeCreateContributionFormModal"
  />
</template>

<script>
import CreateContributionForm from '../../contributions/components/CreateContributionForm.vue';
import ExportOptions from '../../../components/shared/ExportOptions.vue';
import { contributionsMixin } from '../../../mixins';
import { fallbackApi } from '../../../api/config';
import { openDefaultEditor } from '@pqina/pintura';

export default {
  components: { CreateContributionForm, ExportOptions },
  mixins: [contributionsMixin],
  props: {
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    organizationType: {
      type: String,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  emits: ['updateImage', 'setOrganizationFollowing'],
  data() {
    return {
      logo: null,
      banner: null,
      imageMimeTypes: ['image/jpeg', 'image/png'],
      invalidLogoModalOpen: false
    };
  },
  computed: {
    investOrDonateButton() {
      if (this.organization.legal_type?.name === 'Non-profit') {
        return 'Donate';
      } else {
        return 'Invest';
      }
    }
  },
  methods: {
    addLogo() {
      this.logo = this.$refs.logoInput.files[0];
      if (!this.imageMimeTypes.includes(this.logo?.type)) {
        this.invalidLogoModalOpen = true;
        return;
      }

      this.openEditImageModal('logo');
    },
    addBanner() {
      this.banner = this.$refs.bannerInput.files[0];
      if (!this.imageMimeTypes.includes(this.banner?.type)) {
        this.invalidLogoModalOpen = true;
        return;
      }

      this.openEditImageModal('banner');
    },
    updateImage(key) {
      this.$refs[`${key}Input`].click();
    },
    openEditImageModal(key) {
      const image = this[key] || this.organization[key];
      if (!image) return;

      const aspectRatio = key === 'banner' ? 4.95 : 1;

      openDefaultEditor({
        src: image,
        imageCropAspectRatio: aspectRatio,
        utils: ['crop'],
        handleEvent: (type, detail) => {
          if (type === 'process') {
            this.uploadImage(key, detail.dest);
          }
        }
      });
    },
    uploadImage(key, file) {
      const formData = new FormData();
      formData.append(key, file);
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/header_images`,
        payload: {
          body: formData,
          // Remove json content type so browser can set the appropriate formdata content type
          headers: {
            'Content-Type': undefined
          }
        },
        httpVerb: 'post',
        dataSetter: (data) => {
          this.$emit('updateImage', key, data[key]);
          this[key] = null;
        }
      });
    },
    deleteImage(key) {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/header_images`,
        httpVerb: 'delete',
        payload: {
          json: {
            [key]: true
          }
        },
        dataSetter: (data) => {
          const image = data && data[key];
          this.$emit('updateImage', key, image);
        }
      });
    },
    followUnfollow(status, id) {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        this.$emit('setOrganizationFollowing');
      });
    }
  }
};
</script>

<style scoped>
.defaultProfileBuilding {
  font-size: 54px;
}

.logoContainer {
  position: relative;
  width: fit-content;
  margin-top: -100px;
  background-color: white;
  border: 2px solid white;
  border-radius: 0.2rem
}

.logoImage {
  object-fit: contain;
  width: 112px;
  height: 112px;
}

.editLogoBtn {
  position: absolute;
  bottom: -3px;
  right: -18px;
}

.editLogoBtn i {
  background-color: white;
}

.editCoverPhotoButton {
  margin-top: -190px !important;
  padding-right:20px !important;
  height: 40px
}

avl-modal {
  --modal-padding-footer: 25px 0;
}

avl-badge {
  border-radius: 8px;
  font-weight: 500;
  height: 25px;
  text-transform: none;
  font-size: 0.6875rem;
  --default-text-color: #172722;
  --default-color-solid: #EFF0F6;
  align-self: center;
  padding: 3px 6px;
  margin-top: 3px;
}

button.dropdown-item {
  width: calc(100% - 0.625rem);
}
</style>
