<template>
  <div
    id="mapButton"
    class="h-100 d-flex align-items-center justify-content-center imageMapContainer"
  >
    <button
      class="btn goToMapButton DesktopLinkXSmall textTransformNone"
      @click="goToMap"
    >
      Go to map
    </button>
  </div>
</template>

<script>
export default {
  name: 'MapButton',
  props: {
    redirect: {
      type: Boolean,
      required: false,
      default() {
        return true;
      }
    }
  },
  emits: ['goToMap'],
  methods: {
    goToMap() {
      if (this.redirect) {
        window.location = '/map';
      } else {
        this.$emit('goToMap');
      }
    }
  }
};
</script>

<style scoped>
.imageMapContainer {
  /*TODO: fix that asset add */
  background-image: url('https://cdn.theark.co/img/map_photo.png');
  background-size: cover;
  background-position: center;
  border-radius: 6px;
}

.goToMapButton {
  border-radius: 4px;
  background-color: #355159;
}

.goToMapButton:hover {
  background-color: #355159;
}
</style>
