import ApplyForm from './components/ApplyForm.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#apply-form')){
    const app = createApp(ApplyForm);
    app.use(store);
    app.mount('#apply-form');
  }
});
