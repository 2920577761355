OnboardingProjectHelper = class {
  constructor() {}

  setupSubmit() {
    var section = $('#section_type').attr('section') || null;
    // Check if on correct section
    if (section !== 'project'){
      return false;
    }

    var $project__validator = $('#project__form').validate({
      highlight: function(element) {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: function(element) {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
      invalidHandler: function(event, validator) {
        // 'this' refers to the form
        var errors = validator.numberOfInvalids();
        if (errors) {
          var message = errors == 1
            ? 'You missed 1 field. It has been highlighted'
            : 'You missed ' + errors + ' fields. They have been highlighted';
          arkUI.alert.show({
            html: message,
            confirmCallback: function() {
              arkUI.loadingIndicator.hide();
            }
          });
        }
      }
    });
    $('#project__form').submit((e) => {
      arkUI.loadingIndicator.show();
    });
  }
}
