import AllPartners from './components/AllPartners.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#all-partners')){
    const app = createApp(AllPartners);
    app.use(store);
    app.mount('#all-partners');
  }
});