import UseOfFunds from './components/UseOfFunds.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#use-of-funds')){
    const app = createApp(UseOfFunds);
    app.use(store);
    app.mount('#use-of-funds');
  }
});
