<template>
  <div
    id="mapSidebarCard"
    class="sidebarCard fit pointer"
    style="border-radius:2px"
    :class="{ 'hovered-card': hover }"
    @click="onClick(item)"
    @mouseenter="onHover"
    @mouseleave="hover = false"
  >
    <div class="row p-2">
      <div :class="imageSrc ? 'col-7 pr-0': 'col-12'">
        <slot v-if="$slots.title" name="title" />
        <div v-else-if="title" class="row-12 my-2">
          <div class="DesktopLinkSmall secondary7_ark mapSidebarCardTitle">{{ title }}</div>
        </div>
        <div class="row">
          <div v-if="goals" class="col-12 mr-2 d-flex mx-0 justify-content-start mb-3">
            <div class="justify-content-end">
              <avl-badge
                :label="`${goals} goals`"
                class="MobileTextXSmall"
                size="medium"
                type="default"
              />
            </div>
          </div>
          <div class="col-12 grayscaleBody DesktopTextXSmall">{{ getSubtitle }}</div>
          <div v-if="projects || species" class="row-12">
            <div
              class="col-12 align-content-start d-flex my-1 minHeight24"
            >
              <div v-if="projects">
                <div class="warningDarkmode DesktopTextXSmall ellipsis mr-2">
                  {{ `${projects} projects` }}
                </div>
              </div>
              <div v-if="species">
                <div class="warningDarkmode DesktopTextXSmall ellipsis mr-2">
                  {{ `${species} species` }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="imageSrc" class="col-5 d-flex justify-content-end">
        <div class="cropped justify-content-center align-content-center d-flex mr-2">
          <img
            :src="imageSrc"
            :alt="`${title}'s logo or latest image`"
            :class="theme === mapCardThemes.project ? 'objectFitCover': 'objectFitContain'"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapCardThemes } from '../../../domain/enumerationHelper';
import { symbolToString } from '../../../utilities/enumeration';

export default {
  props: {
    imageSrc: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    theme: {
      type: Symbol,
      required: true
    },
    goals: {
      type: Number,
      required: false,
      default: 0
    },
    projects: {
      type: Number,
      required: false,
      default: 0
    },
    species: {
      type: Number,
      required: false,
      default: 0
    },
    item: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
emits: ['hoverCard'],
  data() {
    return {
      hover: false,
      mapCardThemes: mapCardThemes
    };
  },
  computed: {
    getSubtitle() {
      if (this.theme === mapCardThemes.tech_org || this.item.organizationType === 'TechOrg') {
        return 'Technology';
      } else if (this.theme === mapCardThemes.project) {
        return 'Project';
      } else {
        return 'Project Developer';
      }
    },
    imageSourcePresent() {
      const imageSource = (this.imageSrc || '');
      return imageSource.startsWith('http://') || imageSource.startsWith('https://');
    }
  },
  methods: {
    onClick(item) {
      this.$eventBus?.$trigger('travel-to', {
        coordinates: [{
          id: item.id,
          lat: item.lat,
          lng: item.lng,
          type: symbolToString(this.theme)
        }],
        ignoreAssociatedEntities: true,
        source: 'cardClicked'
      });
    },
    onHover() {
      this.hover = true;
      this.$emit('hoverCard', { id: this.item.id, type: symbolToString(this.theme) });
    }
  }
};
</script>

<style scoped>
.card {
  width: 400px;
  height: fit-content;
  margin-top: 5px;
  margin-bottom: 5px;
}

.hovered-card {
  background-color: #F7F7FC;
}

.cropped {
  width: 91px;
  height: 91px;
  overflow: hidden;
  align-items: center;
}

.cropped img {
  height: 91px;
  width: 91px;
}

#mapSidebarCard avl-badge {
  --default-color-solid: #24422B;
  --default-text-color: #fcfcfc;
}

.mapSidebarCardTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
