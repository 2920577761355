<template>
  <div class="apply-form">
    <section class="row justify-content-center mt-3">
      <div class="col-12 col-sm-8 col-xl-6 text-center">
        <header>
          <h1 class="DesktopDisplaySmall pt-5">How do you contribute to impact?</h1>
          <p class="grayscaleOffBlack DesktopTextSmall mt-4 mb-5">
            Connect with wildlife conservation organisations, investors, donors,
            scientists and technology providers. To get started, choose a page type.
          </p>
        </header>
        <main>
          <button
            class="btn btn-link typeButton text-left w-100 d-flex mt-4"
            aria-label="Select Wildlife Organisation Conservationist"
            @click="selectType('Conservationist')"
          >
            <img :src="images?.collaborate" alt="Globe" class="typeImg" aria-hidden="true">
            <div class="ml-3">
              <h2 class="DesktopLinkXSmall warningDarkmode m-0">Conservationist</h2>
              <p class="DesktopTextXSmall grayscaleOffBlack typeText">
                Dedicated to conserving biodiversity, single species, or habitats anywhere in the world
              </p>
            </div>
          </button>
          <button
            class="btn btn-link typeButton text-left w-100 d-flex mt-4"
            aria-label="Select Investor"
            @click="selectType('Donor and Investor')"
          >
            <img :src="images?.invest" alt="Jar" class="typeImg" aria-hidden="true">
            <div class="ml-3">
              <h2 class="DesktopLinkXSmall warningDarkmode m-0">Investor</h2>
              <p class="DesktopTextXSmall grayscaleOffBlack typeText">
                Investors and donors interested in financing conservation impact
              </p>
            </div>
          </button>
          <button
            class="btn btn-link typeButton text-left w-100 d-flex mt-4"
            aria-label="Select Technology provider"
            @click="selectType('Technology Solution')"
          >
            <img :src="images?.data" alt="Data" class="typeImg" aria-hidden="true">
            <div class="ml-3">
              <h2 class="DesktopLinkXSmall warningDarkmode m-0">Technology provider</h2>
              <p class="DesktopTextXSmall grayscaleOffBlack typeText">
                Technology or product providers, R&amp;D specialists
              </p>
            </div>
          </button>
          <button
            class="btn btn-link typeButton text-left w-100 d-flex my-4"
            aria-label="Select Other"
            @click="selectType('Other')"
          >
            <img :src="images?.discover" alt="Discover" class="typeImg" aria-hidden="true">
            <div class="ml-3">
              <h2 class="DesktopLinkXSmall warningDarkmode m-0">Other</h2>
              <p class="DesktopTextXSmall grayscaleOffBlack typeText">
                Journalists, film-makers, photographers, authors, scientists,
                legal counsel or anyone interested in discovering conservation work
              </p>
            </div>
          </button>
        </main>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Object,
      required: true
    }
  },
  emits: ['submit'],
  methods: {
    selectType(type) {
      this.$emit('submit', { user_group: type });
    }
  }
};

</script>

<style scoped>
.typeButton {
  background: #FCFCFC;
  border-radius: 8px;
  border: 1px solid #FCFCFC;
}

.typeButton:hover, .typeButton:focus {
  background: #FCFCFC;
  border: 1px solid #BDBFD2;
}

.typeImg {
  height: 70px;
}

.typeText {
  white-space: pre-line;
}
</style>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.apply-form {
  padding-top: 75px;
}
</style>
