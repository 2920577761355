<template>
  <div class="row">
    <div class="col-lg-5 col-sm-12 mt-3">
      <div class="row justify-content-center">
        <div :id="chartDivId"></div>
      </div>
    </div>
    <div class="col-lg-7 col-sm-12 mt-4">
      <div v-for="(category, index) in paginatedData" :key="category.name">
        <div class="row">
          <div class="col-9">
            <div class="row">
              <div class="col-2">
                <span :style="getColor(getPaginatedIndex(index))" class="dot" />
              </div>
              <div class="col-10 ark-p2">{{ category.name }}</div>
            </div>
          </div>
          <div class="col-3 text-center">{{ category.y }}</div>
        </div>
        <hr class="mx-1">
      </div>
      <div v-if="totalPages > 1" class="row">
        <div class="col-2 text-right">
          <button
            type="button"
            :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
            :disabled="isPreviousPaginationDisabled()"
            aria-label="First page"
            @click="chartPage = 0"
          >
            <i class="fa fa-angle-double-left fa-2x" aria-hidden="true" />
          </button>
        </div>
        <div class="col-2 text-left">
          <button
            type="button"
            :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
            :disabled="isPreviousPaginationDisabled()"
            aria-label="Previous page"
            @click="chartPage--"
          >
            <i class="fa fa-angle-left fa-2x" aria-hidden="true" />
          </button>
        </div>
        <div class="col-4 text-center ark-p2">
          {{ `${chartPage + 1} / ${totalPages}` }}
        </div>
        <div class="col-2 text-right">
          <button
            type="button"
            :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
            :disabled="isNextPaginationDisabled()"
            aria-label="Next page"
            @click="chartPage++"
          >
            <i class="fa fa-angle-right fa-2x" aria-hidden="true" />
          </button>
        </div>
        <div class="col-2 text-left">
          <button
            type="button"
            :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
            :disabled="isNextPaginationDisabled()"
            aria-label="Last page"
            @click="chartPage = totalPages - 1"
          >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chartColors, pieChartTemplate } from '../../domain/constants';
import { enumerate } from '../../utilities/enumeration';

const itemsPerPage = 5;
const template = {
  ...pieChartTemplate,
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: `
      <span style="color:{point.color}">{point.name}</span>
      : <b>{point.percentage:.2f}%</b> of total<br/>
    `
  }
};

const domain = Object.freeze({
  pagination: enumerate(['next', 'previous']),
});

export default {
  props: {
    model: {
      type: String,
      required: false,
      default: ''
    },
    modelId: {
      type: String,
      required: false,
      default: ''
    },
    theData: {
      type: Object,
      required: false,
      default: []
    },
    seriesName: {
      type: String,
      required: false,
      default: ''
    },
    chartDivId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      pieChart: undefined,
      chartPage: 0
    };
  },
  computed: {
    chartData() {
      return this.createFetchedData(this.theData, 'total_iris_count');
    },
    paginatedData() {
      return this.chartData.slice(
        itemsPerPage * this.chartPage,
        itemsPerPage * (this.chartPage + 1)
      );
    },
    totalPages() {
      return Math.ceil(this.chartData.length / itemsPerPage);
    }
  },
  watch: {
    chartData() {
      this.renderHighchart();
    }
  },
  methods: {
    getColor: (index) => `background-color: ${chartColors[index] || '#AAA'}`,

    getPaginatedIndex(currentIndex) {
      return this.chartPage * itemsPerPage + currentIndex;
    },
    isPreviousPaginationDisabled() {
      return !this.chartPage;
    },
    isNextPaginationDisabled() {
      return this.chartPage > this.totalPages - 2;
    },
    createFetchedData(data, totalKey) {
      return Object.entries(data)
        .map((entry) => {
          return {
            name: entry[0],
            y: entry[1],
            percentage: data[totalKey]
              ? entry[1] / data[totalKey]
              : 0
          };
        })
        .sort((i1, i2) => i2.y - i1.y);
    },
    renderHighchart() {
      if (!this.chartData.length) {
        this.pieChart?.destroy();
        this.pieChart = undefined;
        return;
      }
      if (!this.pieChart) {
        this.pieChart = Highcharts.chart(this.chartDivId, template);
      }

      this.pieChart.series[0].remove();
      this.pieChart.addSeries({
        name: this.seriesName,
        data: this.chartData,
        innerSize: '50%'
      });

      this.pieChart.setTitle({
        text: ''
        // ${this.date ? `
        //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
        //     ${DateTime.fromISO(this.date).toFormat('LLL yyyy')}
        //   </div>
        // ` : `
        //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
        //     No End Date
        //   </div>
        // `}
      });

      this.pieChart.reflow();
    }
  }
};
</script>

<style scoped>
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.button {
  color: #777;
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  color: #36533e;
  background-color: #eee;
}

.disabled-button {
  color: #bbb;
  pointer-events: none;
  cursor: default;
}
</style>
