<template>
  <section class="maxWidthInputs">
    <h4 class="grayscaleOffBlack DesktopLinkSmall mt-4">Company headquarters corporate structure</h4>
    <governance-form
      :model-id="modelId"
      :governance="organization.governance_info"
      :loading="loading"
      :country-options="countryOptions"
      :ownership-options="ownershipOptions"
      @save="saveGovernance"
    />
  </section>
  <section class="maxWidthInputs">
    <h4 class="grayscaleOffBlack DesktopLinkSmall">Related entities</h4>
    <button
      v-if="openSubsidiaryIndex === null"
      class="
        btn
        DesktopLinkXSmall
        grayscaleOffWhite
        primaryButton
      "
      @click="addSubsidiary"
    >
      <span>
        <i class="fa fas fa-plus" aria-hidden="true" />
        &nbsp;&nbsp;
        Add entity
      </span>
    </button>
    <ul class="listStyleNone p-0 mt-2">
      <li v-for="(subsidiary, index) in subsidiaries" :key="`${index}-${subsidiary?.id}`">
        <governance-form
          v-if="index === openSubsidiaryIndex"
          :model-id="modelId"
          :governance="subsidiary"
          :loading="loading"
          :country-options="countryOptions"
          :ownership-options="ownershipOptions"
          is-subsidiary
          @save="saveSubsidiary"
          @cancel="closeSubsidiary"
          @delete="deleteSubsidiary(index)"
        />
        <section v-else class="d-flex justify-content-between align-items-start">
          <div>
            <p class="DesktopLinkSmall grayscaleOffBlack m-0">{{ subsidiary.name }}</p>
            <p class="DesktopTextXSmall">{{ subsidiary.country_of_incorporation }}</p>
          </div>
          <button
            class="btn btn-link p-0 m-0"
            aria-label="Edit entity"
            @click="editSubsidiary(index)"
          >
            <i class="fa fa-pencil edit-icon-pencil grayscaleBody" aria-hidden="true" />
          </button>
        </section>
      </li>
    </ul>
  </section>
</template>

<script>
import GovernanceForm from './GovernanceForm.vue';

export default {
  components: { GovernanceForm },
  props: {
    organization: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    countryOptions: {
      type: Array,
      required: true
    },
    ownershipOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['save-organization'],
  data() {
    return {
      subsidiaries: [],
      openSubsidiaryIndex: null,
      loading: false
    };
  },
  watch: {
    organization() {
      this.getOrganizationInfo();
    }
  },
  mounted() {
    this.getOrganizationInfo();
  },
  methods: {
    getOrganizationInfo() {
      this.subsidiaries = [...this.organization.subsidiaries];
    },
    saveGovernance(governance) {
      this.$emit('save-organization', governance);
    },
    editSubsidiary(index) {
      // Remove empty subsidiary if it exists
      if (this.openSubsidiaryIndex && !this.subsidiaries[this.openSubsidiaryIndex]) {
        this.subsidiaries.splice(this.openSubsidiaryIndex, 1);
      }
      this.openSubsidiaryIndex = index;
    },
    addSubsidiary() {
      this.subsidiaries.push(null);
      this.openSubsidiaryIndex = this.subsidiaries.length - 1;
    },
    saveSubsidiary({ governance_info: subsidiary }) {
      this.closeSubsidiary();
      const index = this.subsidiaries.findIndex((sub) => sub?.id === subsidiary.id);
      if (index > -1) {
        this.subsidiaries[index] = subsidiary;
      } else {
        this.subsidiaries.push(subsidiary);
      }
      this.$emit('save-organization', { subsidiaries: this.subsidiaries });
    },
    closeSubsidiary() {
      // Remove empty subsidiary if it exists
      if (this.openSubsidiaryIndex !== null && !this.subsidiaries[this.openSubsidiaryIndex]) {
        this.subsidiaries.splice(this.openSubsidiaryIndex, 1);
      }
      this.openSubsidiaryIndex = null;
    },
    deleteSubsidiary(index) {
      this.subsidiaries.splice(index, 1);
      this.openSubsidiaryIndex = null;
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
