<template>
  <ArkMap
    :hovered-item="hoveredItem"
    @clicked-point="clickedPoint"
    @map-loaded="mapLoading = false"
    @area-changed="areaChanged"
  />
  <div class="container-fluid h-100">
    <MapUI
      v-if="!mapLoading"
      :open-entity-info="entityInfo"
      :visible-area="visibleArea"
      @clicked-button="clickedButton"
      @hover-card="hoverCard"
    />
  </div>
</template>

<script>
import ArkMap from './ArkMap.vue';
import MapUI from './MapUI.vue';
import { mapCardThemes } from '../../../domain/enumerationHelper';

export default {
  name: 'ArkMapContainer',
  components: {
    ArkMap,
    MapUI
  },
  data() {
    return {
      mapLoading: true,
      entityInfo: {},
      hoveredItem: {},
      visibleArea: null
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    clickedButton(which) {},
    clickedPoint(tagId, tagType) {
      this.entityInfo = {
        id: tagId,
        type: mapCardThemes[tagType]
      };
    },
    hoverCard(item) {
      this.hoveredItem = item;
    },
    areaChanged(area) {
      this.visibleArea = area;
    }
  }
};
</script>
