import SubscriptionRequests from './components/SubscriptionRequests.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#subscription_requests')){
    const app = createApp(SubscriptionRequests);
    app.use(store);
    app.mount('#subscription_requests');
  }
});
