import OrganizationEdit from './components/OrganizationEdit.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#edit-organization')){
    const app = createApp(OrganizationEdit);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#edit-organization');
  }
});
