<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10 col-lg-8 ml-4 ml-md-0"
        aria-label="Go back to organization's profile"
      >
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/${modelPluralize}/${modelId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center">
      <section class="col-sm-12 col-md-10 col-lg-8 d-flex align-items-center ml-4 ml-md-0">
        <div class="p-2 d-flex" aria-hidden="true">
          <img
            :src="images?.collaborate"
            class="mr-2 noDataImage"
            alt="An image of the world"
          >
        </div>
        <h2 class="ml-2 mb-0 DesktopTextSmall grayscaleOffBlack">Edit About</h2>
      </section>
    </header>
  </section>
  <section class="row d-flex justify-content-center mx-0">
    <p class="col-sm-12 col-md-10 col-lg-8 mt-3 mb-0 grayscaleLabel DesktopTextXSmall">
      * indicates required
    </p>
  </section>
  <main class="row justify-content-center">
    <article class="col-sm-12 col-md-10 col-lg-8">
      <div v-if="Object.keys(organization).length === 0">
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
      </div>
      <section v-else class="arkCardNoPadding">
        <div class="row mx-0">
          <nav-tabs
            :tab="activeTab"
            :tab-options="tabOptions"
            :tab-header="tabHeader"
            @tab-changed="tabChanged"
          />
          <section class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
            <div v-show="activeTab === 'info'" class="pl-0 pl-xl-4">
              <general-info
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :legal-type-options="legalTypeOptions"
                :organization="organization"
                :organization-location="organizationLocation"
                @set-location="openLocation"
                @save-organization="saveOrganization"
              />
            </div>
            <div v-show="activeTab === 'details'" class="pl-0 pl-xl-4">
              <social-media-video
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :model="organization"
                @save-model="saveOrganization"
              />
            </div>
            <div v-show="activeTab === 'governance'" class="pl-0 pl-xl-4">
              <governance-finance
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :country-options="countryOptions"
                :ownership-options="ownershipOptions"
                :organization="organization"
                @save-organization="saveOrganization"
              />
            </div>
            <div v-show="activeTab === 'attachments'" class="pl-0 pl-xl-4">
              <additional-attachments
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :all-attachments="attachments"
                :total-attachments="totalAttachments"
                @delete-attachment="deleteAttachment"
                @save-model="saveOrganization"
              />
              <div
                v-if="loading && attachments.length > 0"
                class="ml-2 d-flex justify-content-center my-3"
              >
                <div
                  class="
                    d-flex
                    align-content-center
                    spinner-border
                    grayscaleLabel
                    align-self-center
                  "
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div v-show="activeTab === 'partners'" class="pl-0 pl-xl-4">
              <organization-partners
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :organization="organization"
                @delete-partner="deletePartner"
                @save-organization="saveOrganization"
                @update-partner-logo="onUpdatePartnerLogo"
              />
            </div>
            <div v-show="activeTab === 'sites'" class="pl-0 pl-xl-4">
              <model-sites
                :model-id="modelId"
                :model="model"
                :model-pluralize="modelPluralize"
                :refresh-sites="refreshSites"
                @set-location="openLocationSites"
                @upload-file="uploadFile"
              />
            </div>
          </section>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
import AdditionalAttachments from '../../../../components/shared/AdditionalAttachments.vue';
import GeneralInfo from './GeneralInfo.vue';
import GovernanceFinance from './GovernanceFinance.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ModelSites from '../../../../components/shared/ModelSites.vue';
import NavTabs from '../../../../components/shared/NavTabs.vue';
import OrganizationPartners from './OrganizationPartners.vue';
import SocialMediaVideo from '../../../../components/shared/SocialMediaVideo.vue';
import { fallbackApi } from '../../../../api/config';
import { paginationMixin } from '../../../../mixins';

export default {
  components: {
    NavTabs,
    LoadingIndicator,
    GeneralInfo,
    SocialMediaVideo,
    GovernanceFinance,
    OrganizationPartners,
    AdditionalAttachments,
    ModelSites
  },
  mixins: [paginationMixin],
  props: {
    organizationLocation: {
      type: Object,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    images: {
      type: Object,
      required: true
    },
    countryOptions: {
      type: Array,
      required: true
    },
    ownershipOptions: {
      type: Array,
      required: true
    },
    legalTypeOptions: {
      type: Array,
      required: true
    },
    refreshSites: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'set-location',
    'save-organization',
    'delete-attachment',
    'delete-partner',
    'update-partner-logo',
    'set-sites-location',
    'upload-file'
  ],
  data() {
    return {
      tabHeader: 'About',
      activeTab: 'info',
      tabOptions: [
        { alias: 'info', name: 'Basic info' },
        { alias: 'details', name: 'Organisation details' },
        { alias: 'governance', name: 'Governance and finance info' },
        { alias: 'partners', name: 'Partners' },
        { alias: 'attachments', name: 'Additional documentation' },
        { alias: 'sites', name: 'Sites' }
      ],
      totalAttachments: 0,
      attachments: [],
      loading: false,
      page: 1,
      per: 15,
      deletedAttachments: 0
    };
  },
  watch: {
    attachments() {
      if (this.attachments.length <= 5 && this.totalAttachments > 5) {
        this.getAttachments();
      }
    }
  },
  mounted() {
    this.activeTab = new URLSearchParams(window.location.search)?.get('tab') || 'info';
    this.getAttachments();
  },
  methods: {
    tabChanged(tab) {
      this.activeTab = tab;
    },
    saveOrganization(organization) {
      this.page = 1;
      this.per = 15;
      this.getAttachments(true);
      this.$emit('save-organization', organization);
    },
    onUpdatePartnerLogo() {
      this.$emit('update-partner-logo');
    },
    openLocation(form) {
      this.$emit('set-location', form);
    },
    openLocationSites() {
      this.$emit('set-sites-location');
    },
    uploadFile(file) {
      this.$emit('upload-file', file);
    },
    deleteAttachment(id) {
      let attachments;
      attachments = this.attachments.filter((attachment) => {
        return attachment.id !== id;
      });
      this.attachments = attachments;
      this.totalAttachments --;
      this.deletedAttachments ++;
      this.$emit('delete-attachment', id);
    },
    deletePartner(id) {
      this.$emit('delete-partner', id);
    },
    handlePagination() {
      if (this.attachments.length !== this.totalAttachments && !this.loading) {
        if (this.deletedAttachments === 0) this.page ++;
        this.getAttachments();
      }
    },
    getAttachments(newItem = false) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/attachments?page=${this.page}&per=${this.per}`,
        dataSetter: (data) => {
          this.totalAttachments = data.attachments_count;
          if (newItem) {
            this.attachments = this.uniqueItems([...data.attachments]);
          } else {
            this.attachments = this.uniqueItems([...this.attachments, ...data.attachments]);
          }

          if (this.deletedAttachments > 0) {
            this.page ++;
            this.deletedAttachments -= this.per;
            this.getAttachments();
          } else {
            this.loading = false;
            this.deletedAttachments = 0;
          }

        }
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}

.noDataImage {
  width: 48px;
  height: 45px;
}
</style>
