<template>
  <div class="d-flex">
    <sites-sidebar :model="model" />
    <sites-map :model="model" />
  </div>
</template>

<script>
import SitesMap from './SitesMap.vue';
import SitesSidebar from './SitesSidebar.vue';
import { fallbackApi } from '../../../api/config';

export default {
  name: 'SitesMapContainer',
  components: { SitesMap, SitesSidebar },
  data() {
    return {
      modelPluralize: '',
      modelId: null,
      modelTitle: null,
      sites: [],
      model: {},
      loading: true,
      error: null
    };
  },
  beforeMount() {
    this.getModelInfo();
  },
  mounted() {
    this.fetchSites();
  },
  methods: {
    getModelInfo() {
      const info = document.querySelector('#sites-map');
      this.modelPluralize = info?.dataset.modelPluralize;
      this.modelId = info?.dataset.modelId;
      this.modelTitle = info?.dataset.modelTitle;
    },
    fetchSites() {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sites`,
        dataSetter: async (data) => {
          this.model = data;
          this.$eventBus.$trigger('load-sites', this.model);
        },
        errorSetter: (error) => this.error = error
      }).finally(() => this.loading = false);
    }
  }
};
</script>
