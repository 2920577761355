<template>
  <div id="riskCard" class="arkCard">
    <div class="row">
      <div class="col-9">
        <div class="DesktopTextMedium secondary7_ark">Risks</div>
      </div>
      <div class="col-3">
        <div v-if="isAdmin" class="d-flex justify-content-end">
          <a
            class="btn btn-link p-0 m-0"
            aria-label="Add project risks"
            :href="`/projects/${entityId}/risks/new`"
          >
            <i
              class="far fa-plus edit-icon"
              style="font-size: 1.5em"
              aria-hidden="true"
            />
          </a>
        </div>
      </div>
    </div>
    <no-data-card
      v-if="risks.length === 0"
      :first-message="
        isAdmin ?
          'You haven’t added any risks yet' :
          'No risks have been added yet'
      "
      :second-message="
        isAdmin ?
          'Assess the likelihood that impact will be different than expected.' :
          `Risks that the organisation shares on assessing that the likelihood
          of their impact will be different than expected will be displayed here`
      "
      button-text="Add a risk"
      :show-button="isAdmin"
      @button-click="createRisk"
    />
    <div v-else>
      <div
        v-for="risk in risks"
        :key="risk.id"
        class="mb-2 my-3"
      >
        <div v-if="risk.name" class="row">
          <div class="col-7 col-sm-8 col-md-9 pr-0">
            <div class="d-flex flex-row">
              <button
                class="DesktopTextSmall riskTitle btn btn-link p-0 m-0 normal-text"
                @click="showRiskInfo(risk)"
              >
                {{ risk.name }}
              </button>
            </div>
          </div>
          <div class="col-3 col-sm-3 col-md-2 pr-0">
            <div class="p-1 text-right">
              <avl-badge
                :label="capitalizeFirstLetter(risk.score)"
                size="medium"
                type="default"
                class="MobileTextXSmall"
                :style="defineBadgeColors(risk.score_weight)"
              />
            </div>
          </div>
          <div class="col-2 col-sm-1 col-md-1">
            <div v-if="isAdmin" class="d-flex justify-content-end">
              <button
                class="btn btn-link p-0 m-0"
                data-toggle="dropdown"
                aria-label="Add project's risks"
              >
                <i
                  class="far fa-ellipsis-h edit-icon"
                  style="font-size: 1.5em"
                  aria-hidden="true"
                />
              </button>
              <div class="d-flex justify-content-end">
                <div class="dropdown-menu dropdown-primary">
                  <button
                    class="dropdown-item w-100 m-0"
                    @click="goToUpdateRisk(risk.id)"
                  >
                    Edit risk
                  </button>
                  <button
                    class="dropdown-item w-100 m-0"
                    @click="setDeleteRiskModal(risk, true)"
                  >
                    Delete risk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-12">
              <div class="p-0">
                <span class="MobileTextXSmall grayscaleLabel">
                  {{ risk.risk_type }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <avl-modal
      :is-open="deleteRiskModalOpen"
      header-title=""
      @avleventclose="setDeleteRiskModal"
    >
      <div v-if="targetRisk?.name" class="delete-modal-title mt-3">
        {{ `Delete "${targetRisk.name || ""}" ?` }}
      </div>
      <div class="delete-modal-body mt-3">
        Are you sure you want to delete this risk?
      </div>
      <div class="row d-flex justify-content-center mt-3">
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
            @click="setDeleteRiskModal()"
          >
            <div class="cancel-button">Cancel</div>
          </button>
          <button
            type="button"
            class="btn btn-secondary7-ark no-shadow mx-1 mb-0 normal-text"
            @click="() => deleteRisk(targetRisk?.id)"
          >
            <div class="delete-button">Delete</div>
          </button>
        </div>
      </div>
    </avl-modal>
    <info-modal
      v-if="riskInfoModalOpen"
      :info-modal-open="riskInfoModalOpen"
      :info="{ name: targetRisk.name, description: targetRisk.potential_risk_effect_description }"
      :details="{
        options: ['Severity', 'Likelihood', 'Score'],
        badges: [
          { data: [targetRisk.severity] },
          { data: [targetRisk.likelihood] },
          { data: [targetRisk.score] }
        ]
      }"
      @modal-closed-event="modalRiskInfoClosed"
    >
      <template #body>
        <div class="row DesktopLinkXSmall grayscaleLabel pr-2 py-2 text-left">Mitigation strategies</div>
        <div class="row DesktopTextSmall black pr-2 py-3 text-left">
          {{ targetRisk.mitigation_measures_description }}
        </div>
        <div
          v-if="targetRisk.goals.length !== 0"
          class="row DesktopLinkXSmall grayscaleLabel pr-2 py-2 text-left"
        >
          Goals
        </div>
        <div v-if="targetRisk.goals">
          <div
            v-for="goal in targetRisk.goals"
            :key="goal.id"
            class="row DesktopLinkSmall primaryDark pr-2 py-2 text-left"
          >
            {{ goal.name }}
          </div>
        </div>
      </template>
    </info-modal>
  </div>
</template>

<script>
import NoDataCard from '../../../../components/shared/NoDataCard.vue';
import { defineAsyncComponent } from 'vue';
import { fallbackApi } from '../../../../api/config';
import isAdminBase from '../../../../utilities/authentication';

export default {
  components: {
    InfoModal: defineAsyncComponent(() => import('../../../../components/shared/InfoModal.vue')),
    NoDataCard
  },
  props: {
    entityId: {
      type: String,
      required: true
    },
    risks: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['risksChanged'],
  data() {
    return {
      deleteRiskModalOpen: false,
      riskInfoModalOpen: false,
      targetRisk: undefined
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: 'project',
        entityId: this.entityId
      });
    }
  },
  methods: {
    createRisk() {
      location.href = 'risks/new';
    },
    goToUpdateRisk(id) {
      location.href = `risks/${id}/edit`;
    },
    defineBadgeColors(score) {
      const colors = [
        '#e0f3dc',
        '#f0eea280',
        '#ffdbbd',
        '#c43a4980',
        '#61637480'
      ];
      return `--default-color-solid: ${colors[score-1]};`;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setDeleteRiskModal(risk, open = false) {
      this.deleteRiskModalOpen = open;
      if (!risk) this.targetRisk = undefined;
      else this.targetRisk = { id: risk?.id, name: risk?.name };
    },
    deleteRisk(id) {
      fallbackApi({
        url: `risks/${id}`,
        httpVerb: 'delete'
      })
        .then(() => this.$emit('risksChanged', id))
        .catch((error) => {
          console.warn(error);
          Sentry && Sentry.captureException(error);
        })
        .finally(() => this.setDeleteRiskModal());
    },
    showRiskInfo(risk) {
      this.targetRisk = risk;
      this.riskInfoModalOpen = true;
    },
    modalRiskInfoClosed() {
      this.riskInfoModalOpen = false;
      this.targetRisk = undefined;
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped>
#riskCard avl-badge {
  border-radius: 8px;
  font-weight: 500;
  padding: 3px 8px;
  font-size: 13px;
  height: auto;
  --default-text-color: #172722;
}

#riskCard .riskTitle {
  color: #172722 !important;
  white-space: normal;
  text-align: left;
}
</style>
