<template>
  <section class="maxWidthInputs">
    <form>
      <section class="text-left mt-4">
        <h3 class="grayscaleOffBlack DesktopLinkSmall">Invite team members</h3>
        <fieldset>
          <legend class="sr-only">Invite team members:</legend>
          <div>
            <Multiselect
              v-model="emails"
              placeholder="Add team member email addresses"
              mode="tags"
              track-by="name"
              label="name"
              required
              :disabled="loading"
              :create-option="true"
              :searchable="true"
              class="multiselect-ark"
              @select="addMember"
            />
            <error-message
              class="saveCancelContainer d-flex justify-content-end"
              :error-condition="!!errorMessage"
              :error-message="errorMessage"
            />
          </div>
          <p class="mt-2 DesktopTextXSmall grayscaleLabel">
            Add emails to invite them to join the platform.
          </p>
          <!-- FRONTEND for select a role -->
          <!-- <Multiselect
            v-model="role"
            placeholder="Role"
            :options="userRolesExtended"
            mode="single"
            :close-on-select="true"
            :searchable="true"
            track-by="name"
            label="name"
            :disabled="loading"
            class="multiselect-ark"
            :can-clear="false"
          >
            <template #option="{ option }">
              <div class="options-container">
                <div class="DesktopLinkSmall">{{ option.name }}</div>
                <div class="DesktopTextSmall">{{ option.description }}</div>
              </div>
            </template>
          </Multiselect> -->
        </fieldset>
      </section>
    </form>
    <error-message
      class="saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { sharedMixin } from '../../../../mixins';

const ValidationSchema = yup.object({
  email: yup.string().required().email()
});

export default {
  components: { ErrorMessage, Multiselect },
  mixins: [sharedMixin],
  props: {
    organization: {
      type: Object,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  emits: ['new-invited-members'],
  data() {
    return {
      loading: false,
      emails: [],
      role: '',
      userRolesExtended: [
        {
          value: 'Basic',
          name: 'Basic',
          description: 'Only able to view the page as a regular visitor.'
        },
        {
          value: 'Administrator',
          name: 'Administrator',
          description: "Can post updates or manage the page's content."
        }
      ],
      errorMessageFromBackend: '',
      errorMessage: ''
    };
  },
  computed: {
    dataChanged() {
      return this.emails.length !== 0;
    }
  },
  methods: {
    addMember(member) {
      ValidationSchema.validate({email: member})
      .then(() => this.errorMessage = '')
      .catch(() => {
        this.errorMessage = 'Enter only valid emails i.e. example@example.exaample.';
        this.emails.pop();
      });
    },
    //** Send the emails to backend
    submit() {
      if (this.emails.length !== 0) {
        this.loading = true;
        fallbackApi({
          url: 'invitations',
          payload: { json: { emails: this.emails, organization_id: this.modelId }},
          httpVerb: 'post'
        })
        .then((response) => {
          if (response.data.message) {
            this.errorMessageFromBackend = response.data.message;
            this.$emit('new-invited-members', response.data, 'invite');
          } else {
            this.errorMessageFromBackend = '';
            this.emails = [];
            this.$emit('new-invited-members', response.data);
          }
        })
        .catch(async (error) => {
          if (error?.error?.response) {
            const errorMessage = await error.error.response.json();
            this.errorMessageFromBackend = errorMessage.error;
          } else {
            this.errorMessageFromBackend = error?.error?.message ||
              'Something went wrong. Please try again.';
          }
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
    discardChanges() {
      this.emails = [];
      this.errorMessage = '';
      this.errorMessageFromBackend = '';
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/lists.css"></style>
<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.options-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.options-container div {
  flex: 1;
}
</style>