<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Profile basic info:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Basic info</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="firstNameInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                First name *
              </label>
              <input
                id="firstNameInput"
                v-model="form.firstName"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="firstNameError"
                :aria-invalid="!!errors.firstName"
                :class="{ notValid: errors.firstName }"
                @blur="validate"
              >
              <error-message
                id="firstNameError"
                :error-condition="!!errors.firstName"
                :error-message="errors.firstName"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="lastNameInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Last name *
              </label>
              <input
                id="lastNameInput"
                v-model="form.lastName"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="lastNameError"
                :aria-invalid="!!errors.lastName"
                :class="{ notValid: errors.lastName }"
                @blur="validate"
              >
              <error-message
                id="lastNameError"
                :error-condition="!!errors.lastName"
                :error-message="errors.lastName"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="userGroupInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                User group *
              </label>
              <Multiselect
                id="userGroupInput"
                v-model="form.userGroup"
                placeholder="Select one"
                :options="userGroupOptions"
                :close-on-select="true"
                :searchable="true"
                required
                label="name"
                value-prop="id"
                class="multiselect-ark"
                aria-errormessage="userGroupError"
                :aria-invalid="!!errors.userGroup"
                :class="{ 'multiselect-ark-error': errors.userGroup }"
                @blur="validate"
              />
              <error-message
                id="userGroupError"
                :error-condition="!!errors.userGroup"
                :error-message="errors.userGroup"
              />
            </div>
          </div>
        </fieldset>
      </section>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Profile contact info:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Contact info</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="emailInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Email *
              </label>
              <input
                id="emailInput"
                v-model="form.email"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="emailError"
                :aria-invalid="!!errors.email"
                :class="{ notValid: errors.email }"
                @blur="validate"
              >
              <error-message
                id="emailError"
                :error-condition="!!errors.email"
                :error-message="errors.email"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="phoneNumberInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Phone number *
              </label>
              <input
                id="phoneNumberInput"
                v-model="form.phoneNumber"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="phoneNumberError"
                :aria-invalid="!!errors.phoneNumber"
                :class="{ notValid: errors.phoneNumber }"
                @blur="validate"
              >
              <error-message
                id="phoneNumberError"
                :error-condition="!!errors.phoneNumber"
                :error-message="errors.phoneNumber"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="websiteInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Website
              </label>
              <input
                id="websiteInput"
                v-model="form.website"
                type="text"
                class="form-control ark DesktopTextSmall"
                aria-errormessage="websiteError"
                :aria-invalid="!!errors.website"
                :class="{ notValid: errors.website }"
                @blur="validate"
              >
              <error-message
                id="websiteError"
                :error-condition="!!errors.website"
                :error-message="errors.website"
              />
            </div>
          </div>
        </fieldset>
      </section>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Profile social links:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Social links</h4>
          <div
            v-for="item in formSocialMedia"
            :key="'social ' + item.name"
            class="row"
          >
            <div class="col-12 col-sm-6 col-md-4 py-2">
              <Multiselect
                v-model="item.name"
                placeholder="Type"
                :options="availableOptions"
                :close-on-select="true"
                :searchable="true"
                required
                label="value"
                :disabled="loading"
                class="mb-2"
                :class="
                  showErrors && !item.name
                    ? 'multiselect-ark multiselect-ark-error'
                    : 'multiselect-ark'
                "
                @clear="deleteRow(item)"
              />
              <error-message
                :error-condition="showErrors && !item.name"
                error-message="Select an option"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-8 py-2">
              <input
                :id="item.name"
                v-model="item.value"
                type="url"
                class="form-control ark DesktopTextSmall mb-2"
                placeholder="URL"
                :aria-errormessage="item.name + 'Error'"
                :aria-invalid="!!errorsSocialMedia[item.name]"
                :class="{ notValid: errorsSocialMedia[item.name]}"
                @blur="validate"
              >
              <error-message
                :id="item.name + 'Error'"
                :error-condition="!!errorsSocialMedia[item.name]"
                :error-message="errorsSocialMedia[item.name]"
              />
            </div>
          </div>
          <div
            v-if="lastSocialLink(formSocialMedia, 2)"
            class="col-12"
          >
            <button
              class="
                btn
                secondaryButton
                DesktopLinkXSmall
                primaryDefault
                boxShadowNone
                px-0
              "
              @click="addSocialLink"
            >
              <i
                class="fas fa-plus"
                aria-hidden="true"
              />&nbsp;&nbsp;&nbsp; Add social link
            </button>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';
import { socialMediaLinkInputsMixin, sharedMixin } from '../../../../mixins';
import { ValidationSchemaUserBasicInfo, ValidationSchemaSocialMedia } from '../../../../constants/validationSchemas';

export default {
  components: { ErrorMessage, Multiselect },
  mixins: [ socialMediaLinkInputsMixin, sharedMixin ],
  props: {
    user: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    userGroupOptions: {
      type: Array,
      required: true
    },
  },
  emits: ['save-user'],
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        firstName: '',
        lastName: '',
        userGroup: '',
        email: '',
        phoneNumber: '',
        website: '',
      },
      formSocialMedia: [{ name: '', value: '' }],
      socialMediaOptions: [
        { value: 'Linkedin', disabled: false },
        { value: 'Twitter', disabled: false },
      ],
      errors: {
        firstName: '',
        lastName: '',
        userGroup: '',
        email: '',
        phoneNumber: '',
        website: '',
      },
      errorsSocialMedia: {
        Linkedin: '',
        Twitter: '',
      },
      errorMessageFromBackend: ''
    };
  },
  computed: {
    dataChanged() {
      return (
        this.form.firstName !== this.user?.user.first_name ||
        this.form.lastName !== this.user?.user.last_name ||
        this.form.userGroup !== this.user?.user.user_group ||
        this.form.email !== this.user?.user.email ||
        this.form.phoneNumber !== this.user?.user.phone_number ||
        this.form.website !== this.user?.user.website_url ||
        JSON.stringify(this.formSocialMedia) !==
        JSON.stringify(
          this.convertSocialMediaData(this.user?.user.social_media_links || [])
        )
      );
    },
    availableOptions() {
      return this.socialMediaOptions.map((option) => {
        let isSocialSelected = this.formSocialMedia.filter((item) => {
            return item.name === option.value;
          });

        return {
          value: option.value,
          disabled: isSocialSelected?.length !== 0
        };
      });
    },
  },
  watch: {
    user() {
      this.getUserInfo();
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //** Show existing user data
    getUserInfo() {
      this.form = {
        firstName: this.user?.user.first_name,
        lastName: this.user?.user.last_name,
        userGroup: this.user?.user.user_group,
        email: this.user?.user.email,
        phoneNumber: this.user?.user.phone_number,
        website: this.user?.user.website_url
      };
      this.formSocialMedia =
        this.convertSocialMediaData(this.user?.user.social_media_links || []);
    },
    async validate() {
      const validationPartA = validateForm(this.form, this.errors, ValidationSchemaUserBasicInfo, this.showErrors);
      const validationPartB = validateForm(this.formSocialMedia, this.errorsSocialMedia, ValidationSchemaSocialMedia, this.showErrors)
      const [resultA, resultB] = await Promise.all([validationPartA, validationPartB]);
      const areBothTrue = resultA && resultB;
      return areBothTrue;
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/basic_info`,
          payload: {
            json:
              {
                user: {
                  first_name: this.form.firstName,
                  last_name: this.form.lastName,
                  user_group: this.form.userGroup,
                  email: this.form.email,
                  phone_number: this.form.phoneNumber,
                  website_url: this.addHttpProtocol(this.form.website),
                  social_media_links: this.addHttpProtocol(this.requestBody(this.formSocialMedia))
                }
              }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.$emit('save-user', response.data.user);
          this.showErrors = false;
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
    //** Add social media link row w/ select and input
    addSocialLink() {
      this.formSocialMedia =
        [ ...this.formSocialMedia, {name: '', value: ''} ];
    },
    //** Remove social media link row w/ select and input
    deleteRow(item) {
      this.formSocialMedia.splice(
        this.formSocialMedia.findIndex((a) => a.name === item.name) , 1
      );
    },
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
