<template>
  <section>
    <div v-if="loadingUserInfo">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <user-header
        :user="user"
        @follow-unfollow="followUnfollow"
        @update-image="updateHeaderImage"
      />
    </div>
    <div v-if="loading && news.length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <ark-posts-list
        :current-user-profile="currentUserProfile"
        :user-name="userName || ''"
        :no-posts="news.length === 0"
        :back-link="`/users/${userId}`"
        @start-post="startUserPost"
      >
        <template #body>
          <button
            :class="tab === 'allActivity' ? 'primaryButton' : 'borderedButton'"
            class="btn btn-sm DesktopLinkXSmall transition-02 btn-round"
            @click="tab = 'allActivity'"
          >
            All activity
          </button>
          <button
            :class="tab === 'posts' ? 'primaryButton' : 'borderedButton'"
            class="btn btn-sm DesktopLinkXSmall transition-02 btn-round"
            @click="tab = 'posts'"
          >
            Posts
          </button>
        </template>
      </ark-posts-list>
      <section
        v-if="visibleNews.length === 0 && !loading"
        class="arkCard"
      >
        <no-data-card
          first-message="Nothing to see for now"
          :second-message="
            currentUserProfile ?
              'Posts that you share will be displayed here.' :
              `Posts that ${userName} shares will be displayed here.`
          "
          :show-button="false"
        />
      </section>
      <ark-post
        v-for="post in visibleNews"
        :key="post.id"
        :post="post"
        :author="{
          logo: post.user.avatar,
          name: post.user.name,
        }"
        :is-post-admin="currentUserProfile"
        @post-deleted="postDeleted"
        @edit-post="updatePost"
        @add-comment="addComment"
        @edit-comment="editComment"
        @comment-deleted="commentRemoved"
        @share-post="sharingPost"
        @like-unlike-post-or-comment="likeUnlikePostOrComment"
      />
      <div
        v-if="loading && visibleNews.length > 0"
        class="ml-2 d-flex justify-content-center my-3"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            grayscaleLabel
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <create-post
        :show-add-post-input="false"
        :edit-post="editPost"
        :share-post="sharePost"
        :author="{
          logo: user.avatar,
          name: user?.user?.full_name,
        }"
        :open-modal="userNewPostOpenModal"
        @post-created="newPost"
        @post-deleted="postDeleted"
        @post-updated="updateTopNews"
        @comment-created="newCommentCreated"
        @comment-updated="updateCommentsContent"
        @modal-closed="createUpdatePostModalClosed"
      />
    </div>
  </section>
</template>

<script>
import { paginationMixin, postsMixin, userMixin } from '../../../../mixins';
import ArkPost from '../../../../components/listItems/ArkPost.vue';
import ArkPostsList from './../../usersProfile/components/ArkPostsList.vue';
import CreatePost from '../../../../components/shared/CreatePost.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../../components/shared/NoDataCard.vue';
import UserHeader from '../../sharedComponents/UserHeader.vue';
import { fallbackApi } from '../../../../api/config';
import { iframely } from '@iframely/embed.js';
import { nextTick } from 'vue';

export default {
  components: { LoadingIndicator, ArkPostsList, CreatePost, ArkPost, NoDataCard, UserHeader },
  mixins: [postsMixin, paginationMixin, userMixin],
  data() {
    return {
      newsPosts: [],
      totalNews: 0,
      totalNewsPosts: 0,
      currentPageNews: 1,
      currentPageNewsPosts: 1,
      page: 1,
      per: 10,
      deletedPosts: 0,
      includeSystemPosts: true,
      userId: '',
      userName: '',
      currentUserId: '',
      tab: 'allActivity',
      loading: false,
      userNewPostOpenModal: false
    };
  },
  computed: {
    currentUserProfile() {
      return this.userId == this.currentUserId;
    },
    visibleNews() {
      return this.includeSystemPosts ? this.news : this.newsPosts;
    }
  },
  watch: {
    tab() {
      if (this.tab === 'posts' && this.newsPosts.length === 0) {
        this.page = 1;
        this.includeSystemPosts = false;
        this.getTopNews();
      } else if (this.tab === 'posts') {
        this.includeSystemPosts = false;
        this.page = this.currentPageNewsPosts;
      } else {
        this.includeSystemPosts = true;
        this.page = this.currentPageNews;
      }
      this.deletedPosts = 0;
    },
    news() {
      if (this.news.length <= 5 && this.totalNews > 5 && this.includeSystemPosts) {
        this.getTopNews();
      }
    },
    newsPosts() {
      if (
        this.newsPosts.length <= 5 &&
        this.totalNewsPosts > 5 &&
        !this.includeSystemPosts
      ) {
        this.getTopNews();
      }
    }
  },
  beforeMount() {
    this.getModel();
    this.currentUserId = this.$store.state.auth.user.id;
  },
  mounted() {
    this.getTopNews();
  },
  methods: {
    handlePagination() {
      if (
        (
          this.news.length !== this.totalNews &&
          !this.loading && this.includeSystemPosts
        ) || (
          this.newsPosts.length !== this.totalNewsPosts &&
          !this.loading && !this.includeSystemPosts
        )
      ) {
        if (this.deletedPosts === 0) this.page ++;
        this.getTopNews();
      }
    },
    getModel() {
      const modelInfo = document.querySelector('#user-activity');
      this.userId = modelInfo?.dataset.userId;
      this.userName = modelInfo?.dataset.userName;
    },
    getTopNews() {
      this.loading = true;
      fallbackApi({
        url:
          `users/${this.userId}/top_news?page=${this.page}&per=${this.per}&system=${
            this.includeSystemPosts
          }`,
        dataSetter: (data) => {
          if (this.includeSystemPosts) {
            this.currentPageNews = data.top_news_current_page;
            this.totalNews = data.top_news_count;
            this.news = this.uniqueItems([...this.news, ...data.top_news]);
          } else {
            this.currentPageNewsPosts = data.top_news_current_page;
            this.totalNewsPosts = data.top_news_count;
            this.newsPosts = this.uniqueItems([...this.newsPosts, ...data.top_news]);
          }

          if (this.deletedPosts > 0) {
            this.page ++;
            this.deletedPosts -= this.per;
            this.getTopNews();
          } else {
            this.loading = false;
            this.deletedPosts = 0;
          }

          nextTick(() => {
            window.TopNewsHelper.setupPostButtons();
          });
          iframely.load();
        }
      });
    },
    setCardClass(index, maxLength) {
      return (
        index + 1 === maxLength ?
        'arkCardNoPadding py-2' :
        'arkCardNoPadding bottomLine py-2'
      );
    },
    startUserPost() {
      this.userNewPostOpenModal = true;
    },
    newPost(post) {
      this.sharePost = {};
      if (post.user_id != this.userId) return;
      this.news = [structuredClone(post), ...this.news];
      this.totalNews ++;
      if (this.newsPosts.length !== 0) {
        this.newsPosts = [structuredClone(post), ...this.newsPosts];
        this.totalNewsPosts ++;
      }
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    postDeleted(postId) {
      let news;
      news = this.news.filter((post) => {
        return post.id !== postId;
      });

      this.news = news;
      this.totalNews --;

      if (this.newsPosts.length !== 0) {
        let newsPosts;
        newsPosts = this.newsPosts.filter((post) => {
            return post.id !== postId;
        });

        this.newsPosts = newsPosts;
        this.totalNewsPosts --;
      }
      this.deletedPosts ++;
    },
    updateTopNews(updatedPost) {
      const news = this.news.find((post) => post.id === this.editPost.id);
      news.content = updatedPost.content;
      news.url_attachment = updatedPost.url_attachment;

      if (this.newsPosts.length !== 0) {
        const newsPosts = this.newsPosts.find((post) => post.id === this.editPost.id);
        newsPosts.content = updatedPost.content;
        newsPosts.url_attachment = updatedPost.url_attachment;
      }
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      this.editPost = {};
    },
    createUpdatePostModalClosed() {
      this.editPost = {};
      this.sharePost = {};
      this.userNewPostOpenModal = false;
    },
    sharingPost(post) {
      this.userNewPostOpenModal = true;
      this.sharePost = post.parent || post;
    },
    likeUnlikePostOrComment(data) {
      const news = this.includeSystemPosts ? this.newsPosts : this.news;
      if (news.length === 0) return;

      const updatePost = news.find((post) => post.id === data.postId);
      if (!updatePost) return;

      if (data.isPost) {
        updatePost.has_liked = data.like;
        updatePost.likes = data.like ? updatePost.likes + 1 : updatePost.likes - 1;
      } else {
        const updateComment = updatePost.comments.find((comment) => comment.id === data.commentId);
        updateComment.has_liked = data.like;
        updateComment.likes = data.like ? updateComment.likes + 1 : updateComment.likes - 1;
      }

      if (this.includeSystemPosts) {
        this.newsPosts = news;
      } else this.news = news;
    },
    newCommentCreated(comment) {
      this.updateCommentsArray(comment, this.news, 'create');
      if (this.newsPosts.length !== 0) {
        this.updateCommentsArray(comment, this.newsPosts, 'create');
      }

      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    updateCommentsContent(updatedComment) {
      this.updateCommentsArray(updatedComment, this.news, 'update');
      if (this.newsPosts.length !== 0) {
        this.updateCommentsArray(updatedComment, this.newsPosts, 'update');
      }

      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      this.editPost = {};
    },
    commentRemoved(deletedComment) {
      this.updateCommentsArray(deletedComment, this.news, 'delete');
      if (this.newsPosts.length !== 0) {
        this.updateCommentsArray(deletedComment, this.newsPosts, 'delete');
      }
    },
    updateCommentsArray(targetComment, array, mode) {
      const updatePost = array.find(
        (post) => post.id === targetComment.top_news_id
      );
      if (!updatePost) return;

      if (mode === 'delete') {
        const comments = updatePost.comments.filter(
          (comment) => targetComment.id !== comment.id
        );
        updatePost.comments = comments;
        updatePost.comments_count -= 1;
      } else if (mode === 'update') {
        const updateComment = updatePost.comments.find(
          (comment) => comment.id === targetComment.id
        );
        updateComment.content = targetComment.content;
        updateComment.url_attachment = targetComment.url_attachment;
      } else if (mode === 'create') {
        updatePost.comments = updatePost.comments ?
          [structuredClone(targetComment), ...updatePost.comments] :
          [structuredClone(targetComment)];
        updatePost.comments_count += 1;
      }
    }
  }
};
</script>

<style scoped>
.btn-round {
  border-radius: 30px !important;
}
</style>
