<template>
  <section v-if="!setLocation">
    <project-form
      :image="image"
      :tags="tags"
      :asset-classes="assetClasses"
      :organization-id="organizationId"
      :project-type="type"
      :form-info="form"
      @set-location="goToSetLocation"
    />
  </section>
  <section v-else>
    <set-location
      :images="{ collaborate: image }"
      :location="location"
      :reverse-geocode="true"
      :required="type !== 'ReportingProject'"
      @back="setLocation = false"
      @save="saveLocation"
    />
  </section>
</template>

<script>
import ProjectForm from './ProjectForm.vue';
import SetLocation from '../../../../components/shared/SetLocation.vue';

export default {
  components: { ProjectForm, SetLocation },
  data() {
    return {
      image: '',
      organizationId: '',
      tags: [],
      assetClasses: [],
      type: '',
      location: {},
      setLocation: false,
      form: {}
    };
  },
  beforeMount() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const data = document.querySelector('#new-project');
      this.image = data?.dataset?.imgUrl;
      this.organizationId = data?.dataset?.organizationId;
      this.type = data?.dataset?.type;
      this.tags = JSON.parse(data?.dataset?.tags) || [];
      this.assetClasses = JSON.parse(data?.dataset?.assetClasses) || [];
    },
    saveLocation(location) {
      this.location = {...location};
      this.form = {...this.form, ...this.location};
      this.setLocation = false;
    },
    goToSetLocation(form) {
      this.setLocation = true;
      this.form = form;
    }
  }
};
</script>

