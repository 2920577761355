export const sharedMixin = {
  methods: {
    organizationType(type) {
      switch (type) {
        case 'WildlifeOrg':
          return 'Project Developer';
        case 'TechOrg':
          return 'Technology';
        case 'InvestorOrg':
          return 'Investor';
        case null:
          return '';
        default:
          return type;
      }
    },
    openPreview(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    // Prepend http:// when it is not included in string url or json with url values
    addHttpProtocol(value) {
      if (typeof value === 'string') {
        if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
          return 'http://' + value;
        }
        return value;
      } else if (typeof value === 'object' && value !== null) {
        const updatedObject = {};
        for (const key in value) {
          if (Object.prototype.hasOwnProperty.call(value, key)) {
            if (typeof value[key] === 'string' && !value[key].startsWith('http://') && !value[key].startsWith('https://')) {
              updatedObject[key] = 'http://' + value[key];
            } else {
              updatedObject[key] = value[key];
            }
          }
        }
        return updatedObject;
      }
      return value;
    }
  },
}