<template>
  <div class="image-animation">
    <img class="first-image" :src="firstImageSrc" alt="First Image" :class="{ visible: firstImageVisible }" ref="firstImageRef" />
    <img v-if="secondImageSrc" class="second-image" :src="secondImageSrc" alt="Second Image" :class="{ visible: secondImageVisible }" ref="secondImageRef" />
  </div>
</template>

<script>
export default {
  props: {
    firstImageSrc: {
      type: String,
      required: true,
    },
    secondImageSrc: {
      type: String,
      default: '', // Make the second image source optional
    },
  },
  data() {
    return {
      firstImageVisible: false,
      secondImageVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Trigger initial check for visibility
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const firstImageOffsetTop = this.$refs.firstImageRef.getBoundingClientRect().top;
      const secondImageOffsetTop = this.secondImageSrc ? this.$refs.secondImageRef.getBoundingClientRect().top : 0;
      const windowHeight = window.innerHeight;

      // Calculate visibility based on how much of the image is in the viewport
      this.firstImageVisible = firstImageOffsetTop < windowHeight - 50;
      this.secondImageVisible = this.secondImageSrc ? secondImageOffsetTop < windowHeight - 50 : false;
    },
  },
};
</script>

<style scoped>
.image-animation {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide any overflow when images translate */
}

.first-image,
.second-image {
  border-radius: 16px;
  position: absolute;
  transition: transform 0.5s ease; /* Add transform transition */
  opacity: 0; /* Start with images hidden */
}

.first-image {
  width: 100%; /* Adjust the image size as needed */
  height: auto; /* Maintain aspect ratio */
  top: -50px; /* Move the second image outside the viewport */
  right: 0;
}

.second-image {
  width: 30%; /* Adjust the image size as needed */
  height: auto; /* Maintain aspect ratio */
  bottom: -50px; /* Move the first image outside the viewport */
  left: 50px;
}

/* Apply translation and reveal images when they are in the viewport */
.first-image.visible {
  transform: translateY(50px);
  opacity: 1;
}

.second-image.visible {
  transform: translateY(-50px);
  opacity: 1;
}

</style>
