import AllAttachments from './components/AllAttachments.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#attachments-page')){
    const app = createApp(AllAttachments);
    app.use(store);
    app.mount('#attachments-page');
  }
});
