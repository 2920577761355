<template>
  <div id="organizationSidebar">
    <div v-if="loading" class="mt-4 text-center">
      <div class="spinner-border" />
    </div>
    <div v-else-if="error" class="mt-4 ark-p7 text-center">
      Something went wrong...
    </div>
    <div v-else>
      <img
        v-if="cardDetails.banner || cardDetails.last_updated_image || cardDetails.default_banner"
        :src="cardDetails.banner || cardDetails.last_updated_image || cardDetails.default_banner"
        :alt="`${cardDetails.name}'s banner or latest image`"
        style="width: 316px; height: 160px; object-fit: cover"
      >
      <div class="borderBottomLine">
        <div class="row">
          <div
            class="col-12 DesktopLinkMedium secondary7_ark my-2 pointer"
            role="button"
            tabindex="0"
            @keypress.prevent="redirect(cardDetails.id)"
            @click="redirect(cardDetails.id)"
          >
            {{ cardDetails.name || cardDetails.title }}
          </div>
        </div>
        <div v-if="cardDetails.goals_count > 0" class="row">
          <div class="col-12">
            <avl-badge
              :label="cardDetails.goals_count + ' goals'"
              class="MobileTextXSmall"
              size="medium"
              type="default"
            />
          </div>
        </div>
        <div class="row my-2">
          <div class="col-12 MobileTextXSmall grayscaleBody">
            {{ subtitle }}
          </div>
        </div>
        <div class="row my-2" style="padding: 0px 15px">
          <div
            v-if="cardDetails.species_count > 0"
            class="MobileTextXSmall warningDefault mr-2"
          >
            {{ cardDetails.species_count }} species
          </div>
          <div
            v-if="cardDetails.projects_count > 0"
            class="MobileTextXSmall warningDefault mr-2"
          >
            {{ cardDetails.projects_count }} projects
          </div>
        </div>
      </div>
      <div class="row py-3">
        <div
          class="col-4 MobileTextXSmall primary2_ark pointer"
          role="button"
          tabindex="0"
          @keypress.prevent="toggleFollow(fetchedData.is_following)"
          @click="toggleFollow(fetchedData.is_following)"
        >
          <div class="col-12 justify-content-center d-flex">
            <span
              class="justify-content-center align-items-center d-flex iconCircle"
              :class="
                fetchedData.is_following
                  ? 'secondary7_bg_ark'
                  : ''
              "
            >
              <i
                aria-hidden="true"
                class="
                  fa fa-bookmark-o fa-inverse fa-lg
                "
                :class="
                  fetchedData.is_following
                    ? 'grayscaleOffWhite'
                    : 'secondary7_ark'
                "
              />
            </span>
          </div>
          <div
            class="
              col-12
              MobileTextXSmall
              primary2_ark
              justify-content-center
              d-flex
              pt-2
            "
          >
            {{ fetchedData.is_following ? "Following" : "Follow" }}
          </div>
        </div>
        <div
          class="col-4 MobileTextXSmall primary2_ark pointer"
          role="button"
          tabindex="0"
          @keypress.prevent="
            redirect(
              theme !== mapCardThemes.project
                ? `${cardDetails.id}/news`
                : `${cardDetails.id}/team`
            )
          "
          @click="
            redirect(
              theme !== mapCardThemes.project
                ? `${cardDetails.id}/news`
                : `${cardDetails.id}/team`
            )
          "
        >
          <div class="col-12 primary2_ark justify-content-center d-flex">
            <span class="justify-content-center align-items-center d-flex iconCircle">
              <i
                aria-hidden="true"
                class="
                  fa fa-inverse fa-lg
                  secondary7_ark
                "
                :class="
                  theme !== mapCardThemes.project
                    ? 'fa-newspaper-o '
                    : 'fa-users'
                "
              />
            </span>
          </div>
          <div
            class="
              col-12
              MobileTextXSmall
              primary2_ark
              justify-content-center
              d-flex
              pt-2
            "
          >
            {{ theme !== mapCardThemes.project ? "News" : "Team" }}
          </div>
        </div>
        <div
          class="col-4 MobileTextXSmall primary2_ark pointer"
          role="button"
          tabindex="0"
          @keypress.prevent="redirect(`${cardDetails.id}/impact`)"
          @click="redirect(`${cardDetails.id}/impact`)"
        >
          <div class="col-12 primary2_ark justify-content-center d-flex">
            <span class="justify-content-center align-items-center d-flex iconCircle">
              <i
                aria-hidden="true"
                class="
                  far fa-bullseye-arrow fa-inverse fa-lg
                  secondary7_ark
                "
              />
            </span>
          </div>
          <div
            class="
              col-12
              MobileTextXSmall
              primary2_ark
              justify-content-center
              d-flex
              pt-2
            "
          >
            Impact
          </div>
        </div>
      </div>
      <div class="borderBottomLine row pb-3 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-round borderedButton DesktopLinkXSmall transition-02 w-100 mx-3 my-0"
          @click="redirect(cardDetails.id)"
        >
          View detail
        </button>
      </div>
      <div v-if="description" class="borderBottomLine row">
        <div class="col-10 MobileTextXSmall primary2_ark py-3 pr-0">
          {{ description }}
        </div>
        <div
          class="col-2 justify-content-center pointer d-flex px-1"
          role="button"
          tabindex="0"
          @keypress.prevent="redirect(cardDetails.id)"
          @click="redirect(cardDetails.id)"
        >
          <i
            aria-hidden="true"
            class="
              fa fa-angle-right fa-2x
              grayscalePlaceholder
              justify-content-center
              align-items-center
              d-flex
              pt-1
              align-self-center
            "
            style="width: 24px; height: 24px; font-weight: 200"
          />
        </div>
      </div>
      <div
        v-if="fetchedData.full_address || fetchedData.project_full_address"
        class="pt-3 row"
      >
        <div class="col-1">
          <i
            aria-hidden="true"
            class="
              fa fa-map-marker fa-lg
              secondary7_ark
            "
          />
        </div>
        <div class="col-10">
          <p
            class="
              pb-0
              pl-0
              m-0
              DesktopTextXSmall
              primary2_ark
            "
          >
            {{ fetchedData.full_address || fetchedData.project_full_address }}
          </p>
        </div>
      </div>
      <div
        v-if="fetchedData.full_url || fetchedData.project?.location_url"
        class="pt-3 row pointer"
        role="button"
        tabindex="0"
        @click="
          redirect(
            fetchedData.full_url || fetchedData.project?.location_url,
            redirectStatus.newWindow
          )
        "
        @keypress.prevent="
          redirect(
            fetchedData.full_url || fetchedData.project?.location_url,
            redirectStatus.newWindow
          )
        "
      >
        <div class="col-1">
          <i
            aria-hidden="true"
            class="
              fa fa-globe fa-lg
              secondary7_ark
            "
          />
        </div>
        <div class="col-10">
          <p
            class="
              pb-0
              pl-0
              m-0
              DesktopTextXSmall
              primary2_ark
            "
            >
            {{ fetchedData.full_url || fetchedData.project?.location_url }}
          </p>
        </div>
      </div>
      <div v-if="fetchedData.species?.length !== 0" class="pt-3 row">
        <div class="col-1">
          <i
            aria-hidden="true"
            class="
              fa fa-paw fa-lg
              secondary7_ark
            "
          />
        </div>
        <div class="col-10">
          <span
            v-for="(item, index) in fetchedData.species"
            :key="item.id"
            class="align-self-start m-0 DesktopTextXSmall primary2_ark pointer"
            role="button"
            tabindex="0"
            @click="redirect(`/species/${item.id}`, redirectStatus.pureUrl)"
          >
            {{ item.name }}
            <svg
              v-if="index < fetchedData.species?.length - 1"
              height="8"
              width="8"
              class="mr-1"
            >
              <circle cx="4" cy="4" r="2" class="primary2_ark" />
            </svg>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { enumerate, symbolToString } from '../../../utilities/enumeration';
import api from '../../../api/config';
import { mapCardThemes } from '../../../domain/enumerationHelper';

const MAXIMUM_TEXT_LENGTH = 130;

const redirectStatus = enumerate(['newWindow', 'pureUrl']);

export default {
  props: {
    cardDetails: {
      type: Object,
      required: true,
      default: null
    },
    theme: {
      type: Symbol,
      required: true
    }
  },
  data() {
    return {
      fetchedData: {},
      loading: false,
      mapCardThemes,
      error: undefined,
      redirectStatus
    };
  },
  computed: {
    subtitle() {
      if (this.theme === mapCardThemes.tech_org || this.cardDetails.organizationType === 'TechOrg') {
        return 'Technology';
      } else if (this.theme === mapCardThemes.project) {
        return 'Project';
      } else {
        return 'Project Developer';
      }
    },
    description() {
      switch (this.theme) {
        case mapCardThemes.organization:
          return this.truncatedText(this.fetchedData.about);
        case mapCardThemes.project:
          return this.truncatedText(
            this.fetchedData.project?.short_description
          );
        case mapCardThemes.tech_org:
          return this.truncatedText(this.fetchedData.about);
        default:
          return '';
      }
    },
  },
  watch: {
    cardDetails() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.error = undefined;
      api
        .get(`${symbolToString(this.theme)}s/${this.cardDetails.id}` +
          (this.theme === mapCardThemes.organization ? '/about' : '')
        )
        .then((response) => response.json())
        .then((data) => {
          this.fetchedData = data[symbolToString(this.theme)];
        })
        .catch((error) => {
          this.error = error;
          Sentry && Sentry.captureException(error);
        })
        .finally(() => (this.loading = false));
    },
    truncatedText(text) {
      return text?.length > MAXIMUM_TEXT_LENGTH
        ? text.substring(0, MAXIMUM_TEXT_LENGTH) + ' ...'
        : text;
    },
    toggleFollow(isFollowing) {
      api
        .post(
          `${symbolToString(this.theme)}s/${this.cardDetails.id}/${
            isFollowing ? 'unfollow' : 'follow'
          }`
        )
        .then(() => {
          this.fetchedData.is_following = !isFollowing;
        })
        .catch((error) => console.warn(error));
    },
    redirect(url, status = undefined) {
      switch (status) {
        case redirectStatus.newWindow:
          return window.open(url, '_blank');
        case redirectStatus.pureUrl:
          window.location = url;
          break;
        default:
          location.href = `/${symbolToString(this.theme)}s/${url}`;
      }
    }
  }
};
</script>

<style scoped>
#organizationSidebar .row {
  margin: 0px;
}

#organizationSidebar avl-badge {
  --default-color-solid: #24422b;
  --default-text-color: #fcfcfc;
}

.borderBottomLine {
  border-bottom: 1px solid #bdbfd2;
}

.iconCircle {
  border-radius: 35px;
  height: 35px;
  width: 35px;
  border: 2px #24422B solid;
}

.iconCircle:hover {
  box-shadow: 0px 3px 4px 1px #A4B9B4;
}
</style>
