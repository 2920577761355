import { createApp } from 'vue';
import { store } from '../../store';
import HomeContainer from './components/HomeContainer.vue';
import AboutContainer from './components/AboutContainer.vue';
import MeasureContainer from './components/MeasureContainer.vue';
import InvestContainer from './components/InvestContainer.vue';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#static-home')){
    const app = createApp(HomeContainer);
    app.use(store);
    app.mount('#static-home');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#static-about')){
    const app = createApp(AboutContainer);
    app.use(store);
    app.mount('#static-about');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#static-measure')){
    const app = createApp(MeasureContainer);
    app.use(store);
    app.mount('#static-measure');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#static-invest')){
    const app = createApp(InvestContainer);
    app.use(store);
    app.mount('#static-invest');
  }
});