<template>
  <search-tabs tab="all" @tab-changed="tabChanged" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-3">
        <nav v-if="resultsFound" class="arkCard linksCard mx-xl-4">
          <h4 class="MobileTextXSmall grayscaleLabel mt-0">On this page</h4>
          <ul class="listStyleNone p-0">
            <li v-if="results.projects?.length > 0">
              <a href="#project-results" class="MobileTextXSmall grayscaleOffBlack">
                Projects
              </a>
            </li>
            <li v-if="results.organizations?.length > 0">
              <a href="#organisation-results" class="MobileTextXSmall grayscaleOffBlack">
                Organisations
              </a>
            </li>
            <li v-if="results.users?.length > 0">
              <a href="#people-results" class="MobileTextXSmall grayscaleOffBlack">
                People
              </a>
            </li>
            <li v-if="results.top_news?.length > 0">
              <a href="#post-results" class="MobileTextXSmall grayscaleOffBlack">
                Posts
              </a>
            </li>
            <li v-if="results.books?.length > 0">
              <a href="#book-results" class="MobileTextXSmall grayscaleOffBlack">
                Books
              </a>
            </li>
            <li v-if="results.films?.length > 0">
              <a href="#film-results" class="MobileTextXSmall grayscaleOffBlack">
                Films
              </a>
            </li>
            <li v-if="results.podcasts?.length > 0">
              <a href="#webinar-results" class="MobileTextXSmall grayscaleOffBlack">
                Webinars
              </a>
            </li>
            <li v-if="results.papers?.length > 0">
              <a href="#paper-results" class="MobileTextXSmall grayscaleOffBlack">
                Research papers
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <main class="col-12 col-lg-6">
        <div v-if="loading">
          <loading-indicator
            :skeletons="[
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'}
            ]"
          />
        </div>
        <div v-else-if="resultsFound">
          <section v-if="results.projects?.length > 0" class="arkCard" id="project-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Projects</h3>
            <project-card
              v-for="project in results.projects"
              :key="project.id"
              :project="project"
            />
            <show-more
              text="See all project results"
              :icon="false"
              @show-more="tabChanged('projects')"
            />
          </section>
          <section v-if="results.organizations?.length > 0" class="arkCard" id="organisation-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Organisations</h3>
            <organization-card
              v-for="organization in results.organizations"
              :key="organization.id"
              :organization="organization"
            />
            <show-more
              text="See all organisation results"
              :icon="false"
              @show-more="tabChanged('organizations')"
            />
          </section>
          <section v-if="results.users?.length > 0" class="arkCard" id="people-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">People</h3>
            <user-card
              v-for="user in results.users"
              :key="user.id"
              :user="user"
            />
            <show-more
              text="See all people results"
              :icon="false"
              @show-more="tabChanged('users')"
            />
          </section>
          <section v-if="visibleNews.length > 0" class="arkCard" id="post-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Posts</h3>
            <ark-post
              v-for="post in visibleNews"
              :key="post.id"
              :post="post"
              :author="{}"
              @post-deleted="postDeleted"
              @edit-post="updatePost"
              @add-comment="addComment"
              @edit-comment="editComment"
              @comment-deleted="commentDeleted"
              @share-post="sharingPost"
            />
            <show-more
              text="See all post results"
              :icon="false"
              @show-more="tabChanged('top_news')"
            />
            <create-post
              :show-add-post-input="false"
              :edit-post="editPost"
              :share-post="sharePost"
              :author="{}"
              @post-created="newPost"
              @post-deleted="postDeleted"
              @post-updated="updateNews"
              @comment-created="commentCreated"
              @comment-updated="updateComments"
              @modal-closed="createUpdatePostModalClosed"
            />
          </section>
          <section v-if="results.books?.length > 0" class="arkCard" id="book-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Books</h3>
            <book-card
              v-for="book in results.books"
              :key="book.id"
              :book="book"
            />
            <show-more
              text="See all book results"
              :icon="false"
              @show-more="tabChanged('books')"
            />
          </section>
          <section v-if="results.films?.length > 0" class="arkCard" id="film-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Films</h3>
            <film-card
              v-for="film in results.films"
              :key="film.id"
              :film="film"
            />
            <show-more
              text="See all film results"
              :icon="false"
              @show-more="tabChanged('films')"
            />
          </section>
          <section v-if="results.podcasts?.length > 0" class="arkCard" id="webinar-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Webinars</h3>
            <podcast-card
              v-for="podcast in results.podcasts"
              :key="podcast.id"
              :podcast="podcast"
            />
            <show-more
              text="See all webinar results"
              :icon="false"
              @show-more="tabChanged('podcasts')"
            />
          </section>
          <section v-if="results.papers?.length > 0" class="arkCard" id="paper-results">
            <h3 class="DesktopTextMedium primaryDefault mt-0">Research papers</h3>
            <paper-card
              v-for="paper in results.papers"
              :key="paper.id"
              :paper="paper"
            />
            <show-more
              text="See all research paper results"
              :icon="false"
              @show-more="tabChanged('papers')"
            />
          </section>
        </div>
        <section v-else class="arkCard">
          <no-data-card
            first-message="Sorry, no results"
            second-message="No results were found for your search. Make sure all words are spelled correctly or try using different keywords."
            :show-button="false"
          />
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import ArkPost from '../../../components/listItems/ArkPost.vue';
import BookCard from '../../../components/listItems/BookCard.vue';
import CreatePost from '../../../components/shared/CreatePost.vue';
import FilmCard from '../../../components/listItems/FilmCard.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import OrganizationCard from '../../../components/listItems/OrganizationCard.vue';
import PaperCard from '../../../components/listItems/PaperCard.vue';
import PodcastCard from '../../../components/listItems/PodcastCard.vue';
import ProjectCard from '../../../components/listItems/ProjectCard.vue';
import SearchTabs from './SearchTabs.vue';
import ShowMore from '../../../components/shared/ShowMore.vue';
import UserCard from '../../../components/listItems/UserCard.vue';
import { fallbackApi } from '../../../api/config';
import { nextTick } from 'vue';
import { postsMixin } from '../../../mixins';

export default {
  name: 'AllResults',
  components: {
    ArkPost,
    BookCard,
    CreatePost,
    FilmCard,
    LoadingIndicator,
    NoDataCard,
    OrganizationCard,
    PaperCard,
    PodcastCard,
    ProjectCard,
    ShowMore,
    SearchTabs,
    UserCard
  },
  mixins: [postsMixin],
  props: {
    search: {
      type: String,
      required: false,
      default() {
        return '';
      }
    }
  },
  emits: ['tabChanged'],
  data() {
    return {
      results: {},
      news: [],
      loading: true
    };
  },
  computed: {
    resultsFound() {
      return Object.values(this.results).some((v) => v.length > 0);
    },
    visibleNews() {
      return this.news.slice(0, 3);
    }
  },
  watch: {
    loading() {
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    }
  },
  mounted() {
    this.fetchResults();
  },
  methods: {
    tabChanged(tab) {
      this.$emit('tabChanged', tab);
    },
    fetchResults() {
      this.loading = true;

      fallbackApi({
        url: `search?model=all&search=${this.search}`,
        dataSetter: (data) => {
          this.results = data || {};
          if (this.results.top_news?.length > 0) {
            this.news = this.results.top_news;
          }
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    postDeleted(postId) {
      this.results.top_news = this.results.top_news?.filter((post) => {
        return post.id !== postId;
      });
      this.news = this.results.top_news;
    },
    createUpdatePostModalClosed() {
      this.editPost = {};
      this.sharePost = {};
    },
    sharingPost(post) {
      this.sharePost = post.parent || post;
    },
    newPost(post) {
      this.sharePost = {};
      this.news = [post, ...this.news];
      this.results.top_news = [post, ...this.results.top_news];
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
  }
};
</script>

<style scoped>
.arkCard {
  scroll-margin-top: 173px;
}

@media screen and (max-width: 991px) {
  .arkCard {
    scroll-margin-top: 20px;
  }
}

.linksCard {
  position: sticky;
  top: 173px;
  left: auto;
}

.linksCard li:not(:last-child), .linksCard h4 {
  margin-bottom: 10px;
}
</style>
