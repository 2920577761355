<template>
  <section>
    <div v-show="showAddPostInput" class="row">
      <section class="col-12 d-flex align-items-center">
        <img
          v-if="organizationHeaderInfo.logo"
          :src="organizationHeaderInfo?.logo"
          class="logoImage d-flex objectFitContain"
          alt=""
        >
        <div
          v-else
          class="p-2 d-flex logoImage objectFitCover secondary7_bg_ark"
          aria-hidden="true"
        >
          <i class="white fas fa-building fa-2x" aria-hidden="true" />
        </div>
        <button
          class="ml-2 w-100 addPostInputContainer"
          @click="createPostModalOpen = true"
        >
          <input
            id="addPost"
            class="DesktopTextSmall inputCreatePost w-100"
            placeholder="Start a post"
            disabled
          >
        </button>
      </section>
    </div>
    <avl-modal
      :is-open="createPostModalOpen"
      header-title=""
      close-button-icon="fa fa-times"
      @avleventclose="createPostModalClosed"
    >
      <header slot="header" class="row custom-header">
        <div class="col-12">
          <h2 class="DesktopTextMedium textBodyColor text-left">
            {{ modalHeader }}
          </h2>
        </div>
      </header>
      <section class="d-flex">
        <img
          v-if="logoOrAvatar"
          :src="logoOrAvatar"
          class="logoImageMax d-flex"
          :class="
            asOrganization === '0' ?
              'round objectFitCover' :
              'objectFitContain'
          "
          alt=""
        >
        <div
          v-else
          class="p-2 d-flex logoImageMax align-items-center justify-content-center"
          :class="
            asOrganization === '0' ?
              'round objectFitCover' :
              'objectFitContain secondary7_bg_ark'
          "
          aria-hidden="true"
        >
          <i
            class="
              align-items-center
              profileIconSize
            "
            :class="
              asOrganization === '1'
                ? 'white fas fa-building'
                : 'fas fa-user-circle secondary7_ark'
            "
            aria-hidden="true"
          />
        </div>
        <div v-if="!editMode" class="ml-3 d-flex align-items-center dropdown">
          <button
            id="dropdownMenuButton"
            class="
              dropdown-toggle
              btn
              dropdown-select
              DesktopTextXSmall
              grayscaleLabel
              selectpicker
            "
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              class="fa ml-1"
              :class="asOrganization === '1' ? 'fa-users' : 'fa-user'"
              aria-hidden="true"
            />&nbsp;&nbsp;
            <span class="mr-1">
              {{
                asOrganization === '1' && user?.organization
                  ? user.organization.name :
                    user.full_name
              }}
            </span>
          </button>
          <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuButton">
            <button
              v-if="user?.organization"
              class="dropdown-item w-100 m-0"
              @click="asOrganization = '1'"
            >
              <i class="fa fa-users" aria-hidden="true" />&nbsp;&nbsp;
              {{ user.organization.name }}
            </button>
            <button
              class="dropdown-item w-100 m-0"
              @click="asOrganization = '0'"
            >
              <i class="fa fa-user" aria-hidden="true" />&nbsp;&nbsp;
              {{ user.full_name }}
            </button>
          </div>
        </div>
      </section>
      <section class="news-post-editor mt-4">
        <trix-editor
          ref="trix"
          input="post"
          class="w-100 mt-3 text-left"
          rows="5"
          placeholder="What do you want to talk about?"
          @trix-change="handleContentChange"
          @trix-attachment-add="handleAttachmentChanges"
        />
        <input id="post" :value="postContent" type="hidden" name="content">
      </section>
      <section class="url-attachment-container">
        <button
          v-show="urlAttachment"
          class="iframe-close"
          aria-label="Close"
          @click="removeUrlAttachment"
        >
          <i class="fa fa-times fa-lg" />
        </button>
        <div id="url-attachment" v-html="urlAttachment" />
      </section>
      <section v-show="Object.keys(sharePost).length > 0" class="border rounded p-3 my-2">
        <header class="d-flex align-items-start">
          <img
            v-if="logoOrAvatarSharePost"
            :src="logoOrAvatarSharePost"
            class="logoImageMedium d-flex"
            :class="
              sharePost.as_organization ?
                'objectFitContain' :
                'objectFitCover round'
            "
            alt=""
          >
          <div
            v-else
            class="
              p-2 d-flex
              logoImageMedium
              objectFitCover
              align-items-center
              justify-content-center
            "
            :class="{ secondary7_bg_ark: sharePost.as_organization }"
            aria-hidden="true"
          >
            <i
              class="align-items-center"
              :class="
                sharePost.as_organization
                  ? 'white fas fa-building iconSize30'
                  : 'fas fa-user-circle secondary7_ark iconSize40'
              "
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <p class="DesktopLinkSmall grayscaleOffBlack mb-0 pointer">
              {{ sharePost.organization?.name || sharePost.user?.name }}
            </p>
          </div>
        </header>
        <section class="mt-3 mb-2 text-left">
          <div id="content" v-html="sharePost.content" />
        </section>
        <section class="url-attachment-container">
          <div id="url-attachment" v-html="sharePost.url_attachment" />
        </section>
      </section>
      <error-message
        class="d-block saveCancelContainer d-flex justify-content-end maxWidthInputs"
        :error-condition="!!errorMessageFromBackend"
        :error-message="errorMessageFromBackend"
      />
      <div class="saveCancelContainer d-flex justify-content-end">
        <div
          v-if="loading"
          class="ml-2 d-flex justify-content-center"
        >
          <div
            class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
            Posting
          </p>
        </div>
        <button
          v-else
          class="
            btn ml-2
            DesktopLinkXSmall
            grayscaleOffWhite
            primaryButton
          "
          :disabled="disableSaveButton"
          @click="savePost"
        >
          {{ editMode ? 'Update': 'Post' }}
        </button>
      </div>
    </avl-modal>
  </section>
</template>

<script>
import { DirectUpload } from '@rails/activestorage/src/direct_upload';
import ErrorMessage from './ErrorMessage.vue';
import { fallbackApi } from '../../api/config';
import { iframely } from '@iframely/embed.js';

export default {
  components: { ErrorMessage },
  props: {
    showAddPostInput: {
      type: Boolean,
      required: true
    },
    organizationHeaderInfo: {
      type: Object,
      required: false,
      default: function () {
        return {};
      }
    },
    editPost: {
      type: Object,
      required: true
    },
    sharePost: {
      type: Object,
      required: true
    },
    author: {
      type: Object,
      required: false,
      default: function () {
        return null;
      }
    },
    openModal: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      }
    }
  },
  emits: [
    'postCreated',
    'postDeleted',
    'postUpdated',
    'commentCreated',
    'modalClosed',
    'commentUpdated'
  ],
  data() {
    return {
      createPostModalOpen: false,
      loading: false,
      editMode: false,
      modalForComment: false,
      postContent: '',
      urlAttachment: '',
      asOrganization: '1',
      errorMessageFromBackend: '',
      postUrls: [],
      user: {}
    };
  },
  computed: {
    logoOrAvatar() {
      if (this.asOrganization === '1' && this.user.organization?.logo) {
        return this.user.organization.logo;
      } else if (this.asOrganization === '0' && this.user.avatar) {
        return this.user.avatar;
      } else return false;
    },
    logoOrAvatarSharePost() {
      if (
        this.sharePost.organization?.name &&
        this.sharePost.organization.name === this.author.name &&
        this.author.logo
      ) {
        return this.author.logo;
      }

      return this.sharePost.organization?.logo || this.sharePost.user?.avatar;
    },
    modalHeader() {
      if (this.editMode && this.modalForComment) {
        return 'Update this comment';
      } else if (this.modalForComment) {
        return 'Add a comment';
      } else if (this.editMode) {
        return 'Update this post';
      } else return 'Create a post';
    },
    disableSaveButton() {
      return (
        Object.keys(this.sharePost).length === 0 &&
        ((this.postContent === '' && !this.urlAttachment) || !!this.errorMessageFromBackend)
      );
    }
  },
  watch: {
    postContent: {
      handler() {
        if (this.postContent === '') this.errorMessageFromBackend = '';
        this.addUrlAttachment();
      }
    },
    editPost() {
      if (Object.keys(this.editPost).length > 1) {
        this.editMode = true;
        this.asOrganization= this.editPost.as_organization ? '1' :'0';
        this.postContent = this.editPost.content;
        this.urlAttachment = this.editPost.url_attachment;
        document.querySelector('trix-editor').editor.setSelectedRange([0, 0]);
        document.querySelector('trix-editor').editor.insertHTML(this.postContent);
        this.modalForComment = !!this.editPost.top_news_id;
        this.createPostModalOpen = true;
      } else if (Object.keys(this.editPost).length === 1) {
        this.modalForComment = true;
        this.createPostModalOpen = true;
      }
    },
    sharePost() {
      if (Object.keys(this.sharePost).length > 1) {
        this.createPostModalOpen = true;
      }
    },
    createPostModalOpen() {
      if (!this.user.organization) this.asOrganization = '0';
    },
    openModal() {
      if (this.openModal) {
        this.asOrganization = '0';
        this.createPostModalOpen = true;
      }
    }
  },
  beforeMount() {
    this.user = this.$store.state.auth.user;
  },
  updated() {
    iframely.load();
  },
  methods: {
    createPostModalClosed() {
      document.querySelector('trix-editor').editor.loadHTML('');
      this.errorMessageFromBackend = '';
      this.postContent = '';
      this.urlAttachment = '';
      this.postUrls= [];
      this.asOrganization= '1';
      this.editMode = false;
      this.shareMode = false;
      this.modalForComment = false;
      this.createPostModalOpen = false;
      this.$emit('modalClosed');
    },
    handleContentChange(event) {
      this.postContent = event.srcElement ? event.srcElement.value : event.target.value;
    },
    handleAttachmentChanges(event) {
      event.stopPropagation();
      const token = document.querySelector("meta[name='api-token']").getAttribute('content');

      //   upload file to remote server
      const railsActiveStorageDirectUploadsUrl = '/api/v1/direct_uploads';
      const upload = new DirectUpload(
        event.attachment.file,
        railsActiveStorageDirectUploadsUrl,
        {
          directUploadWillCreateBlobWithXHR: (xhr) => {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        }
      );

      if (!upload.file) return;

      // create a blob in the remote server and update attachment's attributes to connect it to it
      upload.create((error, blob) => {
        if (error) {
          this.errorMessageFromBackend = error;
        } else {
          this.errorMessageFromBackend = '';
          const url = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
          let attributes = {
            url: url,
            sgid: blob.attachable_sgid
          };
          event.attachment.setAttributes(attributes);
        }
      });
    },
    addUrlAttachment() {
      let urlRegex = /(https?:\/\/[^\s<>"]+)/g;
      let text = this.postContent;
      text.replace(urlRegex, (url) => {
        fallbackApi({
          url: 'ogp',
          httpVerb: 'post',
          payload: { json: { url: url } }
        })
        .then((response) => {
          if (!this.postUrls.includes(response.data.url)) {
            this.postUrls.push(response.data.url);
            this.urlAttachment = response.data.html;
          }
          this.errorMessageFromBackend = '';
        })
        .catch((error) => {
          this.errorMessageFromBackend = error;
        });
        return url;
      });
    },
    removeUrlAttachment() {
      this.urlAttachment = '';
    },
    savePost() {
      this.loading = true;
      let url;
      let httpVerb;
      let requestBody;
      let parentId = Object.keys(this.sharePost).length > 0 ? this.sharePost.id : null;

      if (this.editMode && this.modalForComment) {
        url = `social/top_news_comments/${this.editPost.id}`;
        httpVerb = 'patch';
        requestBody = {
          content: this.postContent,
          top_news_id: this.editPost.top_news_id,
          url_attachment: this.urlAttachment
        };
      } else if (this.modalForComment) {
        url = 'social/top_news_comments/';
        httpVerb = 'post';
        requestBody = {
          social_top_news_comment: {
            as_organization: this.asOrganization,
            top_news_id: this.editPost.id,
            content: this.postContent,
            url_attachment: this.urlAttachment
          }
        };
      } else if (this.editMode ) {
        url = `social/top_news/${this.editPost.id}`;
        httpVerb = 'patch';
        requestBody = {
          content: this.postContent,
          url_attachment: this.urlAttachment
        };
      } else {
        url = 'social/top_news/';
        httpVerb = 'post';
        requestBody = {
          social_top_news: {
            as_organization: this.asOrganization,
            parent_id: parentId,
            content: this.postContent,
            url_attachment: this.urlAttachment
          }
        };
      }

      fallbackApi({
        url: url,
        httpVerb: httpVerb,
        payload: {
          json: requestBody
        }
      })
      .then((response) => {
        if (this.editMode && this.modalForComment) {
          this.$emit('commentUpdated', response.data);
        } else if (this.modalForComment) {
          this.$emit('commentCreated', response.data);
        } else if (this.editMode) {
          this.$emit('postUpdated', response.data);
        } else {
          this.$emit('postCreated', response.data);
        }
        this.createPostModalClosed();
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => (this.loading = false));
    },
    postDeleted(postId) {
      this.$emit('postDeleted', postId);
    }
  }
};
</script>

<style scoped>
avl-modal {
  --modal-box-shadow-header:  0px 0px 0px 0px;
  --modal-padding-body: 0 3% 0 3%;
  --modal-padding-header: 5px 3% 0 3%;
}

.custom-header {
  margin-top: -40px;
  width: 97%;
}

.inputCreatePost:disabled {
  cursor: pointer;
  padding: 8px 16px;
  background-color: #f8f9fa;
  color: #6c757d;
  border: 2px solid #999;
  border-radius: 30px;
}

.logoImage {
  width: 45px;
  height: 45px;
  border-radius: 3px;
}

.logoImageMax {
  width: 60px;
  height: 60px;
  border-radius: 3px;
}

.logoImageMedium {
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.profileIconSize {
  font-size: 40px
}

#addPost {
  pointer-events: none;
}

.addPostInputContainer, .addPostInputContainer:focus {
  background-color: transparent;
  padding: 0px;
  border: none;
  outline: none;
}

.url-attachment-container {
  position: relative;
}

.url-attachment-container .iframe-close {
  position: absolute;
  right: 5px;
  z-index: 2000;
  padding: 2px;
  margin: 3px;
  background-color: white;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  opacity: 0.5;
}

.url-attachment-container .iframe-close:hover {
  opacity: 0.4;
}

trix-editor {
  flex: 1;
  border: 0;
}

.btn.dropdown-select {
  border: 1px solid #999999;
}
</style>
