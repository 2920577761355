<template>
  <section class="backNav pb-3 mb-2">
    <div class="row justify-content-center mx-0">
      <nav class="col-12 col-lg-10">
        <button
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          @click="back"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </button>
      </nav>
    </div>
    <header class="row justify-content-center mx-0">
      <div class="col-12 col-lg-10 d-flex align-items-center">
        <img
          :src="images.collaborate"
          class="mr-2 noDataImage"
          alt="Globe"
          height="50"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">
          Tell us about your site
        </h1>
      </div>
    </header>
  </section>
  <main class="row justify-content-center mx-0">
    <section class="col-12 col-lg-10">
      <span class="grayscaleLabel DesktopTextXSmall">* indicates required</span>
      <form class="text-center">
        <section class="arkCard mt-3 mb-2 text-left">
          <fieldset>
            <legend class="sr-only">Site info:</legend>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="nameInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Site Name *
                </label>
                <input
                  id="nameInput"
                  v-model="form.name"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  required
                  aria-errormessage="nameError"
                  :aria-invalid="!!errors.name"
                  :class="{ notValid: errors.name }"
                  @blur="validate"
                >
                <error-message
                  id="nameError"
                  :error-condition="!!errors.name"
                  :error-message="errors.name"
                />
              </div>
            </div>
            <div class="row justify-content-center mx-0">
              <div class="col-12">
                <h3 class="grascaleLabel DesktopTextSmall">
                  What type of site is this?
                </h3>
                <div v-for="type in siteTypes" :key="type.id" class="d-flex align-items-center">
                  <input
                    :id="`site-type-${type.id}`"
                    v-model="form.siteType"
                    type="radio"
                    :value="type.id"
                    @blur="validate"
                  >
                  <label
                    :for="`site-type-${type.id}`"
                    class="grayscaleOffBlack DesktopTextSmall mx-3"
                  >
                    {{ type.description }}
                  </label>
                </div>
                <error-message
                  id="siteTypeError"
                  :error-condition="!!errors.siteType"
                  :error-message="errors.siteType"
                />
              </div>
            </div>
          </fieldset>
        </section>
        <p class="grayscaleLabel DesktopTextXSmall text-left mb-5">
          Uploading your site requires some processing so it may not appear on the map immediately.
          Please check back on the site list after a few minutes.
        </p>

        <error-message
          class="d-block"
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />

        <div
          v-if="loading"
          class="spinner-border primary2_ark"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <button
          v-else
          type="submit"
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            primaryButton
            px-5
          "
          :disabled="!form.name || !form.siteType || !!errorMessageFromBackend"
          @click="submit"
        >
          Save
        </button>
      </form>
    </section>
  </main>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from './ErrorMessage.vue';
import { fallbackApi } from '../../api/config';
import { validateForm } from '../../utilities/validation.js';

const ValidationSchema = yup.object({
  name: yup.string().required(),
  siteType: yup.number().nullable().required('Site type is a required field')
});

export default {
  components: { ErrorMessage },
  props: {
    modelId: {
      type: Number,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    images: {
      type: Object,
      required: true
    },
    siteTypes: {
      type: Array,
      required: true
    },
    location: {
      type: Object,
      required: false,
      default: null
    },
    file: {
      type: Object,
      required: false,
      default: null
    }
  },
  emits: ['back', 'save'],
  data() {
    return {
      form: {
        name: '',
        siteType: null
      },
      errors: {
        name: '',
        siteType: ''
      },
      submitted: false,
      loading: false,
      errorMessageFromBackend: ''
    };
  },
  computed: {
    model() {
      return this.modelPluralize.slice(0, -1);
    }
  },
  methods: {
    back() {
      this.$emit('back');
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.submitted);
    },
    afterSubmit(data) {
      this.form = {
        name: '',
        siteType: null
      };
      this.errors = {
        name: '',
        siteType: ''
      };
      this.submitted = false;
      this.errorMessageFromBackend = '';
      this.$emit('save', data);
    },
    submitLocationSite() {
      const site = {
        name: this.form.name,
        site_type_id: this.form.siteType,
        area: this.location.area,
        model: this.model
      };

      if (this.location.wdpaName) {
        site.wdpa_name = this.location.wdpaName;
        site.wdpa = true;
      }

      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sites/`,
        payload: {
          json: {
            site,
            feature: this.location.feature
          }
        },
        httpVerb: 'post'
      })
      .then((data) => {
        this.afterSubmit(data);
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message || `Error adding ${this.model} site.`;
      })
      .finally(() => (this.loading = false));
    },
    submitFileSite() {
      const formData = new FormData();
      formData.append('site[name]', this.form.name);
      formData.append('site[site_type_id]', this.form.siteType);
      formData.append('file', this.file);
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sites/`,
        payload: {
          body: formData,
          // Remove json content type so browser can set the appropriate formdata content type
          headers: {
            'Content-Type': undefined
          }
        },
        httpVerb: 'post'
      })
      .then((data) => {
        this.afterSubmit(data);
      })
      .catch(async ({ error }) => {
         if (error?.response) {
          const errorMessage = await error.response.json();
          this.errorMessageFromBackend = `Error adding ${this.model} site. ${errorMessage.error}`;
        } else {
          this.errorMessageFromBackend =
            error?.error?.message || `Error adding ${this.model} site.`;
        }
      })
      .finally(() => (this.loading = false));
    },
    async submit() {
      this.submitted = true;
      this.loading = true;
      const valid = await this.validate();
      if (valid) {
        if (this.location) {
          this.submitLocationSite();
        } else {
          this.submitFileSite();
        }
      } else {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.backNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .backNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}

.backTag {
  color: #24422B !important;
}
</style>
