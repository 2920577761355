import { iframely } from '@iframely/embed.js';
import { nextTick } from 'vue';

export const postsMixin = {
  data() {
    return {
      editPost: {},
      sharePost: {},
      news: []
    };
  },
  updated() {
    iframely.load();
  },
  methods: {
    updatePost(post) {
      this.editPost = post;
      this.sharePost = post.parent ? post.parent : {};
    },
    // We change the value of editPost in order to trigger
    // the CreatePost component and to open the modal
    // for post creation.
    addComment(post) {
      this.editPost = { id: post.id };
    },
    updateNews(updatedPost) {
      const news = this.news;
      news.find((post) => {
        if (post.id === this.editPost.id) {
          post.content = updatedPost.content;
          post.url_attachment = updatedPost.url_attachment;
        }
      });
      this.news = news;
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      this.editPost = {};
    },
    commentCreated(comment) {
      this.news.find((post) => {
        if (post.id === comment.top_news_id) {
          post.comments = post.comments ? [comment, ...post.comments] : [comment];
          post.comments_count += 1;
        }
      });
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    editComment(comment) {
      this.editPost = comment;
    },
    updateComments(updatedComment) {
      const updatePost = this.news.find((post) => post.id === this.editPost.top_news_id);
      const updateComment = updatePost.comments.find((comment) => comment.id === updatedComment.id);
      updateComment.content = updatedComment.content;
      updateComment.url_attachment = updatedComment.url_attachment;

      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      this.editPost = {};
    },
    commentDeleted(deletedComment) {
      const updatePost = this.news.find((post) => post.id === deletedComment.top_news_id);
      const comments = updatePost.comments.filter((comment) => deletedComment.id !== comment.id);
      updatePost.comments = comments;
      updatePost.comments_count -= 1;
    }
  }
};
