<template>
  <div id="searchMap" />
</template>

<script>
import { loadModules } from 'esri-loader';
import { pointSymbolConfig } from '../../map/helpers/consts';

export default {
  name: 'SearchMap',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  mounted() {
    loadModules([
      'esri/views/MapView',
      'esri/WebMap',
      'esri/config',
      'esri/Graphic'
    ])
      .then(([
        MapView,
        WebMap,
        esriConfig,
        Graphic
      ]) => {
        const windowWidth = window.innerWidth;
        const minZoom = windowWidth > 2049 ? 4 : 3;

        const arkMap = new WebMap({
          portalItem: {
            id: '2db26f96006441aaad38e93a318e0c38'
          }
        });
        const view = new MapView({
          map: arkMap,
          // Longitude, latitude
          center: [10, 40],
          zoom: 1,
          container: 'searchMap',
          constraints: {
            minZoom
          }
        });

        /**
         * Move and add widgets on the map viewport
         */
        view.ui.move(['zoom'], 'bottom-right');

        const points = this.items.map((item)=> {
          return new Graphic({
            geometry: {
              type: 'point',
              latitude: item.location.lat,
              longitude: item.location.lon
            },
            symbol: pointSymbolConfig
          });
        });

        view.graphics.addMany(points);
        this.$esri = { view };
      })
      .catch((err) => {
        // handle any errors
        Sentry && Sentry.captureException(err);
        console.error(err);
      });
  },
  watch: {
    items() {
      loadModules(['esri/Graphic']).then(([Graphic]) => {
        this.$esri.view.graphics.removeAll();
        const points = this.items.map((item)=> {
          return new Graphic({
            geometry: {
              type: 'point',
              latitude: item.location.lat,
              longitude: item.location.lon
            },
            symbol: pointSymbolConfig
          });
        });

        this.$esri?.view?.graphics?.addMany(points);
      })
      .catch((err) => {
        // handle any errors
        Sentry && Sentry.captureException(err);
        console.error(err);
      });
    }
  }
}

</script>

<style scoped>
@import "https://js.arcgis.com/4.23/esri/themes/light/main.css";
:deep(.esri-attribution) {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}
:deep(.esri-attribution__link) {
  color: white !important;
}

#searchMap {
  height: 100%;
}
</style>
