<template>
  <section class="arkCard">
    <header class="row mb-2">
      <div class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">Overview</h2>
      </div>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <button
          class="btn secondaryButton my-0 ml-0 mr-1 p-0 boxShadowNone align-self-center"
          aria-label="Delete this project"
          @click="deleteProjectModalOpen = true"
        >
          <i
            class="fa fa-trash edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </button>
      </section>
    </header>
    <section class="row py-2">
      <article class="DesktopTextSmall grayscaleBody m-0 col-12" v-html="project?.project?.description" />
    </section>
    <section v-if="project?.about_video || project?.about_video_url" class="row">
      <div class="col-12">
        <video
          v-if="project.about_video"
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered  vjs-16-9"
          controls
          width="auto"
          height="auto"
          preload="auto"
          data-setup="{}"
        >
          <source
            v-for="(video, index) in project?.videos"
            :key="index + video.url"
            :src="video.url"
            :type="video.type"
          >
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener">
              supports HTML5 video
            </a>
          </p>
        </video>
        <video
          v-else
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered  vjs-16-9"
          controls
          width="auto"
          height="auto"
          preload="auto"
          tech-order="['youtube', 'html5']"
          data-setup="{}"
        >
          <source
            :src="project.about_video_url"
            type="video/youtube"
          >
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a
            web browser that
            <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener">
              supports HTML5 video
            </a>
          </p>
        </video>
      </div>
    </section>
    <section v-if="project.organization" aria-label="Project's organization info">
      <h3 class="DesktopLinkSmall secondary7_ark">Organisation</h3>
      <a
        class="DesktopTextSmall grayscaleBody"
        :href="`/organizations/${project?.organization?.id}`"
      >
        {{ project?.organization?.name }}
      </a>
    </section>
    <section v-if="project.project.start_date || project.project.end_date" aria-label="Timeframe">
      <h3 class="DesktopLinkSmall secondary7_ark">Timeframe</h3>
      <p class="DesktopTextSmall grayscaleBody mb-0">
        {{ formatDate(project?.project?.start_date, project?.project?.end_date) }}
      </p>
    </section>
    <section v-if="project.species.length > 0" aria-label="IUCN Red List of Threatened Species">
      <h3 class="DesktopLinkSmall secondary7_ark">IUCN Red List of Threatened Species</h3>
      <p class="DesktopTextSmall grayscaleBody mb-0">
        <a
          v-for="(item,index) in project.species"
          :key="item.id + '-iucn'"
          class="pointer"
          :href="`/species/${item.id}`"
        >
          {{ index + 1 === project.species.length ? item.name : item.name + ',&nbsp;' }}
        </a>
      </p>
    </section>
    <section
      v-if="Object.keys(project.project.tags).length > 0"
      aria-label="Tags"
    >
      <h3 class="DesktopLinkSmall secondary7_ark">Tags</h3>
      <p class="DesktopTextSmall grayscaleBody mb-0">
        <a
          v-for="(tag,index) in project.project.tags"
          :key="tag.id + '-tag'"
          class="pointer"
          :href="`/search?search=${tag.name}`"
        >
          {{ tag.name + (index === project.project.tags.length - 1 ? '' : ',&nbsp;') }}
        </a>
      </p>
    </section>
    <section 
      v-if="Object.keys(project.project.categories).length > 0"
      aria-label="Tags"
    >
      <h3 class="DesktopLinkSmall secondary7_ark">Categories</h3>
      <p class="DesktopTextSmall grayscaleBody mb-0">
        <a
          v-for="(category,index) in project.project.categories"
          :key="category.id + '-category'"
          class="pointer"
          :href="`/search?search=${category.name}`"
        >
          {{ category.name + (index === project.project.categories.length - 1 ? '' : ',&nbsp;') }}
        </a>
      </p>
    </section>
  </section>
  <avl-modal
    v-if="deleteProjectModalOpen"
    :is-open="deleteProjectModalOpen"
    size="fit-content"
    header-title=""
    @avleventclose="deleteProjectModalOpen = false"
  >
    <div class="delete-modal-body mt-3">
      Are you sure you want to delete project {{ project?.project?.title }} ? This is a permanent change.
    </div>
    <div class="row d-flex justify-content-center mt-3">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
          @click="deleteProjectModalOpen = false"
        >
          <div class="cancel-button">Cancel</div>
        </button>
        <button
          type="button"
          class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
          style="text-transform: none"
          @click="deleteProject"
        >
          <div class="delete-button">Delete</div>
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>
import { DateTime } from 'luxon';
import { fallbackApi } from '../../../../api/config';

export default {
  name: 'ProjectOverview',
  props: {
    model: {
      type: String,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    projectType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      deleteProjectModalOpen: false,
      player: null
    };
  },
  mounted() {
    if (this.$refs.videoPlayer) this.player = videojs(this.$refs.videoPlayer);
  },
  beforeUnmount() {
    if (this.player) this.player.dispose();
  },
  methods: {
    formatDate(startDate, endDate) {
      if (startDate && endDate) {
        return DateTime.fromISO(startDate).toFormat('dd MMMM yyyy') +
          ' to ' + DateTime.fromISO(endDate).toFormat('dd MMMM yyyy');
      } else {
        const date = startDate ? startDate : endDate;
        return DateTime.fromISO(date).toFormat('dd MMMM yyyy');
      }
    },
    deleteProject() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}`,
        httpVerb: 'delete',
        dataSetter: () => {
          location.href = `/organizations/${this.project?.organization?.id}`;
        }
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
