<template>
  <section>
    <div v-if="Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <organization-header
        v-if="organizationHeaderInfo"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organizationHeaderInfo"
        :organization-type="organizationType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
        @set-organization-following="
          organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
        "
      />
      <team-card
        :model="model"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
      />
    </div>
  </section>
</template>

<script>
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import OrganizationHeader from '../../organizations/sharedComponents/OrganizationHeader.vue';
import TeamCard from './TeamCard.vue';
import { modelMixin } from '../../../mixins';

export default {
  components: {
    OrganizationHeader,
    LoadingIndicator,
    TeamCard
  },
  mixins: [modelMixin],
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#model-team');
      this.getModelDetails(modelInfo);
    }
  }
};
</script>
