<template>
  <main class="row justify-content-center mt-5 px-5 px-md-0 mx-0">
    <section class="col-12 col-md-10 col-lg-10 col-xl-6 text-center">
      <header>
        <h1 class="DesktopDisplaySmall">Create a page on THE ARK</h1>
        <p class="DesktopTextSmall mt-4">
          Connect with wildlife conservation organisations, investors, donors,
          scientists and technology providers. To get started, choose a page type.
        </p>
      </header>
      <section class="row justify-content-center mt-5">
        <button
          class="btn btn-link typeButton col-12 col-md-3 mx-4"
          aria-label="Select non profit type"
          @click="selectType('WildlifeOrg')"
        >
          <img :src="images?.collaborate" alt="Globe" class="typeImg" aria-hidden="true">
          <br>
          <span class="DesktopLinkXSmall warningDarkmode">Project Developer</span>
          <br>
          <span class="DesktopTextXSmall grayscaleOffBlack typeText">
            Dedicated to conserving nature anywhere in the world
          </span>
        </button>
        <button
          class="btn btn-link typeButton col-12 col-md-3 mx-4"
          aria-label="Select investor type"
          @click="selectType('InvestorOrg')"
        >
          <img :src="images?.invest" alt="Jar" class="typeImg" aria-hidden="true">
          <br>
          <span class="DesktopLinkXSmall warningDarkmode">Investor</span>
          <br>
          <span class="DesktopTextXSmall grayscaleOffBlack typeText">
            Entities interested in financing conservation impact
          </span>
        </button>
        <button
          class="btn btn-link typeButton col-12 col-md-3 mx-4"
          aria-label="Select technology type"
          @click="selectType('TechOrg')"
        >
          <img :src="images?.data" alt="Data" class="typeImg" aria-hidden="true">
          <br>
          <span class="DesktopLinkXSmall warningDarkmode">Company</span>
          <br>
          <span class="DesktopTextXSmall grayscaleOffBlack typeText">
            Technology or product providers, R&D specialists
          </span>
        </button>
      </section>
    </section>
  </main>
</template>

<script>

export default {
  props: {
    images: {
      type: Object,
      required: true
    }
  },
  emits: ['selectType'],
  methods: {
    selectType(type) {
      this.$emit('selectType', type);
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped>
.typeButton {
  background: #FCFCFC;
  border-radius: 8px;
  border: 1px solid #FCFCFC;
}

.typeButton:hover, .typeButton:focus {
  background: #FCFCFC;
  border: 1px solid #BDBFD2;
}

.typeImg {
  height: 50px;
}

.typeText {
  white-space: break-spaces;
}
</style>

