<template>
  <section class="maxWidthInputs">
    <form>
      <section class="text-left mt-4">
        <fieldset>
          <legend class="sr-only">{{ modelPluralize === 'projects' ? 'Project' : 'Organization' }} team members:</legend>
          <div class="row">
            <div class="col-12">
              <Multiselect
                v-model="form.selectedMembers"
                placeholder="Search ARK user by name"
                :options="availableOptions"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                track-by="name"
                label="name"
                required
                :disabled="loading"
                class="multiselect-ark"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged || !!errorMessage"
        @click="submit"
      >
        Save
      </button>
    </div>
    <info-notice
      v-show="modelPluralize === 'organizations' && onlyOneAdmin"
      notice-text="This page has only one administrator, modifying the role will result in losing administrative access to the page. This means that no one will be able to post updates or manage the page's content."
      class="py-3 mb-4"
    />
    <div v-show="items.members" class="py-3 mb-4">
      <ul class="listStyleNone p-0">
        <li
          v-for="partner in items.members"
          :key="partner.id + partner.name"
        >
          <section class="py-2">
            <div class="row">
              <figure class="col-2">
                <img
                  v-if="partner.avatar"
                  :src="partner.avatar"
                  class="avatarImage"
                  :alt="partner.name + 's avatar'"
                >
              </figure>
              <article class="col">
                <h3 class="DesktopLinkSmall primaryDefault mb-2 mt-0">
                  {{ partner.name }}
                </h3>
                <section v-if="this.modelPluralize === 'organizations'">
                  <Multiselect
                    v-if="userRoles.includes(partner.role) && !notAllowedMemberUpdate(partner.role)"
                    v-model="partner.role"
                    placeholder="Role"
                    :options="userRolesExtended"
                    mode="single"
                    :close-on-select="true"
                    :searchable="true"
                    track-by="name"
                    label="name"
                    :disabled="loading"
                    class="multiselect-ark"
                    :can-clear="false"
                    @select="updateMember(partner.id, partner.role)"
                  >
                    <template #option="{ option }">
                      <div class="options-container">
                        <div class="DesktopLinkSmall">{{ option.name }}</div>
                        <div class="DesktopTextSmall">{{ option.description }}</div>
                      </div>
                    </template>
                  </Multiselect>
                  <p
                    v-else
                    class="DesktopTextSmall grayscaleBody mb-0"
                  >
                    {{ partner.role }}
                  </p>
                </section>
              </article>
              <p
                class="
                  col-1
                  d-flex
                  justify-content-end
                "
              >
                <span
                  v-show="allowedMemberDelete(partner.role)"
                  class="btn btn-link px-2"
                  @click.prevent="deleteMember(partner.id)"
                >
                  <i class="fas fa-trash fa-lg" />
                </span>
              </p>
            </div>
          </section>
        </li>
      </ul>
      <ul v-if="modelPluralize === 'organizations'" class="listStyleNone p-0">
        <li
          v-for="(invited, index) in items.invited_members"
          :key="invited.email + '-' + index"
        >
          <section class="py-2">
            <div class="row">
              <figure class="col-2">
                <img
                  v-if="defaultProfile"
                  :src="defaultProfile"
                  class="avatarImage"
                  alt="Default user's avatar"
                >
              </figure>
              <article class="col">
                <h3 class="DesktopLinkSmall primaryDefault mb-0 mt-0">
                  {{ invited.email }}
                </h3>
                <h4 class="MobileTextXSmall grayscaleLabel mb-0 mt-0">
                  Invited
                </h4>
                <button
                  class="
                    btn
                    secondaryButton
                    DesktopLinkXSmall
                    primaryDarkmode
                    boxShadowNone
                    p-0 m-0
                    backTag
                  "
                  :disabled="loadingNewInvitation"
                  @click="resendInvitation(invited.email)"
                >
                  Resend invite
                </button>
                <h5 class="MobileTextXSmall grayscaleLabel mb-0 mt-0">
                  Last sent {{ formatDate(invited.updated_at) }}
                </h5>
              </article>
              <p
                class="
                  col-1
                  d-flex
                  justify-content-end
                "
              >
                <span
                  class="btn btn-link px-2"
                  @click.prevent="deleteInvitedMember(invited.id)"
                >
                  <i class="fas fa-trash fa-lg" />
                </span>
              </p>
            </div>
          </section>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { DateTime } from 'luxon';
import ErrorMessage from './ErrorMessage.vue';
import InfoNotice from './InfoNotice.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../api/config';
import { sharedMixin } from '../../mixins';

export default {
  components: { InfoNotice, ErrorMessage, Multiselect },
  mixins: [sharedMixin],
  props: {
    items: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    defaultProfile: {
      type: String,
      required: true
    }
  },
  emits: ['save-items', 'delete-member', 'delete-invitation', 'resend-invitation'],
  data() {
    return {
      loading: false,
      loadingNewInvitation: false,
      availableOptions: [],
      totalOptions: [],
      form: {
        selectedMembers: []
      },
      userRoles: ['Basic', 'Administrator'],
      userRolesExtended: [
        {
          value: 'Basic',
          name: 'Basic',
          description: 'Only able to view the page as a regular visitor.'
        },
        {
          value: 'Administrator',
          name: 'Administrator',
          description: "Can post updates or manage the page's content."
        }
      ],
      errorMessageFromBackend: '',
      errorMessage: ''
    };
  },
  computed: {
    dataChanged() {
      return this.form.selectedMembers.length !== 0;
    },
    // Check if team has only one admin
    onlyOneAdmin() {
      let roleToMatch = 'Administrator';
      let matchingObjects = this.items.members.filter((o) => o.role === roleToMatch);
      let numberOfMatchingObjects = matchingObjects.length;
      return numberOfMatchingObjects == 1;
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    //** Get all ARK user options
    getUsers() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/available_user_pool`,
        dataSetter: (data) => {
          this.totalOptions = data.users;
          this.totalOptions.forEach((option) => {
            // we need this because of multiselect component
            option.value = option.id;
          });
          this.filterOptions();
        }
      });
    },
    //** Filter items options to not include existing partners
    filterOptions() {
      this.availableOptions = this.totalOptions;
      let member_ids = this.items.members.map((i) => i['id']);
      this.availableOptions = this.availableOptions.filter(
            (item) => !member_ids.includes(item.id)
            );
    },
    //** Submit new team member selections
    async submit() {
      if (this.form.selectedMembers.length !== 0) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/members`,
          payload: { json: { members: this.form.selectedMembers }},
          httpVerb: 'post'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.form.selectedMembers = [];
          this.$emit('save-items', response.data);
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => {
          this.filterOptions();
          this.loading = false;
        });
      }
    },
    //** Update team member's role
    updateMember(id, selectedRole) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/members`,
        payload: {
          json: {
            user_id: id,
            role: selectedRole
          }
        },
        httpVerb: 'patch'
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.filterOptions();
        this.loading = false;
      });
    },
    //** Delete a team member
    deleteMember(id) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/members`,
        payload: {
          json: { member_id: id }
        },
        httpVerb: 'delete'
      })
      .then(() => {
        this.$emit('delete-member', id);
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.filterOptions();
        this.loading = false;
      });
    },
    deleteInvitedMember(id) {
      this.loading = true;
      fallbackApi({
        url: `invitations/${id}`,
        payload: {
          json: { organization_id: this.modelId }
        },
        httpVerb: 'delete'
      })
      .then(() => {
        this.$emit('delete-invitation', id);
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.loading = false;
      });
    },
    error(error) {
      this.errorMessage = error? error.main + '' : '';
    },
    discardChanges() {
      this.form.selectedMembers = [];
      this.errorMessage = '';
    },
    notAllowedMemberUpdate(role) {
      let roleToMatch = 'Administrator';
      return (this.onlyOneAdmin && role === roleToMatch);
    },
    allowedMemberDelete(role) {
      if (this.modelPluralize === 'projects') return true;
      let roleToMatch = 'Administrator';
      return this.userRoles.includes(role) && !(this.onlyOneAdmin && role === roleToMatch);
    },
    resendInvitation(email) {
      this.loadingNewInvitation = true;
      fallbackApi({
        url: 'invitations',
        payload: { json: { emails: [email], organization_id: this.modelId }},
        httpVerb: 'post'
      })
      .then((data) => {
        this.loadingNewInvitation = false;
        this.$emit('resend-invitation', data.data);
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
        this.loadingNewInvitation = false;
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date).toRelative();
    }
  }
};
</script>

<style scoped src="../../stylesheet/lists.css"></style>
<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
.options-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.options-container div {
  flex: 1;
}
</style>