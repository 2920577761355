<template>
  <div>
    <div v-if="model === 'organization' && Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <organization-header
      v-else-if="model === 'organization' && organizationHeaderInfo"
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :is-admin="isAdmin"
      :organization="organizationHeaderInfo"
      :organization-type="organizationType"
      :active-tab="activeTab"
      @update-image="updateHeaderImage"
      @set-organization-following="
        organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
      "
    />
    <project-header
      v-else-if="model === 'project' && project"
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :is-admin="isAdmin"
      :project="project"
      :project-type="projectType"
      :active-tab="activeTab"
      @update-image="updateHeaderImage"
    />
    <goal-statistics-card
      :model="model"
      :model-id="modelId"
      :date="date"
      :goals-changed-indicator="goalsChangedIndicator"
    />
    <metric-plot-card
      :model="model"
      :model-id="modelId"
      :entries-changed-indicator="entriesChangedIndicator"
    />
    <sdg-distribution
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :impact-page="true"
    />
    <goals-card
      :model="model"
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :is-admin="isAdmin"
      @goal-data-changed="goalDataChanged"
      @entry-data-changed="entryDataChanged"
    />
  </div>
</template>

<script>
import GoalStatisticsCard from './GoalStatisticsCard.vue';
import GoalsCard from './GoalsCard.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import MetricPlotCard from './MetricPlotCard.vue';
import OrganizationHeader from '../../organizations/sharedComponents/OrganizationHeader.vue';
import ProjectHeader from '../../projects/sharedComponents/ProjectHeader.vue';
import SdgDistribution from '../../portfolio/components/SdgDistribution.vue';
import { modelMixin } from '../../../mixins';

export default {
  components: {
    GoalsCard,
    GoalStatisticsCard,
    MetricPlotCard,
    OrganizationHeader,
    LoadingIndicator,
    SdgDistribution,
    ProjectHeader
},
  mixins: [modelMixin],
  data() {
    return {
      date: '',
      goalsChangedIndicator: true,
      entriesChangedIndicator: true
    };
  },
  methods: {
    getModel() {
      const goalsCard = document.querySelector('#js-goals-card');
      this.date = goalsCard?.dataset.dataDate;
      this.getModelDetails(goalsCard);
    },
    // eslint-disable-next-line no-unused-vars
    goalDataChanged(reason) {
      this.goalsChangedIndicator = !this.goalsChangedIndicator;
    },
    // eslint-disable-next-line no-unused-vars
    entryDataChanged(reason) {
      this.entriesChangedIndicator = !this.entriesChangedIndicator;
    }
  }
};
</script>
