import { mapCardThemes } from '../../../domain/enumerationHelper';

const mutations = {
  saveOrganizations(state, payload) {
    state.organizations = payload.filter((item) => item.type === 'WildlifeOrg')
      .map((item) => {
        item.organizationType = item.type;
        item.type = mapCardThemes.organization;
        return item;
      });

    state.techOrgs = payload.filter((item) => item.type === 'TechOrg')
      .map((item) => {
        item.organizationType = item.type;
        item.type = mapCardThemes.tech_org;
        return item;
      });
  },
  saveTechOrgs(state, payload) {
    state.techOrgs = payload.map((item) => {
      item.type = mapCardThemes.tech_org;
      return item;
    });
  },
  saveProjects(state, payload) {
    state.projects = payload.map((item) => {
      item.type = mapCardThemes.project;
      return item;
    });
  }
};

export default mutations;
