<template>
  <info-modal
    v-if="metricModalOpen"
    :info-modal-open="metricModalOpen"
    :info="{ name: metric.title }"
    @modal-closed-event="closeMetricModal"
  >
    <template #body>
      <section>
        <article>
          <h3 class="row DesktopLinkSmall textBodyColor">
            Description
          </h3>
          <p class="row DesktopTextSmall text-left">
            {{ metric.definition }}
          </p>
        </article>
        <article v-if="guidanceText">
          <h3 class="row DesktopLinkSmall textBodyColor">
            Usage guidance
          </h3>
          <p class="wordBreakWord row DesktopTextSmall text-left whiteSpacePrewrap">
            {{ guidanceText }}
          </p>
        </article>
        <article>
          <h3 class="row DesktopLinkSmall textBodyColor">
            Data source
          </h3>
          <input
            v-model="dataSource"
            class="wordBreakWord row DesktopTextSmall text-left whiteSpacePrewrap"
            :class="isAdmin ? 'incognito-input' : 'disabled-incognito-input'"
            :placeholder="dataSourcePlaceholder"
            :disabled="!isAdmin"
          >
        </article>
        <article>
          <h3 class="row DesktopLinkSmall textBodyColor">
            Means of verification
          </h3>
          <input
            v-model="verificationMeans"
            class="wordBreakWord row DesktopTextSmall text-left whiteSpacePrewrap"
            :class="isAdmin ? 'incognito-input' : 'disabled-incognito-input'"
            :placeholder="verificationMeansPlaceholder"
            :disabled="!isAdmin"
          >
        </article>
      </section>
    </template>
    <template #button>
      <section class="row mb-3">
        <div class="col-7">
          <Multiselect
            v-model="status"
            class="multiselect-ark"
            placeholder="Status"
            :options="statusOptions"
            track-by="name"
            label="name"
            value-prop="id"
            aria-label="Select Status"
            :close-on-select="true"
            :searchable="true"
            :disabled="!isAdmin"
          />
        </div>
        <div v-if="isAdmin" class="col-5 text-left">
          <button
            :class="`
              btn p-0 m-0 pointer select-metric
              DesktopLinkXSmall grayscaleOffWhite
              ${buttonType}
            `"
            :aria-label="actionType"
            :disabled="!isEntryFrequencySelected"
            @click="decideAction"
          >
            {{ actionType }}
          </button>
        </div>
      </section>
    </template>
    <template #detailsBody>
      <section class="row px-4 py-2">
        <div class="col-6">
          <div class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">Reporting Format</div>
        </div>
        <div class="col-6">
          <div class="row DesktopLinkXSmall p-1 text-left">
            {{ metric.format }}
          </div>
        </div>
      </section>
      <section v-if="metric.sdg_goals?.length > 0" class="row px-4 py-2">
        <div class="col-6">
          <div class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">SDGs</div>
        </div>
        <div class="col-6">
          <figure role="group" class="row DesktopLinkXSmall p-1 text-left my-0">
            <figure
              v-for="sdg in [...metric.sdg_goals].sort((a, b) =>
                a.sdg_goal_code - b.sdg_goal_code
              )"
              :key="sdg.sdg_goal_code"
              class="m-0"
              role="group"
            >
              <img
                class="img-fluid shadow border-radius-lg pl-1 pb-1 sdgImage"
                :src="sdg.avatar"
                :alt="sdg.title"
              >
            </figure>
          </figure>
        </div>
      </section>
      <section v-if="metric.metric_id" class="row px-4 py-2">
        <div class="col-6">
          <div class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">Source</div>
        </div>
        <div class="col-6">
          <div class="row DesktopTextSmall p-1 text-left">
            <a
              class="teal500"
              :href="sourceUrl"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ sourceName }}
            </a>
          </div>
        </div>
      </section>
      <section v-if="isMetricANumber || isMetricACurrency" class="row px-4 py-2">
        <div class="col-6">
          <span class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">Units</span>
        </div>
        <div class="col-6">
          <div class="row p-1 text-left">
            <Multiselect
              v-model="measurementUnit"
              class="multiselect-ark"
              placeholder="Select Units"
              track-by="name"
              label="name"
              value-prop="id"
              aria-label="Select Unit"
              open-direction="top"
              :options="isMetricANumber ? measurementOptions : [{ id: 0, name: 'Euros'}]"
              :close-on-select="true"
              :searchable="true"
              :disabled="!isAdmin || !isMetricANumber"
            />
          </div>
        </div>
      </section>
      <section class="row px-4 py-2">
        <div class="col-6">
          <span class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">Reporting &nbsp; Frequency *</span>
        </div>
        <div class="col-6">
          <div class="row p-1 text-left">
            <Multiselect
              v-model="entryFrequency"
              class="multiselect-ark"
              placeholder="Select Frequency"
              track-by="name"
              label="name"
              value-prop="id"
              aria-label="Select Frequency"
              open-direction="top"
              required
              :options="entryFrequencyOptions"
              :close-on-select="true"
              :searchable="true"
              :disabled="!isAdmin"
            />
          </div>
        </div>
      </section>
      <section v-for="field in auditorFields" :key="field.id" class="row px-4 py-2">
        <div class="col-6">
          <span class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">{{ field.name }} </span>
        </div>
        <div class="col-6">
          <div class="row p-1 text-left">
            <Multiselect
              v-model="this[field.id]"
              class="multiselect-ark"
              placeholder="Select user"
              track-by="name"
              label="name"
              value-prop="id"
              :aria-label="`Select ${field.name}`"
              open-direction="top"
              :options="auditorOptions"
              :close-on-select="true"
              :searchable="true"
              :create-tag="true"
              :object="true"
              :disabled="!isAdmin"
            />
          </div>
        </div>
      </section>
    </template>
  </info-modal>

  <delete-entries-modal
    :modal-open="warningModalOpen"
    @close-modal="warningModalOpen = false"
    @confirm="selectOrUpdateMetric"
  />
</template>

<script>
import DeleteEntriesModal from './DeleteEntriesModal.vue';
import InfoModal from './InfoModal.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../api/config';

const metricNumberTypes = [
  'Number',
  'Unit of Measure (PD1602)',
  'Unit of Measure (PD1602) selection per Hectare'
];
const metricCurrencyTypes = [
  'Currency',
  'Reporting Currency (OD5990)'
];

export default {
  name: 'MetricDetailCard',
  components: { DeleteEntriesModal, InfoModal, Multiselect },
  props: {
    metricModalOpen: {
      type: Boolean,
      required: true
    },
    metric: {
      type: Object,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    measurementOptions: {
      type: Array,
      required: true
    },
    entryFrequencyOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    auditorOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['closeModal', 'onRemove', 'onSelect', 'onUpdate'],
  data() {
    return {
      measurementUnit: null,
      entryFrequency: null,
      status: null,
      collectedBy: null,
      reportedBy: null,
      evaluatedBy: null,
      dataSource: null,
      verificationMeans: null,
      warningModalOpen: false,
      auditorFields: [
        { id: 'collectedBy', name: 'Collected by' },
        { id: 'reportedBy', name: 'Reported by' },
        { id: 'evaluatedBy', name: 'Evaluated by' }
      ]
    };
  },
  computed: {
    guidanceText() {
      if (this.metric.guidance) {
        return this.metric.guidance.map((item) => item.note).join('\n');
      } else return this.metric.usage_guidance;
    },
    sourceName() {
      return `IRIS+ v${this.metric.version} Metric ${this.metric.metric_id}`;
    },
    sourceUrl() {
      return `https://iris.thegiin.org/metric/${this.metric.version}/${this.metric.metric_id}/`;
    },
    isSelectAvailable() {
      return this.metric.vgt_id !== undefined;
    },
    isMetricSelected() {
      return this.metric.vgtSelected;
    },
    isMetricANumber() {
      return metricNumberTypes.includes(this.metric.format);
    },
    isMetricACurrency() {
      return metricCurrencyTypes.includes(this.metric.format);
    },
    isMetricModified() {
      return (
        this.isMeasurementUnitModified ||
        this.isEntryFrequencyModified ||
        this.isStatusModified ||
        this.areAuditorsModified ||
        this.isDataSourceModified ||
        this.isMeansOfVerificationModified
      );
    },
    isMeasurementUnitModified() {
      return this.measurementUnit !== this.metric.measurement_unit?.id;
    },
    isEntryFrequencyModified() {
      return this.entryFrequency !== this.metric.entry_frequency?.id;
    },
    isStatusModified() {
      return this.status !== this.metric.status?.id;
    },
    isEntryFrequencySelected() {
      return !!this.entryFrequency;
    },
    isDataSourceModified() {
      return this.dataSource !== this.metric.data_source;
    },
    isMeansOfVerificationModified() {
      return this.verificationMeans !== this.metric.means_of_verification;
    },
    areAuditorsModified() {
      return (
        (this.collectedBy?.id !== this.metric.collected_by?.id &&
          this.collectedBy?.name !== this.metric.collected_by?.name) ||
        (this.reportedBy?.id !== this.metric.reported_by?.id &&
          this.reportedBy?.name !== this.metric.reported_by?.name) ||
        (this.evaluatedBy?.id !== this.metric.evaluated_by?.id &&
          this.evaluatedBy?.name !== this.metric.evaluated_by?.name)
      );
    },
    actionType() {
      if (this.isSelectAvailable && !this.isMetricSelected) return 'Select';
      if (this.isMetricModified) return 'Update';
      return 'Remove';
    },
    buttonType() {
      if (['Select', 'Update'].includes(this.actionType)) return 'primaryButton';
      return 'errorButton';
    },
    dataSourcePlaceholder() {
      if (this.isAdmin) return 'Click to add data source';
      return '-';
    },
    verificationMeansPlaceholder() {
      if (this.isAdmin) return 'Click to add means of verification';
      return '-';
    }
  },
  beforeMount() {
    this.measurementUnit = this.metric.measurement_unit?.id;
    this.entryFrequency = this.metric.entry_frequency?.id;
    this.status = this.metric.status?.id;
    this.dataSource = this.metric.data_source;
    this.verificationMeans = this.metric.means_of_verification;

    // Set auditor info in the form, using name as id if it's not set
    // so that multiselect works correctly with free text
    this.collectedBy =
      this.metric?.collected_by && !this.metric.collected_by.id ?
        { id: this.metric.collected_by.name, name: this.metric.collected_by.name } :
        this.metric?.collected_by;

    this.reportedBy =
      this.metric?.reported_by && !this.metric.reported_by.id ?
        { id: this.metric.reported_by.name, name: this.metric.reported_by.name } :
        this.metric?.reported_by;

    this.evaluatedBy =
      this.metric?.evaluated_by && !this.metric.evaluated_by.id ?
        { id: this.metric.evaluated_by.name, name: this.metric.evaluated_by.name } :
        this.metric?.evaluated_by;
  },
  methods: {
    selectOrUpdateMetric() {
      const actionEndpoint = this.actionType === 'Select' ? 'add' : 'update';
      const apiUrl = `goals/${this.metric.goalId}/${actionEndpoint}_goal_metric`;
      const metricTypeKey = this.metric.iris_metric_type ? 'iris_metric_id' : 'ark_metric_def_id';
      const measurementUnitPayload = this.isMetricANumber ?
        { measurement_unit_id: this.measurementUnit } : {};
      const httpVerb = this.actionType === 'Select' ? 'post' : 'patch';

      const apiPayload = {
        json: {
          [metricTypeKey]: this.metric.id,
          ...measurementUnitPayload,
          entry_frequency_id: this.entryFrequency,
          status_id: this.status,
          collected_by: this.collectedBy?.id,
          reported_by: this.reportedBy?.id,
          evaluated_by: this.evaluatedBy?.id,
          data_source: this.dataSource,
          means_of_verification: this.verificationMeans
        }
      };

      fallbackApi({
        url: apiUrl,
        payload: apiPayload,
        httpVerb: httpVerb,
        dataSetter: (data) => {
          this.$emit(`on${this.actionType}`, data.goal_metric);
        }
      });
    },
    removeMetric() {
      this.$emit('onRemove');
    },
    decideAction() {
      if (this.actionType === 'Remove') {
        this.removeMetric();
      } else if (this.actionType === 'Update' && this.isEntryFrequencyModified) {
        this.warningModalOpen = true;
      } else {
        this.selectOrUpdateMetric();
      }
    },
    closeMetricModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>
<style scoped>
.select-metric {
  width: 113px;
  height: 40px;
}

.incognito-input {
  border: none;
  border-radius: 0.25rem;
  width: 100%;
  color: rgb(78, 75, 102);
}

.incognito-input:hover {
  background-color: rgba(230, 230, 230, 0.658);
  cursor: pointer;
}

.incognito-input:focus {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.disabled-incognito-input {
  border: none;
  border-radius: 0.25rem;
  width: 100%;
  background-color: transparent;
}
</style>
