<template>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("tryToGetUser");
  }
};
</script>
