<template>
  <div>
    <div class="DesktopDisplayMedium">
      {{ headerText }}
    </div>
    <ul class="section-list p-0 mt-4">
      <li v-for="(pt, index) in sectionData" :key="'A' + index" class="p-0 mt-3 d-flex align-items-start">
        <i class="fas fa-check-circle mr-2 primaryDefault"></i>
        <span class="DesktopTextMedium">{{ pt }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      required: true,
    },
    sectionData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.section-list {
  list-style: none; /* Remove default bullet points from the list */
  display: flex; /* Use Flexbox for the container (.section-list) */
  flex-wrap: wrap; /* Allow items to wrap when there's not enough space */
}

/* Adjust the marker size */
.section-list i {
  margin-top: 6px;
  font-size: 25px;
}
</style>
