// Project show view milestones chart
const containerDiv = d3.select('div#milestonesContainer');
if(!containerDiv.empty()) {
  const milestones = containerDiv.attr('data-milestones');
  const data = JSON.parse(milestones).map(m => {
    m.date = moment(m.date)
    return m;
  });
  createMilestonesChart(containerDiv, data);
}


function createMilestonesChart(container, data) {
  function scrollLeftTween(scrollLeft) {
    return function() {
      var i = d3.interpolateNumber(this.scrollLeft, scrollLeft);
      return function(t) { this.scrollLeft = i(t); };
    };
  }

  function disableButtons() {
    const scrollWidth = graphContainer.property('scrollWidth');
    const scrollLeft = graphContainer.property('scrollLeft');
    const clientWidth = graphContainer.property('clientWidth');
    timelineContainer.select('#prevButton')
      .classed('disabled', clientWidth >= scrollWidth || scrollLeft === 0);
    timelineContainer.select('#nextButton')
      .classed('disabled', clientWidth >= scrollWidth || scrollLeft+clientWidth >= scrollWidth);
  }

  function showDetails(milestones) {
    // Clear divs and add new data
    details.selectAll('div.milestoneDetails').data([]).exit().remove();
    const detailContainer = details.selectAll('div.milestoneDetails').data(milestones)
      .enter()
      .append('div').classed('milestoneDetails', true);

    detailContainer.append('h4')
      .style('font-weight', 'bold')
      .text(function(d) { return d.name });
    detailContainer.append('em')
      .text(function(d) { return d.date.format('DD.MM.YYYY') });
    detailContainer.append('p')
      .text(function(d) { return d.description });
  }

  // Get milestones as an object with dates as keys
  const datePoints = data.reduce(function(points, d) {
    const key = d.date.unix();
    if(Array.isArray(points[key])) {
      points[key] = [...points[key], d];
    }
    else {
      points[key] = [d];
    }
    return points;
  }, {});

  const dates = Object.keys(datePoints).sort();

  const length = dates.length,
    min = dates[0],
    max = dates[length-1],
    total = max - min || 1,
    margin = 70,
    width = window.innerWidth/3 > 2*margin ? window.innerWidth/3 : 2*margin,
    totalWidth = width + (length+1)*margin,
    height = 100;

  const timelineContainer = container.append('div').classed('d-flex', true).style('height', height + 'px');

  // Prev button
  timelineContainer.append('div').classed('d-flex flex-column justify-content-center', true)
    .append('button')
    .attr('id', 'prevButton')
    .classed('btn btn-primary1-outline-ark btn-fab w-h-38px btn-round d-flex align-items-center justify-content-center', true)
    .on('click', function() {
      graphContainer.transition().duration(500)
        .tween("uniquetweenname", scrollLeftTween(graphContainer.property('scrollLeft') - width/2))
        .on('end', disableButtons);
    })
    .append('i')
    .classed('fas fa-chevron-left', true);

  // Graph
  const graphContainer = timelineContainer.append('div')
    .style('overflow', 'hidden');

  // Next button
  timelineContainer.append('div').classed('d-flex flex-column justify-content-center', true)
    .append('button')
    .attr('id', 'nextButton')
    .classed('btn btn-primary1-outline-ark btn-fab w-h-38px btn-round d-flex align-items-center justify-content-center', true)
    .style('padding', '10px')
    .style('border-radius', '50%')
    .on('click', function() {
      graphContainer.transition().duration(500)
        .tween("uniquetweenname", scrollLeftTween(graphContainer.property('scrollLeft') + width/2))
        .on('end', disableButtons);
    })
    .append('i')
    .classed('fas fa-chevron-right', true);

  // Init svg graph
  const graph = graphContainer.append('svg')
    .attr('width', totalWidth)
    .attr('height', height);

  // set the gradient
  graph.append("linearGradient")
    .attr("id", "line-gradient")
    .attr("gradientUnits", "userSpaceOnUse")
    .attr("x1", 0).attr("y1", height/2)
    .attr("x2", totalWidth).attr("y2",  height/2)
  .selectAll("stop")
    .data([
      {offset: "0%", color: "#2d432f"},
      {offset: "30%", color: "#2d432f"},
      {offset: "40%", color: "#5f776a"},
      {offset: "50%", color: "#a7c1bf"},
      {offset: "60%", color: "#b3d0d1"},
      {offset: "70%", color: "#c5ded8"},
      {offset: "80%", color: "#d5dec0"},
      {offset: "90%", color: "#e1e3c9"},
      {offset: "100%", color: "#eae8d0"},
    ])
  .enter().append("stop")
    .attr("offset", function(d) { return d.offset; })
    .attr("stop-color", function(d) { return d.color; });

  // Add line
  var lineData = [ 
    { "x": 0,   "y": height/2},
    { "x": totalWidth,  "y": height/2},
  ];

  graph.append("path")
  .datum(lineData)
  .attr("fill", "none")
  .classed( "line", true)
  .attr("stroke-width", 8)
  .attr("d", d3.line()
    .x(function(d) { return d.x })
    .y(function(d) { return d.y })
    );

  // Add points
  const point = graph.selectAll('g')
    .data(dates)
    .enter()
    .append('g')
    .attr('transform', function(d, i) {
      return 'translate(' + ((i+1)*margin + ((d-min)/total*width)) + ',' + height/2 + ')';
    })
    .classed('selected', function(d, i) {
      return i === length-1;
    })
    .on('click', function(e, d) {
      graph.selectAll('g.selected').classed('selected', false);
      d3.select(this).classed('selected', true);
      showDetails(datePoints[d]);
    });

  point.append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', 12)
    .attr('fill', '#24422B')

  // Add date labels to points
  point.append('text')
    .attr('x', 0)
    .attr('y', -30)
    .style('text-anchor', 'middle')
    .style('font-size', '0.8rem')
    .text(function(d) { return datePoints[d][0].date.format('DD.MM.YYYY'); });
  
  // Add icons to point
  point.append('i')
    .attr('x', -2)
    .attr('y', -50)
    .attr('width', '4px')
    .classed('fas fa-info secondary7_ark', true);

  // Scroll to last point
  graphContainer.property('scrollLeft', graphContainer.property('scrollWidth'));
  disableButtons();

  // Init details container
  const details = container.append('div')
    .attr('class', 'container');

  // Show last point
  showDetails(datePoints[dates[length-1]]);
}
