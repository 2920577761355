<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10"
      >
        <a
          class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
              px-3
              backTag
            "
          :href="`/${modelPluralize}/${modelId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
        <h2 class="ml-2 mb-3 DesktopTextMedium grayscaleBody">
          Saved views
        </h2>
      </nav>
    </div>
  </section>
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-10">
      <loading-indicator
        v-if="loading"
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <div v-else class="arkCard mb-0">
        <main class="row justify-content-center">
          <div class="table-responsive col-12">
            <table class="table">
              <thead>
                <tr class="grayscaleBody">
                  <th>
                    <span class="DesktopLinkXSmall">Title</span>
                  </th>
                </tr>
              </thead>
              <tbody class="grayscaleOffBlack DesktopTextSmall">
                <tr v-for="view in portfolioViews" :key="view.id">
                  <td>
                    <div class="d-flex align-items-center">
                      <input
                        v-model="view.name"
                        type="text"
                        class="form-control ark DesktopTextSmall"
                        @change="updatePortfolioView(view.id, view.name)"
                        @input="trimValue(view.name)"
                      >
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <button
                        class="btn btn-link px-2"
                        :aria-label="'Delete portfolio view '+ view.name"
                        @click="deletePortfolioView(view.id)"
                      >
                        <i class="fas fa-trash fa-lg" aria-hidden="true" />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <p class="MobileTextSmall grayscaleOffBlack">Basic</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
        <error-message
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import { fallbackApi } from '../../../api/config';

export default {
  components: { ErrorMessage, LoadingIndicator },
  data() {
    return {
        model: '',
        modelPluralize: '',
        modelId: null,
        loading: true,
        portfolioViews: [],
        initialPortfolioViews: [],
        errorMessageFromBackend: ''
      };
    },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#js-saved-views');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
    },
    fetchData() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/portfolio_views`,
        dataSetter: (data) => {
          this.portfolioViews = data.portfolio_views;
          this.initialPortfolioViews = JSON.parse(JSON.stringify(data.portfolio_views));
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    trimValue(name) {
      if (/\s{1,}$/.test(name)) {
        name = name.trim() + ' ';
      }
      name = name.trimStart();
    },
    updatePortfolioView(id, name) {
      let updateView = this.portfolioViews.findIndex((view) => {
          return view.id === id;
        });

      name = name.trim();

      fallbackApi({
        url: `portfolio_views/${id}`,
        payload: { json: { name: name } },
        httpVerb: 'patch'
      })
      .then(() => {
        this.portfolioViews[updateView].name = name;
        this.initialPortfolioViews[updateView].name = name;
        this.errorMessageFromBackend = '';
      })
      .catch(async (error) => {
        if (error?.error?.response) {
          const errorMessage = await error.error.response.json();
          this.errorMessageFromBackend = `${errorMessage.error}`;
        } else {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again later.';
        }
        this.portfolioViews[updateView].name = this.initialPortfolioViews[updateView].name;
      });
    },
    deletePortfolioView(id) {
      fallbackApi({
        url: `portfolio_views/${id}`,
        httpVerb: 'delete'
      })
      .then(() => {
        this.portfolioViews = this.portfolioViews.filter((view) => {
          return view.id !== id;
        });
        this.errorMessageFromBackend = '';
      })
      .catch(async (error) => {
        if (error?.error?.response) {
          const errorMessage = await error.error.response.json();
          this.errorMessageFromBackend = `${errorMessage.error}`;
        } else {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again later.';
        }
      });
    }
  }
};
</script>

<style scoped>
/* TODO: extract */
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
    margin-top: 10px
  }
}
</style>
