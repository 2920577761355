import api from '../../../api/config';

let filterByLocation = (data) => {
  return data.filter((datum) => {
    try{
      if(datum.location) {
        datum['lng'] = datum.location.lon;
        datum['lat'] = datum.location.lat;
        return true;
      } else {
        throw Error();
      }
    } catch(filteringError) {
      console.error('\'', datum.name, '\' has not valid location');
      return false;
    }
  });
};

const actions = {
  saveEntities(context, payload) {
    return new Promise((resolve, reject) => {
      try {
        context.commit(`save${payload.action}`, payload.data);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  fetchAndSaveAllEntities(context) {
    return new Promise((resolve, reject) => {
      try {
        let allEntities = 0;
        api
          .get('organizations/index_summary')
          .then((response) => response.json())
          .then((data) => {
            const fetchedData = filterByLocation(data['organizations']);
            context.commit('saveOrganizations', fetchedData);
            allEntities += 1;
            if (allEntities === 2) resolve();
          })
          .catch((error) => {
            console.warn(error);
            Sentry && Sentry.captureException(error);
          });

          context.commit('saveTechOrgs', []);

          api
            .get('projects/index_summary')
            .then((response) => response.json())
            .then((data) => {
              const fetchedData = filterByLocation(data['projects']);
              context.commit('saveProjects', fetchedData);
              allEntities += 1;
              if (allEntities === 2) resolve();
            })
            .catch((error) => {
              console.warn(error);
              Sentry && Sentry.captureException(error);
            });
      } catch (fetchingAndSavingError) {
        reject(fetchingAndSavingError);
      }
    });
  }
};

export default actions;
