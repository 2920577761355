import 'v-calendar/dist/style.css';
import NewProject from './components/NewProject.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#new-project')) {
    const app = createApp(NewProject);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#new-project');
  }
});
