class ImageHelper {
  static setupModal($modal) {
    $modal.on('shown.bs.modal', function () {
      $modal.attr('aria-expanded', true);
    })
    // Register filepond plugins
    $.fn.filepond.registerPlugin(
      FilePondPluginFileValidateType,
      FilePondPluginFileValidateSize,
      FilePondPluginImagePreview,
      FilePondPluginFileMetadata,
    );
    var maxTotalFileSize = '12MB'
    $.fn.filepond.setOptions({
        maxTotalFileSize: maxTotalFileSize,
        acceptedFileTypes: "image/png, image/jpeg, image/jpg, image/webpm",
        allowMultiple: true,
        itemInsertLocation: 'after'
    });
    // Turn input element into a pond
    $('.my-pond').filepond();
    const speciesNames = document.querySelector('.js-species');
    const speciesNamesString = speciesNames.dataset.list;
    const speciesNamesArray = JSON.parse("[" + speciesNamesString + "]");

    // Listen for addfile event
    $('.my-pond').on('FilePond:addfile', function(e) {
      var pond_file_id = e.detail.file.id;
      if (e.detail.error === null) {
        // Add caption input element
        $(`#filepond--item-${pond_file_id}`).append(
          `<input id="filepond--item-${pond_file_id}-caption" class="form-control ark ark__image_caption mt-2 mb-1" placeholder="Caption" required="required" type="text" name="image[caption]">`
          );
        // Set file caption to metadata
        e.detail.file.setMetadata('image-file-caption', '')
        $(`#filepond--item-${pond_file_id}-caption`).change(function(){
          e.detail.file.setMetadata('image-file-caption', $(this).val());
        });
        // Create species multiselect dropdown
        var $select = $("<select/>", {
          'class':'selectpicker form-control ark filepond--item-select',
          'id': `filepond--item-${pond_file_id}-select`,
          'data-style': 'btn btn-link pl-0 pt-0',
          'data-none-selected-text': 'Species tag',
          'data-live-search': 'true'
        });
        $select.prop('multiple', 'multiple');
        for (var idx in speciesNamesArray) {
          for (var s_idx in speciesNamesArray[idx]) {
            $select.append(`<option value="${speciesNamesArray[idx][s_idx]}">${speciesNamesArray[idx][s_idx]}</option>`);
          }
        }
        $select.appendTo(`#filepond--item-${pond_file_id}`).selectpicker('refresh');
        // Increase li item z-index on click
        $(`#filepond--item-${pond_file_id}-select`).on('show.bs.select', function(evt, clickedIndex, isSelected, previousValue) {
          $(`#filepond--item-${pond_file_id}`).addClass('z-3');
        });
        $(`#filepond--item-${pond_file_id}-select`).on('hide.bs.select', function(evt, clickedIndex, isSelected, previousValue) {
          $(`#filepond--item-${pond_file_id}`).removeClass('z-3');
        });
        e.detail.file.setMetadata('image-file-species-tags', '')
        // Set species tags to metadata
        $(`#filepond--item-${pond_file_id}-select`).on('changed.bs.select', function(evt, clickedIndex, isSelected, previousValue) {
          e.detail.file.setMetadata('image-file-species-tags', $(this).val());
        });
      }
    });
  }

  static showDeleteAlert(html, redirect, objID){
    new swal({
      html: html,
      showCancelButton: redirect,
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-default',
      confirmButtonClass: 'btn btn-secondary1-ark',
      buttonsStyling: false
    }).then(function(result) {
      if (result.isConfirmed && redirect) {
        fetch(`/media/images/${objID}`, {
          method: 'DELETE',
          headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") }
        }).then((response) => {
          window.location.reload();
        }).catch((error) => {
          Sentry && Sentry.captureException(error)
        });
      } else {
        console.log('Aborting deletion');
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
    });

  }
}

let modal = $('#imageModal');
if(modal.length > 0) {
  ImageHelper.setupModal(modal);
}

let editModal = $('#editImageModal');
if(editModal.length > 0) {
  ImageHelper.setupModal(editModal);
}

$('.js-delete-image').on('click', function() {
  const objID = $(this).attr('data-image-id');
  const redirect = true;
  ImageHelper.showDeleteAlert(`Are you sure you want to delete this resource? This is a permanent change.`, redirect, objID);
});

$('.js-edit-image').on('click', function() {
  const showPath = $(this).attr('data-show-path');
  fetch(showPath)
  .then((response) => response.json())
  .then((json) => {
    const csrf = $('meta[name="csrf-token"]').attr('content');
    $('#editImageModal form').attr('action', showPath);
    $('#editImageModal input[name="authenticity_token"]').val(csrf);
    $('#editImageModal input[name="caption"]').val(json.image.caption);
    $("#editImageModal #editImageModalSelect").val(json.species_ids);
    $("#editImageModal #editImageModalSelect").selectpicker('refresh');
    $('#editImageModal').modal('show');
  });
});

// Save images
$('.submit-images-form').click(function(e){
  e.preventDefault();
  const $form = $('#addImagesForm');
  const url = $form.attr('action');
  const formData = new FormData();

  var files = $('.my-pond').filepond('getFiles');
  var file_ids = [];
  $(files).each(function (index) {
    // Check for PROCESSING_COMPLETE status code
    filepond_status_errors = [6, 10, 7, 8];
    if (!filepond_status_errors.includes(files[index].status)){
      formData.append(`file-${files[index].id}`, files[index].file);
      formData.append(`caption-${files[index].id}`, files[index].getMetadata()['image-file-caption']);
      formData.append(`species-${files[index].id}`, files[index].getMetadata()['image-file-species-tags'].toString());
      file_ids.push(files[index].id);
    }
  });
  formData.append('file_ids', file_ids);
  formData.append('view', $('#addImagesFormView').val());
  formData.append('view_id', $('#addImagesFormViewId').val());
  formData.append('share', $('#image_share').val());
  fetch(url,
    { method: 'POST',
      headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content },
      body: formData
    }).then((response) => {
      if (response.ok) {
        window.location.reload();
      } else {
        Sentry && Sentry.captureException(response);
      }
    })
    .catch((error) => {
      Sentry && Sentry.captureException(error);
    });

});
