<template>
  <div v-show="chartData?.length !== 0" class="arkCard mb-0">
    <header class="row justify-content-center my-3">
      <div class="col-12">
        <h2 v-if="impactPage" class="DesktopTextMedium primaryDefault">SDG Exposure</h2>
        <h2 v-else class="ml-2 mb-0 DesktopLinkXSmall grayscaleBody">
          SDG EXPOSURE
        </h2>
      </div>
    </header>
    <div class="row">
      <div class="col-12">
        <div id="metricBySdg" />
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { fallbackApi } from '../../../api/config';

export default {
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    impactPage: {
      type: Boolean,
      required: false,
      default: false
    },
    viewId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      chartData: [],
      chart: undefined
    };
  },
  watch: {
    chartData() {
      this.renderHighchart();
    },
    viewId: {
      handler() {
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const url = this.viewId ? `?view_id=${this.viewId}` : '';
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sdg_metric_distribution${url}`,
        dataSetter: ({ data }) => {
          this.chartData = data;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    renderHighchart() {
      if (!this.chartData.length) {
        this.chart?.destroy();
        this.chart = undefined;
        return;
      }
      this.chart = Highcharts.chart('metricBySdg', {
        chart: {
          type: 'column',
          marginBottom: 80
        },
        title: {
          text: null
        },
        legend: {
          enabled: false  // Disable the legend to hide it
        },
        xAxis: {
          // Categories will be used as labels for positioning sdg avatars
          categories: this.chartData.map((obj) => obj.sdg_goal_code),
          images: this.chartData.map((obj) => obj.full_avatar || obj.avatar),
          labels: {
            x: -15,
            y: 15
          }
        },
        yAxis: {
          title: {
            text: 'Number of indicators'
          },
          tickInterval: 1 // Set tickInterval to 1 to display only integer tick marks
        },
        tooltip: {
          formatter: function() {
            const dataPoint = this.series.data[this.point.index];
            return dataPoint.tooltip + ': ' + this.point.y;
          }
        },
        series: [{
          name: 'Number of indicators',
          data: this.chartData.map((obj) => ({
            y: obj.count,
            image: obj.full_avatar || obj.avatar,
            tooltip: `SDG ${obj.sdg_goal_code} ${obj.sdg_short_name}`,
            color: obj.color
          }))
        }]
      });
    }
  }
};
</script>
