<template>
  <footer
    class="row"
    :class="{'borderTop': borderTop}"
    style="margin-bottom: -0.9375rem; margin-left: -20px; margin-right: -20px;"
  >
    <div class="col-12 justify-content-center d-flex">
      <a
        v-if="link"
        class="
          btn
          secondaryButton
          DesktopLinkXSmall
          grayscaleLabel
          boxShadowNone
        "
        :href="link"
      >{{ text }}
        <i
          v-if="icon"
          class="fa fa-arrow-right pl-2"
          aria-hidden="true"
        />&nbsp;&nbsp;&nbsp;
      </a>
      <button
        v-else
        class="
          btn
          secondaryButton
          DesktopLinkXSmall
          grayscaleLabel
          boxShadowNone
        "
        @click="showMore"
      >{{ text }}
        <i
          v-if="icon"
          class="fa fa-arrow-right pl-2"
          aria-hidden="true"
        />&nbsp;&nbsp;&nbsp;
      </button>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    icon: {
      type: Boolean,
      required: false,
      default: true
    },
    borderTop: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['showMore'],
  methods: {
    showMore() {
      this.$emit('showMore');
    }
  }
};
</script>

<style scoped>
.borderTop {
    border-top: 1px solid #BDBFD2;
}
</style>
