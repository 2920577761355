import './components';
import './features';
import App from './App.vue';
import ExportData from 'highcharts/modules/export-data';
import Exporting from 'highcharts/modules/exporting';
import Heatmap from 'highcharts/modules/heatmap';
import Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import { createApp } from 'vue';
import { store } from './store';

// Initialize highcharts with desired modules
accessibility(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);
Heatmap(Highcharts);

// Fix for image labels in exports
// Sources:
// https://www.highcharts.com/forum/viewtopic.php?t=50242
// https://jsfiddle.net/BlackLabel/786weuo0/
function renderImageLabels(H) {
  const iconSize = 30;

  H.wrap(H.Tick.prototype, 'renderLabel', function addImages(proceed) {
    proceed.apply(this, [].slice.call(arguments, 1));

    const tick = this;
    const axis = this.axis;
    const images = axis.options.images;
    const xy = this.label && this.label.xy;

    const image = images && images[tick.pos];

    if (image && xy) {
      if (!tick.image) {
        tick.image = axis.chart.renderer.image(image, xy.x, xy.y, iconSize, iconSize).add();
      } else {
        tick.image.animate({
          x: xy.x,
          y: xy.y
        });
      }
    }

    // Hide text labels to show only images
    this.label && this.label.hide();
  });
}

renderImageLabels(Highcharts);

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App);
  app.use(store);
  app.mount('#vue-app');
});
