class CommentHelper {
  static openCommentModal($commentBtn) {
    const top_news_id = $commentBtn.attr('data-top-news-id');
    const $modal = $('#commentModal');
    $modal.find('#social_top_news_comment_top_news_id').val(top_news_id);
    $modal.find('.news-post-form-field').val('');

    $modal.modal('show');
  }

  static setupModal($modal, type) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let embeddedUrlRegex=/iframe/
    let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    var post_urls = new Array();

    $modal.find('#social_top_news_comment_as_organization').change(function() {
      $modal.find('.user-image').toggleClass('d-none');
      $modal.find('.organization-image').toggleClass('d-none');
      $modal.find('.organization-image').toggleClass('d-flex');
      $modal.find('.tech-org-image').toggleClass('d-none');
      $modal.find('.tech-org-image').toggleClass('d-flex');
    });

    let jelement = $modal.find('trix-editor');
    let url_attachment_area = $modal.find('#url_attachment');
    let url_attachment_html_field = $modal.find('#url_attachment_html_field');
    let element = jelement[0];
    let $iframeClose = $('.iframe-close');
    element.editor.loadHTML("");
    url_attachment_area.html("");
    url_attachment_html_field.val("");

    element.addEventListener('trix-change', function(evt) {
      let document = element.editor.getDocument();
      let text = document.toString();
      let statusErrors = [403, 404, 422];
      text.replace(urlRegex, function(url) {
        // We are going to get the embedding here
        $.ajax({
          type: "POST",
          headers: { 'X-CSRF-Token': csrfToken },
          url: '/ogp',
          data: { url: url },
          success: function(d) {
            // Check if url & iframe is found
            if (!('status' in d) && !(d.status in statusErrors) && ('html' in d)) {
              // Check if url same as before
              if (!post_urls.includes(d.url)) {
                post_urls.push(d.url);
                url_attachment_area.html(d.html);
                url_attachment_html_field.val(d.html);
                $iframeClose.removeClass('d-none');
              }
            }
          }
        });
        return url;
      })
    });
    $iframeClose.on('click', function() {
      url_attachment_area.html("");
      url_attachment_html_field.val("");
      $iframeClose.addClass('d-none');
    });

    $modal.on('shown.bs.modal', function() {
      if (type === "new") {
        post_urls = new Array();
        url_attachment_area.html("");
        url_attachment_html_field.val("");
        $iframeClose.addClass('d-none');
      }
      if (type === "edit") {
        post_urls = element.editor.getDocument().toString().match(urlRegex) || [];
        if (url_attachment_area.html() === '') {
          $iframeClose.addClass('d-none');
        }
        else {
          $iframeClose.removeClass('d-none');
        }
      }

      $modal.attr('aria-expanded', true);
      $modal.find('#commentInput').trigger('focus');
    });

    const $form = $modal.find('form');
    $form.on('submit', () => $modal.modal('hide'));

    $form.on('ajax:error', function(e) {
      Sentry && Sentry.captureException(e);
    });
  }

  static showCommentDeleteAlert(html, redirect, commentId){
    new swal({
      html: html,
      showCancelButton: redirect,
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-default',
      confirmButtonClass: 'btn btn-secondary1-ark',
      buttonsStyling: false
    }).then(function(result) {
      if (result.isConfirmed && redirect) {
        $.ajax({
          type: 'DELETE',
          dataType: 'script',
          url: `/social/top_news_comments/${commentId}`,
          error: function(error) {
            Sentry && Sentry.captureException(error);
          }
        });
      } else {
        console.log('Aborting deletion');
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
    });
  }

  static setupCommentButtons() {
    const commentBtns = $('.js-comment-btn');
    if (commentBtns) {
      commentBtns.each(function( index ) {
        $(this).off('click');
        $(this).on('click', function(){
          CommentHelper.openCommentModal($(this));
        });
      });
    }
    CommentHelper.setupCommentDeleteButtons();
    CommentHelper.setupCommentEditButtons();
  }

  static setupCommentDeleteButtons() {
    $('.js-delete-comment').off('click');
    $('.js-delete-comment').on('click', (evt) => {
      const commentId = evt.currentTarget.dataset.commentId;
      const redirect = true;
      this.showCommentDeleteAlert(`Are you sure you want to delete this comment? This is a permanent change.`, redirect, commentId);
    });
  }

  static setupCommentEditButtons() {
    $('.js-edit-comment').off('click');
    $('.js-edit-comment').on('click', function() {
      const showPath = $(this).attr('data-show-path');
      fetch(showPath)
        .then((response) => response.json())
        .then((json) => {
          const csrf = $('meta[name="csrf-token"]').attr('content');
          const $modal = $('#editCommentModal');
          const $form = $modal.find('form');
          $form.attr('action', showPath);
          $form.on('submit', function() {
            $modal.modal('hide');
          });
          $form.on('ajax:error', function(e) {
            Sentry && Sentry.captureException(e);
          });

          $modal.find('input[name="authenticity_token"]').val(csrf);
          $modal.find('#top_news_id').val(json.comment.top_news_id);
          // Show image of user or organization depending on comment owner
          $modal.find('.user-image').toggleClass('d-none', json.comment.as_organization || json.comment.as_tech_org);
          $modal.find('.organization-image').toggleClass('d-none', !json.comment.as_organization);
          $modal.find('.organization-image').toggleClass('d-flex', json.comment.as_organization);
          $modal.find('.tech-org-image').toggleClass('d-none', !json.comment.as_tech_org);
          $modal.find('.tech-org-image').toggleClass('d-flex', json.comment.as_tech_org);

          const editor = $modal.find('trix-editor').get(0);
          editor.editor.loadHTML(json.content);
          const url_attachment = $('#editCommentModal #url_attachment');
          url_attachment.html(json.url_attachment);
          const url_attachment_html_field = $('#editCommentModal #url_attachment_html_field');
          url_attachment_html_field.val(json.url_attachment);

          $modal.modal('show');
        });
    });
  }
}

let commentModal = $('#commentModal');
if(commentModal.length > 0) {
  CommentHelper.setupModal(commentModal, "new");
}

let editCommentModal = $('#editCommentModal');
if(editCommentModal.length > 0) {
  CommentHelper.setupModal(editCommentModal, "edit");
}

export { CommentHelper };
