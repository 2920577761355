<template>
  <div class="arkCard mb-0">
    <header class="row justify-content-center my-3">
      <div class="col-12">
        <h2 class="ml-2 mb-0 DesktopLinkXSmall grayscaleBody">
          COUNTRY EXPOSURE
        </h2>
      </div>
    </header>
    <div class="row">
      <div class="col-md-4">
        <MultipleLocationsMapPreview
          :locations="locations"
        />
      </div>
      <div class="col-md-8">
        <PieChart
          :the-data="chartData"
          series-name="Country"
          chart-div-id="countryExposure"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MultipleLocationsMapPreview from '../../../components/itemMap/components/MultipleLocationsMapPreview.vue';
import PieChart from '../../../components/shared/PieChart.vue';
import { fallbackApi } from '../../../api/config';

export default {
  components: {
    MultipleLocationsMapPreview,
    PieChart
  },
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    viewId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      chartData: [],
      locations: [],
      chart: undefined
    };
  },
  watch: {
    viewId: {
      handler() {
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const url = this.viewId ? `?view_id=${this.viewId}` : '';
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/country_exposure${url}`,
        dataSetter: ({ data }) => {
          this.chartData = data.countries;
          this.locations = data.locations;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    }
  }
};
</script>