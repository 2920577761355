const chartColors = [
  '#c5bd74',
  '#99a29a',
  '#cce0b7',
  '#5e7971',
  '#759aa6',
  '#c7e1e4',
  '#5c7c6c',
  '#7d9c92',
  '#b0af74',
  '#36533e',
  '#f6f8f5',
  '#6c847c',
  '#94b4ac',
  '#ece6cc',
  '#647c74',
  '#9fc0bc',
  '#c7c289',
  '#bcdcdf',
  '#557362',
  '#b4dce0',
  '#94b4b4',
  '#add1d2',
  '#a4ccc9',
  '#cddcc5',
  '#648474',
  '#4c6c5c',
  '#e4ecec',
  '#dae4c4',
  '#4c6c54',
  '#a4aca4',
  '#e4e4c9',
  '#d4e4bc',
  '#d9d4a6',
  '#cde4d4',
  '#c4dcd4',
  '#c8ccc8',
  '#bcdcd4',
  '#b4bcb4',
  '#e0dcb7',
  '#cce4cc',
  '#c4e4d4'
];

const heatmapTemplate = {
  chart: {
    type: 'heatmap',
    marginTop: 80,
    marginBottom: 80,
    plotBorderWidth: 1
  },
  series: [{
    name: 'Heatmap data',
    data: []
  }],
  legend: {
    enabled: true,
    align: 'left',
    layout: 'horizontal',
    margin: 10,
    verticalAlign: 'bottom',
    y: 5,
    symbolHeight: 20,
    symbolWidth: 20,
    symbolRadius: 0,
    itemStyle: {
      fontFamily: 'Poppins',
      fontSize: '13px',
      fontWeight: 400,
      cursor: 'pointer'
    },
    itemHiddenStyle: {
      color: '#9EA0B3'
    },
    labelFormatter: function() {
      return `<span style="color: #000000">${this.name}</span>`;
    }
  }
};

const miniChartTemplate = {
  chart: {
    width: 80,
    height: 56
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      enableMouseTracking: false
    }
  },
  tooltip: {
    enabled: false
  },
  yAxis: {
    visible: false
  },
  xAxis: {
    visible: false
  }
};

const pieChartTemplate = {
  chart: {
    type: 'pie'
  },
  title: {
    useHTML: true,
    floating: true,
    verticalAlign: 'middle',
    margin: 0
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [{
    name: 'DefaultData',
    innerSize: '50%',
    data: []
  }]
};

const splineChartTemplate = {
  chart: {
    type: 'spline',
    parallelCoordinates: true,
    parallelAxes: {
      lineWidth: 2
    }
  },
  plotOptions: {
    series: {
      accessibility: {
        enabled: false
      },
      animation: false,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      states: {
        hover: {
          halo: {
            size: 0
          }
        }
      },
      events: {
        mouseOver: function () {
          this.group.toFront();
        }
      }
    }
  },
  lang: {
    accessibility: {
      axis: {
        yAxisDescriptionPlural: 'The chart has 3 Y axes across the chart displaying ....'
      }
    }
  },
  colors: ['rgba(36, 66, 43, 0.1)'],
  series: [{
    name: 'Default Data',
    data: [0,0,0]
  }]
};

export { chartColors, heatmapTemplate, miniChartTemplate, pieChartTemplate, splineChartTemplate };
