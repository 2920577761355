<template>
  <section class="arkCard">
    <header class="row mb-3">
      <section
        class="d-flex align-items-center"
        :class="currentUserProfile ? 'col-12 col-sm-10 col-md-10 col-lg-11' : 'col-12'"
      >
        <section class="d-flex">
          <a
            v-if="backLink"
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              boxShadowNone
              backTag p-0 mr-3
           "
            :href="backLink"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />
          </a>
          <h3 class="DesktopTextMedium primaryDefault mt-0 mb-0">{{ userName }}'s Activity</h3>
        </section>
      </section>
      <div
        v-if="currentUserProfile"
        class="
          col-12 col-sm-2 col-md-2 col-lg-1
          d-flex
          justify-content-start
          justify-content-sm-end
          align-items-start
        "
      >
        <button
          class="btn btn-sm DesktopLinkXSmall borderedButton transition-02 btn-round"
          @click="startPost"
        >
          Start a post
        </button>
      </div>
    </header>
    <section v-if="noPosts">
      <p class="DesktopLinkSmall primaryDefault mb-1 mt-2">{{ subtitle }}</p>
      <p class="DesktopTextSmall grayscaleBody mb-1">{{ paragraph }}</p>
    </section>
    <slot name="body" />
  </section>
</template>

<script>
export default {
  props: {
    currentUserProfile: {
      type: Boolean,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    noPosts: {
      type: Boolean,
      required: true
    },
    backLink: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['startPost'],
  computed: {
    subtitle() {
      return (
        this.currentUserProfile ?
        "You haven't posted lately" :
        `${this.userName} hasn't posted lately`
      );
    },
    paragraph() {
      return (
        this.currentUserProfile ?
        'Recent posts you share or comment on will be displayed here' :
        `Recent posts ${this.userName} shares or comments on will be displayed here`
      );
    }
  },
  methods: {
    startPost() {
      this.$emit('startPost');
    }
  }
};
</script>

<style scoped>
.backTag {
  color: #24422B !important;
}
</style>