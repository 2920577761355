const mutations = {
  mutateUser(state, payload) {
    state.user = payload.user;
  },
  updateOrganizationLogoOrBanner(state, payload) {
    state.user.organization[payload.key] = payload.logo;
  },
  updateUserLogoOrBanner(state, payload) {
    state.user[payload.key] = payload.avatar;
  },
  updateUserPortfolioAccess(state, payload) {
    state.user.org_portfolio_access = payload?.org_portfolio_access;
  }
};

export default mutations;
