class UsersHelper {
  static renderActivityToggles(container) {
    var buttons = container.querySelectorAll('button');
    buttons.forEach(btn => {
      btn.addEventListener('click', () => {
        const currentBtn = container.querySelector('.active');
        const currentDiv = document.querySelector(currentBtn.getAttribute('data-target'));
        const newDiv = document.querySelector(btn.getAttribute('data-target'));
        
        currentBtn.classList.remove('active');
        currentDiv.classList.add('d-none');
        btn.classList.add('active');
        newDiv.classList.remove('d-none');
      });
    });
  }
}

let activityToggles = document.querySelector('.activity-toggles');
if(activityToggles) {
  UsersHelper.renderActivityToggles(activityToggles);
}
