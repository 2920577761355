
<template>
  <div class="forgot-password text-center textBodyColor">
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <section class="arkCard text-center py-5 mb-3">
          <div class="text-left">
            <h2 class="DesktopTextMedium mb-3">Check your inbox to log in</h2>
            <p class="DesktopTextSmall m-0">
              To complete setup and log in, click the verification link in the email we have sent to
              <span class="DesktopLinkSmall">{{ email }}</span>
            </p>
          </div>
          <hr class="mt-5 mb-4">
          <button
            class="btn btn-link p-0 m-0 MobileLinkSmall warningDarkmode"
            :disabled="loading"
            @click="resendEmail"
          >
            Resend verification email
          </button>
        </section>
        <span class="DesktopTextSmall grayscaleOffBlack">
          If you do not see the email check your spam folder.
        </span>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    email: {
      type: String,
      required: true
    }
  },
  emits: ['resendEmail'],
  methods: {
    resendEmail() {
      this.$emit('resendEmail');
    }
  }
};

</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.forgot-password {
  padding-top: 75px;
}

.arkCard {
  padding-left: 15%;
  padding-right: 15%;
}
</style>
