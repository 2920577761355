<template>
  <div :class="labelStartDate ? 'col-12' : 'row justify-content-left'">
    <div v-if="labelStartDate" class="DesktopTextSmall grayscaleOffBlack d-flex p-2 col-12">
      {{ labelStartDate }}
    </div>
    <div class="text-left" :class="classStartDate">
      <v-date-picker
        v-model="localStartDate"
        :min-date="minStartDate"
        :max-date="localEndDate || ''"
        :disabled="loading"
        :model-config="modelConfig"
        :masks="masks"
        color="yellow"
        :popover="{
          visibility: 'click',
          placement: $screens({ default: 'bottom', lg: 'right-end' })
        }"
      >
        <template #default="{ inputValue, inputEvents, togglePopover }">
          <div
            class="input-group input-group-dynamic mb-4 mt-3"
            :class="
              errorOnsubmit && !localStartDate ? 'invalid-date' : ''
            "
          >
            <input
              class="form-control ark DesktopTextSmall noBorderRight"
              aria-label="Start Date"
              type="text"
              :placeholder="placeholderStartDate"
              :value="inputValue"
              :disabled="loading"
              aria-describedby="startDateIcon"
              v-on="inputEvents"
            >
            <span
              id="startDateIcon"
              role="button"
              tabindex="0"
              class="input-group-text inputIcon pointer"
              aria-label="Click to open the calendar and select start date"
              @click="togglePopover()"
              @keypress.prevent="togglePopover()"
            >
              <i
                class="fa fa-calendar-o fa-lg"
                aria-hidden="true"
              />
            </span>
          </div>
        </template>
      </v-date-picker>
      <error-message
        :error-condition="errorOnsubmit && !localStartDate"
        :error-message="errorMessageStartDate"
      />
    </div>
  </div>
  <div :class="labelEndDate ? 'col-12' : 'row justify-content-left'">
    <div v-if="labelEndDate" class="DesktopTextSmall grayscaleOffBlack d-flex p-2 col-12">
      {{ labelEndDate }}
    </div>
    <div class="text-left" :class="classEndDate">
      <v-date-picker
        v-model="localEndDate"
        :min-date="localStartDate || new Date()"
        :max-date="maxEndDate"
        :disabled="loading"
        :model-config="modelConfig"
        :masks="masks"
        :popover="{
          visibility: 'click',
          placement: $screens({ default: 'bottom', md: 'top', lg: 'right-end' })
        }"
        color="yellow"
      >
        <template #default="{ inputValue, inputEvents, togglePopover }">
          <div
            class="input-group input-group-dynamic mb-4 mt-3"
            :class="
              errorOnsubmit && !localEndDate ? 'invalid-date' : ''
            "
          >
            <input
              class="form-control ark DesktopTextSmall noBorderRight"
              aria-label="End date"
              aria-describedby="endDateIcon"
              type="text"
              :placeholder="placeholderEndDate"
              :value="inputValue"
              :disabled="loading"
              v-on="inputEvents"
            >
            <span
              id="endDateIcon"
              role="button"
              tabindex="0"
              class="input-group-text inputIcon pointer"
              aria-label="Click to open the calendar and select end date"
              @click="togglePopover()"
              @keypress.prevent="togglePopover()"
            >
              <i
                class="fa fa-calendar-o fa-lg"
                aria-hidden="true"
              />
            </span>
          </div>
        </template>
      </v-date-picker>
      <error-message
        :error-condition="errorOnsubmit && !localEndDate"
        :error-message="errorMessageEndDate"
      />
    </div>
  </div>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
  components: { ErrorMessage },
  props: {
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    errorOnsubmit: {
      type: Boolean,
      required: true
    },
    placeholderStartDate: {
      type: String,
      required: false,
      default: 'Start Date *'
    },
    placeholderEndDate: {
      type: String,
      required: false,
      default: 'End Date *'
    },
    errorMessageStartDate: {
      type: String,
      required: false,
      default: 'Start date is a required field.'
    },
    errorMessageEndDate: {
      type: String,
      required: false,
      default: 'End date is a required field.'
    },
    labelStartDate: {
      type: String,
      required: false,
      default: ''
    },
    labelEndDate: {
      type: String,
      required: false,
      default: ''
    },
    classStartDate: {
      type: String,
      required: false,
      default: 'col-sm-12 col-md-6 col-lg-5'
    },
    classEndDate: {
      type: String,
      required: false,
      default: 'col-sm-12 col-md-6 col-lg-5'
    }
  },
  emits: ['date-range'],
  data() {
    return {
      localStartDate: '',
      localEndDate: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD'
      },
      masks: {
        type: 'string',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD']
      }
    };
  },
  computed: {
    minStartDate() {
      if (!this.localEndDate) {
        return null;
      }

      const minDate = new Date(this.localEndDate);
      minDate.setFullYear(minDate.getFullYear() - 30);
      return minDate;
    },
    maxEndDate() {
      if (!this.localStartDate) {
        return null;
      }

      const maxDate = new Date(this.localStartDate);
      maxDate.setFullYear(maxDate.getFullYear() + 30);
      return maxDate;
    }
  },
  watch: {
    localStartDate() {
      this.saveDateRange();
    },
    localEndDate() {
      this.saveDateRange();
    }
  },
  mounted() {
    this.localStartDate = this.startDate;
    this.localEndDate = this.endDate;
  },
  methods: {
    saveDateRange() {
      this.$emit('date-range', this.localStartDate, this.localEndDate);
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
  .noBorderRight {
    border-right: 0px solid transparent;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .noBorderRight:focus {
    border-right: 1px solid #24422b;
    box-shadow: 0px 0px 4px 2px #a4b9b4;
  }

  .input-group {
    margin-bottom: 0px !important;
  }

  .inputIcon {
    display: block;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1em;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-left: none;
    border-radius: 0 4px 4px 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .invalid-date {
    border-radius: 4px;
    border: 1px solid red;
  }
</style>
