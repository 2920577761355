<template>
  <div>
    <section :class="cardClass">
      <header class="row">
        <section class="col-10 col-sm-11 d-flex align-items-center">
          <img
            v-show="logoOrAvatar"
            :src="logoOrAvatar"
            class="logoImageMax d-flex"
            :class="
              post.as_organization ?
                'objectFitContain' :
                'objectFitCover round'
            "
            alt=""
          >
          <div class="ml-3">
            <a
              class="DesktopLinkSmall grayscaleOffBlack mb-0 pointer"
              :href="authorURLFor(post)"
            >
              {{ authorName }}
            </a>
            <h3 class="DesktopTextXSmall grayscaleLabel my-0">
              {{ relativeTimeAgo(post.updated_at) }}
            </h3>
          </div>
        </section>
        <div class="col-2 col-sm-1 d-flex justify-content-end align-items-start">
          <button
            v-show="isPostAdmin || isCurrentUserAuthor(post)"
            class="btn btn-link p-0 m-0"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fal fa-ellipsis-h-alt fa-lg" aria-hidden="true" />
          </button>
          <div class="d-flex justify-content-end">
            <div class="dropdown-menu dropdown-primary">
              <button
                class="dropdown-item w-100 m-0"
                @click="updatePost"
              >
                Update post
              </button>
              <button
                class="dropdown-item w-100 m-0"
                @click="showDeleteModal = true"
              >
                Delete post
              </button>
            </div>
          </div>
        </div>
      </header>
      <section class="mb-3">
        <article class="content wordBreakWord mt-4" v-html="formattedPost.content" />
        <div
          class="url-attachment"
          v-html="formattedPost.url_attachment"
        />
      </section>
      <section v-if="post.parent_id" class="border rounded p-3 my-2">
        <header v-if="post.parent" class="d-flex align-items-start">
          <img
            v-if="logoOrAvatarSharePost"
            :src="logoOrAvatarSharePost"
            aria-hidden="true"
            class="logoImageMedium d-flex"
            :class="
              post.parent.as_organization ?
                'objectFitContain' :
                'objectFitCover round'
            "
            alt=""
          >
          <div
            v-else
            class="p-2 d-flex logoImageMedium align-items-center justify-content-center"
            :class="
              post.parent.as_organization ?
                'objectFitContain secondary7_bg_ark' :
                'objectFitCover'
            "
            aria-hidden="true"
          >
            <i
              class="align-items-center"
              :class="
                post.parent.as_organization
                  ? 'white fas fa-building iconSize30'
                  : 'fas fa-user-circle secondary7_ark iconSize40'
              "
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <a
              class="DesktopLinkSmall grayscaleOffBlack mb-0 pointer"
              :href="authorURLFor(post.parent)"
            >
              {{ post.parent.organization?.name || post.parent.user?.name }}
            </a>
          </div>
        </header>
        <header v-else>
          <h3 class="DesktopLinkSmall grayscaleOffBlack mt-0">
            Content not available
          </h3>
          <p class="DesktopTextXSmall grayscaleOffBlack mb-0">
            This post has been removed by its owner.
          </p>
        </header>
        <section v-if="post.parent" class="mt-3 mb-2 text-left">
          <div id="content" v-html="post.parent.content" />
        </section>
        <section v-if="post.parent" class="url-attachment-container">
          <div id="url-attachment" v-html="post.parent.url_attachment" />
        </section>
      </section>
      <section class="d-flex opacity07">
        <button
          v-if="post.likes > 0"
          class="btn likeButton grayscaleBody DesktopLinkXSmall p-1 my-0"
          @click="showLikesModalOpen(post)"
        >
          <span class="likesCount teal500" aria-hidden="true">
            <i class="fas fa-circle fa-stack-2x circleSize" aria-hidden="true" />
            <i
              class="fas fa-thumbs-up fa-stack-1x thumbsUpCommentSize fa-inverse"
              aria-hidden="true"
            />
          </span>
          {{ post.likes }}
        </button>
        <figure
          v-if="post.comments_count > 0"
          class="grayscaleBody DesktopLinkXSmall p-1 my-0"
        >
          <span class="likesCount grayscaleBody" aria-hidden="true">
            <i class="fas fa-circle fa-stack-2x circleSize" aria-hidden="true" />
            <i
              class="fas fa-comment-dots fa-stack-1x thumbsUpCommentSize fa-inverse"
              aria-hidden="true"
            />
          </span>
          {{ post.comments_count }}
        </figure>
      </section>
      <section>
        <button
          type="button"
          class="btn btn-link DesktopTextXSmall px-2 my-0"
          :class="post.has_liked ? 'teal500' : 'grayscaleBody'"
          @click="likePostOrComment(post)"
        >
          <i
            :class="post.has_liked ? 'fas' : 'fal'"
            class="fa-thumbs-up fa-lg mr-2"
            aria-hidden="true"
          />
          LIKE
        </button>
        <button
          type="button"
          class="btn btn-link grayscaleBody DesktopTextXSmall px-2 my-0"
          @click="commentPost(post)"
        >
          <i class="far fa-comment-dots fa-lg mr-2" aria-hidden="true" />
          COMMENT
        </button>
        <button
          type="button"
          class="btn btn-link grayscaleBody DesktopTextXSmall px-2 my-0"
          :disabled="!post.parent && post.parent_id"
          @click="sharePost(post)"
        >
          <i class="fas fa-share fa-lg mr-2" aria-hidden="true" />
          SHARE
        </button>
      </section>
      <section v-if="post.comments">
        <div v-for="comment in numberOfComments" :key="comment.id" class="row py-2">
          <figure class="col-2 col-sm-1">
            <img
              v-show="logoOrAvatarComment(comment)"
              :src="logoOrAvatarComment(comment)"
              class="logoImageMax d-flex"
              :class="
                comment.as_organization ?
                  'objectFitContain' :
                  'objectFitCover round'
              "
              alt=""
            >
          </figure>
          <section class="col-10 col-sm-11 px-3">
            <div class="secondary11_bg_ark p-2">
              <header class="row">
                <section
                  class="d-flex align-items-center"
                  :class="isCurrentUserAuthor(comment) ? 'col-10 col-sm-11' : 'col-12'"
                >
                  <div>
                    <a
                      class="DesktopLinkXSmall grayscaleOffBlack mb-0 pointer"
                      :href="authorURLFor(comment)"
                    >
                      {{ comment.author }}
                    </a>
                    <h3 class="DesktopTextXSmall grayscaleLabel my-0">
                      {{ relativeTimeAgo(comment.updated_at) }}
                    </h3>
                  </div>
                </section>
                <section
                  v-if="isCurrentUserAuthor(comment)"
                  class="d-flex justify-content-end align-items-start"
                  :class="isCurrentUserAuthor(comment) ? 'col-2 col-sm-1' : ''"
                >
                  <button
                    class="btn btn-link p-0 m-0"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fal fa-ellipsis-h-alt fa-lg" aria-hidden="true" />
                  </button>
                  <div class="d-flex justify-content-end">
                    <div class="dropdown-menu dropdown-primary">
                      <button
                        class="dropdown-item w-100 m-0"
                        @click="updateComment(comment)"
                      >
                        Update comment
                      </button>
                      <button
                        class="dropdown-item w-100 m-0"
                        @click="deleteComment(comment)"
                      >
                        Delete comment
                      </button>
                    </div>
                  </div>
                </section>
              </header>
              <article
                class="content wordBreakWord grayscaleOffBlack MobileTextSmall mt-1"
                v-html="formattedComment(comment).content"
              />
              <div
                class="url-attachment"
                v-html="formattedComment(comment).url_attachment"
              />
              <section class="d-flex mt-2">
                <button
                  type="button"
                  class="
                    btn btn-link
                    DesktopTextXSmall
                    normal-text
                    py-1 px-0 my-0
                  "
                  :class="comment.has_liked ? 'teal500' : 'grayscaleBody'"
                  @click="likePostOrComment(comment, false)"
                >
                  Like
                </button>
                <button
                  v-show="comment.likes > 0"
                  class="btn likeButton grayscaleBody DesktopLinkXSmall p-1 my-0"
                  @click="showLikesModalOpen(comment)"
                >
                  <span class="likesCount teal500" aria-hidden="true">
                    <i class="fas fa-circle fa-stack-2x circleSize" aria-hidden="true" />
                    <i
                      class="fas fa-thumbs-up fa-stack-1x thumbsUpCommentSize fa-inverse"
                      aria-hidden="true"
                    />
                  </span>
                  {{ comment.likes }}
                </button>
              </section>
            </div>
          </section>
        </div>
      </section>
      <footer v-show="!showAllComments && post.comments_count > 3">
        <div class="justify-content-center d-flex">
          <button
            class="
            btn py-0
            secondaryButton
            DesktopLinkXSmall
            grayscaleLabel
            boxShadowNone
          "
            @click="showAllComments = true"
          >
            Show all comments
          </button>
        </div>
      </footer>
    </section>
    <avl-modal
      header-title=""
      :is-open="showDeleteModal"
      @avleventclose="showDeleteModalClosed"
    >
      <h2 class="DesktopTextMedium p-2">{{ commentToDelete ? 'Delete comment' : 'Delete post' }}?</h2>
      <p class="DesktopTextSmall grayscaleBody mt-2 p-3 mb-0">
        Are you sure you want to permanently remove this
        {{ commentToDelete ? ' comment ' : ' post ' }}
        from THE ARK?
      </p>
      <div class="row d-flex justify-content-center mt-3">
        <div class="col-12 text-right">
          <error-message
            :error-condition="!!errorMessageFromBackend"
            :error-message="errorMessageFromBackend"
          />
        </div>
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn btn-link grayscaleBody ark-bold"
            @click="showDeleteModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-link secondary7_ark ark-bold"
            @click="confirmDeleting"
          >
            Delete
          </button>
        </div>
      </div>
    </avl-modal>
    <avl-modal
      header-title=""
      :is-open="showLikesModal"
      @avleventclose="showLikesModalClosed"
    >
      <user-card
        v-for="user in showUsersLikes"
        :key="user.id"
        :user="user"
        :author="user.id === currentUserId"
      />
      <show-more
        v-if="entity.all_likes?.length > showMoreUsers"
        text="See more"
        :icon="false"
        :border-top="false"
        @click="expandUsersList"
      />
    </avl-modal>
  </div>
</template>

<script>
import { debugFallbackApi, fallbackApi } from '../../api/config';
import { DateTime } from 'luxon';
import ErrorMessage from '../shared/ErrorMessage.vue';
import ShowMore from '../shared/ShowMore.vue';
import UserCard from './UserCard.vue';

export default {
  name: 'ArkPost',
  components: { ErrorMessage, UserCard, ShowMore },
  props: {
    post: {
      type: Object,
      required: true,
      default: function () {
        return null;
      }
    },
    author: {
      type: Object,
      required: false,
      default: function () {
        return null;
      }
    },
    isPostAdmin: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      }
    },
    cardClass: {
      type: String,
      required: false,
      default: function () {
        return 'arkCard';
      }
    }
  },
  emits: [
    'postDeleted',
    'editPost',
    'addComment',
    'editComment',
    'commentDeleted',
    'sharePost',
    'likeUnlikePostOrComment'
  ],
  data() {
    return {
      showDeleteModal: false,
      showAllComments: false,
      errorMessageFromBackend: '',
      commentToDelete: null,
      showLikesModal: false,
      showMoreUsers: 5,
      entity: [],
      currentUserId: null
    };
  },
  computed: {
    formattedPost() {
      return this.formatPost(this.post);
    },
    authorName() {
      return this.author?.name || this.post.user?.name || this.post.organization?.name;
    },
    logoOrAvatar() {
      const logo = this.author?.logo || this.post.organization?.logo || this.post.user?.avatar;
      if (logo) {
        return logo;
      } else return false;
    },
    logoOrAvatarSharePost() {
      if (
        this.post.parent.organization?.name &&
        this.post.parent.organization.name === this.author.name &&
        this.author.logo
      ) {
        return this.author.logo;
      }

      return this.post.parent.organization?.logo || this.post.parent.user?.avatar;
    },
    numberOfComments() {
      return this.showAllComments ? this.post.comments : this.post.comments.slice(0, 3);
    },
    showUsersLikes() {
      return this.entity.all_likes?.slice(0, this.showMoreUsers) || [];
    }
  },
  beforeMount() {
    this.currentUserId = this.$store.state.auth.user.id;
  },
  methods: {
    formatPost(post) {
      if (post != null && post.system && post.url_attachment) {
        const link = post.url_attachment.match(/href="(.*)"/)[1];
        const content = post.content.replace(
          /: (.*)<\/p><br><br>/,
          `: <a href="${link}" target="_blank" rel="noopener noreferrer">$1</a></p>`
        );
        return { ...post, url_attachment: null, content };
      }
      return post;
    },
    formattedComment(comment) {
      return this.formatPost(comment);
    },
    logoOrAvatarComment(comment) {
      if (
        comment.organization?.name &&
        comment.organization?.name === this.author.name &&
        this.author.logo
      ) {
        return this.author.logo;
      }
      return comment.organization?.logo || comment.user.avatar || null;
    },
    isCurrentUserAuthor(comment) {
      return this.$store.state.auth.user.id === comment.user_id ||
        (
          comment.organization_id &&
          this.$store.state.auth.user.organization?.id === comment.organization_id
        );
    },
    showDeleteModalClosed() {
      this.showDeleteModal = false;
      this.commentToDelete = null;
    },
    deleteComment(comment) {
      this.showDeleteModal = true;
      this.commentToDelete = comment;
    },
    confirmDeleting() {
      this.commentToDelete ? this.confirmDeletingComment() : this.confirmDeletingPost();
    },
    confirmDeletingPost() {
      debugFallbackApi({
        url: `social/top_news/${this.post.id}`,
        httpVerb: 'delete',
        resolveData: false
      })
      .then(() => {
        this.showDeleteModal = false;
        this.$emit('postDeleted', this.post.id);
        this.commentToDelete = null;
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      });
    },
    confirmDeletingComment() {
      debugFallbackApi({
        url: `social/top_news_comments/${this.commentToDelete.id}`,
        httpVerb: 'delete',
        resolveData: false
      })
      .then(() => {
        this.showDeleteModal = false;
        this.$emit('commentDeleted', this.commentToDelete);
        this.commentToDelete = null;
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      });
    },
    authorURLFor(postOrComment) {
      return postOrComment.as_organization
        ? `/organizations/${postOrComment.organization_id}`
        : `/users/${postOrComment.user_id}`;
    },
    relativeTimeAgo(date) {
      return DateTime.fromISO(date).toRelative();
    },
    likePostOrComment(postOrComment, isPost = true) {
      let likeUrl;
      let unlikeUrl;
      const currentUser = this.$store.state.auth.user;

      if (isPost) {
        likeUrl = `social/top_news/${postOrComment.id}/like`;
        unlikeUrl = `social/top_news/${postOrComment.id}/unlike`;

      } else {
        likeUrl = `social/top_news_comments/${postOrComment.id}/like`;
        unlikeUrl = `social/top_news_comments/${postOrComment.id}/unlike`;
      }

      if (postOrComment.has_liked) {
        // Unlike post
        fallbackApi({
          url: unlikeUrl,
          httpVerb: 'post'
        })
        .then(() => {
          postOrComment.has_liked = false;
          postOrComment.likes -= 1;
          postOrComment.all_likes = postOrComment.all_likes.filter(
            (user) => user.id !== currentUser.id
          );
          this.$emit('likeUnlikePostOrComment', {
            isPost: isPost,
            postId: isPost ? postOrComment.id : postOrComment.top_news_id,
            like: false,
            commentId: !isPost ? postOrComment.id : null
          });
        })
        .catch((err) => {
          console.error(err);
        });
      } else {
        // Like post
        fallbackApi({
          url: likeUrl,
          httpVerb: 'post'
        })
        .then(() => {
          postOrComment.has_liked = true;
          postOrComment.likes += 1;
          const newUserLike = {
            avatar: currentUser.avatar,
            id: currentUser.id,
            is_following: false,
            name: currentUser.full_name,
            occupation: currentUser.occupation,
            organization : currentUser.organization ?
              {
                id: currentUser.organization.id,
                logo: currentUser.organization.logo,
                name: currentUser.organization.name
              } :
              null
          };
          postOrComment.all_likes = [...postOrComment.all_likes, newUserLike];
          this.$emit('likeUnlikePostOrComment', {
            isPost: isPost,
            postId: isPost ? postOrComment.id : postOrComment.top_news_id,
            like: true,
            commentId: !isPost ? postOrComment.id : null
          });
        })
        .catch((err) => {
          console.error(err);
        });
      }
    },
    updatePost() {
      this.$emit('editPost', this.post);
    },
    commentPost(post) {
      this.$emit('addComment', post);
    },
    sharePost(post) {
      this.$emit('sharePost', post);
    },
    updateComment(comment) {
      this.$emit('editComment', comment);
    },
    showLikesModalOpen(entity) {
      this.entity = entity;
      this.showLikesModal = true;
      document.body.classList.add('overflow-hidden');
    },
    showLikesModalClosed() {
      this.entity = [];
      this.showLikesModal = false;
      document.body.classList.remove('overflow-hidden');
    },
    expandUsersList() {
      this.showMoreUsers += 5;
    }
  }
};
</script>

<style scoped>
avl-post {
  margin-top: 10px;
  --card-background-color: transparent;
}

.overflow-hidden {
  overflow: hidden;
}

.likesCount {
  display: inline-block;
  height: 20px;
  position: relative;
  vertical-align: middle;
  width: 2.2em;
}

.circleSize {
  font-size: 20px
}

.thumbsUpCommentSize {
  font-size: 12px;
  line-height: 19px;
}

.opacity07 {
  opacity: 0.7;
}

avl-modal {
  --modal-padding-footer: 16px 16px 0px 16px;
  --modal-background-color: #ffffff;
}

.logoImageMax {
  width: 45px;
  height: 45px;
  border-radius: 3px;
}

.logoImageMedium {
  width: 40px;
  height: 40px;
  border-radius: 3px;
}

.iconSize30 {
  font-size: 30px
}

.iconSize40 {
  font-size: 40px
}

.likeButton {
  background-color: transparent;
  box-shadow: none;
}

.likeButton:hover, .likeButton:active, .likeButton:focus {
  color: #616374 !important;
  background-color: transparent;
  box-shadow: none;
  opacity: 0.8;
}
</style>
