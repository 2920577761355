<template>
  <section class="container-fluid my-5 p-5 mw-1800px">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12 d-flex justify-content-start">
            <div class="DesktopDisplayLargeBold">
              Talk to us
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-start text-left mt-3">
            <div class="MobileTextLarge">
              Get in touch with our team to see THE ARK platform in action.
            </div>
          </div>
        </div>
        <div
          v-if="errorMessageFromBackend"
          class="row mt-3"
        >
          <div class="col-12 text-left">
            <error-message
              :error-condition="errorMessageFromBackend"
              :error-message="errorMessageFromBackend"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div
          v-if="loading"
          class="spinner-border primary2_ark my-5"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div
          v-else-if="successMessageFromBackend"
          class="ark-h5 my-5"
        >
          {{ successMessageFromBackend }}
        </div>
        <div v-else>
          <form>
            <div class="row mt-3 mt-md-0">
              <div class="col-12 text-left">
                <input
                  v-model="form.fullName"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  placeholder="Name *"
                  required
                  :class="
                    errorOnsubmit && !!fullNameError ? 'notValid' : ''
                  "
                >
                <error-message
                  :error-condition="errorOnsubmit && !!fullNameError"
                  :error-message="fullNameError"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-left">
                <input
                  v-model="form.companyEmail"
                  type="email"
                  class="form-control ark DesktopTextSmall"
                  placeholder="Company email *"
                  required
                  :class="
                    errorOnsubmit && !!companyEmailError ? 'notValid' : ''
                  "
                >
                <error-message
                  :error-condition="errorOnsubmit && !!companyEmailError"
                  :error-message="companyEmailError"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-left">
                <input
                  v-model="form.companyName"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  placeholder="Company name *"
                  required
                  :class="
                    errorOnsubmit && !!companyNameError ? 'notValid' : ''
                  "
                >
                <error-message
                  :error-condition="errorOnsubmit && !!companyNameError"
                  :error-message="companyNameError"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-left">
                <input
                  v-model="form.jobTitle"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  placeholder="Job title *"
                  required
                  :class="
                    errorOnsubmit && !!jobTitleError ? 'notValid' : ''
                  "
                >
                <error-message
                  :error-condition="errorOnsubmit && !!jobTitleError"
                  :error-message="jobTitleError"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-left">
                By clicking Submit, you agree to be contacted by THE ARK about their client services.
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 d-flex justify-content-center">
                <form @submit.prevent="submitForm">
                  <VueRecaptcha
                    ref="invisibleRecaptcha"
                    sitekey="6LdLb6okAAAAACoz7BgvN9cS9VwRRp9FWzZZXQvr"
                    @verify="verifyMethod"
                    @expired="expiredMethod"
                    @error="errorMethod"
                    size="invisible"
                  >
                  </VueRecaptcha>
                  <button
                    class="btn btn-secondary6-ark"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import { fallbackApi } from '../../../api/config';
import { VueRecaptcha } from 'vue-recaptcha';

const ValidationSchema = yup.object({
  fullName: yup.string().required('Name is a required field.'),
  companyEmail: yup.string().required('Email is a required field.').email('Not a proper email.'),
  companyName: yup.string().required('Company name is a required field.'),
  jobTitle: yup.string().required('Job title is a required field.'),
});

export default {
  components: { ErrorMessage, VueRecaptcha },
  data() {
    return {
      loading: false,
      form: {
        full_name: '',
        companyEmail: '',
        companyName: '',
        jobTitle: '',
      },
      errorOnsubmit: false,
      fullNameError: '',
      companyEmailError: '',
      companyNameError: '',
      jobTitleError: '',
      errorMessageFromBackend: '',
      successMessageFromBackend: '',
    };
  },
  methods: {

    submitForm() {
      this.loading = true;
      this.fullNameError = '';
      this.companyEmailError = '';
      this.companyNameError = '';
      this.jobTitleError = '';

      const data = {
        full_name: this.form.fullName,
        company_email: this.form.companyEmail,
        company_name: this.form.companyName,
        job_title: this.form.jobTitle,
      };

      this.$refs.invisibleRecaptcha.execute()
      ValidationSchema.validate(this.form)
        .then(() => {
          fallbackApi({
            url: 'demo',
            payload: { json: { demo: data } },
            httpVerb: 'post'
          })
          .then((response) => {
            this.errorOnsubmit = false;
            this.errorMessageFromBackend = '';
            this.successMessageFromBackend = response.data.demo;
          })
          .catch((error) => {
            this.errorMessageFromBackend = error.error.message;
          })
          .finally(() => (this.loading = false));
        })
        .catch((error) => {
          if (error.path === "fullName") {
            this.fullNameError = error.errors[0]
          } else if (error.path === "companyEmail") {
            this.companyEmailError = error.errors[0]
          } else if (error.path === "companyName") {
            this.companyNameError = error.errors[0]
          } else if (error.path === "jobTitle") {
            this.jobTitleError = error.errors[0]
          }
          this.errorOnsubmit = true;
          this.loading = false;
        });
    },
    verifyMethod (response) {
      // make post request to the server if needed (leaving this here just in case)
    },
    expiredMethod () {
      // If your time has expired execute this (leaving this here just in case)
      this.$refs.recaptcha.reset()
    },
    errorMethod() {
      this.errorMessageFromBackend = 'Error verifying reCAPTCHA, please try again.'
      Sentry && Sentry.captureException("Recaptch Validation for Demo Booking failed")
    },

  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
