import VuexPersistence from 'vuex-persist';
import authModule from './modules/auth';
import { createStore } from 'vuex';
import mapModule from './modules/map';
import notificationModule from './modules/notifications';
import settingsModule from './modules/settings';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    settings: settingsModule,
    map: mapModule,
    notifications: notificationModule
  },
  plugins: [vuexLocal.plugin]
});
