<template>
  <div id="data-modal">
    <avl-modal
      class="avl-modal"
      :is-open="openMetricDetailDataModal"
      size="1140px"
      close-button-icon="fa fa-times"
      @avleventclose="closeModal"
    >
      <div
        slot="header"
        class="DesktopLinkSmall primaryDark custom-header text-left"
      >
        {{ metricData?.title }}
      </div>
      <metric-plot-card
        v-if="openMetricDetailDataModal && metricData"
        :single-metric="true"
        :metric="metricData"
        viewport-addition="modal"
        class="plotContainer"
      />
    </avl-modal>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'MetricDetailDataModal',
  components: {
    MetricPlotCard: defineAsyncComponent(() => import('./MetricPlotCard.vue'))
  },
  props: {
    openMetricDetailDataModal: {
      type: Boolean,
      required: true,
      default: false
    },
    metricData: {
      type: Object,
      required: true,
      default: null
    }
  },
  emits: ['modalClosedEvent'],
  methods: {
    closeModal() {
      this.$emit('modalClosedEvent');
    }
  }
};
</script>

<style scoped>
#data-modal .avl-modal {
  --modal-padding-body: 0 10px 0 10px;
}

.custom-header {
  margin-top: -33px;
  width: 97%;
}

@media (max-width: 600px) {
  .plotContainer {
    margin-left: -15%;
    margin-right: -15%;
  }
}
</style>
