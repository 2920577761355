<template>
  <div id="createRiskForm">
    <div class="createRiskNav">
      <div class="row d-flex justify-content-center mx-0">
        <div class="col-sm-12 col-md-10 col-lg-8 mt-3 px-0">
          <a
            id="createRiskBackTag"
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
            "
            :href="`/projects/${projectId}/risks`"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />&nbsp;&nbsp;&nbsp; Back
          </a>
        </div>
      </div>
      <div class="row d-flex justify-content-center mx-0">
        <div
          class="col-sm-12 col-md-10 col-lg-8 my-3 DesktopTextSmall grayscaleOffBlack"
        >
          <img
            :src="imageUrl"
            class="mr-2 noDataImage"
            alt="An image of the world"
          >
          Let’s get started with a few details about your risk.
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-10 col-lg-8 mt-3 DesktopTextXSmall">
        * indicates required
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="arkCard mt-2">
          <div class="d-flex justify-content-left DesktopTextMedium primaryDefault mb-3">
            Risk details
          </div>
          <div class="row d-flex justify-content-left">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
              <Multiselect
                v-model="form.selectedType"
                placeholder="Type *"
                :options="types"
                :close-on-select="true"
                :searchable="true"
                required
                :can-clear="false"
                :disabled="loading"
                :class="
                  errorOnSubmit && !form.selectedType
                    ? 'multiselect-ark multiselect-ark-error'
                    : 'multiselect-ark'
                "
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <Multiselect
                v-model="form.selectedLikelihood"
                placeholder="Likelihood *"
                :options="likelihoodOptions"
                :close-on-select="true"
                :searchable="true"
                required
                object
                track-by="value"
                label="value"
                :can-clear="false"
                :disabled="loading"
                :class="
                  errorOnSubmit && Object.keys(form.selectedLikelihood).length === 0
                    ? 'multiselect-ark multiselect-ark-error'
                    : 'multiselect-ark'
                "
              />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3">
              <Multiselect
                v-model="form.selectedSeverity"
                placeholder="Severity *"
                :options="severityOptions"
                :close-on-select="true"
                :searchable="true"
                required
                object
                track-by="value"
                label="value"
                :can-clear="false"
                :disabled="loading"
                :class="
                  errorOnSubmit && Object.keys(form.selectedSeverity).length === 0
                    ? 'multiselect-ark multiselect-ark-error'
                    : 'multiselect-ark'
                "
              />
            </div>
          </div>
          <div class="row d-flex justify-content-left mt-3">
            <div class="col-12">
              <input
                v-model="form.riskName"
                type="text"
                class="form-control ark DesktopTextSmall"
                placeholder="Name *"
                required
                :disabled="loading"
                :class="
                  errorOnSubmit && !form.riskName ? 'notValid' : ''
                "
              >
            </div>
          </div>
          <div class="row d-flex justify-content-left mt-3">
            <div class="col-12">
              <textarea
                v-model="form.potentialRiskEffectDescription"
                placeholder="Describe potential risk effect *"
                class="textareaNewGoal form-control ark DesktopTextSmall"
                aria-label="Describe potential risk effect area"
                rows="4"
                :disabled="loading"
                :class="{'notValid': errorOnSubmit && !form.potentialRiskEffectDescription}"
              />
            </div>
          </div>
          <div class="row d-flex justify-content-left mt-3">
            <div class="col-12">
              <textarea
                v-model="form.mitigationMeasuresDescription"
                placeholder="Describe mitigation measures *"
                class="textareaNewGoal form-control ark DesktopTextSmall"
                aria-label="Describe mitigation measures area"
                rows="4"
                :disabled="loading"
                :class="{'notValid': errorOnSubmit && !form.mitigationMeasuresDescription}"
              />
            </div>
          </div>
          <div class="row d-flex justify-content-left mt-3 mb-5">
            <div class="col-12">
              <Multiselect
                v-model="form.selectedGoals"
                placeholder="Search for goals"
                :options="goals"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                track-by="name"
                label="name"
                required
                :disabled="loading"
                class="multiselect-ark"
              />
            </div>
          </div>
          <div v-if="errorMessageFromBackend" class="row d-flex justify-content-left mb-2">
            <div class="col-12">
              <error-message
                :error-condition="errorMessageFromBackend"
                :error-message="errorMessageFromBackend"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center">
        <button
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            my-3
            primaryButton
            pointer
            createRiskButtonSave
          "
          @click="saveUpdateNewRisk"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { arraysFromSymbolsToStrings } from '../../../../utilities/enumeration';
import { fallbackApi } from '../../../../api/config';
import { types } from '../../../../domain/riskOptions';

const ValidationSchema = yup.object({
  selectedType: yup.string().required(),
  selectedLikelihood: yup.object({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  selectedSeverity: yup.object({
    key: yup.string().required(),
    value: yup.string().required()
  }),
  riskName: yup.string().required(),
  potentialRiskEffectDescription: yup.string().required(),
  mitigationMeasuresDescription:yup.string().required(),
  selectedGoals: yup.array()
});

export default {
  components: { Multiselect, ErrorMessage },
    data() {
    return {
      errorOnSubmit: false,
      loading: false,
      errorMessageFromBackend: '',
      projectId: undefined,
      riskId: undefined,
      imageUrl: undefined,
      form: {
        selectedType: '',
        selectedLikelihood: {},
        selectedSeverity: {},
        riskName: '',
        potentialRiskEffectDescription: '',
        mitigationMeasuresDescription: '',
        selectedGoals: []
      },
      types: arraysFromSymbolsToStrings(types),
      likelihoodOptions: [],
      severityOptions: [],
      mode: 'create',
      goals: []
    };
  },
  beforeMount() {
    this.getProjectId();
  },
  mounted() {
    this.getGoals();
    if (this.mode === 'edit') {
      this.getRisk();
    }
  },
  methods: {
    getProjectId() {
      const createRisk = document.querySelector('#create-risk');
      this.projectId = createRisk?.dataset.projectId;
      this.imageUrl = createRisk?.dataset.imgUrl;
      this.mode = createRisk?.dataset.mode;
      this.riskId = createRisk?.dataset.riskId || undefined;
      Object.entries(JSON.parse(createRisk.dataset.likelihoodOptions)).forEach(([key, value]) => {
        this.likelihoodOptions = [...this.likelihoodOptions, { key: key, value: value }];
      });
      Object.entries(JSON.parse(createRisk.dataset.severityOptions)).forEach(([key, value]) => {
        this.severityOptions = [...this.severityOptions, { key: key, value: value }];
      });
    },
    getRisk(){
      fallbackApi({
        url: `risks/${this.riskId}`
      })
      .then((data) => {
        this.form.selectedType = data.data.risk.risk_type;
        this.form.selectedLikelihood = this.likelihoodOptions.find(
          (element) => element.key === data.data.risk.likelihood
        );
        this.form.selectedSeverity = this.severityOptions.find(
          (element) => element.key === data.data.risk.severity
        );
        this.form.riskName = data.data.risk.name;
        this.form.potentialRiskEffectDescription = data.data.risk.potential_risk_effect_description;
        this.form.mitigationMeasuresDescription = data.data.risk.mitigation_measures_description;
        this.form.selectedGoals = data.data.risk.goals.map(
          (goal) => goal.id
        );
      })
      .catch((error) => {
        console.warn('error', error);
        Sentry && Sentry.captureException(error);
        location.assign(`/projects/${this.projectId}/risks`);
      });
    },
    getGoals() {
      fallbackApi({
        url: `projects/${this.projectId}/goals`,
        dataSetter: (data) => {
          this.goals = data.goals;
          this.goals.forEach((goal) => {
            // we need this because of multiselect component
            goal.value = goal.id;
          });
        }
      });

    },
    saveUpdateNewRisk() {
      this.loading = true;

      const riskData = {
        name: this.form.riskName,
        risk_type: this.form.selectedType,
        likelihood: this.form.selectedLikelihood.key,
        severity: this.form.selectedSeverity.key,
        potential_risk_effect_description: this.form.potentialRiskEffectDescription,
        mitigation_measures_description: this.form.mitigationMeasuresDescription,
        project_id: parseInt(this.projectId, 10),
        goal_ids: this.form.selectedGoals
      };

      ValidationSchema.validate(this.form)
        .then(() => {
          fallbackApi({
            url: this.mode === 'edit' ? `risks/${this.riskId}` : 'risks',
            payload: { json: { risk: riskData } },
            httpVerb: this.mode === 'edit' ? 'patch' : 'post'
          })
          .then(() => {
            this.errorOnsubmit = false;
            this.errorMessageFromBackend = '';
            location.assign(`/projects/${this.projectId}/risks`);
          })
          .catch((error) => {
            this.errorMessageFromBackend = error.error.message;
            console.warn('error', error);
            Sentry && Sentry.captureException(error);
          })
          .finally(() => (this.loading = false));
        })
        .catch(() => {
          this.errorOnSubmit = true;
          this.loading = false;
        });
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
#createRiskForm {
  margin-top: -15px;
}

#createRiskForm .noDataImage {
  width: 48px;
  height: 45px;
}

#createRiskForm .createRiskNav {
  background: white;
}

#createRiskForm .createRiskButtonSave {
  padding: 9px 75px;
}

#createRiskBackTag {
  color: #24422B !important;
}

@media screen and (max-width: 991px) {
  #createRiskForm {
      margin-top: 10px
  }
}
</style>
