<template>
  <div>
    <div v-if="entryData.length" class="metricPlot">
      <div :class="singleMetric? '': 'arkCard no-shadow'">
        <div v-if="!singleMetric">
          <div class="row">
            <div
              class="
                col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2
                ark-p11 DesktopTextMedium d-flex align-items-center
              "
            >
              <span class="mr-2">Metrics</span>
              <i
                class="fa fa-question-circle grayscalePlaceholder"
                aria-hidden="true"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
              <div class="row d-flex justify-content-start pt-1">
                <div
                  class="
                    col-sm-12 col-md-2 col-lg-3 col-xl-2
                    DesktopTextSmall
                    extraColor4
                    pr-0
                    text-md-right text-sm-left
                  "
                  style="align-self: center"
                >
                  Metric:
                </div>
                <div class="col-sm-12 col-md-8 col-lg-7 col-xl-6">
                  <Multiselect
                    v-model="selectedMetric"
                    placeholder="Metric"
                    :options="multiselectData"
                    groups
                    group-options="metrics"
                    group-label="goalName"
                    :group-select="true"
                    object
                    track-by="titleGoal"
                    label="value"
                    value-prop="titleGoal"
                    class="multiselect-ark"
                    :can-clear="false"
                    :close-on-select="true"
                    :searchable="true"
                    required
                    @select="dropdownIndex = selectedMetric.index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          v-if="singleMetric && miniChart"
          class="textButton"
          @click="openDetailModalEvent"
        >
          View data
        </button>
        <div :class="{ pointer: miniChart }" @click="openDetailModalEvent">
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <div :id="viewport"></div>
        </div>
      </div>
    </div>
    <div v-else-if="singleMetric && isAdmin">
      <button
        class="
          textButton
        "
        @click="openAddDataModalEvent"
      >
        Add data
      </button>
    </div>
  </div>
</template>

<script>
/* global Highcharts, Sentry */

import { DateTime } from 'luxon';
import Multiselect from '@vueform/multiselect';
import api from '../../../api/config';
import { metricEntryMixin } from '../mixins';
import { miniChartTemplate } from '../../../domain/constants';

const areaMeasurementsTemplate = {
  type: 'area',
  name: 'Results',
  visible: true,
  color: '#24422B',
  marker: {
    enabled: false,
    symbol: 'circle'
  },
  lineWidth: 2,
  connectNulls: true,
  data: []
};
const lineMeasurementsTemplate = {
  type: 'line',
  name: 'Results',
  visible: true,
  color: '#24422B',
  connectNulls: true,
  marker: {
    lineWidth: 2,
    lineColor: '#24422B',
    fillColor: 'white',
    symbol: 'circle'
  },
  data: []
};
const pointMeasurementsTemplate = { ...lineMeasurementsTemplate, lineWidth: 0 };

const lineTargetsTemplate = {
  type: 'line',
  name: 'Targets',
  visible: true,
  color: '#C5BD74',
  connectNulls: true,
  marker: {
    lineWidth: 2,
    lineColor: '#C5BD74',
    fillColor: 'white',
    symbol: 'diamond'
  },
  data: []
};
const pointTargetsTemplate = { ...lineTargetsTemplate, lineWidth: 0 };

const lineBaselineTemplate = {
  type: 'line',
  name: 'Baseline',
  visible: true,
  color: '#000000',
  connectNulls: true,
  marker: {
    lineWidth: 2,
    lineColor: '#000000',
    fillColor: 'white',
    symbol: 'triangle'
  },
  data: []
};
const pointBaselineTemplate = { ...lineBaselineTemplate, lineWidth: 0 };

const booleanCategories = ['No', 'Yes'];

const tooltipComment = (tooltipHtml, points) => {
  for (const point of points) {
    const comment = point.point.comment;
    if (comment) {
      return `${tooltipHtml}<br><span class="DesktopTextXSmall"><em>${comment}</em></span>`;
    }
  }

  return tooltipHtml;
};

function formatter(chartOptions) {
  const formatDateFunction = chartOptions.chart.userOptions.xAxis[0].labels.formatter || (() => {});

  const tooltipHtml = this.points.reduce(function (memo, point) {
    return `
      ${memo}<br>
      <span class="DesktopTextXSmall">
        <b>
          <span style="color:${point.series.color}">\u25CF</span> ${point.series.name}:
          ${point.y}
        </b>
      </span>
    `;
  }, `<span class="DesktopTextXSmall">${formatDateFunction(this.x)}</span>`);

  return tooltipComment(tooltipHtml, this.points);
}
function dateFormatter(chartOptions) {
  const formatDateFunction = chartOptions.chart.userOptions.xAxis[0].labels.formatter || (() => {});

  return `<span class="DesktopTextXSmall">${formatDateFunction(this.x)}</span>`;
}
function selectionFormatter(chartOptions) {
  const formatDateFunction = chartOptions.chart.userOptions.xAxis[0].labels.formatter || (() => {});

  const tooltipHtml = this.points.reduce(function (memo, point) {
    const listItems = point.point.items.reduce((previous, item) => {
      return `
        ${previous}<br>
        <span style="color:${point.series.color}">\u25CF</span> <b>${item}</b>
      `;
    }, '');
    return `${memo}${listItems}`;
  }, `<span class="DesktopTextXSmall">${formatDateFunction(this.x)}</span>`);

  return tooltipComment(tooltipHtml, this.points);
}
function booleanFormatter(chartOptions) {
  const formatDateFunction = chartOptions.chart.userOptions.xAxis[0].labels.formatter || (() => {});

  const tooltipHtml = this.points.reduce(function (memo, point) {
    return `
      ${memo}<br>
      <span class="DesktopTextXSmall">
        <b>
          <span style="color:${point.series.color}">\u25CF</span> ${point.series.name}:
          ${point.y ? 'Yes' : 'No'}
        </b>
      </span>
    `;
  }, `<span class="DesktopTextXSmall">${formatDateFunction(this.x)}</span>`);

  return tooltipComment(tooltipHtml, this.points);
}

const numberChartOptions = {
  chart: { height: 400 },
  yAxis: {
    type: 'linear',
    labels: { enabled: true },
    visible: true,
    categories: null,
    min: null,
    max: null
  },
  tooltip: { formatter }
};
const booleanChartOptions = {
  chart: { height: 400 },
  yAxis: {
    type: 'category',
    labels: { enabled: true },
    visible: true,
    categories: booleanCategories,
    min: null,
    max: null
  },
  tooltip: { formatter: booleanFormatter }
};
const ratingChartOptions = {
  chart: { height: 400 },
  yAxis: {
    type: 'linear',
    labels: { enabled: true },
    visible: true,
    categories: null,
    min: 0,
    max: 5
  },
  tooltip: { formatter }
};
const dateChartOptions = {
  chart: { height: 140 },
  yAxis: {
    type: 'linear',
    labels: { enabled: false },
    visible: false,
    categories: null,
    min: null,
    max: null
  },
  tooltip: { formatter: dateFormatter }
};
const selectionChartOptions = {
  ...dateChartOptions,
  tooltip: { formatter: selectionFormatter }
};

const dataWatcher = (dataKey) => {
  return {
    handler() {
      this.renderHighchart({
        changeMeasurements: dataKey === 'measurements',
        changeTargets: dataKey === 'targets',
        changeBaseline: dataKey === 'baseline',
        changeTitle: dataKey === 'measurements'
      });
    },
    deep: true,
    flush: 'post'
  };
};

const MILLISECONDS_IN_YEAR = 31_556_952_000;

export default {
  components: { Multiselect },
  mixins: [metricEntryMixin],
  props: {
    model: {
      type: String,
      required: false,
      default: ''
    },
    modelId: {
      type: String,
      required: false,
      default: ''
    },
    miniChart: {
      type: Boolean,
      required: false,
      default: false
    },
    singleMetric: {
      type: Boolean,
      required: false,
      default: false
    },
    metric: {
      type: Object,
      required: false,
      default: null
    },
    entriesChangedIndicator: {
      type: Boolean,
      required: false,
      default: true
    },
    viewportAddition: {
      type: String,
      required: false,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['openDetailModal', 'openAddDataModal'],
  data() {
    return {
      selectedMetric: {},
      dropdownIndex: 0,
      lineChart: undefined,
      entryData: [],
      lineChartTemplate: {
        chart: {
          spacingBottom: 20,
          spacingTop: 20,
          spacingLeft: 20,
          spacingRight: 50,
          type: 'line'
        },
        yAxis: {
          useHTML: true,
          title: {
            text: ''
          }
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: this.getLabelFormatter()
          }
        },
        title: {
          text: ''
        },
        series: [lineBaselineTemplate, lineTargetsTemplate, areaMeasurementsTemplate],
        legend: {
          layout: 'horizontal',
          align: 'left',
          verticalAlign: 'bottom'
        },
        tooltip: {
          shared: true,
          formatter
        }
      }
    };
  },
  computed: {
    viewport() {
      let customViewport;
      if (this.singleMetric && this.metric.iris_metric_type) {
        customViewport = `plot-iris-${this.metric.id}-${this.metric.goalId}`;
      } else if (this.singleMetric && !this.metric.iris_metric_type){
        customViewport = `plot-ark-${this.metric.id}-${this.metric.goalId}`;
      } else {
        customViewport = 'metric-plot-card';
      }
      return `${customViewport}-${this.viewportAddition}`;
    },
    baselineData() {
      if (!this.entryData.length) return [];
      return this.entryData[this.dropdownIndex].baselineData;
    },
    targetData() {
      if (!this.entryData.length) return [];
      return this.entryData[this.dropdownIndex].targetData;
    },
    measurementData() {
      if (!this.entryData.length) return [];
      return this.entryData[this.dropdownIndex].measurementData;
    },
    multiselectData() {
      // convert the data in this structure because of multiselect component
      return this.entryData.reduce((accumulator,currentValue) => {
        const found = accumulator.find((item) => item.goalName === currentValue.goalName);
        if (found) found.metrics.push(currentValue);
        else {
          accumulator.push({
          goalName : currentValue.goalName,
          metrics : [currentValue]
          });
        }
        return accumulator;
      }, []);
    }
  },
  watch: {
    entriesChangedIndicator() {
      this.fetchData();
    },
    baselineData: dataWatcher('baseline'),
    targetData: dataWatcher('targets'),
    measurementData: dataWatcher('measurements'),
    toggleValue() {
      this.renderHighchart({
        changeBaseline: false,
        changeTargets: false,
        changeMeasurements: false,
        changeTitle: false
      });
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getLabelFormatter() {
      const formatDate = this.formatDate;

      // Formatter used either with a param or with the library's 'this' object
      // depending on where it's called from
      return function dateLabelFormatter(value) {
        const dateValue = this?.value !== undefined ? this.value : value;
        return formatDate(dateValue);
      }
    },
    formatDate(value) {
      const date = DateTime.fromMillis(value, { zone: 'utc' });
      const metric = this.singleMetric ? this.metric : this.entryData[this.dropdownIndex].metric;

      switch (metric.entry_frequency.intervals) {
        case 1:
          return date.year;
        case 2:
          return `${date.year} H${Math.ceil(date.month/6.0)}`;
        case 4:
          return `${date.year} Q${date.quarter}`;
        case 12:
          return `${date.year}-${String(date.month).padStart(2, '0')}`;
        default:
          return date.toString();
      }
    },
    fetchData() {
      this.dropdownIndex = 0;
      if (this.singleMetric) this.getEntries([this.metric]);
      else {
        const allMetrics = [];
        api
          .get(`${this.model}s/${this.modelId}/goals`)
          .then((response) => response.json())
          .then((data) => {
            data.goals.forEach((goal) => {
              [...goal.ark_metrics, ...goal.iris_metrics].forEach((metric) => {
                allMetrics.push({
                  ...metric,
                  goalId: goal.id,
                  goalName: goal.name,
                  startDate: goal.start_date,
                  endDate: goal.end_date
                });
              });
            });
          })
          .finally(() => this.getEntries(allMetrics))
          .catch((error) => {
            console.warn(error);
            Sentry && Sentry.captureException(error);
          });
      }
    },
    getSeries(name) {
      if (!this.lineChart) return {};
      return this.lineChart.series.find((series) => series.name === name);
    },
    convertDate(date) {
      return DateTime.fromISO(date, { zone: 'utc' }).valueOf();
    },
    extractChartData(entries, metric, measurementType) {
      return entries?.map((entry) => {
        let yValue = this.getCorrectField(metric, entry, measurementType);
        let xValue = entry.measurement_date_id;
        const otherFields = {
          comment: entry.measurement_comment?.comment
        };

        if (this.checkEntryType(metric, 'boolean')) {
          if (yValue) {
            yValue = yValue === 'No' ? 0 : 1;
          }
        } else if (this.checkEntryType(metric, 'date')) {
          xValue = yValue;
          yValue = 0; // Set yValue to 0 so chart is 1-dimensional
        } else if (this.checkEntryType(metric, 'selection')) {
          if (yValue) {
            otherFields.items = yValue.split(', ');
            yValue = 0;
          }
        } else {
          yValue = yValue ? Number(yValue) : null;
        }

        return {
          x: this.convertDate(xValue),
          y: yValue,
          ...otherFields
        };
      });
    },
    async getEntries(allMetrics) {
      let counter = 0;
      const allEntries = [];
      await Promise.all(
        allMetrics.map((metric) => {
          const { goalId, metric_id, id } = metric;
          return api
            .get(
              'goals/' +
                `${goalId}/` +
                `${metric_id ? 'iris_metrics' : 'ark_metric_definitions'}/` +
                `${id}`
            )
            .then((response) => response.json())
            .then((data) => {
              const baselineData = this.extractChartData(data.metric_entries, metric, 'baseline')
                .filter((entry) => entry.y !== null);
              const targetData = this.extractChartData(data.metric_entries, metric, 'target')
                .filter((entry) => entry.y !== null);
              const measurementData = this.extractChartData(data.metric_entries, metric, 'result')
                .filter((entry) => entry.y !== null);
              if (
                baselineData.length + targetData.length + measurementData.length &&
                this.checkEntryType(metric, 'numeric', 'rating', 'boolean', 'date', 'selection')
              ) {
                allEntries.push({
                  baselineData,
                  targetData,
                  measurementData,
                  goalName: metric.goalName,
                  metric: metric,
                  index: counter++
                });
              }
            })
            .catch((error) => {
              console.warn(error);
              Sentry && Sentry.captureException(error);
            });
        })
      );
      this.entryData = allEntries.sort((e1, e2) => e1.index - e2.index);
      this.entryData.forEach((metric) => {
        metric.titleGoal = `${metric.goalName} ${metric.metric.title}`;
        metric.value = metric.metric.title;
      });
      this.selectedMetric = this.entryData[this.dropdownIndex];
    },
    createHighChart() {
      if (this.singleMetric && this.metric) {
        let options = {};
        let template = this.lineChartTemplate;

        // Update chart template parameters based on metric type
        if (this.checkEntryType(this.metric, 'boolean')) {
          options = booleanChartOptions;
        } else if (this.checkEntryType(this.metric, 'date')) {
          options = dateChartOptions;
        } else if (this.checkEntryType(this.metric, 'rating')) {
          options = ratingChartOptions;
        } else if (this.checkEntryType(this.metric, 'selection')) {
          options = selectionChartOptions;
        }

        ['chart', 'tooltip', 'yAxis'].forEach((parameter) => {
          template[parameter] = {
            ...template[parameter],
            ...options[parameter]
          };
        });

        if (this.miniChart) {
          template = {
            ...template,
            ...miniChartTemplate
          };
        }

        let unit = 'year';
        if (this.metric.entry_frequency.unit && this.metric.entry_frequency.intervals !== 2) {
          unit = this.metric.entry_frequency.unit;
        }

        template.xAxis.min =
          DateTime.fromISO(this.metric.startDate, { zone: 'utc' })
            .startOf(unit)
            .valueOf();
        template.xAxis.max =
          DateTime.fromISO(this.metric.endDate, { zone: 'utc' })
            .endOf(unit)
            .valueOf();

        template.xAxis.tickInterval = MILLISECONDS_IN_YEAR / this.metric.entry_frequency.intervals;

        this.lineChartTemplate = template;
      }

      this.lineChart = Highcharts.chart(this.viewport, this.lineChartTemplate);
    },
    renderHighchart({
      changeBaseline = true,
      changeTargets = true,
      changeMeasurements = true,
      changeTitle = true
    } = {}) {
      if (!this.entryData.length || this.miniChart) {
        this.lineChart = undefined;
        return;
      }

      // Create new chart
      if (!this.lineChart) this.createHighChart();

      // Pick templates for measurements and targets based on metric type
      let baselineTemplate = lineBaselineTemplate;
      let targetsTemplate = lineTargetsTemplate;
      let measurementsTemplate = areaMeasurementsTemplate;
      const metric = this.singleMetric ? this.metric : this.entryData[this.dropdownIndex].metric;

      if (this.checkEntryType(metric, 'date', 'selection')) {
        baselineTemplate = pointBaselineTemplate;
        targetsTemplate = pointTargetsTemplate;
        measurementsTemplate = pointMeasurementsTemplate;
      } else if (this.checkEntryType(metric, 'boolean')) {
        measurementsTemplate = lineMeasurementsTemplate;
      }

      const getMarkerOptions = (data, { symbol, lineColor }) => {
        if (!this.miniChart) return {};
        return {
          marker: {
            enabled:
              data.filter(({ y }) => y !== null).length === 1 || // A single point
                !!data.find(({ items }) => items), // Selection Metrics (multiple choice)
            symbol,
            lineColor,
            lineWidth: 1
          }
        };
      };

      // Add/Update measurements, targets and baseline
      if (changeBaseline) {
        this.getSeries('Baseline').remove();
        this.lineChart.addSeries({
          ...baselineTemplate,
          ...getMarkerOptions(this.baselineData, baselineTemplate.marker),
          data: this.baselineData
        });
      }
      if (changeTargets) {
        this.getSeries('Targets').remove();
        this.lineChart.addSeries({
          ...targetsTemplate,
          ...getMarkerOptions(this.targetData, targetsTemplate.marker),
          data: this.targetData
        });
      }
      if (changeMeasurements) {
        this.getSeries('Results').remove();
        this.lineChart.addSeries({
          ...measurementsTemplate,
          ...getMarkerOptions(this.measurementData, measurementsTemplate.marker),
          data: this.measurementData
        });
      }

      // Update existing chart
      if (changeTitle && !this.miniChart) {
        let title = metric.measurement_unit.name;
        if (metric.format === 'Currency') {
          title = 'Euro';
        } else if (metric.measurement_unit.name === 'No Unit') {
          title = '';
        }
        this.lineChart.yAxis[0].setTitle({
          text: `<strong>${title}</strong>`
        });

        let unit = 'year';
        if (metric.entry_frequency.unit && metric.entry_frequency.intervals !== 2) {
          unit = metric.entry_frequency.unit;
        }

        this.lineChart.update({
          xAxis: {
            min: DateTime.fromISO(metric.startDate, { zone: 'utc' }).startOf(unit).valueOf(),
            max: DateTime.fromISO(metric.endDate, { zone: 'utc' }).endOf(unit).valueOf(),
            tickInterval: MILLISECONDS_IN_YEAR / metric.entry_frequency.intervals
          }
        });

        // Update chart configuration based on metric type
        if (this.checkEntryType(metric, 'boolean')) {
          this.lineChart.update(booleanChartOptions);
        } else if (this.checkEntryType(metric, 'date')) {
          this.lineChart.update(dateChartOptions);
        } else if (this.checkEntryType(metric, 'rating')) {
          this.lineChart.update(ratingChartOptions);
        } else if (this.checkEntryType(metric, 'selection')) {
          this.lineChart.update(selectionChartOptions);
        } else {
          this.lineChart.update(numberChartOptions);
        }
      }
    },
    openDetailModalEvent() {
      if (this.miniChart) this.$emit('openDetailModal', this.metric);
    },
    openAddDataModalEvent() {
      if (this.isAdmin) this.$emit('openAddDataModal');
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>

</style>
