import api, { fallbackApi } from '../../../api/config';

const actions = {
  tryToGetUser(context) {
    const userId = document.querySelector('meta[name="api-user-id"]');
    const contentId = userId?.getAttribute('content');
    if (!context.state.user || context.state.user.id.toString() !== contentId) {
      context.dispatch('updateUser', contentId);
    }
  },
  updateUser(context, userId) {
    if (userId) {
      api
      .get(`users/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        const {
          organization,
          org_portfolio_access,
          avatar,
          banner
        } = data.user;

        data.user.user = {
          ...data.user.user,
          organization,
          org_portfolio_access,
          avatar,
          banner
        };

        data.user.user.projects = data.projects
          .filter((project) => project.organization_id === data.user.organization?.id);
        data.user.user.associated_projects = data.projects
          .filter((project) => project.organization_id !== data.user.organization?.id);
        context.commit('mutateUser', data.user);
      });
    } else if (context.state.user) {
      context.commit('mutateUser', { user: null });
    }
  },
  updateUserPortfolioAccess(context) {
    fallbackApi({
      url: 'users/portfolio_access',
      dataSetter: (data) => context.commit('updateUserPortfolioAccess', data)
    });
  }
};

export default actions;
