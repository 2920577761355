<template>
  <section>
    <section class="arkCardNoPadding">
      <input
        ref="bannerInput"
        type="file"
        :accept="imageMimeTypes.join(', ')"
        hidden
        @input="addBanner"
      >
      <div
        class="coverPhotoContainer"
        :style="{ 'background-image': `url('${user?.banner}')` }"
        aria-hidden="true"
      />
      <section v-if="isAdmin" class="d-flex justify-content-end">
        <button
          class="btn btn-link p-0 m-0 editCoverPhotoButton"
          aria-label="Edit banner menu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </button>
        <div class="d-flex justify-content-end">
          <div class="dropdown-menu dropdown-primary" role="menu">
            <button
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="updateImage('banner')"
            >
              <i class="far fa-camera mr-2" aria-hidden="true" /> Upload banner image
            </button>
            <button
              v-show="user.banner"
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="openEditImageModal('banner')"
            >
              <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust banner image
            </button>
            <button
              type="button"
              class="dropdown-item w-100 m-0 pointer"
              @click="deleteImage('banner')"
            >
              Delete
            </button>
          </div>
        </div>
      </section>
      <section class="px-4 pt-4">
        <input
          ref="avatarInput"
          type="file"
          :accept="imageMimeTypes.join(', ')"
          hidden
          @input="addAvatar"
        >
        <div class="avatarContainer">
          <img
            v-show="user.avatar"
            :src="user?.avatar"
            class="avatar"
            :alt="`${user?.user?.full_name} avatar`"
          >
          <button
            v-if="isAdmin"
            class="btn btn-link p-0 m-0 align-self-end editAvatarBtn"
            style="margin-top: -23px !important"
            aria-label="Edit profile image menu"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i
              class="fa fa-pencil edit-icon-pencil grayscaleBody edit-avatar-button"
              aria-hidden="true"
            />
          </button>
          <div class="d-flex justify-content-end">
            <div class="dropdown-menu dropdown-primary" role="menu">
              <button
                type="button"
                class="dropdown-item w-100 m-0 pointer"
                @click="updateImage('avatar')"
              >
                <i class="far fa-camera mr-2" aria-hidden="true" /> Upload profile image
              </button>
              <button
                v-show="user.avatar"
                type="button"
                class="dropdown-item w-100 m-0 pointer"
                @click="openEditImageModal('avatar')"
              >
                <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust profile image
              </button>
              <button
                type="button"
                class="dropdown-item w-100 m-0 pointer"
                @click="deleteImage('avatar')"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="px-4 pt-3 row">
        <header class="col-12 col-sm-7">
          <h1 class="DesktopLinkMedium primaryDefault mb-0">
            {{ user?.user?.full_name }}
          </h1>
          <h2 class="MobileTextXSmall primaryDefault mb-0">
            {{ user?.user?.occupation }}
          </h2>
        </header>
        <section
          v-if="user?.organization?.id"
          class="
            col-12 col-sm-5
            d-flex
            my-auto
            justify-content-start
            justify-content-sm-end
            organization-section
          "
        >
          <a class="d-flex p-0" :href="`/organizations/${user?.organization?.id}`">
            <img
              v-if="user?.organization?.logo"
              :src="user?.organization?.logo"
              class="imageOrgLogoContainer d-flex objectFitContain mr-2"
              alt="Organization's logo"
            >
            <h3 class="organization-title-size DesktopLinkXSmall primaryDefault my-0">
              {{ user?.organization?.name }}
            </h3>
          </a>
        </section>
        <section class="d-sm-flex align-items-center mt-2 col-12">
          <button
            class="
              DesktopLinkXSmall
              m-0
              btn
              secondaryButton
              primaryDefault
              boxShadowNone
              p-0
              backTag
            "
            @click="contactInfoModalOpen = true"
          >
            Contact info
          </button>
        </section>
      </section>
      <section class="px-4 py-2 mb-2">
        <a
          v-if="isAdmin"
          class="btn btn-sm DesktopLinkXSmall primaryButton transition-02 mr-2"
          :href="`/users/${user?.user?.id}/edit`"
        >
          <i
            class="fa fa-pencil grayscaleOffWhite pr-2"
            aria-hidden="true"
          />
          Edit
        </a>
        <button
          v-else
          type="button"
          class="
            DesktopLinkXSmall
            primaryButton
            mr-2
            btn
            btn-sm
          "
          @click="
            followUnfollow(user?.is_following ? 'unfollow' : 'follow', user?.user?.id)
          "
        >
          {{ user?.is_following ? 'Following': 'Follow' }}
        </button>
      </section>
      <hr class="p-0 m-0">
    </section>
  </section>
  <avl-modal
    :is-open="invalidImageModalOpen"
    size="900px"
    header-title=""
    close-button-icon="fa fa-times"
    @avleventclose="invalidImageModalOpen = false"
  >
    <div class="DesktopTextSmall grayscaleBody mt-2 p-3">
      Invalid image type selected. Please use one of JPG, JPEG, or PNG.
    </div>
  </avl-modal>
  <section id="contactInfoModal">
    <avl-modal
      :is-open="contactInfoModalOpen"
      size="500px"
      header-title=""
      close-button-icon="fa fa-times"
      @avleventclose="contactInfoModalOpen = false"
    >
      <div slot="header" class="custom-header d-flex">
        <h2 class="DesktopTextMedium textBodyColor text-left">
          {{ user?.user?.full_name }}
        </h2>
      </div>
      <header
        v-if="isAdmin"
        class="d-flex mb-2 mt-3"
      >
        <h2 class="DesktopTextSmall grayscaleOffBlack text-left mb-0">
          Contact Info
        </h2>
        <a
          class="btn btn-link p-0 m-0 ml-2"
          aria-label="Edit contact info"
          :href="`/users/${user?.user?.id}/edit`"
        >
          <i
            class="fa fa-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </header>
      <section v-if="user?.user?.social_media_links?.linkedin_link" class="d-flex mt-4">
        <i
          class="fa fa-linkedin-square grayscaleBody fa-lg pt-1 mr-2"
          aria-hidden="true"
        />
        <section>
          <p class="DesktopLinkSmall grayscaleOffBlack text-left mb-0">Your Linkedin</p>
          <a
            class="DesktopLinkXSmall grayscaleBody text-left pointer wordBreakWord d-flex"
            :href="user?.user?.social_media_links?.linkedin_link"
            target="_blank"
          >
            {{ user?.user?.social_media_links?.linkedin_link }}
          </a>
        </section>
      </section>
      <section v-if="user?.user?.social_media_links?.twitter_link" class="d-flex mt-4">
        <i
          class="fa fa-twitter-square grayscaleBody fa-lg pt-1 mr-2"
          aria-hidden="true"
        />
        <section>
          <p class="DesktopLinkSmall grayscaleOffBlack text-left mb-0">Your Twitter</p>
          <a
            class="DesktopLinkXSmall grayscaleBody text-left pointer wordBreakWord d-flex"
            :href="user?.user?.social_media_links?.twitter_link"
            target="_blank"
          >
            {{ user?.user?.social_media_links?.twitter_link }}
          </a>
        </section>
      </section>
      <section v-if="user?.user?.website_url" class="d-flex mt-4">
        <i
          class="fa fa-link grayscaleBody fa-lg pt-1 mr-2"
          aria-hidden="true"
        />
        <section>
          <p class="DesktopLinkSmall grayscaleOffBlack text-left mb-0">Website</p>
          <a
            class="DesktopLinkXSmall grayscaleBody text-left pointer wordBreakWord d-flex"
            :href="user?.user?.website_url"
            target="_blank"
          >
            {{ user?.user?.website_url }}
          </a>
        </section>
      </section>
    </avl-modal>
  </section>
</template>

<script>
import { fallbackApi } from '../../../api/config';
import { openDefaultEditor } from '@pqina/pintura';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  emits: ['updateImage', 'followUnfollow'],
  data() {
    return {
      invalidImageModalOpen: false,
      contactInfoModalOpen: false,
      avatar: null,
      banner: null,
      imageMimeTypes: ['image/jpeg', 'image/png']
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user.id === this.user?.user?.id;
    }
  },
  methods: {
    addAvatar() {
      this.avatar = this.$refs.avatarInput.files[0];
      if (!this.imageMimeTypes.includes(this.avatar?.type)) {
        this.invalidImageModalOpen = true;
        return;
      }

      this.openEditImageModal('avatar');
    },
    addBanner() {
      this.banner = this.$refs.bannerInput.files[0];
      if (!this.imageMimeTypes.includes(this.banner?.type)) {
        this.invalidImageModalOpen = true;
        return;
      }

      this.openEditImageModal('banner');
    },
    updateImage(key) {
      this.$refs[`${key}Input`].click();
    },
    openEditImageModal(key) {
      const image = this[key] || this.user[key];
      if (!image) return;

      const aspectRatio = key === 'banner' ? 4.95 : 1;

      openDefaultEditor({
        src: image,
        imageCropAspectRatio: aspectRatio,
        utils: ['crop'],
        handleEvent: (type, detail) => {
          if (type === 'process') {
            this.uploadImage(key, detail.dest);
          }
        }
      });
    },
    uploadImage(key, file) {
      const formData = new FormData();
      formData.append(key, file);
      fallbackApi({
        url: `users/${this.user?.user?.id}/header_images`,
        payload: {
          body: formData,
          // Remove json content type so browser can set the appropriate formdata content type
          headers: {
            'Content-Type': undefined
          }
        },
        httpVerb: 'post',
        dataSetter: (data) => {
          this.$emit('updateImage', key, data[key]);
          this[key] = null;
        }
      });
    },
    deleteImage(key) {
      fallbackApi({
        url: `users/${this.user?.user?.id}/header_images`,
        httpVerb: 'delete',
        payload: {
          json: {
            [key]: true
          }
        },
        dataSetter: (data) => {
          const image = data && data[key];
          this.$emit('updateImage', key, image);
        }
      });
    },
    followUnfollow(status, id) {
      fallbackApi({
        url: `users/${id}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        this.$emit('followUnfollow', !this.user.is_following);
      });
    }
  }
};
</script>

<style scoped>
.avatarContainer {
  position: relative;
  width: fit-content;
  margin-top: -100px;
  background-color: white;
  border: 2px solid white;
  border-radius: 50% !important;
}

.avatar {
  object-fit: cover;
  width: 112px;
  height: 112px;
  border-radius: 50% !important;
}

.editAvatarBtn {
  position: absolute;
  bottom: -3px;
  right: -18px;
}

.editAvatarBtn i {
  background-color: white;
}

.editCoverPhotoButton {
  margin-top: -190px !important;
  padding-right:20px !important;
  height: 40px
}

.edit-avatar-button {
  margin-top: -2em !important;
  margin-left: -3em !important;
}

.imageOrgLogoContainer {
  height: 25px;
  width: 25px;
}

.organization-title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-top: -2.5rem;
}

.organization-section {
  margin-top: -2.5rem !important;
}

@media screen and (max-width: 577px) {
  .organization-section {
    margin-top: 0px !important;
  }
}

.custom-header {
  margin-top: -40px;
}

avl-modal {
  --modal-padding-footer: 25px 0;
}

#contactInfoModal avl-modal {
  --modal-padding-header: 1rem 1.4rem 0rem 1.4rem;
  --modal-padding-body: 0rem 1.4rem;
}
</style>
