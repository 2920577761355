<template>
  <section class="attachmentsNav pb-3">
    <div class="row justify-content-center">
      <nav class="col-sm-12 col-md-10 col-lg-8 ml-4 ml-md-0">
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/${modelPluralize}/${modelId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center">
      <section class="col-sm-12 col-md-10 col-lg-8 d-flex align-items-center ml-4 ml-md-0">
        <img
          v-show="modelInfo.logo && model === 'organization'"
          :src="modelInfo?.logo"
          class="logoImage"
          :alt="`${modelInfo?.name} logo`"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">{{ modelInfo?.name || modelInfo?.project?.title }}</h1>
      </section>
    </header>
  </section>
  <main class="row justify-content-center">
    <article class="col-sm-12 col-md-10 col-lg-8">
      <loading-indicator
        v-if="attachments.length === 0"
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <model-attachments
        v-else
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :attachments="attachments"
        :total-attachments="totalAttachments"
        show-all
      />
      <div
        v-if="loading && attachments.length > 0"
        class="ml-2 d-flex justify-content-center my-3"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            grayscaleLabel
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </article>
  </main>
</template>

<script>
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import ModelAttachments from '../../../components/shared/ModelAttachments.vue';
import { fallbackApi } from '../../../api/config';
import isAdminBase from '../../../utilities/authentication';
import { paginationMixin } from '../../../mixins';

export default {
  components: { LoadingIndicator, ModelAttachments },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      modelPluralize: '',
      model: '',
      modelId: '',
      modelInfo: {},
      attachments: [],
      totalAttachments: 0,
      page: 1,
      per: 20
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: this.modelPluralize,
        entityId: this.modelId
      });
    }
  },
  mounted() {
    this.fetchModelInfo();
    this.getAttachments();
  },
  beforeMount() {
    this.getModel();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#attachments-page');
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = modelInfo?.getAttribute('data-model-id');
    },
    fetchModelInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}`,
        dataSetter: (data) => this.modelInfo = data[this.model]
      });
    },
    handlePagination() {
      if (this.attachments.length !== this.totalAttachments && !this.loading) {
        this.page ++;
        this.getAttachments();
      }
    },
    getAttachments() {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/attachments?page=${this.page}&per=${this.per}`,
        dataSetter: (data) => {
          this.totalAttachments = data.attachments_count;
          this.attachments = this.uniqueItems([...this.attachments, ...data.attachments]);
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
<style scoped src="../../../stylesheet/lists.css"></style>

<style scoped>
.attachmentsNav {
  margin-top: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .attachmentsNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}
</style>
