<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10 row"
        aria-label="My Portfolio"
      >
        <div class="col-12 col-sm-9">
          <h2 class=" mb-3 DesktopTextMedium grayscaleBody">My Portfolio</h2>
          <Multiselect
            v-model="selectedValue"
            class="multiselect-ark mb-2 col-12 col-sm-8 col-md-6 col-lg-4 ml-0"
            placeholder="Select Portfolio View"
            aria-label="Select Portfolio View"
            track-by="name"
            label="name"
            value-prop="id"
            groups
            group-options="groupOptions"
            group-label="section"
            :options="options"
            :close-on-select="true"
            :searchable="true"
            :can-clear="false"
            :can-deselect="false"
            @select="selectedOption"
          />
        </div>
        <div class="col-12 col-sm-3 d-flex justify-content-start justify-content-sm-end">
          <a
            v-if="viewId"
            class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            my-3
            primaryButton
            setMetricsButtonSave
            align-self-end
          "
            :href="`/portfolios/${modelId}/portfolio_views/${viewId}/settings`"
          >
            <i class="fa fa-cog" aria-hidden="true" />
            View settings
          </a>
        </div>
      </nav>
    </div>
  </section>
  <main class="row justify-content-center">
    <div class="col-sm-12 col-md-10">
      <MyInvestments
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :view-id="Number(viewId)"
      />
    </div>
    <div class="col-sm-12 col-md-5">
      <ImpactAndIucnGoalDistribution
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        card-title="GOAL DISTRIBUTION BY IRIS+ IMPACT CATEGORY"
        :chart-data="categoryData"
        series-name="Impact Category"
        chart-div-id="goalByCategory"
      />
    </div>
    <div class="col-sm-12 col-md-5">
      <ImpactAndIucnGoalDistribution
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        card-title="GOAL DISTRIBUTION BY IUCN THREAT"
        :chart-data="threatData"
        series-name="Impact Category"
        chart-div-id="goalByThreat"
      />
    </div>
    <div class="col-sm-12 col-md-10">
      <SdgDistribution
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :view-id="Number(viewId)"
      />
    </div>
    <div class="col-sm-12 col-md-10">
      <!-- Hide for now - ESG -->
      <!-- <EsgDistribution
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :view-id="Number(viewId)"
      /> -->
    </div>
    <div class="col-sm-12 col-md-10">
      <CountryExposure
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :view-id="Number(viewId)"
      />
    </div>
  </main>
  <avl-modal
    :is-open="createPortfolioView"
    size="600px"
    header-title=""
    close-button-icon="fa fa-times"
    @avleventclose="closePortfolioViewTitleModal"
  >
    <div
      slot="header"
      class="d-flex justify-content-center custom-header"
    >
      <div class="col-12 text-left pl-0 mt-3">
        <h2 class="DesktopLinkSmall grayscaleOffBlack text-left mb-0">
          Create a new portfolio view
        </h2>
      </div>
    </div>
    <p class="DesktopTextSmall black text-left mt-4">
      Create a personalized portfolio view by customizing the investments displayed to suit your preferences.
    </p>
    <label
      for="nameInput"
      class="DesktopTextSmall grayscaleOffBlack text-left d-flex"
    >
      Title *
    </label>
    <input
      v-model="viewName"
      type="text"
      class="form-control ark DesktopTextSmall"
      required
    >
    <div class="row justify-content-center mt-3">
      <div class="col-12 text-right">
        <error-message
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
      </div>
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-link grayscaleBody ark-bold"
          @click="closePortfolioViewTitleModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link secondary7_ark ark-bold"
          :disabled="!viewName"
          @click="savePortfolioViewTitle"
        >
          Save
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>
import CountryExposure from './CountryExposure.vue';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
// import EsgDistribution from './EsgDistribution.vue';
import ImpactAndIucnGoalDistribution from './ImpactAndIucnGoalDistribution.vue';
import Multiselect from '@vueform/multiselect';
import MyInvestments from './MyInvestments.vue';
import SdgDistribution from './SdgDistribution.vue';
import { fallbackApi } from '../../../api/config';

export default {
  name: 'ArkPortfolio',
  components: {
    ImpactAndIucnGoalDistribution,
    // EsgDistribution,
    SdgDistribution,
    MyInvestments,
    CountryExposure,
    Multiselect,
    ErrorMessage
  },
  data() {
    return {
      model: '',
      modelPluralize: '',
      modelId: null,
      categoryData: [],
      threatData: [],
      createPortfolioView: false,
      viewName: '',
      errorMessageFromBackend: '',
      portfolioViews: [],
      options: [
        {
          section: '',
          groupOptions: [{ name: 'Basic', id: 'basic' }]
        },
        {
          section: '',
          groupOptions: [
            { name: 'Create a new view', id: 'create' },
            { name: 'Manage saved views', id: 'manage' }
          ]
        }
      ],
      selectedValue: 'basic'
    };
  },
  computed: {
    viewId() {
      return isNaN(this.selectedValue) ? null : this.selectedValue;
    }
  },
  beforeMount() {
    this.getModel();
    this.fetchImpactAndIucnGoalDistributionData();
    this.getPortfolioViews();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#js-portfolio');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
    },
    fetchImpactAndIucnGoalDistributionData() {
      const url = this.selectedValue !== 'basic' ? `?view_id=${this.selectedValue}` : '';
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/goals_distribution${url}`,
        dataSetter: (data) => {
          this.categoryData = data.iris_categories;
          this.threatData = data.iucn_threats;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    getPortfolioViews() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/portfolio_views`,
        dataSetter: (data) => {
          this.portfolioViews = data.portfolio_views;
          this.options[0].groupOptions = this.options[0].groupOptions.concat(data.portfolio_views);
        }
      });
    },
    selectedOption(value) {
      switch (value) {
        case 'create':
          this.createPortfolioView = true;
          break;
        case 'manage':
          location.href = `/${this.modelPluralize}/${this.modelId}/views`;
          break;
        default:
          this.selectedValue = value;
          this.fetchImpactAndIucnGoalDistributionData();
      }
    },
    closePortfolioViewTitleModal() {
      this.selectedValue = 'basic';
      this.viewName = '';
      this.errorMessageFromBackend = '';
      this.createPortfolioView = false;
    },
    savePortfolioViewTitle() {
      if (!this.viewName) return;

      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/portfolio_views`,
        httpVerb: 'post',
        payload: { json: { name: this.viewName.trim() } }
      })
      .then(({data}) => {
        let { portfolio_view } = data;
        this.portfolioViews.push(portfolio_view);
        this.options[0].groupOptions.push(portfolio_view);
        this.selectedValue = portfolio_view.id || 'basic';
        this.viewName = '';
        this.createPortfolioView = false;
        this.errorMessageFromBackend = '';
      })
      .catch(async (error) => {
        if (error?.error?.response) {
          const errorMessage = await error.error.response.json();
          this.errorMessageFromBackend = `${errorMessage.error}`;
        } else {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again later.';
        }
      });
    }
  }
};
</script>

<style scoped>

.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
      margin-top: 10px
  }
}

.custom-header {
  margin-top: -40px;
  max-width: 80%;
}

avl-modal {
  --modal-padding-header: 16px 16px 0px 7%;
  --modal-padding-body: 0 7% 0 7%;
}

@media (max-width: 600px) {
  avl-modal {
    --modal-padding-header: 16px 16px 0px 10%;
  }
}
</style>
