<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10 col-lg-8 ml-4 ml-md-0"
        aria-label="Go back to organization's profile"
      >
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/${modelPluralize}/${modelId}`"
        >
          <i class="fa fa-arrow-left" aria-hidden="true" />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center">
      <section class="col-sm-12 col-md-10 col-lg-8 d-flex align-items-center ml-4 ml-md-0">
        <div class="p-2 d-flex" aria-hidden="true">
          <img :src="images?.collaborate" class="mr-2 noDataImage" alt="An image of the world">
        </div>
        <h2 class="ml-2 mb-0 DesktopTextSmall grayscaleOffBlack">Manage your subscription.</h2>
      </section>
    </header>
  </section>

  <main class="row justify-content-center">
    <article class="col-sm-12 col-md-10 col-lg-8">
      <div v-if="loading">
        <loading-indicator
          :skeletons="[
            { width: null, height: '20px' },
            { width: null, height: '20px' },
            { width: null, height: '20px' },
            { width: null, height: '20px' }
          ]"
        />
      </div>
      <section v-else class="arkCardNoPadding">
        <div class="row mx-0">
          <nav-tabs
            :tab="activeTab"
            :tab-options="tabOptions"
            :tab-header="tabHeader"
            @tab-changed="onTabChanged"
          />
          <section class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
            <div v-show="activeTab === tabs.SUBSCRIPTION_TAB" class="pl-0 pl-xl-4">
              <section v-if="activeSubscription">
                <active-subscription
                  :info="subscriptions[0]"
                  @add-seat="onAddSeat"
                />
              </section>
              <section v-else-if="pendingSubscription" class="py-4">
                <h4 class="grayscaleOffBlack DesktopLinkSmall mb-4">Plans</h4>

                <article class="grayscaleOffBlack MobileTextSmall">
                  {{ pendingSubscriptionMsg }}
                </article>
              </section>

              <section v-else>
                <subscription-type-card
                  v-for="subscriptionType in subscriptionTypes"
                  :key="subscriptionType?.id"
                  :subscription-type="subscriptionType"
                  @request-subscription="onRequestSubscription"
                />
              </section>
            </div>
            <div v-show="activeTab === tabs.BILLING_TAB" class="pl-0 pl-xl-4">
              <billing-history
                :subscriptions="subscriptions"
                :current-subscription="
                  activeSubscription || pendingSubscription
                "
              />
            </div>
            <div v-show="activeTab === tabs.MANAGEMENT_TAB" class="pl-0 pl-xl-4">
              <manage-seats
                :current-subscription="activeSubscription || pendingSubscription"
                :model-pluralize="modelPluralize"
                :model-id="modelId"
                @update-seats="onUpdateSeats"
              />
            </div>
          </section>
        </div>
      </section>
    </article>
  </main>

  <toast-stack />
</template>

<script>
import ActiveSubscription from './ActiveSubscription.vue';
import BillingHistory from './BillingHistory.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ManageSeats from './ManageSeats.vue';
import NavTabs from '../../../../components/shared/NavTabs.vue';
import SubscriptionTypeCard from './SubscriptionTypeCard.vue';
import ToastStack from '../../../../components/shared/ToastStack.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: {
    ActiveSubscription,
    BillingHistory,
    LoadingIndicator,
    ManageSeats,
    NavTabs,
    SubscriptionTypeCard,
    ToastStack
  },
  data() {
    return {
      loading: true,
      model: '',
      modelPluralize: '',
      modelType: '',
      defaultProfileImage: '',
      modelId: null,
      images: {},
      tabHeader: 'Admin Console',
      activeTab: '',
      subscriptionTypes: [],
      subscriptions: [],
      tabs: {
        SUBSCRIPTION_TAB: 'subscriptions',
        BILLING_TAB: 'billing',
        MANAGEMENT_TAB: 'management'
      },
      STATUS: {
        PENDING: 0,
        ACTIVE: 1,
        EXPIRED: 2
      }
    };
  },
  computed: {
    activeSubscription() {
      return this.subscriptions.find((subscription) => subscription.status === this.STATUS.ACTIVE);
    },
    pendingSubscription() {
      return this.subscriptions.find((subscription) => subscription.status === this.STATUS.PENDING);
    },
    pendingSubscriptionMsg() {
      return `Your request to activate the
        ${ this.pendingSubscription?.type?.name }
        Plan is currently being processed.
        Thank you for your request.
        THE ARK accounting team will contact you shortly to facilitate payment for
        ${ this.pendingSubscription?.type?.default_seats }
        team members, amounting to
        €${ this.formattedNumber(this.pendingSubscription?.type?.price_yearly) }.
        Once payment is fulfilled, your plan will be activated.`;
    },
    tabOptions() {
      let finalOptions = [
        {
          alias: 'subscriptions',
          name: 'Subscriptions'
        }
      ];
      if (this.subscriptions.length) {
        finalOptions.push(
          {
            alias: 'billing',
            name: 'Billing'
          },
          {
            alias: 'management',
            name: 'Manage account',
            disabled: !!this.pendingSubscription
          }
        );
      }

      return finalOptions;
    }
  },
  beforeMount() {
    this.getModel();
  },
  async mounted() {
    await this.fetchSubscriptions();
    await this.fetchSubscriptionTypes();
    this.loading = false;
    this.activeTab = new URLSearchParams(window.location.search)?.get('tab') || 'subscriptions';
  },
  methods: {
    onTabChanged(tab) {
      this.activeTab = tab;
    },
    getModel() {
      const modelInfo = document.querySelector('#subscriptions');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.modelType = modelInfo?.getAttribute('data-model-type');
      this.images = {
        collaborate: modelInfo?.dataset?.imgUrl
      };
    },
    onRequestSubscription(id) {
      fallbackApi({
        url: 'subscriptions',
        httpVerb: 'post',
        payload: {
          json: {
            subscription: {
              organization_id: this.modelId,
              subscription_type_id: id
            }
          }
        },
        dataSetter: (data) => {
          this.subscriptions.push(data.subscription);
          this.$store.dispatch('addToast', {
            id: this.$store.getters.getId,
            message: 'The subscription request has been sent.',
            variant: 'success',
            autohide: true
          });
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    onUpdateSeats(updatedSubscription) {
      const subscription = this.subscriptions.find((sub) => sub.id === updatedSubscription.id);
      subscription.seats = updatedSubscription.seats;
    },
    async fetchSubscriptions() {
      return fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/subscriptions`,
        dataSetter: (data) => {
          this.subscriptions = data.subscriptions;
        }
      });
    },
    async fetchSubscriptionTypes() {
      return fallbackApi({
        url: `subscription_types?organization_type=${this.modelType}`,
        dataSetter: (data) => {
          this.subscriptionTypes = data.subscription_types;
        }
      });
    },
    formattedNumber(string) {
      return Math.trunc(parseFloat(string)).toLocaleString();
    },
    onUpdateSubscription() {
      // do something
    },
    onAddSeat(updatedSubscription) {
      const index = this.subscriptions
        .findIndex((subscription) => subscription.id === updatedSubscription.id);
      index !== -1 && (this.subscriptions[index] = updatedSubscription);
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped>
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
    margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}

.noDataImage {
  width: 48px;
  height: 45px;
}
</style>
