import 'v-calendar/dist/style.css';
import ImpactCards from './components/ImpactCards.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-goals-card')){
    const app = createApp(ImpactCards);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#js-goals-card');
  }
});
