<template>
  <section>
    <div v-if="model === 'organization' && Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <organization-header
      v-if="model === 'organization' && organizationHeaderInfo"
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :is-admin="isAdmin"
      :organization="organizationHeaderInfo"
      :organization-type="organizationType"
      :active-tab="activeTab"
      @update-image="updateHeaderImage"
      @set-organization-following="
        organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
      "
    />
    <project-header
      v-else-if="model === 'project' && project"
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :is-admin="isAdmin"
      :project="project"
      :project-type="projectType"
      :active-tab="activeTab"
      @update-image="updateHeaderImage"
    />
    <section class="arkCard">
      <header class="d-flex justify-content-start">
        <h2 class="DesktopTextMedium primaryDefault">ESG</h2>
      </header>
      <section v-if="loading">
        <loading-indicator
          :loading="loading"
          :skeletons="[
            {width: '400px', height: '300px'},
          ]"
        />
      </section>
      <section v-else>
        <figure v-if="chartData.length" class="mt-3">
          <div id="esg-spline-chart" />
        </figure>
        <no-data-card
          v-else
          :first-message="
            isAdmin ?
              'You haven’t used any metrics with SDG associations yet' :
              `${modelTitle} hasn't added any SDG-linked impact data yet`
          "
          :second-message="
            isAdmin ?
              `Attract investors by featuring goals that showcase
          your impact and approach` :
              ''
          "
          button-text="Continue Impact Reporting"
          :show-button="isAdmin"
          @button-click="goToImpact"
        />
      </section>
    </section>
  </section>
</template>
<script>
import Highcharts from 'highcharts';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import OrganizationHeader from '../../organizations/sharedComponents/OrganizationHeader.vue';
import ParallelAxes from 'highcharts/modules/parallel-coordinates.js';
import ProjectHeader from '../../projects/sharedComponents/ProjectHeader.vue';
import { fallbackApi } from '../../../api/config';
import { modelMixin } from '../../../mixins';
import { splineChartTemplate } from '../../../domain/constants';

ParallelAxes(Highcharts);

const getLabelData = (sdgData, value) => {
  const { sdg_avatar: src, sdg_short_name: alt } = sdgData.find(
    (sdg) => sdg.sdg_code === value
  );
  return { src, alt };
};
const getTooltipData = (sdgData, context) => {
  const title = context.key !== 'SDG' ? context.key : context.formattedValue;
  const content = context.key !== 'SDG' ?
    context.point.formattedValue :
    sdgData.find((sdg) =>
      sdg.sdg_code === parseInt(context.point.formattedValue.replace('SDG ', ''))
    ).sdg_short_name;
  return { title, content };
};

const template = (sdgData, sdgsWithLines) => {
  return {
    ...splineChartTemplate,
    title: {
      text: ''
    },
    xAxis: {
      categories: ['ESG', 'SDG', 'Number of goals'],
      offset: 10,
      opposite: true
    },
    yAxis: [{
        categories: ['Environmental', 'Social', 'Governance'],
        title: { text: null }
      },
      {
        categories: [...Array(17)].map((v, i) => `SDG ${i + 1}`),
        title: { text: null },
        labels: {
          useHTML: true,
          formatter() {
            const sdgValue = parseInt(this.value.replace('SDG ', ''));
            if (!sdgsWithLines.includes(sdgValue)) return '';

            const { src, alt } = getLabelData(sdgData, sdgValue);
            return `
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <img
                        alt="${alt}"
                        src="${src}"
                        style="height: 15px; width: 15px"
                      >
                    </div>
                    ${this.value}
                  </div>
                </div>
              </div>
            `;
          }
        }
      },
      {
        min: 0,
        allowDecimals: false,
        title: { text: null },
        labels: {
          formatter() {
            return this.value < 0 ? '' : `${this.value}`;
          }
        }
      }
    ],
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      formatter() {
        const { title, content } = getTooltipData(sdgData, this);
        return `
          <div
             style="
               padding: 5px;
               text-align: center;
               border: 1px solid black;
               background-color: #EEE
             "
             class="row"
          >
            <div class="col p-0">${title}: ${content}</div>
          </div>
        `;
      }
    }
  };
};

export default {
  name: 'ESGGraph',
  components: { LoadingIndicator, NoDataCard, OrganizationHeader, ProjectHeader },
  mixins: [modelMixin],
  data() {
    return {
      modelTitle: undefined,
      splineChart: undefined,
      chartData: [],
      sdgData: [],
      loading: true
    };
  },
  watch: {
    chartData() {
      // We must run this in the next rendered frame, or Highcharts crashes
      setTimeout(() => this.renderHighchart(), 1);
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    goToImpact() {
      location.href = 'impact';
    },
    getModel() {
      const esgProfile = document.querySelector('#esg-profile');
      this.modelTitle = esgProfile?.dataset.modelTitle;
      this.getModelDetails(esgProfile);
    },
    normalizeData(data) {
      return data.map((dataSet, index) => {
        return {
          name: 'Series ' + index,
          data: [dataSet.slice(1)].map(([esg, sdg, goals]) => [esg, sdg - 1, goals])[0],
          shadow: false,
          events: {
            mouseOver() {
              this.update({ color: '#478964' });
            },
            mouseOut() {
              this.update({ color: 'rgba(36, 66, 43, 0.1)' });
            }
          }
        };
      });
    },
    fetchData() {
      fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/esgs`,
          dataSetter: (data) => {
            this.chartData = this.normalizeData(data.chart_data);
            this.sdgData = data.sdg_goals;
          }
        })
        .finally(() => this.loading = false);
    },
    renderHighchart() {
      if (!this.chartData.length) {
        this.splineChart = undefined;
        return;
      }

      this.splineChart = Highcharts.chart(
        'esg-spline-chart',
        template(this.sdgData, this.chartData.map(({ data }) => data[1] + 1))
      );
      this.splineChart.series.forEach((series) => series.remove());
      this.chartData.forEach((series) => this.splineChart.addSeries(series));
    }
  }
};
</script>
