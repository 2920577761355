<template>
  <avl-modal
    :is-open="commentModalOpen"
    size="60%"
    header-title=""
    footer
    @avleventclose="closeModal"
  >
    <div
      slot="header"
      class="row d-flex justify-content-center header"
      style="padding-left: 7%"
    >
      <div class="col-12 text-left pl-0">
        <h2 class="DesktopTextMedium primary2_ark boldPlaceholder">
          Comment for {{ entry.row_name }}
        </h2>
      </div>
    </div>
    <div>
      <textarea
        v-model="comment"
        placeholder="Add a comment"
        class="form-control ark DesktopTextSmall"
        aria-label="Add a comment"
        rows="8"
        required
        :class="
          errorOnsubmit && !comment
            ? 'notValid'
            : ''
        "
      />
    </div>
    <div slot="footer" class="pr-3">
      <div
        v-if="error"
        class="row d-flex justify-content-center mt-2"
      >
        <div class="col-12 text-right">
          <error-message
            :error-condition="!!error"
            :error-message="error"
          />
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-1">
        <div class="col-12 text-right">
          <div
            v-if="loading"
            class="spinner-border primary2_ark"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else>
            <button
              type="button"
              class="btn btn-link grayscaleBody ark-bold normal-text"
              @click="closeModal"
            >
              Cancel
            </button>
            <button
              v-if="entry.measurement_comment?.comment"
              type="button"
              class="btn btn-link secondary7_ark ark-bold normal-text"
              @click="deleteComment"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn vueModalsSaveButtons normal-text"
              @click="save"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </avl-modal>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';

export default {
  name: 'CommentModal',
  components: { ErrorMessage },
  props: {
    commentModalOpen: {
      type: Boolean,
      required: true
    },
    entry: {
      type: Object,
      required: true
    },
    goalMetricId: {
      type: Number,
      required: true
    }
  },
  emits: ['modalClosedEvent'],
  data() {
    return {
      loading: false,
      error: null,
      errorOnsubmit: false,
      comment: null,
      validationSchema: null
    };
  },
  watch: {
    entry() {
      this.comment = this.entry.measurement_comment?.comment;
      this.error = null;
    }
  },
  mounted() {
    this.validationSchema = yup.object({
      comment: yup.string().required()
    });
  },
  methods: {
    closeModal(event, newComment) {
      this.$emit('modalClosedEvent', newComment);
      this.errorOnsubmit = false;
    },
    save() {
      this.loading = true;

      this.validationSchema.validate({ comment: this.comment })
        .then(() => {
          this.loading = false;
          this.closeModal(null, { comment: this.comment });
        })
        .catch((error) => {
          this.error = error.message;
          this.errorOnsubmit = true;
          this.loading = false;
        });
    },
    deleteComment() {
      this.closeModal(null, { comment: null });
    }
  }
};

</script>

<style scoped src="../../../stylesheet/forms.css"></style>
<style scoped>
avl-modal {
  --modal-box-shadow-header: 0px;
  --modal-box-shadow-footer: 0px;
}

avl-modal .header {
  padding-left: 7%;
  margin: 15px 0;
}
</style>
