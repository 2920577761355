<template>
  <section>
    <div v-if="Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <organization-header
        v-if="organizationHeaderInfo"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organizationHeaderInfo"
        :organization-type="organizationType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
        @set-organization-following="
          organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
        "
      />
      <create-post
        :show-add-post-input="isAdmin"
        :organization-header-info="organizationHeaderInfo"
        :edit-post="editPost"
        :share-post="sharePost"
        :author="{
          logo: organizationHeaderInfo.logo,
          name: organizationHeaderInfo.name,
        }"
        @post-created="newPost"
        @post-deleted="postDeleted"
        @post-updated="updateNews"
        @comment-created="commentCreated"
        @comment-updated="updateComments"
        @modal-closed="createUpdatePostModalClosed"
      />
      <div v-if="loading && news.length === 0">
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
      </div>
      <div v-else class="mt-4">
        <div v-if="news.length" id="cards">
          <ark-post
            v-for="post in news"
            :key="post.id"
            :post="post"
            :author="{
              logo: organizationHeaderInfo.logo,
              name: organizationHeaderInfo.name,
            }"
            :is-post-admin="isAdmin"
            @post-deleted="postDeleted"
            @edit-post="updatePost"
            @add-comment="addComment"
            @edit-comment="editComment"
            @comment-deleted="commentDeleted"
            @share-post="sharingPost"
          />
        </div>
        <div
          v-if="loading && news.length > 0"
          class="ml-2 d-flex justify-content-center my-3"
        >
          <div
            class="
            d-flex
            align-content-center
            spinner-border
            grayscaleLabel
            align-self-center
          "
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { modelMixin, paginationMixin, postsMixin } from '../../../../mixins';
import ArkPost from '../../../../components/listItems/ArkPost.vue';
import CreatePost from '../../../../components/shared/CreatePost.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import OrganizationHeader from '../../sharedComponents/OrganizationHeader.vue';
import { fallbackApi } from '../../../../api/config';
import { iframely } from '@iframely/embed.js';
import { nextTick } from 'vue';

export default {
  components: { ArkPost, OrganizationHeader, LoadingIndicator, CreatePost },
  mixins: [modelMixin, postsMixin, paginationMixin],
  data() {
    return {
      loading: false,
      page: 1,
      per: 10,
      totalNews: 0,
      deletedPosts: 0
    };
  },
  watch: {
    news() {
      if (this.news.length <= 5 && this.totalNews > 5) {
        this.getTopNews();
      }
    }
  },
  mounted() {
    this.getTopNews();
  },
  methods: {
    handlePagination() {
      if (this.news.length !== this.totalNews && !this.loading) {
        if (this.deletedPosts === 0) this.page ++;
        this.getTopNews();
      }
    },
    getModel() {
      const modelInfo = document.querySelector('#model-news');
      this.getModelDetails(modelInfo);
    },
    getTopNews() {
      this.loading = true;
      fallbackApi({
        url: `organizations/${this.modelId}/top_news?page=${this.page}&per=${this.per}`,
        dataSetter: (data) => {
          this.totalNews = data.top_news_count;
          this.news = this.uniqueItems([...this.news, ...data.top_news]);

          if (this.deletedPosts > 0) {
            this.page ++;
            this.deletedPosts -= this.per;
            this.getTopNews();
          } else {
            this.loading = false;
            this.deletedPosts = 0;
          }

          nextTick(() => {
            window.TopNewsHelper.setupPostButtons();
          });
          iframely.load();
        }
      });
    },
    newPost(post) {
      this.sharePost = {};
      if (post.organization_id != this.modelId) return;
      this.news = [post, ...this.news];
      this.totalNews ++;
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    postDeleted(postId) {
      let news;
      news = this.news.filter((post) => {
        return post.id !== postId;
      });

      this.news = news;
      this.totalNews --;
      this.deletedPosts ++;
    },
    createUpdatePostModalClosed() {
      this.editPost = {};
      this.sharePost = {};
    },
    sharingPost(post) {
      this.sharePost = post.parent || post;
    }
  }
};
</script>
