class LinkHelper {
  static setupTooltip($obj) {
    var dataListStr = $obj.attr('data-list');
    var dataList = dataListStr.split(',');
    var tooltipHtml = '';
    var tooltipElemNum = 10;
    if (dataList.length > tooltipElemNum) {
      tooltipHtml = dataList.slice(0, tooltipElemNum).join('<br>').replace(/\"/g, "").replace("[", "").replace("]", "");
      tooltipHtml.concat("<br> See more...")
    } else {
      tooltipHtml = dataList.join('<br>').replace(/\"/g, "").replace("[", "").replace("]", "");
    }
    $obj.attr('data-original-title', tooltipHtml);
  }

  static setupTooltips() {
    $('.ark-object-link').each(function() {
      LinkHelper.setupTooltip($(this));
    });
  }
}

LinkHelper.setupTooltips();

export { LinkHelper };
