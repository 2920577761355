const getters = {
  getToasts(state) {
    return state.toasts;
  },
  getId(state) {
    if (state.toasts.length) {
      let maxId = Math.max(...state.toasts.map((toast) => toast.id), 0);
      return maxId + 1;
    } else {
      return 1;
    }
  }
};

export default getters;
