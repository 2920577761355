<template>
  <div class="testimonial-carousel primaryDarkBckg py-5">
    <div id="testimonialCarousel" class="carousel slide py-5" data-ride="carousel">

      <div class="carousel-inner grayscaleOffWhite">
        <div v-for="(testimonial, index) in testimonials" :key="index" class="carousel-item" :class="{ active: index === activeIndex }">
          <div class="testimonial-content w-50 m-auto text-center">
            <p class="DesktopDisplaySmall">"{{ testimonial.text }}"</p>
            <img :src="testimonial.logo" height="100" width="250" alt="Testimonial logo" />
            <h4 class="DesktopDisplayXSmall mt-5">{{ testimonial.name }}</h4>
            <span class="DesktopDisplayXSmall">{{ testimonial.position }}</span>
          </div>
        </div>
      </div>
<!--       <a class="carousel-control-prev" href="#testimonialCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#testimonialCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a> -->
      <ol class="carousel-indicators mt-5">
        <li v-for="(testimonial, index) in testimonials" :key="index" :data-target="'#testimonialCarousel'" :data-slide-to="index" :class="{ active: index === activeIndex }"></li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec orci id sem cursus vulputate. Nullam at semper augue.",
          name: "John Doe",
          position: "CEO",
          logo: "https://via.placeholder.com/250x65"
        },
        {
          text: "Nulla et erat feugiat, pulvinar nisi in, faucibus nisi. Nunc fringilla nisl eget lectus rhoncus posuere. Nullam at semper augue.",
          name: "Jane Smith",
          position: "Marketing Manager",
          logo: "https://via.placeholder.com/250x65"
        },
        // Add more testimonials here
      ],
      activeIndex: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.startCarousel();
    });
  },
  methods: {
    startCarousel() {
      // Start the carousel
      const carouselElement = document.getElementById('testimonialCarousel');
      const carousel = new window.bootstrap.Carousel(carouselElement, {
        interval: 10000 // Adjust the interval as needed (in milliseconds)
      });
      carouselElement.addEventListener('slide.bs.carousel', (event) => {
        this.activeIndex = event.to;
      });
    }
  }
};
</script>
