import OrganizationEdit from './components/OrganizationEditTeam.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#edit-organization-team')){
    const app = createApp(OrganizationEdit);
    app.use(store);
    app.mount('#edit-organization-team');
  }
});
