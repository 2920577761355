<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Change your password:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Change your password</h4>
          <section class="mt-4">
            <input
              id="currentPasswordInput"
              v-model="form.currentPassword"
              type="password"
              class="form-control ark DesktopTextSmall py-3 px-3"
              required
              placeholder="Enter current password *"
              aria-errormessage="passwordError"
              :aria-invalid="!!errors.currentPassword"
              :class="{ notValid: errors.currentPassword }"
              @blur="validate"
            >
            <error-message
              id="passwordError"
              :error-condition="!!errors.currentPassword"
              :error-message="errors.currentPassword"
            />
          </section>
          <section class="mt-4">
            <input
              id="passwordInput"
              v-model="form.password"
              type="password"
              class="form-control ark DesktopTextSmall py-3 px-3"
              required
              placeholder="Enter new password *"
              aria-errormessage="passwordError"
              :aria-invalid="!!errors.password"
              :class="{ notValid: errors.password }"
              @blur="validate"
            >
            <error-message
              id="passwordError"
              :error-condition="!!errors.password"
              :error-message="errors.password"
            />
            <div class="DesktopTextXSmall">
              Use 8 or more characters with a mix of letters, numbers & symbols
            </div>
          </section>
        </fieldset>
      </section>
    </form>
    <error-message
      class="saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  currentPassword: yup.string().required(),
  password: yup.string().required()
    .min(8)
    // Regexp extracted from https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    .matches(
      /(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/g,
      'Password must include at least 1 letter, 1 number and 1 special character'
    )
});

export default {
  components: { ErrorMessage },
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        currentPassword: '',
        password: ''
      },
      errors: {
        currentPassword: '',
        password: ''
      },
      errorMessageFromBackend: ''
    };
  },
  computed: {
    dataChanged() {
      return this.form.currentPassword !== '' && this.form.password !== '';
    }
  },
  methods: {
    async validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `users/${this.userId}/update_password`,
          httpVerb: 'patch',
          payload: {
            json: {
              current_password: this.form.currentPassword,
              password: this.form.password
            }
          }
        })
        .then(() => {
          this.errorMessageFromBackend = '';
          this.form = {
            currentPassword: '',
            password: ''
          };
          this.showErrors = false;
        })
        .catch(async (error) => {
          if (error?.error?.response) {
            const errorMessage = await error.error.response.json();
            this.errorMessageFromBackend = errorMessage.error;
          } else {
            this.errorMessageFromBackend = error?.error?.message ||
              'Something went wrong. Please try again.';
          }
        })
        .finally(() => (this.loading = false));
      }
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
