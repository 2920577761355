<template>
  <section class="arkCard subscription-card">
    <h1 class="DesktopDisplayLargeBold grayscaleBody">
      {{ formattedNumber(subscriptionType.price_yearly) }}€
      <span class="ml-2 DesktopTextlarge">/year</span>
    </h1>
    <h2 class="DesktopDisplaySmallBold grayscaleBody mt-3">
      {{ subscriptionType.name }} Plan
    </h2>
    <p class="DesktopTextlarge grayscaleBody mt-3">
      {{ subscriptionType.description }}
    </p>
    <section class="grayscaleBody DesktopLinkMedium top-border-line mt-3">
      <p class="mt-3">
        <i class="fa fa-check" aria-hidden="true" />
        {{ subscriptionType.default_seats }} team member seats
      </p>
      <p>
        <i class="fa fa-check" aria-hidden="true" />
        {{ formattedNumber(subscriptionType.seat_price_monthly) }}€
        <span class="ml-2">/ month for additional member seat</span>
      </p>
    </section>
    <button
      class="btn btn-sm DesktopLinkXSmall primaryButton transition-02 mr-2 w-100"
      @click="requestSubscription"
    >
      Send request
    </button>
  </section>
</template>

<script>

export default {
  props: {
    subscriptionType: {
      type: Object,
      required: true
    }
  },
  emits: ['request-subscription'],
  methods: {
    requestSubscription() {
      this.$emit('request-subscription', this.subscriptionType.id);
    },
    formattedNumber(string) {
      return Math.trunc(parseFloat(string)).toLocaleString();
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped>
.subscription-card {
  background-color: #F4F4F4;
  border-radius: 32px;
}

.top-border-line {
  border-top: 2px solid #BDBFD2;
}
</style>