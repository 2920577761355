import $eventBus from '../../utilities/eventBus.js';
import ArkMapContainer from './components/ArkMapContainer.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-map')){
    const app = createApp(ArkMapContainer);
    app.config.globalProperties.$eventBus = $eventBus;
    app.use(store);
    app.mount('#js-map');
  }
});
