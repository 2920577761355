<template>
  <div v-if="filteredData.length">
    <hr>
    <div class="DesktopLinkXSmall black ml-2 mb-2">{{ title }}</div>
    <div v-for="item in dataShown" :key="checkboxID(item)">
      <div class="container">
        <div class="row">
          <div class="col-2">
            <div class="row justify-content-center">
              <input
                class="form-check-input"
                type="checkbox"
                :checked="checkboxStatus(item)"
                :disabled="(showCounts && !item.count) || disabled"
                @click="checkboxClick(item, type, !checkboxStatus(item))"
              >
            </div>
          </div>
          <div class="col-9">
            <div
              :class="`
                row
                justify-content-start
                DesktopTextXSmall
                ${!showCounts || item.count ? 'grayscaleOffBlack' : 'grayscalePlaceholder'}
              `"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-if="showCounts" class="col-1">
            <div
              :class="`
                row
                justify-content-start
                DesktopTextXSmall
                ${item.count ? 'grayscaleOffBlack' : 'grayscalePlaceholder'}
              `"
            >
              {{ item.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="filteredData.length > minItemsShown"
      type="button"
      class="ml-3 my-2 pointer DesktopTextXSmall primaryDarkmode"
      tabindex="0"
      @keypress.prevent="toggleShowMore"
      @click="toggleShowMore"
    >
      {{
        `Show ${isShowMoreVisible ? `all ${filteredData.length}` : 'less'}`
      }}
      <i
        :class="`fa fa-chevron-${isShowMoreVisible ? 'down' : 'up'} ml-1`"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterSublist',
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    clearFilters: {
      type: Boolean,
      required: false,
      default: false
    },
    keepZeroCount: {
      type: Boolean,
      required: false,
      default: true
    },
    checkedItems: {
      type: Array,
      required: true
    },
    showCounts: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['checkboxClick'],
  data() {
    return {
      checkboxStatuses: {},
      minItemsShown: 5,
      isShowMoreVisible: true
    };
  },
  computed: {
    filteredData() {
      if (this.keepZeroCount) return this.data;
      return this.filterData(this.data);
    },
    dataShown() {
      if (this.isShowMoreVisible) return this.filteredData.slice(0, this.minItemsShown);
      return this.filteredData;
    },
    checkboxID() {
      return (item) => item[
        this.type === 'sdg_goal_codes' ? 'sdg_goal_code' : 'id'
      ];
    },
    checkboxStatus() {
      return (item) => this.checkboxStatuses[this.checkboxID(item)];
    }
  },
  watch: {
    clearFilters() {
      this.checkboxStatuses = {};
    }
  },
  mounted() {
    this.checkedItems?.forEach((item) => {
      this.checkboxStatuses[this.checkboxID(item)] = true;
    });
  },
  methods: {
    filterData(data) {
      return data.filter((item) => item.count);
    },
    checkboxClick(item, type, buttonGotChecked) {
      const id = this.checkboxID(item);
      this.checkboxStatuses[id] = !this.checkboxStatuses[id];
      this.$emit('checkboxClick', item, type, buttonGotChecked);
    },
    toggleShowMore() {
      this.isShowMoreVisible = !this.isShowMoreVisible;
    }
  }
};
</script>
