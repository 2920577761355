<template>
  <section class="d-flex row arkCardNoPadding">
    <div class="col logocoL">
      <figure>
        <img
          v-if="user.avatar"
          :src="user.avatar"
          class="logoImageMax round d-flex objectFitCover"
          :alt="user.name + 's avatar'"
        >
      </figure>  
    </div>
    <div class="col">
      <div class="row justify-content-between">
        <div class="col-12 col-sm-8">
          <a
            class="DesktopLinkSmall primaryDefault d-flex mt-0 pointer"
            :href="`/users/${user.id}`"
          >
            {{ user.name }}
          </a>
          <p class="DesktopTextXSmall d-flex grayscaleBody mb-0">
            {{ user.occupation }}
          </p>
          <section class="d-flex">
            <figure class="align-self-start d-flex mb-0 pl-0">
              <img
                v-if="user.organization?.logo"
                :src="user.organization.logo"
                class="logoImageSmall d-flex objectFitCover mr-2"
                :alt="user.organization.name + 's logo'"
              >
            </figure>
            <p class="DesktopTextXSmall d-flex grayscaleBody">
              <span class="organization-title-size">
                {{ user.organization?.name }}
              </span>
            </p>
          </section>
        </div>
        <div
          v-if="!isCurrentUser"
          class="col-12 col-sm-4 d-flex justify-content-sm-end align-items-start"
        >
          <button
            class="
              btn
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
              follow-unfollow-button
            "
            @click.prevent="
              followUnfollow(user?.is_following ? 'unfollow' : 'follow', user)
            "
          >
            <i
              v-if="user?.is_following"
              class="far fa-check mr-2 align-self-center"
              aria-hidden="true"
            />
            {{ user?.is_following ? 'Following': 'Follow' }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { fallbackApi } from '../../api/config';

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    author: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  computed: {
    isCurrentUser() {
      const currentUser = this.$store.state.auth.user;
      return this.author || this.user.id === currentUser?.id;
    }
  },
  methods: {
    followUnfollow(status, user) {
      fallbackApi({
        url: `users/${user.id}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        user.is_following = !user.is_following;
      });
    }
  }
};
</script>

<style scoped>
.logocoL {
  flex-grow: inherit;
}

.logoImageMax {
  width: 60px;
  height: 60px;
  border-radius: 3px;
}

.logoImageSmall {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.organization-title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
