<template>
  <section>
    <div v-if="loadingUserInfo">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <user-header
        :user="user"
        @follow-unfollow="followUnfollow"
        @update-image="updateHeaderImage"
      />
      <user-about :user="user?.user || {}" :is-admin="currentUserProfile" />
      <section v-if="projects.length > 0" class="arkCard">
        <h3 class="DesktopTextMedium primaryDefault mt-0">Projects</h3>
        <project-card
          v-for="project in projects.slice(0, 3)"
          :key="project.id"
          :project="project"
          @invest="investToProject"
        />
        <show-more
          v-if="projects.length > 3"
          text="See all projects"
          :link="`/users/${user?.user?.id}/projects`"
          :icon="false"
        />
      </section>
      <section v-if="reports.length > 0" class="arkCard">
        <h3 class="DesktopTextMedium primaryDefault mt-0">Reports</h3>
        <project-card
          v-for="report in reports.slice(0, 3)"
          :key="report.id"
          :project="report"
          @invest="investToProject"
        />
        <show-more
          v-if="reports.length > 3"
          text="See all reports"
          :link="`/users/${user?.user?.id}/projects`"
          :icon="false"
        />
      </section>
      <ark-posts-list
        :current-user-profile="currentUserProfile"
        :user-name="user?.user?.first_name || ''"
        :no-posts="news.length === 0"
        @start-post="startUserPost"
      >
        <template #body>
          <ark-post
            v-for="(post, index) in visibleNews"
            :key="post.id"
            :post="post"
            :author="{
              logo: user.avatar,
              name: user?.user?.full_name,
            }"
            :is-post-admin="currentUserProfile"
            :card-class="setCardClass(index, visibleNews.length)"
            @post-deleted="postDeleted"
            @edit-post="updatePost"
            @add-comment="addComment"
            @edit-comment="editComment"
            @comment-deleted="commentDeleted"
            @share-post="sharingPost"
          />
          <show-more
            v-if="(allNewsCount > 3 || news.length > 3)"
            text="See all activity"
            :link="`/users/${user?.user?.id}/activity`"
            :icon="false"
          />
        </template>
      </ark-posts-list>
      <user-areas-of-knowledge :user="user?.user || {}" :is-admin="currentUserProfile" />
      <create-post
        :show-add-post-input="false"
        :edit-post="editPost"
        :share-post="sharePost"
        :author="{
          logo: user.avatar,
          name: user?.user?.full_name,
        }"
        :open-modal="userNewPostOpenModal"
        @post-created="newPost"
        @post-deleted="postDeleted"
        @post-updated="updateNews"
        @comment-created="commentCreated"
        @comment-updated="updateComments"
        @modal-closed="createUpdatePostModalClosed"
      />
    </div>
  </section>
  <CreateContributionForm
    :modal-open="createContributionModalOpen"
    :contribution-recipient-id="contributionRecipientId"
    :contribution-recipient-name="contributionRecipientName"
    contribution-recipient-type="project"
    :contribution-button-text="contributionButtonText"
    @close-modal-event="closeCreateContributionFormModal"
  />
</template>

<script>
import { contributionsMixin, postsMixin, userMixin } from '../../../../mixins';
import ArkPost from '../../../../components/listItems/ArkPost.vue';
import ArkPostsList from './ArkPostsList.vue';
import CreateContributionForm from '../../../contributions/components/CreateContributionForm.vue';
import CreatePost from '../../../../components/shared/CreatePost.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ProjectCard from '../../../../components/listItems/ProjectCard.vue';
import ShowMore from '../../../../components/shared/ShowMore.vue';
import UserAbout from './UserAbout.vue';
import UserAreasOfKnowledge from './UserAreasOfKnowledge.vue';
import UserHeader from '../../sharedComponents/UserHeader.vue';
import { iframely } from '@iframely/embed.js';
import { nextTick } from 'vue';

export default {
  components: {
    LoadingIndicator,
    ArkPostsList,
    CreatePost,
    ArkPost,
    ShowMore,
    ProjectCard,
    UserHeader,
    UserAbout,
    UserAreasOfKnowledge,
    CreateContributionForm
  },
  mixins: [postsMixin, userMixin, contributionsMixin],
  data() {
    return {
      projectData: [],
      allNewsCount: 0,
      userId: '',
      currentUserId: '',
      loading: false,
      userNewPostOpenModal: false,
      contributionRecipientId: '',
      contributionRecipientName: '',
      createContributionModalOpen: false
    };
  },
  computed: {
    currentUserProfile() {
      return this.userId == this.currentUserId;
    },
    visibleNews() {
      return this.news.slice(0, 3);
    },
    projects() {
      return this.projectData.filter((project) => project.type === 'ConservationProject');
    },
    reports() {
      return this.projectData.filter((report) => report.type === 'ReportingProject');
    }
  },
  beforeMount() {
    this.getModel();
    this.currentUserId = this.$store.state.auth.user.id;
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#user-profile');
      this.userId = modelInfo?.dataset.userId;
    },
    setExtraUserInfo(data) {
      this.projectData = data.projects;
      this.news = data.top_news;
      this.allNewsCount = data.top_news_count;
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
      iframely.load();
    },
    setCardClass(index, maxLength) {
      return (
        index + 1 === maxLength ?
        'arkCardNoPadding py-2' :
        'arkCardNoPadding bottomLine py-2'
      );
    },
    startUserPost() {
      this.userNewPostOpenModal = true;
    },
    newPost(post) {
      this.sharePost = {};
      if (post.user_id != this.userId) return;
      this.news = [post, ...this.news];
      nextTick(() => {
        window.TopNewsHelper.setupPostButtons();
      });
    },
    postDeleted(postId) {
      let news;
      news = this.news.filter((post) => {
        return post.id !== postId;
      });

      this.news = news;
    },
    createUpdatePostModalClosed() {
      this.editPost = {};
      this.sharePost = {};
      this.userNewPostOpenModal = false;
    },
    sharingPost(post) {
      this.userNewPostOpenModal = true;
      this.sharePost = post.parent || post;
    },
    investToProject(project, buttonText) {
      this.openCreateContributionFormModal(project, 'project', buttonText);
    }
  }
};
</script>
