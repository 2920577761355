class IframeHelper {
  static setupIframes() {
    let iframes = $('.news_post_url_attachment');
    if(iframes.length > 0) {
      iframes.each(function(){
        var iframe_html = $('<div/>').html($(this).attr('value'));
        if ( $(this).html().length === 0 ){
          $(this).html(iframe_html);
        }
      })
    }
  }
}
export { IframeHelper };
