<template>
  <div id="infoModal">
    <avl-modal
      :is-open="infoModalOpen"
      size="1140px"
      header-title=""
      close-button-icon="fa fa-times"
      @avleventclose="closeInfoModal"
    >
      <div v-if="info.name" slot="header" class="row custom-header">
        <div class="col-12 px-4 py-2 pl-0">
          <h2 class="DesktopLinkMedium textBodyColor text-left">{{ info.name }}</h2>
        </div>
      </div>
      <div class="row px-4 pb-4 pt-1 mb-4">
        <section class="col-12 col-sm-12 col-md-6 col-lg-8">
          <article v-if="info.description" class="row DesktopTextSmall black pr-2 pb-3 text-left">
            {{ info.description }}
          </article>
          <slot name="body" />
        </section>
        <div class="col-12 col-sm-12 col-md-6 col-lg-4 text-right pr-0 pl-0 pl-sm-0 pl-md-3">
          <slot name="button" />
          <aside class="p-1 border">
            <div class="row px-4 py-2">
              <div class="col-12 pl-0">
                <h3 class="DesktopLinkSmall textBodyColor text-left m-0 pl-0">Details</h3>
              </div>
            </div>
            <slot v-if="$slots.detailsBody" name="detailsBody" />
            <div v-else>
              <div v-for="(option, index) in details.options" :key="option">
                <div class="row px-4 py-2">
                  <div class="col-6">
                    <div class="row DesktopLinkXSmall grayscaleLabel p-1 text-left">
                      {{ option }}
                    </div>
                  </div>
                  <div class="col-6">
                    <div
                      v-for="item in details.badges[index].data"
                      :key="item.id || item"
                      class="row DesktopLinkXSmall p-1 text-left whiteSpaceNowrap tooltipAvlBadge"
                      :role="details.badges[index]?.link ? 'button' : ''"
                      :tabindex="details.badges[index]?.link ? 0 : ''"
                      :class="{'pointer' :details.badges[index]?.link }"
                      @click="goToPage(item, details.badges[index]?.link)"
                      @keypress.prevent="goToPage(item, details.badges[index]?.link)"
                    >
                      <avl-badge
                        :id="`avl-badge${item.name || item.title || item}`"
                        :label="snakeCaseToTitleCase(item.name || item.title || item)"
                        size="medium"
                        type="default"
                        class="MobileTextXSmall truncateAvlBadge"
                        :style="`
                          --default-text-color: ${details.badges[index]?.color || '#757789'}
                        `"
                        @mousemove="showTooltip(`avl-badge${item.name || item.title || item}`)"
                      />
                      <span
                        v-if="tooltipVisibility"
                        :id="`tooltip${item.name || item.title || item}`"
                        class="tooltipText MobileTextXSmall"
                      >
                        {{ snakeCaseToTitleCase(item.name || item.title || item) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </avl-modal>
  </div>
</template>

<script>
import { snakeCaseToTitleCase } from '../../utilities/genericMethods';

export default {
  props: {
    infoModalOpen: {
      type: Boolean,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    details: {
      type: Object,
      required: false,
      default() {
        return { options: [] };
      }
    }
  },
  emits: ['modalClosedEvent'],
  data() {
    return {
      snakeCaseToTitleCase: (string) => snakeCaseToTitleCase(string),
      tooltipVisibility: false
    };
  },
  methods: {
    closeInfoModal() {
      this.$emit('modalClosedEvent');
    },
    goToPage(item, link) {
      if (link) location.assign(`/${link}/${item.id}`);
    },
    showTooltip(id) {
      this.tooltipVisibility =
        document.getElementById(id).scrollWidth !== document.getElementById(id).offsetWidth;
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>
<style scoped>
avl-modal {
  --modal-box-shadow-header:  0px 0px 0px 0px;
  --modal-padding-body: 0 3% 0 3%;
  --modal-padding-header: 5px 3% 0 3%;
}

#infoModal avl-badge {
  border-radius: 8px;
  font-weight: 500;
  padding: 3px 8px;
  font-size: 13px;
  height: auto;
  --default-color-solid: #EFF0F6;
}

.border {
  border: 1px solid #BDBFD2;
}

.custom-header {
  margin-top: -45px;
  width: 97%;
}

.truncateAvlBadge {
  display: inline-block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  height: auto;
  background-color: rgb(36, 66, 43, 0.85);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 3px 3px;
  margin: 0px 0px 0px -130px;
  position: absolute;
  z-index: 1;
  white-space: normal;
}

.tooltipAvlBadge:hover .tooltipText {
  visibility: visible;
}
</style>
