<template>
  <section class="arkCard">
    <h4 v-if="info.cancelled_at" class="grayscaleOffBlack DesktopLinkSmall mb-4">
      No active plan
    </h4>
    <h4 v-else class="grayscaleOffBlack DesktopLinkSmall mb-4">
      {{ info.type.name }} Plan
    </h4>
    <p v-if="info.cancelled_at" class="grayscaleOffBlack MobileTextSmall mb-4">
      You’re currently subscribed for the {{ info.type.name }} Plan.
    </p>
    <p v-else class="grayscaleOffBlack MobileTextSmall mb-4">
      You are currently subscribed for the {{ info.type.name }} Plan
      until {{ formatDate(info.expired_at) }}
    </p>
    <button
      class="textButton subscriptionTextButton mb-2"
      @click="addSeat"
    >
      Add additional seats
    </button>
    <p class="MobileTextSmall grayscaleLabel padding-6">
      Request to add additional annual seats for your team.
    </p>

    <div v-if="info.cancelled_at">
      <button
        class="textButton subscriptionTextButton mb-2"
        @click="openUpdateSubscriptionModal"
      >
        Reactivate plan
      </button>
      <p class="MobileTextSmall grayscaleLabel padding-6">
        Reactivate your annual {{ info.type.name }} Plan to extend your access to plan features.
      </p>
    </div>
    <div v-else>
      <button
        class="textButton subscriptionTextButton mb-2"
        @click="openUpdateSubscriptionModal"
      >
        Update subscription preferences
      </button>
      <p class="MobileTextSmall grayscaleLabel padding-6">
        Update your annual subscription for Platform as a
        Service, your access to subscription features
        will remain active until
        {{ formatDate(info.expired_at) }}.
      </p>
    </div>
  </section>
  <add-seats-modal
    :subscription="info"
    :modal-open="seatsModalOpen"
    @modal-closed="onSeatsModalClosed"
  />
  <update-subscription-modal
    :subscription="info"
    :modal-open="updateSubscriptionModalOpen"
    @modal-closed="onSubscriptionModalClosed"
  />
</template>

<script>
import AddSeatsModal from './AddSeatsModal.vue';
import { DateTime } from 'luxon';
import UpdateSubscriptionModal from './UpdateSubscriptionModal.vue';

export default {
  components: {
    AddSeatsModal,
    UpdateSubscriptionModal
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  emits: ['add-seat'],
  data() {
    return {
      seatsModalOpen: false,
      updateSubscriptionModalOpen: false
    };
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('LLL d, yyyy');
    },
    addSeat() {
      this.seatsModalOpen = true;
    },
    openUpdateSubscriptionModal() {
      this.updateSubscriptionModalOpen = true;
    },
    onSeatsModalClosed(subscription) {
      if (subscription) this.$emit('add-seat', subscription);
      this.seatsModalOpen = false;
    },
    onSubscriptionModalClosed() {
      this.updateSubscriptionModalOpen = false;
    }
  }
};
</script>

<style scoped>
.subscriptionTextButton {
  color: #478964 !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  vertical-align: top !important;
}

.padding-6 {
  padding: 1px 6px;
}
</style>
