import MapPreview from './components/MapPreview.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#item-map')) {
    const app = createApp(MapPreview);
    app.use(store);
    app.mount('#item-map');
  }
});
