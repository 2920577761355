export const metricEntryMixin = {
  data() {
    return {
      numericEntries: [
        'Number',
        'Decimal',
        'Reporting Currency (OD5990)',
        'Currency',
        'Unit of Measure (PD1602)',
        'Unit of Measure (PD1602) selection per Hectare',
        'Unit of reference within metric',
        'Unit Referenced within Metric Definition',
        'Number of Metric Tons of CO2 Equivalent'
      ],
      booleanEntries: ['Yes/No'],
      dateEntries: ['Date'],
      ratingEntries: ['Rating', 'Text', 'Text (multiple entries possible)'],
      selectionEntries: ['Selection', 'Multiple Choice', 'Selection of 3 letter code']
    };
  },
  methods: {
    getCorrectField(metric, entry, measurement) {
      if (this.checkEntryType(metric, 'boolean')) {
        switch (entry[`${measurement}_yesno`]) {
          case true:
            return 'Yes';
          case false:
            return 'No';
          default:
            return null;
        }
      } else if (this.checkEntryType(metric, 'numeric')) {
        return entry[`${measurement}_numeric`];
      } else if (this.checkEntryType(metric, 'rating')) {
        return entry[`${measurement}_rating`];
      } else if (this.checkEntryType(metric, 'date')) {
        return entry[`${measurement}_date`];
      } else if (this.checkEntryType(metric, 'selection')) {
        return entry[`${measurement}_selection`];
      }
    },
    checkEntryType(metric, ...typeList) {
      let finalCondition = false;
      if (typeList.includes('numeric')) {
        finalCondition ||= this.numericEntries.includes(metric.format);
      }
      if (typeList.includes('boolean')) {
        finalCondition ||= this.booleanEntries.includes(metric.format);
      }
      if (typeList.includes('rating')) {
        finalCondition ||= this.ratingEntries.includes(metric.format);
      }
      if (typeList.includes('date')) {
        finalCondition ||= this.dateEntries.includes(metric.format);
      }
      if (typeList.includes('selection')) {
        finalCondition ||= this.selectionEntries.includes(metric.format);
      }
      return finalCondition;
    },
    measurementType(measurement) {
      if ('entry_numeric' in measurement) return 'entry';
      else if ('target_numeric' in measurement) return 'target';
      else return null;
    },
    unitName(metric) {
      if (
        metric.format === 'Reporting Currency (OD5990)' ||
        metric.format === 'Currency'
      ) {
        return 'Euros';
      } else if (
        metric.format === 'Number' ||
        metric.format === 'Number of Metric Tons of CO2 Equivalent'
      ) {
        return 'tons';
      } else if (metric.measurement_unit.name !== 'No Unit') {
        return metric.measurement_unit.name;
      }
      return '';
    }
  }
};
