export const validateForm = (form, errors, validationSchema, showErrors = true) => {
  return new Promise((resolve) => {
    validationSchema.validate(form, { abortEarly: false })
      .then(() => {
        for (const key in errors) {
          errors[key] = '';
        }
        resolve(true);
      })
      .catch((error) => {
        if (showErrors) {
          for (const key in errors) {
            let message = error.inner.find((e) => {
              return e.path === key || e.params.label === key;
            })?.message;

            message = message && message.charAt(0).toUpperCase() + message.slice(1);
            message = message && message.replace(/_/g, ' ');
            errors[key] = message;
          }
        }
        resolve(false);
      });
  });
};
