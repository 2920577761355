<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10"
      >
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="!loading && contributions.length === 0 ?
            '/' :
            `/${modelPluralize}/${modelId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
        <h2 class="ml-2 mb-3 DesktopTextMedium grayscaleBody">
          My Transactions
        </h2>
      </nav>
    </div>
  </section>
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-10">
      <div class="arkCard mb-0">
        <main class="row justify-content-center">
          <div class="table-responsive col-12">
            <table class="table">
              <thead>
                <tr class="grayscaleBody">
                  <th scope="col">
                    <span class="DesktopLinkXSmall">Transaction date</span>
                  </th>
                  <th scope="col">
                    <span class="DesktopLinkXSmall">Investment</span>
                  </th>
                  <th scope="col">
                    <span class="DesktopLinkXSmall">Deployed amount</span>
                  </th>
                  <th scope="col">
                    <span class="DesktopLinkXSmall">Committed amount</span>
                  </th>
                  <th scope="col"><span class="DesktopLinkXSmall"></span></th>
                </tr>
              </thead>
              <tbody class="grayscaleOffBlack DesktopTextSmall">
                <tr v-for="contribution in contributions" :key="contribution.id">
                  <td>{{ contribution.date }}</td>
                  <td>
                    <a
                      :href="`/${contribution.recipient_link}/impact`"
                      target="_self"
                    >
                      {{ contribution.name }}
                    </a>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <input
                        v-model="contribution.amount"
                        type="number"
                        class="form-control ark DesktopTextSmall"
                        @blur="updateContribution(contribution)"
                      >
                      <span class="pl-1">
                        &euro;
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <input
                        v-model="contribution.committed_amount"
                        type="number"
                        class="form-control ark DesktopTextSmall"
                        @blur="updateContribution(contribution)"
                      >
                      <span class="pl-1">
                        &euro;
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-end">
                      <span
                        class="btn btn-link px-2"
                        @click.prevent="deleteContribution(contribution.id)"
                      >
                        <i class="fas fa-trash fa-lg" />
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { fallbackApi } from '../../../api/config';

export default {
  data() {
    return {
      model: '',
      modelPluralize: '',
      modelId: null,
      loading: true,
      contributions: [],
      errorMessageFromBackend: '',
      viewId: null
    };
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.getPortfolioViews();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#js-contributions');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
    },
    fetchData() {
      const url = this.viewId ? `?view_id=${this.viewId}` : '';
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/contributions${url}`,
        dataSetter: ({ contributions }) => {
          this.contributions = contributions;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    getPortfolioViews() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/portfolio_views`,
        dataSetter: (data) => {
          const searchParams = new URLSearchParams(window.location.search);
          this.viewId = data.portfolio_views.some(
            (element) => element.id == searchParams.get('view_id')
          ) ? searchParams.get('view_id') : null;
          this.fetchData();
        }
      });
    },
    updateContribution(contribution) {
      // Change empty amounts to 0.0
      if (!contribution.amount) {
        contribution.amount = 0;
      }
      if (!contribution.committed_amount) {
        contribution.committed_amount = 0;
      }

      this.loading = true;
      fallbackApi({
        url: `contributions/${contribution.id}`,
        payload: {
          json: {
            amount: contribution.amount,
            committed_amount: contribution.committed_amount
          }
        },
        httpVerb: 'patch'
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.fetchData();
        this.loading = false;
      });
    },
    deleteContribution( contribution_id ) {
      this.loading = true;
      fallbackApi({
        url: `contributions/${contribution_id}`,
        payload: {
          json: { contribution_id: contribution_id }
        },
        httpVerb: 'delete'
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.fetchData();
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
/* TODO: extract */
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
      margin-top: 10px
  }
}
</style>
