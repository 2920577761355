const cartesianDistance = (point1, point2) => {
  const latitudeDistance = point1.lat - point2.lat;
  const longitudeDistance = point1.lng - point2.lng;
  return Math.sqrt(latitudeDistance * latitudeDistance + longitudeDistance * longitudeDistance);
};

const snakeToCamelCase = (snakeCaseString) => {
  return snakeCaseString
    .toLowerCase()
    .replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
};

const camelToSnakeCase = (camelCaseString) => {
  return camelCaseString
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

const snakeCaseToTitleCase = (camelCaseString) => {
  return camelCaseString.split('_').map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');
};

const pointInsideArea = (area, longitude, latitude, spatialFilterCheckboxStatus) => {
  // If spatial filtering is not enabled then also show points outside area
  if (!spatialFilterCheckboxStatus) return true;
  if (!area) return false;

  // Map is wrapping around, longitude should be inside (xmin, 180] U [-180, xmax)
  if (area.xmin > area.xmax) {
    return (
      (longitude > area.xmin && longitude <= 180 ||
       longitude >= -180 && longitude < area.xmax) &&
      latitude > area.ymin && latitude < area.ymax
    );
  }

  // Map is not wrapping around, longitude should be inside (xmin, xmax)
  return (
    longitude > area.xmin && longitude < area.xmax &&
    latitude > area.ymin && latitude < area.ymax
  );
};

export {
  cartesianDistance,
  snakeToCamelCase,
  camelToSnakeCase,
  snakeCaseToTitleCase,
  pointInsideArea
};
