<template>
  <section class="backNav pb-3 mb-2">
    <div class="row justify-content-center mx-0">
      <nav class="col-12 col-lg-10">
        <button
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          @click="back"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </button>
      </nav>
    </div>
    <header class="row justify-content-center mx-0">
      <div class="col-12 col-lg-10 d-flex align-items-center">
        <img
          :src="images.collaborate"
          class="mr-2 noDataImage"
          alt="Globe"
          height="50"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">
          Let’s get started with a few details about your company.
        </h1>
      </div>
    </header>
  </section>
  <main class="row justify-content-center mx-0">
    <section class="col-12 col-lg-10">
      <span>* indicates required</span>
      <form class="text-center">
        <section class="arkCard mt-3 text-left">
          <fieldset>
            <legend class="sr-only">Organization info:</legend>
            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="nameInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Name *
                </label>
                <input
                  id="nameInput"
                  v-model="form.name"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  required
                  aria-errormessage="nameError"
                  :aria-invalid="!!errors.name"
                  :class="{ notValid: errors.name }"
                  @blur="validate"
                >
                <error-message
                  id="nameError"
                  :error-condition="!!errors.name"
                  :error-message="errors.name"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="legalTypeInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Legal entity type *
                </label>
                <Multiselect
                  id="legalTypeInput"
                  v-model="form.legalTypeId"
                  placeholder="Select one"
                  :options="legalTypeOptions"
                  :close-on-select="true"
                  :searchable="true"
                  required
                  label="name"
                  value-prop="id"
                  class="multiselect-ark"
                  aria-errormessage="legalTypeError"
                  :aria-invalid="!!errors.legalTypeId"
                  :class="{ 'multiselect-ark-error': errors.legalTypeId }"
                  @blur="validate"
                />
                <error-message
                  id="nameError"
                  :error-condition="!!errors.legalTypeId"
                  :error-message="errors.legalTypeId"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="locationInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Location *
                </label>
                <div
                  class="input-group inputBorder pointer"
                  :class="{ notValid: errors.latitude || errors.longitude }"
                  @click="setLocation"
                  @blur="validate"
                >
                  <input
                    id="locationInput"
                    class="form-control ark DesktopTextSmall border-0"
                    :value="form.locationName || coordinates"
                    disabled
                    aria-errormessage="locationError"
                    required
                    :aria-invalid="!!errors.latitude || !!errors.longitude"
                  >
                  <button
                    type="button"
                    class="btn btn-link d-flex align-items-center py-0 px-3"
                    aria-label="Go to set location view"
                    @click="setLocation"
                  >
                    <i class="fal fa-map-marker-alt grayscaleLabel inputIcon" aria-hidden="true" />
                  </button>
                </div>
                <error-message
                  id="locationError"
                  :error-condition="!!errors.latitude || !!errors.longitude"
                  :error-message="errors.latitude || errors.longitude"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="emailInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Contact email *
                </label>
                <input
                  id="emailInput"
                  v-model="form.email"
                  type="email"
                  class="form-control ark DesktopTextSmall mb-2"
                  aria-describedby="emailHint"
                  aria-errormessage="emailError"
                  required
                  :aria-invalid="!!errors.email"
                  :class="{ notValid: errors.email }"
                  @blur="validate"
                >
                <span
                  id="emailHint"
                  class="DesktopTextSmall grayscaleLabel d-block"
                >
                  This is your company’s service email address.
                </span>
                <error-message
                  id="emailError"
                  :error-condition="!!errors.email"
                  :error-message="errors.email"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <label
                  for="websiteInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Website
                </label>
                <input
                  id="websiteInput"
                  v-model="form.website"
                  type="url"
                  class="form-control ark DesktopTextSmall mb-2"
                  aria-describedby="websiteHint"
                  aria-errormessage="websiteError"
                  :aria-invalid="!!errors.website"
                  :class="{ notValid: errors.website }"
                  @blur="validate"
                >
                <span
                  id="websiteHint"
                  class="DesktopTextSmall grayscaleLabel d-block"
                >
                  This is a link to your external website.
                </span>
                <error-message
                  id="websiteError"
                  :error-condition="!!errors.website"
                  :error-message="errors.website"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-md-6">
                <file-pond
                  v-show="!form.logoUrl"
                  ref="pond"
                  label-idle="
                    <i class='far fa-cloud-upload' aria-hidden></i>
                    <strong>Choose logo image</strong>
                    <br>Upload to see preview
                  "
                  accepted-file-types="image/jpeg, image/png"
                  aria-describedby="logoHint"
                  @addfile="addLogo"
                  @removefile="removeLogo"
                />
                <!-- TODO: find a way to announce image changes to screen readers -->
                <div v-if="form.logoUrl" class="logoPreviewContainer mb-2">
                  <img
                    :src="form.logoUrl"
                    alt="Uploaded logo"
                    class="logoPreview"
                  >
                  <button
                    type="button"
                    class="btn btn-link p-0 m-0 editLogoBtn"
                    aria-label="Toggle edit logo menu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      class="fa fa-pencil edit-icon-pencil grayscaleBody"
                      aria-hidden="true"
                    />
                  </button>
                  <div class="d-flex justify-content-end">
                    <div class="dropdown-menu dropdown-primary" role="menu">
                      <button
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="updateLogo"
                      >
                        <i class="far fa-camera mr-2" aria-hidden="true" /> Upload logo image
                      </button>
                      <button
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="openEditModal"
                      >
                        <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust logo image
                      </button>
                      <button
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="deleteLogo"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <span
                  id="logoHint"
                  class="DesktopTextSmall grayscaleLabel d-block"
                >
                  300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                </span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label
                  for="descriptionInput"
                  class="DesktopTextSmall grayscaleOffBlack"
                >
                  Description *
                </label>
                <textarea
                  id="descriptionInput"
                  v-model="form.description"
                  class="form-control ark DesktopTextSmall mb-2"
                  aria-describedby="descriptionHint"
                  aria-errormessage="descriptionError"
                  rows="5"
                  required
                  :aria-invalid="!!errors.description"
                  :class="{ notValid: errors.description }"
                  @blur="validate"
                />
                <span
                  id="descriptionHint"
                  class="DesktopTextSmall grayscaleLabel d-block"
                >
                  Use your description to briefly describe your organisation’s mission statement.
                  This can be changed later.
                </span>
                <error-message
                  id="descriptionError"
                  :error-condition="!!errors.description"
                  :error-message="errors.description"
                />
              </div>
            </div>

            <div class="d-flex align-items-center">
              <input
                id="verifyInput"
                v-model="form.verified"
                type="checkbox"
                aria-errormessage="verifiedError"
                :aria-invalid="!!errors.verified"
                @blur="validate"
              >
              <label class="DesktopTextSmall grayscaleLabel ml-3 mb-0" for="verifyInput">
                I verify that I am an authorized representative of this organisation and have the
                right to act on its behalf in the creation and management of this page.
              </label>
            </div>
            <error-message
              id="verifiedError"
              :error-condition="!!errors.verified"
              :error-message="errors.verified"
            />
          </fieldset>
        </section>

        <error-message
          class="d-block"
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />

        <div
          v-if="loading"
          class="spinner-border primary2_ark"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <button
          v-else
          type="submit"
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            primaryButton
          "
          :disabled="saveDisabled"
          @click="submit"
        >
          Save
        </button>
      </form>
    </section>
  </main>
</template>

<script>
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';

// Import FilePond and Pintura
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { openDefaultEditor } from '@pqina/pintura';
import { validateForm } from '../../../../utilities/validation.js';
import vueFilePond from 'vue-filepond';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType
);

const ValidationSchema = yup.object({
  name: yup.string().required(),
  locationName: yup.string(),
  latitude: yup.number()
    .typeError('location is a required field')
    .required('location is a required field'),
  longitude: yup.number()
    .typeError('location is a required field')
    .required('location is a required field'),
  email: yup.string().required().email(),
  website: yup.string().url(),
  description: yup.string().required(),
  legalTypeId: yup.number()
    .typeError('Legal entity type is a required field')
    .required('Legal entity type is a required field'),
  verified: yup.boolean().oneOf([true], 'must be checked')
});

export default {
  components: { ErrorMessage, FilePond, Multiselect },
  props: {
    images: {
      type: Object,
      required: true
    },
    legalTypeOptions: {
      type: Array,
      required: true
    },
    organizationType: {
      type: String,
      required: true
    },
    organizationLocation: {
      type: Object,
      required: true
    }
  },
  emits: ['back', 'set-location'],
  data() {
    return {
      form: {
        name: '',
        locationName: '',
        latitude: null,
        longitude: null,
        country: '',
        city: '',
        zip: '',
        email: '',
        website: '',
        logo: null,
        logoUrl: '',
        description: '',
        legalTypeId: null,
        verified: false
      },
      errors: {
        name: '',
        locationName: '',
        latitude: '',
        longitude: '',
        email: '',
        website: '',
        description: '',
        legalTypeId: '',
        verified: ''
      },
      deletingLogo: false,
      editLogoModalOpen: false,
      submitted: false,
      loading: false,
      errorMessageFromBackend: ''
    };
  },
  computed: {
    coordinates() {
      return this.form.latitude && this.form.longitude ?
        `${this.form.latitude}, ${this.form.longitude}` :
        '';
    },
    saveDisabled() {
      return (
        !this.form.name ||
        !this.form.latitude || !this.form.longitude ||
        !this.form.email ||
        !this.form.description ||
        !this.form.legalTypeId ||
        !this.form.verified
      );
    }
  },
  watch: {
    organizationLocation: {
      handler(newValue) {
        this.form = {...this.form, ...newValue};
      }
    }
  },
  mounted() {
    this.form = {...this.form, ...this.organizationLocation};
  },
  methods: {
    back() {
      this.$emit('back');
    },
    setLocation(event) {
      event.stopPropagation();
      this.$emit('set-location');
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.submitted);
    },
    addLogo(error, file) {
      this.form.logo = error ? null : file?.file;
      this.form.logoUrl = error ? null : URL.createObjectURL(this.form.logo);
    },
    removeLogo() {
      if (this.form.logoUrl) {
        URL.revokeObjectURL(this.form.logoUrl);
      }

      if (this.deletingLogo) {
        this.form.logo = null;
        this.form.logoUrl = '';
        this.deletingLogo = false;
      }
    },
    updateLogo() {
      this.$refs.pond.browse();
    },
    deleteLogo() {
      this.deletingLogo = true;
      this.$refs.pond.removeFile();
    },
    openEditModal() {
      openDefaultEditor({
        src: this.form.logo,
        imageCropAspectRatio: 1,
        utils: ['crop'],
        handleEvent: (type, detail) => {
          if (type === 'process') {
            this.$refs.pond.addFile(detail.dest);
          }
        }
      });
    },
    async submit() {
      this.submitted = true;
      this.loading = true;
      const valid = await this.validate();
      if (valid) {
        const formData = new FormData();
        formData.append('type', this.organizationType);
        formData.append('name', this.form.name);
        formData.append('location', this.form.locationName);
        formData.append('latitude', this.form.latitude);
        formData.append('longitude', this.form.longitude);
        formData.append('country', this.form.country);
        formData.append('city', this.form.city);
        formData.append('zip', this.form.zip);
        formData.append('contact_email', this.form.email);
        formData.append('about', this.form.description);
        formData.append('legal_type_id', this.form.legalTypeId);
        if (this.form.website) {
          formData.append('website_url', this.form.website);
        }
        if (this.form.logo) {
          formData.append('logo', this.form.logo);
        }

        fallbackApi({
          url: 'organizations/',
          payload: {
            body: formData,
            // Remove json content type so browser can set the appropriate formdata content type
            headers: {
              'Content-Type': undefined
            }
          },
          httpVerb: 'post'
        })
        .then((data) => {
          this.errorMessageFromBackend = '';
          this.$store.dispatch('updateUser', this.$store.auth?.user?.id);
          location.assign(`/organizations/${data.data.organization.id}`);
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message || 'Error creating organisation.';
        })
        .finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style src="@pqina/pintura/pintura.css"></style>

<style>
.PinturaModal {
  --editor-max-width: 50em;
  --editor-max-height: 40em;
  --editor-modal-border-radius: 4px;
  --editor-modal-overlay-opacity: 0.5;
}
</style>

<style scoped>
body {
  font-family: Poppins, sans-serif;
}

.backNav {
  margin-top: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .backNav {
      margin-top: 10px;
  }
}

.backTag {
  color: #24422B !important;
}

.inputBorder {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.inputIcon {
  font-size: 1.7em;
}

#locationInput {
  pointer-events: none;
}

.logoPreviewContainer {
  position: relative;
  width: fit-content;
}

.logoPreview {
  width: 100px;
  aspect-ratio: 1;
  object-fit: contain;
}

.editLogoBtn {
  position: absolute;
  bottom: -3px;
  right: -18px;
}

.editLogoBtn i {
  background-color: white;
}
</style>
