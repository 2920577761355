// File attachment fxnality
ProjectAttachmentsHelper = class {
  constructor() {
    this.supportingAssetsContainer = $('#project__supporting_assets_list');
    this.supportingAssetsContainerInfo =$('#project__supporting_assets_list_info');
    this.supportingAssetsCheckboxes = $('.project__supporting_assets_checkbox');
    this.deleteSupportingAssetsBtn = $('#project__supporting_assets_delete');
    this.projectPhotosContainer = $('#project__photos_list');
    this.projectPhotosContainerInfo =$('#project__photos_list_info');
    this.projectPhotosCheckboxes = $('.project__photos_checkbox');
    this.deleteProjectPhotosBtn = $('#project__photos_delete');
    this.projectTypeDiv = $('#js-project-type');
    this.projectVideoContainer = $('#project__video_container');
    this.projectVideoContainerInfo =$('#project__video_container_info');
    this.projectVideoCheckboxes = $('.project__video_checkbox');
    this.deleteProjectVideoBtn = $('#project__video_delete');
    this.orgVideoContainer = $('#org__video_container');
    this.orgVideoContainerInfo =$('#org__video_container_info');
    this.orgVideoCheckboxes = $('.org__video_checkbox');
    this.deleteOrgVideoBtn = $('#org__video_delete');
    this.orgReportsContainer = $('#org__reports_list');
    this.orgReportsContainerInfo =$('#org__reports_list_info');
    this.orgReportsCheckboxes = $('.org__reports_checkbox');
    this.deleteOrgReportsBtn = $('#org__reports_delete');
    this.orgLogoContainer = $('#org__logo_img_container');
    this.orgLogoCheckboxes = $('.org__logo_checkbox');
    this.deleteOrgLogoBtn = $('#org__logo_delete');
    this.userAvatarContainer = $('#user__avatar_img_container');
    this.userAvatarCheckboxes = $('.user__avatar_checkbox');
    this.deleteUserAvatarBtn = $('#user__avatar_delete');
    this.techForm = $('.js-tech-org-form');
    this.logoInputField = $('#logo_input_field')
  }

  deleteSupportingAssets() {
    var checked_supporting_assets = $('.project__supporting_assets_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_supporting_asset_inputs = [];
    checked_supporting_assets.each((s) => checked_supporting_asset_inputs.push(
      `<input type="hidden" name="project[rm_supporting_assets][]" value="${checked_supporting_assets[s].value}" />`
    ));
    var checked_supporting_asset_input = checked_supporting_asset_inputs.join('');
    this.supportingAssetsContainer.after(checked_supporting_asset_input);
    // Remove checked assets
    checked_supporting_assets.closest('.project__supporting_asset').remove();
    if ($('.project__supporting_assets_checkbox:checked').length === 0){
      this.supportingAssetsContainerInfo.addClass('hide');
    }
  }

  deleteProjectPhotos() {
    var checked_project_photo_assets = $('.project__photos_checkbox:checked');
    const projectType = this.projectTypeDiv?.data('type');
    // Add checked assets to hidden inputs
    var checked_project_photo_inputs = [];
    checked_project_photo_assets.each((s) => checked_project_photo_inputs.push(
      `<input type="hidden" name="${projectType}[rm_photos][]" value="${checked_project_photo_assets[s].value}" />`
    ));
    var checked_project_photo_input = checked_project_photo_inputs.join('');
    this.projectPhotosContainer.after(checked_project_photo_input);
    // Remove checked assets
    checked_project_photo_assets.closest('.project__photo_asset').remove();
    if ($('.project__photos_checkbox:checked').length === 0){
      this.projectPhotosContainerInfo.addClass('hide');
    }
  }

  deleteProjectVideo() {
    var checked_project_video_assets = $('.project__video_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_project_video_inputs = [];
    checked_project_video_assets.each((s) => checked_project_video_inputs.push(
      `<input type="hidden" name="project[rm_video][]" value="${checked_project_video_assets[s].value}" />`
    ));
    var checked_project_video_input = checked_project_video_inputs.join('');
    this.projectVideoContainer.after(checked_project_video_input);
    // Remove checked assets
    checked_project_video_assets.closest('.project__video_asset').remove();
    this.projectVideoContainerInfo.addClass('hide');
  }

  deleteOrgVideo(entityType = 'organization') {
    var checked_org_video_assets = $('.org__video_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_org_video_inputs = [];
    checked_org_video_assets.each((s) => checked_org_video_inputs.push(
      `<input type="hidden" name="${entityType}[rm_video][]" value="${checked_org_video_assets[s].value}" />`
    ));
    var checked_org_video_input = checked_org_video_inputs.join('');
    this.orgVideoContainer.after(checked_org_video_input);
    // Remove checked assets
    checked_org_video_assets.closest('.org__video_asset').remove();
    this.orgVideoContainerInfo.addClass('hide');
  }

  deleteOrgReports(entityType = 'organization') {
    var checked_org_reports_assets = $('.org__reports_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_org_reports_inputs = [];
    checked_org_reports_assets.each((s) => checked_org_reports_inputs.push(
      `<input type="hidden" name="${entityType}[rm_reports][]" value="${checked_org_reports_assets[s].value}" />`
    ));
    var checked_org_reports_input = checked_org_reports_inputs.join('');
    this.orgReportsContainer.after(checked_org_reports_input);
    // Remove checked assets
    checked_org_reports_assets.closest('.org__reports_asset').remove();
    if ($('.org__reports_checkbox:checked').length === 0){
      this.orgReportsContainerInfo.addClass('hide');
    }
  }

  deleteOrgLogo(entityType = 'organization') {
    var checked_org_logo = $('.org__logo_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_org_logo_inputs = [];
    checked_org_logo.each((s) => checked_org_logo_inputs.push(
      `<input type="hidden" name="${entityType}[rm_logo][]" value="${checked_org_logo[s].value}" />`
    ));
    var checked_org_logo_input = checked_org_logo_inputs.join('');
    this.orgLogoContainer.after(checked_org_logo_input);
    // Remove checked assets
    $('#org__logo_asset').remove();
  }

  deleteUserAvatar() {
    var checked_user_avatar = $('.user__avatar_checkbox:checked');
    // Add checked assets to hidden inputs
    var checked_user_avatar_inputs = [];
    checked_user_avatar.each((s) => checked_user_avatar_inputs.push(
      `<input type="hidden" name="user[rm_avatar][]" value="${checked_user_avatar[s].value}" />`
    ));
    var checked_user_avatar_input = checked_user_avatar_inputs.join('');
    this.userAvatarContainer.after(checked_user_avatar_input);
    // Remove checked assets
    $('#user__avatar_asset').remove();
  }

  setupSubmit() {
    /////////////////////////////
    // Supporting assets setup //
    /////////////////////////////
    if (this.supportingAssetsCheckboxes !== null){
      this.supportingAssetsCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.project__supporting_assets_checkbox:checked').length > 0){
          this.deleteSupportingAssetsBtn.removeClass('hide');
        } else {
          this.deleteSupportingAssetsBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteSupportingAssetsBtn) {
      this.deleteSupportingAssetsBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteSupportingAssets();
        this.deleteSupportingAssetsBtn.addClass('hide');
      });
    }
    //////////////////////////
    // Project photos setup //
    //////////////////////////
    if (this.projectPhotosCheckboxes !== null){
      this.projectPhotosCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.project__photos_checkbox:checked').length > 0){
          this.deleteProjectPhotosBtn.removeClass('hide');
        } else {
          this.deleteProjectPhotosBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteProjectPhotosBtn) {
      this.deleteProjectPhotosBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteProjectPhotos();
        this.deleteProjectPhotosBtn.addClass('hide');
      });
    }
    /////////////////////////
    // Project about video //
    /////////////////////////
    if (this.projectVideoCheckboxes !== null){
      this.projectVideoCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.project__video_checkbox:checked').length > 0){
          this.deleteProjectVideoBtn.removeClass('hide');
        } else {
          this.deleteProjectVideoBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteProjectVideoBtn) {
      this.deleteProjectVideoBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteProjectVideo();
        this.deleteProjectVideoBtn.addClass('hide');
      });
    }
    //////////////////
    // Entity check //
    //////////////////

    // Check if Organization or Tech Organization
    var entityType = this.techForm.length > 0 ? 'tech_org' : 'organization';

    //////////////////////////////
    // Organization about video //
    //////////////////////////////
    if (this.orgVideoCheckboxes !== null){
      this.orgVideoCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.org__video_checkbox:checked').length > 0){
          this.deleteOrgVideoBtn.removeClass('hide');
        } else {
          this.deleteOrgVideoBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteOrgVideoBtn) {
      this.deleteOrgVideoBtn.on('click', (e) => {
        e.preventDefault();

        this.deleteOrgVideo(entityType);
        this.deleteOrgVideoBtn.addClass('hide');
      });
    }
    ////////////////////////////////
    // Organization reports setup //
    ////////////////////////////////
    if (this.orgReportsCheckboxes !== null){
      this.orgReportsCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.org__reports_checkbox:checked').length > 0){
          this.deleteOrgReportsBtn.removeClass('hide');
        } else {
          this.deleteOrgReportsBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteOrgReportsBtn) {
      this.deleteOrgReportsBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteOrgReports(entityType);
        this.deleteOrgReportsBtn.addClass('hide');
      });
    }
    /////////////////////////////
    // Organization logo setup //
    /////////////////////////////
    if (this.orgLogoCheckboxes !== null){
      this.orgLogoCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.org__logo_checkbox:checked').length > 0){
          this.deleteOrgLogoBtn.removeClass('hide');
        } else {
          this.deleteOrgLogoBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteOrgLogoBtn) {
      this.deleteOrgLogoBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteOrgLogo(entityType);
        this.deleteOrgLogoBtn.addClass('hide');
        if (this.logoInputField) {
          this.logoInputField.prop('required',true);
        }
      });
    }
    ///////////////////////
    // User avatar setup //
    ///////////////////////
    if (this.userAvatarCheckboxes !== null){
      this.userAvatarCheckboxes.on('change', (e) => {
        // If any selected, then show Delete option
        if ($('.user__avatar_checkbox:checked').length > 0){
          this.deleteUserAvatarBtn.removeClass('hide');
        } else {
          this.deleteUserAvatarBtn.addClass('hide');
        }
      })
    }
    // If delete button clicked
    // Hide selected items and add to hidden field to update backend
    if (this.deleteUserAvatarBtn) {
      this.deleteUserAvatarBtn.on('click', (e) => {
        e.preventDefault();
        this.deleteUserAvatar();
        this.deleteUserAvatarBtn.addClass('hide');
      });
    }
  }
}
