<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Profile details:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Details</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="aboutInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Write some details about yourself
              </label>
              <textarea
                id="aboutInput"
                v-model="form.about"
                class="form-control ark DesktopTextSmall mb-2"
                aria-describedby="aboutHint"
                aria-errormessage="aboutError"
                rows="12"
                required
                :aria-invalid="!!errors.about"
                :class="{ notValid: errors.about }"
                @blur="validate"
              />
              <error-message
                id="aboutError"
                :error-condition="!!errors.about"
                :error-message="errors.about"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  about: yup.string().required('Profile details is a required field.')
});

export default {
  components: { ErrorMessage },
  props: {
    user: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
  },
  emits: ['save-user'],
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        about: '',
      },
      errors: {
        about: '',
      },
      errorMessageFromBackend: ''
    };
  },
  computed: {
    dataChanged() {
      return this.form.about !== this.user?.user.about;
    },
  },
  watch: {
    user() {
      this.getUserInfo();
    }
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //** Show existing user data
    getUserInfo() {
      this.form = {
        about: this.user?.user.about,
      };
    },
    async validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/details`,
          payload: {
            json:
              {
                user: {
                  about: this.form.about,
                }
              }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.$emit('save-user', response.data.user);
          this.showErrors = false;
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
