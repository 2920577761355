const isAdminBase = ({ user, entity, entityId }) => {
  if (!user) return false;

  if (entity === 'project') {
    const findProject = (project) => project.id.toString() === entityId;
    const projectFound = user.projects.some(findProject) ||
      user.associated_projects.some(findProject);

    return (user.role === 'admin' || user.role === 'superadmin') && projectFound;
  }

  if (entity === 'organization') {
    const organizationFound = user?.organization?.id?.toString() === entityId;
    return (user.role === 'admin' || user.role === 'superadmin') && organizationFound;
  }
  return false;
};

export default isAdminBase;
