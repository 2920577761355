<template>
  <div>
    <div v-if="loading === true">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
        ]"
      />
    </div>
    <div v-else class="arkCard">
      <div class="row">
        <div class="col-9">
          <div class="DesktopTextMedium secondary7_ark">Team</div>
        </div>
        <div class="col-3">
          <div v-if="isAdmin" class="d-flex justify-content-end">
            <a
              class="p-0 m-0 pointer"
              :aria-label="`Update ${model} team`"
              :href="`/${modelPluralize}/${modelId}/edit_team?tab=members`"
            >
              <i
                class="fa fa-pencil edit-icon-pencil grayscaleBody"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="teamMembers && teamMembers.length"
      >
        <div
          class="d-flex col-6 col-md-3"
          v-for="member in teamMembers"
          :key="member.id + member.name"
        >
          <section class="card team-card">
            <div
              class="team-card__image-banner"
              v-bind:style="{ 'background-image': `url('${member?.banner}')` }"
              aria-hidden="true"
              >
            </div>
            <div
              class="team-card__info-container"
            >
              <a
                :href="`/users/${member.id}`"
                class="team-card__link"
                target="_self"
              >
                <div class="team-card__img-wrapper">
                  <img
                    width="104"
                    v-if="member.avatar"
                    :src="member?.avatar"
                    class="team-card__image-circle"
                    :alt="`${member.name}'s picture`"
                  >
                </div>
                <span>
                  <span class="team-card__name grayscaleOffBlack DesktopLinkSmall">
                    {{member.name}}
                  </span>
                </span>
                <span class="team-card__occupation grayscaleBody DesktopTextXSmall">
                  {{member.occupation}}
                </span>
              </a>
            </div>
            <div
              class="team-card__bottom-container"
              v-if="this.$store.state.auth.user.id != member?.id"
              >
              <footer class="mt-2">
                <button
                  class="w-100 btn btn-sm btn-round DesktopLinkXSmall borderedButton"
                  :aria-label="`Follow ${member.name}`"
                  @click.prevent="
                    followUnfollow(member?.is_following ? 'unfollow' : 'follow', member?.id)
                  "
                >
                  <i
                    v-if="member?.is_following"
                    class="far fa-check mr-2"
                    aria-hidden="true"
                  />
                  {{ member?.is_following ? 'Following': 'Follow' }}
                </button>
              </footer>
            </div>
          </section>
        </div>
      </div>
      <div v-else-if="teamMembers.length === 0">
        <no-data-card
          :first-message="
            isAdmin ?
              'You have not added any team members yet' :
              `No team members have been added yet`
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import { fallbackApi } from '../../../api/config';

export default {
  components: {
    LoadingIndicator,
    NoDataCard
  },
  props: {
    model: {
      type: String,
      required: false,
      default: ''
    },
    modelId: {
      type: String,
      required: false,
      default: ''
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      teamMembers: [],
      loading: true
    };
  },
  mounted() {
    this.fetchTeam();
  },
  methods: {
    fetchTeam() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/team`,
        dataSetter: (data) => {
          this.teamMembers = data[`${this.model}_team`] || []
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    followUnfollow(status, id) {
      fallbackApi({
        url: `users/${id}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        let teamMember = this.teamMembers.find((member) => member.id === id);
        teamMember.is_following = !teamMember.is_following;
      })
    }
  }
};
</script>

<style scoped src="../../../stylesheet/team.css"></style>
