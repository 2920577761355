class TopNewsHelper {
  static setupModal($modal, type) {
    let urlRegex = /(https?:\/\/[^\s]+)/g;
    let embeddedUrlRegex=/iframe/
    let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    var post_urls = new Array();
    let view = $('#social_top_news_view').val();
    if (type === "new" && view === "organization") {
      // Show image of organization
      $modal.find('.user-image').toggleClass('d-none');
      $modal.find('.organization-image').toggleClass('d-none');
      $modal.find('.organization-image').toggleClass('d-flex');
    }
    if (type === "new" && view === "tech_org") {
      // Show image of organization
      $modal.find('.user-image').toggleClass('d-none');
      $modal.find('.tech-org-image').toggleClass('d-none');
      $modal.find('.tech-org-image').toggleClass('d-flex');
    }
    $modal.find('#social_top_news_as_organization').change(function() {
      $modal.find('.user-image').toggleClass('d-none');
      $modal.find('.tech-org-image').toggleClass('d-none');
      $modal.find('.tech-org-image').toggleClass('d-flex');
      $modal.find('.organization-image').toggleClass('d-none');
      $modal.find('.organization-image').toggleClass('d-flex');
    });

    let jelement = $modal.find('trix-editor');
    let url_attachment_area = $modal.find('#url_attachment');
    let url_attachment_html_field = $modal.find('#url_attachment_html_field');
    let element = jelement[0];
    let $iframeClose = $('.iframe-close');
    element.addEventListener('trix-change', function(evt) {
      let document = element.editor.getDocument();
      let text = document.toString();
      let statusErrors = [403, 404, 422];
      text.replace(urlRegex, function(url) {
        // We are going to get the embedding here
        $.ajax({
          type: "POST",
          headers: { 'X-CSRF-Token': csrfToken },
          url: '/ogp',
          data: { url: url },
          success: function(d) {
            // Check if url & iframe is found
            if (!('status' in d) && !(d.status in statusErrors) && ('html' in d)) {
              // Check if url same as before
              if (!post_urls.includes(d.url)) {
                post_urls.push(d.url);
                url_attachment_area.html(d.html);
                url_attachment_html_field.val(d.html);
                $iframeClose.removeClass('d-none');
              }
            }
          }
        });
        return url;
      })
    });
    $iframeClose.on('click', function() {
      url_attachment_area.html("");
      url_attachment_html_field.val("");
      $iframeClose.addClass('d-none');
    });

    $modal.on('hidden.bs.modal', function () {
      let shared_post = $modal.find('#shared_post');
      shared_post.html('');
      shared_post.toggleClass('d-none', true);
      $modal.find('#parent_id_field').val('');
    })

    $modal.on('shown.bs.modal', function () {
      if (type === "new") {
        post_urls = new Array();
        element.editor.loadHTML("");
        url_attachment_area.html("");
        url_attachment_html_field.val("");
        $iframeClose.addClass('d-none');
      }
      if (type === "edit") {
        post_urls = element.editor.getDocument().toString().match(urlRegex) || [];
        if (url_attachment_area.html() === '') {
          $iframeClose.addClass('d-none');
        }
        else {
          $iframeClose.removeClass('d-none');
        }
      }

      $modal.attr('aria-expanded', true);
      $modal.find('#newsPostInput').trigger('focus');
    })
  }

  static showNewsPostDeleteAlert(html, showPath, redirectPath){
    new swal({
      html: html,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-default',
      confirmButtonClass: 'btn btn-secondary1-ark',
      buttonsStyling: false
    }).then(function(result) {
      if (result.isConfirmed) {
        fetch(showPath, {
          method: 'DELETE',
          headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") }
        }).then((response) => {
          if (redirectPath) {
            window.location.replace(redirectPath)
          }
          else {
            window.location.reload();
          }
        }).catch((error) => {
          Sentry && Sentry.captureException(error)
        });
      } else {
        console.log('Aborting deletion');
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
    });
  }

  static setupPostButtons() {
    $('.js-delete-news-post').off('click');
    $('.js-delete-news-post').on('click', function() {
      const showPath = $(this).attr('data-show-path');
      const redirectPath = $(this).attr('data-redirect-path');
      TopNewsHelper.showNewsPostDeleteAlert(`Are you sure you want to delete this news post? This is a permanent change.`, showPath, redirectPath);
    });

    $('.js-edit-news-post').off('click');
    $('.js-edit-news-post').on('click', function() {
      const showPath = $(this).attr('data-show-path');
      fetch(showPath)
        .then((response) => response.json())
        .then((json) => {
          const csrf = $('meta[name="csrf-token"]').attr('content');
          $('#editPostModal form').attr('action', showPath);
          $('#editPostModal input[name="authenticity_token"]').val(csrf);
          // Show image of user or organization depending on post owner
          $('#editPostModal .user-image').toggleClass('d-none', json.post.as_organization || json.post.as_tech_org);
          $('#editPostModal .organization-image').toggleClass('d-none', !json.post.as_organization);
          $('#editPostModal .organization-image').toggleClass('d-flex', json.post.as_organization);
          $('#editPostModal .tech-org-image').toggleClass('d-none', !json.post.as_tech_org);
          $('#editPostModal .tech-org-image').toggleClass('d-flex', json.post.as_tech_org);

          const editor = $('#editPostModal trix-editor').get(0);
          editor.editor.loadHTML(json.content);
          const url_attachment = $('#editPostModal #url_attachment');
          url_attachment.html(json.url_attachment);
          const url_attachment_html_field = $('#editPostModal #url_attachment_html_field');
          url_attachment_html_field.val(json.url_attachment);

          if(json && json.parent) {
            $('#editPostModal #shared_post').html(json.parent);
            $('#editPostModal #shared_post').toggleClass('d-none', false);
            $('#editPostModal #parent_id_field').val(json.post.parent_id);

            // Activate buttons on parent post partial
            FollowHelper.setupFollowButtons();
            LinkHelper.setupTooltips();
            $('[data-toggle="tooltip"]').tooltip();
          }

          $('#editPostModal').modal('show');
        });
    });

    $('.js-share-btn').off('click');
    $('.js-share-btn').on('click', function() {
      const showPath = $(this).attr('data-show-path');
      fetch(showPath)
        .then((response) => response.json())
        .then((json) => {
          $('#newsPostModal #shared_post').html(json.post);
          $('#newsPostModal #shared_post').toggleClass('d-none', false);
          $('#newsPostModal #parent_id_field').val($(this).attr('data-top-news-id'));

          // Activate buttons on parent post partial
          FollowHelper.setupFollowButtons();
          LinkHelper.setupTooltips();
          $('[data-toggle="tooltip"]').tooltip();

          $('#newsPostModal').modal('show');
        });
    });

    // Show picture in full screen when clicked
    $('.trix-content img, .attachment img').off('click');
    $('.trix-content img, .attachment img').on('click', function() {
      const previewModal = $('#previewModal');
      if(previewModal.length === 0) {
        return;
      }

      const parent = $(this).parent();
      // Trix attachment
      if(parent.parent().is('figure')) {
        const figure = parent.parent();
        const attachment = figure.parent();
        const caption = figure.find('figcaption');
        const speciesLinks = attachment.parent().find('.species').clone();
        previewModal.find('img').attr('src', '');
        previewModal.find('.js-preview-caption').text(caption.text());
        previewModal.find('.js-preview-species').html(speciesLinks);
        // Image
        if(attachment.attr('content-type').includes('image')) {
          previewModal.find('img').attr('src', attachment.attr('url') || $(this).attr('src'));
        }
        // pdf preview
        else {
          previewModal.find('img').attr('src', $(this).attr('src'));
        }
        previewModal.modal('show');
      }
      // Image in media system post, following specific format
      else {
        // TODO: Format of the posts is changed but older posts keep this format,
        // remove this part when they are changed
        // These posts are created in images_controller/announce_activity_news
        // Image, caption and species links are taken from the post content
        // Caption and Species links are in positions 7 and 10 of the post content according to the current format
        const caption = parent.contents()[7].textContent;
        const speciesLinks = parent.contents().slice(10).clone();
        previewModal.find('img').attr('src', '');
        previewModal.find('img').attr('src', $(this).attr('src'));
        previewModal.find('.js-preview-caption').text(caption);
        previewModal.find('.js-preview-species').html(speciesLinks);
        previewModal.modal('show');
      }
    });
  }
}

let newsPostModal = $('#newsPostModal');
if(newsPostModal.length > 0) {
  TopNewsHelper.setupModal(newsPostModal, "new");
}

let editPostModal = $('#editPostModal');
if(editPostModal.length > 0) {
  TopNewsHelper.setupModal(editPostModal, "edit");
}

TopNewsHelper.setupPostButtons();

export { TopNewsHelper };
