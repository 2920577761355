import DemoButtonAndForm from './components/DemoButtonAndForm.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#demo-form')){
    const app = createApp(DemoButtonAndForm);
    app.use(store);
    app.mount('#demo-form');
  }
});
