<template>
  <map-side-bar
    :open-side-bar="openSideBar"
    :visible-side-bar-button="true"
    :open-second-side-bar="false"
    :back-button-on-second-sidebar="false"
    @side-bar-status="sideBarStatus"
  >
    <template #insideOfSideBar>
      <div class="px-4">
        <header class="d-flex align-items-center mb-2">
          <button
            class="btn btn-link p-0 m-0 grayscaleOffBlack mr-3 backBtn"
            aria-label="Go back"
            @click="goBack"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />
          </button>
          <h2 class="DesktopLinkSmall grayscaleOffBlack m-0">Back</h2>
        </header>
        <div
          v-for="site in sitesData"
          :key="site.id"
          class="py-3 pl-3 d-flex justify-content-between align-items-start siteCard"
          @mouseenter="hover(site.id, site.entityId)"
          @mouseleave="unhover(site.id, site.entityId)"
        >
          <div>
            <h3 class="DesktopLinkSmall primaryDefault mt-0">{{ site.name }}</h3>
            <p class="DesktopTextXSmall grayscaleBody m-0 d-flex align-items-center">
              <img
                v-if="site.geometry_type === 'Headquarters'"
                :src="siteIconSource(site)"
                class="mr-2 my-auto"
                alt="Icon"
                height="13"
              >
              <i
                v-else
                :class="
                  `mr-2 fas
                  ${site.geometry_type === 'esriGeometryPoint' ?
                    'fa-map-marker' :
                    'fa-draw-polygon'
                  }
                  ${siteTypeClass(site.site_type_name)}`
                "
                aria-hidden="true"
              />
              <span>{{ site.site_type_name }}</span>
              <span v-if="site.area"> - {{ formatArea(site.area) }}km<sup>2</sup></span>
            </p>
          </div>
          <button
            v-if="site.id !== headquartersId || site.location"
            class="btn btn-link p-0 m-0 pr-2 DesktopLinkSmall grayscaleOffBlack"
            aria-label="Zoom to site"
            @click="travelTo(site.id, site.entityId)"
          >
            <i aria-hidden="true" class="far fa-search-location fa-lg" />
          </button>
        </div>
      </div>
    </template>
  </map-side-bar>
</template>

<script>
import MapSideBar from '../../map/components/MapSideBar.vue';
import { sitesMixin } from '../../../mixins';

export default {
  name: 'SitesSidebar',
  components: { MapSideBar },
  mixins: [sitesMixin],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      openSideBar: true
    };
  },
  computed: {
    modelType() {
      const arkType = this.model.ark_type;
      const type = this.model.type;

      if (type === 'ReportingProject'){
        return 'Reporting site';
      } else {
        return `${arkType.charAt(0).toUpperCase()}${arkType.slice(1)} Headquarters` ;
      }
    },
    sitesData() {
      if (!this.model?.sites) {
        return [];
      }

      let sitesData = [
        {
          id: this.headquartersId,
          entityId: `${this.model.ark_type}-${this.model.id}`,
          name: this.model.title || this.model.name,
          geometry_type: 'Headquarters',
          site_type_name: this.modelType,
          ark_type: this.model.ark_type,
          location: this.model.location
        },
        ...this.model.sites.filter((site) => site.layer_url)
      ];

      if (this.model.projects) {
        sitesData = this.model.projects.reduce((acc, project) => {
          const projectSites = [
            {
              id: this.headquartersId,
              entityId: `project-${project.id}`,
              name: project.title,
              geometry_type: 'Headquarters',
              site_type_name: this.projectType(project),
              ark_type: project.ark_type,
              location: project.location
            },
            ...project.sites.filter((site) => site.layer_url)
          ];

          return acc.concat(projectSites);
        }, sitesData);
      }

      return sitesData;
    }
  },
  methods: {
    projectType(project) {
      const type = project.type;
      return (type === 'ConservationProject') ? 'Project Headquarters' : 'Reporting site';
    },
    siteIconSource(site) {
      if (site.ark_type === 'organization') {
        return 'https://cdn.theark.co/img/map_icon_building_dark.png';
      } else if (site.site_type_name === 'Reporting site'){
        return 'https://cdn.theark.co/img/map_icon_file_dark.png';
      } else {
        return 'https://cdn.theark.co/img/map_icon_location_dark.png';
      }
    },
    sideBarStatus(statusOfSidebar) {
      this.openSideBar = statusOfSidebar;
    },
    goBack() {
      window.history.back();
    },
    hover(id, entityId) {
      this.$eventBus.$trigger('hover-site', ({ id, entityId }));
    },
    unhover(id, entityId) {
      this.$eventBus.$trigger('unhover-site', ({ id, entityId }));
    },
    travelTo(id, entityId) {
      this.$eventBus.$trigger('travel-to', ({ id, entityId }));
    }
  }
};
</script>

<style scoped src="../../../stylesheet/maps.css"></style>

<style scoped>
.backBtn i {
  font-size: 20px;
  font-weight: 400;
}

.backBtn:hover {
  color: #24422B !important;
}

.siteCard {
  margin-left: 20px;
}

.siteCard:hover {
  background-color: #F7F7FC;
}
</style>
