<template>
  <div v-if="Object.keys(expenses).length || (!loading && isAdmin)" class="arkCard">
    <div class="row">
      <div class="col-9">
        <div class="DesktopTextMedium primaryDefault">Use of funds</div>
        <!-- todo: add icon and the tooltip -->
        <!-- <i class="mt-2 fa fa-question-circle fa-lg grayscalePlaceholder" aria-hidden="true" /> -->
      </div>
      <div class="col-3 d-flex justify-content-end">
        <a
          v-if="isAdmin"
          :href="`/${entity}s/${entityId}/finances`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
    <div v-if="!specificYearExpenses.length" class="DesktopTextSmall grayscaleLabel row">
      <div class="col-12 mt-2">
        Help investors and donors review details on use of funds.
      </div>
    </div>
    <div v-else>
      <div class="row mt-2">
        <div class="col d-flex justify-content-start">
          <div class="DesktopTextSmall grayscaleLabel">Year:</div>
          <div class="ml-2">
            <div
              class="btn btn-link p-0 m-0"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="d-flex justify-content-start">
                <div class="ml-2 DesktopTextSmall grayscaleOffBlack">
                  {{ dropdownValue }}
                </div>
                <i class="ml-3 mt-1 fa fa-angle-down" aria-hidden="true" />
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="dropdown-menu dropdown-primary">
                <div v-for="category in dropdownCategories" :key="category">
                  <button
                    class="dropdown-item w-100 m-0"
                    @click="setDropdownValue"
                  >
                    {{ category }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-sm-12 mt-4">
          <div class="text-center DesktopTextSmall grayscaleLabel">
            Distribution by expense categories
          </div>
          <div id="financial-distribution-card" />
        </div>
        <div class="col-lg-7 col-sm-12 mt-4">
          <div class="row DesktopTextSmall grayscaleLabel">
            <div class="col-9">Expense categories</div>
            <div class="col-3 text-center">Expense</div>
          </div>
          <hr class="mx-1">
          <div v-for="(category, index) in paginatedData" :key="category.name">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col-2">
                    <span :style="getColor(getPaginatedIndex(index))" class="dot" />
                  </div>
                  <div class="col-10 DesktopTextSmall">{{ category.name }}</div>
                </div>
              </div>
              <div class="col-3 text-center DesktopTextSmall">{{ category.y.toFixed(1) }} %</div>
            </div>
            <hr class="mx-1">
          </div>
          <div v-if="totalPages > 1" class="row">
            <div class="col-2 text-right">
              <button
                type="button"
                :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
                :disabled="isPreviousPaginationDisabled()"
                aria-label="First page"
                @click="chartPage = 0"
              >
                <i class="fa fa-angle-double-left fa-2x" aria-hidden="true" />
              </button>
            </div>
            <div class="col-2 text-left">
              <button
                type="button"
                :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
                :disabled="isPreviousPaginationDisabled()"
                aria-label="Previous page"
                @click="chartPage--"
              >
                <i class="fa fa-angle-left fa-2x" aria-hidden="true" />
              </button>
            </div>
            <div class="col-4 text-center ark-p2">
              {{ `${chartPage + 1} / ${totalPages}` }}
            </div>
            <div class="col-2 text-right">
              <button
                type="button"
                :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
                :disabled="isNextPaginationDisabled()"
                aria-label="Next page"
                @click="chartPage++"
              >
                <i class="fa fa-angle-right fa-2x" aria-hidden="true" />
              </button>
            </div>
            <div class="col-2 text-left">
              <button
                type="button"
                :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
                :disabled="isNextPaginationDisabled()"
                aria-label="Last page"
                @click="chartPage = totalPages - 1"
              >
                <i class="fa fa-angle-double-right fa-2x" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global Highcharts */

import { chartColors, pieChartTemplate } from '../../../../domain/constants';
// import { DateTime } from 'luxon';
import { fallbackApi } from '../../../../api/config';
import isAdminBase from '../../../../utilities/authentication';
import { nextTick } from 'vue';

const currentYear = new Date().getFullYear();
const itemsPerPage = 6;
const template = {
  ...pieChartTemplate,
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: `
      <span style="color:{point.color}">{point.name}</span>
      : <b>{point.budget}</b> ({point.customPercentage:.0f}%) of total <b>{series.budget}</b><br/>
    `
  }
};

const formatter = Intl.NumberFormat('en', { notation: 'compact' });
const formatBudget = (budget) => formatter.format(budget);

export default {
  name: 'FinancialDistributionCard',
  data() {
    return {
      entity: '',
      entityId: '',
      budget: '',
      date: '',
      pieChart: undefined,
      expenses: {},
      dropdownValue: currentYear,
      dropdownCategories: [currentYear],
      chartPage: 0,
      loading: true
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: this.entity,
        entityId: this.entityId
      });
    },
    specificYearExpenses() {
      return this.expenses[this.dropdownValue] || [];
    },
    visibleExpenses() {
      return this.specificYearExpenses
        .filter((expense) => expense.customPercentage)
        .sort((expense1, expense2) =>
          expense2.customPercentage - expense1.customPercentage
        );
    },
    paginatedData() {
      return this.visibleExpenses.slice(
        itemsPerPage * this.chartPage,
        itemsPerPage * (this.chartPage + 1)
      );
    },
    totalPages() {
      return Math.ceil(this.visibleExpenses.length / itemsPerPage);
    }
  },
  watch: {
    dropdownValue() {
      this.chartPage = 0;
      this.renderHighchart();
    }
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.getExpenses();
  },
  methods: {
    getColor: (index) => `background-color: ${chartColors[index] || '#AAA'}`,
    getExpenses() {
      fallbackApi({
        url: `projects/${this.entityId}/finances`,
        dataSetter: async ({ budget }) => {
          const allocations = budget?.budget_allocations;
          if (
            allocations && allocations?.length !== 0 &&
              !allocations?.every(({ value }) => parseFloat(value) === 0)
          ) {
            this.normalizeDataForChart(budget);
          }
          this.loading = false;
          await nextTick();
          this.renderHighchart();
        }
      });
    },
    getModel() {
      const financeCard = document.querySelector('#finance');
      this.entity = financeCard?.getAttribute('data-model');
      this.entityId = financeCard?.getAttribute('data-model-id');
      this.date = financeCard?.getAttribute('data-date');
    },
    normalizeDataForChart({ value, budget_allocations: allocations }) {
      this.budget = formatBudget(value);

      const years = [...new Set([
        currentYear,
        ...allocations.map(({ year }) => year)
      ])].sort((year1, year2) => year2 - year1);

      const expensesData = {};
      years.forEach((year) => {
        let budgetSum = 0;
        expensesData[year] = allocations
          .filter(({ year: itemYear }) => itemYear === year)
          .map(({ value: itemValue, budget_allocation_category: category }) => {
            const floatValue = parseFloat(itemValue);
            budgetSum += floatValue;
            return {
              name: category.name,
              budget: formatBudget(floatValue * value / 100),
              customPercentage: floatValue,
              y: floatValue
            };
          })
          .sort(({ y: y1 }, { y: y2 }) => y2 - y1);

        if (budgetSum < 100) {
          const customPercentage = 100 - budgetSum;
          expensesData[year].push({
            name: 'No financial category selected',
            budget: formatBudget(value),
            customPercentage,
            y: customPercentage,
            color: 'rgba(75, 75, 75, 0.2)'
          });
        }
      });

      this.dropdownCategories = years;
      this.expenses = expensesData;
    },
    getPaginatedIndex(currentIndex) {
      return this.chartPage * itemsPerPage + currentIndex;
    },
    isPreviousPaginationDisabled() {
      return !this.chartPage;
    },
    isNextPaginationDisabled() {
      return this.chartPage > this.totalPages - 2;
    },
    setDropdownValue({ srcElement }) {
      this.dropdownValue = parseFloat(srcElement.innerText);
    },
    renderHighchart() {
      if (!this.specificYearExpenses.length) {
        this.pieChart = undefined;
        return;
      }

      this.pieChart = Highcharts.chart('financial-distribution-card', template);
      this.pieChart.series[0].remove();
      this.pieChart.addSeries({
        name: 'Expenses',
        data: this.specificYearExpenses,
        innerSize: '50%'
      });
      this.pieChart.series[0].budget = this.budget;

      this.pieChart.setTitle({
        text: `
          <div class="text-center DesktopLinkMedium primaryDefault">
            ${this.budget}
          </div>
        `
          // ${this.date ? `
          //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
          //     ${DateTime.fromISO(this.date).toFormat('LLL yyyy')}
          //   </div>
          // ` : `
          //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
          //     No End Date
          //   </div>
          // `}
      });

      this.pieChart.reflow();
    }
  }
};
</script>

<style scoped>
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.button {
  color: #777;
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  color: #36533e;
  background-color: #eee;
}

.disabled-button {
  color: #bbb;
  pointer-events: none;
  cursor: default;
}
</style>
