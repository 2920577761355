import UserEdit from './components/UserEdit.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#edit-user')){
    const app = createApp(UserEdit);
    app.use(store);
    app.mount('#edit-user');
  }
});
