import 'v-calendar/dist/style.css';
import ESGGraph from './components/ESGGraph.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#esg-profile')){
    const app = createApp(ESGGraph);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#esg-profile');
  }
});
