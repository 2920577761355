<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10 col-lg-8 ml-4 ml-md-0"
        aria-label="Go back to organization's profile"
      >
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/${modelPluralize}/${modelId}/team`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center">
      <section class="col-sm-12 col-md-10 col-lg-8 d-flex align-items-center ml-4 ml-md-0">
        <div class="p-2 d-flex" aria-hidden="true">
          <img
            :src="images?.collaborate"
            class="mr-2 noDataImage"
            alt="An image of the world"
          >
        </div>
        <h2 class="ml-2 mb-0 DesktopTextSmall grayscaleOffBlack">Edit Team</h2>
      </section>
    </header>
  </section>

  <main class="row justify-content-center">
    <article class="col-sm-12 col-md-10 col-lg-8">
      <div v-if="Object.keys(organization).length === 0">
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
      </div>
      <section v-else class="arkCardNoPadding">
        <div class="row mx-0">
          <nav-tabs :tab="activeTab" :tab-options="tabOptions" :tab-header="tabHeader" @tab-changed="tabChanged" />
          <section class="col-12 col-sm-7 col-md-7 col-lg-7 col-xl-8">
            <div v-show="activeTab === 'members'" class="pl-0 pl-xl-4">
              <team-members
                :model-id="modelId"
                :model-pluralize="modelPluralize"
                :items="organization"
                :default-profile="defaultProfileImage"
                @save-items="saveOrganization"
                @delete-member="deleteMember"
                @delete-invitation="deleteInvitation"
                @resend-invitation="invitedMembers"
              />
            </div>
            <div v-show="activeTab === 'invite'" class="pl-0 pl-xl-4">
              <invite-members
                :model-id="modelId"
                :organization="organization"
                @new-invited-members="invitedMembers"
              />
            </div>
          </section>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
import InviteMembers from './InviteMembers.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import NavTabs from '../../../../components/shared/NavTabs.vue';
import { fallbackApi } from '../../../../api/config';
import TeamMembers from '../../../../components/shared/TeamMembers.vue';

export default {
  components: { LoadingIndicator, NavTabs, InviteMembers, TeamMembers },
  data() {
    return {
      loading: true,
      organization: {},
      model: '',
      modelPluralize: '',
      defaultProfileImage: '',
      modelId: null,
      images: {},
      tabHeader: 'Team',
      tabOptions: [
        {alias: 'members', name: 'Manage team'},
        {alias: 'invite', name: 'Invite members'},
      ],
      activeTab: ''
    };
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchOrganizationInfo();
    this.activeTab = new URLSearchParams(window.location.search)?.get('tab') || 'members';
  },
  methods: {
    tabChanged(tab) {
      this.activeTab = tab;
    },
    getModel() {
      const modelInfo = document.querySelector('#edit-organization-team');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.defaultProfileImage = modelInfo?.getAttribute('data-default-profile-image');
      this.images = {
        collaborate: modelInfo?.dataset?.imgUrl
      };
    },
    fetchOrganizationInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/about`,
        dataSetter: ({ organization }) => {
          this.organization = organization;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    saveOrganization(organization) {
      this.organization = { ...this.organization, ...organization };
    },
    deleteMember(id) {
      this.organization.members = this.organization.members.filter(
        (item) => item.id !== id
      );
    },
    deleteInvitation(id) {
      this.organization.invited_members = this.organization.invited_members.filter(
        (item) => item.id !== id
      );
    },
    invitedMembers(data, tab = 'members') {
      this.organization.members = data.members;
      this.organization.invited_members = data.invited_members;
      this.activeTab = tab;
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}

.noDataImage {
  width: 48px;
  height: 45px;
}
</style>
