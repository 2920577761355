<template>
  <div>
    <div class="progress-bar">
      <div class="progress-bar-inner" :style="{ width: progress + '%' }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.progress-bar {
  width: 100px;
  height: 10px;
  background-color: #ddd;
  border-radius: 3px;
}

.progress-bar-inner {
  height: 100%;
  background-color: #478964;
  border-radius: 3px;
}
</style>