<template>
  <div class="arkCard mb-0">
    <header class="row justify-content-center mt-3">
      <div class="col-12">
        <h2 class="ml-2 mb-0 DesktopLinkXSmall grayscaleBody">
          {{ cardTitle }}
        </h2>
      </div>
    </header>
    <PieChart
      :the-data="chartData"
      :series-name="seriesName"
      :chart-div-id="chartDivId"
    />
  </div>
</template>

<script>
import PieChart from '../../../components/shared/PieChart.vue';

export default {
  components: {
    PieChart
  },
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    cardTitle: {
      type: String,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    seriesName: {
      type: String,
      required: false
    },
    chartDivId: {
      type: String,
      required: true
    }
  }
};
</script>
