<template>
  <section>
    <button
      type="button"
      class="btn btn-sm DesktopLinkXSmall primaryButton transition-02 mr-2 text-right d-flex"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i
        class="fa fa-cloud-download grayscaleOffWhite pr-2 align-self-center"
        aria-hidden="true"
      />
      Export
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <button
        class="dropdown-item ark-link-2"
        @click="onExport"
      >
        M&E Plan
      </button>
      <button
        class="dropdown-item ark-link-2"
        @click="openExportModal = true"
      >
        M&E Report
      </button>
    </div>
  </section>
  <avl-modal
    v-if="openExportModal"
    :is-open="openExportModal"
    size="600px"
    header-title=""
    @avleventclose="closeExportModal"
  >
    <section class="row mb-5">
      <div class="DesktopLinkMedium grayscaleOffBlack d-flex p-2 col-12 mt-4">
        Export M&E report
      </div>
      <div class="DesktopTextSmall grayscaleOffBlack d-flex p-2 col-12">
        Data will be exported as an .XLSX file.
      </div>
      <div class="DesktopLinkSmall grayscaleOffBlack d-flex mt-4 p-2 col-12">
        Time range
      </div>
      <date-range
        :error-onsubmit="errorOnsubmit"
        :loading="loading"
        :start-date="form.startDate"
        :end-date="form.endDate"
        :labels="true"
        class-start-date="col-12 col-sm-12 col-md-8 col-lg-7"
        class-end-date="col-12 col-sm-12 col-md-8 col-lg-7"
        label-start-date="From"
        label-end-date="To"
        @date-range="saveDateRange"
      />
    </section>
    <div class="row d-flex justify-content-center mt-3 mb-2">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-link grayscaleBody ark-bold"
          @click="closeExportModal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link secondary7_ark ark-bold"
          @click="exportReport"
        >
          Export
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>
import * as yup from 'yup';
import DateRange from './DateRange.vue';
import { fallbackApi } from '../../api/config';

const ValidationSchema = yup.object({
  startDate: yup.string().required(),
  endDate: yup.string().required()
});

export default {
  components: { DateRange },
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      openExportModal: false,
      form: {
        startDate: '',
        endDate: ''
      },
      errorOnsubmit: false,
      loading: false
    };
  },
  methods: {
    closeExportModal() {
      this.form.startDate = '';
      this.form.endDate = '';
      this.openExportModal = false;
    },
    exportReport() {
      this.loading = true;

      ValidationSchema.validate(this.form)
        .then(() => {
          fallbackApi({
            url: `${this.modelPluralize}/${this.modelId}/export_report`,
            payload: {
              json: {
                range: {
                  start_date: this.form.startDate,
                  end_date: this.form.endDate
                }
              }
            },
            httpVerb: 'post',
            fileResponse: true,
            dataSetter: (data) => { this.downloadFile(data, 'report'); },
            loadingSetter: (loading) => this.loading = loading
          });
        })
        .catch(() => {
          this.errorOnsubmit = true;
          this.loading = false;
        });
    },
    saveDateRange(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    },
    onExport() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/export_plan`,
        httpVerb: 'post',
        fileResponse: true,
        dataSetter: (data) => { this.downloadFile(data, 'plan'); },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    downloadFile(raw, name){
      const url = window.URL.createObjectURL(new Blob([raw]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
  button.dropdown-item {
    width: calc(100% - 0.625rem);
  }

  .dropdown-menu {
    min-width: auto;
  }
</style>
