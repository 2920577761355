<template>
  <section>
    <div v-if="loadingUserInfo">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <user-header
        :user="user"
        @follow-unfollow="followUnfollow"
        @update-image="updateHeaderImage"
      />
    </div>
    <div v-if="loading && projects.length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <section class="arkCard d-flex">
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            boxShadowNone
            backTag p-0 mr-3
          "
          :href="`/users/${userId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />
        </a>
        <h3 class="DesktopTextMedium primaryDefault my-0 ml-3">Projects/Reports</h3>
      </section>
      <section
        v-if="projects.length === 0 && !loading"
        class="arkCard"
      >
        <no-data-card
          first-message="Nothing to see for now"
          :second-message="
            currentUserProfile ?
              'Your projects will be displayed here.' :
              `${userName}'s projects will be displayed here.`
          "
          :show-button="false"
        />
      </section>
      <project-card
        v-for="project in projects"
        :key="project.id"
        :project="project"
        :single-card="true"
        @invest="investToProject"
      />
      <div
        v-if="loading && projects.length > 0"
        class="ml-2 d-flex justify-content-center my-3"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            grayscaleLabel
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </section>
  <CreateContributionForm
    :modal-open="createContributionModalOpen"
    :contribution-recipient-id="contributionRecipientId"
    :contribution-recipient-name="contributionRecipientName"
    contribution-recipient-type="project"
    :contribution-button-text="contributionButtonText"
    @close-modal-event="closeCreateContributionFormModal"
  />
</template>

<script>
import { contributionsMixin, paginationMixin, userMixin } from '../../../../mixins';
import CreateContributionForm from '../../../contributions/components/CreateContributionForm.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../../components/shared/NoDataCard.vue';
import ProjectCard from '../../../../components/listItems/ProjectCard.vue';
import UserHeader from '../../sharedComponents/UserHeader.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { UserHeader, LoadingIndicator, NoDataCard, ProjectCard, CreateContributionForm },
  mixins: [paginationMixin, userMixin, contributionsMixin],
  data() {
    return {
      projects: [],
      totalProjects: 0,
      page: 1,
      per: 10,
      userId: '',
      userName: '',
      currentUserId: '',
      loading: false,
      contributionRecipientId: '',
      contributionRecipientName: '',
      createContributionModalOpen: false
    };
  },
  computed: {
    currentUserProfile() {
      return this.userId == this.currentUserId;
    }
  },
  beforeMount() {
    this.getModel();
    this.currentUserId = this.$store.state.auth.user.id;
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    handlePagination() {
      if (this.projects.length !== this.totalProjects && !this.loading) {
        this.page ++;
        this.getProjects();
      }
    },
    getModel() {
      const modelInfo = document.querySelector('#user-projects');
      this.userId = modelInfo?.dataset.userId;
      this.userName = modelInfo?.dataset.userName;
    },
    getProjects() {
      this.loading = true;
      fallbackApi({
        url:
          `users/${this.userId}/projects?page=${this.page}&per=${this.per}`,
        dataSetter: (data) => {
          this.totalProjects = data.all_projects_count;
          this.projects = this.uniqueItems([...this.projects, ...data.projects]);
          this.loading = false;
        }
      });
    },
    investToProject(project, buttonText) {
      this.openCreateContributionFormModal(project, 'project', buttonText);
    }
  }
};
</script>

<style scoped>
.backTag {
  color: #24422B !important;
}
</style>
