import CreateRiskForm from './components/CreateRiskForm.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#create-risk')){
    const app = createApp(CreateRiskForm);
    app.use(store);
    app.mount('#create-risk');
  }
});
