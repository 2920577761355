/* global Sentry */

import ky from 'ky';

const apiToken = document.querySelector("meta[name='api-token']");
let api = null;

if (apiToken) {
  let options = {
    headers: {
      Authorization: `Bearer ${apiToken.getAttribute('content')}`,
      'Content-Type': 'application/json'
    },
    prefixUrl: '/api/v1/',
    timeout: 20 * 1000
  };

  if (process.env.RAILS_ENV === 'development') {
    options['timeout'] = 90 * 1000;
  }

  api = ky.create(options);
} else {
  let options = {
    headers: {
      'Content-Type': 'application/json'
    },
    prefixUrl: '/api/v1/'
  };
  api = ky.create(options);
}

/*
  This method does no error or loading handling and just returns the raw data,
  that were fetched from the response (it can of course include the readable stream
  parsing via `isReadableStream`). It's intended for development cases where
  we build components with dummy/fallback data and want a simpler version of
  the fallback method.
*/
const debugFallbackApi = async ({
  url,
  payload,
  fallbackData,
  httpVerb = 'get',
  resolveData = true
}) => {
  const httpArguments = [url];
  if (payload) httpArguments.push(payload);

  return new Promise((resolve, reject) => {
    api[httpVerb](...httpArguments)
      .then((response) => resolveData ? response.json().then((data) => resolve(data)) : resolve())
      .catch((error) => {
        console.warn('error', error);
        Sentry && Sentry.captureException(error);

        if (fallbackData) resolve(fallbackData);
        else reject(fallbackData);
      });
    });
};

/*
  This method includes all the features that this endpoint abstraction has to offer.
  It includes error and loading handling, both via the returned object { data, error }
  and via the 3 setters available: `dataSetter`, `loadingSetter` and `errorSetter`.
  It also includes some redirect options via the `redirect` argument, which must receive
  a `url` and a `when` key. If the `url` key is not given, no redirects will happen.
  The `when` key needs to have one of the following values: [always, onSuccess, onError].
*/
const fallbackApi = async ({
  url,
  payload,
  fallbackData,
  httpVerb = 'get',
  redirect = { url: '', when: 'onSuccess' },
  dataSetter,
  loadingSetter,
  errorSetter,
  resolveData = true,
  fileResponse = false
}
) => {
  let errorOccurred = false;
  const httpArguments = [url];
  if (payload) httpArguments.push(payload);
  if (loadingSetter) loadingSetter(true);
  if (errorSetter) errorSetter(undefined);

  return new Promise((resolve, reject) => {
    api[httpVerb](...httpArguments)
      .then(async (response) => {
        if (resolveData) {
          if (fileResponse) {
            const blob = await response.blob();
            if (dataSetter) dataSetter(blob);
            resolve({ data: blob, error: undefined });
            return;
          }
          const data = await response.json();
          if (dataSetter) dataSetter(data);
          resolve({ data, error: undefined });
        } else {
          resolve({ error: undefined });
        }
      })
      .catch((error) => {
        errorOccurred = true;

        console.warn('error', error);
        Sentry && Sentry.captureException(error);

        if (errorSetter) errorSetter(error);

        if (fallbackData) {
          if (dataSetter) dataSetter(fallbackData);
          resolve({ data: fallbackData, error });
        } else {
          reject({ data: undefined, error });
        }
      })
      .finally(() => {
        if (loadingSetter) loadingSetter(false);
        if (redirect?.url) {
          switch (redirect?.when) {
            case 'always':
              location.assign(redirect.url);
              break;
            case 'onSuccess':
              if (!errorOccurred || fallbackData) location.assign(redirect.url);
              break;
            case 'onError':
              if (errorOccurred && !fallbackData) location.assign(redirect.url);
              break;
            default:
              throw new Error(
                'redirect.when needs to be one of the following: [always, onSuccess, onError]'
              );
          }
        }
      });
    });
};

export default api;
export { api, fallbackApi, debugFallbackApi };
