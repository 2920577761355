<template>
  <section class="py-4 maxWidthInputs">
    <h4 class="grayscaleOffBlack DesktopLinkSmall mb-4">Team Member Seats</h4>
    <form class="mb-2">
      <section class="text-left mt-4">
        <fieldset>
          <legend class="sr-only">Members with seats</legend>
          <div class="row">
            <div class="col-12">
              <Multiselect
                v-model="form.selectedMembers"
                class="multiselect-ark"
                placeholder="Search ARK user by name"
                mode="tags"
                track-by="name"
                label="name"
                value-prop="id"
                required
                :close-on-select="false"
                :searchable="true"
                :options="availableOptions"
                :disabled="loading || emptySeats === 0"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <span class="grayscaleLabel DesktopTextXSmall">{{ emptySeatsMessage }}</span>
    <error-message
      class="saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessage"
      :error-message="errorMessage"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged || !!errorMessage"
        @click="submit"
      >
        Save
      </button>
    </div>
    <section class="py-3 mb-4">
      <ul class="listStyleNone p-0">
        <li v-for="member in members" :key="member.id">
          <section class="py-2 row">
            <figure class="col-2">
              <img
                v-if="member.avatar"
                :src="member.avatar"
                class="avatarImage"
                :alt="`${member.full_name}'s avatar`"
              >
            </figure>
            <article class="col">
              <h3 class="DesktopLinkSmall primaryDefault mb-2 mt-0">
                {{ member.full_name }}
              </h3>
              <p class="MobileTextXSmall primaryDefault">
                {{ member.occupation }}
              </p>
            </article>
          </section>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { ErrorMessage, Multiselect },
  props: {
    currentSubscription: {
      type: Object,
      default: () => undefined
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  emits: ['update-seats'],
  data() {
    return {
      loading: false,
      availableOptions: [],
      totalOptions: [],
      form: {
        selectedMembers: []
      },
      errorMessage: ''
    };
  },
  computed: {
    members() {
      return this.currentSubscription
        ? this.currentSubscription.seats
            .filter((seat) => seat.user && seat.active)
            .map((seat) => seat.user)
        : [];
    },
    emptySeats() {
      return this.currentSubscription?.seats?.filter((seat) => {
        return !seat.user && seat.active;
      })?.length || 0;
    },
    emptySeatsMessage() {
      if (this.emptySeats === 0) {
        return 'You have 0 remaining Seats on your plan.';
      }

      return `You have ${this.emptySeats} empty ${this.emptySeats > 1 ? 'Seats' : 'Seat'}.`;
    },
    dataChanged() {
      return this.form.selectedMembers.length !== 0;
    }
  },
  mounted() {
    this.getTeamMembers();
  },
  methods: {
    // Get all members of the organization as options to assign to seats
    getTeamMembers() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/team`,
        dataSetter: (data) => {
          this.totalOptions = data.organization_team;
          this.filterOptions();
        }
      });
    },
    // Exclude current seat holders from options
    filterOptions() {
      const memberIds = this.members.map((member) => member.id);
      this.availableOptions = this.totalOptions.filter((item) => !memberIds.includes(item.id));
    },
    async submit() {
      if (this.form.selectedMembers.length === 0) return;

      if (this.form.selectedMembers.length > this.emptySeats) {
        this.errorMessage = "You don't have enough available seats.";
        return;
      }
      this.loading = true;

      fallbackApi({
        url: `subscriptions/${this.currentSubscription?.id}/assign_seats`,
        payload: {
          json: {
            user_ids: this.form.selectedMembers
          }
        },
        httpVerb: 'post'
      })
      .then((response) => {
        this.errorMessage = '';
        this.form.selectedMembers = [];
        this.$emit('update-seats', response.data.subscription);
      })
      .catch((error) => {
        console.log(error);
        this.errorMessage = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.filterOptions();
        this.loading = false;
      });
    },
    discardChanges() {
      this.form.selectedMembers = [];
      this.errorMessage = '';
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped src="../../../../stylesheet/lists.css"></style>
