import ProjectEditTeam from './components/ProjectEditTeam.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#edit-project-team')){
    const app = createApp(ProjectEditTeam);
    app.use(store);
    app.mount('#edit-project-team');
  }
});
