<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Organization basic info:</legend>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="nameInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Name *
              </label>
              <input
                id="nameInput"
                v-model="form.name"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="nameError"
                :aria-invalid="!!errors.name"
                :class="{ notValid: errors.name }"
                @blur="validate"
              >
              <error-message
                id="nameError"
                :error-condition="!!errors.name"
                :error-message="errors.name"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="legalTypeInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Legal entity type *
              </label>
              <Multiselect
                id="legalTypeInput"
                v-model="form.legalTypeId"
                placeholder="Select one"
                :options="legalTypeOptions"
                :close-on-select="true"
                :searchable="true"
                required
                label="name"
                value-prop="id"
                class="multiselect-ark"
                aria-errormessage="legalTypeError"
                :aria-invalid="!!errors.legalTypeId"
                :class="{ 'multiselect-ark-error': errors.legalTypeId }"
                @blur="validate"
              />
              <error-message
                id="legalTypeError"
                :error-condition="!!errors.legalTypeId"
                :error-message="errors.legalTypeId"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="locationInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Location *
              </label>
              <div
                id="locationInputContainer"
                class="input-group inputBorder"
                :class="{ notValid: errors.latitude || errors.longitude }"
                @click="setLocation"
                @blur="validate"
              >
                <input
                  id="locationInput"
                  class="form-control ark DesktopTextSmall border-0"
                  :value="form.locationName || coordinates"
                  disabled
                  aria-errormessage="locationError"
                  required
                  :aria-invalid="!!errors.latitude || !!errors.longitude"
                >
                <button
                  type="button"
                  class="btn btn-link d-flex align-items-center py-0 px-3"
                  aria-label="Go to set location view"
                  @click="setLocation"
                >
                  <i
                    class="fal fa-map-marker-alt grayscaleLabel inputIcon"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <error-message
                id="locationError"
                :error-condition="!!errors.latitude || !!errors.longitude"
                :error-message="errors.latitude || errors.longitude"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="emailInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Contact email *
              </label>
              <input
                id="emailInput"
                v-model="form.email"
                type="email"
                class="form-control ark DesktopTextSmall mb-2"
                aria-describedby="emailHint"
                aria-errormessage="emailError"
                required
                :aria-invalid="!!errors.email"
                :class="{ notValid: errors.email }"
                @blur="validate"
              >
              <error-message
                id="emailError"
                :error-condition="!!errors.email"
                :error-message="errors.email"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="websiteInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Website
              </label>
              <input
                id="websiteInput"
                v-model="form.website"
                type="url"
                class="form-control ark DesktopTextSmall mb-2"
                aria-describedby="websiteHint"
                aria-errormessage="websiteError"
                :aria-invalid="!!errors.website"
                :class="{ notValid: errors.website }"
                @blur="validate"
              >
              <error-message
                id="websiteError"
                :error-condition="!!errors.website"
                :error-message="errors.website"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="descriptionInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Description *
              </label>
              <textarea
                id="descriptionInput"
                v-model="form.description"
                class="form-control ark DesktopTextSmall mb-2"
                aria-describedby="descriptionHint"
                aria-errormessage="descriptionError"
                rows="5"
                required
                :aria-invalid="!!errors.description"
                :class="{ notValid: errors.description }"
                @blur="validate"
              />
              <error-message
                id="descriptionError"
                :error-condition="!!errors.description"
                :error-message="errors.description"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';
import { sharedMixin } from '../../../../mixins';
import { ValidationSchemaOrgGeneralInfo } from '../../../../constants/validationSchemas';


export default {
  components: { ErrorMessage, Multiselect },
  mixins: [ sharedMixin ],
  props: {
    organizationLocation: {
      type: Object,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    legalTypeOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['set-location', 'save-organization'],
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        name: '',
        locationName: '',
        latitude: null,
        longitude: null,
        country: '',
        countryCode: '',
        city: '',
        zip: '',
        email: '',
        website: '',
        description: '',
        legalTypeId: null
      },
      errors: {
        name: '',
        locationName: '',
        latitude: '',
        longitude: '',
        email: '',
        website: '',
        description: '',
        legalTypeId: ''
      },
      errorMessageFromBackend: ''
    };
  },
  computed: {
    dataChanged() {
      return (
        this.form.name !== this.organization?.name ||
        this.form.locationName !== this.organization?.location ||
        this.form.latitude !== this.organization?.formatted_location?.lat ||
        this.form.longitude !== this.organization?.formatted_location?.lon ||
        this.form.city !== this.organization?.city ||
        this.form.zip !== this.organization?.zip ||
        this.form.email !== this.organization?.contact_email ||
        this.form.website !== this.organization?.website_url ||
        this.form.description !== this.organization?.about ||
        this.form.legalTypeId !== this.organization?.legal_type_id
      );
    },
    coordinates() {
      if (this.form.latitude && this.form.longitude) {
        return `${this.form.latitude}, ${this.form.longitude}`;
      }
      else return '';
    }
  },
  watch: {
    organization() {
      this.getOrganizationInfo();
      this.getLocationInfo();
    },
    organizationLocation() {
      this.getLocationInfo();
    }
  },
  mounted() {
    this.getOrganizationInfo();
    this.getLocationInfo();
  },
  methods: {
    getOrganizationInfo() {
      this.form = {
        name: this.organization?.name,
        email: this.organization?.contact_email,
        website: this.organization?.website_url,
        description: this.organization?.about,
        legalTypeId: this.organization?.legal_type_id
      };
    },
    getLocationInfo() {
      this.form = {...this.form, ...this.organizationLocation};
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchemaOrgGeneralInfo, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}`,
          payload: {
            json:
              {
                organization: {
                  name: this.form.name,
                  location: this.form.locationName,
                  latitude: this.form.latitude,
                  longitude: this.form.longitude,
                  country: this.form.country,
                  country_code: this.form.countryCode,
                  city: this.form.city,
                  zip: this.form.zip,
                  contact_email: this.form.email,
                  about: this.form.description,
                  website_url: this.addHttpProtocol(this.form.website),
                  legal_type_id: this.form.legalTypeId
                }
              }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.$emit('save-organization', response.data.organization);
          this.showErrors = false;
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
    discardChanges() {
      this.showErrors = false;
      this.form = {
        name: this.organization?.name,
        locationName: this.organization?.location,
        latitude: this.organization?.formatted_location?.lat,
        longitude: this.organization?.formatted_location?.lon,
        country: this.organization?.country,
        countryCode: '',
        city: this.organization?.city,
        zip: this.organization?.zip,
        email: this.organization?.contact_email,
        website: this.organization?.website_url,
        description: this.organization?.about,
        legalTypeId: this.organization?.legal_type_id
      };
      this.validate();
    },
    setLocation(event) {
      event.stopPropagation();
      this.$emit('set-location', this.form);
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
#locationInputContainer {
  cursor: pointer;
}

#locationInput {
  pointer-events: none;
}

.inputIcon {
  font-size: 1.7em;
}

.inputBorder {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
</style>
