
import 'v-calendar/dist/style.css';
import ProjectEdit from './components/ProjectEdit.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#edit-project')){
    const app = createApp(ProjectEdit);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#edit-project');
  }
});

