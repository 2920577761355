<template>
  <CheckInbox :email="email" />
</template>

<script>
import CheckInbox from '../../sharedComponents/CheckInbox.vue';

export default {
  components: { CheckInbox },
  data() {
    return {
      email: ''
    };
  },
  beforeMount() {
    this.getEmail();
  },
  methods: {
    getEmail() {
      const data = document.querySelector('#applicant-unconfirmed');
      this.email = data?.dataset?.email;
    }
  }
};
</script>
