<template>
  <div v-show="step === 1">
    <select-organization-type
      :images="images"
      @select-type="selectType"
    />
  </div>
  <div v-show="step === 2">
    <organization-form
      :images="images"
      :legal-type-options="legalTypeOptions"
      :organization-type="type"
      :organization-location="location"
      @back="step--"
      @set-location="step++"
    />
  </div>
  <div v-show="step === 3">
    <set-location
      :images="images"
      :location="location"
      :reverse-geocode="true"
      :required="true"
      @back="step--"
      @save="saveLocation"
    />
  </div>
</template>

<script>
import OrganizationForm from './OrganizationForm.vue';
import SelectOrganizationType from './SelectOrganizationType.vue';
import SetLocation from '../../../../components/shared/SetLocation.vue';

export default {
  components: { OrganizationForm, SelectOrganizationType, SetLocation },
  data() {
    return {
      images: {},
      legalTypeOptions: [],
      type: '',
      location: {},
      step: 1
    };
  },
  beforeMount() {
    this.getImages();
  },
  methods: {
    getImages() {
      const data = document.querySelector('#new-organization');
      this.images = {
        collaborate: data?.dataset?.imgCollaborate,
        invest: data?.dataset?.imgInvest,
        data: data?.dataset?.imgData
      };
      this.legalTypeOptions = JSON.parse(data?.dataset?.legalTypes) || [];
    },
    selectType(type) {
      this.type = type;
      this.step = 2;
    },
    saveLocation(location) {
      this.location = {...location};
      this.step = 2;
    }
  }
};
</script>

