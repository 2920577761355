<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Organization attachments:</legend>
          <div class="row">
            <div class="col-12">
              <file-pond
                ref="pond"
                label-idle="
                    <i class='far fa-cloud-upload' aria-hidden></i>
                    Drop files to attach or browse.
                "
                allow-multiple="true"
                :files="attachments"
                accepted-file-types="application/pdf"
                @updatefiles="updateAttachments"
                @error="error"
                @removefile="checkInvalidType"
              />
              <error-message
                class="saveCancelContainer d-flex justify-content-end"
                :error-condition="!!errorMessage"
                :error-message="errorMessage"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged || !!errorMessage"
        @click="submit"
      >
        Save
      </button>
    </div>
    <table
      v-show="allAttachments.length > 0"
      class="py-3 pt-3 mb-4 w-100"
      aria-describedby="table for attachments"
    >
      <thead>
        <tr class="row grayscaleLabel">
          <th
            class="col-8 col-md-4 mb-0 pr-0 DesktopTextSmall fontWeight400"
          >
            Name
          </th>
          <th
            class="col-4 col-md-2 mb-0 pr-0 DesktopTextSmall fontWeight400"
          >
            Size
          </th>
          <th
            class="col-12 col-md-6 mb-0 pr-0 DesktopTextSmall fontWeight400"
          >
            Date added
          </th>
        </tr>
      </thead>
      <tbody class="bottomTopBorder">
        <tr
          v-for="attachment in allAttachments"
          :key="attachment.id + attachment.filename"
          class="row MobileTextSmall grayscaleOffBlack py-2 pointer"
          :aria-label="`Open the attachment ${attachment.filename} in a new tab`"
        >
          <td
            class="col-8 col-md-4 mb-1 pr-0 wordBreakWord d-flex align-items-center"
            @click="openPreview(attachment.url)"
          >
            {{ attachment.filename }}
          </td>
          <td
            class="col-4 col-md-2 mb-1 pr-0 wordBreakWord d-flex align-items-center"
            @click="openPreview(attachment.url)"
          >
            {{ attachment.byte_size }}
          </td>
          <td
            class="col-8 col-md-3 mb-1 pr-0 wordBreakWord d-flex align-items-center"
            @click="openPreview(attachment.url)">
            {{ attachment.formatted_date }}
          </td>
          <td
            class="
              col-4 col-md-3 mb-1
              d-flex
              justify-content-start justify-content-md-end
            "
          >
            <button
              class="btn btn-link px-2"
              :aria-label="'Delete the attachment with name ' + attachment.filename"
              @click.prevent="deleteAttachment(attachment.id)"
            >
              <i aria-hidden="true" class="fas fa-trash fa-lg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import 'filepond/dist/filepond.min.css';
import ErrorMessage from './ErrorMessage.vue';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { fallbackApi } from '../../api/config';
import vueFilePond from 'vue-filepond';
import { sharedMixin } from '../../mixins';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType
);

export default {
  components: { ErrorMessage, FilePond },
  mixins: [sharedMixin],
  props: {
    allAttachments: {
      type: Object,
      required: true
    },
    totalAttachments: {
      type: Number,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  emits: ['delete-attachment', 'save-model'],
  data() {
    return {
      loading: false,
      submitted: false,
      attachments: [],
      errorMessageFromBackend: '',
      errorMessage: ''
    };
  },
  computed: {
    dataChanged() {
      return this.attachments.length !== 0;
    }
  },
  methods: {
    deleteAttachment(id) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/attachments`,
        payload: {
          json: { attachment_id: id }
        },
        httpVerb: 'delete'
      })
      .then(() => {
        this.$emit('delete-attachment', id);
      })
      .catch(async (error) => {
        if (error?.error?.response) {
            const errorMessage = await error.error.response.json();
            this.errorMessageFromBackend = errorMessage.error;
          } else {
            this.errorMessageFromBackend = error?.error?.message ||
              'Something went wrong. Please try again';
          }
      })
      .finally(() => (this.loading = false));
    },
    error(error) {
      this.errorMessage = error? error.main + '. The files should have type application/pdf' : '';
    },
    checkInvalidType() {
      const invalidType = this.attachments.find((attachment) => {
        return attachment.type !== 'application/pdf';
      });
      if (!invalidType) this.errorMessage = '';
    },
    updateAttachments(attachments) {
      this.warningMessage = '';
      let files = [];
      files = attachments.map((attachment) => {
        return attachment.file;
      });
      this.attachments = files;
      this.checkInvalidType();
    },
    async submit() {
      this.submitted = true;
      const formData = new FormData();

      if (this.attachments.length !== 0) {
        this.loading = true;
        this.attachments.forEach((file) => {
            formData.append('attachments[]', file);
        });

        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/attachments`,
          payload: {
            body: formData,
            // Remove json content type so browser can set the appropriate formdata content type
            headers: {
              'Content-Type': undefined
            }
          },
          httpVerb: 'post'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.submitted = false;
          this.attachments = [];
          this.$refs.pond.removeFiles();
          this.$emit('save-model', response.data);
        })
        .catch(async (error) => {
          if (error?.error?.response) {
            const errorMessage = await error.error.response.json();
            this.errorMessageFromBackend = errorMessage.error;
          } else {
            this.errorMessageFromBackend = error?.error?.message ||
              'Something went wrong. Please try again';
          }
        })
        .finally(() => (this.loading = false));
      }
    },
    discardChanges() {
      this.submitted = false;
      this.attachments = [];
      this.errorMessage = '';
      this.errorMessageFromBackend = '';
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
.fontWeight400 {
    font-weight: 400;
}

.bottomTopBorder {
  border-bottom: solid 1px #BDBFD2;
  border-top: solid 1px #BDBFD2;
}
</style>
