import { fallbackApi } from '../../../api/config';

export const metricOptionsMixin = {
  data() {
    return {
      measurementOptions: [],
      entryFrequencyOptions: [],
      statusOptions: [],
      auditorOptions: []
    };
  },
  mounted() {
    this.fetchMeasurementTypes();
    this.fetchEntryFrequencies();
    this.fetchStatuses();
    if (this.isAdmin) {
      this.fetchAuditors();
    }
  },
  methods: {
    fetchMeasurementTypes() {
      fallbackApi({
        url: 'measurements/types',
        dataSetter: (data) => {
          const units = data.types.flatMap(({ units }) => units);
          units.splice(units.findIndex(({ name }) => name === 'No Unit'), 1);
          this.measurementOptions = units;
        }
      });
    },
    fetchEntryFrequencies() {
      fallbackApi({
        url: 'entry_frequencies',
        dataSetter: (data) => {
          this.entryFrequencyOptions = data.entry_frequencies;
        }
      });
    },
    fetchStatuses() {
      fallbackApi({
        url: 'statuses',
        dataSetter: (data) => {
          this.statusOptions = data.statuses;
        }
      });
    },
    fetchAuditors() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/available_auditors`,
        dataSetter: (data) => {
          this.auditorOptions = data.available_auditors;
        }
      })
    }
  }
};
