<template>
  <div class="userJourneyCard p-5 position-relative">
    <div class="DesktopDisplayLargeBold">{{ cardTitle }}</div>
    <div class="DesktopTextMedium mt-5 cardContent">{{ cardContent }}</div>
    <a :href="buttonLink" class="btn userJourneyCardBtn textTransformNone position-absolute">{{ buttonText }}</a>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    cardContent: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonLink: {
      type: String,
      required: true,      
    }
  },
};
</script>

<style scoped>
.userJourneyCard {
  background-color: #001C00;
  border: #478964 4px solid;
  border-radius: 16px;
  height: 100%;
}

.userJourneyCardBtn {
  border-radius: 24px;
  color: white !important;
  background-color: #478964;
  bottom: 50px;
  cursor: pointer !important;
}

.cardContent {
  margin-bottom: 100px;
}
</style>