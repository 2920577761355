// Form image input
const inputs = document.querySelectorAll('.js-read-url');
inputs.forEach((input) => input.addEventListener('change', () => readURL(input)));

// Return to top button
function setUpReturnTop($btn) {
  $(window).scroll(function() {
    if($(this).scrollTop() >= 100) {
      $btn.fadeIn(200);
    } else {
      $btn.fadeOut(200);
    }
  });

  $btn.click(function() {
    $('body,html').animate({
      scrollTop : 0
    }, 500);
  });
}


const returnBtn = $('.return-top-btn');
if(returnBtn) {
  setUpReturnTop(returnBtn);
}


// Show more functionality
function setUpShowMore(container) {
  const button = container.querySelector('.js-show-more');
  if(!button) {
    return;
  }
  const showMoreText = button.innerHTML;
  const showLessText = showMoreText.replace('fa-angle-down', 'fa-angle-up').replace(/(more|all)/, 'less');
  button.addEventListener('click', () => {
    moreItems = container.querySelector('.js-more-items');
    if(!moreItems) {
      return;
    }
    if(moreItems.classList.contains('d-none')) {
      moreItems.classList.toggle('d-none');
      button.innerHTML = showLessText;
    }
    else {
      moreItems.classList.toggle('d-none');
      button.innerHTML = showMoreText;
    }
  })
}

const showMoreContainers = document.querySelectorAll('.js-show-more-container');
showMoreContainers.forEach(container => setUpShowMore(container));


// Show hidden component
function setUpShowHidden(button) {
  button.addEventListener('click', () => {
    const id = button.getAttribute('data-target');
    const component = document.querySelector(id);
    if (component) {
      component.classList.toggle('d-none');
      component.querySelector('input').focus();
    }
  });
}

const showHiddenButtons = document.querySelectorAll('.js-show-hidden');
showHiddenButtons.forEach(button => setUpShowHidden(button));

// Gallery modal
function setupGalleryModal() {
  $('.gallery-img-container').on('click', function() {
    $('.gallery-modal').modal('show');
    $('.carousel-control-next').focus();
  });
  $('.gallery-modal-close').on('click', function() {
    $('.gallery-modal').modal('hide');
  });
}

if ($('.gallery-img-container')) {
  setupGalleryModal();
}


// Map with single point
function renderMap(mapContainer) {
  const address = mapContainer.getAttribute('data-address');
  const name = mapContainer.getAttribute('data-name');
  const location_url = mapContainer.getAttribute('data-url');
  const marker_color = mapContainer.getAttribute('marker-color');

  // Regex: get '@lat,long' part of url
  var coords = location_url.match(/@-?(0|[1-9]\d*)(\.\d+)?,-?(0|[1-9]\d*)(\.\d+)?/);
  // If coords can be extracted from url use them, else use address
  if (coords && coords[0][0] === '@') {
    coords = coords[0].substring(1).split(',').reverse();
    var map = new mapboxgl.Map({
      container: mapContainer.id,
      style: 'mapbox://styles/mapbox/light-v10',
      center: coords,
      zoom: 1
    });
    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    // Create popup
    var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setText(name);

    // Create marker
    var marker = new mapboxgl.Marker({ color: marker_color }).setLngLat(coords).setPopup(popup);
    marker.getElement().style.cursor = 'pointer';
    marker.addTo(map);
  }
  else {
    var mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
    mapboxClient.geocoding
    .forwardGeocode({
      query: address,
      autocomplete: true,
      limit: 1
    })
    .send()
    .then(function (response) {
      if(
        response &&
        response.body &&
        response.body.features &&
        response.body.features.length
      ) {
        var feature = response.body.features[0];

        var map = new mapboxgl.Map({
          container: mapContainer.id,
          style: 'mapbox://styles/mapbox/light-v10',
          center: feature.center,
          zoom: 1
        });
        // Add zoom and rotation controls to the map.
        map.addControl(new mapboxgl.NavigationControl());

        // Create popup
        var popup = new mapboxgl.Popup({ offset: 25, focusAfterOpen: false }).setText(name);

        // Create marker
        var marker = new mapboxgl.Marker({ color: marker_color }).setLngLat(feature.center).setPopup(popup);
        marker.getElement().style.cursor = 'pointer';
        marker.addTo(map);
      }
    });
  }
}

const mapDiv = document.getElementById('single-point-map');
if (mapDiv) {
  renderMap(mapDiv);
}

window.arkUI = {
  alert: {
    show: function showAlert(options) {
      const html = options.html;
      const redirect = options.redirect || false;
      new swal({
        html: html,
        showCancelButton: redirect,
        cancelButtonText: options.buttonText || 'later',
        cancelButtonClass: 'btn btn-default',
        confirmButtonClass: 'btn btn-secondary1-ark',
        buttonsStyling: false
      }).then(function(result) {
        if (result.isConfirmed) {
          if (options.redirect) {
            setTimeout(function() {
              window.location = options.redirect_location || "/";
            }, options.redirect_delay || 500);
          }
          if (options.confirmCallback) {
            options.confirmCallback();
          }
        }
      }).catch((error) => {
        Sentry && Sentry.captureException(error);
        swal.noop();
      });
    }
  },
  loadingIndicator: {
    // Global Loading Indicator Functionality. Use onDemand across any pages
    show: function showLoadingIndicator() {
      $('#loadingModal').css('display','block');
    },
    hide: function hideLoadingIndicator() {
      $('#loadingModal').css('display','none');
    }
  }
}

// Replace load more link with spinner while fetching more items
$('.paginator a').on('click', function() {
  $(this).parent().html('<span class="spinner-border spinner-border-sm text-muted" role="alert" aria-label="Loading more elements"></span>');
});

if($('#ark-video').length > 0) {
  // Show videojs player only when ready to play (source is correct)
  const player = window.videojs('ark-video');
  if(player) {
    player.on('ready', () => {
      $('#ark-video').removeClass('d-none');
    });

    player.on('error', () => {
      $('#ark-video.hide-errors').addClass('d-none');
    });
  }
}

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png'];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("Only jpeg or png files can be uploaded");
  }
});
