<template>
  <section class="maxWidthInputs">
    <h4 class="grayscaleOffBlack DesktopLinkSmall mt-4">About video</h4>
    <button
      class="
        btn
        DesktopLinkXSmall
        grayscaleOffWhite
        primaryButton
      "
      @click="uploadVideoModalOpen = true"
    >
      <span v-if="aboutVideo">Replace about video </span>
      <span v-else>
        <i
          class="fa fas fa-plus"
          aria-hidden="true"
        />&nbsp;&nbsp;
        Add about video
      </span>
    </button>
    <article v-if="aboutVideo" class="row mt-3">
      <section
        v-if="model.videos[0]"
        class="col-10"
      >
        <div class="row">
          <p
            class="
              col-5 d-flex mb-0
              align-items-center
              grayscaleOffBlack
              MobileTextSmall
              wordBreakWord
            "
          >
            {{ model.videos[0].filename }}
          </p>
          <p
            class="
              col-2 d-flex px-0 mb-0
              align-items-center
              grayscaleOffBlack
              MobileTextSmall
              wordBreakWord
            "
          >
            {{ model.videos[0].byte_size }}
          </p>
          <p
            class="
              col-5 d-flex mb-0
              align-items-center
              grayscaleOffBlack
              MobileTextSmall
              wordBreakWord
            "
          >
            {{ model.videos[0].formatted_date }}
          </p>
        </div>
      </section>
      <p
        v-else-if="model.about_video_url"
        class="
          col-10 mb-0
          grayscaleOffBlack
          MobileTextSmall
          d-flex
          align-items-center
          wordBreakWord
        "
      >
        {{ model.about_video_url }}
      </p>
      <div class="col-2 d-flex align-items-center justify-content-end">
        <button
          aria-hidden="true"
          aria-label="Delete about video"
          class="btn btn-link px-2"
          @click="deleteVideo"
        >
          <i class="fas fa-trash fa-lg" />
        </button>
      </div>
    </article>
    <section v-if="socialLinks" class="mt-4">
      <fieldset>
        <legend class="sr-only">Social links info:</legend>
        <h4 class="grayscaleOffBlack DesktopLinkSmall">Social links</h4>
        <div
          v-for="item in formSocialMedia"
          :key="'social ' + item.name"
          class="row"
        >
          <div class="col-12 col-sm-6 col-md-4 py-2">
            <Multiselect
              v-model="item.name"
              placeholder="Type"
              :options="availableOptions"
              :close-on-select="true"
              :searchable="true"
              required
              label="value"
              :disabled="loading"
              class="mb-2"
              :class="
                showErrors && !item.name
                  ? 'multiselect-ark multiselect-ark-error'
                  : 'multiselect-ark'
              "
              @clear="deleteRow(item)"
            />
            <error-message
              :error-condition="showErrors && !item.name"
              error-message="Select an option"
            />
          </div>
          <div class="col-12 col-sm-6 col-md-8 py-2">
            <input
              :id="item.name"
              v-model="item.value"
              type="url"
              class="form-control ark DesktopTextSmall mb-2"
              placeholder="URL"
              :aria-errormessage="item.name + 'Error'"
              :aria-invalid="!!errorsSocialMedia[item.name]"
              :class="{ notValid: errorsSocialMedia[item.name]}"
              @blur="validate"
            >
            <error-message
              :id="item.name + 'Error'"
              :error-condition="!!errorsSocialMedia[item.name]"
              :error-message="errorsSocialMedia[item.name]"
            />
          </div>
        </div>
        <div
          v-if="lastSocialLink(formSocialMedia, 5)"
          class="col-12"
        >
          <button
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
              px-0
            "
            @click="addSocialLink"
          >
            <i
              class="fas fa-plus"
              aria-hidden="true"
            />&nbsp;&nbsp;&nbsp; Add social link
          </button>
        </div>
      </fieldset>
    </section>
    <error-message
      class="saveCancelContainer d-flex justify-content-end maxWidthInputs"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end maxWidthInputs">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading && !uploadVideoModalOpen"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        Save
      </button>
    </div>
    <avl-modal
      :is-open="uploadVideoModalOpen"
      header-title=""
      @avleventclose="uploadVideoModalClosed()"
    >
      <section class="arkCardPadding">
        <div class="form-row">
          <div class="col-12">
            <file-pond
              ref="pond"
              label-idle="
                <i class='far fa-cloud-upload' aria-hidden></i>
                <strong>Drag & Drop your file or Browse</strong>
                <br>Up to 1 GB recommended. MP4 and WebM supported.
              "
              accepted-file-types="video/webm, video/mp4"
              aria-describedby="logoHint"
              :disabled="!!aboutVideoUrl"
              @addfile="addVideo"
              @removefile="video = null"
            />
          </div>
        </div>
        <p class="DesktopTextSmall grayscaleLabel">or</p>
        <div class="py-2">
          <input
            id="aboutVideoUrl"
            v-model="aboutVideoUrl"
            type="url"
            class="form-control ark DesktopTextSmall mb-2"
            placeholder="Paste YouTube video URL (e.g. https://www.youtube.com/watch?v=ZiULxLLP32s)"
            aria-errormessage="aboutVideoUrlError"
            :aria-invalid="!!errorAboutVideoUrl.video"
            :class="{ notValid: errorAboutVideoUrl.video}"
            :disabled="!!video"
            @blur="validateAboutVideoUrl"
          >
          <error-message
            id="aboutVideoUrlError"
            class="d-flex"
            :error-condition="!!errorAboutVideoUrl.video"
            :error-message="errorAboutVideoUrl.video"
          />
        </div>
        <error-message
          class="d-block saveCancelContainer d-flex justify-content-end"
          :error-condition="!!errorMessageFromBackendVideo"
          :error-message="errorMessageFromBackendVideo"
        />
        <div class="saveCancelContainer d-flex justify-content-end">
          <button
            class="btn DesktopLinkXSmall cancelButton"
            @click="uploadVideoModalClosed"
          >
            Cancel
          </button>
          <div
            v-if="loading && uploadVideoModalOpen"
            class="ml-2 d-flex justify-content-center"
          >
            <div
              class="
                d-flex
                align-content-center
                spinner-border
                primary2_ark
                align-self-center
              "
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
              Saving
            </p>
          </div>
          <button
            v-else
            class="
              btn ml-2
              DesktopLinkXSmall
              grayscaleOffWhite
              primaryButton
            "
            :disabled="!video && !aboutVideoUrl"
            @click="saveVideo"
          >
            Save
          </button>
        </div>
      </section>
    </avl-modal>
  </section>
</template>

<script>
import 'filepond/dist/filepond.min.css';
import * as yup from 'yup';
import ErrorMessage from './ErrorMessage.vue';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../api/config';
import { validateForm } from '../../utilities/validation.js';
import vueFilePond from 'vue-filepond';
import { ValidationSchemaSocialMedia } from '../../constants/validationSchemas';
import { socialMediaLinkInputsMixin, sharedMixin } from '../../mixins';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType
);

const ValidationAboutVideoLink = yup.string().url().label('video');

export default {
  components: { ErrorMessage, Multiselect, FilePond },
  mixins: [ socialMediaLinkInputsMixin, sharedMixin ],
  props: {
    model: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    socialLinks: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['save-model'],
  data() {
    return {
      loading: false,
      showErrors: false,
      uploadVideoModalOpen: false,
      video: null,
      aboutVideoUrl: null,
      formSocialMedia: [{ name: '', value: '' }],
      errorsSocialMedia: {
        Facebook: '',
        Instagram: '',
        Linkedin: '',
        Twitter: '',
        Youtube: ''
      },
      errorAboutVideoUrl: { video: '' },
      socialMediaOptions: [
        { value: 'Facebook', disabled: false },
        { value: 'Instagram', disabled: false },
        { value: 'Linkedin', disabled: false },
        { value: 'Twitter', disabled: false },
        { value: 'Youtube', disabled: false }
      ],
      errorMessageFromBackend: '',
      errorMessageFromBackendVideo: ''
    };
  },
  computed: {
    dataChanged() {
      return JSON.stringify(this.formSocialMedia) !==
        JSON.stringify(
            this.convertSocialMediaData(this.model?.social_media_links || [])
      );
    },
    availableOptions() {
      return this.socialMediaOptions.map((option) => {
        let isSocialSelected = this.formSocialMedia.filter((item) => {
            return item.name === option.value;
          });

        return {
          value: option.value,
          disabled: isSocialSelected?.length !== 0
        };
      });
    },
    aboutVideo () {
      return this.model.about_video_url || this.model.about_video;
    }
  },
  watch: {
    model() {
      this.getModelInfo();
    }
  },
  mounted() {
    this.getModelInfo();
  },
  methods: {
    getModelInfo() {
      this.formSocialMedia =
        this.convertSocialMediaData(this.model?.social_media_links || []);
    },
    validate() {
      return validateForm(
        this.formSocialMedia,
        this.errorsSocialMedia,
        ValidationSchemaSocialMedia,
        this.showErrors
      );
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}`,
          payload: {
            json: { organization: { social_media_links: this.addHttpProtocol(this.requestBody(this.formSocialMedia)) } }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.showErrors = false;
          if (this.modelPluralize === 'organizations') {
            this.formSocialMedia =
              this.convertSocialMediaData(response.data?.organization?.social_media_links || []);
            this.$emit('save-model', response.data.organization);
          }
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
    discardChanges() {
      this.showErrors = false;
      this.formSocialMedia =
        this.convertSocialMediaData(this.model?.social_media_links || []);
      this.validate();
    },
    addSocialLink() {
      this.formSocialMedia =
        [ ...this.formSocialMedia, {name: '', value: ''} ];
    },
    deleteRow(item) {
      this.formSocialMedia.splice(
        this.formSocialMedia.findIndex((a) => a.name === item.name) , 1
      );
    },
    deleteVideo() {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/video`,
        httpVerb: 'delete'
      })
      .then(() => {
        this.aboutVideoUrl = null;
        this.video = null;
        this.$refs.pond.removeFile();
        this.$emit('save-model', { about_video: null, about_video_url: null, videos: [] });
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => (this.loading = false));
    },
    addVideo(error, file) {
      this.video = error ? null : file?.file;
    },
    uploadVideoModalClosed() {
      this.aboutVideoUrl = null;
      this.video = null;
      this.$refs.pond.removeFile();
      this.errorMessageFromBackendVideo = '';
      this.showErrors = false;
      this.uploadVideoModalOpen = false;
    },
    validateAboutVideoUrl() {
      return validateForm(this.aboutVideoUrl,
        this.errorAboutVideoUrl,
        ValidationAboutVideoLink,
        this.showErrors && this.aboutVideoUrl
      );
    },
    async saveVideo() {
      this.showErrors = true;
      const formData = new FormData();
      if (this.video) {
        formData.append('about_video', this.video);
      }

      const valid = await this.validateAboutVideoUrl();
      if (valid) {
        formData.append('about_video_url', this.aboutVideoUrl);
      }

      if (this.video || valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/video`,
          payload: {
            body: formData,
            // Remove json content type so browser can set the appropriate formdata content type
            headers: {
              'Content-Type': undefined
            }
          },
          httpVerb: 'post'
        })
        .then((data) => {
          this.errorMessageFromBackendVideo = '';
          this.aboutVideoUrl = null;
          this.video = null;
          this.$refs.pond.removeFile();
          this.showErrors = false;
          this.uploadVideoModalOpen = false;
          this.$emit('save-model', data?.data);
        })
        .catch((error) => {
          this.errorMessageFromBackendVideo = error?.error?.message || 'Error uploading video.';
        })
        .finally(() => (this.loading = false));
      }
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>

<style scoped>
.saveCancelContainer {
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

</style>
