import { fallbackApi } from '../api/config';
import { nextTick } from 'vue';

export const searchResultsMixin = {
  props: {
    search: {
      type: String,
      required: false,
      default() {
        return '';
      }
    }
  },
  emits: ['tabChanged'],
  data() {
    return {
      results: {},
      allResults: [],
      paginatedData: [],
      loading: true,
      page: 1,
      itemsPerPage: 10
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.fetchResults();
  },
  methods: {
    tabChanged(tab) {
      this.$emit('tabChanged', tab);
    },
    handleScroll({ target: { scrollingElement: { scrollTop, clientHeight, scrollHeight } }}) {
      if (scrollHeight - scrollTop - clientHeight <= 50) {
        this.loadMore();
      }
    },
    loadMore() {
      if (
        this.results[this.tab] &&
        (this.page === 1 || this.results[this.tab]?.length !== this.paginatedData.length)
      ) {
        this.paginatedData = this.results[this.tab].slice(0, this.itemsPerPage * this.page);
        this.page++;
        if (this.tab === 'top_news') {
          nextTick(() => {
            window.TopNewsHelper.setupPostButtons();
          });
        }
      }
    },
    fetchResults() {
      this.loading = true;

      fallbackApi({
        url: `search?model=${this.tab}&search=${this.search}`,
        dataSetter: (data) => {
          this.results = data || {};
          if (this.tab === 'organizations' || this.tab === 'projects') {
            this.allResults = this.results[this.tab] || [];
          }

          this.page = 1;
          this.paginatedData = [];
          this.loadMore();
        },
        loadingSetter: (loading) => this.loading = loading
      });
    }
  }
};
