<template>
  <div>
    <div class="expandMapIcon grayscaleOffBlack">
      <a :href="mapLink" aria-label="Open map">
        <i class="far fa-expand-arrows" aria-hidden="true" />
      </a>
    </div>
    <div v-show="showSitesLink" class="DesktopLinkSmall sitesMapLink text-center">
      <a :href="`/${modelPluralize}/${modelId}/sites`" class="grayscaleOffBlack">
        Show sites detail
      </a>
    </div>
    <div id="mapPreview" style="height: 340px" />
  </div>
</template>

<script>
import { loadModules } from 'esri-loader';
import { pointSymbolConfig } from '../../../features/map/helpers/consts';

export default {
  name: 'MapPreview',
  data() {
    return {
      location: null,
      model: null,
      modelPluralize: null,
      modelId: null,
      showSitesLink: false
    };
  },
  computed: {
    mapLink() {
      if (this.location) {
        return `/map?lat=${this.location.lat}&lon=${this.location.lon}`;
      } else {
        return '/map';
      }
    }
  },
  beforeMount() {
    const itemMapDiv = document.querySelector('#item-map');
    this.location = JSON.parse(itemMapDiv?.getAttribute('data-location'));
    this.model = itemMapDiv?.dataset?.model;
    this.modelPluralize = itemMapDiv?.dataset?.modelPluralize;
    this.modelId = itemMapDiv?.dataset?.modelId;
    this.showSitesLink = itemMapDiv?.dataset?.showSitesLink;
  },
  mounted() {
    loadModules([
      'esri/views/MapView',
      'esri/WebMap',
      'esri/config',
      'esri/Graphic'
    ])
      .then(([
        MapView,
        WebMap,
        esriConfig,
        Graphic
      ]) => {
        const arkMap = new WebMap({
          portalItem: {
            id: '2db26f96006441aaad38e93a318e0c38'
          }
        });
        const view = new MapView({
          map: arkMap,
          // Longitude, latitude
          center: this.location ? [this.location.lon, this.location.lat] : [10, 40],
          zoom: this.location ? 4 : 1,
          container: 'mapPreview',
          constraints: {
            minZoom: 1
          }
        });

        /**
         * Move and add widgets on the map viewport
         */
        view.ui.empty('top-left');

        /**
         * If the item has a location
         * create a point from it and add it to the map
         */
        if (!this.location) return;

        const point = new Graphic({
          geometry: {
            type: 'point',
            latitude: this.location.lat,
            longitude: this.location.lon
          },
          symbol: pointSymbolConfig
        });

        view.graphics.add(point);
      })
      .catch((err) => {
        // handle any errors
        Sentry && Sentry.captureException(err);
        console.error(err);
      });
  }
};
</script>

<style scoped>
:deep(.esri-attribution) {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}
:deep(.esri-attribution__link) {
  color: white !important;
}

.expandMapIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  font-size: 24px;
}

.expandMapIcon a {
  color: #d7dec0 !important;
}

.sitesMapLink {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.sitesMapLink a {
  background-color: white;
  border-radius: 8px;
  padding: 3px 8px;
}

.sitesMapLink a:hover {
  color: #24422B !important;
}
</style>
