<template>
  <main class="row justify-content-center mx-0">
    <form class="col-12 col-lg-10 arkCard p-4">
      <info-notice
        v-show="user"
        class="py-3 mb-4"
      >
        <template #notice>
          Created new regular user {{ user?.full_name }}<br>
          with email: <strong class="DesktopLinkSmall">{{ user?.email }}</strong>
          and password: <strong class="DesktopLinkSmall">{{ user?.password }}</strong>.<br>
          This is the only time you will see this message for security purposes. The password
          won't be shown again so please copy paste this somewhere safe to send it to the user.
        </template>
      </info-notice>

      <fieldset>
        <legend class="sr-only">User info:</legend>
        <div class="row">
          <div class="col-12 col-sm-6">
            <label
              for="firstNameInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              First name *
            </label>
            <input
              id="firstNameInput"
              v-model="form.first_name"
              type="text"
              class="form-control ark DesktopTextSmall"
              required
              aria-errormessage="firstNameError"
              :aria-invalid="!!errors.first_name"
              :class="{ notValid: errors.first_name }"
              @blur="validate"
            >
            <error-message
              id="firstNameError"
              :error-condition="!!errors.first_name"
              :error-message="errors.first_name"
            />
          </div>
          <div class="col-12 col-sm-6">
            <label
              for="lastNameInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Last name *
            </label>
            <input
              id="lastNameInput"
              v-model="form.last_name"
              type="text"
              class="form-control ark DesktopTextSmall"
              required
              aria-errormessage="lastNameError"
              :aria-invalid="!!errors.last_name"
              :class="{ notValid: errors.last_name }"
              @blur="validate"
            >
            <error-message
              id="lastNameError"
              :error-condition="!!errors.last_name"
              :error-message="errors.last_name"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label
              for="emailInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Email *
            </label>
            <input
              id="emailInput"
              v-model="form.email"
              type="email"
              class="form-control ark DesktopTextSmall"
              required
              aria-errormessage="emailError"
              :aria-invalid="!!errors.email"
              :class="{ notValid: errors.email }"
              @blur="validate"
            >
            <error-message
              id="emailError"
              :error-condition="!!errors.email"
              :error-message="errors.email"
            />
          </div>
          <div class="col-12 col-sm-6">
            <label
              for="phoneInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Phone number
            </label>
            <input
              id="phoneInput"
              v-model="form.phone_number"
              type="text"
              class="form-control ark DesktopTextSmall"
              aria-errormessage="phoneError"
              :aria-invalid="!!errors.phone_number"
              :class="{ notValid: errors.phone_number }"
              @blur="validate"
            >
            <error-message
              id="phoneError"
              :error-condition="!!errors.phone_number"
              :error-message="errors.phone_number"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12 col-sm-6">
            <label
              for="groupInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Stakeholder *
            </label>
            <Multiselect
              v-model="form.user_group"
              class="multiselect-ark"
              :options="userGroups"
              aria-label="Select user group"
              aria-errormessage="groupError"
              :close-on-select="true"
              :searchable="true"
              required
              :aria-invalid="!!errors.user_group"
              :class="{ 'multiselect-ark-error': errors.user_group }"
            />
            <error-message
              id="groupError"
              :error-condition="!!errors.user_group"
              :error-message="errors.user_group"
            />
          </div>
          <div class="col-12 col-sm-6">
            <label
              for="roleInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Role
            </label>
            <Multiselect
              v-model="form.role"
              class="multiselect-ark"
              :options="userRoles"
              aria-label="Select user role"
              aria-describedby="roleHint"
              aria-errormessage="roleError"
              :close-on-select="true"
              :searchable="true"
              :aria-invalid="!!errors.role"
              :class="{ 'multiselect-ark-error': errors.role }"
            />
            <span
              id="roleHint"
              class="DesktopTextXSmall grayscaleLabel d-block"
            >
              Set Role to "admin" to create Organisation Administrator User
            </span>
            <error-message
              id="roleError"
              :error-condition="!!errors.role"
              :error-message="errors.role"
            />
          </div>
        </div>
      </fieldset>

      <hr class="my-4">
      <div class="text-right">
        <div
          v-if="loading"
          class="spinner-border primary2_ark"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <button
          v-else
          type="submit"
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            primaryButton
          "
          @click="submit"
        >
          Save
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import InfoNotice from '../../../../components/shared/InfoNotice.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  phone_number: yup.string().nullable(),
  user_group: yup.string().nullable().required(),
  role: yup.string().nullable()
});

export default {
  components: { ErrorMessage, InfoNotice, Multiselect },
  data() {
    return {
      userGroups: [],
      userRoles: [],
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        user_group: '',
        role: ''
      },
      errors: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        user_group: '',
        role: ''
      },
      submitted: false,
      loading: false,
      errorMessageFromBackend: '',
      user: null
    };
  },
  beforeMount() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      const info = document.querySelector('#new-user');
      this.userGroups = JSON.parse(info?.dataset?.userGroups);
      this.userRoles = JSON.parse(info?.dataset?.userRoles);
    },
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.submitted);
    },
    async submit() {
      this.submitted = true;
      this.loading = true;
      const valid = await this.validate();
      if (valid) {
        fallbackApi({
          url: 'users',
          httpVerb: 'post',
          payload: {
            json: {
              user: this.form
            }
          },
          dataSetter: (data) => {
            this.user = data.user;
            this.form = {
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              user_group: '',
              role: ''
            };
            this.submitted = false;
          },
          errorSetter: (error) => {
            this.errorMessageFromBackend = error?.error?.message || 'Error creating organisation.';
          },
          loadingSetter: (loading) => this.loading = loading
        });
      } else {
        this.loading = false;
      }
    }
  }
};
</script>
