<template>
  <section>
    <div v-if="Object.keys(project).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <project-header
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :project="project"
        :project-type="projectType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
      />
    </div>
    <div>
      <risk-heatmap
        v-if="risks.length !== 0"
        :risks="risks"
        :likelihood-options="likelihoodOptions"
        :severity-options="severityOptions"
      />
      <div v-if="loading">
        <loading-indicator
          :skeletons="[
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'},
            {width: null, height: '20px'}
          ]"
        />
      </div>
      <div v-else>
        <risk-card :risks="risks" :entity-id="modelId" @risks-changed="deleteRisk" />
      </div>
    </div>
  </section>
</template>

<script>
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ProjectHeader from '../../sharedComponents/ProjectHeader.vue';
import RiskCard from './RiskCard.vue';
import RiskHeatmap from './RiskHeatmap.vue';
import { fallbackApi } from '../../../../api/config';
import { modelMixin } from '../../../../mixins';

export default {
  components: { ProjectHeader, LoadingIndicator, RiskCard, RiskHeatmap },
  mixins: [modelMixin],
  data() {
    return {
      likelihoodOptions: [],
      severityOptions: [],
      risks: [],
      loading: true
    };
  },
  mounted() {
    this.fetchRisks();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#project-risks');
      this.likelihoodOptions = Object.values(JSON.parse(modelInfo.dataset.likelihoodOptions));
      this.severityOptions = Object.values(JSON.parse(modelInfo.dataset.severityOptions));
      this.getModelDetails(modelInfo);
    },
    fetchRisks() {
      fallbackApi({
          url: `projects/${this.modelId}/risks`,
          dataSetter: (data) => this.risks = data.risks
        })
        .finally(() => this.loading = false);
    },
    deleteRisk(id) {
      this.risks = this.risks.filter((risk) => risk.id !== id);
    }
  }
};
</script>
