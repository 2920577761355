// Species multiselect rendering
if (typeof organization_species !== 'undefined'){
  var organization_species_values = [];
  for (const species_key in organization_species) {
    organization_species_values.push(organization_species[species_key])
  }
  if ($('#organization_species').length > 0) {
    $('#organization_species').val(organization_species_values);
  }
}

// Species projects multiselect
if (typeof project_species !== 'undefined' && document.querySelector('#project_species')) {
  $('#project_species').val(project_species);
}
// Categories projects multiselect
if (typeof project_categories !== 'undefined' && document.querySelector('#project_categories')) {
  $('#project_categories').val(project_categories);
}
// Tags projects multiselect
if (typeof project_tags !== 'undefined' && document.querySelector('#project_tags')) {
  $('#project_tags').val(project_tags);
}

// Update org logo preview
$('#org__logo').change(function(event) {
  if (event.originalEvent.srcElement.files && event.originalEvent.srcElement.files[0]) {
    var reader = new FileReader();
    reader.onload = function(e) {
      if ($('#org__logo_img').length > 0){
        $('#org__logo_img')
            .attr('src', e.target.result)
            .width(100)
      } else {
        var image = document.createElement("img");
        image.src = e.target.result;
        image.style.width = "100px";
        image.className = "img_container";
        image.id = "org__logo_img";
        document.getElementById('org__logo_img_container').appendChild(image);
      }

    };
    reader.readAsDataURL(event.originalEvent.srcElement.files[0]);
  }
})


// Wizard functionality

// Form table functionality

/////////////////////////
// Organisation tables //
/////////////////////////

// Helper functions
function clear_input_fields(input_dom_elements) {
  input_dom_elements.forEach(element => element.val(""));
}

function create_UUID(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

///////////////////
// Employee table//
///////////////////
var org_employees_table = $("#form__org_employees");
var org_employee_count = org_employees_table.find("tbody").children().length;
// Add table row
$( "#form__add_org_employee").click(function() {
  $("#form__add_org_employee").prop("disabled", true);

  org_employees_table = $("#form__org_employees");
  var org__employee__name = $("#org__employee__name");
  var org__employee__id = $("#org__employee__id");
  var org__employee__link = $("#org__employee__link");
  var new_employee_id = "org__employee_" + create_UUID();

  org_employees_table.find("tr:last").after(
  	`<tr id="${new_employee_id}">`+
    `<td class="org__employee__id_cell">
      <span class="bmd-form-group">
        <a href="${org__employee__link.val()}" target="_blank" rel="noopener noreferrer">${org__employee__name.val()}</a>
        <input name="organization[employees][]" value="${org__employee__id.val()}" hidden>
      </span>
    </td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link employee_delete" id="${new_employee_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
  	'</tr>'
  	);

  org_employees_table.find("thead").removeClass("invisible");
  org_employee_count += 1;
  clear_input_fields([
  	org__employee__name,
    org__employee__id,
    org__employee__link
  	])

  addRemoveEmployeeEventListener();
});

function addRemoveEmployeeEventListener() {
  $('.employee_delete').off('click');
  $('.employee_delete').on('click', (evt) => {
    const deleteBtn = evt.currentTarget;
    $(deleteBtn).closest('tr').remove();
    org_employee_count -= 1;
    updateEmployeeTableHead();
    evt.stopPropagation(); // No bubbling
  });
}
addRemoveEmployeeEventListener();

// Hide table head if there are no rows
function updateEmployeeTableHead() {
  if (org_employee_count === 0){
    org_employees_table.find("thead").addClass("invisible");
  }
}

///////////////////
// Partner table //
///////////////////
var form__org_partners = $("#form__org_partners");
var org_partners_count = form__org_partners.find("tbody").children().length;
// Add table row
$( "#form__add_org_partner").click(function() {
  form__org_partners = $("#form__org_partners");
  var org__partner__name = $("#org__partner__name");
  var org__partner__website = $("#org__partner__website");
  var org__partner__contact_name = $("#org__partner__contact_name");
  var org__partner__email = $("#org__partner__email");
  var new_id = "org__partner_" + create_UUID();

  form__org_partners.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<td class="org__partner__name_cell"><input name="organization[technology_partners][][company_name]" type="text" class="form-control table-input" value="${org__partner__name.val()}"/></td>`+
    `<td class="org__partner__website_cell"><input name="organization[technology_partners][][website]" type="url" class="form-control table-input" value="${org__partner__website.val()}"/></td>`+
    `<td class="org__partner__li_cell"><input name="organization[technology_partners][][contact_name]" type="text" class="form-control table-input" value="${org__partner__contact_name.val()}"/></td>`+
    `<td class="org__partner__email_cell text-right"><input name="organization[technology_partners][][email]" type="email" class="form-control table-input" value="${org__partner__email.val()}"/></td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link partner_delete" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
  	);

  form__org_partners.find("thead").removeClass("invisible");
  org_partners_count += 1;
  clear_input_fields([
  	org__partner__name,
  	org__partner__website,
  	org__partner__contact_name,
  	org__partner__email,
  	])

  addRemovePartnerEventListener();
});

function addRemovePartnerEventListener() {
  $('.partner_delete').on('click', (evt) => {
    const deleteBtn = evt.currentTarget;
    $(deleteBtn).closest('tr').remove();
    org_employee_count -= 1;
    updateEmployeeTableHead();
    evt.stopPropagation(); // No bubbling
  });
}
addRemovePartnerEventListener();

// Hide table head if there are no rows
function updatePartnerTableHead() {
  if (org_partners_count === 0){
    form__org_partners.find("thead").addClass("invisible");
  }
}

///////////////////////
// Documentary table //
///////////////////////
var form__rec_films = $("#form__rec_films");
var rec_films_count = form__rec_films.find("tbody").children().length;
// Add table row
$( "#form__add_film").click(function() {
  //Check if required fields are valid
  var invalidField = null;
  $('#form__rec_new_film').find('[required]').each(function() {
    if(!this.validity.valid) {
      invalidField = this;
      return false;
    }
  });

  if(invalidField) {
    invalidField.focus();
    return false;
  }

  form__rec_films = $("#form__rec_films");
  var rec__film__title = $("#rec__film__title");
  var rec__film__directors = $("#rec__film__directors");
  var rec__film__year = $("#rec__film__year");
  var rec__film__url = $("#rec__film__url");
  var rec__film__yt = $("#rec__film__yt");
  var rec__film__imdb = $("#rec__film__imdb");
  var new_id = "rec__film_" + create_UUID();
  var checked_species = $('#rec__film__species input:checked');
  var values_for_species = [];
  checked_species.each((s) => values_for_species.push(`<input type="hidden" name="recommendation[films_attributes][][species][]" value="${checked_species[s].value}" />`));
  form__rec_films.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<input type="hidden" name="recommendation[films_attributes][][release_date]" value="${rec__film__year.val()}" />`+
    `<td class="rec__film__title_cell"><input name="recommendation[films_attributes][][title]" type="text" class="form-control table-input" value="${rec__film__title.val()}"/></td>`+
    `<td class="rec__film__directors_cell"><input name="recommendation[films_attributes][][directors]" type="text" class="form-control table-input" value="${rec__film__directors.val()}"/></td>`+
    `<td class="rec__film__url_cell"><input name="recommendation[films_attributes][][film_url]" type="text" class="form-control table-input" value="${rec__film__url.val()}"/></td>`+
    `<td class="rec__film__yt_cell text-right"><input name="recommendation[films_attributes][][youtube_url]" type="text" class="form-control table-input" value="${rec__film__yt.val()}"/></td>`+
    `<td class="rec__film__imdb_cell text-right">`+
    `<input name="recommendation[films_attributes][][imdb_url]" type="text" class="form-control table-input" value="${rec__film__imdb.val()}"/>`+
    '</td>' +
    values_for_species.join('') +
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__rec_films.find("thead").removeClass("invisible");
  rec_films_count += 1;
  clear_input_fields([
    rec__film__title,
    rec__film__directors,
    rec__film__year,
    rec__film__url,
    rec__film__yt,
    rec__film__imdb,
    ])
  // Clear checkboxes
  checked_species.prop("checked", false);
  addRemoveFilmEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveFilmEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    rec_films_count -= 1;
    updateFilmTableHead();
  })
}
// Remove pre existing table row on delete
$('.rec__film_delete').click(function() {
  $(this).closest("tr").remove();
  rec_films_count -= 1;
  updateFilmTableHead();
})

// Hide table head if there are no rows
function updateFilmTableHead() {
  if (rec_films_count === 0){
    form__rec_films.find("thead").addClass("invisible");
  }
}

/////////////////
// Books table //
/////////////////
var form__rec_books = $("#form__rec_books");
var rec_books_count = form__rec_books.find("tbody").children().length;
// Add table row
$( "#form__add_book").click(function() {
  //Check if required fields are valid
  var invalidField = null;
  $('#form__rec_new_book').find('[required]').each(function() {
    if(!this.validity.valid) {
      invalidField = this;
      return false;
    }
  });

  if(invalidField) {
    invalidField.focus();
    return false;
  }

  form__rec_books = $("#form__rec_books");
  var rec__book__title = $("#rec__book__title");
  var rec__book__author = $("#rec__book__author");
  var rec__book__year = $("#rec__book__year");
  var rec__book__url = $("#rec__book__url");
  var rec__book__publisher = $("#rec__book__publisher");
  var rec__book__isbn = $("#rec__book__isbn");
  var new_id = "rec__book_" + create_UUID();
  var checked_species = $('#rec__book__species input:checked');
  var values_for_species = [];
  checked_species.each((s) => values_for_species.push(`<input type="hidden" name="recommendation[books_attributes][][species][]" value="${checked_species[s].value}" />`));

  form__rec_books.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<input type="hidden" name="recommendation[books_attributes][][release_date]" value="${rec__book__year.val()}" />`+
    `<td class="rec__book__title_cell"><input name="recommendation[books_attributes][][title]" type="text" class="form-control table-input" value="${rec__book__title.val()}"/></td>`+
    `<td class="rec__book__author_cell"><input name="recommendation[books_attributes][][author]" type="text" class="form-control table-input" value="${rec__book__author.val()}"/></td>`+
    `<td class="rec__book__url_cell"><input name="recommendation[books_attributes][][book_url]" type="text" class="form-control table-input" value="${rec__book__url.val()}"/></td>`+
    `<td class="rec__book__publisher_cell text-right"><input name="recommendation[books_attributes][][publisher]" type="text" class="form-control table-input" value="${rec__book__publisher.val()}"/></td>`+
    `<td class="rec__book__isbn_cell text-right">`+
    `<input name="recommendation[books_attributes][][isbn_number]" type="text" class="form-control table-input" value="${rec__book__isbn.val()}"/>`+
    values_for_species.join('') + '</td>' +
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__rec_books.find("thead").removeClass("invisible");
  rec_books_count += 1;
  clear_input_fields([
    rec__book__title,
    rec__book__author,
    rec__book__year,
    rec__book__url,
    rec__book__publisher,
    rec__book__isbn,
    ])
  // Clear checkboxes
  checked_species.prop("checked", false);
  addRemoveBookEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveBookEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    rec_books_count -= 1;
    updateBookTableHead();
  })
}
// Remove pre existing table row on delete
$('.rec__book_delete').click(function() {
  $(this).closest("tr").remove();
  rec_books_count -= 1;
  updateBookTableHead();
})
// Hide table head if there are no rows
function updateBookTableHead() {
  if (rec_books_count === 0){
    form__rec_books.find("thead").addClass("invisible");
  }
}

///////////////////
// Podcast table //
///////////////////
var form__rec_podcasts = $("#form__rec_podcasts");
var rec_podcast_count = form__rec_podcasts.find("tbody").children().length;
// Add table row
$( "#form__add_podcast").click(function() {
  //Check if required fields are valid
  var invalidField = null;
  $('#form__rec_new_podcast').find('[required]').each(function() {
    if(!this.validity.valid) {
      invalidField = this;
      return false;
    }
  });

  if(invalidField) {
    invalidField.focus();
    return false;
  }

  form__rec_podcasts = $("#form__rec_podcasts");
  var rec__pod__title = $("#rec__pod__title");
  var rec__pod__publisher = $("#rec__pod__publisher");
  var rec__pod__website = $("#rec__pod__website");
  var rec__pod__itunes = $("#rec__pod__itunes");
  var new_id = "rec__pod_" + create_UUID();
  var checked_species = $('#rec__pod__species input:checked');
  var values_for_species = [];
  checked_species.each((s) => values_for_species.push(`<input type="hidden" name="recommendation[podcasts_attributes][][species][]" value="${checked_species[s].value}" />`));

  form__rec_podcasts.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<td class="rec__pod__title_cell"><input name="recommendation[podcasts_attributes][][title]" type="text" class="form-control table-input" value="${rec__pod__title.val()}"/></td>`+
    `<td class="rec__pod__publisher_cell"><input name="recommendation[podcasts_attributes][][publisher]" type="text" class="form-control table-input" value="${rec__pod__publisher.val()}"/></td>`+
    `<td class="rec__pod__website_cell"><input name="recommendation[podcasts_attributes][][podcast_url]" type="text" class="form-control table-input" value="${rec__pod__website.val()}"/></td>`+
    `<td class="rec__pod__itunes_cell text-right"><input name="recommendation[podcasts_attributes][][itunes_id]" type="text" class="form-control table-input" value="${rec__pod__itunes.val()}"/></td>`+
    values_for_species.join('') +
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__rec_podcasts.find("thead").removeClass("invisible");
  rec_podcast_count += 1;
  clear_input_fields([
    rec__pod__title,
    rec__pod__publisher,
    rec__pod__website,
    rec__pod__itunes,
    ])
  // Clear checkboxes
  checked_species.prop("checked", false);
  addRemovePodcastEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemovePodcastEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    rec_podcast_count -= 1;
    updatePodcastTableHead();
  })
}
// Remove pre existing table row on delete
$('.rec__podcast_delete').click(function() {
  $(this).closest("tr").remove();
  rec_podcast_count -= 1;
  updatePodcastTableHead();
})
// Hide table head if there are no rows
function updatePodcastTableHead() {
  if (rec_podcast_count === 0){
    form__rec_podcasts.find("thead").addClass("invisible");
  }
}


//////////////////////////////////////////
// Publications table (recommendations) //
//////////////////////////////////////////

var form__rec_pubs = $("#form__rec_pubs");
var rec_pubs_count = form__rec_pubs.find("tbody").children().length;
// Add table row
$( "#form__add_rec__pub").click(function() {
  //Check if required fields are valid
  var invalidField = null;
  $('#form__rec_new_pub').find('[required]').each(function() {
    if(!this.validity.valid) {
      invalidField = this;
      return false;
    }
  });

  if(invalidField) {
    invalidField.focus();
    return false;
  }

  form__rec_pubs = $("#form__rec_pubs");
  var rec__pub__title = $("#rec__pub__title");
  var rec__pub__author = $("#rec__pub__author");
  var rec__pub__publisher = $("#rec__pub__publisher");
  var rec__pub__date = $("#rec__pub__date");
  var rec__pub__volume = $("#rec__pub__volume");
  var rec__pub__issue = $("#rec__pub__issue");
  var rec__pub__series = $("#rec__pub__series");
  var rec__pub__page_start = $("#rec__pub__page_start");
  var rec__pub__page_end = $("#rec__pub__page_end");
  var rec__pub__doi = $("#rec__pub__doi");
  var rec__pub__url = $("#rec__pub__url");
  var new_id = "rec__pub_" + create_UUID();
  var checked_species = $('#rec__pub__species input:checked');
  var values_for_species = [];
  checked_species.each((s) => values_for_species.push(`<input type="hidden" name="recommendation[papers_attributes][][species][]" value="${checked_species[s].value}" />`));

  form__rec_pubs.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<input type="hidden" name="recommendation[papers_attributes][][release_date]" value="${rec__pub__date.val()}" />`+
    `<td class="rec__pub__title_cell"><input name="recommendation[papers_attributes][][title]" type="text" class="form-control table-input" value="${rec__pub__title.val()}"/></td>`+
    `<td class="rec__pub__author_cell"><input name="recommendation[papers_attributes][][authors]" type="text" class="form-control table-input" value="${rec__pub__author.val()}"/></td>`+
    `<td class="rec__pub__publisher_cell"><input name="recommendation[papers_attributes][][journal]" type="text" class="form-control table-input" value="${rec__pub__publisher.val()}"/></td>`+
    `<td class="rec__pub__volume_cell text-right"><input name="recommendation[papers_attributes][][volume]" type="text" class="form-control table-input" value="${rec__pub__volume.val()}"/></td>`+
    `<td class="rec__pub__issue_cell"><input name="recommendation[papers_attributes][][issue]" type="text" class="form-control table-input" value="${rec__pub__issue.val()}"/></td>`+
    `<td class="rec__pub__series_cell"><input name="recommendation[papers_attributes][][series]" type="text" class="form-control table-input" value="${rec__pub__series.val()}"/></td>`+
    `<td class="rec__pub__page_start_cell"><input name="recommendation[papers_attributes][][page_start]" type="text" class="form-control table-input" value="${rec__pub__page_start.val()}"/></td>`+
    `<td class="rec__pub__page_end_cell text-right"><input name="recommendation[papers_attributes][][page_end]" type="text" class="form-control table-input" value="${rec__pub__page_end.val()}"/></td>`+
    `<td class="rec__pub__doi_cell text-right"><input name="recommendation[papers_attributes][][doi]" type="text" class="form-control table-input" value="${rec__pub__doi.val()}"/></td>`+
    `<td class="rec__pub__url_cell text-right"><input name="recommendation[papers_attributes][][paper_url]" type="text" class="form-control table-input" value="${rec__pub__url.val()}"/></td>`+
    values_for_species.join('') +
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__rec_pubs.find("thead").removeClass("invisible");
  rec_pubs_count += 1;
  clear_input_fields([
    rec__pub__title,
    rec__pub__author,
    rec__pub__publisher,
    rec__pub__date,
    rec__pub__volume,
    rec__pub__issue,
    rec__pub__series,
    rec__pub__page_start,
    rec__pub__page_end,
    rec__pub__doi,
    rec__pub__url,
    ])
  // Clear checkboxes
  checked_species.prop("checked", false);
  addRemoveRecPubEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveRecPubEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    rec_pubs_count -= 1;
    updateRecPubTableHead();
  })
}
// Remove pre existing table row on delete
$('.rec__pub_delete').click(function() {
  $(this).closest("tr").remove();
  rec_pubs_count -= 1;
  updateRecPubTableHead();
})
// Hide table head if there are no rows
function updateRecPubTableHead() {
  if (rec_pubs_count === 0){
    form__rec_pubs.find("thead").addClass("invisible");
  }
}

//////////////////////
// Milestones table //
//////////////////////
var form__project_milestones = $("#form__project_milestones");
var project_milestone_count = form__project_milestones.find("tbody").children().length;
// Add table row
$( "#form__add_milestone").click(function() {
  form__project_milestones = $("#form__project_milestones");
  var project__milestone_name = $("#project__milestone_name");
  var project__milestone_end_date = $("#project__milestone_end_date");
  var project__milestone_summary = $("#project__milestone_summary");

  var new_id = "project__milestone_" + create_UUID();

  form__project_milestones.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<td class="project__milestone_name_cell"><input type="text" name="project[milestones][][name]" class="form-control table-input" value="${project__milestone_name.val()}"/></td>`+
    `<td class="project__milestone_end_date_cell"><input type="text" name="project[milestones][][date]" class="form-control table-input" value="${project__milestone_end_date.val()}"/></td>`+
    `<td class="project__milestone_summary_cell"><input type="text" name="project[milestones][][description]" class="form-control table-input" value="${project__milestone_summary.val()}"/></td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__project_milestones.find("thead").removeClass("invisible");
  project_milestone_count += 1;
  clear_input_fields([
    project__milestone_name,
    project__milestone_summary,
    ])

  addRemoveMilestoneEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveMilestoneEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    project_milestone_count -= 1;
    updateMilestoneTableHead();
  })
}

// Hide table head if there are no rows
function updateMilestoneTableHead() {
  if (project_milestone_count === 0){
    form__project_milestones.find("thead").addClass("invisible");
  }
}

//////////////////////////
// Project members table//
//////////////////////////
var form__project_members = $("#form__project_members");
var project_members_count = form__project_members.find("tbody").children().length;
// Add table row
$( "#form__add_project_member").click(function() {
  $("#form__add_project_member").prop("disabled", true);

  form__project_members = $("#form__project_members");
  var project__member__name = $("#project__member__name");
  var project__member__id = $("#project__member__id");
  var project__member__link = $("#project__member__link");
  var new_id = "project__member_" + create_UUID();

  form__project_members.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<td class="org__employee__id_cell">
      <span class="bmd-form-group">
        <a href="${project__member__link.val()}" target="_blank" rel="noopener noreferrer">${project__member__name.val()}</a>
        <input name="project[project_members][]" value="${project__member__id.val()}" hidden>
      </span>
    </td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__project_members.find("thead").removeClass("invisible");
  project_members_count += 1;
  clear_input_fields([
    project__member__name,
    project__member__id,
    project__member__link
    ])

  addRemoveProjectMemberEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveProjectMemberEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    project_members_count -= 1;
    updateProjectMemberTableHead();
  })
}

// Hide table head if there are no rows
function updateProjectMemberTableHead() {
  if (project_members_count === 0){
    form__project_members.find("thead").addClass("invisible");
  }
}

///////////////////////////////////
// Publications table (projects) //
///////////////////////////////////

var form__project_pubs = $("#form__project_pubs");
var project_pubs_count = form__project_pubs.find("tbody").children().length;
// Add table row
$( "#form__add_project_pub").click(function() {
  form__project_pubs = $("#form__project_pubs");
  var project__pub_title = $("#project__pub_title");
  var project__pub_author = $("#project__pub_author");
  var project__pub_publisher = $("#project__pub_publisher");
  var project__pub_date = $("#project__pub_date");
  var project__pub_volume = $("#project__pub_volume");
  var project__pub_issue = $("#project__pub_issue");
  var project__pub_series = $("#project__pub_series");
  var project__pub_page_start = $("#project__pub_page_start");
  var project__pub_page_end = $("#project__pub_page_end");
  var project__pub_doi = $("#project__pub_doi");
  var project__pub_url = $("#project__pub_url");
  var checked_species = $('#project__pub__species input:checked');
  var values_for_species = [];
  checked_species.each((s) => values_for_species.push(`<input type="hidden" name="project[papers_attributes][][species][]" value="${checked_species[s].value}" />`));
  var new_id = "project__pub_" + create_UUID();

  form__project_pubs.find("tr:last").after(
    `<tr id="${new_id}">`+
    values_for_species.join('') +
    `<input type="hidden" name="project[papers_attributes][][release_date]" value="${project__pub_date.val()}" />`+
    `<td class="project__pub_title_cell"><input type="text" name="project[papers_attributes][][title]" class="form-control table-input" value="${project__pub_title.val()}"/></td>`+
    `<td class="project__pub_author_cell"><input type="text" name="project[papers_attributes][][authors]" class="form-control table-input" value="${project__pub_author.val()}"/></td>`+
    `<td class="project__pub_publisher_cell"><input type="text" name="project[papers_attributes][][journal]" class="form-control table-input" value="${project__pub_publisher.val()}"/></td>`+
    `<td class="project__pub_volume_cell text-right"><input type="text" name="project[papers_attributes][][volume]" class="form-control table-input" value="${project__pub_volume.val()}"/></td>`+
    `<td class="project__pub_issue_cell"><input type="text" name="project[papers_attributes][][issue]" class="form-control table-input" value="${project__pub_issue.val()}"/></td>`+
    `<td class="project__pub_series_cell"><input type="text" name="project[papers_attributes][][series]" class="form-control table-input" value="${project__pub_series.val()}"/></td>`+
    `<td class="project__pub_page_start_cell"><input type="text" name="project[papers_attributes][][page_start]" class="form-control table-input" value="${project__pub_page_start.val()}"/></td>`+
    `<td class="project__pub_page_end_cell"><input type="text" name="project[papers_attributes][][page_end]" class="form-control table-input" value="${project__pub_page_end.val()}"/></td>`+
    `<td class="project__pub_doi_cell"><input type="text" name="project[papers_attributes][][doi]" class="form-control table-input" value="${project__pub_doi.val()}"/></td>`+
    `<td class="project__pub_url_cell"><input type="url" name="project[papers_attributes][][paper_url]" class="form-control table-input" value="${project__pub_url.val()}"/></td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__project_pubs.find("thead").removeClass("invisible");
  project_pubs_count += 1;
  clear_input_fields([
    project__pub_title,
    project__pub_author,
    project__pub_publisher,
    project__pub_date,
    project__pub_volume,
    project__pub_issue,
    project__pub_series,
    project__pub_page_start,
    project__pub_page_end,
    project__pub_doi,
    project__pub_url,
    ])
  // Clear checkboxes
  checked_species.prop("checked", false);

  addRemoveProjectPubEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveProjectPubEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    project_pubs_count -= 1;
    updateProjectPubTableHead();
  })
}

// Hide table head if there are no rows
function updateProjectPubTableHead() {
  if (project_pubs_count === 0){
    form__project_pubs.find("thead").addClass("invisible");
  }
}

///////////////////////////
// Project partners table//
///////////////////////////
var form__project_partners = $("#form__project_partners");
var project_partners_count = form__project_partners.find("tbody").children().length;
// Add table row
$( "#form__add_project_partner").click(function() {
  form__project_partners = $("#form__project_partners");
  var project__partner_name = $("#project__partner_name");
  var project__partner_website = $("#project__partner_website");
  var project__partner_li = $("#project__partner_li");
  var project__partner_email = $("#project__partner_email");
  var new_id = "project__partner_" + create_UUID();

  form__project_partners.find("tr:last").after(
    `<tr id="${new_id}">`+
    `<td class="project__partner_name_cell"><input type="text" name="project[project_partners][][name]" class="form-control table-input" value="${project__partner_name.val()}"/></td>`+
    `<td class="project__partner_website_cell"><input type="text" name="project[project_partners][][website]" class="form-control table-input" value="${project__partner_website.val()}"/></td>`+
    `<td class="project__partner_li_cell"><input type="url" name="project[project_partners][][linkedin_link]" class="form-control table-input" value="${project__partner_li.val()}"/></td>`+
    `<td class="project__partner_email_cell"><input type="email" name="project[project_partners][][email]" class="form-control table-input" value="${project__partner_email.val()}"/></td>`+
    '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
    `<button type="button" rel="tooltip" class="btn btn-danger btn-link" id="${new_id+"_delete"}">`+
    '<i class="material-icons">delete</i>'+
    '</button>'+
    '</td>'+
    '</tr>'
    );

  form__project_partners.find("thead").removeClass("invisible");
  project_partners_count += 1;
  clear_input_fields([
    project__partner_name,
    project__partner_position,
    project__partner_li,
    project__partner_email,
    ])

  addRemoveProjectPartnerEventListenser(new_id+"_delete")
});

// Remove table row on delete
function addRemoveProjectPartnerEventListenser(dom_element_id){
  $('#' + dom_element_id).click(function() {
    $(this).closest("tr").remove();
    project_partners_count -= 1;
    updateProjectPartnerTableHead();
  })
}

// Hide table head if there are no rows
function updateProjectPartnerTableHead() {
  if (project_partners_count === 0){
    form__project_partners.find("thead").addClass("invisible");
  }
}

function showProjectDeleteAlert(html, redirect, redirectTo, projectId){
  new swal({
    html: html,
    showCancelButton: redirect,
    cancelButtonText: 'Cancel',
    cancelButtonClass: 'btn btn-default',
    confirmButtonClass: 'btn btn-secondary1-ark',
    buttonsStyling: false
  }).then(function(result) {
    if (result.isConfirmed && redirect) {
      fetch(`/projects/${projectId}`, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") }
      }).then((response) => {
        if(redirectTo) {
          window.location.replace(redirectTo);
        }
        else {
          window.location.reload();
        }
      }).catch((error) => {
        Sentry && Sentry.captureException(error)
      });
    } else {
      console.log('Aborting deletion');
    }
  }).catch(swal.noop);
}


$('.js-delete-project').on('click', (evt) => {
  const projectId = evt.currentTarget.dataset.projectId;
  const projectTitle = evt.currentTarget.dataset.projectTitle;
  const redirectTo = evt.currentTarget.dataset.redirectTo;
  const redirect = true;
  showProjectDeleteAlert(`Are you sure you want to delete project ${projectTitle}? This is a permanent change.`, redirect, redirectTo, projectId);
});
