<template>
  <section class="maxWidthInputs">
    <h4 class="grayscaleOffBlack DesktopLinkSmall mt-4">Sites</h4>
    <p class="grascaleLabel DesktopTextSmall">
      You can select a location on the map or upload a shape file.
      <a v-if="sites.length > 0" href="sites" class="DesktopLinkSmall warningDefault">
        View {{ model.toLowerCase() }} sites on map
      </a>
    </p>
    <button
      class="
        btn
        DesktopLinkXSmall
        grayscaleOffWhite
        primaryButton
      "
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i
        class="fas fa-plus"
        aria-hidden="true"
      />&nbsp;&nbsp;
      Add your site
    </button>
    <div class="d-flex justify-content-end">
      <div class="dropdown-menu dropdown-primary">
        <button
          class="dropdown-item w-100 m-0 pointer"
          @click="setLocation"
        >
          <i
            class="far fa-map"
            aria-hidden="true"
          />&nbsp;&nbsp;
          Find location on map
        </button>
        <button
          class="dropdown-item w-100 m-0 pointer"
          @click="uploadFileModalOpen = true"
        >
          <i
            class="far fa-cloud-upload"
            aria-hidden="true"
          />&nbsp;&nbsp;
          Upload area file
        </button>
      </div>
    </div>
    <ul class="listStyleNone p-0 mt-4">
      <li v-for="site in sites" :key="site.id" class="mb-2">
        <section class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="DesktopLinkSmall primaryDefault mb-1">{{ site.name }}</h5>
            <p class="DesktopTextSmall grayscaleBody">
              <span>{{ site.site_type_name }}</span>
              <span v-if="site.area"> - {{ formatArea(site.area) }}km<sup>2</sup></span>
              <error-message
                class="d-block mt-0 pl-0"
                :error-condition="!site.layer_url"
                error-message="There is an issue with this site. Please delete and reupload."
              />
            </p>
          </div>
          <div
            v-if="site.loading"
            class="spinner-border primary2_ark"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <button
            v-else
            class="btn btn-link p-0 m-0 grayscaleOffBlack"
            aria-label="Delete site"
            @click="deleteSite(site)"
          >
            <i class="fas fa-trash fa-lg" aria-hidden="true" />
          </button>
        </section>
      </li>
    </ul>
  </section>
  <upload-file-modal
    :modal-open="uploadFileModalOpen"
    :model-id="modelId"
    @modal-closed-event="closeFileUploadModal"
  />
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';
import UploadFileModal from './UploadFileModal.vue';
import { fallbackApi } from '../../api/config';
import { sitesMixin } from '../../mixins';

export default {
  components: { ErrorMessage, UploadFileModal },
  mixins: [sitesMixin],
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    refreshSites: {
      type: Boolean,
      required: true
    }
  },
  emits: ['set-location', 'upload-file'],
  data() {
    return {
      sites: [],
      uploadFileModalOpen: false
    };
  },
  watch: {
    refreshSites() {
      this.fetchSites();
    }
  },
  mounted() {
    this.fetchSites();
  },
  methods: {
    fetchSites() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sites`,
        dataSetter: (data) => this.sites = data.sites
      });
    },
    deleteSite(site) {
      site.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/sites/${site.id}`,
        httpVerb: 'delete',
        dataSetter: () => this.fetchSites()
      });
    },
    setLocation() {
      this.$emit('set-location');
    },
    closeFileUploadModal(file) {
      this.uploadFileModalOpen = false;
      if (file instanceof File) {
        this.$emit('upload-file', file);
      }
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>
