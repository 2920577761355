<template>
  <div class="row">
    <div class="col-md-12 ml-auto mr-auto">
      <div class="card card-signup no-shadow pt-3">
        <div class="card-body">
          <h2 class="DesktopDisplaySmall px-3 mb-4"> Subscription Management Hub </h2>
          <loading-indicator
            v-if="loading"
            :skeletons="[
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'},
              {width: null, height: '20px'}
            ]"
          />
          <table v-else class="table">
            <thead>
              <tr class="grayscaleBody">
                <th
                  v-for="header in headers"
                  :key="header.id"
                  :class="{'text-bold': header.sortable && sortBy === header.key }"
                  scope="col"
                  @click="header.sortable && sortByColumn(header.key)"
                >
                  <span class="DesktopTextSmall grayscaleLabel mr-2">{{ header.label }}</span>
                  <i
                    v-if="header.sortable && sortBy === header.key"
                    :class="`fa fa-arrow-${sortOrder === 'asc' ? 'up' : 'down'}`"
                    aria-hidden="true"
                  />
                </th>
              </tr>
            </thead>
            <tbody class="grayscaleOffBlack">
              <tr v-for="(row) in requests" :key="row.id">
                <td class="MobileTextSmall grayscaleOffBlack">{{ row.organization }}</td>
                <td class="MobileTextSmall grayscaleOffBlack">{{ formatDate(row.created_at) }}</td>
                <td class="MobileTextSmall grayscaleOffBlack">{{ row.description }}</td>
                <td class="MobileTextSmall grayscaleOffBlack">{{ formatPrice(row.total) }}</td>
                <td class="MobileTextSmall grayscaleOffBlack">
                  <button
                    class="
                    btn
                    primaryButton
                    DesktopLinkXSmall
                    primaryDarkmode
                  "
                    @click="approveRequest(row)"
                  >
                    Mark as Paid
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import { fallbackApi } from '../../../api/config';

export default {
  components: {
    LoadingIndicator
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          id: 1,
          key: 'organization',
          label: 'Organisation',
          sortable: true
        },
        {
          id: 2,
          key: 'created_at',
          label: 'At',
          sortable: true
        },
        {
          id: 3,
          key: 'description',
          label: 'Requests',
          sortable: true
        },
        {
          id: 4,
          key: 'total',
          label: 'Total',
          sortable: true
        },
        {
          id: 5,
          key: 'status',
          label: 'Status',
          sortable: false
        }
      ],
      subscriptions: [],
      sortBy: 'organization',
      sortOrder: 'asc',
      STATUS: {
        PENDING: 0,
        ACTIVE: 1,
        EXPIRED: 2
      }
    };
  },

  computed: {
    requests() {
      return this.subscriptions.reduce((rows, subscription) => {
        // Construct table rows for extra seats
        const extraSeats = subscription.seat_additions || [];
        if (extraSeats.length > 0) {
          // Construct table rows from grouped extra seats
          const seatRows = extraSeats.map((seatGroup) => {
            return {
              id: `${subscription.id}-${seatGroup.date}`,
              created_at: seatGroup.date,
              organization: subscription.organization_name,
              description: `${seatGroup.seats_count} Team Member Seat Addition`,
              total: parseFloat(seatGroup.price),
              status: seatGroup.status,
              type: 'seat',
              original_seats: seatGroup.seats_ids
            };
          }).filter((seatGroup) => seatGroup.status === this.STATUS.PENDING);
          rows = rows.concat(seatRows);
        }

        // Construct table row for subscription
        const subscriptionRow = {
          id: subscription.id,
          created_at: subscription.created_at,
          organization: subscription.organization_name,
          description: this.subscriptionDescription(subscription),
          total: parseFloat(subscription.type.price_yearly),
          status: subscription.status,
          type: 'sub'
        };
        // only add pending subs
        if (subscriptionRow?.status === this.STATUS.PENDING)
          rows.push(subscriptionRow);

        return rows;
      }, [])
      .sort((a, b) => {
        const modifier = this.sortOrder === 'asc' ? 1 : -1;
        // Implement sorting logic based on the key
        return (a[this.sortBy] < b[this.sortBy] ? -1 : 1) * modifier;
      });
    }
  },

  mounted() {
    this.fetchSubscriptionRequests();
  },
  methods: {
    fetchSubscriptionRequests() {
      fallbackApi({
        url: 'subscriptions/requests',
        dataSetter: (data) => {
          this.subscriptions = data.subscriptions;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    approveRequest(request) {
      if (request.type === 'seat') {
        fallbackApi({
          url: 'subscriptions/approve_seats',
          httpVerb: 'post',
          payload: {
            json: {
              seats_ids: request.original_seats
            }
          },
          dataSetter: (data) => {
            this.subscriptions = data.subscriptions;
          },
          loadingSetter: (loading) => this.loading = loading
        });
      } else {
        fallbackApi({
          url: `subscriptions/${request.id}/approve`,
          httpVerb: 'post',
          dataSetter: (data) => {
            this.subscriptions = data.subscriptions;
          },
          loadingSetter: (loading) => this.loading = loading
        });
      }
    },
    sortByColumn(columnName) {
      this.sortOrder =
        this.sortBy === columnName
          ?
          (this.sortOrder === 'asc' ? 'desc' : 'asc')
          :
          'asc';
      this.sortBy = columnName;
    },
    subscriptionDescription(subscription) {
      return `Annual
        ${subscription?.type?.name}
        Plan with
        ${subscription?.type?.default_seats}
        Team Member Seats`;
    },
    formatPrice(string) {
      return `€ ${Math.trunc(parseFloat(string)).toLocaleString()}`;
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('LLL d, yyyy');
    }
  }

};
</script>

<style lang="css" scoped>
th {
  cursor: pointer;
}
</style>
