<template>
  <section class="basicInfoNav">
    <div class="row justify-content-center">
      <nav
        class="col-sm-12 col-md-10"
      >
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/portfolios/${portfolioId}`"
        >
          <i
            class="fa fa-arrow-left mr-2"
            aria-hidden="true"
          />Back
        </a>
      </nav>
    </div>
  </section>
  <div id="portfolioViewInvestmentTable" class="row justify-content-center">
    <div class="col-sm-12 col-md-10">
      <loading-indicator
        v-if="loading"
        :skeletons="loadingSkeleton"
      />
      <div v-else class="arkCard mt-4">
        <div class="row">
          <div
            class="
                col-sm-12 col-md-6
                text-left
                justify-content-left
                DesktopTextMedium
                textBodyColor
                mx-0 mb-3 pr-2
              "
          >
            Select Investments for {{ modelName }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-12">
            <div class="d-flex justify-content-end mt-3 DesktopTextSmall grayscaleOffBlack">
              <div class="mr-2">
                Show selected investments
              </div>
              <avl-toggle
                size="medium"
                :value="showSavedTable"
                @avleventtoggle="onToggleStatusChange"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="col-12 text-left px-0">
            <vue-good-table
              :columns="columns"
              :rows="showSavedTable ? rows.saved : rows.all"
              :pagination-options="vueGoodTablePaginationOptions"
              :select-options="vueGoodTableSelectOptions"
              style-class="vgt-table"
            >
              <template #table-row="props">
                <button
                  class="
                    row
                    mx-0
                    investmentButton
                    secondary8_ark
                    rowButton
                    px-3
                    py-3
                    w-100
                    text-left
                  "
                  :style="
                    showSavedTable || props.row.vgtSelected ? 'background-color: #F7F7FC': ''
                  "
                  @click="onRowClick(props.row)"
                >
                  <span
                    v-if="props.column.field === 'title'"
                    class="w-100 col-12 col-sm-10 col-md-11 d-block font-weight-bold"
                  >
                    {{ props.row.name }}
                  </span>
                  <i
                    v-if="showSavedTable || props.row.vgtSelected"
                    class="
                        col-12 col-sm-2 col-md-1
                        fa fa-check-circle
                        checkedIconInvestment
                        d-flex
                        justify-content-end
                        align-self-center
                      "
                    aria-hidden="true"
                  />
                  <span class="sr-only">
                    {{ showSavedTable || props.row.vgtSelected ? 'Selected' : 'Not selected' }}
                  </span>
                </button>
              </template>
              <template #emptystate>
                <div class="text-align-center">
                  <h4>No investments were found.</h4>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center">
          <error-message
            :error-condition="!!errorMessageFromBackend"
            :error-message="errorMessageFromBackend"
          />
          <button
            class="
              btn
              DesktopLinkXSmall
              grayscaleOffWhite
              my-3
              primaryButton
              pointer
              setInvestmentButtonSave
            "
            @click="updatePortfolioView"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <avl-modal
    :is-open="investmentSelectedModal"
    size="600px"
    header-title=""
    close-button-icon="fa fa-times"
    @avleventclose="onInvestmentModalClose"
  >
    <div
      slot="header"
      class="d-flex justify-content-center custom-header"
    >
      <div class="col-12 text-left pl-0 mt-3">
        <h2 class="DesktopLinkSmall grayscaleOffBlack text-left mb-0">
          {{ selectOrDeselect ? 'Deselect an investment' : 'Select an investment' }}
        </h2>
      </div>
    </div>
    <p class="DesktopTextSmall black text-left mt-4">
      {{
        (selectOrDeselect ? 'Remove' : 'Add') +
          ' investment '
          + selectedInvestment?.name + (selectOrDeselect ? ' from' : ' to')
          + ' portfolio view ' + modelName
      }}
    </p>
    <div class="row justify-content-center mt-3">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn btn-link grayscaleBody ark-bold"
          @click="onInvestmentModalClose"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-link secondary7_ark ark-bold"
          @click="saveInvestmentToView()"
        >
          {{ selectOrDeselect ? 'Deselect' : 'Select' }}
        </button>
      </div>
    </div>
  </avl-modal>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import { VueGoodTable } from 'vue-good-table-next';
import { fallbackApi } from '../../../api/config';

export default {
  components: { ErrorMessage, LoadingIndicator, VueGoodTable },
  data() {
    return {
      modelPluralize: '',
      modelId: null,
      portfolioId: null,
      selectedInvestment: null,
      modelName: '',
      loading: true,
      showSavedTable: true,
      investmentSelectedModal: false,
      portfolioViews: [],
      investments: [],
      selectedInvestments: [],
      errorMessageFromBackend: '',
      rows: {
        all: [],
        saved: []
      },
      columns: [{
        label: 'Name',
        field: 'title',
        width: '90%'
      }],
      loadingSkeleton: [
        {width: null, height: '20px'},
        {width: null, height: '20px'},
        {width: null, height: '20px'},
        {width: null, height: '20px'}
      ],
      vueGoodTableSelectOptions: {
        enabled: false,
        disableSelectInfo: true
      },
      vueGoodTablePaginationOptions: {
        enabled: true,
        perPageDropdownEnabled: false,
        perPage: 20
      }
    };
  },
  computed: {
    selectOrDeselect() {
      return this.showSavedTable || this.selectedInvestment?.vgtSelected;
    }
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchInvestments();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#js-portfolio-view-settings');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.portfolioId = modelInfo?.getAttribute('data-portfolio-id');
      this.modelName = modelInfo?.getAttribute('data-model-name');
    },
    fetchSelectedInvestments() {
      fallbackApi({
        url: `portfolios/${this.portfolioId}/grouped_contributions?view_id=${this.modelId}`,
        dataSetter: ({ contributions }) => {
          this.selectedInvestments = contributions;
          this.rows.saved = contributions.contributions;
          this.rows.saved.forEach((item) => {
            item.vgtSelected = true;
          });
          this.rows.all = this.rows.all.map((item) => ({
            ...this.rows.saved.find(({ id }) => id === item.id),
            ...item
          }));
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    fetchInvestments() {
      this.loading = true;
      fallbackApi({
        url: `portfolios/${this.portfolioId}/grouped_contributions`,
        dataSetter: ({ contributions }) => {
          this.investments = contributions;
          this.rows.all = contributions.contributions;
          this.fetchSelectedInvestments();
        }
      });
    },
    onToggleStatusChange() {
      this.showSavedTable = !this.showSavedTable;
    },
    onRowClick(row) {
     this.investmentSelectedModal = true;
     this.selectedInvestment = row;
    },
    updatePortfolioView() {
      const ids = this.rows.saved.map((item) => item.investment.id );

      fallbackApi({
        url: `portfolio_views/${this.modelId}`,
        payload: { json: { portfolio_view: { investment_ids: ids } } },
        httpVerb: 'patch'
      })
      .then(() => {
        location.href = `/portfolios/${this.portfolioId}`;
      })
      .catch((error) => {
        error.response.json().then((body) => {
          this.errorMessageFromBackend = body.error ||
            'Something went wrong. Please try again later.';
        });
      });
    },
    onInvestmentModalClose() {
      this.selectedInvestment = null;
      this.investmentSelectedModal = false;
    },
    saveInvestmentToView() {
      let target = this.rows.all.find((item) => item.id === this.selectedInvestment.id);

      target.vgtSelected = !this.selectedInvestment.vgtSelected;

      // deselect investment
      if (this.selectOrDeselect) {
        this.rows.saved = this.rows.saved.filter((item) => item.id !== this.selectedInvestment.id);
      } else {
        // select investment
        this.rows.saved = [...this.rows.saved, this.selectedInvestment];
      }
      this.selectedInvestment = null;
      this.investmentSelectedModal = false;
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../stylesheet/forms.css"></style>
<style scoped>
/* TODO: extract */
.basicInfoNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

.custom-header {
  margin-top: -40px;
  max-width: 80%;
}

.text-align-center {
  text-align: center;
}

avl-modal {
  --modal-padding-header: 16px 16px 0px 7%;
  --modal-padding-body: 0 7% 0 7%;
}

@media (max-width: 600px) {
  avl-modal {
    --modal-padding-header: 16px 16px 0px 10%;
  }
}

@media screen and (max-width: 991px) {
  .basicInfoNav {
    margin-top: 10px
  }
}

#portfolioViewInvestmentTable {
  margin-top: -15px;
}

#portfolioViewInvestmentTable .investmentButton {
  cursor: pointer;
  font-weight: 900;
  background-color: transparent;
  border: none;
}

#portfolioViewInvestmentTable .investmentButton:focus {
  border: none;
  outline: 0 !important;
}

#portfolioViewInvestmentTable .setInvestmentButtonSave {
  padding: 9px 75px;
}

div :deep(.vgt-global-search) {
  border: 1px solid white;
  background: transparent;
}

div :deep(table.vgt-table) {
  background-color: white;
  border: 1px solid white;
}

div :deep(th.sortable) {
  color: #757789;
  padding: 15px;
}

div :deep(th button::after) {
  left: 70px;
}

div :deep(th button::before) {
  left: 70px;
}

div :deep(th.sorting-asc button::after) {
  border-bottom: 5px solid #68B4B9;
}

div :deep(th.sorting-desc button::before) {
  border-top: 5px solid #68B4B9;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after) {
  border-left: 6px solid #999;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after) {
  border-right: 6px solid #999;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.right) {
  width: 10px;
  margin-left: 5px;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.left) {
  width: 10px;
  margin-right: 5px;
}

div :deep(button.footer__navigation__page-btn) {
  border-width: 0px;
  border-radius: 3px;
  text-transform: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  font-weight: 500;
  white-space: nowrap;
  height: 36px;
  -webkit-box-pack: center;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #999  !important;
  outline: none;
}

div :deep(button.footer__navigation__page-btn:hover) {
  transition: background 0s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: rgba(9, 30, 66, 0.08);
}

div :deep(button.footer__navigation__page-btn:focus-visible) {
  transition: background 0s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: rgba(9, 30, 66, 0.08);
}

div :deep(button.footer__navigation__page-btn:focus) {
  outline: none;
}

div :deep(button.footer__navigation__page-btn:active) {
  outline: none;
}

div :deep(.vgt-table thead th) {
  background: white;
}

div :deep(.vgt-wrap__footer) {
  border: 1px solid white;
  background: white;
}

div :deep(.vgt-inner-wrap) {
  box-shadow: none;
}

div :deep(.vgt-left-align) {
  color: #68B4B9;
  border-bottom: none;
  padding: 0;
}

.rowButton:hover, .rowButton:focus {
  background-color: #F7F7FC !important;
  cursor: pointer;
}

.checkedIconInvestment {
  color: #68B4B9;
}

@media screen and (max-width: 991px) {
  #portfolioViewInvestmentTable {
      margin-top: 10px
  }
}
</style>
