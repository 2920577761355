OnBoardingRecommendationHelper = class {
  constructor(submitBtn = null) {
    this.submitBtn = submitBtn;
    this.saveBtn = document.querySelector('.js-submit-form');
    this.finishBtn = document.querySelector('.btn-finish');
  }

  submitForm() {
    var section = $('#section_type').attr('section') || null;
    // Check if on correct tab
    if (section !== 'media'){
      return false;
    }
    arkUI.loadingIndicator.show();
    const form = document.querySelector('.js-recommendation-form');
    const formURL = form.action;
    const formData = new FormData(form);
    fetch(formURL, {
      method: form.method,
      body: formData
    }).then((response) => {
      if (response.ok) {
      } else {
        console.error('Problems creating the recommendations');
      }
      return response.json();
    }).then((json) => {
      console.log('Recommendations created successfully.');
      // Attach the created user ID so we know if we need to update or create the user when
      // save is clicked.
      if (json.errors) {
        var error_html = 'An error occurred while saving your organization.<br ><ul>'
        json.errors.forEach((e) => {
          error_html += `<li>${e}</li>`
        })
        error_html += '</ul>'
        arkUI.loadingIndicator.hide();
        arkUI.alert.show({
          html: error_html
        });
      } else {
        // Show the finish modal upon successfull execution
        notice_html = '<h6>Your media recommendations were saved successfully.<br ><br >Click OK to continue or LATER to keep editing.</h6>';
        arkUI.loadingIndicator.hide();
        arkUI.alert.show({
          html: notice_html,
          redirect: true,
          redirect_location: '/onboarding'
        });
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
      alert('Something went wrong. Please, try again', error);
    });
  }

  setupSubmit() {
    if (this.submitBtn) {
      this.submitBtn.addEventListener('click', this.submitForm);
    } else {
      this.saveBtn.addEventListener('click', this.submitForm);
    }

    if (this.finishBtn) {
      this.finishBtn.addEventListener('click', this.submitForm);
    }
  }
}
