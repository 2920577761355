<template>
  <section class="arkCardNoPadding">
    <input
      ref="bannerInput"
      type="file"
      :accept="imageMimeTypes.join(', ')"
      hidden
      @input="addBanner"
    >
    <div
      class="coverPhotoContainer"
      :style="{ 'background-image': `url('${project?.project?.banner}')` }"
      aria-hidden="true"
    />
    <section v-if="isAdmin" class="d-flex justify-content-end">
      <button
        class="btn btn-link p-0 m-0 editCoverPhotoButton"
        aria-label="Edit banner menu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          class="fa fa-pencil edit-icon-pencil grayscaleBody"
          aria-hidden="true"
        />
      </button>
      <div class="d-flex justify-content-end">
        <div class="dropdown-menu dropdown-primary" role="menu">
          <button
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="updateImage('banner')"
          >
            <i class="far fa-camera mr-2" aria-hidden="true" /> Upload banner image
          </button>
          <button
            v-show="project?.project?.banner"
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="openEditImageModal('banner')"
          >
            <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust banner image
          </button>
          <button
            type="button"
            class="dropdown-item w-100 m-0 pointer"
            @click="deleteImage('banner')"
          >
            Delete
          </button>
        </div>
      </div>
    </section>
    <section class="px-4 pt-3">
      <header class="row">
        <section :class="project?.project?.budget ? 'col-md-9': 'col-12'">
          <h1
            class="DesktopLinkMedium grayscaleOffBlack mb-0 wordBreakWord"
          >
            {{ project?.project?.title }}
            <avl-badge
              v-if="project?.project?.hidden"
              label="Hidden"
              size="medium"
              type="default"
              class="MobileTextXSmall grayscaleBody ml-2"
            />
          </h1>
        </section>
        <h1
          v-if="project?.project?.budget"
          class="col-md-3 DesktopLinkMedium grayscaleOffBlack mb-0 wordBreakWord"
        >
          <i class="fa fa-eur" aria-hidden="true" />
          {{ Math.trunc(parseFloat(project?.project?.budget)).toLocaleString()
          }}
          <p class="MobileTextXSmall mb-0 grayscaleLabel position-absolute d-none d-md-block">
            Financial target
          </p>
        </h1>
      </header>
    </section>
    <section class="px-4 pt-3">
      <section class="d-flex align-items-center grayscaleLabel">
        <a
          class="MobileTextXSmall mb-0 organization-title-size"
          :href="`/organizations/${project?.organization?.id}`"
        >
          {{ project?.organization?.name }}
        </a>
        <span v-if="project?.project?.country !== ''" class="d-flex align-items-center">
          <i class="fa fa-circle dot-size mx-1" aria-hidden="true" />
          <h2
            class="MobileTextXSmall mb-0 organization-title-size"
          >
            {{ project?.project?.country }}
          </h2>
        </span>
      </section>
      <section class="d-flex align-items-center grayscaleLabel">
        <i :class="{ 'fas fa-file-alt mr-2': getProjectTypeString === 'Report', 
             'fas fa-map-marker-alt mr-2': getProjectTypeString !== 'Report' }"
             aria-hidden="true">
        </i>
        <h2 class="MobileTextXSmall mb-0">
          {{ getProjectTypeString }}
        </h2>
        <i class="fa fa-circle dot-size mx-1" aria-hidden="true" />
        <h2 class="MobileTextXSmall mb-0">
          {{ projectType }}
        </h2>
      </section>
    </section>
    <section class="px-4 py-3 mb-2 d-flex justify-content-between">
      <div>
        <a
          v-if="isAdmin"
          class="btn btn-sm DesktopLinkXSmall primaryButton transition-02 mr-2"
          :href="`/${modelPluralize}/${modelId}/edit`"
        >
          <i
            class="fa fa-pencil grayscaleOffWhite pr-2 align-self-center"
            aria-hidden="true"
          />
          Edit
        </a>
        <button
          v-else
          type="button"
          class="
            DesktopLinkXSmall
            primaryButton
            mr-2
            btn
            btn-sm
            align-self-center
          "
          @click="
            followUnfollow(project?.is_following ? 'unfollow' : 'follow', project)
          "
        >
          {{ project?.is_following ? 'Following': 'Follow' }}
        </button>
        <button
          v-if="project?.project?.email"
          class="btn btn-sm DesktopLinkXSmall primaryButton donateInvestButton transition-02"
          @click="
            openCreateContributionFormModal(project?.project, 'project', investOrDonateButton)
          "
        >
          {{ investOrDonateButton }}
        </button>
      </div>
      <export-options v-if="isAdmin" :model-pluralize="modelPluralize" :model-id="modelId" />
    </section>
    <hr class="p-0 m-0">
    <nav class="ml-3 pt-2px">
      <a
        class="DesktopLinkXSmall pointer transition-02 mr-1 d-inline-block"
        :class="activeTab === 'about' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}`"
      >
        About
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'impact' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}/impact`"
      >
        Impact
      </a>
      <!-- Hide for now - ESG -->
      <!-- <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'esg' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}/esg`"
      >
        ESG Profile
      </a> -->
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'risks' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}/risks`"
      >
        Risk
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'team' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}/team`"
      >
        Team
      </a>
      <a
        class="DesktopLinkXSmall pointer transition-02 mx-1 d-inline-block"
        :class="activeTab === 'media' ? 'navbarButtonActive' : 'navbarButton'"
        :href="`/projects/${modelId}/media`"
      >
        Monitoring
      </a>
      <a
        class="
          DesktopLinkXSmall
          pointer
          transition-02
          mx-1
          d-inline-block
          navbarButtonDisabled
          not-allowed
        "
        disabled
      >
        Verification
      </a>
    </nav>
  </section>
  <avl-modal
    :is-open="invalidLogoModalOpen"
    size="fit-content"
    header-title=""
    close-button-icon="fa fa-times"
    @avleventclose="invalidLogoModalOpen = false"
  >
    <div class="DesktopTextSmall grayscaleBody mt-2 p-3">
      Invalid image type selected. Please use one of JPG, JPEG, or PNG.
    </div>
  </avl-modal>
  <CreateContributionForm
    :modal-open="createContributionModalOpen"
    :contribution-recipient-id="contributionRecipientId"
    contribution-recipient-type="project"
    :contribution-recipient-name="contributionRecipientName"
    :contribution-button-text="contributionButtonText"
    @close-modal-event="closeCreateContributionFormModal"
  />
</template>

<script>
import CreateContributionForm from '../../contributions/components/CreateContributionForm.vue';
import ExportOptions from '../../../components/shared/ExportOptions.vue';
import { contributionsMixin } from '../../../mixins';
import { fallbackApi } from '../../../api/config';
import { openDefaultEditor } from '@pqina/pintura';

export default {
  components: { CreateContributionForm, ExportOptions },
  mixins: [contributionsMixin],
  props: {
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    projectType: {
      type: String,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }
  },
  emits: ['updateImage'],
  data() {
    return {
      banner: null,
      imageMimeTypes: ['image/jpeg', 'image/png'],
      invalidLogoModalOpen: false
    };
  },
  computed: {
    investOrDonateButton() {
      if (this.getProjectTypeString === 'Report') {
        return 'Add to portfolio';
      } else if (
        this.project?.project?.asset_classes[0]?.name === 'Philanthropic'
          && this.project?.project?.asset_classes?.length === 1
      ) {
        return 'Donate';
      } else return 'Invest';
    },
    getProjectTypeString() {
      let { project } = this.project;
      if (project?.type === 'ReportingProject') {
        return 'Report';
      } else {
        return 'Project';
      }
    }
  },
  methods: {
    addBanner() {
      this.banner = this.$refs.bannerInput.files[0];
      if (!this.imageMimeTypes.includes(this.banner?.type)) {
        this.invalidLogoModalOpen = true;
        return;
      }

      this.openEditImageModal('banner');
    },
    updateImage(key = 'banner') {
      this.$refs[`${key}Input`].click();
    },
    openEditImageModal(key) {
      const image = this.banner || this.project.project.banner;
      if (!image) return;

      const aspectRatio = 4.95;

      openDefaultEditor({
        src: image,
        imageCropAspectRatio: aspectRatio,
        utils: ['crop'],
        handleEvent: (type, detail) => {
          if (type === 'process') {
            this.uploadImage(key, detail.dest);
          }
        }
      });
    },
    uploadImage(key, file) {
      const formData = new FormData();
      formData.append(key, file);
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/banner`,
        payload: {
          body: formData,
          // Remove json content type so browser can set the appropriate formdata content type
          headers: {
            'Content-Type': undefined
          }
        },
        httpVerb: 'post',
        dataSetter: (data) => {
          this.$emit('updateImage', key, data[key]);
        }
      });
    },
    deleteImage(key) {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/banner`,
        httpVerb: 'delete',
        dataSetter: (data) => {
          const image = data.banner;
          this.$emit('updateImage', key, image);
        }
      });
    },
    followUnfollow(status, project) {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/${status}`,
        httpVerb: 'post'
      })
      .then(() => {
        project.is_following = !project.is_following;
      });
    }
  }
};
</script>

<style scoped>
.editCoverPhotoButton {
  margin-top: -190px !important;
  padding-right:20px !important;
  height: 40px
}

.organization-title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  max-wdth: 50%;
}

avl-modal {
  --modal-padding-footer: 25px 0;
}

avl-badge {
  border-radius: 8px;
  font-weight: 500;
  height: 25px;
  text-transform: none;
  font-size: 0.6875rem;
  --default-text-color: #172722;
  --default-color-solid: #EFF0F6;
  align-self: center;
  padding: 3px 6px;
  margin-top: 3px;
}

button.dropdown-item {
  width: calc(100% - 0.625rem);
}

.donateInvestButton {
  background-color: #C5BD74 !important;
  border-color: #C5BD74 !important;
}

.donateInvestButton:hover, .donateInvestButton:hover:enabled, .donateInvestButton:focus  {
  background-color: #C5BD74 !important;
  border-color: #C5BD74 !important;
  box-shadow: 0px 2px 3px 1px #c5bd7481;
}
.dot-size {
  font-size: 3px;
}
</style>
