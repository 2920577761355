<template>
  <div class="confirm-email white-background">
    <header class="row justify-content-center mt-3">
      <div class="col-10 text-center">
        <h1 class="DesktopDisplayXSmall black pt-5">Thank you for requesting an ARK account.</h1>
        <h1 class="DesktopDisplayXSmall black mt-3">We’ll process your request shortly.</h1>
      </div>
    </header>
    <main class="row justify-content-center">
      <p class="col-10 text-center DesktopTextSmall textBodyColor mt-5">
        We’ve received your request and look forward to getting in touch soon.
        <br><br>
        Should you need immediate assistance, please contact us at info@theark.co
      </p>
    </main>
  </div>
</template>

<style scoped>
.confirm-email {
  padding-top: 75px;
}

.white-background {
  min-height: 100vh;
  background-color: #FFFFFF;
}
</style>
