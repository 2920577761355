<template>
  <section>
    <div v-if="Object.keys(project).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <project-header
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :project="project"
        :project-type="projectType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
      />
    </div>
  </section>
</template>

<script>
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import ProjectHeader from '../../sharedComponents/ProjectHeader.vue';
import { modelMixin } from '../../../../mixins';

export default {
  components: { ProjectHeader, LoadingIndicator },
  mixins: [modelMixin],
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#project-media');
      this.getModelDetails(modelInfo);
    }
  }
};
</script>
