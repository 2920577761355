import FinancialDistributionCard from './components/FinancialDistributionCard.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#finance')){
    const app = createApp(FinancialDistributionCard);
    app.use(store);
    app.mount('#finance');
  }
});
