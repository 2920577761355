import { fallbackApi } from '../api/config';
import isAdminBase from '../utilities/authentication';

export const modelMixin = {
  data() {
    return {
      model: '',
      modelPluralize: '',
      modelId: '',
      activeTab: '',
      organizationHeaderInfo: {},
      project: {}
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: this.model,
        entityId: this.modelId
      });
    },
    organizationType() {
      switch (this.organizationHeaderInfo?.type) {
        case 'WildlifeOrg':
          return 'Project Developer';
        case 'TechOrg':
          return 'Technology';
        case 'InvestorOrg':
          return 'Investor';
        default:
          return 'Project Developer';
      }
    },
    projectType() {
      if (
        this.project?.project?.asset_classes[0]?.name === 'Philanthropic'
          && this.project?.project?.asset_classes?.length === 1
      ) {
        return 'Philanthropic';
      } else return 'For profit';
    }
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    if (this.model === 'organization') this.fetchOrganizationHeaderInfo();
    else this.fetchProjectInfo();
  },
  methods: {
    fetchProjectInfo() {
      fallbackApi({
        url: `projects/${this.modelId}`,
        dataSetter: ({ project }) => this.project = project
      });
    },
    fetchOrganizationHeaderInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}`,
        dataSetter: ({ organization }) => this.organizationHeaderInfo = organization
      });
    },
    getModelDetails(info) {
      this.model = info?.dataset.model;
      this.modelId = info?.dataset.modelId;
      this.modelPluralize = info?.dataset.modelPluralize;
      this.activeTab = info?.dataset.tabActive;
    },
    updateHeaderImage(key, image) {
      if (this.model === 'organization') {
        this.$store.commit('updateOrganizationLogoOrBanner', { key: key, logo: image });
        this.organizationHeaderInfo[key] = image;
      } else {
        this.project.project[key] = image;
      }
    }
  }
};
