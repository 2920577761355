<template>
  <div class="carousel" ref="carousel">
    <div
      ref="carouselWrapper"
      class="carousel-wrapper"
      :class="{ 'contained-width': !fullWidth }"
      @scroll="handleScroll"
    >
      <slot></slot>
    </div>
    <button
      class="btn secondaryButton primaryDefault m-0 px-3 arrow arrow-left"
      @click="scrollLeft"
      :class="{ 'hidden': atBeginning }"
    >
      <i class="fas fa-chevron-left fa-xs"></i>
    </button>
    <button
      class="btn secondaryButton primaryDefault m-0 px-3 arrow arrow-right"
      @click="scrollRight"
      :class="{ 'hidden': atEnd }"
    >
      <i class="fas fa-chevron-right fa-xs"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      atBeginning: true,
      atEnd: false,
    };
  },
  methods: {
    scrollLeft() {
      this.$refs.carouselWrapper.scrollBy({
        left: -300,
        behavior: "smooth",
      });
      this.updateButtonVisibility();
    },
    scrollRight() {
      this.$refs.carouselWrapper.scrollBy({
        left: 300,
        behavior: "smooth",
      });
      this.updateButtonVisibility();
    },
    updateButtonVisibility() {
      const maxScroll = this.$refs.carouselWrapper?.scrollWidth - this.$refs.carousel?.clientWidth + 50;
      const currentScroll = this.$refs.carouselWrapper?.scrollLeft;
      this.atBeginning = currentScroll === 0;
      this.atEnd = currentScroll >= maxScroll;
    },
    handleScroll() {
      this.updateButtonVisibility();
    },
    onResize() {
      this.isSmallScreen = window.innerWidth < 768;
      this.updateButtonVisibility();
    },
  },
  mounted() {
    this.updateButtonVisibility();
  },
  created() {
    // add watcher for window resize
    window.addEventListener('resize', this.onResize);
  },
  destroyed() {
    // remove window resize listener when component is destroyed
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped>
.carousel {
  overflow: visible;
  position: relative;
}

.carousel-wrapper {
  display: flex;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 1rem;
}

.arrow-left {
  left: -20px;
}

.arrow-right {
  right: -20px;
}

.arrow,
.hidden {
  display: none;
}


@media screen and (max-width: 1162px) {
  .carousel {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  .carousel-wrapper {
    position: relative;
    overflow-x: auto;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    align-items: stretch;
    scroll-padding-left: 2.4rem;
    scroll-padding-right: 2.4rem;
  }

  .arrow {
    display: block;
  }

  .arrow.hidden {
    display: none;
  }
}

@media screen and (min-width: 985px) and (max-width: 1162px) {
  .contained-width {
    width: 500px;
  }
}

@media screen and (min-width: 376px) and (max-width: 985px) {
  .contained-width {
    width: 300px;
  }
}

@media screen and (max-width: 376px) {
  .contained-width {
    width: 260px;
  }
}
</style>
