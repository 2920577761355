<template>
  <div v-if="!settingLocation && !settingSite">
    <basic-info
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :model="model"
      :images="images"
      :form="form"
      :location="newLocation"
      :project="project"
      :refresh-sites="refreshSites"
      :tags="tags"
      :asset-classes="assetClasses"
      @set-location="goToSetLocation"
      @set-sites-location="setSitesLocation"
      @upload-file="uploadFile"
      @save-video="saveVideo"
      @update-project="updateProject"
    />
  </div>
  <div v-show="!loading && settingLocation">
    <div v-if="!loading && changeCurrentLocation && !settingSite">
      <set-location
        :images="images"
        :location="form"
        :reverse-geocode="true"
        :required="project.project.type !== 'ReportingProject'"
        @back="closeLocation"
        @save="updateLocation"
      />
    </div>
    <div v-else-if="!loading && !changeCurrentLocation && !settingSite">
      <set-location
        :images="images"
        :location-name-input="false"
        :protected-areas="true"
        :required="true"
        @back="settingLocation = false"
        @save="saveLocation"
      />
    </div>
  </div>
  <div v-show="!loading && !settingLocation && settingSite">
    <new-site
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :images="images"
      :site-types="siteTypes"
      :location="location"
      :file="areaFile"
      @back="backToSites"
      @save="saveSite"
    />
  </div>
</template>

<script>
import BasicInfo from './BasicInfo.vue';
import NewSite from '../../../../components/shared/NewSite.vue';
import SetLocation from '../../../../components/shared/SetLocation.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { BasicInfo, NewSite, SetLocation },
  data() {
    return {
      loading: true,
      project: {},
      model: '',
      modelPluralize: '',
      modelId: null,
      images: {},
      siteTypes: [],
      tags: [],
      assetClasses: [],
      location: null,
      areaFile: null,
      settingLocation: false,
      settingSite: false,
      refreshSites: false,
      changeCurrentLocation: false,
      newLocation: {},
      form: {}
    };
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchProjectInfo();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#edit-project');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.images = {
        collaborate: modelInfo?.dataset?.imgUrl
      };
      this.siteTypes = JSON.parse(modelInfo?.dataset.siteTypes);
      this.tags = JSON.parse(modelInfo?.dataset?.tags) || [];
      this.assetClasses = JSON.parse(modelInfo?.dataset?.assetClasses) || [];
    },
    fillForm(data) {
      this.form = {
        name: data?.project.title,
        locationName: data?.project.location,
        latitude: data?.project?.formatted_location?.lat,
        longitude: data?.project?.formatted_location?.lon,
        country: data?.project?.country,
        city: data?.project?.city,
        zip: data?.project?.zip,
        description: data?.project?.description,
        startDate: data?.project?.start_date,
        endDate: data?.project?.end_date,
        email: data?.project?.email,
        budget: data?.project?.budget ? parseInt(data?.project?.budget) : null,
        selectedCategories: data?.project?.categories?.map(
          (item) => item.id
        ),
        selectedSpecies: (data?.species || data?.project?.species)?.map(
          (item) => item.id
        ),
        selectedTags: data?.project.tags?.map(
          (tag) => tag.id
        ),
        selectedAssetClasses: data?.project.asset_classes?.map(
          (item) => item.id
        ),
        hidden: data?.project?.hidden
      };
      this.newLocation = {
        locationName: data?.project.location,
        latitude: data?.project?.formatted_location?.lat,
        longitude: data?.project?.formatted_location?.lon,
        country: data?.project?.country,
        city: data?.project?.city,
        zip: data?.project?.zip
      };
    },
    fetchProjectInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}`,
        dataSetter: ({ project }) => {
          this.project = project;
          this.fillForm(this.project);
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    updateProject(data) {
      this.project.project = { ...this.project.project, data };
      this.fillForm(data);
    },
    uploadFile(file) {
      this.areaFile = file;
      this.settingSite = true;
    },
    saveLocation(location) {
      this.location = {...location};
      this.settingLocation = false;
      this.settingSite = true;
    },
    updateLocation(location) {
      this.newLocation = {...location};
      this.changeCurrentLocation = false;
      this.settingLocation = false;
    },
    closeLocation() {
      this.changeCurrentLocation = false;
      this.settingLocation = false;
    },
    backToSites() {
      this.refreshSites = !this.refreshSites;
      this.settingSite = false;
    },
    saveSite() {
      this.location = null;
      this.areaFile = null;
      this.refreshSites = !this.refreshSites;
      this.settingSite = false;
    },
    goToSetLocation(form) {
      this.changeCurrentLocation = true;
      this.settingLocation = true;
      this.newLocation = null;
      this.form = form;
    },
    setSitesLocation() {
      this.settingLocation = true;
    },
    saveVideo(video) {
      this.project = { ...this.project, ...video };
    }
  }
};
</script>
