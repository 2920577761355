<template>
  <nav class="arkCard searchTabs">
    <div v-if="tab === 'all'">
      <button-carousel full-width>
        <button
          v-for="option in tabOptions"
          :key="option.alias"
          :class="tab === option.alias ? 'primaryButton' : 'borderedButton'"
          class="btn DesktopLinkXSmall btn-round mr-2"
          @click="goToTab(option.alias)"
        >
          {{ option.name }}
        </button>
      </button-carousel>
    </div>
    <div v-else class="d-flex align-items-center justify-content-start justify-content-md-between dropdownContainer">
      <div class="d-flex">
        <div>
          <button
            class="btn primaryButton DesktopLinkXSmall btn-round mr-2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ tabName }} <i class="fa fa-caret-down" aria-hidden="true" />
          </button>
          <div class="d-flex justify-content-end">
            <div class="dropdown-menu dropdown-primary">
              <button
                v-for="option in tabOptions"
                :key="option.alias"
                class="dropdown-item w-100 m-0 DesktopLinkXSmall"
                :class="{ leftBorder: tab === option.alias }"
                @click="goToTab(option.alias)"
              >
                {{ option.name }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="tab === 'projects'" class="d-flex">
          <span class="verticalLine mr-2" />
          <button
            :class="investmentReady ? 'primaryButton' : 'borderedButton'"
            class="btn DesktopLinkXSmall btn-round mr-2"
            @click="investmentReadyClick"
          >
            Investment ready
          </button>
        </div>
        <div v-if="tab === 'organizations'">
          <button
            class="btn borderedButton DesktopLinkXSmall btn-round mr-2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ organizationTypeName }} <i class="fa fa-caret-down" aria-hidden="true" />
          </button>
          <div class="d-flex justify-content-end">
            <div class="dropdown-menu dropdown-primary">
              <button
                v-for="type in organizationTypes"
                :key="type.value"
                :class="{ leftBorder: organizationType === type.value }"
                class="dropdown-item w-100 m-0 DesktopLinkXSmall"
                @click="selectType(type.value)"
              >
                {{ type.name }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="tab === 'organizations' || tab === 'projects'" class="d-flex">
          <span class="verticalLine mr-2" />
          <button
            class="btn DesktopLinkXSmall btn-round borderedButton mr-2"
            @click="allFiltersClick"
          >
            All filters
          </button>
        </div>
      </div>
      <div v-if="closeMapButton" class="d-flex">
        <span class="verticalLine mr-2 d-md-none" />
        <button
          class="btn DesktopLinkXSmall closeMapButton textTransformNone"
          @click="closeMap"
        >
          <i class="far fa-times fa-lg mr-2" aria-hidden="true" />
          Close Map
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import ButtonCarousel from '../../../components/shared/ButtonCarousel.vue';

export default {
  name: 'SearchTabs',
  components: { ButtonCarousel },
  props: {
    tab: {
      type: String,
      required: true
    },
    investmentReady: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    organizationType: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    closeMapButton: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  emits: ['tabChanged', 'allFilters', 'investmentReady', 'selectOrganizationType', 'closeMap'],
  data() {
    return {
      tabOptions: [
        { alias: 'all', name: 'All results' },
        { alias: 'projects', name: 'Projects' },
        { alias: 'organizations', name: 'Organisations' },
        { alias: 'users', name: 'People' },
        { alias: 'top_news', name: 'Posts' },
        { alias: 'books', name: 'Books' },
        { alias: 'films', name: 'Films' },
        { alias: 'podcasts', name: 'Webinars' },
        { alias: 'papers', name: 'Research papers' }
      ],
      organizationTypes: [
        { value: '', name: 'All sectors' },
        { value: 'WildlifeOrg', name: 'Project Developer' },
        { value: 'InvestorOrg', name: 'Investor' },
        { value: 'TechOrg', name: 'Company' },
      ]
    };
  },
  computed: {
    tabName() {
      return this.tabOptions.find((option) => option.alias === this.tab)?.name;
    },
    organizationTypeName() {
      return this.organizationTypes.find((type) => type.value === this.organizationType)?.name;
    }
  },
  methods: {
    goToTab(tab) {
      this.$emit('tabChanged', tab);
    },
    allFiltersClick() {
      this.$emit('allFilters');
    },
    investmentReadyClick() {
      this.$emit('investmentReady', !this.investmentReady);
    },
    selectType(type) {
      this.$emit('selectOrganizationType', type);
    },
    closeMap() {
      this.$emit('closeMap');
    }
  }
};
</script>

<style scoped>
.btn-round {
  border-radius: 30px !important;
}

.primaryButton {
  padding: 9px 22px;
}

.searchTabs {
  margin-top: -15px;
  margin-bottom: 0;
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 4;
}

@media screen and (max-width: 991px) {
  .searchTabs {
    margin-top: 10px;
    position: sticky;
    top: 60px;
    left: 0;
    z-index: 4;
  }
}

.leftBorder {
  border-left: 3px solid #24422b;
}

.dropdown-item:hover, .dropdown-item:active, .dropdown-item:focus {
  background-color: #FCFCFC !important;
  color: unset;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14) !important;
}

.verticalLine {
  display: inline-block;
  border-left: 1px solid #BDBFD2;
  height: 50px;
}

@media screen and (max-width: 992px) {
  .dropdownContainer {
    overflow-x: scroll;
  }
}

.closeMapButton {
  border-radius: 4px;
  background-color: #355159;
}

.closeMapButton:hover {
  background-color: #355159;
}
</style>
