<template>
  <aside class="info-notice">
    <span class="icon" aria-hidden=true><i :class="iconClass"></i></span>
    <p class="text DesktopTextSmall">
      <slot v-if="$slots.notice" name="notice" />
      <span v-else>{{ noticeText }}</span>
    </p>
  </aside>
</template>

<script>
export default {
  props: {
    noticeText: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: 'fas fa-info-circle'
    }
  }
};
</script>

<style scoped>
.info-notice {
  display: flex;
  align-items: flex-start;
  padding: 1em;
  background-color: #e6f0ff;
  border-radius: 3px;
  color: #0056b3;
}

.info-notice .icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  font-size: 1.2em;
  line-height: 1.2;
}

.info-notice .text {
  margin: 0;
}
</style>
