import PortfolioContainer from './components/PortfolioContainer.vue';
import PortfolioContributions from './components/PortfolioContributions.vue';
import PortfolioSavedViews from './components/PortfolioSavedViews.vue';
import PortfolioViewSettings from './components/PortfolioViewSettings.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-portfolio')){
    const app = createApp(PortfolioContainer);
    app.use(store);
    app.mount('#js-portfolio');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-contributions')){
    const app = createApp(PortfolioContributions);
    app.use(store);
    app.mount('#js-contributions');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-saved-views')){
    const app = createApp(PortfolioSavedViews);
    app.use(store);
    app.mount('#js-saved-views');
  }
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#js-portfolio-view-settings')){
    const app = createApp(PortfolioViewSettings);
    app.use(store);
    app.mount('#js-portfolio-view-settings');
  }
});