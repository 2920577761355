class LikeHelper {
  static likeActor(btn, userId = null) {
    const likeActorHTML = btn.siblings('.js-like-url');

    if (likeActorHTML) {
      const likeActorUrl = likeActorHTML.val();
      fetch(likeActorUrl,
        { method: 'POST',
          headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content }
        }).then((response) => {
          if (response.ok) {
            return response.json()
              .then((json) => {
                btn.addClass('d-none');
                btn.siblings('.js-unlike-btn').removeClass('d-none');
                const likeContainer = btn.parents('.js-social-counts').find('.js-like-container');
                const likeCountSpan = likeContainer.find('.js-like-count');
                const likeCount = json.likes;
                var users = json.users;
                if (likeCount > 5) {
                  users.push('...');
                }
                likeCountSpan.find('a').text(likeCount);
                likeCountSpan.attr('data-list', users);
                LinkHelper.setupTooltip(likeCountSpan);
                if (likeCount >= 1) {
                  likeContainer.removeClass('d-none');
                }
              })
              .catch((error) => {
                Sentry && Sentry.captureException(error);
              })
          } else {
            Sentry && Sentry.captureException(response);
          }
        });
    }
  }

  static unlikeActor(btn, userId = null) {
    const unlikeActorHTML = btn.siblings('.js-unlike-url');

    if (unlikeActorHTML) {
      const unlikeActorUrl = unlikeActorHTML.val();
      fetch(unlikeActorUrl,
        { method: 'POST',
          headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content }
        }).then((response) => {
          if (response.ok) {
            return response.json()
              .then((json) => {
                btn.addClass('d-none');
                btn.siblings('.js-like-btn').removeClass('d-none');
                const likeContainer = btn.parents('.js-social-counts').find('.js-like-container');
                const likeCountSpan = likeContainer.find('.js-like-count');
                const likeCount = json.likes;
                var users = json.users;
                if (likeCount > 5) {
                  users.push('...');
                }
                likeCountSpan.find('a').text(likeCount);
                likeCountSpan.attr('data-list', users);
                LinkHelper.setupTooltip(likeCountSpan);
                if (likeCount < 1) {
                  likeContainer.addClass('d-none');
                }
              })
              .catch((error) => {
                Sentry && Sentry.captureException(error);
              })
          } else {
            Sentry && Sentry.captureException(response);
          }
        });
    }
  }

  static setupLikeButtons() {
    // Search for like buttons
    const likeBtns = $('.js-like-btn');
    if (likeBtns) {
      likeBtns.each(function( index ) {
        $(this).off('click');
        $(this).on('click', function(){
          LikeHelper.likeActor($(this));
        });
      });
    }

    const unlikeBtns = $('.js-unlike-btn');
    if (unlikeBtns) {
      unlikeBtns.each(function( index ) {
        $(this).off('click');
        $(this).on('click', function(){
          LikeHelper.unlikeActor($(this));
        })
      });
    }
  }
}

export { LikeHelper };
