export const paginationMixin = {
  mounted() {
    window.addEventListener('scroll', this.scrollToBottom);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollToBottom);
  },
  methods: {
    // TODO: check accessibility concerns
    scrollToBottom(e) {
      const scrollHeight = e.target.documentElement.scrollHeight;
      const scrollTop = e.target.documentElement.scrollTop;
      const clientHeight = e.target.documentElement.clientHeight;

      if (scrollHeight - scrollTop - clientHeight <= 50) {
        this.handlePagination();
      }
    },
    uniqueItems(array) {
      return array.reduce((unique, item) => {
        if (!unique.some((obj) => obj.id === item.id)) {
          unique.push(item);
        }
        return unique;
      },[]);
    }
  }
};
