import 'v-calendar/dist/style.css';
import OrganizationProjects from './components/OrganizationProjects.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#model-projects')){
    const app = createApp(OrganizationProjects);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#model-projects');
  }
});