import $eventBus from '../../utilities/eventBus.js';
import SitesMapContainer from './components/SitesMapContainer.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#sites-map')){
    const app = createApp(SitesMapContainer);
    app.config.globalProperties.$eventBus = $eventBus;
    app.use(store);
    app.mount('#sites-map');
  }
});
