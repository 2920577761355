Highcharts.setOptions({
  colors: [
    '#c5bd74',
    '#99a29a',
    '#cce0b7',
    '#5e7971',
    '#759aa6',
    '#c7e1e4',
    '#5c7c6c',
    '#7d9c92',
    '#b0af74',
    '#36533e',
    '#f6f8f5',
    '#6c847c',
    '#94b4ac',
    '#ece6cc',
    '#647c74',
    '#9fc0bc',
    '#c7c289',
    '#bcdcdf',
    '#557362',
    '#b4dce0',
    '#94b4b4',
    '#add1d2',
    '#a4ccc9',
    '#cddcc5',
    '#648474',
    '#4c6c5c',
    '#e4ecec',
    '#dae4c4',
    '#4c6c54',
    '#a4aca4',
    '#e4e4c9',
    '#d4e4bc',
    '#d9d4a6',
    '#cde4d4',
    '#c4dcd4',
    '#c8ccc8',
    '#bcdcd4',
    '#b4bcb4',
    '#e0dcb7',
    '#cce4cc',
    '#c4e4d4',
  ],
});