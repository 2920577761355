class FilmHelper {
  static setupModal($modal) {
    $modal.on('shown.bs.modal', function () {
      $modal.attr('aria-expanded', true);
    })
  }

  static showDeleteAlert(html, redirect, objID){
    new swal({
      html: html,
      showCancelButton: redirect,
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-default',
      confirmButtonClass: 'btn btn-secondary1-ark',
      buttonsStyling: false
    }).then(function(result) {
      if (result.isConfirmed && redirect) {
        fetch(`/media/films/${objID}`, {
          method: 'DELETE',
          headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") }
        }).then((response) => {
          window.location.reload();
        }).catch((error) => {
          Sentry && Sentry.captureException(error)
        });
      } else {
        console.log('Aborting deletion');
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
    });

  }
}

let modal = $('#filmModal');
if(modal) {
  FilmHelper.setupModal(modal);
}

let editModal = $('#editFilmModal');
if(editModal) {
  FilmHelper.setupModal(editModal);
}

$('.js-delete-film').on('click', function() {
  const objID = $(this).attr('data-film-id');
  const redirect = true;
  FilmHelper.showDeleteAlert(`Are you sure you want to delete this resource? This is a permanent change.`, redirect, objID);
});

$('.js-edit-film').on('click', function() {
  const showPath = $(this).attr('data-show-path');
  fetch(showPath)
  .then((response) => response.json())
  .then((json) => {
    const csrf = $('meta[name="csrf-token"]').attr('content');
    $('#editFilmModal form').attr('action', showPath);
    $('#editFilmModal input[name="authenticity_token"]').val(csrf);
    $('#editFilmModal input[name="title"]').val(json.film.title);
    if (json.film.release_date){
      $('#editFilmModal input[name="release_date"]').val(moment(json.film.release_date).utc().format('YYYY/MM/DD'));
    }
    $('#editFilmModal input[name="directors"]').val(json.film.directors);
    $('#editFilmModal input[name="film_url"]').val(json.film.film_url);
    $('#editFilmModal input[name="youtube_url"]').val(json.film.youtube_url);
    $('#editFilmModal input[name="imdb_url"]').val(json.film.imdb_url);
    json.species_ids.forEach(function(id) {
      $(`#editFilmModal #species_ids_${id}`).attr('checked', true);
    });

    $('#editFilmModal').modal('show');
  });
});
