class NotificationHelper {
  static setupNotificationButtons() {
    // Mark all as read button
    const readAllNotifications = $('.js-read-all');
    if (readAllNotifications.length > 0) {
      const url = readAllNotifications.data('read-url');
      readAllNotifications.off('click');
      readAllNotifications.on('click', function() {
        fetch(url,
          { method: 'POST',
            headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content },
          }).then((response) => {
            if (response.ok) {
              $('.js-notification').removeClass('ark-p9');
              $('.js-notification').addClass('ark-p8');
              $('.js-read-notification').addClass('d-none');
              $('.js-notification-count').addClass('d-none');
            } else {
              Sentry && Sentry.captureException(response);
            }
          })
          .catch((error) => {
            Sentry && Sentry.captureException(error);
          });
      });
    }

    // Mark as read 'dot' buttons
    const readNotification = $('.js-read-notification');
    if (readNotification.length > 0) {
      readNotification.each(function() {
        $(this).off('click');
        $(this).on('click', function(){
          NotificationHelper.markAsRead($(this));
        });
      });
    }

    // Links inside notification
    const links = $('.js-notification a');
    if (links.length > 0) {
      links.each(function() {
        $(this).off('click');
        $(this).on('click', function(e){
          e.preventDefault();
          NotificationHelper.markAsRead($(this));
        });
      });
    }

    // Buttons inside notification
    const notificationButtons = $('.js-notification-button');
    if (notificationButtons.length > 0) {
      notificationButtons.each(function() {
        $(this).off('click');
        $(this).on('click', function(){
          const url = $(this).data('url');
          const body = $(this).data('body');
          const formData = new FormData();
          if (body) {
            for (var key in body) {
              formData.append(key, body[key]);
            }
          }
          fetch(url,
            { method: 'POST',
              headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content },
              body: body ? formData : null
            }).then((response) => {
              if (response.ok) {
                $(this).attr('disabled', true);
                NotificationHelper.markAsRead($(this));
              } else {
                Sentry && Sentry.captureException(response);
              }
            })
            .catch((error) => {
              Sentry && Sentry.captureException(error);
            });
        });
      });
    }
  }

  static markAsRead($btn) {
    const notificationDiv = $btn.closest('.js-notification');
    const url = notificationDiv.data('read-url');
    const redirectTo = $btn.attr('href');
    if (url) {
      fetch(url,
        { method: 'POST',
          headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content }
        }).then((response) => {
          if (response.ok) {
            return response.json()
              .then((json) => {
                notificationDiv.removeClass('ark-p9');
                notificationDiv.addClass('ark-p8');
                notificationDiv.find('.js-read-notification').addClass('d-none');
                $('.js-notification-count').text(json.notifications);
                if (redirectTo) {
                  window.location.href = redirectTo;
                }
              })
          } else {
            Sentry && Sentry.captureException(response);
          }
        })
        .catch((error) => {
          Sentry && Sentry.captureException(error);
        });
    }
  }
}

export { NotificationHelper };
