import NewOrganization from './components/NewOrganization.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#new-organization')){
    const app = createApp(NewOrganization);
    app.use(store);
    app.mount('#new-organization');
  }
});
