<template>
  <div slot="header" class="row custom-header">
    <div class="col-12 px-3 py-2">
      <h2 class="DesktopTextMedium textBodyColor">
        Select a field type for your metric
      </h2>
    </div>
  </div>
  <section v-if="formats && formats.length > 0">
    <div class="row justify-content-center">
      <div class="col-12 text-left">
        <h3 class="textBodyColor DesktopTextSmall mt-0">
          Set the field type you will use to keep track of your metric.
        </h3>
      </div>
    </div>
    <div v-for="format in formats" :key="format.id" class="row justify-content-start format-row">
      <div class="col-12">
        <button
          class="btn btn-link p-0 m-0 py-2 w-100 text-left normal-text format-button"
          :class="{ selected: format.name === selectedFormat }"
          :aria-label="`Select ${format.name} format`"
          @click="selectFormat(format.name)"
        >
          <span class="row justify-content-start">
            <span class="col-12 col-sm-2 col-lg-1">
              <img :src="format.image" :alt="`${format.name} format`" class="w-100">
            </span>
            <span class="col-12 col-sm-10 col-lg-11">
              <span class="DesktopLinkMedium grayscaleOffBlack">{{ format.name }}</span><br>
              <span class="DesktopTextMedium grayscaleOffBlack">{{ format.description }}</span>
            </span>
          </span>
        </button>
      </div>
    </div>
  </section>
  <section v-else class="p-5 m-5">
    <span aria-hidden="true" class="spinner-border mr-2" />
    <span>Loading</span>
  </section>
  <div slot="footer" class="pr-3">
    <div class="row d-flex justify-content-center mt-2">
      <div class="col-12 text-right">
        <button
          class="btn btn-link secondary7_ark ark-bold normal-text"
          @click="back"
        >
          Cancel
        </button>
        <button
          class="btn btn-secondary7-filled-ark normal-text"
          @click="save"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { fallbackApi } from '../../../api/config';

export default {
  name: 'CreateMetricStep1',
  props: {
    previousFormat: {
      type: String,
      required: false,
      default() {
        return null;
      }
    }
  },
  emits: ['back', 'save'],
  data() {
    return {
      formats: [],
      selectedFormat: null
    };
  },
  watch: {
    previousFormat(newValue) {
      this.selectedFormat = newValue;
    }
  },
  mounted() {
    this.selectedFormat = this.previousFormat;
    this.getFormats();
  },
  methods: {
    getFormats() {
      fallbackApi({
        url: 'measurements/formats',
        dataSetter: (data) => {
          let formats = [];
          data.formats.forEach((format) => {
            if (
              format.name !== null &&
              !formats.find((existFormat) => existFormat.name === format.name)
            ) {
              formats = [...formats, format];
            }
          });

          this.formats = formats;
          this.selectedFormat ||= formats[0] && formats[0].name;
        }
      });
    },
    selectFormat(format) {
      this.selectedFormat = format;
    },
    back() {
      this.$emit('back');
    },
    save() {
      this.$emit('save', this.selectedFormat);
    }
  }
};
</script>

<style scoped>
.format-row:not(:last-child) .format-button {
  border-bottom: 1px solid #BDBFD2;
}

.format-button.selected, .format-button:focus, .format-button:hover {
  background-color: #F7F7FC;
}

</style>
