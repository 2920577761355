<template>
  <div v-show="!settingLocation&& !settingSite">
    <basic-info
      :model-pluralize="modelPluralize"
      :model="model"
      :model-id="modelId"
      :images="images"
      :refresh-sites="refreshSites"
      :country-options="countryOptions"
      :ownership-options="ownershipOptions"
      :legal-type-options="legalTypeOptions"
      :organization="organization"
      :organization-location="newLocation"
      @set-location="goToSetLocation"
      @save-organization="saveOrganization"
      @delete-attachment="deleteAttachment"
      @delete-partner="deletePartner"
      @update-partner-logo="onUpdatePartnerLogo"
      @set-sites-location="setSitesLocation"
      @upload-file="uploadFile"
    />
  </div>
  <div v-show="!loading && settingLocation">
    <div v-if="!loading && changeCurrentLocation && !settingSite">
      <set-location
        :images="images"
        :location="form"
        :reverse-geocode="true"
        :required="true"
        @back="closeLocationComponent"
        @save="updateLocation"
      />
    </div>
    <div v-else-if="!loading && !changeCurrentLocation && !settingSite">
      <set-location
        :images="images"
        :location-name-input="false"
        :protected-areas="true"
        :required="true"
        @back="settingLocation = false"
        @save="saveLocation"
      />
    </div>
  </div>
  <div v-show="!loading && !settingLocation && settingSite">
    <new-site
      :model-pluralize="modelPluralize"
      :model-id="modelId"
      :images="images"
      :site-types="siteTypes"
      :location="location"
      :file="areaFile"
      @back="backToSites"
      @save="saveSite"
    />
  </div>
</template>

<script>
import BasicInfo from './BasicInfo.vue';
import NewSite from '../../../../components/shared/NewSite.vue';
import SetLocation from '../../../../components/shared/SetLocation.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: { BasicInfo, SetLocation, NewSite },
  data() {
    return {
      loading: true,
      organization: {},
      model: '',
      modelPluralize: '',
      modelId: null,
      images: {},
      countryOptions: [],
      ownershipOptions: [],
      legalTypeOptions: [],
      siteTypes: [],
      location: null,
      settingLocation: false,
      areaFile: null,
      settingSite: false,
      refreshSites: false,
      changeCurrentLocation: false,
      newLocation: {},
      form: {}
    };
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchOrganizationInfo();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#edit-organization');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = parseInt(modelInfo?.getAttribute('data-model-id'), 10);
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
      this.images = {
        collaborate: modelInfo?.dataset?.imgUrl
      };
      this.siteTypes = this.parseJSONArray(modelInfo?.dataset.siteTypes);
      this.countryOptions = this.parseJSONArray(modelInfo?.dataset?.countries);
      this.ownershipOptions = this.parseJSONArray(modelInfo?.dataset?.ownershipTypes);
      this.legalTypeOptions = this.parseJSONArray(modelInfo?.dataset?.legalTypes);
    },
    parseJSONArray(info) {
      try {
        return JSON.parse(info);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    fetchOrganizationInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/about`,
        dataSetter: ({ organization }) => {
          this.organization = organization;
          this.fillForm(organization);
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    fillForm(organization) {
      this.form = {
        locationName: organization?.location,
        latitude: organization?.formatted_location?.lat || null,
        longitude: organization?.formatted_location?.lon || null,
        country: organization?.country,
        city: organization?.city,
        zip: organization?.zip
      };

      this.newLocation = {
        locationName: organization?.location,
        latitude: organization?.formatted_location?.lat,
        longitude: organization?.formatted_location?.lon,
        country: organization?.country,
        city: organization?.city,
        zip: organization?.zip
      };
    },
    saveOrganization(organization) {
      this.organization = { ...this.organization, ...organization };
      this.fillForm(organization);
    },
    saveLocation(location) {
      this.location = {...location};
      this.settingLocation = false;
      this.settingSite = true;
    },
    deleteAttachment(id) {
      this.organization.attachments = this.organization.attachments.filter(
        (item) => item.id !== id
      );
    },
    deletePartner(id) {
      this.organization.partners = this.organization.partners.filter(
        (item) => item.id !== id
      );
    },
    onUpdatePartnerLogo() {
      this.fetchOrganizationInfo();
    },
    setSitesLocation() {
      this.settingLocation = true;
    },
    uploadFile(file) {
      this.areaFile = file;
      this.settingSite = true;
    },
    closeLocationComponent() {
      this.changeCurrentLocation = false;
      this.settingLocation = false;
    },
    updateLocation(location) {
      this.newLocation = {...location};
      this.changeCurrentLocation = false;
      this.settingLocation = false;
    },
    backToSites() {
      this.refreshSites = !this.refreshSites;
      this.settingSite = false;
    },
    saveSite() {
      this.location = null;
      this.areaFile = null;
      this.refreshSites = !this.refreshSites;
      this.settingSite = false;
    },
    goToSetLocation(form) {
      this.changeCurrentLocation = true;
      this.settingLocation = true;
      this.newLocation = null;
      this.form = form;
    }
  }
};
</script>
