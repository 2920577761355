<template>
  <div>
    <div v-if="loading">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else class="arkCard">
      <div class="row">
        <div class="col-9">
          <div class="DesktopTextMedium secondary7_ark">Goals</div>
        </div>
        <div class="col-3">
          <div v-if="isAdmin" class="d-flex justify-content-end">
            <button
              class="btn btn-link p-0 m-0"
              :aria-label="`Add ${model} goal`"
              @click="createGoal"
            >
              <i
                class="far fa-plus edit-icon"
                style="font-size: 1.5em"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
      <div v-if="goals && goals.length">
        <div
          v-for="goal in sortGoals()"
          :key="goal.id"
          class="mb-4"
        >
          <div class="row">
            <div class="col-1">
              <div
                v-if="goal.ark_metrics?.length + goal.iris_metrics?.length > 0 || isAdmin"
                class="DesktopLinkSmall d-flex justify-content-start pointer p-1"
                tabindex="0"
                role="button"
                aria-label="my label"
                @click="toggleGoalDetails(goal.id)"
                @keydown.enter="toggleGoalDetails(goal.id)"
              >
                <i
                  class="grayscaleOffBlack"
                  :class="openedGoals.includes(goal.id) ? 'far fa-chevron-down' : 'far fa-chevron-right pl-1'"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div
              class="col-8 col-sm-10 pointer"
              tabindex="0"
              role="button"
              aria-label="my label"
              @click="showGoalInfoModal(goal)"
              @keydown.enter="showGoalInfoModal(goal)"
            >
              <div class="row">
                <div class="col pl-0 d-flex align-items-start">
                  <span class="DesktopLinkSmall grayscaleOffBlack">{{ goal.name }}</span>
                  <span
                    class="DesktopTextXSmall grayscaleLabel pl-1"
                    v-if="goal.ark_metrics?.length + goal.iris_metrics?.length > 0"
                  >
                    {{goal.ark_metrics?.length + goal.iris_metrics?.length}}
                  </span>
                </div>
                <div
                  class="col-md-4 pl-0"
                  v-if="goal.sdg_goals && goal.sdg_goals.length > 0"
                >
                  <div v-if="goal.sdg_goals && goal.sdg_goals.length > 0">
                    <img
                      v-for="sdg in goal.sdg_goals.sort((a, b) => {
                        return a.sdg_goal_code - b.sdg_goal_code;
                      })"
                      :key="sdg.sdg_goal_code"
                      class="img-fluid shadow border-radius-lg pr-1 pb-1"
                      :src="sdg.avatar"
                      :alt="sdg.title"
                      style="height: 30px; width: 30px"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-sm-1 d-flex justify-content-end">
              <div v-if="isAdmin">
                <button
                  class="btn btn-link p-0 m-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fal fa-ellipsis-h-alt fa-lg" aria-hidden="true" />
                </button>
                <div class="d-flex justify-content-end">
                  <div class="dropdown-menu dropdown-primary">
                    <button
                      class="dropdown-item w-100 m-0"
                      @click="setKeyResultMetrics(goal.id)"
                    >
                      Metrics ...
                    </button>
                    <hr class="m-1">
                    <button
                      class="dropdown-item w-100 m-0"
                      @click="updateGoal(goal.id)"
                    >
                      Edit goal
                    </button>
                    <button
                      class="dropdown-item w-100 m-0"
                      @click="setDeleteGoalModal(goal, true)"
                    >
                      Delete goal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="openedGoals.includes(goal.id)">
            <div v-if="goal.iris_metrics">
              <div
                v-for="irisMetric in goal.iris_metrics"
                :key="irisMetric.id"
              >
                <div class="row py-1 mh-40px">
                  <div
                    class="col-8 offset-1 pl-0 pointer border-bottom"
                    tabindex="0"
                    role="button"
                    aria-label="my label"
                    @click="showMetric(irisMetric, goal)"
                    @keydown.enter="showMetric(irisMetric, goal)"
                  >
                    <span class="DesktopTextSmall grayscaleOffBlack">{{ irisMetric.title }}</span>
                    <p class="MobileTextXSmall grayscaleLabel mt-1">
                      {{ irisMetric.status?.name }}
                    </p>
                  </div>
                  <div class="col-1 border-bottom"></div>
                  <div class="col-2 border-bottom d-flex justify-content-end align-items-start pr-2">
                    <metric-plot-card
                      :mini-chart="true"
                      :single-metric="true"
                      :metric="{
                        ...irisMetric,
                        goalId: goal.id,
                        goalName: goal.name,
                        startDate: goal.start_date,
                        endDate: goal.end_date
                      }"
                      :entries-changed-indicator="entriesChangedIndicator"
                      :is-admin="isAdmin"
                      @open-detail-modal="openDetailDataModal"
                      @open-add-data-modal="addMetricEntry(irisMetric, goal, 'iris')"
                    />
                    <div v-if="isAdmin">
                      <button
                        class="textButton"
                        data-toggle="dropdown"
                        aria-label="Metric options"
                      >
                        <i
                          class="far fa-ellipsis-h fa-lg"
                          aria-hidden="true"
                        />
                      </button>
                      <div class="d-flex justify-content-end">
                        <div class="dropdown-menu dropdown-primary">
                          <button
                            class="dropdown-item w-100 m-0"
                            @click="addMetricEntry(irisMetric, goal, 'iris')"
                          >
                            Add data
                          </button>
                          <button
                            class="dropdown-item w-100 m-0"
                            @click="
                              deleteAssociation(irisMetric, goal, 'iris_metrics')
                            "
                          >
                            Remove metric
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="goal.ark_metrics">
              <div
                v-for="arkMetric in goal.ark_metrics"
                :key="arkMetric.id"
              >
                <div class="row py-1 mh-40px">
                  <div
                    class="col-8 offset-1 pl-0 pointer border-bottom"
                    tabindex="0"
                    role="button"
                    aria-label="my label"
                    @click="showMetric(arkMetric, goal)"
                    @keydown.enter="showMetric(arkMetric, goal)"
                  >
                    <span class="DesktopTextSmall grayscaleOffBlack">{{ arkMetric.title }}</span>
                    <p class="MobileTextXSmall grayscaleLabel mt-1">
                      {{ arkMetric.status?.name }}
                    </p>
                  </div>
                  <div class="col-1 border-bottom"></div>
                  <div class="col-2 border-bottom d-flex justify-content-end align-items-start pr-2">
                    <metric-plot-card
                      :mini-chart="true"
                      :single-metric="true"
                      :metric="{
                        ...arkMetric,
                        goalId: goal.id,
                        goalName: goal.name,
                        startDate: goal.start_date,
                        endDate: goal.end_date
                      }"
                      :entries-changed-indicator="entriesChangedIndicator"
                      :is-admin="isAdmin"
                      @open-detail-modal="openDetailDataModal"
                      @open-add-data-modal="addMetricEntry(arkMetric, goal, 'ark')"
                    />
                    <div v-if="isAdmin" class="d-flex justify-content-end">
                      <button
                        class="textButton"
                        data-toggle="dropdown"
                        aria-label="Metric options"
                      >
                        <i
                          class="far fa-ellipsis-h fa-lg"
                          aria-hidden="true"
                        />
                      </button>
                      <div class="d-flex justify-content-end">
                        <div class="dropdown-menu dropdown-primary">
                          <button
                            class="dropdown-item w-100 m-0"
                            @click="addMetricEntry(arkMetric, goal, 'ark')"
                          >
                            Add data
                          </button>
                          <button
                            class="dropdown-item w-100 m-0"
                            @click="editCustomMetric(arkMetric, goal.id)"
                          >
                            Edit metric
                          </button>
                          <button
                            class="dropdown-item w-100 m-0"
                            @click="
                              deleteAssociation(arkMetric, goal, 'ark_metrics')
                            "
                          >
                            Remove metric
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isAdmin" class="row">
              <div class="col-2 offset-1 pl-0">
                <button
                  class="textButton"
                  @click="setKeyResultMetrics(goal.id)"
                >
                  <i
                    class="far fa-plus primaryDarkmode fa-lg pr-1"
                    aria-hidden="true"
                  />
                  Add metrics
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <no-data-card
          :first-message="
            isAdmin ?
              'You have not added any goals yet' :
              `No goals have been added yet`
          "
          :second-message="
            isAdmin ?
              'Attract visitors by featuring up to 21 goals that showcase your impact and approach' :
              'Goals that are shared to showcase impact will be displayed here'
          "
          button-text="Add a goal"
          :show-button="isAdmin"
          @button-click="createGoal"
        />
      </div>
      <avl-modal
        v-if="deleteGoalModalOpen"
        :is-open="deleteGoalModalOpen"
        size="fit-content"
        header-title=""
        @avleventclose="setDeleteGoalModal"
      >
        <div v-if="targetItem?.name" class="delete-modal-title">
          {{ `Delete goal: ${targetItem.name || ""}?` }}
        </div>
        <div class="delete-modal-body mt-3">
          Are you sure you want to permanently remove this goal from THE ARK?
        </div>
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-12 text-right">
            <button
              type="button"
              class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
              @click="() => setDeleteGoalModal()"
            >
              <div class="cancel-button">Cancel</div>
            </button>
            <button
              type="button"
              class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
              style="text-transform: none"
              @click="() => deleteGoal(targetItem?.id)"
            >
              <div class="delete-button">Remove</div>
            </button>
          </div>
        </div>
      </avl-modal>
      <metric-entries-form
        v-if="newMetricEntryModalOpen"
        :metric="targetItem"
        :metric-type="metricType"
        :goal-id="selectedGoalData.id"
        :goal-start-date="selectedGoalData.start_date"
        :goal-end-date="selectedGoalData.end_date"
        :new-metric-entry-modal-open="newMetricEntryModalOpen"
        @modal-closed-event="closeNewMetricEntryModal"
        @entry-data-changed="entryDataChanged"
      />
      <delete-association-modal
        v-if="deleteAssociationModalOpen"
        :delete-association-modal-open="deleteAssociationModalOpen"
        :metric-info="targetItem"
        @delete-association-modal-closed-event="deleteAssociationModalClosed"
        @entry-data-changed="entryDataChanged"
      />
      <create-metric
        v-if="editMetricModalOpen"
        :create-metric-modal-open="editMetricModalOpen"
        mode="edit"
        :custom-metric-info="targetItem"
        :measurement-options="measurementOptions"
        :entry-frequency-options="entryFrequencyOptions"
        :auditor-options="auditorOptions"
        @create-metric-modal-closed-event="closeEditMetricModal"
        @entry-data-changed="entryDataChanged"
      />
      <metric-detail-data-modal
        v-if="openMetricDetailDataModal"
        :metric-data="targetItem"
        :open-metric-detail-data-modal="openMetricDetailDataModal"
        @modal-closed-event="closeDetailDataModal"
      />
      <info-modal
        v-if="goalInfoModalOpen"
        :info-modal-open="goalInfoModalOpen"
        :info="{ name: targetItem.name, description: targetItem.description }"
        :details="{
          options: ['Impact', 'Species', 'Threats'],
          badges: [
            {
              data: targetItem.iris_categories
            },
            {
              data: targetItem.species,
              color: '#68B4B9',
              link: 'species'
            },
            {
              data: targetItem.iucn_threats
            }
          ]
        }"
        @modal-closed-event="modalGoalInfoClosed"
      />
      <metric-detail-card
        v-if="metricModalOpen"
        :metric-modal-open="metricModalOpen"
        :metric="targetItem"
        :is-admin="isAdmin"
        :measurement-options="measurementOptions"
        :entry-frequency-options="entryFrequencyOptions"
        :status-options="statusOptions"
        :auditor-options="auditorOptions"
        @close-modal="metricModalOpen = false"
        @on-update="onUpdateMetric"
        @on-remove="onRemoveMetric"
      />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import LoadingIndicator from '../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../components/shared/NoDataCard.vue';
import { defineAsyncComponent } from 'vue';
import { fallbackApi } from '../../../api/config';
import { metricEntryMixin, metricOptionsMixin } from '../mixins';

const metricCurrencyTypes = [
  'Currency',
  'Reporting Currency (OD5990)'
];

export default {
  components: {
    CreateMetric: defineAsyncComponent(() => import('./CreateMetric.vue')),
    DeleteAssociationModal: defineAsyncComponent(() =>
      import('./DeleteAssociationModal.vue')
    ),
    InfoModal: defineAsyncComponent(() => import('../../../components/shared/InfoModal.vue')),
    LoadingIndicator,
    MetricEntriesForm: defineAsyncComponent(() => import('./MetricEntriesForm.vue')),
    MetricDetailCard: defineAsyncComponent(() =>
      import('../../../components/shared/MetricDetailCard.vue')
    ),
    MetricDetailDataModal: defineAsyncComponent(() => import('./MetricDetailDataModal.vue')),
    MetricPlotCard: defineAsyncComponent(() => import('./MetricPlotCard.vue')),
    NoDataCard
  },
  mixins: [metricEntryMixin, metricOptionsMixin],
  props: {
    model: {
      type: String,
      required: false,
      default: ''
    },
    modelPluralize: {
      type: String,
      required: false,
      default: ''
    },
    modelId: {
      type: String,
      required: false,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      required: true
    }
  },
  emits: ['goalDataChanged', 'entryDataChanged'],
  data() {
    return {
      metricType: '',
      openedGoals: [],
      goals: [],
      selectedGoalData: {
        id: null,
        start_date: null,
        end_date: null
      },
      supportModalOpen: false,
      supportModalMessage: '',
      newMetricEntryModalOpen: false,
      metricModalOpen: false,
      deleteAssociationModalOpen: false,
      deleteGoalModalOpen: false,
      editMetricModalOpen: false,
      openMetricDetailDataModal: false,
      goalInfoModalOpen: false,
      targetItem: undefined,
      entriesChangedIndicator: true,
      loading: true
    };
  },
  mounted() {
    this.fetchGoals();
  },
  methods: {
    showGoalInfoModal(goal) {
      this.goalInfoModalOpen = true;
      this.targetItem = goal;
    },
    toggleGoalDetails(goalId) {
      if (this.openedGoals.includes(goalId)) {
        const indexToRemove = this.openedGoals.indexOf(goalId);
        this.openedGoals.splice(indexToRemove, 1);
      } else {
        this.openedGoals.push(goalId);
      }
    },
    addMetricEntry(metric, goal, type) {
      this.targetItem = metric;
      this.metricType = type;
      this.selectedGoalData = (({ id, start_date, end_date }) =>
        ({ id, start_date, end_date }))(goal);
      this.newMetricEntryModalOpen = true;
    },
    closeNewMetricEntryModal() {
      this.newMetricEntryModalOpen = false;
    },
    modalGoalInfoClosed() {
      this.goalInfoModalOpen = false;
      this.targetItem = undefined;
    },
    setKeyResultMetrics(id) {
      location.href = `/${this.modelPluralize}/${this.modelId}/goals/${id}/metrics`;
    },
    formatDate: (date) => DateTime.fromISO(date).toFormat('yyyy LLL'),
    setDeleteGoalModal(goal = undefined, open = false) {
      this.deleteGoalModalOpen = open;
      if (!goal) this.targetItem = undefined;
      else this.targetItem = { id: goal?.id, name: goal?.name };
    },
    fetchGoals() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/goals`,
        dataSetter: ({ goals }) => this.goals = goals || [],
        loadingSetter: (loading) => this.loading = loading
      });
    },
    fetchGoal(id) {
      fallbackApi({
        url: `goals/${id}`,
        dataSetter: ({ goal }) => {
          let goalIndex = this.goals.findIndex((oldGoal) => goal.id === oldGoal.id);
          this.goals[goalIndex] = goal;
        }
      })
    },
    sortGoals() {
      return this.goals.sort((a, b) => {
        if (a.start_date !== b.start_date) {
          return new Date(a.start_date) - new Date(b.start_date);
        } else if (a.end_date !== b.end_date) {
          return new Date(a.end_date) - new Date(b.end_date);
        } else {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA === nameB) return 0;
          return nameA < nameB ? -1 : 1;
        }
      });
    },
    deleteGoal(id) {
      fallbackApi({ url: `goals/${id}`, httpVerb: 'delete' })
        .then(() => {
          this.goals = this.goals.filter(({ id: goalId }) => goalId !== id);
          this.goalDataChanged('Delete Goal');
          this.entryDataChanged('Delete Goal');
          this.setDeleteGoalModal();
        });
    },
    createGoal() {
      location.href = `/${this.modelPluralize}/${this.modelId}/goals/new`;
    },
    updateGoal(id) {
      location.href = `/${this.modelPluralize}/${this.modelId}/goals/${id}/edit`;
    },
    editCustomMetric(metric, goalId) {
      this.targetItem = { ...metric, goalId };
      this.editMetricModalOpen = true;
    },
    closeEditMetricModal(updatedMetric) {
      if (updatedMetric?.ark_metric_definition) {
        this.goals.forEach((goal) => {
          const customMetric = goal.ark_metrics.find(
            (metric) => metric.id === updatedMetric?.ark_metric_definition?.id
          );

          if (customMetric) {
            customMetric.title = updatedMetric?.ark_metric_definition?.title;
            customMetric.definition =
              updatedMetric?.ark_metric_definition?.definition;
            customMetric.iris_categories =
              updatedMetric?.ark_metric_definition?.iris_categories;
            customMetric.enumeration =
              updatedMetric?.ark_metric_definition?.enumeration;
            customMetric.sdg_goals =
              updatedMetric?.ark_metric_definition?.sdg_goals;
            customMetric.usage_guidance =
              updatedMetric?.ark_metric_definition?.usage_guidance;
            customMetric.measurement_unit =
              updatedMetric?.ark_metric_definition?.measurement_unit;
            customMetric.collected_by =
              updatedMetric?.ark_metric_definition?.collected_by;
            customMetric.reported_by =
              updatedMetric?.ark_metric_definition?.reported_by;
            customMetric.evaluated_by =
              updatedMetric?.ark_metric_definition?.evaluated_by;
            customMetric.data_source =
              updatedMetric?.ark_metric_definition?.data_source;
            customMetric.means_of_verification =
              updatedMetric?.ark_metric_definition?.means_of_verification;

            // We need to fetch the goal again, because the custom metric was updated and the SDG
            // goals might have changed as well.
            this.fetchGoal(goal.id);
          }
        });

        const { name } = updatedMetric.ark_metric_definition.measurement_unit;
        const metricID = `${updatedMetric.goalId}-custom-${updatedMetric.ark_metric_definition.id}`;
      }
      this.targetItem = undefined;
      this.editMetricModalOpen = false;
    },
    deleteAssociation(metric, goalInfo, irisOrCustom) {
      this.targetItem = {
        ...metric,
        goalName: goalInfo.name,
        goalId: goalInfo.id,
        irisOrCustom
      };
      this.deleteAssociationModalOpen = true;
    },
    deleteAssociationModalClosed(deletedMetricInfo, irisOrCustom = 'iris_metrics') {
      this.targetItem = undefined;
      this.deleteAssociationModalOpen = false;
      if (!deletedMetricInfo?.goalId) return;

      const target = this.goals.find((goal) => {
        return goal.id === deletedMetricInfo.goalId;
      });
      if (target) {
        target[irisOrCustom] = target[irisOrCustom].filter(
          (metric) => metric.id !== deletedMetricInfo.id
        );
      }
    },
    openDetailDataModal(metricData) {
      this.targetItem = metricData;
      this.openMetricDetailDataModal = true;
    },
    closeDetailDataModal() {
      this.targetItem = undefined;
      this.openMetricDetailDataModal = false;
    },
    showMetric(metric, goal) {
      this.targetItem = metric;
      this.targetItem.goalId = goal.id;
      this.targetItem.goalName = goal.name;
      if (metricCurrencyTypes.includes(metric.format)) {
        this.targetItem.measurement_unit = { id: 0, name: 'Euros' };
      }
      this.metricModalOpen = true;
    },
    onUpdateMetric(updatedData) {
      if (this.targetItem.entry_frequency.id !== updatedData.entry_frequency.id) {
        this.entryDataChanged('Update Metric');
      }

      this.targetItem.measurement_unit = updatedData.measurement_unit;
      this.targetItem.entry_frequency = updatedData.entry_frequency;
      this.targetItem.status = updatedData.status;
      this.targetItem.collected_by = updatedData.collected_by;
      this.targetItem.reported_by = updatedData.reported_by;
      this.targetItem.evaluated_by = updatedData.evaluated_by;
      this.targetItem.data_source = updatedData.data_source;
      this.targetItem.means_of_verification = updatedData.means_of_verification;
      this.metricModalOpen = false;
    },
    onRemoveMetric() {
      const { goalId, goalName } = this.targetItem;
      this.deleteAssociation(
        this.targetItem,
        { id: goalId, name: goalName },
        this.targetItem.iris_metric_type ? 'iris_metrics' : 'ark_metrics'
      );
      this.metricModalOpen = false;
    },
    goalDataChanged(reason) {
      this.$emit('goalDataChanged', reason);
    },
    entryDataChanged(reason) {
      this.$emit('entryDataChanged', reason);
      // We want the list of metrics to be updated first, when one is deleted
      setTimeout(() => this.entriesChangedIndicator = !this.entriesChangedIndicator, 1);
    }
  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
