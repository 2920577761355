<template>
  <div>
    <all-results
      v-if="tab === 'all'"
      :search="search"
      @tab-changed="tabChanged"
    />
    <project-results
      v-else-if="tab === 'projects'"
      :search="search"
      @tab-changed="tabChanged"
    />
    <organization-results
      v-else-if="tab === 'organizations'"
      :search="search"
      @tab-changed="tabChanged"
    />
    <post-results
      v-else-if="tab === 'top_news'"
      :search="search"
      @tab-changed="tabChanged"
    />
    <entity-results
      v-else
      :search="search"
      :tab="tab"
      @tab-changed="tabChanged"
    />
  </div>
</template>

<script>
import AllResults from './AllResults.vue';
import EntityResults from './EntityResults.vue';
import OrganizationResults from './OrganizationResults.vue';
import PostResults from './PostResults.vue';
import ProjectResults from './ProjectResults.vue';

export default {
  name: 'SearchContainer',
  components: { AllResults, EntityResults, OrganizationResults, PostResults, ProjectResults },
  data() {
    return {
      tab: 'all',
      search: ''
    };
  },
  beforeMount() {
    const params = new URLSearchParams(window.location.search);
    this.search = params.get('search') || '';
  },
  methods: {
    tabChanged(tab) {
      this.tab = tab;
    }
  }
};
</script>
