import MapButton from './components/MapButton.vue';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#map-link')) {
    const app = createApp(MapButton);
    app.use(store);
    app.mount('#map-link');
  }
});