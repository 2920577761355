<template>
  <section class="arkCard">
    <header class="row">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">Partners</h2>
      </section>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <a
          class="p-0 m-0 pointer"
          aria-label="Edit partners section"
          :href="`/${modelPluralize}/${modelId}/edit?tab=partners`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <ul class="row listStyleNone pl-0 pt-2 mb-0">
      <li
        v-for="(partner, index) in showPartners"
        :key="partner.name + index"
        class="mb-4"
        :class="showAll ? 'col-12' : 'col-12 col-sm-6'"
      >
          <a
            :href="`/organizations/${partner.partneree_id}`"
            class="d-flex"
            target="_self"
            v-if="partner.in_ark"
          >
            <partner-item
              :model-pluralize="modelPluralize"
              :model-id="modelId"
              :is-admin="isAdmin"
              :organization="organization"
              show-all
              :partner="partner"
            />
          </a>
          <div v-else>
            <partner-item
              :model-pluralize="modelPluralize"
              :model-id="modelId"
              :is-admin="isAdmin"
              :organization="organization"
              show-all
              :partner="partner"
            />
          </div>
      </li>
    </ul>
    <show-more
      v-if="partners?.length > 2 && !showAll"
      :text="'Show all ' + partners?.length + ' partners'"
      :link="`/${modelPluralize}/${modelId}/partners`"
    />
  </section>
</template>

<script>
import ShowMore from '../../../../components/shared/ShowMore.vue';
import { fallbackApi } from '../../../../api/config';
import { sharedMixin } from '../../../../mixins';
import PartnerItem from './PartnerItem.vue';

export default {
  components: { ShowMore, PartnerItem },
  mixins: [sharedMixin],
  props: {
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: true
    },
    showAll: {
      type: Boolean,
      required: false,
      default: false
    },
    organization: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      partners: []
    };
  },
  computed: {
    showPartners() {
      return (
        this.showAll ? this.partners : this.partners?.slice(0,2)
      ) || [];
    }
  },
  mounted() {
    if (this.organization.partners) {
      this.partners = this.organization.partners;
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/lists.css"></style>
