import { fallbackApi } from '../api/config';

export const userMixin = {
  data() {
    return {
      user: {},
      loadingUserInfo: true
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.loadingUserInfo = true;
      fallbackApi({
        url: `users/${this.userId}`,
        dataSetter: (data) => {
          this.user = data.user;
          if (this.setExtraUserInfo) this.setExtraUserInfo(data);
          this.loadingUserInfo = false;
        }
      });
    },
    followUnfollow(status) {
      this.user.is_following = status;
    },
    updateHeaderImage(key, image) {
      this.$store.commit('updateUserLogoOrBanner', { key: key, avatar: image });
      this.user[key] = image;
    }
  }
};
