<template>
  <section class="backNav pb-3 mb-2">
    <div class="row justify-content-center mx-0">
      <nav class="col-12 col-lg-10">
        <button
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          @click="back"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </button>
      </nav>
    </div>
    <header class="row justify-content-center mx-0">
      <div class="col-12 col-lg-10 d-flex align-items-center">
        <img
          :src="images.collaborate"
          class="mr-2 noDataImage"
          alt="Globe"
          height="50"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">
          Click on the map to select a specific location
          <span v-if="protectedAreas">
            OR on <i class="fas fa-leaf" aria-label="Show/hide protected areas button" />
            to choose a location from the World Database of Protected Areas (WDPA)
          </span>.
        </h1>
      </div>
    </header>
  </section>
  <main class="row justify-content-center mx-0">
    <section class="col-12 col-lg-10 arkCard">
      <section class="row align-items-center justify-content-between">
        <div
          id="geosearch"
          class="DesktopTextSmall"
          :class="locationNameInput ? 'col-12 col-lg-4 col-xl-4' : 'col-12 col-lg-12 col-xl-8'"
        />
        <div v-if="locationNameInput" class="col-12 col-lg-8 col-xl-4 mt-2 mt-lg-0">
          <input
            v-model="locationName"
            type="text"
            class="form-control ark DesktopTextSmall"
            placeholder="Location name"
            aria-label="Location name"
          >
        </div>
        <div class="col-12 col-lg-12 col-xl-4 text-right">
          <button
            v-if="removeVisible"
            class="
              btn
              DesktopLinkXSmall
              secondaryButton
              primaryDefault
              boxShadowNone
              rowButton
            "
            @click="removeLocation"
          >
            Remove
          </button>
          <button
            class="
              btn
              ml-2
              DesktopLinkXSmall
              grayscaleOffWhite
              primaryButton
              rowButton
            "
            :disabled="saveDisabled"
            @click="save"
          >
            Save
          </button>
          <button
            class="btn ml-2 DesktopLinkXSmall rowButton cancelButton"
            @click="back"
          >
            Cancel
          </button>
        </div>
      </section>
      <div id="setLocationMap" class="w-100 my-3" style="height: 900px;" />
      <div v-if="protectedAreas" id="protected-areas-btn">
        <button
          class="btn btn-secondary esri-widget--button esri-widget"
          :class="{ selected: protectedAreasEnabled }"
          aria-label="Show/hide protected areas"
        >
          <i class="fas fa-leaf" aria-hidden="true" />
        </button>
      </div>
      <span v-if="longitude !== null && latitude !== null">
        <i class="fas fa-map-marker-alt" aria-hidden="true" />
        Location: {{ latitude }}, {{ longitude }}
      </span>
    </section>
  </main>
</template>

<script>
/* global Sentry */

import { pointSymbolConfig, protectedAreasPopupTemplate } from '../../features/map/helpers/consts';
import { fallbackApi } from '../../api/config';
import { loadModules } from 'esri-loader';

export default {
  props: {
    images: {
      type: Object,
      required: true
    },
    location: {
      type: Object,
      required: false,
      default: () => {}
    },
    locationNameInput: {
      type: Boolean,
      required: false,
      default: true
    },
    protectedAreas: {
      type: Boolean,
      required: false,
      default: false
    },
    reverseGeocode: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['back', 'save'],
  data() {
    return {
      protectedAreasEnabled: false,
      esriToken: null,
      latitude: null,
      longitude: null,
      locationName: '',
      country: '',
      countryCode: '',
      city: '',
      zip: '',
      area: 0,
      wdpaName: '',
      feature: null
    };
  },
  mounted() {
    loadModules([
      'esri/views/MapView',
      'esri/WebMap',
      'esri/config',
      'esri/Graphic',
      'esri/layers/FeatureLayer',
      'esri/widgets/Search'
    ])
      .then(async ([
        MapView,
        WebMap,
        esriConfig,
        Graphic,
        FeatureLayer,
        Search
      ]) => {
        let minZoom = 3;
        const windowWidth = window.innerWidth;
        if (windowWidth > 2049) minZoom = 4;

        const arkMap = new WebMap({
          portalItem: {
            id: '2db26f96006441aaad38e93a318e0c38'
          }
        });

        const view = new MapView({
          map: arkMap,
          // Longitude, latitude
          center: [this.location?.longitude || 10, this.location?.latitude || 40],
          container: 'setLocationMap',
          zoom: minZoom
        });

        view.constraints = { minZoom };

        /**
         * Move and add widgets on the map viewport
         */
        view.ui.move(['zoom'], 'top-left');

        this.$esri = { view };

        // Add protected areas layers
        let pointLayer = null, polygonLayer = null;
        if (this.protectedAreas) {
          pointLayer = new FeatureLayer({
            url: 'https://services5.arcgis.com/Mj0hjvkNtV7NRhA7/arcgis/rest/services/WDPA_v0/FeatureServer/0',
            popupTemplate: protectedAreasPopupTemplate,
            renderer: {
              type: 'simple',
              symbol: {
                type: 'simple-marker',
                style: 'circle',
                color: [7, 112, 1, 200],
                size: 5,
                outline: {
                  color: [9, 160, 1, 200],
                  width: 1
                }
              }
            },
            outFields: ['*'],
            visible: this.protectedAreasEnabled
          });

          polygonLayer = new FeatureLayer({
            url: 'https://services5.arcgis.com/Mj0hjvkNtV7NRhA7/arcgis/rest/services/WDPA_v0/FeatureServer/1',
            popupTemplate: protectedAreasPopupTemplate,
            renderer: {
              type: 'simple',
              symbol: {
                type: 'simple-fill',
                color: [7, 112, 1, 200],
                outline: {
                  color: [9, 160, 1, 200],
                  width: 1
                }
              }
            },
            outFields: ['*'],
            visible: this.protectedAreasEnabled
          });

          arkMap.addMany([polygonLayer, pointLayer]);

          const selectArea = () => {
            const feature = view.popup.selectedFeature;
            if (feature.geometry.type === 'polygon') {
              this.area = feature.attributes.gis_area;
              this.feature = feature.toJSON();
            } else {
              // Get the first point of the multipoint feature
              const firstPoint = new Graphic({
                geometry: {
                  type: 'point',
                  x: feature.geometry.points[0][0],
                  y: feature.geometry.points[0][1],
                  spatialReference: feature.geometry.spatialReference
                },
                attributes: feature.attributes
              });
              this.feature = firstPoint.toJSON();
              this.area = 0;
            }

            this.wdpaName = feature.attributes.name;
            this.latitude = null;
            this.longitude = null;

            this.save();
          };

          view.popup.watch('selectedFeature', () => {
            if (!view.popup.selectedFeature) {
              return;
            }

            // Wait for popup to open
            setTimeout(() => {
              const btn = document.getElementById('select-area-btn');
              if (btn) {
                btn.removeEventListener('click', selectArea);
                btn.addEventListener('click', selectArea);
              }
            }, 500);
          });

          const protectedAreasBtn = document.getElementById('protected-areas-btn');
          protectedAreasBtn.querySelector('button').addEventListener('click', () => {
            pointLayer.visible = !this.protectedAreasEnabled;
            polygonLayer.visible = !this.protectedAreasEnabled;
            this.protectedAreasEnabled = !this.protectedAreasEnabled;
          });

          view.ui.add(protectedAreasBtn, 'top-left');
        }

        let selectedPoint = null;
        if (this.location?.latitude && this.location?.longitude) {
          selectedPoint = new Graphic({
            geometry: {
              type: 'point',
              latitude: this.location.latitude,
              longitude: this.location.longitude
            },
            symbol: pointSymbolConfig
          });
          view.graphics.add(selectedPoint);

          this.locationName = this.location.locationName;
          this.latitude = this.location.latitude;
          this.longitude = this.location.longitude;
          this.feature = selectedPoint.toJSON();
        }

        // Get oauth2 token to use geocoding in search widget
        let token = null;
        await fallbackApi({
          url: 'esri_oauth2_token',
          dataSetter: (data) => token = data.access_token
        });

        esriConfig.apiKey = token;
        this.esriToken = token;

        const searchWidget = new Search({
          view: view,
          container: 'geosearch',
          sources: [{
            // Default source url
            url: 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer',
            name: 'ArcGIS World Geocoding Service',
            placeholder: 'Search',
            resultSymbol: pointSymbolConfig
          }],
          includeDefaultSources: false,
          popupEnabled: false
        });


        searchWidget.on('select-result', (event) => {
          view.graphics.remove(selectedPoint);

          this.locationName = event.result.name;
          this.longitude = event.result.feature.geometry.longitude;
          this.latitude = event.result.feature.geometry.latitude;
          this.area = 0;
          this.wdpaName = '';
          this.feature = event.result.feature.toJSON();
        });

        searchWidget.on('search-clear', () => {
          view.graphics.remove(selectedPoint);

          this.locationName = '';
          this.longitude = null;
          this.latitude = null;
          this.area = 0;
          this.wdpaName = '';
          this.feature = null;
        });

        view.on('click', (event) => {
          const selectPoint = () => {
            this.longitude = event.mapPoint.longitude;
            this.latitude = event.mapPoint.latitude;
            this.area = 0;
            this.wdpaName = '';

            selectedPoint = new Graphic({
              geometry: event.mapPoint,
              symbol: pointSymbolConfig
            });

            this.feature = selectedPoint.toJSON();

            view.graphics.add(selectedPoint);
          };

          view.graphics.removeAll();
          if (this.protectedAreasEnabled) {
            view.hitTest(event, { include: [pointLayer, polygonLayer] })
              .then((response) => {
                if (response.results.length === 0) {
                  selectPoint();
                }
              });
          } else {
            selectPoint();
          }

        });
      })
      .catch((err) => {
        // handle any errors
        Sentry && Sentry.captureException(err);
        console.error(err);
      });
  },
  computed: {
    removeVisible() {
      return !this.required && (this.longitude !== null || this.latitude !== null);
    },
    saveDisabled() {
      return this.required && (!this.longitude || !this.latitude);
    }
  },
  methods: {
    back() {
      this.$emit('back');
    },
    removeLocation() {
      this.latitude = null;
      this.longitude = null;
      this.area = 0;
      this.wdpaName = '';
      this.feature = null;
      this.$esri.view.graphics.removeAll();
    },
    async save() {
      if (this.reverseGeocode) {
        await fallbackApi({
          url: 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
          payload: {
            searchParams: {
              token: this.esriToken,
              f: 'json',
              location: JSON.stringify({
                x: this.longitude,
                y: this.latitude,
                spatialReference: { wkid: 4326 }
              }),
              langCode: 'en'
            },
            prefixUrl: '',
            // Remove our api token from header
            headers: {
              'Authorization': undefined
            }
          },
          dataSetter: (data) => {
            this.country = data.address?.CntryName || '';
            this.countryCode = data.address?.CountryCode || '';
            this.city = data.address?.City || '';
            this.zip = data.address?.Postal || '';
          }
        });
      }

      const locationData = { ...this.$data };
      delete locationData.esriToken;
      this.$emit('save', locationData);
    }
  }
};
</script>

<style scoped>
.backNav {
  margin-top: -15px;
  margin-right: -15px;
  margin-left: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .backNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}

.rowButton {
  width: 100px;
  padding: 0.40625rem 1.25rem; /* same as primaryButton */
}

/* Style from .form-control.ark */
.esri-search {
  width: 100%;
}

:deep(.esri-search__container) {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

:deep(.esri-search__container:focus-within) {
  border-color: #24422B;
  box-shadow: 0px 0px 4px 2px #A4B9B4 !important;
}

:deep(.esri-search__input) {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  border-radius: 0.25rem;
}

:deep(.esri-search__submit-button) {
 border-top-right-radius: 0.25rem;
 border-bottom-right-radius: 0.25rem;
}

:deep(.esri-widget) {
  border-radius: 5px;
  box-shadow: none;
}

#protected-areas-btn button {
  color: #24422B !important;
}

#protected-areas-btn button:hover {
  color: #FFFFFF !important;
}

#protected-areas-btn button.selected, #protected-areas-btn button:active {
  background-color: #24422B !important;
  color: #FFFFFF !important;
}
</style>
