import ForgotPassword from './components/ForgotPassword.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#forgot-password')){
    const app = createApp(ForgotPassword);
    app.use(store);
    app.mount('#forgot-password');
  }
});
