class InvitationHelper {
  static setupModal($modal) {
    $modal.find('#form__add_invitation').click(() => InvitationHelper.addInvitation($modal));
    $modal.find('#invitation_email').on('keydown', (event) => {
      if(event.key === 'Enter') {
        event.preventDefault();
        InvitationHelper.addInvitation($modal);
      }
    });
  }

  static addInvitation($modal) {
    const invitation_email = $modal.find('#invitation_email');
    const form__invitations = $modal.find('#form__invitations');
    if(invitation_email.val().length === 0) {
      return;
    }

    var new_id = 'invitation_' + InvitationHelper.create_UUID();
    form__invitations.find("tr:last").after(
      `<tr id="${new_id}">`+
      `<td class="invitation_email_cell"><input name="invitations[][email]" type="text" class="form-control-plaintext ark-p2" value="${invitation_email.val()}" readonly></td>`+
      '<td class="td-actions text-right" style="justify-content: flex-end!important;">'+
      `<button type="button" rel="tooltip" class="btn btn-danger btn-link invitation_delete" id="${new_id+"_delete"}">`+
      '<i class="material-icons">delete</i>'+
      '</button>'+
      '</td>'+
      '</tr>'
      );

    form__invitations.find('thead').removeClass('invisible');
    invitation_email.val('');

    InvitationHelper.addRemoveInvitationListener();
  }

  static create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  static addRemoveInvitationListener() {
  $('.invitation_delete').off('click');
  $('.invitation_delete').on('click', (evt) => {
    const deleteBtn = evt.currentTarget;
    $(deleteBtn).closest('tr').remove();
    evt.stopPropagation(); // No bubbling
  });
}
}

let invitationsModal = $('#invitationsModal');
if(invitationsModal.length > 0) {
  InvitationHelper.setupModal(invitationsModal);
}

export { InvitationHelper };
