<template>
  <div id="useOfFunds">
    <div class="useOfFundsNav">
      <div class="row d-flex justify-content-center mx-0">
        <div class="col-sm-12 col-md-10 col-lg-8 mt-3 px-0">
          <a
            id="useOfFundsBackTag"
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
            "
            :href="`/projects/${projectId}`"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />&nbsp;&nbsp;&nbsp; Back
          </a>
        </div>
      </div>
      <div class="row d-flex justify-content-center mx-0">
        <div
          class="col-sm-12 col-md-10 col-lg-8 my-3 DesktopTextSmall grayscaleOffBlack"
        >
          <img
            :src="imageUrl"
            class="mr-2 noDataImage"
            alt=""
            aria-hidden="true"
          >
          Provide details on use of funds.
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="arkCard">
          <div class="DesktopTextMedium textBodyColor mb-3">
            Use of funds
          </div>
          <div class="DesktopTextSmall grayscaleOffBlack mb-5">
            Add an estimate of the budget percent spent per category
          </div>
          <div class="DesktopTextSmall grayscaleLabel mb-2">
            Category
          </div>
          <hr>
          <div v-for="category in budgetCategories" :key="category.name">
            <div class="row DesktopTextSmall grayscaleOffBlack">
              <div class="col-5">
                {{ category.name }}
              </div>
              <div class="col-7">
                <input
                  v-model="category.value"
                  :class="`
                    textField
                    grayscaleLabel
                    ${sumError || categoryErrors[category.name] ? 'in' : ''}valid
                  `"
                  type="number"
                  :aria-label="`${category.name} input`"
                >
                %
              </div>
            </div>
            <error-message
              :error-condition="categoryErrors[category.name]"
              error-message="* All percentages must be a non-negative number"
            />
            <hr>
          </div>
          <error-message
            :error-condition="sumError"
            error-message="* The sum of the percentages cannot exceed 100%"
          />
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center">
        <button
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            primaryButton
            pointer
            finallyButton
          "
          :disabled="!isDataValid || loading"
          @click="saveFunds"
        >
          Finish
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <error-message
        :error-condition="postError"
        error-message="Something went wrong, while trying to save your Funds"
      />
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  name: 'UseOfFunds',
  components: { ErrorMessage },
  data() {
    return {
      mode: 'post',
      projectId: undefined,
      imageUrl: undefined,
      loading: false,
      sumError: false,
      postError: false,
      budgetCategories: [],
      categoryErrors: {}
    };
  },
  computed: {
    isDataValid() {
      if (this.sumError) return false;
      return !Object.values(this.categoryErrors).find((category) => category);
    }
  },
  watch: {
    budgetCategories: {
      handler(newValue) {
        newValue.forEach(({ name, value }) => {
          this.categoryErrors[name] = (value === '' || value < 0);
        });
        const sum = newValue.reduce((previous, { value }) => previous + (value || 0), 0);
        this.sumError = sum > 100;
      },
      deep: true
    }
  },
  beforeMount() {
    this.initializeData();
  },
  mounted() {
    this.getBudgetCategories();
  },
  methods: {
    initializeData() {
      const useOfFunds = document.querySelector('#use-of-funds');
      this.projectId = useOfFunds?.dataset.projectId;
      this.budgetCategories = JSON.parse(useOfFunds.dataset.categories).map((item) => {
        return {
          budget_allocation_category_id: item.id,
          name: item.name,
          value: 0
        };
      });
      this.imageUrl = useOfFunds?.dataset.imgUrl;
    },
    normalizeDataForChart(data) {
      if (!data.budget_allocations.length) {
        this.mode = 'post';
        return;
      }

      this.mode = 'patch';
      const currentYear = new Date().getFullYear();
      const fetchedCategories = data.budget_allocations
        .filter((item) => item.year === currentYear)
        .map((item) => {
          const { name, id } = item.budget_allocation_category;
          this.categoryErrors[name] = false;
          return {
            budget_allocation_category_id: id,
            name,
            value: parseFloat(item.value)
          };
        });
      this.budgetCategories = [
        ...fetchedCategories,
        ...this.budgetCategories.filter((initialCategory) =>
          !fetchedCategories.find((fetchedCategory) =>
            fetchedCategory.budget_allocation_category_id ===
              initialCategory.budget_allocation_category_id
          )
        )
      ].sort((i1, i2) => i2.value - i1.value);
    },
    getBudgetCategories() {
      fallbackApi({
        url: `projects/${this.projectId}/finances`,
        dataSetter: (data) => {
          if (data.budget) this.normalizeDataForChart(data.budget);
          else this.mode = 'post';
        }
      });
    },
    saveFunds() {
      fallbackApi({
        url: `projects/${this.projectId}/finances`,
        payload: {
          json: {
            funds: {
              categories: this.budgetCategories.map((category) => {
                return {
                  budget_allocation_category_id: category.budget_allocation_category_id,
                  value: category.value,
                  year: new Date().getFullYear()
                };
              })
            }
          }
        },
        httpVerb: this.mode,
        redirect: { url: `/projects/${this.projectId}`, when: 'onSuccess' },
        loadingSetter: (loading) => this.loading = loading,
        errorSetter: (error) => this.postError = error
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped>
#useOfFunds {
  margin-top: -15px;
}

#useOfFunds .noDataImage {
  width: 48px;
  height: 45px
}

#useOfFunds .useOfFundsNav {
  background: white;
}

#useOfFunds .finallyButton {
  padding: 9px 75px;
}

#useOfFundsBackTag {
  color: #24422B !important;
}

@media screen and (max-width: 991px) {
  #useOfFunds {
    margin-top: 10px
  }
}

.textField {
  text-align: center;
  width: 12%;
  height: 120%;
  border-radius: 4px;
}

.valid {
  border: 1px solid #CED4DA;
}
.invalid {
  border: 1px solid red;
}

.errorMessage {
  color: red;
}
</style>
