OnBoardingOrganizationHelper = class {
  constructor(submitBtn = null) {
    this.submitBtn = submitBtn;
    this.saveBtn = document.querySelector('.js-submit-form');
    this.finishBtn = document.querySelector('.btn-finish');
    this.organizationIdInput = document.querySelector('.js-organization-id');
  }

  submitForm() {
    var section = $('#section_type').attr('section') || null;
    // Check if on correct tab
    if (section !== 'org'){
      return false;
    }
    // Validate organization required fields
    var $org__validator = $('#org__form').validate({
      highlight: function(element) {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: function(element) {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
    });

    var $org__valid = $('#org__form').valid();
    if (!$org__valid) {
      $org__validator.focusInvalid();
      return false;
    }
    // Submit form
    arkUI.loadingIndicator.show();
    const form = document.querySelector('.js-organization-form');
    const formURL = form.action;
    const formData = new FormData(form);
    fetch(formURL, {
      method: form.method,
      body: formData
    }).then((response) => {
      return response.json();
    }).then((json) => {
      var notice_html = "";
      var error_html = "";
      if (json.action && json.action === "update") {
        notice_html = '<h6>Organisation updated successfully! <br ><br >Click OK to continue or LATER to keep editing.</h6>';
      } else if (json.action && json.action === "create") {
        notice_html = '<h6>Organisation created successfully! <br ><br >Click OK to continue or LATER to keep editing.</h6>';
      }

      let errors = [];
      if (json.error) {
        errors.push(json.error);
      } else if (json.errors) {
        errors = json.errors;
      }

      if (errors.length > 0) {
        error_html = 'An error occurred while saving your organization.<br ><ul>';
        errors.forEach((e) => {
          error_html += `<li>${e}</li>`
        })
        error_html += '</ul>'
        arkUI.loadingIndicator.hide();
        arkUI.alert.show({
          html: error_html
        });
      } else {
        arkUI.loadingIndicator.hide();
        arkUI.alert.show({
          html: notice_html,
          redirect: true,
          redirect_location: '/onboarding'
        });
      }

      // Attach the created user ID so we know if we need to update or create the user when
      // save is clicked.
      const organizationIdInput = document.querySelector('.js-organization-id');
      if (organizationIdInput) {
        // We need to make sure that an empty/nil value is assigned if no id is present.
        organizationIdInput.value = json.org.id || null;
      }
    }).catch((error) => {
      Sentry && Sentry.captureException(error);
    });
  }

  setupSubmit() {
    if (this.submitBtn) {
      this.submitBtn.addEventListener('click', this.submitForm);
    } else {
      this.saveBtn.addEventListener('click', this.submitForm);
    }

    if (this.finishBtn) {
      this.finishBtn.addEventListener('click', this.submitForm);
    }
  }
}
