import 'v-calendar/dist/style.css';
import CreateGoalForm from './components/CreateGoalForm.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#goal-form')){
    const app = createApp(CreateGoalForm);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#goal-form');
  }
});