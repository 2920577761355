<template>
  <nav
    role="tablist"
    class="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-4 rightBorder pb-3"
    aria-label="Navigate through tabs to update forms"
  >
    <h3 class="grayscaleOffBlack DesktopLinkMedium">{{ tabHeader }}</h3>
    <button
      v-for="item in tabOptions"
      :key="item.alias"
      :disabled="item.disabled"
      role="tab"
      class="btn col-12 d-flex justify-content-start DesktopLinkXSmall transition-02"
      :class="activeTab === item.alias ? 'editNavButtonActive' : 'editNavButton'"
      @click="tabChanged(item.alias)"
    >
    {{item.name}}
    </button>
  </nav>
</template>

<script>
export default {
  props: {
    tabHeader: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      required: true
    },
    tabOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['tabChanged'],
  data() {
    return {
      activeTab: ''
    };
  },
  watch: {
    tab() {
      this.activeTab = this.tab;
    }
  },
  mounted() {
    this.activeTab = this.tab;
  },
  methods: {
    tabChanged(tab) {
      this.activeTab = tab;
      this.$emit('tabChanged', tab);
    }
  }
};
</script>

<style scoped src="../../stylesheet/forms.css"></style>
<style scoped src="../../stylesheet/edit_detail_pages_navtabs.css"></style>
