<template>
  <div id="mapSideBar">
    <div
      role="button"
      tabindex="0"
      class="openCloseButton"
      :style="styleObject"
      @click="sideBarStatus(!openSideBar, false, false)"
      @keypress.prevent="sideBarStatus(!openSideBar, false, false)"
    >
      <i
        class="fas fa-angle-left mr-2 fa-x w-100 h-100"
        :class="
          openSideBar || openSecondSideBar ? 'fa-angle-left' : 'fa-angle-right'
        "
        aria-hidden="true"
        style="align-items: center; display: flex; justify-content: center"
      />
    </div>
    <div
      ref="sidebar"
      class="sideBarContainer"
      :class="{ 'pt-5': exitButtonOnFirstSidebar }"
      :style="styleObject"
      @scroll="scrollToBottom"
    >
      <button
        v-if="exitButtonOnFirstSidebar"
        class="btn btn-link m-0 p-0 closebtn mt-2"
        aria-label="Close sidebar"
        @click="sideBarStatus(false, false)"
      >
        <i
          class="fa fa-times"
          aria-hidden="true"
          style="font-size: 20px; font-weight: 400"
        />
      </button>
      <slot v-if="$slots.insideOfSideBar" name="insideOfSideBar" />
    </div>
    <div
      class="sideBarContainer secondSideBarContainer"
      :class="exitButtonOnSecondSidebar || backButtonOnSecondSidebar ? 'pt-5' : ''"
      :style="styleObject"
    >
      <button
        v-if="backButtonOnSecondSidebar"
        class="btn btn-link m-0 p-0 backbtn mt-2"
        aria-label="Go back"
        @click="goBack"
        @keypress.prevent="goBack"
      >
        <i
          class="fa fa-arrow-left"
          aria-hidden="true"
          style="font-size: 20px; font-weight: 400"
        />
      </button>
      <button
        v-if="exitButtonOnSecondSidebar"
        class="btn btn-link m-0 p-0 closebtn mt-2"
        aria-label="Close second sidebar"
        @click="sideBarStatus(openSideBar, !openSecondSideBar)"
      >
        <i
          class="fa fa-times"
          aria-hidden="true"
          style="font-size: 20px; font-weight: 400"
        />
      </button>
      <slot v-if="$slots.secondSideBar" name="secondSideBar" />
    </div>
    <div class="slideItems" :style="styleObject">
      <slot v-if="$slots.outsideOfSideBar" name="outsideOfSideBar" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sideBarWidth: {
      type: String,
      required: false,
      default: '420px'
    },
    openSideBar: {
      type: Boolean,
      required: true,
      default: false
    },
    openSecondSideBar: {
      type: Boolean,
      required: true,
      default: false
    },
    secondSideBarWidth: {
      type: String,
      required: false,
      default: '316px'
    },
    visibleSideBarButton: {
      type: Boolean,
      required: false,
      default: true
    },
    exitButtonOnFirstSidebar: {
      type: Boolean,
      required: false,
      default: false
    },
    exitButtonOnSecondSidebar: {
      type: Boolean,
      required: false,
      default: true
    },
    backButtonOnSecondSidebar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['sideBarStatus', 'bottomOfContainer', 'loadMore', 'goBack'],
  computed: {
    calculateCssVariables() {
      if (!this.openSideBar && !this.openSecondSideBar)
        return {
          paddingOfSlideItems: '0px',
          positionOfSecondSidebar: `${this.secondSideBarWidth} * -1`,
          positionOfSidebar: `${this.sideBarWidth} * -1`,
          positionOfButton: this.visibleSideBarButton ? '0px' : '-23px'
        };
      if (!this.openSideBar) {
        return {
          paddingOfSlideItems: this.secondSideBarWidth,
          positionOfSecondSidebar: '0px',
          positionOfSidebar: `${this.sideBarWidth} * -1`,
          positionOfButton: this.visibleSideBarButton
            ? this.secondSideBarWidth
            : '-23px'
        };
      } else if (!this.openSecondSideBar) {
        return {
          paddingOfSlideItems: this.sideBarWidth,
          positionOfSecondSidebar: `${this.secondSideBarWidth}  * -1`,
          positionOfSidebar: '0px',
          positionOfButton: this.visibleSideBarButton
            ? this.sideBarWidth
            : '-23px'
        };
      } else {
        return {
          paddingOfSlideItems: `${this.sideBarWidth} + ${this.secondSideBarWidth} + 30px`,
          positionOfSecondSidebar: `${this.sideBarWidth} + 30px`,
          positionOfSidebar: '0px',
          positionOfButton: this.visibleSideBarButton
            ? `${this.sideBarWidth} + ${this.secondSideBarWidth} + 30px`
            : '-23px'
        };
      }
    },
    styleObject() {
      return {
        '--sidebarWidth': this.sideBarWidth,
        '--secondSidebarWidth': this.secondSideBarWidth,
        '--paddingSlideItems': this.calculateCssVariables.paddingOfSlideItems,
        '--positionOfSecondSidebar':
          this.calculateCssVariables.positionOfSecondSidebar,
        '--positionOfSidebar': this.calculateCssVariables.positionOfSidebar,
        '--visibleSideBarButtonLeft': this.calculateCssVariables.positionOfButton
      };
    }
  },
  watch: {
    openSideBar() {
      if (this.openSideBar) {
        this.$refs.sidebar.scrollTo({
          top: 0,
          left: 0
        });
        this.$emit('loadMore', this.$refs.sidebar.clientHeight);
      }
    }
  },
  methods: {
    sideBarStatus(statusOfSidebar, statusOfSecondSidebar, statusOfFiltersBar) {
      this.$emit('sideBarStatus', statusOfSidebar, statusOfSecondSidebar, statusOfFiltersBar);
    },
    scrollToBottom({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollHeight - scrollTop - clientHeight <= 50) {
        this.$emit('bottomOfContainer');
      }
    },
    goBack() {
      this.$emit('goBack');
    }
  }
};
</script>

<style scoped>
.sideBarContainer {
  width: var(--sidebarWidth);
  position: fixed;
  z-index: 2;
  top: var(--sidebarTop, 59px);
  left: calc(var(--positionOfSidebar));
  bottom: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  padding-top: 10px;
  padding-bottom: 20px;
  transition: 0.2s;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

.secondSideBarContainer {
  width: var(--secondSidebarWidth);
  z-index: 1;
  transition: 0.2s;
  left: calc(var(--positionOfSecondSidebar));
}

.slideItems {
  transition: 0.2s;
  padding-left: calc(var(--paddingSlideItems));
}

.openCloseButton {
  margin-left: 0;
  transition: 0.2s;
  border: 0;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  width: 23px;
  height: 48px;
  cursor: pointer;
  border-left: 1px solid #dadce0;
  border-radius: 0 8px 8px 0;
  background: white 7px center/7px 10px no-repeat;
  position: absolute;
  z-index: 3;
  top: calc(var(--visibleSideBarButtonTop, 50vh - 24px));
  left: calc(var(--visibleSideBarButtonLeft));
}

.backbtn {
  position: absolute;
  top: 6px;
  left: 25px;
  z-index: 2;
  color: #5E768A;
  background-color: transparent;
  border: none;
}

.backbtn:hover {
  color: #24422B;
}

.closebtn {
  position: absolute;
  top: 6px;
  right: 25px;
  z-index: 2;
  color: #5E768A;
  background-color: transparent;
  border: none;
}

.closebtn:hover {
  color: #24422B;
}

@media (max-width: 1098px) {
  .sideBarContainer {
    top: var(--sidebarTop, 63px);
  }
}
@media (max-width: 991px) {
  .sideBarContainer {
    top: var(--sidebarTop, 0);
  }
}
</style>
