import UnconfirmedApplicant from './components/UnconfirmedApplicant.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#applicant-unconfirmed')){
    const app = createApp(UnconfirmedApplicant);
    app.use(store);
    app.mount('#applicant-unconfirmed');
  }
});

