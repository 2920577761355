<template>
  <div id="metricEntriesForm">
    <avl-modal
      :is-open="newMetricEntryModalOpen"
      size="80%"
      header-title=""
      footer
      @avleventclose="closeModal"
    >
      <div slot="header" class="row d-flex header">
        <div class="col-12 text-left pl-0">
          <h2 class="DesktopTextMedium primary2_ark boldPlaceholder">
            Add Data to '{{ metric.title }}' {{ metricUnit }}
          </h2>
        </div>
      </div>
      <div v-if="!supportedMetricType">
        Cannot add data to this metric type.
      </div>
      <div v-else-if="loading">Loading...</div>
      <div v-else class="table">
        <div class="row mt-3">
          <div class="col-2 text-left DesktopLinkSmall">
            Date
          </div>
          <div class="col-3 text-left DesktopLinkSmall">
            Baseline
            <div
              class="btn btn-social btn-link m-0 p-0 align-text-top"
              mousetip
              mousetip:message="Baseline"
            >
              <i class="material-icons" aria-hidden="true">info</i>
            </div>
          </div>
          <div class="col-3 text-left DesktopLinkSmall">
            Targets
            <div
              class="btn btn-social btn-link m-0 p-0 align-text-top"
              mousetip
              mousetip:message="Targets"
            >
              <i class="material-icons" aria-hidden="true">info</i>
            </div>
          </div>
          <div class="col-3 text-left DesktopLinkSmall">
            Results
            <div
              class="btn btn-social btn-link m-0 p-0 align-text-top"
              mousetip
              mousetip:message="Results"
            >
              <i class="material-icons" aria-hidden="true">info</i>
            </div>
          </div>
        </div>
        <div
          v-for="entry in entries"
          :key="`${entry.measurement_date_year}-${entry.measurement_date_period}`"
          class="row"
        >
          <div class="col-2 d-flex align-items-center">
            {{ entry.row_name }}
          </div>
          <div class="col-3 d-flex align-items-center">
            <input
              v-if="checkEntryType(metric, 'numeric')"
              v-model="entry.baseline_value"
              type="number"
              class="form-control ark DesktopTextSmall"
              placeholder=""
              :aria-label="`${entry.row_name} Baseline`"
            >
            <Multiselect
              v-else-if="checkEntryType(metric, 'boolean', 'rating')"
              v-model="entry.baseline_value"
              :placeholder="selectPlaceholder"
              :options="selectOptions"
              :can-clear="true"
              :can-deselect="true"
              :close-on-select="true"
              track-by="name"
              label="name"
              class="multiselect-ark"
              :aria-label="`${entry.row_name} Baseline`"
            />
            <Multiselect
              v-else-if="isSelectionEntry"
              v-model="entry.baseline_value"
              v-bind="selectionAttributes"
              :can-clear="true"
              :can-deselect="true"
              :close-on-select="true"
              :searchable="true"
              class="multiselect-ark"
              :aria-label="`${entry.row_name} Baseline`"
            />
          </div>
          <div class="col-3 d-flex align-items-center">
            <input
              v-if="checkEntryType(metric, 'numeric')"
              v-model="entry.target_value"
              type="number"
              class="form-control ark DesktopTextSmall"
              placeholder=""
              :aria-label="`${entry.row_name} Target`"
            >
            <Multiselect
              v-else-if="checkEntryType(metric, 'boolean', 'rating')"
              v-model="entry.target_value"
              :placeholder="selectPlaceholder"
              :options="selectOptions"
              :can-clear="true"
              :can-deselect="true"
              :close-on-select="true"
              track-by="name"
              label="name"
              class="multiselect-ark"
              :aria-label="`${entry.row_name} Target`"
            />
            <Multiselect
              v-else-if="isSelectionEntry"
              v-model="entry.target_value"
              v-bind="selectionAttributes"
              :can-clear="true"
              :can-deselect="true"
              :close-on-select="true"
              :searchable="true"
              class="multiselect-ark"
              :aria-label="`${entry.row_name} Target`"
            />
          </div>
          <div class="col-3 d-flex align-items-center">
            <div
              class="w-100"
              :mousetip="isFutureEntry(entry) || null"
              mousetip:message="Cannot enter results yet"
            >
              <input
                v-if="checkEntryType(metric, 'numeric')"
                v-model="entry.result_value"
                type="number"
                class="form-control ark DesktopTextSmall"
                placeholder=""
                :aria-label="`${entry.row_name} Results`"
                :disabled="isFutureEntry(entry)"
              >
              <Multiselect
                v-else-if="checkEntryType(metric, 'boolean', 'rating')"
                v-model="entry.result_value"
                :placeholder="selectPlaceholder"
                :options="selectOptions"
                :can-clear="true"
                :can-deselect="true"
                :close-on-select="true"
                track-by="name"
                label="name"
                class="multiselect-ark"
                :aria-label="`${entry.row_name} Results`"
                :disabled="isFutureEntry(entry)"
              />
              <Multiselect
                v-else-if="isSelectionEntry"
                v-model="entry.result_value"
                v-bind="selectionAttributes"
                :can-clear="true"
                :can-deselect="true"
                :close-on-select="true"
                :searchable="true"
                class="multiselect-ark"
                :aria-label="`${entry.row_name} Results`"
                :disabled="isFutureEntry(entry)"
              />
            </div>
          </div>
          <div
            class="col-1 d-flex align-items-center justify-content-end"
          >
            <button
              type="button"
              class="btn btn-link p-0 m-0"
              aria-label="Add comment"
              @click="openCommentModal(entry)"
            >
              <span
                class="fa-stack fa-s align-middle"
                :class="{ secondary7_ark: entry.measurement_comment?.comment }"
                aria-hidden="true"
              >
                <i class="fas fa-circle fa-stack-2x" aria-hidden="true" />
                <i class="fas fa-comment-lines fa-stack-1x fa-inverse" aria-hidden="true" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div slot="footer" class="pr-3">
        <div
          v-if="error"
          class="row d-flex justify-content-center mt-2"
        >
          <div class="col-12 text-right">
            <error-message
              :error-condition="error"
              :error-message="error"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-1">
          <div class="col-12 text-right">
            <div
              v-if="saveLoading"
              class="spinner-border primary2_ark"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <button
              v-else-if="supportedMetricType"
              type="button"
              class="btn vueModalsSaveButtons"
              @click="saveEntries"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </avl-modal>
    <comment-modal
      :comment-modal-open="commentModalOpen"
      :entry="selectedEntry"
      :goal-metric-id="metric.goal_metric_id"
      @modal-closed-event="closeCommentModal"
    />
  </div>
</template>

<script>
import CommentModal from './CommentModal.vue';
import { DateTime } from 'luxon';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../api/config';
import { metricEntryMixin } from '../mixins';

export default {
  name: 'MetricEntriesForm',
  components: {
    Multiselect,
    ErrorMessage,
    CommentModal
  },
  mixins: [metricEntryMixin],
  props: {
    metric: {
      type: Object,
      required: true
    },
    metricType: {
      type: String,
      required: true
    },
    goalId: {
      type: Number,
      required: true
    },
    goalStartDate: {
      type: String,
      required: true
    },
    goalEndDate: {
      type: String,
      required: true
    },
    newMetricEntryModalOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['modalClosedEvent', 'entryDataChanged'],
  data() {
    return {
      loading: false,
      saveLoading: false,
      error: '',
      entries: [],
      initialEntries: [],
      commentModalOpen: false,
      selectedEntry: {}
    };
  },
  computed: {
    supportedMetricType() {
      return this.checkEntryType(this.metric, 'numeric', 'boolean', 'rating', 'selection');
    },
    metricUnit() {
      return (
        this.metric.measurement_unit.name !== 'No Unit'
          ? `(${this.metric.measurement_unit.name})`
          : ''
      );
    },
    selectPlaceholder() {
      if (this.checkEntryType(this.metric, 'boolean')) {
        return 'Yes/No';
      } else if (this.checkEntryType(this.metric, 'rating')) {
        return '1-5';
      } else {
        return '';
      }
    },
    selectOptions() {
      if (this.checkEntryType(this.metric, 'boolean')) {
        return ['Yes', 'No'];
      } else if (this.checkEntryType(this.metric, 'rating')) {
        return [1, 2, 3, 4, 5];
      } else {
        return [];
      }
    },
    isSelectionEntry() {
      return (
        this.checkEntryType(this.metric, 'selection') &&
        this.metric.enumeration && (
          (Array.isArray(this.metric.enumeration) && this.metric.enumeration.length > 0) ||
          this.metric.enumeration.list
        )
      );
    },
    selectionAttributes() {
      if (this.metricType === 'ark') {
        return {
          placeholder: 'Selection',
          options: this.metric.enumeration,
          trackBy: 'name',
          label: 'name',
          mode: 'tags'
        };
      } else {
        let optionGroupsConfig = {};
        if (this.metric.enumeration?.list?.length > 0 && this.metric.enumeration.list[0].children) {
          optionGroupsConfig = {
            groupOptions: 'children',
            groupLabel: 'value',
            groupSelect: true,
            groups: true
          };
        }

        return {
          ...optionGroupsConfig,
          placeholder: this.metric.enumeration?.name,
          options: this.metric.enumeration?.list,
          trackBy: 'value',
          label: 'value',
          mode: this.metric.enumeration?.name?.includes(' one)') ? 'single' : 'tags'
        };
      }
    }
  },
  mounted() {
    this.initData();
  },
  updated() {
    window.mouseTip.start();
  },
  methods: {
    closeModal() {
      this.$emit('modalClosedEvent');
    },
    openCommentModal(entry) {
      this.selectedEntry = entry;
      this.commentModalOpen = true;
    },
    closeCommentModal(comment) {
      this.commentModalOpen = false;

      const commentEntry = this.entries.find((entry) =>
        entry.measurement_date_year === this.selectedEntry.measurement_date_year &&
        entry.measurement_date_period === this.selectedEntry.measurement_date_period
      );
      if (comment) {
        commentEntry.measurement_comment = comment;
      }
      this.selectedEntry = {};
    },
    initData() {
      this.fetchEntries();

      window.mouseTip.start();
    },
    async fetchEntries() {
      this.loading = true;
      await fallbackApi({
        url:
          `goals/${this.goalId}/${
            this.metricType === 'iris' ? 'iris_metrics' : 'ark_metric_definitions'
          }/${this.metric.id}/entry_rows`,
        dataSetter: this.setEntries,
        errorSetter: () => this.entries = []
      });
    },
    async saveEntries() {
      this.saveLoading = true;
      const deletedEntries = this.entries.filter((entry) =>
        this.isEmptyEntry(entry) && entry.id
      ).map((entry) => entry.id);

      const newEntries = this.entries.filter((entry) => !this.isEmptyEntry(entry));
      newEntries.forEach((entry) => this.singleToMultiFields(entry));

      if (deletedEntries.length > 0) {
        await fallbackApi({
          url: 'metric_entries',
          httpVerb: 'delete',
          payload: {
            json: {
              metric_entry_ids: deletedEntries
            }
          },
          errorSetter: (error) => this.error = error
        });
      }

      if (newEntries.length > 0) {
        await fallbackApi({
          url: 'metric_entries',
          httpVerb: 'post',
          payload: {
            json: {
              goal_metric_id: this.metric.goal_metric_id,
              metric_entries: newEntries
            }
          },
          errorSetter: (error) => this.error = error
        });
      }

      if (deletedEntries.length > 0 || newEntries.length > 0) {
        this.entryDataChanged('Updated entries');
      }

      this.saveLoading = false;
      this.closeModal();
    },
    /**
     * Changes an entry by copying its values from the various different
     * field types (numeric, yesno, rating, selection)
     * to a single value field for each of baseline, target and result based on the metric type.
     * @param entry: Entry to be changed
     */
    multiToSingleFields(entry) {
      if (!entry) return;

      if (this.checkEntryType(this.metric, 'numeric')) {
        entry.baseline_value = entry.baseline_numeric;
        entry.target_value = entry.target_numeric;
        entry.result_value = entry.result_numeric;
      } else if (this.checkEntryType(this.metric, 'boolean')) {
        if (entry.baseline_yesno === true) {
          entry.baseline_value = 'Yes';
        } else if (entry.baseline_yesno === false) {
          entry.baseline_value = 'No';
        } else {
          entry.baseline_value = null;
        }

        if (entry.target_yesno === true) {
          entry.target_value = 'Yes';
        } else if (entry.target_yesno === false) {
          entry.target_value = 'No';
        } else {
          entry.target_value = null;
        }

        if (entry.result_yesno === true) {
          entry.result_value = 'Yes';
        } else if (entry.result_yesno === false) {
          entry.result_value = 'No';
        } else {
          entry.result_value = null;
        }
      } else if (this.checkEntryType(this.metric, 'rating')) {
        entry.baseline_value = entry.baseline_rating;
        entry.target_value = entry.target_rating;
        entry.result_value = entry.result_rating;
      } else if (this.checkEntryType(this.metric, 'selection')) {
        const singleSelection = this.metric.enumeration?.name?.includes(' one)');
        entry.baseline_value = singleSelection ?
          entry.baseline_selection :
          entry.baseline_selection?.split(', ');
        entry.target_value = singleSelection ?
          entry.target_selection :
          entry.target_selection?.split(', ');
        entry.result_value = singleSelection ?
          entry.result_selection :
          entry.result_selection?.split(', ');
      }
    },
    /**
     * Changes an entry by copying its values from a single value field
     * to the correct field type (numeric, yesno, rating, selection)
     * for each of baseline, target and result based on the metric type
     * and deletes the value fields.
     * @param entry: Entry to be changed
     */
    singleToMultiFields(entry) {
      if (!entry) return;

      if (this.checkEntryType(this.metric, 'numeric')) {
        entry.baseline_numeric = entry.baseline_value === '' ? null : entry.baseline_value;
        entry.target_numeric = entry.target_value === '' ? null : entry.target_value;
        entry.result_numeric = entry.result_value === '' ? null : entry.result_value;
      } else if (this.checkEntryType(this.metric, 'boolean')) {
        if (entry.baseline_value === 'Yes') {
          entry.baseline_yesno = true;
        } else if (entry.baseline_value === 'No') {
          entry.baseline_yesno = false;
        } else {
          entry.baseline_yesno = null;
        }

        if (entry.target_value === 'Yes') {
          entry.target_yesno = true;
        } else if (entry.target_value === 'No') {
          entry.target_yesno = false;
        } else {
          entry.target_yesno = null;
        }

        if (entry.result_value === 'Yes') {
          entry.result_yesno = true;
        } else if (entry.result_value === 'No') {
          entry.result_yesno = false;
        } else {
          entry.result_yesno = null;
        }
      } else if (this.checkEntryType(this.metric, 'rating')) {
        entry.baseline_rating = entry.baseline_value;
        entry.target_rating = entry.target_value;
        entry.result_rating = entry.result_value;
      } else if (this.checkEntryType(this.metric, 'selection')) {
        entry.baseline_selection = Array.isArray(entry.baseline_value) ?
          entry.baseline_value.join(', ') :
          entry.baseline_value;
        entry.target_selection = Array.isArray(entry.target_value) ?
          entry.target_value.join(', ') :
          entry.target_value;
        entry.result_selection = Array.isArray(entry.result_value) ?
          entry.result_value.join(', ') :
          entry.result_value;
      }

      delete entry.baseline_value;
      delete entry.target_value;
      delete entry.result_value;
    },
    isFutureEntry(entry) {
      const intervals = this.metric.entry_frequency.intervals;
      const unit = this.metric.entry_frequency.unit;
      const now = DateTime.now();

      let currentPeriod = 1;
      if (unit && now[unit]) {
        currentPeriod = now[unit];
      } else if (intervals === 2) {
        currentPeriod = Math.ceil(now.month/6.0);
      }

      return (
        entry.measurement_date_year > now.year ||
        (entry.measurement_date_year === now.year &&
        entry.measurement_date_period >= currentPeriod)
      );
    },
    isEmptyEntry(entry) {
      if (entry.measurement_comment?.comment) {
        return false;
      }

      const isEmptyNumeric = (value) => !value && value !== 0;
      const isEmptyString = (value) => !value || value?.length === 0;

      if (this.checkEntryType(this.metric, 'numeric')) {
        return isEmptyNumeric(entry.baseline_value) &&
          isEmptyNumeric(entry.target_value) &&
          isEmptyNumeric(entry.result_value);
      }
      return isEmptyString(entry.baseline_value) &&
        isEmptyString(entry.target_value) &&
        isEmptyString(entry.result_value);
    },
    setEntries(data) {
      this.entries = data.entry_rows;
      this.entries.forEach((entry) => {
        this.multiToSingleFields(entry);
      });
      this.loading = false;
    },
    entryDataChanged(reason) {
      this.$emit("entryDataChanged", reason);
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../stylesheet/forms.css"></style>

<style scoped>
avl-modal {
  --modal-box-shadow-header: 0px;
  --modal-box-shadow-footer: 0px;
}

avl-modal .header {
  padding-left: 7%;
  margin: 15px 0;
}

.table {
  border: 1px solid #BDBFD2;
  padding: 0 25px 25px;
}

.table .row {
  border-bottom: 1px solid #BDBFD2;
  padding: 10px 0;
}

#metricEntriesForm .form-control .ark {
  border-radius: 4px;
}

#metricEntriesForm input {
  color: #212529;
}

input.form-control:disabled {
  background-color: #e9ecef !important;
}

/* Hide spin buttons from number inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
