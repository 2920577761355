<template>
  <section class="my-3" :class="singleCard ? 'arkCard': 'arkCardNoPadding'">
    <section
      class="row pointer"
      role="button"
      tabindex="0"
      @click="goToSpecificProject"
      @keyup.enter="goToSpecificProject"
    >
      <picture
        v-if="project.last_updated_image || project.banner"
        class="col-12 col-sm-3 pr-3 pr-sm-0 d-flex align-items-center"
      >
        <source :srcset="project.last_updated_image || project.banner">
        <img
          :src="project.last_updated_image || project.banner"
          class="d-flex objectFitCover imageContainer align-self-start mb-2 mb-sm-0"
          alt="Project's last updated image"
        >
      </picture>
      <article class="col-12 col-sm-9">
        <ul
          v-if="project.project_iris_categories"
          id="badgeContainer"
          class="badge-container pl-0 mb-0 listStyleNone d-flex"
        >
          <li
            v-for="category in visibleIrisCategories"
            :id="`iris-category-${category.id}`"
            :key="category.id"
            class="whiteSpaceNowrap MobileTextXSmall ark-badge"
          >
            {{ category.name }}
          </li>
          <li
            v-if="remainingCategories > 0"
            id="remainingCategories"
            class="MobileTextXSmall ark-badge-last"
          >
            + {{ remainingCategories }}
          </li>
        </ul>
        <section class="row">
          <h3
            class="
              col-12 col-sm-10
              DesktopLinkSmall
              primaryDefault
              title-size
              mt-0 mb-1
              pr-3 pr-sm-0
            "
            :class="project.parent_organization_logo ? 'col-12 col-sm-10' : 'col-12'"
          >
            {{ project.title }}
          </h3>
          <section
            class="
              col-1 col-sm-2
              d-flex
              align-items-start
              justify-content-start
              justify-content-sm-end
              pr-4 pr-sm-3
            "
          >
            <img
              v-if="project.parent_organization_logo"
              :src="project.parent_organization_logo"
              class="imageParentOrgContainer d-flex objectFitContain"
              alt="Project's parent organization logo"
            >
          </section>
          <h4
            :class="project.parent_organization_logo ? 'col-10' : 'col-12'"
            class="col-10 col-sm-10 MobileTextXSmall grayscaleOffBlack mb-0 pr-3 pr-sm-0"
          >
            {{ getProjectTypeString }}
          </h4>
          <h5
            v-if="
              project.parent_organization &&
                project.parent_organization_logo
            "
            class="
              col-12 col-sm-10
              MobileTextXSmall
              grayscaleLabel
              organization-title-size
              pr-3 pr-sm-0
            "
          >
            By {{ project.parent_organization }}
          </h5>
          <h5
            v-else-if="project.parent_organization"
            class="
              col-12
              MobileTextXSmall
              grayscaleLabel
              organization-title-size
              pr-3 pr-sm-0
            "
          >
            By {{ project.parent_organization }}
          </h5>
        </section>
        <section class="row">
          <p
            class="mb-1 MobileTextXSmall grayscaleLabel description-size col-12 col-sm-9"
          >
            {{ project.description_text }}
          </p>
          <div class="align-self-end col-12 col-sm-3">
            <div class="col-12 p-0 justify-content-end d-flex">
              <avl-badge
                v-if="project.hidden"
                label="Hidden"
                size="medium"
                type="default"
                class="MobileTextXSmall grayscaleBody widthBadge"
              />
            </div>
            <div class="col-12 p-0 justify-content-end d-flex">
              <button
                v-if="project.can_invest"
                type="button"
                class="
                  DesktopLinkXSmall
                  primaryButton
                  btn
                  btn-sm
                "
                @click.stop="invest(project)"
              >
                {{ investOrDonateButton }}
              </button>
            </div>
          </div>
        </section>
      </article>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true,
      default: function () {
        return null;
      }
    },
    singleCard: {
      type: Boolean,
      required: false,
      default: function () {
        return false;
      }
    }
  },
  emits: ['invest'],
  data() {
    return {
      badgeContainerWidth: 0
    };
  },
  computed: {
    visibleIrisCategories() {
      return this.project.project_iris_categories.slice(0,3);
    },
    remainingCategories() {
      return this.project.project_iris_categories.length - 3;
    },
    getProjectTypeString() {
      if (this.project.type === 'ReportingProject') {
        return 'Report';
      } else {
        return 'Project';
      }
    },
    investOrDonateButton() {
      if (this.getProjectTypeString === 'Report') {
        return 'Add to portfolio';
      } else if (
        this.project?.asset_classes[0]?.name === 'Philanthropic'
          && this.project?.asset_classes?.length === 1
      ) {
        return 'Donate';
      } else return 'Invest';
    }
  },
  methods: {
    invest(project) {
      this.$emit('invest', project, this.investOrDonateButton);
    },
    goToSpecificProject() {
      window.location = `/projects/${this.project.id}`;
    }
  }
};
</script>

<style scoped>
.imageContainer {
  height: 205px;
  width: 100%;
}

.imageParentOrgContainer{
  height: 30px;
  width: 30px;
}

.title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.description-size {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.organization-title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

avl-badge {
  border-radius: 8px;
  font-weight: 500;
  height: auto;
  text-transform: none;
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  margin: 6px 1px;
  --default-text-color: #172722;
  --default-color-solid: #EFF0F6;
}

.widthBadge {
  width: 81px;
}

.ark-badge {
  display: inline-block;
  background-color: #B9DACA;
  color: #001C00;
  padding: 3px 8px;
  line-height: 1.5;
  margin: 0px 1px 6px 1px;
  border-radius: 8px;
  font-weight: 500;
  height: auto;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ark-badge:hover {
  color: #001C00 !important;
}

.ark-badge-last {
  display: inline-block;
  background-color: #B9DACA;
  color: #001C00;
  padding: 3px 8px;
  line-height: 1.5;
  margin: 0px 1px 6px 1px;
  border-radius: 8px;
  font-weight: 500;
  height: auto;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  min-width: 37px;
}
</style>
