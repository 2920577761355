import 'v-calendar/dist/style.css';
import ProjectRisks from './components/ProjectRisks.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#project-risks')){
    const app = createApp(ProjectRisks);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#project-risks');
  }
});
