<template>
  <div class="apply-form">
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <form class="arkCard py-5 mb-2">
          <div>
            <img :src="logoImage" alt="ARK logo" class="typeImg mb-2" aria-hidden="true">
            <h2 class="DesktopTextlarge mb-3">Apply for your account</h2>
            <label
              for="emailInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Email *
            </label>
            <input
              id="emailInput"
              v-model="form.email"
              type="text"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="emailError"
              :aria-invalid="!!errors.email"
              :class="{ notValid: errors.email }"
              @blur="validate"
            >
            <error-message
              id="emailError"
              :error-condition="!!errors.email"
              :error-message="errors.email"
            />
          </div>
          <div class="mt-4">
            <label
              for="passwordInput"
              class="DesktopTextSmall grayscaleOffBlack"
            >
              Create password *
            </label>
            <input
              id="passwordInput"
              v-model="form.password"
              type="password"
              class="form-control ark DesktopTextSmall py-4 px-3"
              required
              aria-errormessage="passwordError"
              :aria-invalid="!!errors.password"
              :class="{ notValid: errors.password }"
              @blur="validate"
            >
            <error-message
              id="passwordError"
              :error-condition="!!errors.password"
              :error-message="errors.password"
            />
            <div class="DesktopTextXSmall">
              Use 8 or more characters with a mix of letters, numbers & symbols
            </div>
          </div>
          <p class="DesktopTextSmall mt-4">
            By clicking Agree & Join, you agree to THE ARK
            <a href="/terms" class="warningDarkmode">Terms of Services</a> and
            <a href="/privacy" class="warningDarkmode">Privacy Policy</a>.
          </p>
          <div class="row mt-4">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <a href="/users/sign_in" class="DesktopTextSmall warningDarkmode">
                Sign in instead
              </a>
            </div>
            <div class="col-12 col-sm-6 text-sm-right">
              <button
                type="submit"
                class="
                  btn
                  DesktopLinkXSmall
                  primaryButton
                  px-4
                "
                :disabled="!form.email || !form.password"
                @click.prevent="submit"
              >
                Agree & Join
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required()
    .min(8)
    // Regexp extracted from https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
    .matches(
      /(?=.*?[A-Za-z)(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/g,
      'Password must include at least 1 letter, 1 number and 1 special character'
    )
});

export default {
  props: {
    logoImage: {
      type: String,
      required: true
    }
  },
  components: { ErrorMessage },
  emits: ['submit'],
  data() {
    return {
      showErrors: false,
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: ''
      }
    };
  },
  methods: {
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.$emit('submit', this.form);
      }
    }
  }
};

</script>

<style scoped>
.apply-form {
  padding-top: 75px;
}

.arkCard {
  padding-left: 10%;
  padding-right: 10%;
}
</style>
