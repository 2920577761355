<template>
  <section
    v-if="user?.interests?.length > 0 || user?.expertise?.length > 0"
    class="arkCard"
  >
    <header class="row mb-2">
      <section class="col-9">
        <h2 class="DesktopTextMedium secondary7_ark">Areas of Knowledge</h2>
      </section>
      <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
        <a
          aria-label="Edit about section"
          :href="`/users/${user?.id}/edit?tab=knowledge`"
        >
          <i
            class="fa fa-pencil edit-icon-pencil grayscaleBody"
            aria-hidden="true"
          />
        </a>
      </section>
    </header>
    <section class="row py-2">
      <article class="col-6">
        <h4 class="primaryDefault DesktopLinkSmall">Interests</h4>
        <p class="DesktopTextSmall warningDefault m-0">
          {{ user?.interests?.join(', ') || '-' }}
        </p>
      </article>
      <article class="col-6">
        <h4 class="primaryDefault DesktopLinkSmall">Expertise</h4>
        <p class="DesktopTextSmall warningDefault m-0">
          {{ user?.expertise?.join(', ') || '-' }}
        </p>
      </article>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
};
</script>
