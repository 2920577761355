<template>
  <section>
    <div v-if="Object.keys(organizationHeaderInfo).length === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <div v-else>
      <organization-header
        v-if="organizationHeaderInfo"
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organizationHeaderInfo"
        :organization-type="organizationType"
        :active-tab="activeTab"
        @update-image="updateHeaderImage"
        @set-organization-following="
          organizationHeaderInfo.is_following = !organizationHeaderInfo.is_following
        "
      />
    </div>
  </section>
  <section class="arkCard">
    <div class="row">
      <div class="col-9 d-flex">
        <h3 class="DesktopTextMedium primaryDefault text-capitalize my-auto">
          {{ activeTab }}
        </h3>
        <div
          v-if="activeTab === 'reports'"
          class="btn btn-social btn-link my-auto ml-2 p-0 align-text-top"
          mousetip
          :mousetip:message="reportInfo"
        >
          <i
            class="fa fa-question-circle grayscalePlaceholder"
            aria-hidden="true"
          />
          <span class="sr-only">{{ reportInfo }}</span>
        </div>
      </div>
      <div class="col-3">
        <aside v-if="isAdmin" class="d-flex justify-content-end">
          <a
            class="p-0 m-0 pointer"
            aria-label="Create new project"
            :href="`/${modelPluralize}/${modelId}/${activeTab}/new`"
          >
            <i
              class="fa fa-plus edit-icon-pencil grayscaleBody"
              aria-hidden="true"
            />
          </a>
        </aside>
      </div>
    </div>
    <div v-if="loading && projects.length === 0 && totalProjects === 0">
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <loading-indicator
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
    </div>
    <section v-else-if="!loading && projects.length === 0 && totalProjects === 0">
      <no-data-card
        first-message="Nothing to see for now"
        :second-message="`${organizationHeaderInfo?.name}'s ${activeTab} will be displayed here.`"
        :show-button="false"
      />
    </section>
    <div v-else>
      <project-card
        v-for="project in projects"
        :key="project.id"
        :project="project"
        @invest="investToProject"
      />
      <div
        v-if="loading && projects.length > 0"
        class="ml-2 d-flex justify-content-center my-3"
      >
        <div
          class="
          d-flex
          align-content-center
          spinner-border
          grayscaleLabel
          align-self-center
        "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </section>
  <CreateContributionForm
    :modal-open="createContributionModalOpen"
    :contribution-recipient-id="contributionRecipientId"
    contribution-recipient-type="project"
    :contribution-recipient-name="contributionRecipientName"
    :contribution-button-text="contributionButtonText"
    @close-modal-event="closeCreateContributionFormModal"
  />
</template>

<script>
import { contributionsMixin, modelMixin, paginationMixin } from '../../../../mixins';
import CreateContributionForm from '../../../contributions/components/CreateContributionForm.vue';
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import NoDataCard from '../../../../components/shared/NoDataCard.vue';
import OrganizationHeader from '../../sharedComponents/OrganizationHeader.vue';
import ProjectCard from '../../../../components/listItems/ProjectCard.vue';
import { fallbackApi } from '../../../../api/config';

export default {
  components: {
    CreateContributionForm,
    OrganizationHeader,
    LoadingIndicator,
    ProjectCard,
    NoDataCard
  },
  mixins: [contributionsMixin, modelMixin, paginationMixin],
  data() {
    return {
      projects: [],
      page: 1,
      per: 10,
      totalProjects: 0,
      loading: false,
      reportInfo: `Reports are tailored for investor reporting,
        featuring the same fields as Projects but are private,
        accessible only to designated investor users and team members of the organization`
    };
  },
  beforeMount() {
    this.getModel();
  },
  mounted() {
    this.fetchOrganizationProjects();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#model-projects');
      this.getModelDetails(modelInfo);
    },
    handlePagination() {
      if (this.projects.length !== this.totalProjects && !this.loading) {
        this.page ++;
        this.fetchOrganizationProjects();
      }
    },
    fetchOrganizationProjects() {
      this.loading = true;
      fallbackApi({
        url:
        `${this.modelPluralize}/${this.modelId}/projects?type=${this.activeTab}&page=${this.page}&per=${this.per}`,
        dataSetter: (data) => {
          this.totalProjects = data.all_projects_count;
          this.projects = this.uniqueItems([...this.projects, ...data.projects]);
          this.loading = false;
        }
      });
    },
    investToProject(project, buttonText) {
      this.openCreateContributionFormModal(project, 'project', buttonText);
    }
  }
};
</script>
