<template>
  <div class="apply-form text-center textBodyColor">
    <header class="row justify-content-center mt-3">
      <div class="col-12">
        <h1 class="DesktopDisplaySmall pt-5">Check your inbox</h1>
      </div>
    </header>
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <section class="arkCard py-5 mb-3">
          <p class="DesktopTextSmall text-left m-0">
            To complete registration, click the verification link in the email we have sent to
            <span class="DesktopLinkSmall">{{ email }}</span>
          </p>
        </section>
        <span class="DesktopTextSmall grayscaleOffBlack">
          If you do not see the email check your spam folder.
        </span>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      required: true
    }
  }
};

</script>

<style scoped src="../../../stylesheet/forms.css"></style>

<style scoped>
.apply-form {
  padding-top: 75px;
}

.arkCard {
  padding-left: 15%;
  padding-right: 15%;
}
</style>
