<template>
  <div>
    <div
      v-if="!openFiltersBar && !markerClickedContent"
      class="fixed-ui pointer"
      style="z-index: 3"
    >
      <div class="row d-flex align-items-center transition-02">
        <div class="text-left">
          <div
            class="
              input-group input-group-dynamic
              btn
              p-1
              search-input
              searchBarWidth
            "
          >
            <span
              role="button"
              tabindex="0"
              class="input-group-text basic-addon"
              @click="searchButtonClicked"
              @keypress.prevent="searchButtonClicked"
            >
              <i class="fas fa-search" aria-hidden="true" />
            </span>
            <input
              v-model="searchString"
              type="text"
              class="form-control"
              :placeholder="searchStringPlaceholder"
              aria-label="Search"
              @input="openSearchSidebar"
              @keydown="searchButtonClicked"
            >
            <span
              v-if="searchString || type"
              role="button"
              tabindex="0"
              class="input-group-text basic-addon"
              aria-label="Close first sidebar"
              @click="closeSidebar"
              @keypress.prevent="closeSidebar"
            >
              <i class="fas fa-times" aria-hidden="true" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <map-side-bar
      :open-side-bar="openSideBar"
      :visible-side-bar-button="visibleSideBarButton && !openbackButtonOnSecondSidebar"
      :open-second-side-bar="openSecondSideBar"
      :back-button-on-second-sidebar="openbackButtonOnSecondSidebar"
      @side-bar-status="sideBarStatus"
      @bottom-of-container="loadMore"
      @load-more="defineItemsPerPage"
      @go-back="goBack"
    >
      <template #insideOfSideBar>
        <div v-if="filtersLoading && !openFiltersBar" class="spinner-container text-center">
          <div class="spinner-border" />
        </div>
        <div v-else-if="error" class="mt-4 ark-p7 text-center">
          Something went wrong...
        </div>
        <div v-else-if="!openFiltersBar">
          <div class="sidebarPaddingTop" />
          <div v-if="renderedData.length > 0">
            <div v-for="item in renderedData" :key="`${item.type.toString()}-${item.id}`">
              <div class="row d-flex justify-content-center">
                <map-sidebar-card
                  :image-src="imageMapSrc(item)"
                  :title="item.name || item.title"
                  :goals="item.goals_count"
                  :projects="item.projects_count"
                  :species="item.species_count"
                  :theme="item.type"
                  :item="item"
                  @click="openEntitySidebar(item)"
                  @hover-card="hoverCard"
                />
              </div>
            </div>
          </div>
          <div v-else class="mt-3">
            <div class="text-center mb-3 DesktopLinkMedium">
              Sorry, no results
            </div>
            <div class="pl-3 DesktopTextSmall">
              <span class="pb-1">Try the following to get results:</span>
              <ul>
                <li>Zoom out on the map</li>
                <li>Search at a different location</li>
                <li>Update your filters</li>
              </ul>
            </div>
          </div>
        </div>
        <!-- v-if: filters state is kept when the sidebar (for filtering) is open or collapsed -->
        <!-- v-show: filters ui is displayed when clicking all filters button -->
        <filter-sidebar-content
          v-if="visibleSideBarButton && !searchString"
          v-show="!error && openFiltersBar"
          :apply-filtering="openFiltersBar || backendFiltering"
          :entity-type="type === mapCardThemes.tech_org ? mapCardThemes.organization : type"
          :initial-ids="$store.state.map[entityKey]"
          :investment-ready-filter="toggledInvestmentReady"
          @results="fetchFilterEntities"
          @close="openFiltersBar = false"
        />
        <div
          v-show="!openFiltersBar && openSideBar"
          class="spatial-filter-control-container"
        >
          <input
            type="checkbox"
            :checked="spatialFilterCheckboxStatus"
            @click="spatialFilterClick"
          >
          <span class="DesktopTextXSmall pl-2">Update results when map moves</span>
        </div>
      </template>
      <template #secondSideBar>
        <second-sidebar-content
          v-if="openEntityContent"
          :card-details="cardDetails"
          :theme="cardDetails.type === mapCardThemes.tech_org ? mapCardThemes.organization : cardDetails.type"
        />
      </template>
      <template #outsideOfSideBar>
        <div class="fixed-ui w-100 row m-0 align-items-start" style="right: 0; left: auto;">
          <div
            v-show="type"
            class="mr-4"
          >
            <div class="new-line" :class="{ buttonsPaddingTop: closedSideBars }" />
            <div
              class="
                row
                mr-0
                transition-02
              "
              :class="{
                sidebarMarginLeft: visibleSearchBar && !openSecondSideBar,
                sidebarMarginLeftUnset: closedSideBars,
                secondSidebarMarginLeft: openSecondSideBar,
                bothSidebarsMarginLeft: openSideBar && openSecondSideBar
              }"
            >
              <button
                v-show="stringType === 'project' && openSideBar && !openSecondSideBar"
                type="button"
                class="btn map-btn DesktopLinkXSmall primaryDefault mr-2"
                :class="{ btnToggled: toggledInvestmentReady }"
                @click="toggleInvestmentReady(!toggledInvestmentReady)"
              >
                <i
                  v-show="toggledInvestmentReady"
                  class="fa fa-check"
                  aria-hidden="true"
                />&nbsp; Investment ready
              </button>
              <button
                v-show="visibleFilterButton"
                type="button"
                class="btn secondaryButton DesktopLinkXSmall primaryDefault mr-2"
                @click="showFiltersBar"
              >
                <i
                  class="fa fa-filter"
                  aria-hidden="true"
                />&nbsp;&nbsp;&nbsp; All filters
              </button>
              <div class="clusteringToggle d-flex align-items-center secondaryButton DesktopLinkXSmall primaryDefault">
                <span>Clustering</span>
                <avl-toggle
                  class="toggle-sm"
                  size="small"
                  :value="clusteringEnabled"
                  @avleventtoggle="toggleClustering"
                />
              </div>
            </div>
          </div>
          <div v-show="visibleEntityButtons" class="mr-4">
            <div class="new-line" :class="{ buttonsPaddingTop: closedSideBars }" />
            <div
              class="
                d-flex
                align-items-center
                transition-02
              "
              :class="{
                sidebarMarginLeft: visibleSearchBar && !openSecondSideBar,
                'ml-30px': closedSideBars,
                secondSidebarMarginLeft: openSecondSideBar,
                bothSidebarsMarginLeft: openSideBar && openSecondSideBar
              }"
            >
              <button-carousel>
                <button
                  type="button"
                  class="btn secondaryButton DesktopLinkXSmall primaryDefault mr-2"
                  @click="openSidebarWithData(mapCardThemes.organization)"
                >
                  <i
                    class="fa fa-building"
                    aria-hidden="true"
                  />&nbsp;&nbsp;&nbsp; Organizations
                </button>
                <button
                  type="button"
                  class="btn secondaryButton DesktopLinkXSmall primaryDefault mr-2"
                  @click="openSidebarWithData(mapCardThemes.project)"
                >
                  <i
                    class="fas fa-map-marker-alt"
                    aria-hidden="true"
                  />&nbsp;&nbsp;&nbsp; Projects
                </button>
                <button
                  type="button"
                  class="btn secondaryButton DesktopLinkXSmall primaryDefault mr-2"
                  @click="openSidebarWithData(mapCardThemes.tech_org)"
                >
                  <i
                    class="fa fa-project-diagram"
                    aria-hidden="true"
                  />&nbsp;&nbsp;&nbsp; Technologies
                </button>
                <div class="clusteringToggle d-flex align-items-center secondaryButton DesktopLinkXSmall primaryDefault">
                  <span>Clustering</span>
                  <avl-toggle
                    class="toggle-sm"
                    size="small"
                    :value="clusteringEnabled"
                    @avleventtoggle="toggleClustering"
                  />
                </div>
              </button-carousel>
            </div>
          </div>
        </div>
      </template>
    </map-side-bar>
  </div>
</template>

<script>
import FilterSidebarContent from './FilterSidebarContent.vue';
import MapSideBar from './MapSideBar.vue';
import MapSidebarCard from './MapSidebarCard.vue';
import SecondSidebarContent from './SecondSidebarContent.vue';
import ButtonCarousel from '../../../components/shared/ButtonCarousel.vue';
import { mapCardThemes } from '../../../domain/enumerationHelper';
import { snakeToCamelCase, pointInsideArea } from '../../../utilities/genericMethods';
import { symbolToString } from '../../../utilities/enumeration';

export default {
  name: 'MapUI',
  components: {
    FilterSidebarContent,
    MapSideBar,
    MapSidebarCard,
    SecondSidebarContent,
    ButtonCarousel,
  },
  props: {
    openEntityInfo: {
      type: Object,
      required: true
    },
    visibleArea: {
      type: Object,
      required: true
    }
  },
  emits: ['clickedButton', 'hoverCard'],
  data() {
    return {
      clusteringEnabled: false,
      type: null,
      searchString: '',
      searchResults: false,
      filtersLoading: false,
      error: false,
      openSideBar: false,
      openSecondSideBar: false,
      openFiltersBar: false,
      openEntityContent: false,
      visibleSideBarButton: false,
      openbackButtonOnSecondSidebar: false,
      backendFiltering: false,
      fetchedData: [],
      areaData: [],
      renderedData: [],
      cardDetails: {},
      itemsPerPage: 10,
      page: 1,
      mapCardThemes,
      toggledInvestmentReady: false,
      markerClickedContent: false,
      spatialFilterCheckboxStatus: false
    };
  },
  computed: {
    searchStringPlaceholder(){
      return this.sidebarTitle === '' ? 'Find by name on map...' : this.sidebarTitle;
    },
    stringType() {
      return symbolToString(this.type === null ? '' : this.type);
    },
    entityKey() {
      return `${snakeToCamelCase(this.stringType)}s`;
    },
    sidebarTitle() {
      switch (this.type) {
        case mapCardThemes.organization:
          return 'Organisations';
        case mapCardThemes.project:
          return 'Projects';
        case mapCardThemes.tech_org:
          return 'Technologies';
        default:
          return '';
      }
    },
    visibleSearchBar() {
      return this.openSideBar || !this.openSecondSideBar;
    },
    visibleFilterButton() {
      return (
        this.type &&
        this.visibleSideBarButton &&
        !this.openFiltersBar &&
        !this.openbackButtonOnSecondSidebar
      );
    },
    visibleEntityButtons() {
      return !this.visibleSideBarButton || !this.type;
    },
    closedSideBars() {
      return !this.openSideBar && !this.openSecondSideBar;
    }
  },
  watch: {
    openEntityInfo() {
      this.getEntityInfo();
    },
    visibleArea() {
      this.checkArea();
    },
    spatialFilterCheckboxStatus() {
      this.checkArea();
    }
  },
  mounted() {
    this.fetchAllEntities();
  },
  methods: {
    imageMapSrc(item) {
      if (item.type === mapCardThemes.project) {
        return item.last_updated_image || item.banner;
      }
      return item.logo;
    },
    checkArea() {
      if (!this.spatialFilterCheckboxStatus) return;

      this.areaData = this.filterByArea(this.fetchedData);
      this.page = 1;
      this.loadMore();
    },
    toggleClustering() {
      this.clusteringEnabled = !this.clusteringEnabled;
      this.$eventBus.$trigger('toggle-clustering', this.clusteringEnabled);
    },
    toggleInvestmentReady(toggledInvestmentReady) {
      this.filtersLoading = true;
      this.toggledInvestmentReady = toggledInvestmentReady;

      this.fetchAndFilterData();
    },
    getEntityInfo() {
      if (!this.openEntityInfo.id || !this.openEntityInfo.type) return;
      if (this.visibleSideBarButton) {
        this.openbackButtonOnSecondSidebar = true;
      }
      this.openSideBar = false;
      this.markerClickedContent = true;
      const entityKey = snakeToCamelCase(`${symbolToString(this.openEntityInfo.type)}s`);
      const item = this.$store.state.map[entityKey]
        .find((element) => element.id === this.openEntityInfo.id);

      this.moveTo(item);
      this.openEntitySidebar(item);
    },
    moveTo(item) {
      this.$eventBus.$trigger('travel-to', {
        coordinates: [{
          id: item.id,
          lat: item.lat,
          lng: item.lng,
          type: symbolToString(item.type)
        }],
        ignoreAssociatedEntities: true,
        filters: this.searchResults || this.type ?
          {} :
          {
            organization: this.$store.state.map[this.toKey(mapCardThemes.organization)],
            project: this.$store.state.map[this.toKey(mapCardThemes.project)],
            tech_org: this.$store.state.map[this.toKey(mapCardThemes.tech_org)]
          },
        source: 'markerClicked'
      });
    },
    defineItemsPerPage(height) {
      this.itemsPerPage = Math.floor(height/100) + 5;
      this.page = 1;
      this.loadMore();
    },
    loadMore() {
      if (this.page === 1 || this.areaData.length !== this.renderedData.length) {
        this.renderedData = this.areaData.slice(0, this.itemsPerPage * this.page);
        this.page++;
      }
    },
    openEntitySidebar(entityData) {
      this.openSecondSideBar = true;
      this.openEntityContent = true;

      // In smaller screens, a card click is treated as a marker click (single sidebar)
      if (window.innerWidth < 850) {
        this.openSideBar = false;
        this.markerClickedContent = true;
        if (this.visibleSideBarButton) {
          this.openbackButtonOnSecondSidebar = true;
        }
      }

      this.cardDetails = entityData;
    },
    openSidebarWithData(type) {
      this.visibleSideBarButton = true;
      this.openSecondSideBar = false;
      this.markerClickedContent = false;
      this.type = type;
      this.searchString = '';
      this.searchResults = false;
      this.openSideBar = true;
      this.fetchAndFilterData();
    },
    showFiltersBar() {
      this.openSideBar = true;
      this.openFiltersBar = true;
      this.backendFiltering = true;
      this.openSecondSideBar = false;
    },
    /**
     * Opens the sidebar, showing a search input and focuses on it
     * If a search hasn't been made already, shows all entities
     */
    openSearchSidebar() {
      if (this.searchString.length === 0) {
        this.closeSidebar();
        return;
      }
      else if (this.searchString.length > 1) return;

      this.toggledInvestmentReady = false;
      this.backendFiltering = false;
      this.type = null;
      this.visibleSideBarButton = true;
      this.openSecondSideBar = false;
      this.openSideBar = false;
      setTimeout(() => {
        if (this.searchString.length !== 0) {
          this.openSideBar = true;
        }
      }, 500);

      if (!this.searchResults) {
        this.fetchAllEntities();
        this.searchResults = true;
      }
    },
    /**
     * Makes a search and shows appropriate items
     * If searchstring is empty shows all entities
     * Opens sidebar if not open
     * @param event: Event this was called from (click or keydown)
     */
    searchButtonClicked(event) {
      // If called by keydown event, check that 'enter' was pressed
      if (event.type === 'keydown' && event.key !== 'Enter' && event.keyCode !== 13) {
        return;
      }

      this.openSecondSideBar = false;
      if (!this.openSideBar) {
        this.type = null;
        this.openSideBar = true;
        this.visibleSideBarButton = true;
      }

      if (!this.searchString) {
        this.fetchAllEntities();
      } else {
        this.fetchSearchEntities();
      }

      this.searchResults = true;
    },
    fetchAllEntities() {
      this.fetchedData = this.$store.state.map[this.toKey(mapCardThemes.organization)]
        .concat(this.$store.state.map[this.toKey(mapCardThemes.project)])
        .concat(this.$store.state.map[this.toKey(mapCardThemes.tech_org)]);

      this.areaData = this.filterByArea(this.fetchedData);
      this.page = 1;
      this.loadMore();

      this.$eventBus.$trigger('reset-all-rendered-entities');
    },
    fetchSearchEntities() {
      let allItems = this.$store.state.map[this.toKey(mapCardThemes.organization)]
        .concat(this.$store.state.map[this.toKey(mapCardThemes.project)])
        .concat(this.$store.state.map[this.toKey(mapCardThemes.tech_org)]);

      // Split search strings into lowercase words and remove empty strings
      const searchStrings = this.searchString.toLowerCase().split(/\W/).filter((str) => str);

      // Filter data based on title
      // Choose items where the title includes any of the search strings
      allItems = allItems.filter((item) => {
        const title = (item.name || item.title).toLowerCase();
        item.priority = searchStrings.filter((str) => title.includes(str)).length;
        return item.priority > 0;
      });

      // Sort results based on matched words
      allItems = allItems.sort((a, b) => {
        return b.priority - a.priority;
      });

      this.fetchedData = allItems;
      this.areaData = this.filterByArea(allItems);
      this.page = 1;
      this.loadMore();

      this.renderMapPoints();
    },
    fetchFilterEntities(resultIDs, checkboxesSelected) {
      this.backendFiltering = checkboxesSelected || this.openFiltersBar;
      this.fetchedData = this.$store.state.map[this.entityKey].filter((datum) => {
        return resultIDs.includes(datum.id);
      });
      this.areaData = this.filterByArea(this.fetchedData);

      this.page = 1;
      this.loadMore();
      this.renderMapPoints();
      this.filtersLoading = false;
    },
    renderMapPoints() {
      const filters = {
        [symbolToString(mapCardThemes.organization)]:
          this.fetchedData.filter((item) => item.type === mapCardThemes.organization),
        [symbolToString(mapCardThemes.project)]:
          this.fetchedData.filter((item) => item.type === mapCardThemes.project),
        [symbolToString(mapCardThemes.tech_org)]:
          this.fetchedData.filter((item) => item.type === mapCardThemes.tech_org)
      };

      const entityLayers = {
        [symbolToString(mapCardThemes.organization)]: true,
        [symbolToString(mapCardThemes.project)]: true,
        [symbolToString(mapCardThemes.tech_org)]: true
      };

      this.$eventBus.$trigger('show-entities', {
        entityLayers,
        filters
      });
    },
    fetchAndFilterData() {
      if (this.backendFiltering) {
        return;
      }

      let filteredData = this.$store.state.map[this.entityKey] || [];
      if (this.toggledInvestmentReady) {
        filteredData = filteredData.filter((item) => item.investment_ready === true);
      }

      this.fetchedData = filteredData;
      this.areaData = this.filterByArea(filteredData);
      this.page = 1;
      this.loadMore();

      this.renderMapPoints();
      this.filtersLoading = false;
    },
    filterByArea(points) {
      if (!this.visibleArea) {
        return points;
      }

      return points.filter((item) => {
        return pointInsideArea(this.visibleArea, item.location.lon, item.location.lat, this.spatialFilterCheckboxStatus);
      });
    },
    sideBarStatus(statusOfSidebar, statusOfSecondSidebar, statusOfFiltersBar) {
      if (!this.openSideBar && this.openSecondSideBar && !statusOfSecondSidebar) {
        this.closeSidebar();
        return;
      }

      this.openSideBar = statusOfSidebar;
      this.openSecondSideBar = statusOfSecondSidebar;
      this.openbackButtonOnSecondSidebar = false;
      this.markerClickedContent = false;
      if (statusOfFiltersBar !== undefined) this.openFiltersBar = statusOfFiltersBar;

      // Restore map points to sidebar content
      if (!statusOfSecondSidebar) {
        if (!this.searchString && !this.type && this.fetchedData.length === 0) {
          this.fetchedData = this.$store.state.map[this.toKey(mapCardThemes.organization)]
            .concat(this.$store.state.map[this.toKey(mapCardThemes.project)])
            .concat(this.$store.state.map[this.toKey(mapCardThemes.tech_org)]);
        }

        this.renderMapPoints();
      }
    },
    closeSidebar() {
      this.backendFiltering = false;
      this.type = null;
      this.searchString = '';
      this.searchResults = false;
      this.openSideBar = false;
      this.openSecondSideBar = false;
      this.openEntityContent = false;
      this.openFiltersBar = false;
      this.visibleSideBarButton = false;
      this.openbackButtonOnSecondSidebar = false;
      this.toggledInvestmentReady = false;
      this.markerClickedContent = false;
      this.fetchAllEntities();
    },
    toKey(type) {
      return `${snakeToCamelCase(symbolToString(type))}s`;
    },
    goBack() {
      this.openSideBar = true;
      this.openSecondSideBar = false;
      this.markerClickedContent = false;
      this.openbackButtonOnSecondSidebar = false;
      this.renderMapPoints();
    },
    hoverCard(item) {
      this.$emit('hoverCard', item);
    },
    spatialFilterClick() {
      this.spatialFilterCheckboxStatus = !this.spatialFilterCheckboxStatus;
    }
  }
};
</script>

<style scoped>
.fixed-ui {
  position: fixed;
  top: 80px;
  margin-left: 7px;
}

.map-btn {
  background-color: white !important;
  text-transform: none;
  border-radius: 4px;
  padding: 9px 22px;
  gap: 12px;
}
.btnToggled{
  background-color: #B9DACA !important;
}

.map-btn:hover {
    color: #24422B;
}

.map-btn:focus {
    color: #24422B;
}

.search-input {
  background-color: white !important;
}

.filter-btn {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  color: #24422b;
  font-weight: 800;
}

.form-control,
.is-focused .form-control {
  background-image: none !important;
}

.basic-addon {
  cursor: pointer;
}

.searchBarWidth {
  width: 390px;
}

.sidebarMarginLeft {
  margin-left: 430px;
}

.bothSidebarsMarginLeft {
  margin-left: 776px !important;
  /* margin-left: 420px + 316px + 30px + 10px = sidebar + space + second sidebar + space */
}

.secondSidebarMarginLeft {
  margin-left: 326px;
  /* margin-left: 316px + 10px = second sidebar + space */
}

.sidebarPaddingTop {
  height: 65px;
}

.buttonsPaddingTop {
  height: 65px;
}

.new-line {
  display: none;
}

.spinner-container {
  margin-top: 25%;
}

.toggle-sm {
  height: 22px;
}

.clusteringToggle {
  margin: 0.3125rem 1px;
  position: fixed;
  right: 10px;
}

.spatial-filter-control-container {
  position: fixed;
  bottom: 0px;
  left: 15px;
  background: white;
  width: 404px;
}

@media screen and (max-width: 1162px) {
  .clusteringToggle {
    position: unset;
    right:unset;
  }
}

@media screen and (max-width: 991px) {
  .spinner-container {
    margin-top: 35%;
  }
}

@media screen and (max-width: 910px) {
  .fixed-ui {
    top: 50px;
  }

  .sidebarMarginLeftUnset {
    margin-left: 20px;
  }

  .ml-30px {
    margin-left: 30px;
  }

  .sidebarPaddingTop {
    height: 90px;
  }

  .new-line {
    display: block;
  }
}
</style>
