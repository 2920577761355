import 'v-calendar/dist/style.css';
import OrganizationAbout from './components/OrganizationAbout.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#model-about')){
    const app = createApp(OrganizationAbout);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#model-about');
  }
});
