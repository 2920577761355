import 'v-calendar/dist/style.css';
import ProjectMedia from './components/ProjectMedia.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#project-media')){
    const app = createApp(ProjectMedia);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#project-media');
  }
});
