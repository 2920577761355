const mutations = {
  ADD_TOAST(state, toast) {
    state.toasts.push(toast);
  },
  REMOVE_TOAST(state, id) {
    let index = state.toasts.findIndex((toast) => toast.id === id);
    state.toasts.splice(index, 1);
  }
};

export default mutations;
