<template>
  <div id="setMetricsForm">
    <div class="setMetricsNav">
      <div class="row d-flex justify-content-center mx-0">
        <div class="col-sm-12 col-md-12 col-lg-10 mt-3 px-0">
          <a
            id="setMetricsBackTag"
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
            "
            :href="`/${modelPluralize}/${modelId}/impact`"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />&nbsp;&nbsp;&nbsp; Back
          </a>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-12 col-lg-10">
        <div class="arkCard mt-4">
          <div class="row d-flex">
            <div
              class="
                col-sm-12 col-md-6
                text-left
                justify-content-left
                DesktopTextMedium
                textBodyColor
                mx-0 mb-3 pr-2
              "
            >
              Set Metrics
            </div>
            <div
              class="
                col-sm-12
                col-md-6
                text-sm-left
                text-md-right
                mb-3
                textBodyColor
                DesktopTextSmall
              "
            >
              Can’t find a metric that captures what you want to measure?<br>
              <button
                class="secondary8_ark createCustomMetricButton px-0"
                @click="createMetricModalOpen = true"
              >
                Create a new custom metric
              </button>
              for your organisation.
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-12">
              <div class="row d-flex justify-content-left mt-3">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 text-left">
                  <div class="input-group input-group-dynamic mb-4">
                    <input
                      v-model="searchString"
                      :disabled="disabled.search"
                      type="text"
                      class="form-control ark DesktopTextSmall noBorderRight"
                      placeholder="Search"
                      aria-label="Search"
                    >
                    <span id="basic-addon1" class="input-group-text inputIcon pointer">
                      <i class="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 text-left">
                  <Multiselect
                    v-model="impactCategories"
                    :disabled="disabled.categories"
                    :options="impactCategoryOptions"
                    placeholder="Impact Category"
                    mode="tags"
                    :close-on-select="true"
                    :searchable="true"
                    track-by="name"
                    class="multiselect-ark"
                  />
                </div>
                <div
                  class="
                    col-12
                    col-sm-12
                    col-md-12
                    col-lg-12
                    col-xl-4
                    mt-3
                    mt-xl-0
                    mb-3
                    mb-xl-0
                    text-left
                  "
                >
                  <Multiselect
                    v-model="sdg"
                    :options="sdgOptions"
                    placeholder="SDG"
                    mode="tags"
                    :close-on-select="true"
                    :searchable="true"
                    track-by="codeTitle"
                    label="codeTitle"
                    value-prop="value"
                    class="multiselect-ark"
                    :disabled="disabled.sdg"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-12">
              <div class="d-flex justify-content-end mt-3 DesktopTextSmall grayscaleOffBlack">
                <div class="mr-2">
                  Show selected metrics
                </div>
                <avl-toggle
                  size="medium"
                  :value="showSavedTable"
                  @avleventtoggle="changedStatusToggle"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div :key="dataTableKey" class="col-12 text-left px-0">
              <vue-good-table
                ref="iris-metrics"
                :columns="columns"
                :rows="showSavedTable ? rows.saved : rows.all"
                :pagination-options="{
                  enabled: true,
                  perPageDropdownEnabled: false,
                  perPage: 20
                }"
                :select-options="{
                  enabled: false,
                  disableSelectInfo: true
                }"
                style-class="vgt-table"
              >
                <template #table-row="props">
                  <button
                    class="
                      row
                      mx-0
                      createCustomMetricButton
                      secondary8_ark
                      rowButton
                      px-3
                      py-3
                      w-100
                      text-left
                    "
                    :style="
                      showSavedTable || props.row.vgtSelected ? 'background-color: #F7F7FC': ''
                    "
                    @click="onRowClick(props.row)"
                  >
                    <span
                      v-if="props.column.field === 'title'"
                      style="font-weight: bold; display: block"
                      class="w-100 col-12 col-sm-10 col-md-11"
                    >
                      {{ props.row.title }}
              &nbsp;&nbsp;
                    </span>
                    <i
                      v-if="showSavedTable || props.row.vgtSelected"
                      class="
                        col-12 col-sm-2 col-md-1
                        fa fa-check-circle
                        checkedIconMetric
                        d-flex
                        justify-content-end
                        align-self-center
                      "
                      aria-hidden="true"
                    />
                    <span class="sr-only">
                      {{ showSavedTable || props.row.vgtSelected ? 'Selected' : 'Not selected' }}
                    </span>
                  </button>
                </template>
                <template #emptystate>
                  <div style="text-align: center">
                    <span v-if="disabled.search || disabled.categories || disabled.sdg">
                      <span class="spinner-border primary2_ark" role="status">
                        <span class="sr-only">Loading...</span>
                      </span>
                      Loading ...
                    </span>
                    <span
                      v-else-if="
                        showSavedTable
                          && searchString === ''
                          && impactCategories.length === 0
                          && sdg.length === 0
                      "
                    >
                      <h4>No metrics found.</h4>
                    </span>
                    <span v-else>
                      <h4>No results were found that match your search.</h4>
                    </span>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center">
        <button
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            my-3
            primaryButton
            pointer
            setMetricsButtonSave
          "
          @click="redirectToImpact"
        >
          Finish
        </button>
      </div>
    </div>
    <create-metric
      v-if="createMetricModalOpen"
      :create-metric-modal-open="createMetricModalOpen"
      :custom-metric-info="{ goalId }"
      :measurement-options="measurementOptions"
      :entry-frequency-options="entryFrequencyOptions"
      :auditor-options="auditorOptions"
      @create-metric-modal-closed-event="closeMetricModal"
    />
    <metric-detail-card
      v-if="metricDetailModalOpen"
      :metric-modal-open="metricDetailModalOpen"
      :metric="targetMetric"
      :is-admin="true"
      :measurement-options="measurementOptions"
      :entry-frequency-options="entryFrequencyOptions"
      :status-options="statusOptions"
      :auditor-options="auditorOptions"
      @close-modal="metricDetailModalOpen = false"
      @on-select="onSelectMetric"
      @on-update="onUpdateMetric"
      @on-remove="onRemoveMetric"
    />
    <delete-association-modal
      v-if="deleteAssociationModalOpen"
      :delete-association-modal-open="deleteAssociationModalOpen"
      :metric-info="targetMetric"
      @on-delete="onDelete"
      @delete-association-modal-closed-event="deleteAssociationModalOpen = false"
    />
  </div>
</template>

<script>
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Multiselect from '@vueform/multiselect';
import { VueGoodTable } from 'vue-good-table-next';
import { defineAsyncComponent } from 'vue';
import { fallbackApi } from '../../../api/config';
import isAdminBase from '../../../utilities/authentication';
import { metricOptionsMixin } from '../../impact/mixins';

const metricCurrencyTypes = [
  'Currency',
  'Reporting Currency (OD5990)'
];

export default {
  components: {
    CreateMetric: defineAsyncComponent(() => import('./../../impact/components/CreateMetric.vue')),
    DeleteAssociationModal: defineAsyncComponent(() =>
      import('./../../impact/components/DeleteAssociationModal.vue')
    ),
    MetricDetailCard: defineAsyncComponent(() =>
      import('../../../components/shared/MetricDetailCard.vue')
    ),
    Multiselect,
    VueGoodTable
  },
  mixins: [metricOptionsMixin],
  data() {
    return {
      createMetricModalOpen: false,
      metricDetailModalOpen: false,
      deleteAssociationModalOpen: false,
      disabled: {
        search: false,
        categories: false,
        sdg: false
      },
      searchString: '',
      impactCategories: [],
      impactCategoryOptions: [],
      sdg: [],
      sdgOptions: [],
      rows: {
        all: [],
        saved: []
      },
      initialRows: [],
      initialSavedRows: [],
      searchedRows: {
        all: [],
        saved: []
      },
      filteredRows: {
        all: [],
        saved: []
      },
      sdgRows: {
        all: [],
        saved: []
      },
      arkMetricsAlreadySet: [],
      irisMetricsAlreadySet: [],
      showSavedTable: false,
      columns: [{
        label: 'Name',
        field: 'title',
        width: '90%'
      }],
      targetMetric: undefined,
      model: undefined,
      modelId: undefined,
      modelPluralize: undefined,
      goalId: undefined,
      goalName: undefined,
      dataTableKey: 1
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: this.model,
        entityId: this.modelId
      });
    }
  },
  watch: {
    /**
     * watch for the search and filter values
     * -> if any is changed
     * -> search/filter in the initial rows object
     * -> assign to 'rows' the intersection of the 2 array results
     */
    searchString() {
      this.disabled = {
        ...this.disabled,
        categories: true,
        sdg: true
      };
      this.updateSearchResults();
    },
    impactCategories() {
      this.disabled = {
        ...this.disabled,
        search: true,
        sdg: true
      };
      this.updateImpactCategoriesResults();
    },
    sdg() {
      this.disabled = {
        ...this.disabled,
        search: true,
        categories: true
      };
      this.updateSdgResults();
    }
  },
  beforeMount() {
    this.getModelInfo();
  },
  mounted() {
    this.getGoalInfo();
    this.fetchIrisCategories();
    this.fetchSdgs();
  },
  methods: {
    getModelInfo() {
      const setMetrics = document.querySelector('#set-metrics-form');
      this.model = setMetrics?.dataset.model;
      this.modelId = setMetrics?.dataset.modelId;
      this.modelPluralize = setMetrics?.dataset.modelPluralize;
      this.goalId = setMetrics?.dataset.goalId;
      this.goalName = setMetrics?.dataset.goalName;
    },
    getGoalInfo() {
      fallbackApi({
        url: `goals/${this.goalId}`,
        dataSetter: (data) => {
          this.arkMetricsAlreadySet = data.goal.ark_metrics;
          this.irisMetricsAlreadySet = data.goal.iris_metrics;
        }
      });
      this.fetchMetrics();
    },
    fetchMetrics() {
      this.disabled = {
        search: true,
        categories: true,
        sdg: true
      };

      fallbackApi({
        url: 'metrics',
        dataSetter: (data) => {
          const allRows = [];
          const savedRows = [];

          for (let row of [...data.metrics.ark_metrics, ...data.metrics.iris_metrics]) {
            const newRow = { ...row, row_type: row.metric_id ? 'iris' : 'ark' };
            for (
              let metricAlreadySet of [...this.arkMetricsAlreadySet, ...this.irisMetricsAlreadySet]
            ) {
              if (metricAlreadySet.id === row.id && metricAlreadySet.metric_id === row.metric_id) {
                newRow.vgtSelected = true;
                newRow.measurement_unit = metricAlreadySet.measurement_unit;
                newRow.entry_frequency = metricAlreadySet.entry_frequency;
                newRow.status = metricAlreadySet.status;
                newRow.collected_by = metricAlreadySet.collected_by;
                newRow.reported_by = metricAlreadySet.reported_by;
                newRow.evaluated_by = metricAlreadySet.evaluated_by;
                newRow.data_source = metricAlreadySet.data_source;
                newRow.means_of_verification = metricAlreadySet.means_of_verification;
                savedRows.push(newRow);
                break;
              }
            }
            allRows.push(newRow);
          }

          this.rows = { all: allRows, saved: savedRows };

          this.searchedRows = {
            all: allRows,
            saved: savedRows
          };

          this.filteredRows = {
            all: allRows,
            saved: savedRows
          };

          this.sdgRows = {
            all: allRows,
            saved: savedRows
          };

          this.initialRows = JSON.parse(JSON.stringify(this.rows.all));
          this.initialSavedRows = JSON.parse(JSON.stringify(this.rows.saved));
          this.disabled = {
            search: false,
            categories: false,
            sdg: false
          };
        }
      });
    },
    fetchIrisCategories() {
      fallbackApi({
        url: 'iris_categories',
        dataSetter: (data) => {
          this.impactCategoryOptions = data.iris_categories.reduce(
            (accumulator, { name }) => [...accumulator, name], []
          );
        }
      });
    },
    fetchSdgs() {
      fallbackApi({
        url: 'sdg_goals',
        dataSetter: (data) => {
          const sdgOptions = data.sdg_goals;
          sdgOptions.forEach((sdg) => {
            // we need this because of multiselect component
            sdg.value = sdg.sdg_goal_code;
            sdg.codeTitle = `SDG ${sdg.sdg_goal_code} ${sdg.small_title}`;
          });
          this.sdgOptions = sdgOptions;
         }
       });
    },
    updateSearchResults() {
      const status = this.showSavedTable ? 'saved' : 'all';
      this.searchedRows[status] = this.filterTableRows('search');

      if (this.impactCategories.length === 0 && this.sdg.length === 0) {
        this.rows[status] = this.searchedRows[status];
      } else {
        this.rows[status] = this.defineFilterSearchedRows(
          this.searchedRows,
          this.filteredRows,
          this.sdgRows,
          status
        );
      }

      this.disabled = {
        ...this.disabled,
        sdg: false,
        categories: false
      };
    },
    updateImpactCategoriesResults() {
      const status = this.showSavedTable ? 'saved' : 'all';
      this.filteredRows[status] = this.filterTableRows('categories');

      if (this.searchString === '' && this.sdg.length === 0) {
        this.rows[status] = this.filteredRows[status];
      } else {
        this.rows[status] = this.defineFilterSearchedRows(
          this.filteredRows,
          this.searchedRows,
          this.sdgRows,
          status
        );
      }

      this.disabled = {
        ...this.disabled,
        search: false,
        sdg: false
      };
    },
    updateSdgResults() {
      const status = this.showSavedTable ? 'saved' : 'all';
      this.sdgRows[status] = this.filterTableRows('sdg');

      if (this.searchString === '' && this.impactCategories.length === 0) {
        this.rows[status] = this.sdgRows[status];
      } else {
        this.rows[status] = this.defineFilterSearchedRows(
          this.sdgRows,
          this.searchedRows,
          this.filteredRows,
          status
        );
      }

      this.disabled = {
        ...this.disabled,
        search: false,
        categories: false
      };
    },
    defineFilterSearchedRows(filterArray, findArray1, findArray2, status) {
      return filterArray[status].filter((element) => {
        const elementReplica = JSON.parse(JSON.stringify(element));
        return findArray1[status]?.find((value) => (
          value.id === elementReplica.id &&
            value.row_type === elementReplica.row_type
        )) && findArray2[status]?.find((value) => (
          value.id === elementReplica.id &&
            value.row_type === elementReplica.row_type
        ));
      });
    },
    changedStatusToggle() {
      this.showSavedTable = !this.showSavedTable;
      this.updateImpactCategoriesResults();
      this.updateSdgResults();
      this.updateSearchResults();
      this.dataTableKey++ ;
    },
    emptyFilterSearch(mode) {
      switch (mode) {
        case 'search':
          return !this.searchString;
        case 'categories':
          return this.impactCategories.length === 0;
        case 'sdg':
          return this.sdg.length === 0;
        default:
          return false;
      }
    },
    filterTableRows(mode) {
      this.dataTableKey++ ;

      const rows = this.showSavedTable
        ? this.initialSavedRows
        : this.initialRows;

      if (this.emptyFilterSearch(mode)) return rows;

      return rows.filter((element) => {
        const elementReplica = JSON.parse(JSON.stringify(element));
        let filterMatched = false;
        switch (mode) {
          case 'search':
            elementReplica.title = elementReplica.title
              .toLowerCase()
              .replace(/\s/g, '');
            return elementReplica.title.includes(
              this.searchString.toLowerCase().replace(/\s/g, '')
            );
          case 'categories':
            elementReplica.iris_categories.forEach((category) => {
              if (this.impactCategories.includes(category.name)) {
                filterMatched = true;
              }
            });
            return filterMatched;
          case 'sdg':
            elementReplica.sdg_goals.forEach((sdg) => {
              if (this.sdg.includes(sdg.sdg_goal_code)) {
                filterMatched = true;
              }
            });
            return filterMatched;
          default:
            return false;
        }
      });
    },
    redirectToImpact() {
      location.assign(`/${this.modelPluralize}/${this.modelId}/impact`);
    },
    onRowClick(row) {
      const rowCopy = { ...row, goalId: this.goalId };
      if (metricCurrencyTypes.includes(rowCopy.format)) {
        rowCopy.measurement_unit = { id: 0, name: 'Euros' };
      } else if (!rowCopy.measurement_unit) rowCopy.measurement_unit = { name: '' };
      this.targetMetric = rowCopy;
      this.metricDetailModalOpen = true;
    },
    updateMetricInArray(array, updatedData, vgtSelected) { // Array passed by reference
      const index = array.findIndex((row) => row.id === this.targetMetric.id);
      if (index >= 0) {
        array[index].measurement_unit = updatedData.measurement_unit;
        array[index].entry_frequency = updatedData.entry_frequency;
        array[index].status = updatedData.status;
        array[index].collected_by = updatedData.collected_by;
        array[index].reported_by = updatedData.reported_by;
        array[index].evaluated_by = updatedData.evaluated_by;
        array[index].data_source = updatedData.data_source;
        array[index].means_of_verification = updatedData.means_of_verification;
        array[index].vgtSelected = vgtSelected;
      }
    },
    onDelete() {
      const emptyFields = {
        entry_frequency: { name: '' },
        measurement_unit: { name: '' }
      };

      const updateArray = (array) => this.updateMetricInArray(array, emptyFields);
      const removeTargetMetricFromArray = (array) => { // Array passed by reference
        const index = array.findIndex((row) => row.id === this.targetMetric.id);
        if (index >= 0) array.splice(index, 1);
      };

      // This is being done to reduce the amount of re-renders to the minimum of 3.
      const allRows = this.rows.all;
      const savedRows = this.rows.saved;
      const initialRows = this.initialRows;
      const initialSavedRows = this.initialSavedRows;

      allRows.find((row) => row.id === this.targetMetric.id).vgtSelected = false;
      initialRows.find((row) => row.id === this.targetMetric.id).vgtSelected = false;
      removeTargetMetricFromArray(savedRows);
      removeTargetMetricFromArray(initialSavedRows);

      updateArray(allRows);
      updateArray(savedRows);
      updateArray(initialRows);
      updateArray(initialSavedRows);

      this.rows = { all: allRows, saved: savedRows };
      this.initialRows = initialRows;
      this.initialSavedRows = initialSavedRows;
    },
    onSelectMetric(metricData) {
      const targetMetric = { ...this.targetMetric, vgtSelected: true };
      this.rows.saved = [...this.rows.saved, targetMetric];
      this.initialSavedRows = [...this.initialSavedRows, targetMetric];

      this.onUpdateMetric(metricData);
    },
    onUpdateMetric(updatedData) {
      const updateArray = (array) => {
        this.updateMetricInArray(array, updatedData, true);
      };

      // This is being done to reduce the amount of re-renders to the minimum of 3.
      const allRows = this.rows.all;
      const savedRows = this.rows.saved;
      const initialRows = this.initialRows;
      const initialSavedRows = this.initialSavedRows;

      updateArray(allRows);
      updateArray(savedRows);
      updateArray(initialRows);
      updateArray(initialSavedRows);

      this.metricDetailModalOpen = false;
    },
    onRemoveMetric() {
      this.targetMetric = {
        ...this.targetMetric,
        goalName: this.goalName,
        goalId: this.goalId,
        irisOrCustom: this.targetMetric.iris_metric_type ? 'iris_metrics' : 'ark_metrics'
      };
      this.metricDetailModalOpen = false;
      this.deleteAssociationModalOpen = true;
    },
    closeMetricModal(customMetric) {
      if (customMetric.ark_metric_definition) {
        const newRow = {
          ...customMetric.ark_metric_definition,
          row_type: 'ark',
          vgtSelected: true
        };
        this.rows.all = [newRow , ...this.rows.all];
        this.rows.saved = [newRow, ...this.rows.saved];
        this.initialRows = [newRow , ...this.initialRows];
        this.initialSavedRows = [newRow , ...this.initialSavedRows];
        this.arkMetricsAlreadySet = [newRow, ...this.arkMetricsAlreadySet];
        this.searchedRows = {
          all: [newRow , ...this.searchedRows.all],
          saved: [newRow , ...this.searchedRows.saved]
        };

        this.filteredRows = {
          all: [newRow , ...this.filteredRows.all],
          saved: [newRow , ...this.filteredRows.saved]
        };

        this.sdgRows = {
          all: [newRow , ...this.sdgRows.all],
          saved: [newRow , ...this.sdgRows.saved]
        };

        if (this.searchString !== '') this.updateSearchResults();

        if (this.impactCategories.length !== 0) this.updateImpactCategoriesResults();

        if (this.sdg.length !== 0) this.updateSdgResults();

        if (!customMetric.ark_metric_definition.originalIndex) {
          customMetric.ark_metric_definition = {
            ...customMetric.ark_metric_definition,
            originalIndex: 0,
            row_type: 'ark',
            vgtSelected: true
          };
        }
        this.showSavedTable = true;
      }
      this.createMetricModalOpen = false;
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../stylesheet/forms.css"></style>

<style scoped>
#setMetricsForm {
  margin-top: -15px;
}

#setMetricsForm .noDataImage {
  width: 48px;
  height: 45px;
}

#setMetricsForm .setMetricsNav {
  background: white;
}

#setMetricsBackTag {
  color: #24422B !important;
}

#setMetricsForm .createCustomMetricButton {
  cursor: pointer;
  font-weight: 900;
  background-color: transparent;
  border: none;
}

#setMetricsForm .createCustomMetricButton:focus {
  border: none;
  outline: 0 !important;
}

#setMetricsForm .setMetricsButtonSave {
  padding: 9px 75px;
}

#setMetricsForm .noBorderRight {
  border-right: 0 solid transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#setMetricsForm .noBorderRight:focus {
  border-right: 1px solid #24422b;
  box-shadow: 0 0 4px 2px #a4b9b4;
}

#setMetricsForm .inputIcon {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 4px 4px 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.saved-state-btn {
  border-radius: 50px;
  background-color: white !important;
  color: #24422b;
  border: 1px #24422b solid !important;
}

.saved-state-btn:focus {
  color: #24422b;
}

.selected-saved-state-btn {
  background-color: #24422b !important;
  color: white;
}

.selected-saved-state-btn:focus {
  color: white;
}

div :deep(.vgt-global-search) {
  border: 1px solid white;
  background: transparent;
}

div :deep(table.vgt-table) {
  background-color: white;
  border: 1px solid white;
}

div :deep(th.sortable) {
  color: #757789;
  padding: 15px;
}

div :deep(th button::after) {
  left: 70px;
}

div :deep(th button::before) {
  left: 70px;
}

div :deep(th.sorting-asc button::after) {
  border-bottom: 5px solid #68B4B9;
}

div :deep(th.sorting-desc button::before) {
  border-top: 5px solid #68B4B9;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after) {
  border-left: 6px solid #999;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after) {
  border-right: 6px solid #999;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.right) {
  width: 10px;
  margin-left: 5px;
}

div :deep(.vgt-wrap__footer .footer__navigation__page-btn .chevron.left) {
  width: 10px;
  margin-right: 5px;
}

div :deep(button.footer__navigation__page-btn) {
  border-width: 0px;
  border-radius: 3px;
  text-transform: none;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  font-weight: 500;
  white-space: nowrap;
  height: 36px;
  -webkit-box-pack: center;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #999  !important;
  outline: none;
}

div :deep(button.footer__navigation__page-btn:hover) {
  transition: background 0s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: rgba(9, 30, 66, 0.08);
}

div :deep(button.footer__navigation__page-btn:focus-visible) {
  transition: background 0s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  background: rgba(9, 30, 66, 0.08);
}

div :deep(button.footer__navigation__page-btn:focus) {
  outline: none;
}

div :deep(button.footer__navigation__page-btn:active) {
  outline: none;
}

div :deep(.vgt-table thead th) {
  background: white;
}

div :deep(.vgt-wrap__footer) {
  border: 1px solid white;
  background: white;
}

div :deep(.vgt-inner-wrap) {
  box-shadow: none;
}

div :deep(.vgt-left-align) {
  color: #68B4B9;
  border-bottom: none;
  padding: 0;
}

.rowButton:hover, .rowButton:focus {
  background-color: #F7F7FC !important;
  cursor: pointer;
}

.checkedIconMetric {
  color: #68B4B9;
}

@media screen and (max-width: 991px) {
  #setMetricsForm {
      margin-top: 10px
  }
}
</style>
