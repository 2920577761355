export const socialMediaLinkInputsMixin = {
  methods: {
    //** Reformat backend jsonb social media link data to populate frontend array with the same data
    convertSocialMediaData(data) {
      let socialMediaLinks = [];
      Object.entries(data).map(([key, value]) => {
        socialMediaLinks = [ ...socialMediaLinks,
            { name: this.defineSocialLink(key), value: value }
          ];
      });
      return JSON.parse(JSON.stringify(socialMediaLinks));
    },
    defineSocialLink(key) {
      const socialLink = key.substring(0, key.indexOf('_'));
      return socialLink.charAt(0).toUpperCase() + socialLink.slice(1);
    },
    //** Reformat frontend social media link data to send to the backend
    requestBody(formSocialMedia) {
      let arrayLinks = {};
      formSocialMedia.map((link) => {
        if (link.value && link.name) {
            arrayLinks[this.defineSocialLinkKey(link.name)] = link.value;
        }
      });

      return arrayLinks
    },
    defineSocialLinkKey(value) {
      return `${value.toLowerCase()}_link`;
    },
    //** Check whether to show the option to add another social media link
    lastSocialLink(formSocialMedia, maxNumberOfLinks) {
      return (
        formSocialMedia.length === 0 ||
        (
          formSocialMedia.filter((x) => x.name === '').length === 0 &&
          formSocialMedia.length < maxNumberOfLinks
        )
      );
    },
  },
}
