<template>
  <div id="createGoalForm">
    <div class="createGoalNav">
      <div class="row d-flex justify-content-center mx-0">
        <div class="col-sm-12 col-md-12 col-lg-10 mt-3 px-0">
          <a
            id="createGoalBackTag"
            class="
              btn
              secondaryButton
              DesktopLinkXSmall
              primaryDefault
              boxShadowNone
            "
            :href="`/${model}s/${modelId}/impact`"
          >
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
            />&nbsp;&nbsp;&nbsp; Back
          </a>
        </div>
      </div>
      <div class="row d-flex justify-content-center mx-0">
        <div
          class="col-sm-12 col-md-12 col-lg-10 my-3 DesktopTextSmall grayscaleOffBlack"
        >
          <img
            :src="imageUrl"
            class="mr-2 noDataImage"
            alt="An image of the world"
          >
          Let’s get started with a few details about your goal.
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-12 col-lg-10 mt-3 DesktopTextXSmall">
        * indicates required
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-12 col-lg-10">
        <div class="arkCard mt-2">
          <div class="DesktopTextMedium textBodyColor mb-3">
            Goal details
          </div>
          <form>
            <div class="row d-flex justify-content-center mt-3">
              <div class="col-12 text-left">
                <input
                  v-model="form.goalName"
                  type="text"
                  class="form-control ark DesktopTextSmall"
                  placeholder="Goal name *"
                  required
                  :class="
                    errorOnsubmit && !form.goalName ? 'notValid' : ''
                  "
                >
                <error-message
                  :error-condition="errorOnsubmit && !form.goalName"
                  error-message="Goal name is a required field."
                />
              </div>
            </div>
            <date-range
              v-if="mode === 'edit' ? form.startDate && form.startDate : true"
              :error-onsubmit="errorOnsubmit"
              :loading="loading"
              :start-date="form.startDate"
              :end-date="form.endDate"
              @date-range="saveDateRange"
            />
            <div class="row d-flex justify-content-center mb-4 mt-3">
              <div class="col-12 text-left newGoalInputButton">
                <textarea
                  v-model="form.description"
                  placeholder="Description *"
                  class="textareaNewGoal form-control ark DesktopTextSmall"
                  aria-label="With textarea"
                  rows="3"
                  :disabled="loading"
                  :class="
                    errorOnsubmit && !form.description ? 'notValid' : ''
                  "
                />
                <error-message
                  :error-condition="errorOnsubmit && !form.description"
                  error-message="Description is a required field."
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center mb-5">
              <div class="col-12 text-left">
                <h2 class="DesktopTextSmall grayscaleLabel">
                  Use your description to briefly describe what problem you are trying to solve. This can be changed later.
                </h2>
              </div>
            </div>

            <div class="row d-flex justify-content-center mt-5">
              <div class="col-12 text-left">
                <h2 class="DesktopTextMedium textBodyColor">
                  Select the impact priorities for this goal
                </h2>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-3">
              <div class="col-12 text-left">
                <p class="DesktopTextSmall grayscaleBody">
                  First, select the high-level impact categories that
                  your goal will be contributing to. To learn more about
                  impact categories please visit review the
                  <a
                    class="teal500 DesktopLinkSmall pointer"
                    href="https://s3.amazonaws.com/giin-web-assets/iris/assets/files/iris/2021-05-27_IRIS-FND_Taxonomy-Final-R3.pdf"
                    target="_blank"
                    rel="noopener"
                  >IRIS+ Thematic Taxonomy</a>.
                </p>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12 text-left">
                <p class="DesktopTextSmall grayscaleBody">
                  Next, further specify which of the species related to
                  {{ modelTitle }}
                  and
                  <a
                    rel="noopener"
                    class="teal500 DesktopLinkSmall pointer"
                    href="https://nc.iucnredlist.org/redlist/content/attachment_files/dec_2012_guidance_threats_classification_scheme.pdf"
                    target="_blank"
                  >IUCN threat types</a>
                  are linked to your goal.
                </p>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12 text-left">
                <h3 class="textBodyColor DesktopTextSmall">
                  Which impact categories best relates to your metric?
                </h3>
              </div>
            </div>
            <div class="row d-flex justify-content-left">
              <div class="col-sm-12 col-md-6 col-lg-5 text-left mt-3">
                <Multiselect
                  v-model="form.selectedCategories"
                  placeholder="Search for categories"
                  :options="categories"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  required
                  :disabled="loading"
                  :class="
                    errorOnsubmit && form.selectedCategories.length === 0
                      ? 'multiselect-ark multiselect-ark-error'
                      : 'multiselect-ark'
                  "
                />
                <error-message
                  :error-condition="
                    errorOnsubmit && form.selectedCategories.length === 0
                  "
                  error-message="Categories is a required field."
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12 text-left">
                <h3 class="textBodyColor DesktopTextSmall">
                  Which species are associated with your goal?
                </h3>
              </div>
            </div>
            <div class="row d-flex justify-content-left">
              <div class="col-sm-12 col-md-6 col-lg-5 text-left mt-3">
                <Multiselect
                  v-model="form.selectedSpecies"
                  placeholder="Search for species"
                  :options="species"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  required
                  :disabled="loading"
                  class="multiselect-ark"
                />
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12 text-left">
                <h3 class="textBodyColor DesktopTextSmall">
                  What threat types will you try to address?
                </h3>
              </div>
            </div>
            <div class="row d-flex justify-content-left mb-5">
              <div class="col-sm-12 col-md-6 col-lg-5 text-left mt-3">
                <Multiselect
                  v-model="form.selectedThreats"
                  placeholder="Search for threat types"
                  :options="threats"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  track-by="title"
                  label="title"
                  required
                  :disabled="loading"
                  :class="
                    errorOnsubmit && form.selectedThreats.length === 0
                      ? 'multiselect-ark multiselect-ark-error'
                      : 'multiselect-ark'
                  "
                />
                <error-message
                  :error-condition="
                    errorOnsubmit && form.selectedThreats.length === 0
                  "
                  error-message="Threat types is a required field."
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      v-if="errorMessageFromBackend"
      class="row d-flex justify-content-center mt-2"
    >
      <div class="col-12 text-center">
        <error-message
          :error-condition="errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-center mx-0">
      <div class="col-sm-12 col-md-5 col-lg-3 col-xl-2 d-flex justify-content-center">
        <div
          v-if="loading"
          class="spinner-border primary2_ark"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <button
          v-else
          class="
            btn
            DesktopLinkXSmall
            grayscaleOffWhite
            my-3
            primaryButton
            pointer
            createGoalButtonSave
          "
          @click="finish"
        >
          {{ mode === 'create' ? 'Create' : 'Update' }} goal
        </button>
      </div>
    </div>
    <avl-modal
      v-if="warningModalOpen"
      :is-open="warningModalOpen"
      size="fit-content"
      header-title=""
      @avleventclose="warningModalOpen = false"
    >
      <div class="delete-modal-title">
        Goal time range changed
      </div>
      <div class="delete-modal-body mt-3">
        Saved data outside the new time range will be deleted. Are you sure you want to proceed with the update?
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
            @click="warningModalOpen = false"
          >
            <div class="cancel-button">Cancel</div>
          </button>
          <button
            type="button"
            class="btn btn-secondary7-ark no-shadow mx-1 mb-0"
            @click="saveMyGoal"
          >
            <div class="delete-button">Update</div>
          </button>
        </div>
      </div>
    </avl-modal>
  </div>
</template>

<script>
import * as yup from 'yup';
import DateRange from '../../../components/shared/DateRange.vue';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../api/config';

const ValidationSchema = yup.object({
  goalName: yup.string().required(),
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  description: yup.string().required(),
  selectedCategories: yup.array().min(1).required(),
  selectedSpecies: yup.array(),
  selectedThreats: yup.array().min(1).required()
});

export default {
  components: { ErrorMessage, Multiselect, DateRange },
  data() {
    return {
      loading: false,
      modelTitle: '',
      categories: [],
      species: [],
      threats: [],
      modelId: undefined,
      goalId: undefined,
      model: undefined,
      mode: 'create',
      form: {
        goalName: '',
        startDate: '',
        endDate: '',
        description: '',
        selectedCategories: [],
        selectedSpecies: [],
        selectedThreats: []
      },
      oldData: {
        startDate: '',
        endDate: ''
      },
      errorOnsubmit: false,
      errorMessageFromBackend: '',
      warningModalOpen: false
    };
  },
  computed: {
    minStartDate() {
      if (!this.form.endDate) {
        return null;
      }

      const minDate = new Date(this.form.endDate);
      minDate.setFullYear(minDate.getFullYear() - 30);
      return minDate;
    },
    maxEndDate() {
      if (!this.form.startDate) {
        return null;
      }

      const maxDate = new Date(this.form.startDate);
      maxDate.setFullYear(maxDate.getFullYear() + 30);
      return maxDate;
    }
  },
  beforeMount() {
    this.getModelInfo();
  },
  mounted() {
    this.getCategories();
    this.getSpecies();
    this.getIucnThreats();
    if (this.mode === 'edit') this.getGoalInfo();
  },
  methods: {
    getModelInfo() {
      const createGoal = document.querySelector('#goal-form');
      this.modelId = createGoal?.dataset.modelId;
      this.model = createGoal?.dataset.model;
      this.imageUrl = createGoal?.dataset.imgUrl;
      this.mode = createGoal?.dataset.mode;
      this.goalId = createGoal?.dataset.goalId;
    },
    getCategories() {
      fallbackApi({
        url: 'iris_categories',
        dataSetter: (data) => {
          const categories = data.iris_categories;
          categories.forEach((category) => {
            // we need this because of multiselect component
            category.value = category.id;
          });
          this.categories = categories;
        }
      });
    },
    getSpecies() {
      const endpoint = this.model === 'organization' ? 'about' : '';
      fallbackApi({
        url: `${this.model}s/${this.modelId}/${endpoint}`,
        dataSetter: (data) => {
          this.modelTitle =
            this.model === 'organization'
              ? data?.organization?.name
              : data?.project?.project?.title;

          const { species } = data[this.model];
          // We need this because of multiselect component
          species.forEach((sp) => sp.value = sp.id);
          this.species = species;
        }
      });
    },
    getIucnThreats() {
      fallbackApi({
        url: 'iucn_threats',
        dataSetter: (data) => {
          const { iucn_threats } = data;
          // We need this because of multiselect component
          iucn_threats.forEach((threat) => threat.value = threat.code);
          this.threats = iucn_threats;
        }
      });
    },
    getGoalInfo() {
      fallbackApi({
        url: `goals/${this.goalId}`,
        dataSetter: (data) => {
          const { goal } = data;
          const selectedCategories = goal?.iris_categories.map(
            (category) => category.id
          );
          const selectedSpecies = goal?.species.map(
            (species) => species.id
          );
          const selectedThreats = goal?.iucn_threats.map(
            (threat) => threat.code
          );

          this.form = {
            goalName: goal?.name,
            startDate: goal?.start_date,
            endDate: goal?.end_date,
            description: goal?.description,
            selectedCategories,
            selectedSpecies,
            selectedThreats
          };
          this.oldData = {
            startDate: goal?.start_date,
            endDate: goal?.end_date
          };
        }
      });
    },
    finish() {
      if (this.mode === 'edit' &&
          (this.form.startDate !== this.oldData.startDate ||
          this.form.endDate !== this.oldData.endDate)) {
        this.warningModalOpen = true;
        return;
      }

      this.saveMyGoal();
    },
    saveMyGoal() {
      this.loading = true;

      const goalData = {
        name: this.form.goalName,
        start_date: this.form.startDate,
        end_date: this.form.endDate,
        description: this.form.description,
        [`${this.model}_id`]: parseInt(this.modelId),
        iris_category_ids: this.form.selectedCategories,
        species_ids: this.form.selectedSpecies,
        iucn_threat_codes: this.form.selectedThreats
      };

      ValidationSchema.validate(this.form)
        .then(() => {
          fallbackApi({
            url: this.mode === 'edit' ? `goals/${this.goalId}` : 'goals',
            payload: { json: { goal: goalData } },
            httpVerb: this.mode === 'edit' ? 'patch' : 'post'
          })
          .then(() => {
            this.errorOnsubmit = false;
            this.errorMessageFromBackend = '';
            location.assign(`/${this.model}s/${this.modelId}/impact`);
          })
          .catch((error) => {
            this.errorMessageFromBackend = error.error.message;
          })
          .finally(() => (this.loading = false));
        })
        .catch(() => {
          this.errorOnsubmit = true;
          this.loading = false;
        });
    },
    saveDateRange(startDate, endDate) {
      this.form.startDate = startDate;
      this.form.endDate = endDate;
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../stylesheet/forms.css"></style>

<style scoped>
#createGoalForm {
  margin-top: -15px;
  font-family: Poppins, sans-serif;
}

#createGoalForm .noDataImage {
  width: 48px;
  height: 45px;
}

#createGoalForm .createGoalNav {
  background: white;
}

#createGoalForm .createGoalButtonSave {
  padding: 9px 75px;
}

#createGoalBackTag {
  color: #355159 !important;
}

#createGoalForm .form-control .ark {
  border-radius: 4px;
}

#createGoalForm input {
  color: #212529;
}

#createGoalForm input ::placeholder {
  color: #6c757d;
}

#createGoalForm .noBorderRight {
  border-right: 0px solid transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#createGoalForm .noBorderRight:focus {
  border-right: 1px solid #24422b;
  box-shadow: 0px 0px 4px 2px #a4b9b4;
}

#createGoalForm .input-group {
  margin-bottom: 0px !important;
}

#createGoalForm .inputIcon {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1em;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0 4px 4px 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#createGoalForm .notValid {
  border-radius: 4px;
  border: 1px solid red;
}

#createGoalForm .textareaNewGoal {
  background-color: transparent;
  border-radius: 4px;
  padding: 7px 13px;
  width: 100%;
}

#createGoalForm .textareaNewGoal ::placeholder {
  color: #6c757d;
}

@media screen and (max-width: 991px) {
  #createGoalForm {
      margin-top: 10px
  }
}
</style>
