<template>
  <section class="maxWidthInputs">
    <form>
      <section class="text-left mt-4">
        <fieldset>
          <legend class="sr-only">Organization partners:</legend>
          <div class="row">
            <div class="col-12">
              <Multiselect
                v-model="form.selectedPartners"
                placeholder="Company or organisation name"
                :options="availableOptions"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                track-by="name"
                label="name"
                required
                :disabled="loading"
                class="multiselect-ark"
                :create-option="true"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <button
        v-if="dataChanged"
        class="btn DesktopLinkXSmall cancelButton"
        @click="discardChanges"
      >
        Cancel
      </button>
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged || !!errorMessage"
        @click="submit"
      >
        Save
      </button>
    </div>
    <div v-show="organization.partners" class="py-3 pt-3 mb-4">
      <ul class="listStyleNone p-0">
        <li
          v-for="partner in organization.partners"
          :key="partner.id + partner.name"
        >
          <section class="py-2">
            <a
              v-if="partner.in_ark"
              :href="`/organizations/${partner.partneree_id}`"
              class="row"
              target="_self"
            >
              <figure class="col-2">
                <img
                  v-if="partner.logo"
                  :src="partner.logo"
                  class="logoImage"
                  :alt="partner.name + 's logo'"
                >
                <div
                  v-else
                  class="
                    p-2 d-flex rad-5
                    secondary7_bg_ark
                    buildingContainer
                    justify-content-center
                    align-items-center"
                  role="img"
                  aria-label="No logo image available."
                >
                  <i class="white fas fa-building" aria-hidden="true" />
                </div>
              </figure>
              <article class="col">
                <h3 class="DesktopLinkSmall primaryDefault mb-2 mt-0">
                  {{ partner.name }}
                </h3>
                <p class="DesktopTextSmall grayscaleBody mb-0">
                  {{ organizationType(partner.type) }}
                </p>
              </article>
              <p
                class="
                  col-1
                  d-flex
                  justify-content-end
                "
              >
                <span
                  class="btn btn-link px-2"
                  @click.prevent="deletePartnership(partner.id)"
                >
                  <i class="fas fa-trash fa-lg" />
                </span>
              </p>
            </a>
            <div
              v-else
              class="row"
            >
              <section class="col-2">
                <input
                  :ref="'logoInput-' + partner.id"
                  type="file"
                  :accept="imageMimeTypes.join(', ')"
                  hidden
                  @input="addLogo(partner)"
                >
                <div class="logoContainer">
                  <img
                    v-if="partner.logo"
                    :src="partner?.logo"
                    class="logoImage"
                    :ref="'logoImage-' + partner.id"
                    alt=""
                  >
                  <button
                    class="btn btn-link p-0 m-0 align-self-end editLogoBtn"
                    style="margin-top: -23px !important"
                    aria-label="Edit logo menu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      class="fa fa-pencil edit-icon-pencil grayscaleBody"
                      aria-hidden="true"
                    />
                  </button>
                  <div class="d-flex justify-content-end">
                    <div class="dropdown-menu dropdown-primary" role="menu">
                      <button
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="updatePartnerLogo(partner.id)"
                      >
                        <i class="far fa-camera mr-2" aria-hidden="true" /> Upload logo image
                      </button>
                      <button
                        v-show="partner.logo"
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="openEditImageModal(partner, 'logo')"
                      >
                        <i class="fad fa-arrows-v mr-2" aria-hidden="true" /> Adjust logo image
                      </button>
                      <button
                        type="button"
                        class="dropdown-item w-100 m-0 pointer"
                        @click="deletePartnerLogo(partner.id, 'logo')"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <article class="col">
                <h3 class="DesktopLinkSmall primaryDefault mb-2 mt-0">
                  {{ partner.name }}
                </h3>
                <Multiselect
                  v-model="partner.type"
                  placeholder="Type"
                  :options="partnerTypes"
                  mode="single"
                  :close-on-select="true"
                  :searchable="true"
                  track-by="name"
                  label="name"
                  :disabled="loading"
                  class="multiselect-ark"
                  @select="updatePartnership(partner.id, partner.type)"
                  @clear="updatePartnership(partner.id, '')"
                />
              </article>
              <p
                class="
                  col-1
                  d-flex
                  justify-content-end
                "
              >
                <span
                  class="btn btn-link px-2"
                  @click.prevent="deletePartnership(partner.id)"
                >
                  <i class="fas fa-trash fa-lg" />
                </span>
              </p>
            </div>
          </section>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { sharedMixin } from '../../../../mixins';
import { openDefaultEditor } from '@pqina/pintura';

export default {
  components: { ErrorMessage, Multiselect },
  mixins: [sharedMixin],
  props: {
    organization: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    }
  },
  emits: ['save-organization', 'delete-partner', 'update-partner-logo'],
  data() {
    return {
      loading: false,
      availableOptions: [],
      totalOptions: [],
      form: {
        selectedPartners: []
      },
      partnerTypes: ['Project Developer','Technology', 'Investor'],
      imageMimeTypes: ['image/jpeg', 'image/png'],
      errorMessageFromBackend: '',
      errorMessage: '',
      logo: null,
      invalidLogoModalOpen: false
    };
  },
  computed: {
    dataChanged() {
      return this.form.selectedPartners.length !== 0;
    }
  },
  mounted() {
    this.getOrganizations();
  },
  methods: {
    getLogoInputRef(partner_id) {
      return this.$refs[`logoInput-${partner_id}`][0];
    },
    addLogo(partner) {
      this.logo = this.getLogoInputRef(partner.id).files[0];
      if (!this.imageMimeTypes.includes(this.logo?.type)) {
        this.invalidLogoModalOpen = true;
        return;
      }
      this.openEditImageModal(partner, 'logo');
    },
    updatePartnerLogo(partner_id) {
      this.getLogoInputRef(partner_id).click();
    },
    openEditImageModal(partner, key) {
      const image = this[key] || partner[key];
      if (!image) return;

      const aspectRatio = 1;

      openDefaultEditor({
        src: image,
        imageCropAspectRatio: aspectRatio,
        utils: ['crop'],
        handleEvent: (type, detail) => {
          if (type === 'process') {
            this.uploadPartnerLogo(partner.id, key, detail.dest);
          }
        }
      });
    },
    uploadPartnerLogo(partner_id, key, file) {
      const formData = new FormData();
      formData.append('partnership_id', partner_id);
      formData.append(key, file);
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/partners`,
        payload: {
          body: formData,
          // Remove json content type so browser can set the appropriate formdata content type
          headers: {
            'Content-Type': undefined
          }
        },
        httpVerb: 'patch',
        dataSetter: () => {
          this.$emit('update-partner-logo');
          this[key] = null;
        }
      });
    },
    deletePartnerLogo(partner_id, key) {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/partners`,
        httpVerb: 'patch',
        payload: {
          json: {
            partnership_id: partner_id,
            delete_logo: true
          }
        },
        dataSetter: (data) => {
          const image = data && data[key];
          this.$emit('update-partner-logo');
        }
      });
    },
    //** Get all organization options
    getOrganizations() {
      fallbackApi({
        url: 'organizations/index_summary',
        dataSetter: (data) => {
          this.totalOptions = data.organizations;
          this.totalOptions.forEach((org) => {
            // we need this because of multiselect component
            org.value = org.id;
          });
          this.filterOptions();
        }
      });
    },
    //** Filter organization options to not include existing partners
    filterOptions() {
      this.availableOptions = this.totalOptions;
      let partneree_ids = this.organization.partners.map(i => i['partneree_id'])
      this.availableOptions = this.availableOptions.filter(
            (item) => item.id !== this.organization.id && !partneree_ids.includes(item.id)
            )
    },
    //** Update non-ARK partner category/type
    updatePartnership(id, selectedType) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/partners`,
        payload: {
          json: {
            partnership_id: id,
            category: selectedType
          }
        },
        httpVerb: 'patch'
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.filterOptions();
        this.loading = false;
      });
    },
    deletePartnership(id) {
      this.loading = true;
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/partners`,
        payload: {
          json: { partnership_id: id }
        },
        httpVerb: 'delete'
      })
      .then(() => {
        this.$emit('delete-partner', id);
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      })
      .finally(() => {
        this.filterOptions();
        this.loading = false;
      });
    },
    error(error) {
      this.errorMessage = error? error.main + '' : '';
    },
    // Submit new partner selections
    async submit() {
      if (this.form.selectedPartners.length !== 0) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/partners`,
          payload: { json: { partners: this.form.selectedPartners }},
          httpVerb: 'post'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.form.selectedPartners = [];
          this.$emit('save-organization', response.data);
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => {
          this.filterOptions();
          this.loading = false;
        });
      }
    },
    discardChanges() {
      this.form.selectedPartners = [];
      this.errorMessage = '';
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/lists.css"></style>
<style scoped src="../../../../stylesheet/forms.css"></style>
