import UserProfile from './components/UserProfile.vue';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#user-profile')){
    const app = createApp(UserProfile);
    app.use(store);
    app.mount('#user-profile');
  }
});
