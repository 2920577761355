<template>
  <div>
    <div id="mapPreview" style="height: 340px" />
  </div>
</template>

<script>
import { loadModules } from 'esri-loader';
import { pointSymbolConfig } from '../../../features/map/helpers/consts';

export default {
  name: 'MapPreview',
  props: {
    locations: {
      type: Array,
      required: true
    }
  },
  watch: {
    locations() {
      loadModules([
        'esri/views/MapView',
        'esri/WebMap',
        'esri/Graphic'
      ])
        .then(([
          MapView,
          WebMap,
          Graphic
        ]) => {
          const arkMap = new WebMap({
            portalItem: {
              id: '2db26f96006441aaad38e93a318e0c38'
            }
          });
          const view = new MapView({
            map: arkMap,
            center: [10, 40], // Set the initial center of the map
            zoom: 1, // Set the initial zoom level of the map
            container: 'mapPreview',
            constraints: {
              minZoom: 1
            }
          });
          /**
           * Move and add widgets on the map viewport
           */
          view.ui.move(['zoom'], 'bottom-right');
          /**
           * If you have an array of locations
           * create points from them and add them to the map
           */
          const locations = this.locations

          const graphics = locations.map((location) => {
            return new Graphic({
              geometry: {
                type: 'point',
                latitude: location.lat,
                longitude: location.lon
              },
              symbol: pointSymbolConfig,
              attributes: {
                name: location.name
              }
            });
          });
          view.graphics.addMany(graphics);
        })
        .catch((err) => {
          // handle any errors
          Sentry && Sentry.captureException(err);
          console.error(err);
        });
    }
  }
};
</script>

<style scoped>
:deep(.esri-attribution) {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}
:deep(.esri-attribution__link) {
  color: white !important;
}
</style>
