<template>
  <section class="maxWidthInputs">
    <form>
      <section class="mt-4">
        <fieldset>
          <legend class="sr-only">Profile work:</legend>
          <h4 class="grayscaleOffBlack DesktopLinkSmall">Work</h4>
          <div class="row">
            <div class="col-12 mb-3">
              <label
                for="workAssociation"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Organisation or Company
              </label>
              <info-notice
                v-show="onlyAdmin"
                :notice-text="noticeText"
                class="py-3 mb-4"
              />
              <Multiselect
                id="workAssociation"
                v-model="form.workplace"
                placeholder="Select or type name"
                :options="availableOptions"
                :close-on-select="true"
                :searchable="true"
                track-by="name"
                label="name"
                :disabled="loading || onlyAdmin"
                class="multiselect-ark"
                :create-option="true"
                @change="handleInputUpdate"
                @select="handleSelect"
                @create="handleCreate"
              />
              <error-message
                id="aboutError"
                :error-condition="!!errors.workplace"
                :error-message="errors.workplace"
              />
            </div>
            <div class="col-12 mb-3">
              <label
                for="occupationInput"
                class="DesktopTextSmall grayscaleOffBlack"
              >
                Job title
              </label>
              <input
                id="occupationInput"
                v-model="form.occupation"
                type="text"
                class="form-control ark DesktopTextSmall"
                required
                aria-errormessage="occupationError"
                :aria-invalid="!!errors.occupation"
                :class="{ notValid: errors.occupation }"
                @blur="validate"
                @input="handleInputUpdate"
              >
              <error-message
                id="occupationError"
                :error-condition="!!errors.occupation"
                :error-message="errors.occupation"
              />
            </div>
          </div>
        </fieldset>
      </section>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!formUpdated"
        @click="submit"
      >
        Save
      </button>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import InfoNotice from '../../../../components/shared/InfoNotice.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  workplace: yup.string().matches(/^[^<>]*$/, 'JavaScript code is not allowed').nullable(),
  occupation: yup.string().matches(/^[^<>]*$/, 'JavaScript code is not allowed')
});

export default {
  components: { ErrorMessage, InfoNotice, Multiselect },
  props: {
    user: {
      type: Object,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
  },
  emits: ['save-user'],
  data() {
    return {
      loading: false,
      availableOptions: [],
      totalOptions: [],
      showErrors: false,
      form: {
        workplace: '',
        occupation: '',
      },
      errors: {
        workplace: '',
        occupation: '',
      },
      errorMessageFromBackend: '',
      formUpdated: false,
      newOptionCreated: false, //** For backend interpretation in case typed input is a Number
      onlyAdmin: false,
      noticeText: `You are the only administrator for this organisation, modifying the role will result in losing administrative access to the page. This means that no one will be able to post updates or manage the page's content.`
    };
  },
  watch: {
    user() {
      this.getUserInfo();
    }
  },
  mounted() {
    this.getOrganizations();
    this.getUserInfo();
  },
  methods: {
    handleCreate() {
      this.newOptionCreated = true;
    },
    handleSelect(option) {
      const selectedOption = this.availableOptions.find((o) => o.value === option)
      // Check if the selected option exists in the options list
      if (selectedOption) {
        // Existing option is selected
        this.newOptionCreated = false;
      } else {
        // New option is created and selected
        this.newOptionCreated = true;
      }
    },
    //** Enable Save button if there is iteraction with the form
    handleInputUpdate() {
      this.formUpdated = true;
    },
    //** Get all organization options
    getOrganizations() {
      fallbackApi({
        url: 'organizations/index_summary',
        dataSetter: (data) => {
          this.availableOptions = data.organizations;
          this.availableOptions.forEach((org) => {
            // we need this because of multiselect component
            org.value = org.id;
          });
        }
      });
    },
    //** Show existing user data
    getUserInfo() {
      //** ARK entity associations which may be null, hence their id may be undefined
      var ark_organization_id =  this.user?.organization?.id;
      var ark_tech_org_id = this.user?.tech_org?.id;
      //** Non ARK entity association aka company which may be undefined
      var non_ark_company_name = this.user?.user.company;
      var workplace = this.assignWorkPlace(ark_organization_id, ark_tech_org_id, non_ark_company_name)

      this.form = {
        workplace: workplace,
        occupation: this.user?.user.occupation
      };

      this.onlyAdmin = this.user?.only_admin;
    },
    //** Assign workplace option (if one exists) as an id if its an ARK entity, as a name if it's not
    assignWorkPlace(ark_organization_id, ark_tech_org_id, non_ark_company_name) {
      // return ark_organization_id !== undefined ? ark_organization_id : ark_tech_org_id !== undefined ? ark_tech_org_id : non_ark_company_name !== undefined ? non_ark_company_name : '';
      if (ark_organization_id !== undefined) {
        return ark_organization_id;
      }
      if (ark_tech_org_id !== undefined) {
        return ark_tech_org_id;
      }
      if (non_ark_company_name !== undefined) {
        this.availableOptions.push({name: non_ark_company_name, value: non_ark_company_name})
        return non_ark_company_name;
      }
      return '';
    },
    async validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: `${this.modelPluralize}/${this.modelId}/work`,
          payload: {
            json:
              {
                user: {
                  workplace: this.form.workplace,
                  new_workplace: this.newOptionCreated,
                  occupation: this.form.occupation,
                }
              }
          },
          httpVerb: 'patch'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.$emit('save-user', response.data.user);
          this.showErrors = false;
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
        .finally(() => (this.loading = false));
      }
    },
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
