<template>
  <section  class="my-3">
    <div class="row align-items-center justify-content-between">
      <h3 class="col col-lg-10 DesktopLinkSmall primaryDefault title-size m-0">
        <i class="fas fa-book-open mr-1" aria-hidden="true" /> {{ paper.title }}
      </h3>
      <p class="col col-lg-2 DesktopLinkSmall grayscaleBody text-right m-0">
        {{ releaseDate }}
      </p>
    </div>
    <p class="DesktopTextXSmall grayscaleBody mb-0">
      {{ paper.authors }}
    </p>
    <p class="DesktopTextXSmall grayscaleBody mb-1">
      {{ paper.journal }} {{ paper.volume }} <span v-if="paper.issue">({{ paper.issue }}) </span>
      <span v-if="paper.page_start && paper.page_end">
        {{ paper.page_start }} - {{ paper.page_end }}
      </span>
    </p>
    <a
      v-if="paper.paper_url"
      class="btn btn-sm DesktopLinkXSmall borderedButton transition-02 btn-round"
      :href="paper.paper_url"
      target="_blank"
      rel="noreferrer"
    >
      Preview
    </a>
  </section>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    paper: {
      type: Object,
      required: true
    }
  },
  computed: {
    releaseDate() {
      return (
        this.paper.release_date &&
        DateTime.fromISO(this.paper.release_date).toFormat('LLL yyyy')
      );
    }
  }
};
</script>

<style scoped>
.title-size {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>

