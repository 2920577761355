export const sitesMixin = {
  data() {
    return {
      headquartersId: -1
    };
  },
  methods: {
    formatArea(area) {
      return area.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 0 });
    },
    siteTypeColor(type) {
      switch (type) {
        case 'Submitted site':
          return [160, 213, 126, 255];
        case 'Paired development site':
          return [245, 129, 101, 255];
        case 'Reference site':
          return [140, 228, 217, 255];
        case 'Upcoming site':
          return [251, 192, 45, 255];
        default:
          return [160, 213, 126, 255];
      }
    },
    siteTypeClass(type) {
      switch (type) {
        case 'Submitted site':
          return 'submitted-site';
        case 'Paired development site':
          return 'paired-development-site';
        case 'Reference site':
          return 'reference-site';
        case 'Upcoming site':
          return 'upcoming-site';
        default:
          return 'submitted-site';
      }
    }
  }
}
