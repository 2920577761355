import 'v-calendar/dist/style.css';
import OrganizationNews from './components/OrganizationNews.vue';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import { store } from '../../../store';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('#model-news')){
    const app = createApp(OrganizationNews);
    app.use(store);
    app.use(VCalendar, {});
    app.mount('#model-news');
  }
});