import * as yup from 'yup';

export const ValidationSchemaSocialMedia = yup.array().of(
  yup.object().shape({
    name: yup.string().required(),
    value: yup.string().matches(
            /^(?:(?:https?:\/\/)?(?:www\.)?|(?:www\.))(?:[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)$/,{excludeEmptyString: true, message: 'Please enter a valid url'}
            ).required().when('name', (name, schema) => {
      return schema.label(name);
    })
  })
);

export const ValidationSchemaUserBasicInfo = yup.object({
  firstName: yup.string().required('First name is a required field.'),
  lastName: yup.string().required('Last name is a required field.'),
  userGroup: yup.string().nullable().required('User group is a required field.'),
  email: yup.string().required().email(),
  phoneNumber: yup.string().required('Phone number is required').matches(/^[+0-9\-]+$/, 'Invalid phone number'),
  website: yup.string().nullable().matches(
            /^(?:(?:https?:\/\/)?(?:www\.)?|(?:www\.))(?:[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*)?)$/,{excludeEmptyString: true, message: 'Please enter a valid url'}
            )
});

export const ValidationSchemaOrgGeneralInfo = yup.object({
  name: yup.string().required(),
  locationName: yup.string().nullable(),
  latitude: yup.number()
    .typeError('location is a required field')
    .required('location is a required field'),
  longitude: yup.number()
    .typeError('location is a required field')
    .required('location is a required field'),
  email: yup.string().required().email(),
  website: yup.string().nullable().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,{excludeEmptyString: true, message: 'Please enter a valid url'}
            ),
  description: yup.string().required(),
  legalTypeId: yup.number()
    .typeError('legal entity type is a required field')
    .required('legal entity type is a required field')
});
