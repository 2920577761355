<template>
  <header slot="header" class="row custom-header">
    <div class="col-12 px-3 py-2">
      <h2 class="DesktopTextMedium textBodyColor">
        Configure '{{ metric.selectedFormat }}' field
      </h2>
    </div>
  </header>
  <section class="text-left">
    <section class="row justify-content-center">
      <div class="col-12 text-left">
        <h3 class="textBodyColor DesktopTextSmall mt-0">
          Which impact categories best relate to your metric?
        </h3>
      </div>
    </section>
    <section class="row justify-content-start">
      <div class="col-sm-12 col-md-8 col-lg-7 col-xl-5 text-left">
        <Multiselect
          ref="categoriesMultiselect"
          v-model="form.selectedCategories"
          placeholder="Search for categories *"
          :options="categories"
          mode="tags"
          :close-on-select="false"
          :searchable="true"
          track-by="name"
          label="name"
          value-prop="id"
          required
          :disabled="loading"
          :class="getErrorClass(form.selectedCategories.length === 0)"
          @open="openMultiselect('categoriesMultiselect')"
          @close="closeMultiselect('categoriesMultiselect')"
        />
        <error-message
          :error-condition="
            getErrorClass(form.selectedCategories.length === 0, true)
          "
          error-message="Categories is a required field."
        />
      </div>
    </section>
    <section class="row justify-content-center">
      <div class="col-12 text-left">
        <h3 class="textBodyColor DesktopTextSmall">
          Select the SDGs that best relate to your metric
          <i class="fa fa-question-circle grayscalePlaceholder" aria-hidden="true" />
        </h3>
      </div>
    </section>
    <section v-if="sdgGoals && sdgGoals.length > 0" class="row m-0">
      <div
        v-for="sdg in sdgGoals"
        :key="sdg.sdg_goal_code"
        class="m-0"
      >
        <input
          :id="`sdg-${sdg.sdg_goal_code}`"
          v-model="form.selectedSdgGoals"
          :value="sdg.sdg_goal_code"
          type="checkbox"
          class="sdg-input"
          :aria-label="`Sdg ${sdg.sdg_goal_code}: ${sdg.title}`"
        >
        <label
          :for="`sdg-${sdg.sdg_goal_code}`"
          :style="{
            '--background': `url(${sdg.inverted_avatar})`,
            '--checked-background': `url(${sdg.avatar})`
          }"
          class="sdg-label"
        />
      </div>
    </section>
    <section v-else class="row justify-content-center">
      <span aria-hidden="true" class="spinner-border mr-2" />
      <span>Loading</span>
    </section>
    <section class="row justify-content-center mt-3">
      <div class="col-12 text-left">
        <input
          v-model="form.customMetricName"
          type="text"
          class="form-control ark DesktopTextSmall"
          placeholder="Metric name *"
          required
          :class="{ notValid: errorOnSubmit && !form.customMetricName }"
        >
        <error-message
          :error-condition="getErrorClass(!form.customMetricName, true)"
          error-message="Custom metric name is a required field."
        />
      </div>
    </section>
    <section class="row justify-content-center mb-1">
      <div class="col-12 text-left">
        <textarea
          v-model="form.description"
          placeholder="Description *"
          class="textareaNewGoal form-control ark DesktopTextSmall mt-3"
          aria-label="Description"
          rows="3"
          :disabled="loading"
          required
          :class="{ notValid: errorOnSubmit && !form.description }"
        />
        <error-message
          :error-condition="getErrorClass(!form.description, true)"
          error-message="Description is a required field."
        />
      </div>
    </section>
    <span class="textBodyColor DesktopLinkXSmall">
      Overview of what the metric measures and its importance.
    </span>
    <section class="row justify-content-center mb-1">
      <div class="col-12 text-left">
        <textarea
          v-model="form.usageGuidance"
          placeholder="Usage guidance *"
          class="textareaNewGoal form-control ark DesktopTextSmall mt-3"
          aria-label="With usage guidance"
          rows="3"
          :disabled="loading"
          required
          :class="{ notValid: errorOnSubmit && !form.usageGuidance }"
        />
        <error-message
          :error-condition="getErrorClass(!form.usageGuidance, true)"
          error-message="Usage guidance is a required field."
        />
      </div>
    </section>
    <span class="textBodyColor DesktopLinkXSmall">
      Instructions for using and interpreting the metric.
    </span>
    <section class="row justify-content-center mb-1">
      <div class="col-12 text-left">
        <textarea
          v-model="form.dataSource"
          placeholder="Data source"
          class="textareaNewGoal form-control ark DesktopTextSmall mt-3"
          aria-label="With data source"
          rows="3"
          :disabled="loading"
        />
      </div>
    </section>
    <span class="textBodyColor DesktopLinkXSmall">
      Origin and reliabity of the metric's data.
    </span>
    <section class="row justify-content-center mb-1">
      <div class="col-12 text-left">
        <textarea
          v-model="form.verificationMeans"
          placeholder="Means of verification"
          class="textareaNewGoal form-control ark DesktopTextSmall mt-3"
          aria-label="With means of verification"
          rows="3"
          :disabled="loading"
        />
      </div>
    </section>
    <span class="textBodyColor DesktopLinkXSmall mt-4">
      Process to collect, measure and validate data.
    </span>
  </section>
  <section
    v-if="selectUnits && metric.selectedFormat === 'Number'"
    class="text-left"
  >
    <hr class="my-4">
    <h3 class="textBodyColor DesktopTextSmall">
      What units should be used?
    </h3>
    <div class="d-flex align-items-center">
      <input id="number-simple" v-model="form.formatType" type="radio" value="simple">
      <label for="number-simple" class="textBodyColor DesktopTextSmall mx-3">
        Simple Number Field
      </label>
    </div>
    <div class="d-flex align-items-center">
      <input id="number-unit" v-model="form.formatType" type="radio" value="unit">
      <label for="number-unit" class="textBodyColor DesktopTextSmall mx-3 mb-0">
        Number Field with Units
      </label>
      <div v-if="form.formatType === 'unit'" style="width: 250px">
        <Multiselect
          ref="unitMultiselect"
          v-model="form.measurement_unit"
          placeholder="Select Unit *"
          :options="measurementOptions"
          :close-on-select="true"
          track-by="name"
          label="name"
          :object="true"
          value-prop="id"
          required
          :disabled="loading"
          :class="getErrorClass(!form.measurement_unit)"
          @open="openMultiselect('unitMultiselect')"
          @close="closeMultiselect('unitMultiselect')"
        />
        <error-message
          :error-condition="
            getErrorClass(!form.measurement_unit, true)
          "
          error-message="Unit is a required field."
        />
      </div>
    </div>
  </section>
  <section
    v-else-if="selectUnits && metric.selectedFormat === 'Currency'"
    class="text-left"
  >
    <hr class="my-4">
    <h3 class="textBodyColor DesktopTextSmall">
      What units should be used?
    </h3>
    <div class="row justify-content-left">
      <div class="col-sm-12 col-md-8 col-lg-7 col-xl-5">
        <Multiselect
          ref="currencyMultiselect"
          v-model="form.selectedCurrency"
          placeholder="Currency type *"
          :options="['Euro']"
          :close-on-select="true"
          :searchable="true"
          track-by="name"
          label="name"
          required
          :disabled="loading"
          :class="getErrorClass(form.selectedCurrency === null)"
          @open="openMultiselect('currencyMultiselect')"
          @close="closeMultiselect('currencyMultiselect')"
        />
        <error-message
          :error-condition="
            getErrorClass(form.selectedCurrency === null, true)
          "
          error-message="Currency type is a required field."
        />
      </div>
    </div>
  </section>
  <section
    v-else-if="selectUnits && metric.selectedFormat === 'Multiple Choice'"
    class="text-left"
  >
    <hr class="my-4">
    <h3 class="textBodyColor DesktopTextSmall">
      Options *
    </h3>
    <div class="row justify-content-left">
      <div class="col-sm-12 col-md-8 col-lg-7 col-xl-5">
        <Multiselect
          ref="enumMultiselect"
          v-model="form.enumeration"
          tag-placeholder="Add this as new item"
          placeholder="Enter a new item/items."
          mode="tags"
          track-by="value"
          :options="metric.enumeration"
          label="value"
          :searchable="true"
          :create-tag="true"
          no-options-text="Write the name of the item and press enter"
          no-results-text="Add another item and press enter"
          required
          :disabled="loading"
          :class="getErrorClass(form.enumeration.length === 0)"
          @open="openMultiselect('enumMultiselect')"
          @close="closeMultiselect('enumMultiselect')"
        />
        <error-message
          :error-condition="
            getErrorClass(form.enumeration.length === 0, true)
          "
          error-message="Add at least one item."
        />
      </div>
    </div>
  </section>
  <section class="text-left">
    <hr class="my-3">
    <div class="row justify-content-left align-items-center">
      <h3 class="col-12 col-md-7 col-lg-6 col-xl-4 textBodyColor DesktopTextSmall">
        What reporting frequency should be used?
      </h3>
      <div class="col-12 col-md-5 col-lg-6 col-xl-4">
        <Multiselect
          ref="frequencyMultiselect"
          v-model="form.entry_frequency"
          placeholder="Select Frequency *"
          track-by="name"
          label="name"
          value-prop="id"
          required
          :options="entryFrequencyOptions"
          :close-on-select="true"
          :object="true"
          :disabled="loading"
          :class="getErrorClass(!form.entry_frequency)"
          @open="openMultiselect('frequencyMultiselect')"
          @close="closeMultiselect('frequencyMultiselect')"
        />
        <error-message
          :error-condition="
            getErrorClass(!form.entry_frequency, true)
          "
          error-message="Frequency is a required field."
        />
      </div>
    </div>
  </section>
  <section class="text-left">
    <hr class="my-4">
    <div
      v-for="field in auditorFields" :key="field.id"
      class="row justify-content-left align-items-center"
    >
      <h3 class="col-12 col-md-7 col-lg-6 col-xl-4 textBodyColor DesktopTextSmall">
        Who will be {{ field.verb }} data for this metric?
      </h3>
      <div class="col-12 col-md-5 col-lg-6 col-xl-4">
        <Multiselect
          v-model="form[field.id]"
          class="multiselect-ark"
          placeholder="Select or enter text"
          track-by="name"
          label="name"
          value-prop="id"
          open-direction="top"
          :options="auditorOptions"
          :close-on-select="true"
          :object="true"
          :searchable="true"
          :create-tag="true"
          :disabled="loading"
        />
      </div>
    </div>
  </section>
  <div slot="footer" class="pr-3">
    <div
      v-if="errorMessageFromBackend"
      class="row d-flex justify-content-center mt-2"
    >
      <div class="col-12 text-right">
        <error-message
          :error-condition="errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
      </div>
    </div>
    <div class="row d-flex justify-content-center mt-2">
      <div class="col-12 text-right">
        <button
          v-if="mode !== 'edit'"
          class="btn btn-link secondary7_ark ark-bold normal-text"
          :disabled="loading"
          @click="back"
        >
          Previous
        </button>
        <div
          v-if="loading"
          class="spinner-border primary2_ark"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <button
          v-else
          class="btn btn-secondary7-filled-ark normal-text"
          @click="save"
        >
          {{ mode === 'edit' ? 'Update' : 'Create' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../api/config';
import { nextTick } from 'vue';

export default {
  name: 'CreateMetricStep2',
  components: { ErrorMessage, Multiselect },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    metric: {
      type: Object,
      required: true
    },
    selectUnits: {
      type: Boolean,
      required: false,
      default: false
    },
    measurementOptions: {
      type: Array,
      required: true
    },
    entryFrequencyOptions: {
      type: Array,
      required: true
    },
    auditorOptions: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: false,
      default: 'create'
    },
    errorOnSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessageFromBackend: {
      type: String,
      required: false,
      default: null
    }
  },
  emits: ['back', 'save'],
  data() {
    return {
      projectTitle: null,
      categories: null,
      sdgGoals: [],
      form: {
        customMetricName: '',
        description: '',
        usageGuidance: '',
        dataSource: '',
        verificationMeans: '',
        selectedCategories: [],
        selectedSdgGoals: [],
        enumeration: [],
        selectedCurrency: 'Euro',
        measurement_unit: null,
        entry_frequency: null,
        collected_by: null,
        reported_by: null,
        evaluated_by: null,
        formatType: 'simple'
      },
      auditorFields: [
        { id: 'collected_by', verb: 'collecting' },
        { id: 'reported_by', verb: 'reporting' },
        { id: 'evaluated_by', verb: 'evaluating' }
      ]
    };
  },
  watch: {
    metric: {
      handler(newValue) {
        this.form = { ...this.form, ...newValue };
        if (this.form.formatType !== 'unit') {
          this.form.measurement_unit = null;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getCategories();
    this.getSdgGoals();
    this.setupModal();
  },
  methods: {
    getCategories() {
      fallbackApi({
        url: 'iris_categories',
        dataSetter: (data) => this.categories = data.iris_categories
      });
    },
    getSdgGoals() {
      fallbackApi({
        url: 'sdg_goals',
        dataSetter: (data) => this.sdgGoals = data.sdg_goals,
        errorSetter: () => this.sdgGoals = []
      });
    },
    getErrorClass(emptyFieldBool, returnBoolean = false) {
      if (this.errorOnSubmit && emptyFieldBool) {
        return returnBoolean || 'multiselect-ark multiselect-ark-error';
      } else return returnBoolean ? false : 'multiselect-ark';
    },
    back() {
      this.$emit('back');
    },
    save() {
      this.$emit('save', this.form);
    },
    /**
     * Set up on scroll listener for body inside of avl-modal
     * to close all multiselect components on scroll
     * #createMetricModal is defined in parent (CreateMetric.vue)
     * setTimeout is needed in order to access the shadow dom
     */
    setupModal() {
      setTimeout(() => {
        const modalBody =
          document.querySelector('#createMetricModal')?.shadowRoot?.querySelector('.body');
        if (!modalBody) return;

        modalBody.onscroll = () => {
          if (this.$refs.categoriesMultiselect) {
            this.$refs.categoriesMultiselect.close();
            this.$refs.categoriesMultiselect.blur();
          }
          if (this.$refs.currencyMultiselect) {
            this.$refs.currencyMultiselect.close();
            this.$refs.currencyMultiselect.blur();
          }
          if (this.$refs.unitMultiselect) {
            this.$refs.unitMultiselect.close();
            this.$refs.unitMultiselect.blur();
          }
          if (this.$refs.enumMultiselect) {
            this.$refs.enumMultiselect.close();
            this.$refs.enumMultiselect.blur();
          }
          if (this.$refs.frequencyMultiselect) {
            this.$refs.frequencyMultiselect.close();
            this.$refs.frequencyMultiselect.blur();
          }
        };
      }, 0);
    },
    async openMultiselect(id) {
      // Wait for dropdown to be displayed
      await nextTick();

      // Get absolute position of dropdown in view
      const dropdown = this.$refs[id].$el.querySelector('.multiselect-dropdown');
      const rect = dropdown.getBoundingClientRect();

      // Change dropdown from absolute position to fixed in the same location
      dropdown.style.position = 'fixed';
      dropdown.style.width = `${rect.width}px`;
      dropdown.style.top = `${rect.top}px`;
      dropdown.style.left = `${rect.left}px`;
      dropdown.style.bottom = 'unset';
      dropdown.style.right = 'unset';
      dropdown.style.transform = 'unset';
    },
    closeMultiselect(id) {
      // Remove all absolute styling added by js
      const dropdown = this.$refs[id].$el.querySelector('.multiselect-dropdown');
      dropdown.style = {};
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.custom-header {
  padding-right: 30px;
}

.sdg-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sdg-input + .sdg-label {
  background-image: var(--background);
  background-size: cover;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.sdg-input:hover + .sdg-label,
.sdg-input:focus-visible + .sdg-label,
.sdg-input:checked + .sdg-label {
  background-image: var(--checked-background);
}

</style>
