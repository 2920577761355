<template>
  <div id="mapViewport" />
</template>

<script>
import { cartesianDistance, snakeToCamelCase } from '../../../utilities/genericMethods';
import {
  clusterConfiguration,
  customPopupHtml,
  uniqueValueInfoCreator,
  pointSymbolConfig
} from '../helpers/consts';
import { fallbackApi } from '../../../api/config';
import { loadModules } from 'esri-loader';
import { mapCardThemes } from '../../../domain/enumerationHelper';
import { symbolToString } from '../../../utilities/enumeration';

/**
 * Key constants
 */
const organizationKey = symbolToString(mapCardThemes.organization);
const projectKey = symbolToString(mapCardThemes.project);
const techOrgKey = symbolToString(mapCardThemes.tech_org);
const keyArray = [organizationKey, projectKey, techOrgKey];

/**
 * Line configuration for use in polyline
 */
const lineTemplate = {
  type: 'simple-line',
  color: [215, 222, 192, 200],
  width: 2
};

/**
 * Symbol configuration for use in labels for points.
 */
const symbolTemplate = {
  type: 'text',
  color: '#172722',
  font: {
    weight: 'normal',
    size: 13
  }
};

export default {
  props: {
    hoveredItem: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  emits: ['clickedPoint', 'mapLoaded', 'areaChanged'],
  data() {
    return {
      highlightHoverID: undefined,
      highlightClickID: undefined,
      highlightHoverTarget: undefined,
      highlightClickTarget: undefined,
      mapLoadedSteps: 0,
      goToFinishedFlag: true
    };
  },
  watch: {
    hoveredItem(/* entity */) {
      // TODO: resolve this along with the graphs
      // this.$eventBus.$trigger('highlight-entity', entity);
    },
    mapLoadedSteps() {
      if (this.mapLoadedSteps === 2) {
        this.$emit('mapLoaded');
      }
    }
  },
  mounted() {
    loadModules([
      'esri/views/MapView',
      'esri/WebMap',
      'esri/layers/FeatureLayer',
      'esri/layers/support/FeatureFilter',
      'esri/layers/support/LabelClass',
      'esri/Graphic',
      'esri/geometry/Multipoint',
      'esri/geometry/geometryEngine',
      'esri/geometry/support/webMercatorUtils'
    ])
      .then(([
        MapView,
        WebMap,
        FeatureLayer,
        FeatureFilter,
        LabelClass,
        Graphic,
        Multipoint,
        geometryEngine,
        webMercatorUtils
      ]) => {
        let minZoom = 3;
        let maxZoom = 23;
        const windowWidth = window.innerWidth;
        if (windowWidth > 2049) minZoom = 4;
        /**
         * Webmap can be found in our account:
         * https://the-ark.maps.arcgis.com/home/gallery.html?view=grid&sortOrder=desc&sortField=relevance&focus=maps-webmaps
         * and can be edited by selecting 'Open in Map Viewer Classic'
         */
        const arkMap = new WebMap({
          portalItem: {
            id: '2db26f96006441aaad38e93a318e0c38'
          }
        });
        const view = new MapView({
          map: arkMap,
          center: [10, 40], // Longitude, latitude
          zoom: minZoom,
          highlightOptions: {
            color: '#478964',
            haloColor: '#478964',
            fillOpacity: 0.4
          },
          container: 'mapViewport',
          popup: {
            viewModel: {
              includeDefaultActions: false
            },
            spinnerEnabled: true,
            collapseEnabled: false,
            dockEnabled: true,
            dockOptions: {
              // Disables the dock button from the popup
              buttonEnabled: false,
              // Ignore the default sizes that trigger responsive docking
              breakpoint: true
            },
            visibleElements: {
              featureNavigation: false
            }
          }
        });

        view.constraints = { minZoom };

        /**
         * Move and add widgets on the map viewport
         */
        view.ui.move(['zoom'], 'bottom-right');

        /**
         * Create 'layers' object storing the 'layer' and 'view' information.
         */
        const arkEntitiesLayer = {
          layer: new FeatureLayer(
            this.getLayerTemplate('arkEntitiesLayer', [215, 222, 192, 200])
          ),
          view: null
        };

        let mapFilters = {
          [organizationKey]: [],
          [projectKey]: [],
          [techOrgKey]: []
        };

        let associatedEntitiesFilters = {
          [organizationKey]: [],
          [projectKey]: [],
          [techOrgKey]: []
        };

        let hoverGraphics = [];
        let clickGraphics = [];

        /**
         * Add layer on the map and set 'layerViews'.
         * With this we can filter features afterwards.
         */
        arkMap.add(arkEntitiesLayer.layer);

        view.whenLayerView(arkEntitiesLayer.layer).then((layerView) => {
          arkEntitiesLayer.view = layerView;
          this.mapLoadedSteps++;

          arkEntitiesLayer.view.watch('updating', (event) => {
            if (!event) {
              arkEntitiesLayer.view
                ._updateHighlight()
                .catch(() => {
                  // skip ESRI's error
                });
            }
          });
        });


        const resetHighlightsAndPopups = (
          { highlightHover = false, highlightClick = false, popups = false, graphicsHover = false }
        ) => {
          if (highlightHover) {
            if (this.highlightHoverTarget) this.highlightHoverTarget.remove();
            this.highlightHoverID = undefined;
            this.highlightHoverTarget = undefined;
          }
          if (highlightClick) {
            if (this.highlightClickTarget) this.highlightClickTarget.remove();
            this.highlightClickID = undefined;
            this.highlightClickTarget = undefined;
          }
          if (popups) {
            view.popup.visible = false;
          }
          if (graphicsHover) {
            hoverGraphics.forEach((hoverGraphic) => {
              if (!clickGraphics.find((clickGraphic) => clickGraphic.uid === hoverGraphic.uid)) {
                view.graphics.remove(hoverGraphic);
              }
            });
          }
        };

        /**
         * Update layers properly using the correct arguments.
         * @param entities: Defines which layers will become visible.
         * @param oldFilters: Includes an array of items to display for each layer.
         * Should be passed with the following keys: 'project', 'organization', 'tech_org'.
         * @param newFilters: Same format as oldFilters.
         * This includes temporary items that will be removed in next layer update
         * and need to be connected with a graph.
         * @param connectedPoint: A [lng, lat] array with the coordinates of
         * the point that we want to connect the new visible layer points to
         * (e.g. organization connected with its projects).
         * @param forceUpdate: If set false, the items on the map will not be updated
         */
        function updateLayers(
          {
            entities,
            oldFilters,
            newFilters,
            connectedPoint,
            forceUpdate = true,
            isTechOrgEntity = false,
            source
          }
        ) {
          return new Promise((resolve) => {
            if (source === 'cardClicked') {
              associatedEntitiesFilters = {
                [organizationKey]: newFilters[organizationKey] || [],
                [projectKey]: newFilters[projectKey] || [],
                [techOrgKey]: newFilters[techOrgKey] || []
              };
            } else if (source === 'markerClicked') {
              associatedEntitiesFilters = {
                [organizationKey]: newFilters[organizationKey] || [],
                [projectKey]: newFilters[projectKey] || [],
                [techOrgKey]: []
              };
              view.graphics.removeAll();
            }
            if (!forceUpdate && hoverGraphics.length) {
              resetHighlightsAndPopups({ graphicsHover: true });
            } else if (hoverGraphics.length && !isTechOrgEntity) {
              view.graphics.removeAll();
            }

            if (forceUpdate) {
              resetHighlightsAndPopups({
                highlightHover: true,
                highlightClick: true,
                popups: true
              });
            }

            let where = [];
            let generatedGraphics = [];

            for (const entity of keyArray) {
              if (source !== 'uiInteract') {
                associatedEntitiesFilters[entity].concat(newFilters ? newFilters[entity] || [] : []);
              }
              if (entities[entity]) {
                if (oldFilters && Array.isArray(oldFilters[entity])) {
                  let filteredIds =
                    oldFilters[entity]
                    .concat(
                      newFilters ? newFilters[entity] || [] : []
                    )
                    .concat(
                      associatedEntitiesFilters ? associatedEntitiesFilters[entity] || [] : []
                    )
                    .map((obj) => obj.id);
                  /**
                   * Handle case where oldFilters is empty, so where clause doesn't throw a syntax error
                   * 0 is an impossible id
                   */
                  filteredIds.push(0);
                  where.push(`ArkType = '${entity}' AND ArkId IN (${filteredIds.join(', ')})`);

                  /**
                   * Connected point is passed when displaying associated entities.
                   * A polyline graphic is created that connects the associated entities
                   * with the provided point coords.
                   */
                  if (connectedPoint) {
                    const paths = (newFilters ? newFilters[entity] || [] : []).map((item) => {
                      const pointCoords = [item.location?.lon, item.location?.lat];
                      return [[connectedPoint.lng, connectedPoint.lat], pointCoords];
                    });

                    const polyline = {
                      type: 'polyline',
                      paths,
                      // Default spatial reference (required in geometryEngine)
                      spatialReference: { wkid: 4326 }
                    };
                    const curvedPolyline = geometryEngine.geodesicDensify(polyline, 50000);

                    const polylineGraphic = new Graphic({
                      geometry: curvedPolyline,
                      symbol: lineTemplate
                    });

                    generatedGraphics.push(polylineGraphic);
                    view.graphics.add(polylineGraphic);
                  }
                }
              }
            }

            if (forceUpdate || connectedPoint || (!forceUpdate && hoverGraphics.length)) {
              where = where.join(' OR ');
              arkEntitiesLayer.view.filter = new FeatureFilter({ where });
            }
            resolve(generatedGraphics);
          });
        }

        /**
         * Create points (graphics as ESRI mention) from the given array with the
         * proper schema. The argument is the key that vuex consumes.
         */
        const createGraphicsFrom = (entityString) => {
          const entityKey = `${snakeToCamelCase(entityString)}s`;
          return this.$store.state.map[entityKey].map((entity) => {
            const attributes = {
              arkId: entity.id,
              name: entity.name || entity.title,
              lat: entity.lat,
              lng: entity.lng,
              ArkType: symbolToString(entity.type),
              ArkSubType: entity.organizationType,
              ArkGoalsCount: entity.goals_count,
              ArkProjectsCount: entity.projects_count,
              ArkSpeciesCount: entity.species_count,
              ArkImage: entity.banner || entity.last_updated_image || entity.default_banner
            };

            return new Graphic({
              geometry: {
                type: 'point',
                latitude: attributes.lat,
                longitude: attributes.lng
              },
              attributes
            });
          });
        };

        /**
         * Remove labels from layer.
         */
        const resetLabels = () => {
          arkEntitiesLayer.layer.labelingInfo = [];
        };

        /**
         * Add labels to selected points.
         */
        // eslint-disable-next-line no-unused-vars
        const showLabels = (entities) => {
          const labelExpressionInfo = { expression: '$feature.NAME' };
          let labelWhere = [];
          labelWhere = entities.map((item) => {
            return `ArkType = '${item.type}' AND ArkId = ${item.id}`;
          }).join(' OR ');
          arkEntitiesLayer.layer.labelingInfo = new LabelClass({
            labelExpressionInfo,
            symbol: symbolTemplate,
            where: labelWhere
          });
        };

        /**
         * Call when traveling to point(s).
         * When traveling to single point, show labels for it and render its associated entities
         */
        const getAssociatedCoordinates = async (coordinates, forceUpdate = true, source) => {
          if (coordinates.length !== 1) return coordinates;

          const fetchedEntitiesInfo =
            await fetchAndRenderAssociatedEntities(coordinates[0], forceUpdate, source);
          const renderEntitiesInfo = fetchedEntitiesInfo.map((entity) => {
            return {
              lat: entity.location?.lat,
              lng: entity.location?.lon,
              id: entity.id,
              type: entity.type
            };
          });

          return [...coordinates, ...renderEntitiesInfo];
        };

        this.$eventBus.$on('show-entities', async ({ entityLayers, filters }) => {
          resetLabels();
          mapFilters = filters;
          associatedEntitiesFilters = {
            [organizationKey]: [],
            [projectKey]: [],
            [techOrgKey]: []
          };
          await updateLayers({ entities: entityLayers, oldFilters: filters });
        });

        const removeFirstHighlightedPoints = () => {
          let oneValueElements = [];
          if (arkEntitiesLayer.view._highlightIds.size !== 0) {
            /**
             * Take all entries as array of arrays,
             * filter all arrays inside an array that their first item equals to 1
             * reduce the array of arrays into an array of the first item of all other arrays
             */
            oneValueElements = [...arkEntitiesLayer.view._highlightIds.entries()]
              .filter(({ 1: v }) => v === 1)
              .map(([k]) => k);
            arkEntitiesLayer.view._highlightIds.delete(oneValueElements[0]);
          }
          return oneValueElements;
        };

        const highlight = async (graphic, type = 'hover') => {
          if (type === 'hover') {
            const removedPoints = removeFirstHighlightedPoints();
            if (this.highlightHoverTarget) {
              this.highlightHoverTarget.remove();
            }
            this.highlightHoverID = graphic.attributes.ObjectID;
            this.highlightHoverTarget = arkEntitiesLayer.view.highlight(graphic);
            if (removedPoints.length > 0) {
              arkEntitiesLayer.view._highlightIds.set(removedPoints[0], 1);
            }
          } else if (type === 'click') {
            if (this.highlightClickTarget) {
              this.highlightClickTarget.remove();
            }
            this.highlightClickID = graphic.attributes.ObjectID;
            this.highlightClickTarget = arkEntitiesLayer.view.highlight(graphic);
          }
          await getAssociatedCoordinates([{
            id: graphic.attributes.ArkId,
            type: graphic.attributes.ArkType,
            lng: graphic.geometry.longitude,
            lat: graphic.geometry.latitude
          }], false);
        };

        let timer; // The ID of the timer that starts counting whenever the mouse is moved
        const mouseMovementTimeLimit = 100; // When the timer passes, we consider the cursor stable
        let lastHighlightedEntity = null;

        const highlightHelper = (condition, graphic = null, highlightCallback, source) => {
          if (condition) {
            lastHighlightedEntity = graphic?.attributes?.ArkType;
            clearTimeout(timer);
            highlightCallback();
          } else {
            view.popup.visible = false;
            if (
              this.highlightHoverTarget &&
              this.highlightClickID !== this.highlightHoverID
            ) {
              const removedPoints = removeFirstHighlightedPoints();
              arkEntitiesLayer.view._highlightIds.clear();
              this.highlightHoverTarget.remove();
              if (removedPoints.length > 0) {
                arkEntitiesLayer.view._highlightIds.set(removedPoints[0], 1);
              }
              this.highlightHoverTarget = undefined;
              updateLayers({
                entities: { [projectKey]: true, [organizationKey]: true, [techOrgKey]: true },
                oldFilters: mapFilters,
                forceUpdate: false,
                isTechOrgEntity: lastHighlightedEntity !== 'tech_org' ? false : true,
                source
              });
              lastHighlightedEntity = null;
            }
          }
        };

        const queryHighlight = (id, type, highlightCallback) => {
          const query = arkEntitiesLayer.layer.createQuery();
          query.where = `ArkId = ${id} AND ArkType = '${type}'`;
          arkEntitiesLayer.layer.queryFeatures(query).then((response) => {
            highlightHelper(
              view.extent.contains(response.features[0].geometry),
              response.features[0],
              () => highlightCallback(response.features[0]),
              'query'
            );
          });
        };

        const clickHighlight = (graphic) => {
          const customWatcher = (callback, initialFlag, timer, samplingRate = 100) => {
            setTimeout(() => {
              if (this.goToFinishedFlag !== initialFlag) callback();
              else if (--timer) customWatcher(callback, initialFlag, timer, samplingRate);
            }, samplingRate);
          };
          if (this.highlightClickTarget) this.highlightClickTarget.remove();
          customWatcher(
            () => highlight(graphic, 'click'),
            this.goToFinishedFlag,
            100
          );
        };

        this.$eventBus.$on('highlight-entity', ({ id, type }) => {
          view.popup.visible = false;
          queryHighlight(id, type, highlight);
        });

        this.$eventBus.$on('toggle-clustering', (clusteringEnabled) => {
          arkEntitiesLayer.layer.featureReduction = clusteringEnabled ?
            clusterConfiguration(
              organizationKey,
              projectKey,
              techOrgKey,
              [215, 222, 192, 200]
            ) : null;
        });

        const showPopup = (point) => {
          view.popup.visible = true;
          view.popup.open({
            fetchFeatures: true,
            location: point
          });
        };

        view.on('pointer-move', (event) => {
          view.hitTest(event, { include: arkEntitiesLayer.layer }).then((response) => {
            const { results } = response;

            clearTimeout(timer);
            timer = setTimeout(() => {
              highlightHelper(
                results?.length,
                results[0]?.graphic,
                () => {
                  const { graphic } = results[0];
                  const { ObjectID, clusterId } = graphic.attributes;

                  if (this.highlightHoverID !== (ObjectID || clusterId) || !view.popup.visible) {
                    showPopup(view.toMap({ x: event.x, y: event.y }));
                    this.highlightHoverID = ObjectID || clusterId;
                  }
                  if (!graphic?.attributes?.clusterId) {
                    highlight(graphic);
                  }
                },
                'uiInteract'
              );
            }, mouseMovementTimeLimit);
          });
        });

        const zoomOptions = {
          duration: 3000,
          easing: 'ease-in-out'
        };

        /**
         * Zooms and centers to the coordinates given.
         * @param coordinates: A list of lng and lat key-value pairs
         * @param zoom: (optional): This overwrites the dynamic zoom calculation if we want
         * it to be static, if given
         * @param initialZoomBreakpoint: (optional): This value dictates how much we should
         * be zooming based on the distance of the points, higher value means more zoom
         * (is ignored if zoom's value is given)
         * @param distanceCalculatorMethod: (optional): The method that dictates the distance
         * between points (Cartesian distance is used by default)
         * Sources:
         * https://developers.arcgis.com/javascript/latest/sample-code/scene-goto/
         * https://developers.arcgis.com/javascript/latest/api-reference/esri-views-MapView.html#goTo
         * https://developers.arcgis.com/documentation/mapping-apis-and-services/reference/zoom-levels-and-scale/
         */
        // eslint-disable-next-line no-unused-vars
        const zoomTo = (
          coordinates,
          zoom,
          initialZoomBreakpoint = 640 / Math.pow(2, minZoom), // Higher value = More zoom
          distanceCalculatorMethod = cartesianDistance
        ) => {
          const convertDistanceToZoom = (distance) => {
            const zoomBreakpoints = []; // zoom: [minZoom, 22]
            for (let i = 0; i < 23 - minZoom; i++) {
              zoomBreakpoints.push(initialZoomBreakpoint / Math.pow(2, i));
            }
            for (let i = 0; i < zoomBreakpoints.length; i++) {
              if (distance > zoomBreakpoints[i]) return i + minZoom; // zoom: i + minZoom
            }
            return 23; // zoom: 23
          };

          const minPoint = {
            lat: Math.min(...coordinates.map((item) => item.lat)),
            lng: Math.min(...coordinates.map((item) => item.lng || item.lon))
          };
          const maxPoint = {
            lat: Math.max(...coordinates.map((item) => item.lat)),
            lng: Math.max(...coordinates.map((item) => item.lng || item.lon))
          };
          const centerPoint = {
            lat: (minPoint.lat + maxPoint.lat) / 2,
            lng: (minPoint.lng + maxPoint.lng) / 2
          };

          view.goTo(
            {
              center: [centerPoint.lng, centerPoint.lat],
              zoom: zoom || convertDistanceToZoom(distanceCalculatorMethod(minPoint, maxPoint))
            },
            zoomOptions
          ).then(() => this.goToFinishedFlag = !this.goToFinishedFlag);
        };

        /**
         * Zooms and centers to the coordinates given, using the ArcGIS API to create a graphic to travel to.
         * @param coordinates: A list of lng and lat key-value pairs
         * @param zoom: (optional): This forces the zoom level to be its value, should the item
         * list we'll be traveling to contain only a single one, else zoom is calculated dynamically
         * Sources:
         * https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-Geometry.html
         * https://developers.arcgis.com/javascript/latest/api-reference/esri-Graphic.html
         */
        // eslint-disable-next-line no-unused-vars
        const zoomToGraphic = (coordinates, zoom) => {
          view.goTo(
            {
              geometry: new Graphic({
                geometry: {
                  type: 'polyline',
                  paths: coordinates.map((point) => [point.lng || point.lon, point.lat])
                }
              }).geometry,
              ...((coordinates.length === 1 && zoom) && { zoom })
            },
            zoomOptions
          ).then(() => this.goToFinishedFlag = !this.goToFinishedFlag);
        };

        /**
         * Zooms and centers to the coordinates given,
         * using the ArcGIS API to create a multipoint to travel to.
         * @param coordinates: A list of lng and lat key-value pairs
         * @param zoom: (optional): This forces the zoom level to be its value, should the item
         * list we'll be traveling to contain only a single one, else zoom is calculated dynamically
         * Source:
         * https://developers.arcgis.com/javascript/latest/api-reference/esri-geometry-Multipoint.html
         */
        const zoomToMultipoint = (coordinates, zoom) => {
          view.goTo(
            {
              geometry: new Multipoint({
                points: coordinates.map((point) => [(point.lng || point.lon), point.lat])
              }),
              ...((coordinates.length === 1 && zoom) && { zoom })
            },
            zoomOptions
          ).then(() => this.goToFinishedFlag = !this.goToFinishedFlag);
        };

        /**
         * When 'travel-to' event triggers, the map:
         * * Fetches and renders associated entities (including network graph):
         *    * organizations -> all associated projects
         *    * projects -> associated organization or tech-organization
         * * Displays labels on given and associated entities
         * * Zooms and centers to the coordinates of given and associated entities
         * @param coordinates: A list of lng and lat key-value pairs
         * @param zoom: (optional): This overwrites the dynamic zoom calculation if we want
         * it to be static, if given
         * @param ignoreAssociatedEntities: Whether to exclude associated entities from zoom action
         * @param filters: An object containing an array of items to display for each layer
         * The keys should include the names of layers that will display data
         */
        this.$eventBus.$on('travel-to', async ({
           coordinates,
           zoom,
           ignoreAssociatedEntities = false,
           filters = {},
           source = 'uiInteract'
         }
        ) => {
          if (Object.keys(filters).length) mapFilters = filters;

          const { id, type } = coordinates[0];
          if (id && type) queryHighlight(id, type, clickHighlight);

          const allEntitiesCoordinates = await getAssociatedCoordinates(coordinates, true, source);
          const entityCoordinates = ignoreAssociatedEntities
            ? [allEntitiesCoordinates[0]]
            : allEntitiesCoordinates;

          // Move map slightly to the left so marker is visible next to the two sidebars
          // if screen is smaller than sidebars * 2.
          if (ignoreAssociatedEntities &&
            source === 'cardClicked' &&
            window.innerWidth >= 850 && window.innerWidth < 1660) {
            entityCoordinates[0].lng -= 70;
          }

          // Move map slightly to the left so marker is visible next to the sidebar
          // if screen is smaller than sidebar * 2.
          if (ignoreAssociatedEntities && window.innerWidth < 720) {
            entityCoordinates[0].lng -= 30;
          }

          zoomToMultipoint(entityCoordinates, zoom);
        });

        /**
         * Clear all previous filtering actions and labels and enable visibility of all the layers.
         */
        this.$eventBus.$on('reset-all-rendered-entities', async () => {
          resetLabels();
          mapFilters = {
            [organizationKey]: this.$store.state.map[this.toKey(mapCardThemes.organization)],
            [projectKey]: this.$store.state.map[this.toKey(mapCardThemes.project)],
            [techOrgKey]: this.$store.state.map[this.toKey(mapCardThemes.tech_org)]
          };
          await updateLayers({
            entities: {
              [organizationKey]: true,
              [projectKey]: true,
              [techOrgKey]: true
            }
          });
        });


        /**
         * Uses Vuex store to fetch and store all the available entities.
         * Then, adds everything to the corresponding layer
         */
        const fetchAndRenderAllEntities = () => {
          this.$store
            .dispatch('fetchAndSaveAllEntities')
            .then(() => {
              for (const entity of keyArray) {
                arkEntitiesLayer.layer.applyEdits({ addFeatures: createGraphicsFrom(entity) });
              }

              this.mapLoadedSteps++;
            });
            mapFilters = {
              [organizationKey]: this.$store.state.map[this.toKey(mapCardThemes.organization)],
              [projectKey]: this.$store.state.map[this.toKey(mapCardThemes.project)],
              [techOrgKey]: this.$store.state.map[this.toKey(mapCardThemes.tech_org)]
            };
        };

        /**
         * Fetches and updates the layers with the proper associated entities
         * @param entityInfo: Information about the entity that is clicked
         * @param forceUpdate: If set false, the items on the map will not be updated
         */
        const fetchAndRenderAssociatedEntities = async (entityInfo, forceUpdate, source) => {
          const entities = { [projectKey]: true, [organizationKey]: true, [techOrgKey]: true };
          let coordinateData = [];

          if (entityInfo.type === organizationKey) {
            await fallbackApi({
              url: `${entityInfo.type}s/${entityInfo.id}/projects`,
              dataSetter: (data) => {
                const existingProjects = this.$store.state.map[this.toKey(mapCardThemes.project)];
                data.projects = data.projects.filter((project) => {
                  return existingProjects.some(
                    (existingProject) => existingProject.id === project.id
                  );
                });

                updateLayers({
                  entities,
                  oldFilters: mapFilters,
                  newFilters: {[projectKey]: data.projects, [organizationKey]: [entityInfo]},
                  connectedPoint: entityInfo,
                  forceUpdate,
                  source
                }).then((response) => {
                  if (forceUpdate) clickGraphics = response;
                  else hoverGraphics = response;
                });
              }
            })
              .then((response) => {
                coordinateData = response.data.projects.map((project) => {
                  project.type = projectKey;
                  return project;
                });
              });
          } else if (entityInfo.type === projectKey) {
            // Fetch current project from store.
            const currentProject = this.$store.state.map['projects'].find((entity) => {
              return entity.id === entityInfo.id;
            });

            const currentOrganization = currentProject.organization;
            if (currentOrganization) {
              currentOrganization.type = organizationKey;
              coordinateData = [currentOrganization];
            }

            updateLayers({
              entities,
              oldFilters: mapFilters,
              newFilters: {
                [organizationKey]: currentOrganization ? [currentOrganization] : [],
                [projectKey]: [currentProject]
              },
              connectedPoint: entityInfo,
              forceUpdate,
              source
            }).then((response) => {
              if (forceUpdate) clickGraphics = response;
              else hoverGraphics = response;
            });
          } else {
            updateLayers({
              entities,
              oldFilters: mapFilters,
              newFilters: {},
              forceUpdate,
              source
            }).then((response) => {
              if (forceUpdate) clickGraphics = response;
              else hoverGraphics = response;
            });
          }

          return coordinateData;
        };

        /**
         * If coordinates are passed through url params, zoom to that point
         */
        const urlParams = new URLSearchParams(window.location.search);
        const lat = Number(urlParams.get('lat'));
        const lon = Number(urlParams.get('lon'));
        if (lat && lon) zoomToMultipoint([{ lat, lon }], 10);

        /**
         * When everything above finishes,
         * initialize map information with all the information fetched
         */
        fetchAndRenderAllEntities();

        view.on('click', (event) => {
          view.hitTest(event, { include: [arkEntitiesLayer.layer] })
            .then((response) => {
              if (
                response.results.length
                && response.results[0].graphic.attributes.clusterId
              ) {
                this.highlightHoverID = undefined;
                let clusterLong = response.results[0].mapPoint.longitude;
                let clusterLat = response.results[0].mapPoint.latitude;
                let currZoom = view.zoom;
                if (clusterLong && clusterLat && currZoom < maxZoom) view.goTo(
                  {
                    center: [clusterLong, clusterLat],
                    zoom: view.zoom + 3

                  },
                  zoomOptions
                );
              } else if (
                response.results.length
                && !response.results[0].graphic.attributes.clusterId
              ) {
                clickHighlight(response.results[0].graphic);
                const { ArkId, ArkType } = response.results[0].graphic.attributes;
                this.$emit('clickedPoint', ArkId, ArkType);
              }
            });
        });

        let extentTimer; // The ID of the timer that starts counting whenever the view's extent is changed
        const extentTimeLimit = 500; // When the timer passes, we consider the extent

        view.watch('extent', (camera) => {
          clearTimeout(extentTimer);
          extentTimer = setTimeout(() => {
            if (camera.extent.xmin == undefined) return;

            const geography = webMercatorUtils.webMercatorToGeographic(camera.extent, false);
            const area = {
              xmin: geography.xmin,
              ymin: geography.ymin,
              xmax: geography.xmax,
              ymax: geography.ymax
            };

            this.$emit('areaChanged', area);
          }, extentTimeLimit);
        });

        const setSubtitle = (type, organizationType) => {
          if (type === symbolToString(mapCardThemes.tech_org) || organizationType === 'TechOrg') {
            return 'Technology';
          } else if (type === symbolToString(mapCardThemes.project)) {
            return 'Project';
          } else {
            return 'Project Developer';
          }
        };

        const getURL = (type, id) => {
          if (type === "tech_org" || type === "organization") {
            return `organizations/${id}`;
          } else {
            return `projects/${id}`;
          }
        };

        const customPopup = (feature) => {
          const popupDiv = document.createElement('div');
          popupDiv.innerHTML = customPopupHtml(feature);

          return popupDiv;
        };

        arkEntitiesLayer.layer.popupTemplate = {
          title: '{Name}',
          outFields: ['*'],
          content: customPopup
        };
      })
      .catch((err) => {
        // handle any errors
        Sentry && Sentry.captureException(err);
      });
  },
  methods: {
    toKey(type) {
      return `${snakeToCamelCase(symbolToString(type))}s`;
    },
    getLayerTemplate(title, color) {
      return {
        title,
        featureReduction: null,
        source: [], // adding an empty feature collection
        objectIdField: 'ObjectID',
        geometryType: 'point',
        outFields: ['*'],
        fields: [
          {
            name: 'ObjectID',
            alias: 'id',
            type: 'oid'
          },
          {
            name: 'Name',
            alias: 'name',
            type: 'string'
          },
          {
            name: 'ArkId',
            alias: 'arkId',
            type: 'integer'
          },
          {
            name: 'ArkType',
            alias: 'arkType',
            type: 'string'
          },
          {
            name: 'ArkSubType',
            alias: 'arkSubType',
            type: 'string'
          },
          {
            name: 'ArkGoalsCount',
            alias: 'arkGoalsCount',
            type: 'integer'
          },
          {
            name: 'ArkProjectsCount',
            alias: 'arkProjectsCount',
            type: 'integer'
          },
          {
            name: 'ArkSpeciesCount',
            alias: 'arkSpeciesCount',
            type: 'integer'
          },
          {
            name: 'ArkImage',
            alias: 'arkImage',
            type: 'string'
          }
        ],
        renderer: {
          type: 'unique-value',
          field: 'ArkType',
          uniqueValueInfos: [
            uniqueValueInfoCreator(
              'organization',
              'https://cdn.theark.co/img/map_icon_building_dark.png',
              color
            ),
            {
              value: 'project',
              symbol: pointSymbolConfig
            },
            uniqueValueInfoCreator(
              'tech_org',
              'https://cdn.theark.co/img/map_icon_chart_dark.png',
              color
            )
          ]
        },
        spatialReference: {
          wkid: 3857
        }
      };
    }
  }
};
</script>

<style scoped>
@import "https://js.arcgis.com/4.23/esri/themes/light/main.css";
:deep(.esri-attribution) {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}
:deep(.esri-attribution__link) {
  color: white !important;
}
:deep(.esri-popup__header) {
  display: none !important;
}
:deep(.esri-popup__main-container) {
  width: 350px;
  border-radius: 10px;
}
:deep(.esri-popup__content) {
  margin: 0px !important;
  border-radius: 10px;
}
:deep(.esri-popup__pointer) {
  display: none !important;
}
:deep(.esri-popup__footer) {
  order: 1;
}

#mapViewport {
  padding: 0;
  margin: 0;
  height: calc(100vh - 42px);
}

@media (max-width: 991px) {
  #mapViewport {
    height: calc(100vh - 49px);
  }
}

.badgeGoals {
  color: white;
  background-color: #24422b;
  border-radius: 2px;
  width: auto;
  padding: 2px 4px;
}

:deep(.popup-title) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
