<template>
  <article>
    <ul class="row governanceList listStyleNone">
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">Founded</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.year_founded || ' - ' }}
          </p>
        </div>
      </li>
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">Country of incorporation</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.country_of_incorporation|| ' - ' }}
          </p>
        </div>
      </li>
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">Ownership type</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.ownership_type || ' - ' }}
          </p>
        </div>
      </li>
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">Company registration authority</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.registration_authority || ' - ' }}
          </p>
        </div>
      </li>
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">Company ID</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.company_id || ' - ' }}
          </p>
        </div>
      </li>
      <li class="col-12">
        <div class="row py-1">
          <h3 class="col-12 col-sm-12 col-md-5 DesktopTextXSmall grayscaleOffBlack my-0">LEI</h3>
          <p class="col-12 col-sm-12 col-md-7 DesktopTextSmall grayscaleOffBlack mb-0">
            {{ info?.lei || ' - ' }}
          </p>
        </div>
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.governanceList {
  padding-left: 0px;
}
</style>
