export const enumerate = (toBeEnumerated, options = {}) => {
  if (typeof(toBeEnumerated) !== 'object') return {};
  const optionsWithDefault =
    { symbolizeKeys: false, symbolizeValues: true, ...options };
  const enumerable = {};

  const createItem = (condition, item) => condition ? Symbol(item) : item;
  const setItem = (key, value) => {
    enumerable[createItem(optionsWithDefault.symbolizeKeys, key)] =
      createItem(optionsWithDefault.symbolizeValues, value);
  };

  if (Array.isArray(toBeEnumerated)) {
    toBeEnumerated.forEach((item) => setItem(item, item));
  } else {
    Object.entries(toBeEnumerated).forEach((entry) => setItem(...entry));
  }

  return Object.freeze(enumerable);
};

export const symbolToString = (symbol) => symbol.toString().slice(7, -1);

export const arraysFromSymbolsToStrings = (symbolsObject) => {
  return Object.values(symbolsObject)
    .reduce((previous, next) => [...previous, symbolToString(next)], []);
};

