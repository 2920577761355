class FollowHelper {
  static followActor(btn, userId = null) {
    const followActorHTML = btn.siblings('.js-follow-url');

    if (followActorHTML) {
      const followActorUrl = followActorHTML.val();
      fetch(followActorUrl,
        { method: 'POST',
          headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content }
        }).then((response) => {
          if (response.ok) {
            btn.addClass('d-none');
            btn.siblings().removeClass('d-none');
          } else {
            console.error('Something happened.', response);
          }
        });
    }
  }

  static unfollowActor(btn, userId = null) {
    const unfollowActorHTML = btn.siblings('.js-unfollow-url');

    if (unfollowActorHTML) {
      const unfollowActorUrl = unfollowActorHTML.val();
      fetch(unfollowActorUrl,
        { method: 'POST',
          headers: { 'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content }
        }).then((response) => {
          if (response.ok) {
            btn.addClass('d-none');
            btn.siblings().removeClass('d-none');
          } else {
            console.error('Something happened.', response);
          }
        });
    }
  }

  static setupFollowButtons() {
    // Search for follow buttons
    const followBtns = $('.js-follow-btn');
    if (followBtns) {
      followBtns.each(function( index ) {
        $(this).off('click');
        $(this).on('click', function(){
          FollowHelper.followActor($(this));
        });
      });
    }

    const unfollowBtns = $('.js-unfollow-btn');
    if (unfollowBtns) {
      unfollowBtns.each(function( index ) {
        $(this).off('click');
        $(this).on('click', function(){
          FollowHelper.unfollowActor($(this));
        })
      });
    }
  }
}

export { FollowHelper };
