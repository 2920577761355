<template>
  <section>
    <section class="arkCard">
      <header class="row">
        <section class="col-9">
          <h2 class="DesktopTextMedium secondary7_ark">Governance and financial info</h2>
        </section>
        <section v-if="isAdmin" class="col-3 d-flex justify-content-end">
          <a
            class="p-0 m-0 pointer"
            aria-label="Edit Governance and financial section"
            :href="`/${modelPluralize}/${modelId}/edit?tab=governance`"
          >
            <i
              class="fa fa-pencil edit-icon-pencil grayscaleBody"
              aria-hidden="true"
            />
          </a>
        </section>
      </header>
      <governance-and-financial-list
        :info="organization.governance_info"
      />
      <div v-if="organization.subsidiaries && organization.subsidiaries.length > 0">
        <div class="row">
          <h3 class="col-12 DesktopLinkSmall primaryDefault">Related entities</h3>
        </div>
        <ul class="listStyleNone pl-0">
          <li v-for="entity in organization.subsidiaries" :key="entity.id" class="pt-1">
            <section
              class="d-flex align-items-center pointer"
              @click="entity.showMore = !entity.showMore"
              >
              <button
                class="
                  DesktopLinkSmall d-flex justify-content-start
                  align-self-start p-1 mr-2 chevronDownRightToggle
                "
                :aria-label="'Show info for the related entity: ' + entity.name"
                :aria-expanded="entity.showMore"
                :aria-controls="`governance-financial-list-${entity.id}`"
              >
                <i
                  class="grayscaleOffBlack"
                  :class="entity.showMore ? 'far fa-chevron-down' : 'far fa-chevron-right pl-1'"
                  aria-hidden="true"
                />
              </button>
              <article :id="`governance-financial-list-${entity.id}`">
                <p class="mb-1 grayscaleOffBlack DesktopLinkSmall">
                  {{ entity.name }}
                </p>
                <governance-and-financial-list
                  v-show="entity.showMore"
                  :info="entity"
                />
              </article>
            </section>
          </li>
        </ul>
      </div>
    </section>
  </section>
</template>

<script>
import GovernanceAndFinancialList from './GovernanceAndFinancialList.vue';

export default {
  components: { GovernanceAndFinancialList },
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    modelId: {
      type: String,
      required: true
    },
    modelPluralize: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.organization.subsidiaries.forEach((entity) => {
      entity.showMore = false;
    });
  }
};
</script>
