<template>
  <div class="forgot-password">
    <main class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-xl-4">
        <form class="arkCard text-center textBodyColor py-5 mb-2">
          <div class="text-left">
            <img :src="logoImage" alt="ARK logo" class="typeImg mb-2" aria-hidden="true">
            <h2 class="DesktopTextlarge mb-3">Can't log in?</h2>
            <span class="DesktopTextSmall">We'll send a recovery link to</span>
            <label
              for="emailInput"
              class="DesktopTextSmall grayscaleOffBlack sr-only"
            >
              Email *
            </label>
            <input
              id="emailInput"
              v-model="email"
              type="text"
              placeholder="Email"
              class="form-control ark DesktopTextSmall py-4 px-3 mt-2"
              required
              aria-errormessage="emailError"
              :aria-invalid="!!errors.email"
              :class="{ notValid: errors.email }"
              @blur="validate"
            >
            <error-message
              id="emailError"
              :error-condition="!!errors.email"
              :error-message="errors.email"
            />
          </div>
          <div class="row mt-4">
            <div class="col-12 col-sm-6 d-flex align-items-center">
              <a href="/users/sign_in" class="DesktopTextSmall warningDarkmode">
                Return to sign in
               </a>
            </div>
            <div class="col-12 col-sm-6 text-sm-right">
              <button
                type="submit"
                class="
                  btn
                  DesktopLinkXSmall
                  primaryButton
                  px-4
                "
                :disabled="!email || loading"
                @click.prevent="submit"
              >
                Send recovery link
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <error-message
                  :error-condition="errorMessage"
                  :error-message="errorMessage"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import { validateForm } from '../../../../utilities/validation.js';

const ValidationSchema = yup.object({
  email: yup.string().required().email()
});

export default {
  components: { ErrorMessage },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    logoImage: {
      type: String,
      required: true
    }
  },
  emits: ['submit'],
  data() {
    return {
      showErrors: true,
      email: '',
      errors: {
        email: ''
      }
    };
  },
  methods: {
    validate() {
      return validateForm({ email: this.email }, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.$emit('submit', this.email);
      }
    }
  }
};

</script>

<style scoped>
.forgot-password {
  padding-top: 75px;
}

.arkCard {
  padding-left: 10%;
  padding-right: 10%;
}
</style>

