// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Initialize MapBox with token
mapboxgl.accessToken = process.env.MAPBOX_TOKEN;

require('trix');

// require("@rails/actiontext") // TODO: Replace the code below with this import statement
import { AttachmentUpload } from '@rails/actiontext/app/javascript/actiontext/attachment_upload';

addEventListener('trix-attachment-add', (event) => {
  const { attachment, target } = event;

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target);
    upload.start();
  }
});

require('@rails/ujs').start();
import { defineCustomElements } from '@avlos/avl-components/dist/loader';
defineCustomElements(window, {
  ce: (eventName, opts) => new CustomEvent(eventName.toLowerCase(), opts)
});

require('./application_utils.js');

// Chart config
require ('./chart_config.js');
// Onboarding form
require('./onboarding/wizard.js');
require('./onboarding/onboarding.js');
// Project screens
require('./projects/show.js');
// Species page
require('./species.js');
// Search page
require('./search.js');
// User page
require ('./users.js');
// Autocomplete class
require ('./autocomplete.js');
// Invitations modal
require('./invitations.js');
// Top news page
require('./social/top_news.js');
// Media
require('./media/paper.js');
require('./media/film.js');
require('./media/book.js');
require('./media/podcast.js');
require('./media/image.js');
// Misc text style utils
require('./text_utils.js');
require('./links.js');
// User support
require('./support.js');
// Analytics
require('./analytics.js');
// Tech Org modal
require('./tech_org.js');

import './vue';

// User follow helpers
const FollowHelper = require('./social/follow_helper.js').FollowHelper;
// Like helpers
const LikeHelper = require('./social/like_helper.js').LikeHelper;
// Comment helpers
const CommentHelper = require('./social/comment_helper.js').CommentHelper;
// Top news post helpers
const TopNewsHelper = require('./social/top_news.js').TopNewsHelper;
// Link helper
const LinkHelper = require('./links.js').LinkHelper;
// Iframe post helpers
const IframeHelper = require('./social/iframe_helper.js').IframeHelper;
// Notification helpers
const NotificationHelper = require('./notification_helper.js').NotificationHelper;

// Make helpers available to paginate.js.erb files
window.FollowHelper = FollowHelper;
window.LikeHelper = LikeHelper;
window.CommentHelper = CommentHelper;
window.TopNewsHelper = TopNewsHelper;
window.LinkHelper = LinkHelper;
window.IframeHelper = IframeHelper;
window.NotificationHelper = NotificationHelper;

import * as ActiveStorage from '@rails/activestorage';
ActiveStorage.start();

$(document).ready(function() {
  // Initialise the wizard
  wizard.initMaterialWizard();
  setTimeout(function() {
    $('.card.card-wizard').addClass('active');
  }, 600);
  // Initialise Datetimepicker
  md.initFormExtendedDatetimepickers();
  // Activate the tooltips
  $('[data-toggle="tooltip"]').tooltip();

  FollowHelper.setupFollowButtons();
  LikeHelper.setupLikeButtons();
  CommentHelper.setupCommentButtons();
  IframeHelper.setupIframes();
  NotificationHelper.setupNotificationButtons();
  window.mouseTip = new MouseTip({
    id: 'global-mousetip',
    direction: ['bottom', 'center'],
    styles: { backgroundColor: '#172722e6' }
  });
  window.mouseTip.start();
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// FileInput Fix
$('.form-file-simple .inputFileVisible').click(function() {
  $(this).siblings('.inputFileHidden').trigger('click');
});

$('.form-file-multiple .inputFileVisible, .form-file-multiple .input-group-btn').click(function() {
  $(this).parent().parent().find('.inputFileHidden').trigger('click');
  $(this).parent().parent().addClass('is-focused');
});

$('.form-file-multiple .inputFileHidden').change(function() {
  var names = '';
  for (var i = 0; i < $(this).get(0).files.length; ++i) {
    if (i < $(this).get(0).files.length - 1) {
      names += $(this).get(0).files.item(i).name + ',';
    } else {
      names += $(this).get(0).files.item(i).name;
    }
  }
  $(this).siblings('.input-group').find('.inputFileVisible').val(names);
});
