require("./user.js")
require("./organization.js")
require("./recommendation.js")
require("./project.js")
require("./attachments.js")

wizard = {
  initMaterialWizard: function() {
    // Code for the Validator
    var $validator = $('#about__form').validate({
      highlight: function(element) {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: function(element) {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
    });

    var $org__validator = $('#org__form').validate({
      highlight: function(element) {
        $(element).closest('.form-group').removeClass('has-success').addClass('has-danger');
      },
      success: function(element) {
        $(element).closest('.form-group').removeClass('has-danger').addClass('has-success');
      },
    });

    $.validator.addMethod("multipleSelectOptions", function(value, element, arg){
    return value.length > 0;
    }, "Have to select an option");

    $.validator.addMethod("multipleFileInput", function(value, element, arg){
    return value.length > 0;
    }, "Have to select an option");

    // section is defined on each erb file.
    var section = $('#section_type').attr('section') || null;

    // Disable save button when a field is invalid
    $('.form-control, button, .collection-check-box, .btn-next, .nav-item').on('blur keypress click', function() {
      if (section && section === 'user') {
        var $valid = $('.js-about-form').valid();
        $valid ? $('#user__org__submit').removeClass('opacity_05') : $('#user__org__submit').addClass('opacity_05');
        return true;
      } else if (section === 'org') {
        var $org__valid = $('.js-organization-form').valid();
        $org__valid ? $('#user__org__submit').removeClass('opacity_05') : $('#user__org__submit').addClass('opacity_05');
        return true;
      }
      $('#user__org__submit').removeClass('opacity_05')
    });
    // Set form submit listeners
    if (section && section === 'user') {
      const about = new OnBoardingUserHelper();
      about.setupSubmit();
    } else if (section &&  section === 'org') {
      const organization = new OnBoardingOrganizationHelper();
      organization.setupSubmit();
    } else if (section && section  === 'media') {
      const recommendation = new OnBoardingRecommendationHelper();
      recommendation.setupSubmit();
    } else if (section && section === 'project') {
      const project = new OnboardingProjectHelper();
      project.setupSubmit();
    }

    const project_attachments = new ProjectAttachmentsHelper();
    project_attachments.setupSubmit();
  },
}
