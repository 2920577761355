<template>
  <span v-if="errorCondition" class="requiredError">{{ errorMessage }}</span>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: 'This field is required'
    },
    errorCondition: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};
</script>

<style scoped>
.requiredError {
  color: rgb(255, 0, 0);
  font-size: 9pt;
  padding-left: 5px;
  margin-top: 12px;
}
</style>