<template>
  <section class="py-4">
    <h4 class="grayscaleOffBlack DesktopLinkSmall mb-4">Billing</h4>

    <div v-if="currentSubscription" class="row px-0 mb-1">
      <div v-if="!currentSubscription.cancelled_at" class="col-12 col-md-4 col-md-4">
        <div class="MobileTextSmall grayscaleOffBlack">Next invoice issue date</div>
        <div class="MobileDisplaySmallBold">
          {{ nextIssueDate(currentSubscription) }}
        </div>
      </div>

      <div class="col-12 col-md-4 col-md-4">
        <div class="MobileTextSmall grayscaleOffBlack">Team Member Seats</div>
        <div class="MobileDisplaySmallBold">
          {{ activeSeats }}
        </div>
      </div>

      <div v-if="!currentSubscription.cancelled_at" class="col-12 col-md-4 col-md-4">
        <div class="MobileTextSmall grayscaleOffBlack">Invoice total</div>
        <div class="MobileDisplaySmallBold">
          {{ formatPrice(currentSubscription?.type?.price_yearly) }}
        </div>
      </div>
    </div>

    <h4 class="grayscaleOffBlack DesktopLinkSmall mb-3 mt-5">Purchase history</h4>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr class="grayscaleBody">
            <th scope="col">
              <span class="DesktopTextSmall grayscaleLabel">Date</span>
            </th>
            <th scope="col">
              <span class="DesktopTextSmall grayscaleLabel">Description</span>
            </th>
            <th scope="col">
              <span class="DesktopTextSmall grayscaleLabel">Total</span>
            </th>
            <th scope="col">
              <span class="DesktopTextSmall grayscaleLabel">Status</span>
            </th>
          </tr>
        </thead>
        <tbody class="grayscaleOffBlack">
          <tr v-for="(row) in historyRows" :key="row.id">
            <td class="MobileTextSmall grayscaleOffBlack">{{ row.date }}</td>
            <td class="MobileTextSmall grayscaleOffBlack">{{ row.description }}</td>
            <td class="MobileTextSmall grayscaleOffBlack">{{ row.total }}</td>
            <td
              v-if="row.status === STATUS.PENDING"
              :mousetip:message="row.statusMessage"
              mousetip
              class="MobileTextSmall orange500"
            >
              Pending
            </td>
            <td v-else class="MobileTextSmall grayscaleOffBlack">Paid</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: {
    subscriptions: {
      type: Array,
      required: true,
      default: () => []
    },
    currentSubscription:{
      type: Object,
      default: () => undefined
    }
  },
  data() {
    return {
      STATUS: {
        PENDING: 0,
        ACTIVE: 1,
        EXPIRED: 2
      }
    };
  },
  computed: {
    activeSeats() {
      return this.currentSubscription?.status === this.STATUS.PENDING ?
        this.currentSubscription?.type?.default_seats :
        this.currentSubscription?.seats?.filter(({ active }) => active).length;
    },
    // Construct history table rows with subscription and seat requests
    // TODO: This will be removed when we add the invoices/requests table
    historyRows() {
      return this.subscriptions.reduce((rows, subscription) => {
        // Construct table rows for extra seats
        const extraSeats = subscription.seat_additions || [];
        if (extraSeats.length > 0) {
          // Construct table rows from grouped extra seats
          const seatRows = extraSeats.map((seatGroup) => {
            return {
              id: `${subscription.id}-${seatGroup.date}`,
              date: seatGroup.status === this.STATUS.PENDING ?
                'Scheduled Soon' : this.formatDate(seatGroup.date),
              description: `${seatGroup.seats_count} Team Member Seat Addition`,
              total: this.formatPrice(seatGroup.price),
              status: seatGroup.status,
              statusMessage: seatGroup.status === this.STATUS.PENDING &&
                this.statusMessage(subscription, seatGroup.seats_count, seatGroup.price)
            };
          });

          rows = rows.concat(seatRows);
        }

        // Construct table row for subscription
        const subscriptionRow = {
          id: subscription.id,
          date: subscription.status ? this.formatDate(subscription.started_at) : 'Scheduled Soon',
          description: this.subscriptionDescription(subscription),
          total: this.formatPrice(subscription.type.price_yearly),
          status: subscription.status,
          statusMessage: subscription.status === this.STATUS.PENDING &&
            this.statusMessage(subscription)
        };
        rows.push(subscriptionRow);

        return rows;
      }, []);
    },
    cancelled() {
      return true;
    }
  },
  mounted() {
    window.mouseTip.start();
  },
  updated() {
    window.mouseTip.start();
  },
  methods: {
    subscriptionDescription(subscription) {
      return `Annual
        ${subscription?.type?.name}
        Plan with
        ${subscription?.type?.default_seats}
        Team Member Seats`;
    },
    nextIssueDate(subscription) {
      return subscription?.status
      ?
        (
          subscription.expired_at
          ?
          DateTime.fromISO(subscription.expired_at).toFormat('LLL d, yyyy')
          :
          DateTime.fromISO(subscription.started_at).plus({years: 1}).toFormat('LLL d, yyyy')
        )
      :
      'Scheduled Soon';
    },
    formatPrice(string) {
      return `€ ${Math.trunc(parseFloat(string)).toLocaleString()}`;
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('LLL d, yyyy');
    },
    statusMessage(subscription, seats, price) {
      if (!seats) {
        return `Your request to activate the
          ${ subscription?.type?.name }
          Plan is currently being processed.
          Thank you for your request.
          THE ARK accounting team will contact you shortly to facilitate payment for
          ${ subscription?.type?.default_seats }
          team members, amounting to
          ${ this.formatPrice(subscription?.type?.price_yearly) }.
          Once payment is fulfilled, your plan will be activated.`;
      } else {
        return `Your request for more seats in the
          ${ subscription?.type?.name }
          Plan is currently being processed.
          Thank you for your request.
          The ARK accounting team will contact you shortly to facilitate payment for
          ${ seats }
          team members, amounting to ${ this.formatPrice(price) }.
          Once payment is fulfilled, your extra seats will be activated.`;
      }
    }
  }
};
</script>

<style scoped></style>
