<template>
  <div id="createOrEditACustomMetric">
    <avl-modal
      id="createMetricModal"
      :is-open="createMetricModalOpen"
      size="80%"
      header-title=""
      footer
      style="--modal-min-height: 60%"
      @avleventclose="closeCustomMetricModal"
    >
      <create-metric-step-1
        v-if="step === 1"
        :previous-format="metric.selectedFormat"
        @back="closeCustomMetricModal"
        @save="selectFormat"
      />
      <create-metric-step-2
        v-else-if="step === 2"
        :loading="saveLoading"
        :metric="metric"
        :select-units="!!customMetricInfo.goalId"
        :measurement-options="measurementOptions"
        :entry-frequency-options="entryFrequencyOptions"
        :auditor-options="auditorOptions"
        :error-on-submit="errorOnsubmit"
        :error-message-from-backend="errorMessageFromBackend"
        :mode="mode"
        @back="step = 1"
        @save="createOrUpdateMetric"
      />
    </avl-modal>

    <delete-entries-modal
      :modal-open="warningModalOpen"
      @closeModal="warningModalOpen = false"
      @confirm="saveMyCustomMetric"
    />
  </div>
</template>

<script>
import * as yup from 'yup';
import CreateMetricStep1 from './CreateMetricStep1.vue';
import CreateMetricStep2 from './CreateMetricStep2.vue';
import DeleteEntriesModal from '../../../components/shared/DeleteEntriesModal.vue';
import api from '../../../api/config';

const ValidationSchema = yup.object({
  customMetricName: yup.string().required(),
  selectedCategories: yup.array().min(1).required(),
  selectedSdgGoals: yup.array(),
  description: yup.string().required(),
  usageGuidance: yup.string().required(),
  selectedFormat: yup.string().required(),
  enumeration: yup.array().when('selectedFormat', (selectedFormat, schema) => {
    if (selectedFormat === 'Multiple Choice') return schema.min(1).required();
    return schema.min(0);
  }),
  selectedCurrency: yup
    .string()
    .when('selectedFormat', (selectedFormat, schema) => {
      if (selectedFormat === 'Currency') return schema.required();
      return schema.nullable();
    }),
  measurement_unit: yup.object().when('formatType', (formatType, schema) => {
    if (formatType === 'unit') return schema.required();
    return schema.nullable();
  }),
  entry_frequency: yup.object().required()
});

export default {
  name: 'CreateMetric',
  components: { CreateMetricStep1, CreateMetricStep2, DeleteEntriesModal },
  props: {
    createMetricModalOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    mode: {
      type: String,
      required: false,
      default: 'create'
    },
    customMetricInfo: {
      type: Object,
      required: false,
      default: null
    },
    measurementOptions: {
      type: Array,
      required: true
    },
    entryFrequencyOptions: {
      type: Array,
      required: true
    },
    auditorOptions: {
      type: Array,
      required: true
    }
  },
  emits: ['createMetricModalClosedEvent', 'entryDataChanged'],
  data() {
    return {
      saveLoading: false,
      initialMetricName: '',
      metric: {
        customMetricName: '',
        description: '',
        usageGuidance: '',
        selectedCategories: [],
        selectedSdgGoals: [],
        selectedFormat: null,
        enumeration: [],
        selectedCurrency: 'Euro',
        measurement_unit: null,
        entry_frequency: null,
        collected_by: null,
        reported_by: null,
        evaluated_by: null,
        dataSource: null,
        verificationMeans: null,
        formatType: 'simple'
      },
      metricToSave: null,
      warningModalOpen: false,
      errorOnsubmit: false,
      errorMessageFromBackend: '',
      step: this.mode === 'edit' ? 2 : 1
    };
  },
  mounted() {
    if (this.mode === 'edit') {
      this.initialMetricName = this.customMetricInfo?.title;
      this.metric.customMetricName = this.customMetricInfo?.title;
      this.metric.description = this.customMetricInfo?.definition;
      this.metric.usageGuidance = this.customMetricInfo?.usage_guidance;
      this.metric.selectedCategories = this.customMetricInfo?.iris_categories?.map(
        (category) => category.id
      ) || [];
      this.metric.selectedSdgGoals = this.customMetricInfo?.sdg_goals?.map(
        (sdg) => sdg.sdg_goal_code
      ) || [];
      this.metric.selectedFormat = this.customMetricInfo?.format;
      this.metric.enumeration = this.customMetricInfo?.enumeration;
      this.metric.measurement_unit = this.customMetricInfo?.measurement_unit;
      if (this.customMetricInfo?.format === 'Number' && this.metric.measurement_unit?.name !== 'No Unit') {
        this.metric.formatType = 'unit';
      }
      this.metric.entry_frequency = this.customMetricInfo?.entry_frequency;

      // Set auditor info in the form, using name as id if it's not set
      // so that multiselect works correctly with free text
      this.metric.collected_by =
        this.customMetricInfo?.collected_by && !this.customMetricInfo.collected_by.id ?
          { id: this.customMetricInfo.collected_by.name, name: this.customMetricInfo.collected_by.name } :
          this.customMetricInfo?.collected_by;

      this.metric.reported_by =
        this.customMetricInfo?.reported_by && !this.customMetricInfo.reported_by.id ?
          { id: this.customMetricInfo.reported_by.name, name: this.customMetricInfo.reported_by.name } :
          this.customMetricInfo?.reported_by;

      this.metric.evaluated_by =
        this.customMetricInfo?.evaluated_by && !this.customMetricInfo.evaluated_by.id ?
          { id: this.customMetricInfo.evaluated_by.name, name: this.customMetricInfo.evaluated_by.name } :
          this.customMetricInfo?.evaluated_by;
      this.metric.dataSource = this.customMetricInfo?.data_source;
      this.metric.verificationMeans = this.customMetricInfo?.means_of_verification;
    }
  },
  methods: {
    selectFormat(format) {
      this.metric.selectedFormat = format;
      this.errorOnsubmit = false;
      this.step = 2;
    },
    createOrUpdateMetric(metric) {
      this.metricToSave = metric;
      if (this.mode === 'edit' && metric.entry_frequency?.id !== this.metric.entry_frequency?.id) {
        this.warningModalOpen = true;
      } else {
        this.saveMyCustomMetric();
      }
    },
    saveMyCustomMetric() {
      this.saveLoading = true;
      this.metric = { ...this.metric, ...this.metricToSave };
      ValidationSchema.validate(this.metric)
        .then(() => {
          this.metricToSave = null;
          const metricChanged =
            this.initialMetricName !== this.metric.customMetricName ||
            this.metric.entry_frequency?.id !== this.customMetricInfo.entry_frequency?.id ||
            this.metric.collected_by?.id !== this.customMetricInfo.collected_by?.id ||
            this.metric.reported_by?.id !== this.customMetricInfo.reported_by?.id ||
            this.metric.evaluated_by?.id !== this.customMetricInfo.evaluated_by?.id ||
            this.metric.dataSource !== this.customMetricInfo.data_source ||
            this.metric.verificationMeans !== this.customMetricInfo.means_of_verification;

          this.requestManager('ark_metric_definitions')
            .then((res) => {
              this.errorOnsubmit = false;
              this.errorMessageFromBackend = '';
              if (this.mode === 'edit' && metricChanged) {
                this.entryDataChanged('Update Metric');
              }
              res.goalId = this.customMetricInfo.goalId;
              this.closeCustomMetricModal(res);
            })
            .catch((error) =>
              error.response.json().then((body) => {
                this.errorMessageFromBackend = body.error;
              })
            )
            .finally(() => this.saveLoading = false);
        })
        .catch(() => {
          this.errorOnsubmit = true;
          this.saveLoading = false;
        });
    },
    requestManager: async function (url) {
      const metricData = {
        title: this.metric.customMetricName,
        definition: this.metric.description,
        usage_guidance: this.metric.usageGuidance,
        format: this.metric.selectedFormat,
        iris_category_ids: this.metric.selectedCategories,
        sdg_goal_ids: this.metric.selectedSdgGoals,
        enumeration: this.metric.enumeration
      };

      const associationData = {
        goal_id: this.customMetricInfo?.goalId,
        measurement_unit_id: this.metric.formatType === 'unit' ?
          this.metric.measurement_unit?.id :
          null,
        entry_frequency_id: this.metric.entry_frequency?.id,
        collected_by: this.metric.collected_by?.id,
        reported_by: this.metric.reported_by?.id,
        evaluated_by: this.metric.evaluated_by?.id,
        data_source: this.metric.dataSource,
        means_of_verification: this.metric.verificationMeans
      };

      if (this.mode === 'create') {
        return api
          .post(url, {
            json: {
              ark_metric_definition: metricData,
              ...associationData
            }
          })
          .json();
      } else {
        return api
          .patch(url + `/${this.customMetricInfo?.id || ''}`, {
            json: {
              ark_metric_definition: metricData,
              ...associationData
            }
          })
          .json();
      }
    },
    closeCustomMetricModal(customMetric) {
      this.$emit('createMetricModalClosedEvent', customMetric || {});
    },
    entryDataChanged(reason) {
      this.$emit('entryDataChanged', reason);
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped src="../../../stylesheet/forms.css"></style>

<style scoped>
#createOrEditACustomMetric {
  font-family: Poppins, sans-serif;
}

#createOrEditACustomMetric .form-control .ark {
  border-radius: 4px;
}

#createOrEditACustomMetric input {
  color: #212529;
}

#createOrEditACustomMetric input ::placeholder {
  color: #6c757d;
}

#createOrEditACustomMetric .textareaNewGoal {
  background-color: transparent;
  border-radius: 4px;
  padding: 7px 13px;
  width: 100%;
}

#createOrEditACustomMetric .textareaNewGoal ::placeholder {
  color: #6c757d;
}

avl-modal {
  --modal-box-shadow-header: 0px 0px 0px 0px;
  --modal-box-shadow-footer: 0px 0px 0px 0px;
  --modal-padding-body: 0 3% 0 3%;
  --modal-padding-header: 5px 3% 0 3%;
}

.custom-header {
  padding-right: 30px;
}

</style>
