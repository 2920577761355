import { enumerate } from '../utilities/enumeration';

const types = enumerate([
    'Operational',
    'Technology',
    'Financial',
    'Governance',
    'Reputational',
    'Compliance',
    'Security'
]);

export { types };