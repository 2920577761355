<template>
  <section class="partnersNav pb-3">
    <div class="row justify-content-center">
      <nav class="col-sm-12 col-md-10 col-lg-8 ml-4 ml-md-0">
        <a
          class="
            btn
            secondaryButton
            DesktopLinkXSmall
            primaryDefault
            boxShadowNone
            px-3
            backTag
          "
          :href="`/${modelPluralize}/${modelId}`"
        >
          <i
            class="fa fa-arrow-left"
            aria-hidden="true"
          />&nbsp;&nbsp;&nbsp; Back
        </a>
      </nav>
    </div>
    <header class="row justify-content-center">
      <section class="col-sm-12 col-md-10 col-lg-8 d-flex align-items-center ml-4 ml-md-0">
        <img
          v-show="organization.logo"
          :src="organization?.logo"
          class="logoImage"
          :alt="`${organization?.name} logo`"
        >
        <h1 class="ml-2 mb-0 DesktopTextSmall">{{ organization?.name }}</h1>
      </section>
    </header>
  </section>
  <main class="row justify-content-center">
    <article class="col-sm-12 col-md-10 col-lg-8">
      <loading-indicator
        v-if="Object.keys(organization).length === 0"
        :skeletons="[
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'},
          {width: null, height: '20px'}
        ]"
      />
      <organization-partners
        v-else
        :model-pluralize="modelPluralize"
        :model-id="modelId"
        :is-admin="isAdmin"
        :organization="organization"
        show-all
      />
    </article>
  </main>
</template>

<script>
import LoadingIndicator from '../../../../components/shared/LoadingIndicator.vue';
import OrganizationPartners from '../../organizationsAbout/components/OrganizationPartners.vue';
import { fallbackApi } from '../../../../api/config';
import isAdminBase from '../../../../utilities/authentication';

export default {
  components: { LoadingIndicator, OrganizationPartners },
    data() {
    return {
      loading: true,
      model: '',
      modelPluralize: '',
      modelId: '',
      organization: {}
    };
  },
  computed: {
    isAdmin() {
      return isAdminBase({
        user: this.$store.state.auth.user,
        entity: this.model,
        entityId: this.modelId
      });
    }
  },
  mounted() {
    this.fetchOrganizationInfo();
  },
  beforeMount() {
    this.getModel();
  },
  methods: {
    getModel() {
      const modelInfo = document.querySelector('#all-partners');
      this.model = modelInfo?.getAttribute('data-model');
      this.modelId = modelInfo?.getAttribute('data-model-id');
      this.modelPluralize = modelInfo?.getAttribute('data-model-pluralize');
    },
    fetchOrganizationInfo() {
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/about`,
        dataSetter: ({ organization }) => this.organization = organization
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
<style scoped src="../../../../stylesheet/lists.css"></style>

<style scoped>
.partnersNav {
  margin-top: -15px;
  background: white;
}

@media screen and (max-width: 991px) {
  .partnersNav {
      margin-top: 10px
  }
}

.backTag {
  color: #24422B !important;
}
</style>