<template>
  <div v-if="chartData.length" class="arkCard no-shadow">
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-start">
          <div class="DesktopTextMedium primaryDefault">Goal Distribution</div>
          <div class="ml-2 mt-1">
            <i class="fa fa-question-circle grayscalePlaceholder" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-start">
          <div class="DesktopTextSmall grayscaleLabel">Based on:</div>
          <div class="ml-2">
            <div
              class="btn btn-link p-0 m-0"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="d-flex justify-content-start">
                <div class="ml-2 DesktopTextSmall grayscaleOffBlack">
                  {{ getCurrentDropdownValue() }}
                </div>
                <i class="ml-3 mt-1 fa fa-angle-down" aria-hidden="true" />
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="dropdown-menu dropdown-primary">
                <button
                  :class="`
                    ${!categoryData.length ? 'disabled-button ' : ''}
                    dropdown-item w-100 m-0
                  `"
                  :disabled="!categoryData.length"
                  @click="setDropdownCategories"
                >
                  {{ getDropdownCategories() }}
                </button>
                <button
                  :class="`
                    ${!threatData.length ? 'disabled-button ' : ''}
                    dropdown-item w-100 m-0
                  `"
                  :disabled="!threatData.length"
                  @click="setDropdownThreats"
                >
                  {{ getDropdownThreats() }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5 col-sm-12 mt-3">
        <div class="row justify-content-center">
          <div class="col-12 text-center DesktopTextSmall grayscaleLabel">
            {{ chartTitle }}
          </div>
        </div>
        <div class="row justify-content-center">
          <div id="goal-statistics-card" />
        </div>
      </div>
      <div class="col-lg-7 col-sm-12 mt-4">
        <div class="row ark-p5">
          <div class="col-9">Goal count</div>
          <div class="col-3 text-center">Total</div>
        </div>
        <hr class="mx-1">
        <div v-for="(category, index) in paginatedData" :key="category.name">
          <div class="row">
            <div class="col-9">
              <div class="row">
                <div class="col-2">
                  <span :style="getColor(getPaginatedIndex(index))" class="dot" />
                </div>
                <div class="col-10 ark-p2">{{ category.name }}</div>
              </div>
            </div>
            <div class="col-3 text-center">{{ category.y }}</div>
          </div>
          <hr class="mx-1">
        </div>
        <div v-if="totalPages > 1" class="row">
          <div class="col-2 text-right">
            <button
              type="button"
              :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
              :disabled="isPreviousPaginationDisabled()"
              aria-label="First page"
              @click="chartPage = 0"
            >
              <i class="fa fa-angle-double-left fa-2x" aria-hidden="true" />
            </button>
          </div>
          <div class="col-2 text-left">
            <button
              type="button"
              :class="`button ${isPreviousPaginationDisabled() ? 'disabled-button' : ''}`"
              :disabled="isPreviousPaginationDisabled()"
              aria-label="Previous page"
              @click="chartPage--"
            >
              <i class="fa fa-angle-left fa-2x" aria-hidden="true" />
            </button>
          </div>
          <div class="col-4 text-center ark-p2">
            {{ `${chartPage + 1} / ${totalPages}` }}
          </div>
          <div class="col-2 text-right">
            <button
              type="button"
              :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
              :disabled="isNextPaginationDisabled()"
              aria-label="Next page"
              @click="chartPage++"
            >
              <i class="fa fa-angle-right fa-2x" aria-hidden="true" />
            </button>
          </div>
          <div class="col-2 text-left">
            <button
              type="button"
              :class="`button ${isNextPaginationDisabled() ? 'disabled-button' : ''}`"
              :disabled="isNextPaginationDisabled()"
              aria-label="Last page"
              @click="chartPage = totalPages - 1"
            >
              <i class="fa fa-angle-double-right fa-2x" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global Highcharts */

import { chartColors, pieChartTemplate } from '../../../domain/constants';
import { enumerate, symbolToString } from '../../../utilities/enumeration';
// import { DateTime } from 'luxon';
import { fallbackApi } from '../../../api/config';

const itemsPerPage = 5;
const template = {
  ...pieChartTemplate,
  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    pointFormat: `
      <span style="color:{point.color}">{point.name}</span>
      : <b>{point.percentage:.2f}%</b> of total<br/>
    `
  }
};

const domain = Object.freeze({
  pagination: enumerate(['next', 'previous']),
  dropdownSymbols: enumerate(['categories', 'threats']),
  dropdownValues: enumerate({
    categories: 'IRIS+ Impact Category',
    threats: 'IUCN Threat'
  }),
  chartTitles: enumerate({
    categories: 'Distribution by IRIS+ Impact Category',
    threats: 'Distribution by IUCN Threat'
  }),
  seriesNames: enumerate({
    categories: 'Categories',
    threats: 'Threats'
  })
});

const dataWatcher = (dropdownSymbol) => {
  return {
    handler() {
      if (this.dropdownValue === dropdownSymbol) this.renderHighchart();
    },
    deep: true,
    flush: 'post'
  };
};

export default {
  props: {
    model: {
      type: String,
      required: false,
      default: ''
    },
    modelId: {
      type: String,
      required: false,
      default: ''
    },
    date: {
      type: String,
      required: false,
      default: ''
    },
    goalsChangedIndicator: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      dropdownValue: domain.dropdownSymbols.categories,
      pieChart: undefined,
      categoryData: [],
      threatData: [],
      chartPage: 0
    };
  },
  computed: {
    chartTitle() {
      return this.getStringifiedItem(domain.chartTitles);
    },
    chartData() {
      if (!this.categoryData.length) return this.threatData;
      return this.dropdownValue === domain.dropdownSymbols.categories
        ? this.categoryData
        : this.threatData;
    },
    paginatedData() {
      return this.chartData.slice(
        itemsPerPage * this.chartPage,
        itemsPerPage * (this.chartPage + 1)
      );
    },
    totalPages() {
      return Math.ceil(this.chartData.length / itemsPerPage);
    }
  },
  watch: {
    goalsChangedIndicator() {
      this.fetchData();
    },
    categoryData: dataWatcher(domain.dropdownSymbols.categories),
    threatData: dataWatcher(domain.dropdownSymbols.threats),
    dropdownValue() {
      this.chartPage = 0;
      this.renderHighchart();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getColor: (index) => `background-color: ${chartColors[index] || '#AAA'}`,
    getStringifiedItem(from, item = this.dropdownValue) {
      return symbolToString(from[symbolToString(item)]);
    },
    getCurrentDropdownValue() {
      return this.getStringifiedItem(domain.dropdownValues);
    },
    getDropdownCategories() {
      return this.getStringifiedItem(
        domain.dropdownValues, domain.dropdownSymbols.categories
      );
    },
    getDropdownThreats() {
      return this.getStringifiedItem(
        domain.dropdownValues, domain.dropdownSymbols.threats
      );
    },
    setDropdownCategories() {
      this.dropdownValue = domain.dropdownSymbols.categories;
    },
    setDropdownThreats() {
      this.dropdownValue = domain.dropdownSymbols.threats;
    },
    getPaginatedIndex(currentIndex) {
      return this.chartPage * itemsPerPage + currentIndex;
    },
    isPreviousPaginationDisabled() {
      return !this.chartPage;
    },
    isNextPaginationDisabled() {
      return this.chartPage > this.totalPages - 2;
    },
    fetchData() {
      fallbackApi({
        url: `${this.model}s/${this.modelId}/goals_distribution`,
        dataSetter: (data) => {
          this.categoryData =
            this.createFetchedData(data.iris_categories, 'total_iris_count');
          this.threatData =
            this.createFetchedData(data.iucn_threats, 'total_iucn_threats_count');
        }
      });
    },
    createFetchedData(data, totalKey) {
      return Object.entries(data)
        .map((entry) => {
          return {
            name: entry[0],
            y: entry[1],
            percentage: data[totalKey]
              ? entry[1] / data[totalKey]
              : 0
          };
        })
        .sort((i1, i2) => i2.y - i1.y);
    },
    renderHighchart() {
      if (!this.chartData.length) {
        this.pieChart = undefined;
        return;
      }
      if (!this.pieChart) {
        this.pieChart = Highcharts.chart('goal-statistics-card', template);
      }

      this.pieChart.series[0].remove();
      this.pieChart.addSeries({
        name: this.getStringifiedItem(domain.seriesNames),
        data: this.chartData,
        innerSize: '50%'
      });

      this.pieChart.setTitle({
        text: `
          <div class="text-center DesktopLinkMedium primaryDefault">
            ${this.chartData.reduce((accumulator, { y }) => accumulator + y, 0)}
          </div>
        `
        // ${this.date ? `
        //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
        //     ${DateTime.fromISO(this.date).toFormat('LLL yyyy')}
        //   </div>
        // ` : `
        //   <div class="mt-1 text-center DesktopTextSmall grayscaleLabel">
        //     No End Date
        //   </div>
        // `}
      });

      this.pieChart.reflow();
    }
  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
<style scoped>
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.button {
  color: #777;
  background-color: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
}

.button:hover {
  color: #36533e;
  background-color: #eee;
}

.disabled-button {
  color: #bbb;
  pointer-events: none;
  cursor: default;
}
</style>
