<template>
  <form>
    <fieldset>
      <legend class="sr-only">
        {{ isSubsidiary ? 'Related entity info:' : 'Governance and finance info:' }}
      </legend>
      <div v-if="isSubsidiary" class="row">
        <div class="col-12">
          <label
            for="nameInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Name *
          </label>
          <input
            id="nameInput"
            v-model="form.name"
            type="text"
            class="form-control ark DesktopTextSmall"
            required
            aria-errormessage="nameError"
            :aria-invalid="!!errors.name"
            :class="{ notValid: errors.name }"
            @blur="validate"
          >
          <error-message
            id="nameError"
            :error-condition="!!errors.name"
            :error-message="errors.name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <label
            for="yearInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Year founded *
          </label>
          <input
            id="yearInput"
            v-model="form.year_founded"
            type="number"
            class="form-control ark DesktopTextSmall"
            required
            aria-errormessage="yearError"
            :aria-invalid="!!errors.year_founded"
            :class="{ notValid: errors.year_founded }"
            @blur="validate"
          >
          <error-message
            id="yearError"
            :error-condition="!!errors.year_founded"
            :error-message="errors.year_founded"
          />
        </div>
        <div class="col-12">
          <label
            for="countryInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Country of incorporation *
          </label>
          <Multiselect
            id="countryInput"
            v-model="form.country_of_incorporation"
            placeholder="Select one"
            :options="countryOptions"
            :close-on-select="true"
            :searchable="true"
            required
            label="value"
            :disabled="loading"
            class="mb-2"
            aria-errormessage="countryError"
            :aria-invalid="!!errors.country_of_incorporation"
            :class="
              errors.country_of_incorporation
                ? 'multiselect-ark multiselect-ark-error'
                : 'multiselect-ark'
            "
            @blur="validate"
          />
          <error-message
            id="countryError"
            :error-condition="!!errors.country_of_incorporation"
            :error-message="errors.country_of_incorporation"
          />
        </div>
        <div class="col-12 mb-3">
          <label
            for="ownershipTypeInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Ownership type
          </label>
          <Multiselect
            id="ownershipTypeInput"
            v-model="form.ownership_type"
            placeholder="Select one"
            :options="ownershipOptions"
            :close-on-select="true"
            :searchable="true"
            label="value"
            :disabled="loading"
            class="mb-2"
            aria-errormessage="ownershipTypeError"
            :aria-invalid="!!errors.ownership_type"
            :class="
              errors.ownership_type
                ? 'multiselect-ark multiselect-ark-error'
                : 'multiselect-ark'
            "
            @blur="validate"
          />
          <error-message
            id="ownershipTypeError"
            :error-condition="!!errors.ownership_type"
            :error-message="errors.ownership_type"
          />
        </div>
        <div class="col-12 mb-3">
          <label
            for="companyIdInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Name of company registration authority *
          </label>
          <input
            id="authorityInput"
            v-model="form.registration_authority"
            type="text"
            class="form-control ark DesktopTextSmall"
            placeholder="Ex: UK Charity Commission"
            required
            aria-errormessage="authorityError"
            :aria-invalid="!!errors.registration_authority"
            :class="{ notValid: errors.registration_authority }"
            @blur="validate"
          >
          <error-message
            id="authorityError"
            :error-condition="!!errors.registration_authority"
            :error-message="errors.registration_authority"
          />
        </div>
        <div class="col-12 mb-3">
          <label
            for="companyIdInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            Company ID *
          </label>
          <input
            id="companyIdInput"
            v-model="form.company_id"
            type="text"
            class="form-control ark DesktopTextSmall"
            required
            aria-errormessage="company_idError"
            :aria-invalid="!!errors.company_id"
            :class="{ notValid: errors.company_id }"
            @blur="validate"
          >
          <error-message
            id="company_idError"
            :error-condition="!!errors.company_id"
            :error-message="errors.company_id"
          />
        </div>
        <div class="col-12 mb-3">
          <label
            for="leiInput"
            class="DesktopTextSmall grayscaleOffBlack"
          >
            LEI
          </label>
          <input
            id="leiInput"
            v-model="form.lei"
            type="text"
            class="form-control ark DesktopTextSmall"
            aria-errormessage="leiError"
            :aria-invalid="!!errors.lei"
            aria-describedby="leiHint"
            :class="{ notValid: errors.lei }"
            @blur="validate"
          >
          <error-message
            id="leiError"
            :error-condition="!!errors.lei"
            :error-message="errors.lei"
          />
          <span
            id="leiHint"
            class="DesktopTextSmall grayscaleLabel d-block"
            v-if="!isSubsidiary"
          >
            Global identifier for entities participating in financial transactions.
            <a
              href="https://www.gleif.org/en/about-lei/introducing-the-legal-entity-identifier-lei"
              target="_blank"
              rel="noopener"
            >Learn more</a>
          </span>
        </div>
      </div>
    </fieldset>
    <section
      class="d-flex"
      :class="isSubsidiary && governance ? 'justify-content-between' : 'justify-content-end'"
    >
      <button
        v-if="isSubsidiary && governance"
        type="button"
        class="btn btn-link p-0 m-0 DesktopLinkXSmall deleteButton"
        @click="deleteEntity"
      >
        Delete entity
      </button>
      <div>
        <error-message
          class="maxWidthInputs"
          :error-condition="!!errorMessageFromBackend"
          :error-message="errorMessageFromBackend"
        />
        <div class="d-flex justify-content-end maxWidthInputs mb-4">
          <button
            v-if="isSubsidiary || dataChanged"
            type="button"
            class="btn DesktopLinkXSmall cancelButton"
            @click="cancel"
          >
            Cancel
          </button>
          <div
            v-if="loading"
            class="ml-2 d-flex justify-content-center"
          >
            <div
              class="
                d-flex
                align-content-center
                spinner-border
                primary2_ark
                align-self-center
              "
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
              Saving
            </p>
          </div>
          <button
            v-else
            type="submit"
            class="
              btn ml-2
              DesktopLinkXSmall
              grayscaleOffWhite
              primaryButton
            "
            :disabled="!dataChanged"
            @click.prevent="save"
          >
            Save
          </button>
        </div>
      </div>
    </section>
  </form>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../../api/config';
import { validateForm } from '../../../../utilities/validation.js';

export default {
  components: { ErrorMessage, Multiselect },
  props: {
    modelId: {
      type: Number,
      required: true
    },
    governance: {
      type: Object,
      required: false,
      default: null
    },
    countryOptions: {
      type: Array,
      required: true
    },
    ownershipOptions: {
      type: Array,
      required: true
    },
    isSubsidiary: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['save', 'delete', 'cancel'],
  data() {
    return {
      form: {},
      errors: {
        name: '',
        year_founded: '',
        country_of_incorporation: '',
        ownership_type: '',
        registration_authority: '',
        company_id: '',
        lei: ''
      },
      errorMessageFromBackend: '',
      loading: false,
      showErrors: false,
      validationSchema: yup.object({
        name: this.isSubsidiary ? yup.string().nullable().required() : yup.string().nullable(),
        year_founded: yup.number().typeError('Year founded must be a number').required()
          .min(1600).max(new Date().getFullYear()),
        country_of_incorporation: yup.string().nullable().required(),
        ownership_type: yup.string().nullable(),
        registration_authority: yup.string().required(),
        company_id: yup.string().required(),
        lei: yup.string().nullable()
      })
    };
  },
  computed: {
    dataChanged() {
      if (!this.governance) return true;
      for (const [key, value] of Object.entries(this.governance)) {
        if (this.form[key] !== value) return true;
      }

      return false;
    }
  },
  watch: {
    governance() {
      this.getGovernanceInfo();
    }
  },
  mounted() {
    this.getGovernanceInfo();
  },
  methods: {
    getGovernanceInfo() {
      if (this.governance) {
        this.form = {...this.governance};
      } else {
        this.form = {
          year_founded: null,
          country_of_incorporation: null,
          ownership_type: '',
          registration_authority: '',
          company_id: '',
          lei: ''
        };

        if (this.isSubsidiary) {
          this.form.name = '';
        }
      }
    },
    validate() {
      return validateForm(this.form, this.errors, this.validationSchema, this.showErrors);
    },
    async save() {
      this.showErrors = true;
      const valid = await this.validate();
      if (!valid) return;

      this.loading = true;
      const url = this.governance ? `governance_info/${this.governance.id}` : 'governance_info';
      const httpVerb = this.governance ? 'patch' : 'post';
      fallbackApi({
        url,
        httpVerb,
        payload: {
          json: {
            governance_info: {
              ...this.form,
              organization_id: this.modelId
            }
          }
        },
        dataSetter: (data) => {
          this.errorMessageFromBackend = '';
          this.$emit('save', data);
          this.showErrors = false;
        },
        loadingSetter: (loading) => this.loading = loading
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      });
    },
    cancel() {
      this.errorMessageFromBackend = '';
      this.showErrors = false;
      this.getGovernanceInfo();
      this.validate();
      this.$emit('cancel');
    },
    deleteEntity() {
      this.loading = true;
      fallbackApi({
        url: `governance_info/${this.governance.id}`,
        httpVerb: 'delete',
        dataSetter: () => this.$emit('delete'),
        loadingSetter: (loading) => this.loading = loading
      })
      .catch((error) => {
        this.errorMessageFromBackend = error?.error?.message ||
          'Something went wrong. Please try again';
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>

<style scoped>
.inputBorder {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.deleteButton {
  color: #24422B;
}

/* Hide spin buttons from number inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

