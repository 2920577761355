<template>
  <avl-modal
    :is-open="modalOpen"
    size="700px"
    header-title=""
    @avleventclose="closeModal"
  >
    <header>
      <h4 class="text-left DesktopLinkSmall grayscaleOffBlack mb-4">
        Update subscription preferences
      </h4>
      <p class="text-left DesktopTextSmall black mb-4">
        To modify your subscription preferences, kindly provide us with comprehensive details.
        Our team will promptly contact you to confirm the requested adjustments.
      </p>
    </header>
    <section class="text-left">
      <label for="requestBody" class="DesktopTextSmall black">Request body</label>
      <textarea
        id="requestBody"
        v-model="requestBody"
        class="form-control ark DesktopTextSmall mt-2"
        placeholder="Add details here"
        rows="10"
      />
    </section>
    <aside class="text-right mt-5">
      <button
        class="btn btn-link grayscaleBody ark-bold mr-4"
        @click="closeModal"
      >
        Cancel
      </button>
      <button
        class="btn btn-link secondary7_ark ark-bold"
        @click="submit"
      >
        Submit
      </button>
    </aside>
  </avl-modal>
</template>

<script>
import { fallbackApi } from '../../../../api/config';

export default {
  name: 'UpdateSubscriptionModal',
  props: {
    modalOpen: {
      type: Boolean,
      required: true
    },
    subscription: {
      type: Object,
      required: true
    }
  },
  emits: ['modalClosed'],
  data() {
    return {
      requestBody: ''
    };
  },
  methods: {
    closeModal() {
      this.$emit('modalClosed');
    },
    submit() {
      fallbackApi({
        url: `subscriptions/${this.subscription?.id}/update_request`,
        payload: {
          json: {
            request: this.requestBody
          }
        },
        httpVerb: 'post'
      })
      .then((response) => {
        this.$store.dispatch('addToast', {
          id: this.$store.getters.getId,
          message: response.data.message,
          variant: 'success',
          autohide: true
        });
      })
      .catch((error) => {
        this.$store.dispatch('addToast', {
          id: this.$store.getters.getId,
          message: error.error,
          variant: 'error',
          autohide: true
        });
      }).finally(() => {
        this.$emit('modalClosed');
      });
    }
  }
};
</script>

<style scoped src="../../../../stylesheet/forms.css"></style>
