<template>
  <avl-modal
    :is-open="modalOpen"
    @avleventclose="closeModal"
    size="900px"
    close-button-icon="fa fa-times"
  >
    <header slot="header" class="row custom-header">
      <div class="col-12">
        <h2 class="DesktopTextMedium textBodyColor text-left">
          {{ contributionHeader }} {{ contributionRecipientName }}
        </h2>
      </div>
    </header>
    <div class="text-left">
      {{ contributionButtonText }} as
    </div>
    <section class="d-flex">
      <img
        v-if="logoOrAvatar"
        :src="logoOrAvatar"
        class="logoImageMax d-flex"
        :class="
          !isOrganizationContribution ?
            'round objectFitCover' :
            'objectFitContain'
        "
        alt=""
      >
      <div
        v-else
        class="p-2 d-flex logoImageMax align-items-center justify-content-center"
        :class="
          !isOrganizationContribution ?
            'round objectFitCover' :
            'objectFitContain secondary7_bg_ark'
        "
        aria-hidden="true"
      >
        <i
          class="
            align-items-center
            profileIconSize
          "
          :class="
            isOrganizationContribution
              ? 'white fas fa-building'
              : 'fas fa-user-circle secondary7_ark'
          "
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 d-flex align-items-center dropdown">
        <button
          id="dropdownMenuButton"
          class="
            dropdown-toggle
            btn
            dropdown-select
            DesktopTextXSmall
            grayscaleLabel
            selectpicker
          "
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            class="fa ml-1"
            :class="isOrganizationContribution ? 'fa-users' : 'fa-user'"
            aria-hidden="true"
          />&nbsp;&nbsp;
          <span class="mr-1">
            {{
              isOrganizationContribution
                ? user.organization.name :
                  user?.full_name
            }}
          </span>
        </button>
        <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuButton">
          <button
            v-if="user?.organization && user?.org_portfolio_access"
            class="dropdown-item w-100 m-0"
            @click="asOrganization = '1'"
          >
            <i class="fa fa-users" aria-hidden="true" />&nbsp;&nbsp;
            {{ user.organization.name }}
          </button>
          <button
            class="dropdown-item w-100 m-0"
            @click="asOrganization = '0'"
          >
            <i class="fa fa-user" aria-hidden="true" />&nbsp;&nbsp;
            {{ user?.full_name }}
          </button>
        </div>
      </div>
    </section>
    <form>
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4">
          <label
            for="amountInput"
            class="DesktopTextSmall grayscaleOffBlack d-flex justify-self-start"
          >
            Amount
          </label>
          <div class="d-flex align-items-center">
            <input
              id="amountInput"
              v-model="form.amount"
              type="number"
              class="form-control ark DesktopTextSmall"
              required
              aria-errormessage="amountError"
              :aria-invalid="!!errors.amount"
              :class="{ notValid: errors.amount }"
              @blur="validate"
            >
            <span class="pl-1">
              &euro;
            </span>
          </div>
          <error-message
            id="amountError"
            class="d-flex justify-self-start"
            :error-condition="!!errors.amount"
            :error-message="errors.amount"
          />
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <label
            for="amountInput"
            class="DesktopTextSmall grayscaleOffBlack d-flex justify-self-start"
          >
            Committed amount
          </label>
          <div class="d-flex align-items-center">
            <input
              id="committedAmountInput"
              v-model="form.committedAmount"
              type="number"
              class="form-control ark DesktopTextSmall"
              required
              aria-errormessage="committedAmountError"
              :aria-invalid="!!errors.committedAmount"
              :class="{ notValid: errors.committedAmount }"
              @blur="validate"
            >
            <span class="pl-1">
              &euro;
            </span>
          </div>
          <error-message
            id="committedAmountError"
            class="d-flex justify-self-start"
            :error-condition="!!errors.committedAmount"
            :error-message="errors.committedAmount"
          />
        </div>
      </div>
    </form>
    <error-message
      class="d-block saveCancelContainer d-flex justify-content-end"
      :error-condition="!!errorMessageFromBackend"
      :error-message="errorMessageFromBackend"
    />
    <div class="saveCancelContainer d-flex justify-content-end mb-4">
      <div
        v-if="loading"
        class="ml-2 d-flex justify-content-center"
      >
        <div
          class="
            d-flex
            align-content-center
            spinner-border
            primary2_ark
            align-self-center
          "
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <p class="DesktopLinkXSmall primary2_ark mb-0 d-flex align-items-center ml-2">
          Saving
        </p>
      </div>
      <button
        v-else
        type="submit"
        class="
          btn ml-2
          DesktopLinkXSmall
          grayscaleOffWhite
          primaryButton
        "
        :disabled="!dataChanged"
        @click="submit"
      >
        {{ contributionButtonText }}
      </button>
    </div>

  </avl-modal>
</template>

<script>
import * as yup from 'yup';
import ErrorMessage from '../../../components/shared/ErrorMessage.vue';
import Multiselect from '@vueform/multiselect';
import { fallbackApi } from '../../../api/config';
import { validateForm } from '../../../utilities/validation.js';

const ValidationSchema = yup.object({
  amount: yup.number()
    .typeError('Input must be a number')
    .min(0, 'Number must be non-negative')
    .required('Number is required'),
  committedAmount: yup.number()
    .typeError('Input must be a number')
    .min(0, 'Number must be non-negative')
    .required('Number is required')
});

export default {
  components: { ErrorMessage, Multiselect },
  props: {
    modalOpen: {
      type: Boolean,
      required: true
    },
    contributionRecipientId: {
      type: Number,
      required: true
    },
    contributionRecipientType: {
      type: String,
      required: true
    },
    contributionRecipientName: {
      type: String,
      required: true
    },
    contributionButtonText: {
      type: String,
      required: false,
      default() {
        return 'Invest';
      }
    }
  },
  emits: ['close-modal-event'],
  data() {
    return {
      loading: false,
      showErrors: false,
      form: {
        amount: '',
        committedAmount: ''
      },
      errors: {
        provider: '',
        amount: '',
        committedAmount: ''
      },
      errorMessageFromBackend: '',
      asOrganization: '1'
    };
  },
  mounted() {
    this.$store.dispatch('updateUserPortfolioAccess');
  },
  computed: {
    // TODO: extract
    logoOrAvatar() {
      if (this.isOrganizationContribution && this.user?.organization?.logo) {
        return this.user.organization.logo;
      } else if (!this.isOrganizationContribution && this.user?.avatar) {
        return this.user.avatar;
      } else return false;
    },
    dataChanged() {
      return this.form.amount !== '' && this.form.committedAmount !== ''
    },
    contributionHeader() {
      const inTo = this.contributionButtonText === 'Invest' ? 'in' :
              this.contributionButtonText === 'Add to portfolio' ? '' : 'to';
      return `${this.contributionButtonText} ${inTo}`;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isOrganizationContribution() {
      return this.asOrganization === '1' && this.user?.organization && this.user?.org_portfolio_access;
    }
  },
  watch: {
    modalOpen() {
      if (!this.user?.organization || !this.user?.org_portfolio_access) {
        this.asOrganization = '0';
      }
    },
    openModal() {
      if (this.openModal) {
        this.asOrganization = '0';
        this.modalOpen = true;
      }
    }
  },
  methods: {
    validate() {
      return validateForm(this.form, this.errors, ValidationSchema, this.showErrors);
    },
    async submit() {
      this.showErrors = true;
      const valid = await this.validate();
      if (valid) {
        this.loading = true;
        fallbackApi({
          url: 'contributions',
          payload: {
            json:
              {
                contribution: {
                  amount: this.form.amount,
                  committed_amount: this.form.committedAmount,
                  as_organization: this.asOrganization,
                  as_organization_id: this.user?.organization?.id,
                  recipient_id: this.contributionRecipientId,
                  recipient_type: this.contributionRecipientType
                }
              }
          },
          httpVerb: 'post'
        })
        .then((response) => {
          this.errorMessageFromBackend = '';
          this.showErrors = false;
          this.loading = false;
          this.closeModal;
          const portfolioId = response.data?.contribution?.portfolio_id;
          if (portfolioId) {
            location.href = `/portfolios/${portfolioId}`;
          }
        })
        .catch((error) => {
          this.errorMessageFromBackend = error?.error?.message ||
            'Something went wrong. Please try again';
        })
      }
    },
    closeModal() {
      this.$emit('close-modal-event');
    },
  }
};
</script>

<style scoped src="../../../stylesheet/forms.css"></style>
<style scoped>
avl-modal {
  --modal-box-shadow-header:  0px 0px 0px 0px;
  --modal-padding-body: 0 3% 0 3%;
  --modal-padding-header: 5px 3% 0 3%;
}

.custom-header {
  margin-top: -40px;
  width: 97%;
}

.logoImage {
  width: 45px;
  height: 45px;
  border-radius: 3px;
}

.logoImageMax {
  width: 60px;
  height: 60px;
  border-radius: 3px;
}

.profileIconSize {
  font-size: 40px
}

.btn.dropdown-select {
  border: 1px solid #999999;
}

/* Hide spin buttons from number inputs */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
