<template>
  <div class="toast-stack p-3">
    <toast-notification
      v-for="toast in toasts"
      :key="toast.id"
      :message="toast.message"
      :variant="toast.variant"
      :autohide="toast.autohide"
      :delay="toast.delay"
      @dismiss="dismissToast(toast.id)"
    />
  </div>
</template>

<script>
import ToastNotification from './ToastNotification.vue';

export default {
  name: 'ToastStack',
  components: {
    ToastNotification
  },
  computed: {
    toasts() {
      return this.$store.getters.getToasts;
    }
  },
  methods: {
    dismissToast(id) {
      this.$store.dispatch('removeToast', id);
    }
  }
};
</script>

<style scoped>
.toast-stack {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
</style>
