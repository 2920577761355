class TextHelper {
  static setModifyParagraphListeners() {
    $('.modifyParagraph').click(function(){
      var parent_dom_id = $(this).attr('parent_dom_id');
      var html_content = $(this).attr('html_content');
      $(`#${parent_dom_id}`).html(html_content);
      $(this).addClass("hide");
      $(this).siblings().removeClass("hide");
      return false;
    });
  }
}

TextHelper.setModifyParagraphListeners();
