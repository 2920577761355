<template>
  <div id="mapViewport" />
</template>

<script>
/* global Sentry */

import {
  customPopupHtml,
  pointSymbolConfig,
  sitePointSymbolConfigCreator,
  sitePolygonSymbolConfigCreator,
  sitePolylineSymbolConfigCreator,
  uniqueValueInfoCreator
} from '../../map/helpers/consts';
import { fallbackApi } from '../../../api/config';
import { loadModules } from 'esri-loader';
import { sitesMixin } from '../../../mixins';

export default {
  mixins: [sitesMixin],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.loadMap();
  },
  methods: {
    // Accumulate all organization + project sites
    allSites(model) {
      let allSites = model?.sites || [];

      if (model?.projects) {
        allSites = model.projects.reduce((acc, project) => {
          return acc.concat(project.sites);
        }, allSites);
      }
      return allSites;
    },
    // Accumulate all organization + project headquarters
    allHeadquarters(model) {
      const { sites, projects, ...entity } = model;
      let allHeadquarters = [];

      if (model?.projects) {
        allHeadquarters = projects.map((project) => {
          const { sites, ...rest } = project;
          return rest;
        });
      }
      allHeadquarters.unshift(entity);
      return allHeadquarters;
    },
    customPopup(feature) {
      const popupDiv = document.createElement('div');
      popupDiv.innerHTML = customPopupHtml(feature);

      return popupDiv;
    },
    getLayerTemplate(title, color) {
      return {
        title,
        featureReduction: null,
        source: [], // adding an empty feature collection
        objectIdField: 'ObjectID',
        geometryType: 'point',
        outFields: ['*'],
        fields: [
          {
            name: 'ObjectID',
            alias: 'id',
            type: 'oid'
          },
          {
            name: 'Name',
            alias: 'name',
            type: 'string'
          },
          {
            name: 'ArkId',
            alias: 'arkId',
            type: 'string'
          },
          {
            name: 'EntityId',
            alias: 'entityId',
            type: 'string'
          },
          {
            name: 'ArkType',
            alias: 'arkType',
            type: 'string'
          },
          {
            name: 'ArkSubType',
            alias: 'arkSubType',
            type: 'string'
          },
          {
            name: 'ArkGoalsCount',
            alias: 'arkGoalsCount',
            type: 'integer'
          },
          {
            name: 'ArkProjectsCount',
            alias: 'arkProjectsCount',
            type: 'integer'
          },
          {
            name: 'ArkSpeciesCount',
            alias: 'arkSpeciesCount',
            type: 'integer'
          },
          {
            name: 'ArkImage',
            alias: 'arkImage',
            type: 'string'
          }
        ],
        popupTemplate: {
          title: '{Name}',
          outFields: ['*'],
          content: this.customPopup
        },
        renderer: {
          type: 'unique-value',
          field: 'ArkSubType',
          uniqueValueInfos: [
            uniqueValueInfoCreator(
              'WildlifeOrg',
              'https://cdn.theark.co/img/map_icon_building_dark.png',
              color
            ),
            uniqueValueInfoCreator(
              'TechOrg',
              'https://cdn.theark.co/img/map_icon_building_dark.png',
              color
            ),
            uniqueValueInfoCreator(
              'InvestorOrg',
              'https://cdn.theark.co/img/map_icon_building_dark.png',
              color
            ),
            {
              value: 'ConservationProject',
              symbol: pointSymbolConfig
            },
            uniqueValueInfoCreator(
              'ReportingProject',
              'https://cdn.theark.co/img/map_icon_file_dark.png',
              color
            ),
          ]
        },
        spatialReference: {
          wkid: 4326
        }
      };
    },
    loadMap() {
      loadModules([
        'esri/views/MapView',
        'esri/WebMap',
        'esri/layers/FeatureLayer',
        'esri/Graphic',
        'esri/geometry/Multipoint',
        'esri/geometry/support/webMercatorUtils'
      ])
        .then(([
          MapView,
          WebMap,
          FeatureLayer,
          Graphic,
          Multipoint,
          webMercatorUtils
        ]) => {
          let minZoom = 3;
          const windowWidth = window.innerWidth;
          if (windowWidth > 2049) minZoom = 4;

          const zoomOptions =
          {
            duration: 3000,
            easing: 'ease-in-out'
          };
          /**
           * Webmap can be found in our account:
           * https://the-ark.maps.arcgis.com/home/gallery.html?view=grid&sortOrder=desc&sortField=relevance&focus=maps-webmaps
           * and can be edited by selecting 'Open in Map Viewer Classic'
           */
          const arkMap = new WebMap({
            portalItem: {
              id: '2db26f96006441aaad38e93a318e0c38'
            }
          });
          const view = new MapView({
            map: arkMap,
            center: [10, 40], // Longitude, latitude
            zoom: minZoom,
            highlightOptions: {
              color: [255, 255, 255, 1],
              haloOpacity: 0.9,
              fillOpacity: 0.2
            },
            container: 'mapViewport',
            popup: {
              viewModel: {
                includeDefaultActions: false
              },
              spinnerEnabled: true,
              collapseEnabled: false,
              dockEnabled: true,
              dockOptions: {
                // Disables the dock button from the popup
                buttonEnabled: false,
                // Ignore the default sizes that trigger responsive docking
                breakpoint: true
              },
              visibleElements: {
                featureNavigation: false
              }
            }
          });

          view.constraints = { minZoom };

          /**
           * Move and add widgets on the map viewport
           */
          view.ui.move(['zoom'], 'bottom-right');

          let displayedSites = false;
          let siteLayers = [];
          let highlightedFeatures = [];
          let loadedLayers = 0;
          let headquartersLayer = null;

          const displaySites = async (data) => {
            // Sites are displayed once
            // when esri components finish loading or when sites are fetched
            // whichever happens last
            if (displayedSites) return;
            displayedSites = true;

            // Get oauth2 token for loading private layers
            let token = null;
            await fallbackApi({
              url: 'esri_oauth2_token',
              dataSetter: (data) => token = data.access_token
            });

            const geometryTypesOrder = {
              esriGeometryPolygon: 0,
              esriGeometryPolyline: 1,
              esriGeometryPoint: 2
            };

            const allSites = this.allSites(data);

            siteLayers = allSites.filter((site) => {
              return site.layer_url;
            }).sort((a, b) => {
              const orderA = geometryTypesOrder[a.geometry_type] || 0;
              const orderB = geometryTypesOrder[b.geometry_type] || 0;
              return orderA - orderB;
            }).map((site) => {
              const color = this.siteTypeColor(site.site_type_name);

              const params = {};
              if (site.geometry_type === 'esriGeometryPoint') {
                params.renderer = sitePointSymbolConfigCreator(color);
              } else if (site.geometry_type === 'esriGeometryPolygon') {
                params.renderer = sitePolygonSymbolConfigCreator(color);
              } else if (site.geometry_type === 'esriGeometryPolyline') {
                params.renderer = sitePolylineSymbolConfigCreator(color);
              }

              const layer = new FeatureLayer({
                url: site.layer_url,
                apiKey: token,
                ...params
              });

              layer.when(() => {
                loadedLayers++;
              });

              return { id: site.id, layer };
            });

            headquartersLayer = new FeatureLayer(
              this.getLayerTemplate('headquartersLayer', [215, 222, 192, 200])
            );

            headquartersLayer.when(() => {
              loadedLayers++;
            });

            const allHeadquarters = this.allHeadquarters(data);
            const headquarters = allHeadquarters
              .filter((entity) => {
                return entity.location?.lat && entity.location?.lon;
              })
              .map((entity) => {
                return new Graphic({
                  geometry: {
                    type: 'point',
                    latitude: entity.location.lat,
                    longitude: entity.location.lon
                  },
                  attributes: {
                    ArkId: entity.id,
                    EntityId: `${entity.ark_type}-${entity.id}`,
                    name: entity.name || entity.title,
                    lat: entity.location.lat,
                    lng: entity.location.lon,
                    ArkType: entity.ark_type,
                    ArkSubType: entity.type,
                    ArkGoalsCount: entity.goals_count,
                    ArkProjectsCount: entity.projects_count,
                    ArkSpeciesCount: entity.species_count,
                    ArkImage: entity.banner
                  }
                });
            });

            headquartersLayer.source.addMany(headquarters);
            siteLayers.push({ id: this.headquartersId, layer: headquartersLayer });

            arkMap.addMany(siteLayers.map((l) => l.layer));
          };

          // Sites finished fetching, display them
          this.$eventBus.$on('load-sites', (data) => {
            displaySites(data);
          });

          const clearHighlights = () => {
            highlightedFeatures.forEach((highlight) => {
              highlight.remove();
            });
            highlightedFeatures = [];
          };

          // Done loading esri components, display sites if they have finished fetching
          if (this.model?.sites) {
            displaySites(this.model);
          }

          let timer; // The ID of the timer that starts counting whenever the mouse is moved
          const mouseMovementTimeLimit = 100; // When the timer passes, we consider the cursor stable
          let popupFeatureID = null;

          const showPopup = (point) => {
            view.popup.visible = true;
            view.popup.open({
              fetchFeatures: true,
              location: point
            });
          };

          view.on('pointer-move', (event) => {
            view.hitTest(event, { include: headquartersLayer }).then((response) => {
              const { results } = response;

              clearTimeout(timer);

              timer = setTimeout(() => {
                if (results?.length) {
                  const { graphic } = results[0];
                  const { ObjectID } = graphic.attributes;
                  if (popupFeatureID !== ObjectID || !view.popup.visible) {
                    showPopup(view.toMap({ x: event.x, y: event.y }));
                    popupFeatureID = ObjectID;
                  }
                } else {
                  view.popup.visible = false;
                }
              }, mouseMovementTimeLimit);
            });
          });

          view.when(() => {
            view.watch('updating', (updating) => {
              if (!updating && loadedLayers === siteLayers.length) {
                loadedLayers = 0;

                // Zoom into sites
                // Get extent of every layer, and then coordinates of each extent center
                // Create a Multipoint based on these coordinates and zoom to it
                // Extents need to be projected to a common spatial reference
                // Those that cannot be converted are droped
                const extents = siteLayers.map((siteLayer) => {
                  const extent = siteLayer.layer.fullExtent;
                  if (!webMercatorUtils.canProject(extent, { wkid: 4326 })) {
                    return null;
                  }

                  const projectedExtent = webMercatorUtils.project(extent, { wkid: 4326 });
                  return projectedExtent;
                })
                  .filter((e) => e);

                if (extents.length > 0) {
                  // Get array of coordinates key pairs from extents
                  let coordinates = extents.map((extent) => {
                    return { lng: extent.center.longitude, lat: extent.center.latitude };
                  });
                  // Go to multipoint center
                  view.goTo(
                    {
                      geometry: new Multipoint({
                        points: coordinates.map((point) => [point.lng, point.lat])
                      }),
                      ...((coordinates.length === 1 && minZoom) && { minZoom })
                    },
                    zoomOptions
                  );
                }
              }
            });
          });

          this.$eventBus.$on('hover-site', ({ id, entityId }) => {
            const siteLayer = siteLayers.find((l) => l.id === id);
            if (!siteLayer) return;

            clearHighlights();

            siteLayer.layer.queryFeatures().then((result) => {
              view.whenLayerView(siteLayer.layer).then((layerView) => {
                if (entityId) {
                  const feature = result.features.find((feature) => {
                    return feature.attributes.EntityId === entityId;
                  });

                  highlightedFeatures.push(layerView.highlight(feature));
                } else {
                  result.features.forEach((feature) => {
                    highlightedFeatures.push(layerView.highlight(feature));
                  });
                }
              });
            });
          });

          this.$eventBus.$on('unhover-site', () => {
            clearHighlights();
          });

          this.$eventBus.$on('travel-to', ({ id, entityId }) => {
            const siteLayer = siteLayers.find((l) => l.id === id);
            if (!siteLayer) return;

            if (entityId) {
              siteLayer.layer.queryFeatures().then((result) => {
                const feature = result.features.find((feature) => {
                  return feature.attributes.EntityId === entityId;
                });

                view.goTo({ target: feature, zoom: 12 }, zoomOptions);
              });
            } else {
              const extent = siteLayer.layer.fullExtent;

              view.goTo(extent, zoomOptions);
            }
          });
        })
        .catch((err) => {
          console.log(err);

          // handle any errors
          Sentry && Sentry.captureException(err);
        });
    }
  }
};
</script>

<style scoped>
@import "https://js.arcgis.com/4.23/esri/themes/light/main.css";

:deep(.esri-attribution) {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}

:deep(.esri-attribution__link) {
  color: white !important;
}

:deep(.esri-popup__header) {
  display: none !important;
}
:deep(.esri-popup__main-container) {
  width: 350px;
  border-radius: 10px;
}
:deep(.esri-popup__content) {
  margin: 0px !important;
  border-radius: 10px;
}
:deep(.esri-popup__pointer) {
  display: none !important;
}
:deep(.esri-popup__footer) {
  order: 1;
}

#mapViewport {
  padding: 0;
  margin: 0;
  height: calc(100vh - 42px);
  width: 100%;
}

@media (max-width: 991px) {
  #mapViewport {
    height: calc(100vh - 49px);
  }
}

:deep(.popup-title) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>
