class AutoComplete {
  constructor(dataList, inputElement = null, container = null, key = null, callback = null, notFoundText = null) {
    this.dataList = dataList;

    if (inputElement) {
      this.inputElement = inputElement;
    } else {
      this._htmlElement = null;
    }

    if (container) {
      this.listContainer = container;
    } else {
      this._container = null;
    }

    if (key) {
      this.key = key;
    } else {
      this._key = null;
    }

    if (callback) {
      this.callback = callback;
    } else {
      this._callback = null;
    }

    if (notFoundText) {
      this.notFoundText = notFoundText;
    } else {
      this._notFoundText = null;
    }
  }

  set listContainer(htmlElement) {
    this._container = htmlElement;
  }

  set inputElement(htmlElement) {
    if (htmlElement.type !== 'text') {
      console.error('Autocomplete can only be defined in HTML elements of "text" type');
      return;
    }

    this._htmlElement = htmlElement;
    // this._defineAutocompleteBehaviour();
  }

  set key(key) {
    this._key = key;
  }

  set callback(callback) {
    this._callback = callback;
  }

  set notFoundText(notFoundText) {
    this._notFoundText = notFoundText;
  }

  get inputElement() {
    return this._htmlElement;
  }

  get listContainer() {
    return this._container;
  }

  get key() {
    return this._key;
  }

  get callback() {
    return this._callback;
  }

  get notFoundText(){
    return this._notFoundText;
  }

  setupBehaviour() {
    // We don't want the Browser to autocomplete based on previous user inputs.
    this._htmlElement.autocomplete = 'off';
    document.addEventListener(('click'), () => {
      this._container.innerText = '';
      this._container.style.display = 'none';
    });
    this._htmlElement.addEventListener('keyup', (event) => {
      if (event.currentTarget.value.length < 3) {

        // Clear and hide the container if there are less than 3 typed characters
        this._container.innerText = '';
        this._container.style.display = 'none';
        return;
      }

      // Function that search a string within an string
      const filterElements = (elementToFilter) => {
        const element = new String(this.key !== null ? elementToFilter[this.key] : elementToFilter);

        return element.toLowerCase().includes(event.currentTarget.value.toLowerCase());
      };

      // Use the function that searches for string within a string to use the result
      // as the matched values for autocomplete.
      let matchedElements = this.dataList.filter(filterElements);
      this._setupPosition();
      this._showMatchedElements(matchedElements);
      this._clickBehaviour(matchedElements);
    });
  }

  _setupPosition() {
    this._htmlElement.classList.add('js-parent');
    this._container.classList.add('js-child');
  }

  _showMatchedElements(elements) {
    const uList = document.createElement('ul');

    if (elements.length < 1 && this.notFoundText) {
      const notFoundElement = document.createElement('li');
      notFoundElement.innerText = this.notFoundText;
      notFoundElement.classList.add('js-element');
      notFoundElement.classList.add('js-not-found');
      uList.appendChild(notFoundElement);
    }

    // Elements is an array of objects that can be stringified and shown
    // via innerText.
    elements.forEach((element) => {
      const elementList = document.createElement('li');
      elementList.innerText = this.key ? element[this.key] : element;
      elementList.classList.add('js-element');

      uList.appendChild(elementList);
    });

    // Remove previous test and add the new list of matched elements
    this._container.innerText = '';
    this._container.appendChild(uList);
  }

  _clickBehaviour(matchedElements) {
    const listItem = document.querySelectorAll('.js-element:not(.js-not-found)');
    this._container.style.display = 'block';

    // Add click event listener to every item in the list
    listItem.forEach((item, index) => {
      item.addEventListener(('click'), () => {
        const element = matchedElements[index];
        this._container.innerText = '';
        if(this.key === null) {
          this._htmlElement.value = element;
        }
        else {
          const parent = this._htmlElement.parentNode;
          Object.entries(element).forEach(([key, value]) => {
            parent.querySelector(`input[name="${key}"]`).value = value;
          });
        }

        if(this.callback) {
          this.callback();
        }
      });
    });
  }
}

export default AutoComplete;


const stakeholderField = document.querySelector('.js-org');
if (stakeholderField) {
  stakeholderField.addEventListener("click", function () {
    const field = document.getElementsByClassName('js-auto')[0].value;
    if (field === 'Conservationist') {
      const organizationsString = document.querySelector('.js-org').dataset.list
      const organizationsArray = JSON.parse("[" + organizationsString + "]");
      const autoCompleteObj = new AutoComplete(organizationsArray[0]);
      autoCompleteObj.inputElement = document.querySelector('.js-input');
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div');
      autoCompleteObj.setupBehaviour();
    }
  });
}

const tagsCard = document.querySelector('.js-tags');
if (tagsCard) {
  const tagsString = tagsCard.dataset.list;
  const tagsArray = JSON.parse("[" + tagsString + "]");
  const addExpertiseInput = document.getElementById('addExpertiseInput');
  if (addExpertiseInput) {
    addExpertiseInput.addEventListener("click", function () {
      var autoCompleteObj = new AutoComplete(tagsArray[0]);
      autoCompleteObj.inputElement = document.querySelector('.js-input-expertise');
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div-expertise');
      autoCompleteObj.setupBehaviour();
    });
  }

  const addInterestInput = document.getElementById('addInterestInput');
  if (addInterestInput) {
    addInterestInput.addEventListener("click", function () {
      var autoCompleteObj = new AutoComplete(tagsArray[0]);
      autoCompleteObj.inputElement = document.querySelector('.js-input-interest');
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div-interest');
      autoCompleteObj.setupBehaviour();
    });
  }
}

const usersCard = document.querySelector('.js-users');
if (usersCard) {
  const usersString = usersCard.dataset.list;
  const usersArray = JSON.parse("[" + usersString + "]");
  const addEmployeeInput = document.getElementById('addEmployeeInput');
  const employeeInput = document.querySelector('.js-input-employee');
  const addEmployeeButton = document.getElementById('form__add_org_employee');
  if (addEmployeeInput && employeeInput && addEmployeeButton) {
    addEmployeeInput.addEventListener("click", function () {
      const enableAddEmployee = () => {
        addEmployeeButton.disabled = false;
      }

      var autoCompleteObj = new AutoComplete(usersArray[0]);
      autoCompleteObj.inputElement = employeeInput;
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div-employee');
      autoCompleteObj.key = 'name';
      autoCompleteObj.callback = enableAddEmployee;
      autoCompleteObj.notFoundText = 'No available user found';
      autoCompleteObj.setupBehaviour();
    });

    employeeInput.addEventListener("change", function () {
      employeeInput.parentNode.querySelector('input[name=id]').value = '';
      employeeInput.parentNode.querySelector('input[name=link]').value = '';
      addEmployeeButton.disabled = true;
    });
  }

  const addProjectMemberInput = document.getElementById('addProjectMemberInput');
  const projectMemberInput = document.querySelector('.js-input-project-member');
  const addProjectMemberButton = document.getElementById('form__add_project_member');
  if (addProjectMemberInput && projectMemberInput && addProjectMemberButton) {
    addProjectMemberInput.addEventListener("click", function () {
      const enableAddProjectMember = () => {
        addProjectMemberButton.disabled = false;
      }

      var autoCompleteObj = new AutoComplete(usersArray[0]);
      autoCompleteObj.inputElement = projectMemberInput;
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div-project-member');
      autoCompleteObj.key = 'name';
      autoCompleteObj.callback = enableAddProjectMember;
      autoCompleteObj.notFoundText = 'No available user found';
      autoCompleteObj.setupBehaviour();
    });

    projectMemberInput.addEventListener("change", function () {
      projectMemberInput.parentNode.querySelector('input[name=id]').value = '';
      projectMemberInput.parentNode.querySelector('input[name=link]').value = '';
      addProjectMemberButton.disabled = true;
    });
  }
}

const organizations = document.querySelector('.js-organizations');
const tech_orgs = document.querySelector('.js-tech-orgs');
if (organizations && tech_orgs) {
  const organizationsString = organizations.dataset.list;
  const techOrgsString = tech_orgs.dataset.list;
  const mergedString = organizationsString.slice(0, -1) + ',' + techOrgsString.slice(1);
  const organizationsArray = JSON.parse("[" + mergedString + "]");
  const organizationInput = document.getElementById('organizationInput');
  const inputElement = document.querySelector('.js-input-organization');
  const organizationText = document.getElementById('organizationText');
  if (organizationInput && inputElement && organizationText) {
    organizationInput.addEventListener("click", function () {
      const showText = () => {
        organizationText.classList.remove('d-none');
      }

      var autoCompleteObj = new AutoComplete(organizationsArray[0]);
      autoCompleteObj.inputElement = inputElement;
      autoCompleteObj.listContainer = document.querySelector('.js-hidden-div-organization');
      autoCompleteObj.key = 'user[company]';
      autoCompleteObj.callback = showText;
      autoCompleteObj.setupBehaviour();
    });

    inputElement.addEventListener("change", function () {
      inputElement.parentNode.querySelector('input[name="user[organization_id]"]').value = '';
      inputElement.parentNode.querySelector('input[name="user[tech_org_id]"]').value = '';
      organizationText.classList.add('d-none');
    });
  }
}
