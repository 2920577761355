<template>
  <div class="arkCard mb-0">
    <header class="row justify-content-center mt-3">
      <div class="col-6">
        <h2 class="ml-2 mb-0 DesktopLinkXSmall grayscaleBody">
          MY ASSETS
        </h2>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <a
          :href="`/${modelPluralize}/${modelId}/contributions${viewId ? '?view_id=' + viewId : ''}`"
        >
          View my transactions
        </a>
      </div>
    </header>
    <main class="row justify-content-center">
      <div class="table-responsive col-12">
        <table class="table table-no-dividers">
          <thead>
            <tr class="grayscaleBody">
              <th scope="col">
                <span class="DesktopLinkXSmall">Company</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Asset</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Country</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Legal status</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Managed area</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Deployed amount</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Committed amount</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Portfolio weighting</span>
              </th>
              <th scope="col">
                <span class="DesktopLinkXSmall">Contribution</span>
              </th>
            </tr>
          </thead>
          <tbody class="grayscaleOffBlack">
            <tr
              v-for="grouped_contribution in contributions.contributions"
              :key="grouped_contribution.id"
            >
              <td>
                <a :href="grouped_contribution.recipient_parent_link ? `/${grouped_contribution.recipient_parent_link}` : ''"
                   target="_self"
                   :class="{ 'no-link': !grouped_contribution.recipient_parent_link }">
                  {{ grouped_contribution.recipient_parent_name }}
                </a>
              </td>
              <td>
                <a
                  :href="`/${grouped_contribution.recipient_link}/impact`"
                  target="_self"
                >
                  {{ grouped_contribution.name }}
                </a>
              </td>
              <td>{{ grouped_contribution.country }}</td>
              <td>{{ grouped_contribution.legal }}</td>
              <td>
                {{ formatNumber(grouped_contribution.area) }}<span class="pl-1">km<sup>2</sup></span>
              </td>
              <td>&euro;{{ formatNumber(grouped_contribution.amount) }}</td>
              <td>&euro;{{ formatNumber(grouped_contribution.committed_amount) }}</td>
              <td>{{ grouped_contribution.weight }} %</td>
              <td>
                <section
                  v-if="editInvestment === grouped_contribution?.id"
                  class="d-flex align-items-center justify-content-end w-147px"
                >
                  <span class="pr-1">%</span>
                  <input
                    :id="grouped_contribution?.investment?.id"
                    v-model="percentageValue"
                    :name="'percentage-id-' + grouped_contribution?.investment?.id"
                    class="form-control ark DesktopTextSmall"
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                  >
                  <button
                    class="btn edit-investment-button grayscaleBody"
                    aria-label="Save new percentage"
                    @click="updateInvestment(grouped_contribution?.investment?.id)"
                  >
                    <i class="fa fa-check edit-investment-icon" aria-hidden="true" />
                  </button>
                </section>
                <section v-else class="d-flex align-items-center justify-content-end w-147px">
                  <span class="pr-1"> {{ grouped_contribution?.investment?.percentage }}% </span>
                  <ProgressBar :progress="Number(grouped_contribution?.investment?.percentage)" />
                  <button
                    class="btn edit-investment-button grayscaleBody"
                    aria-label="Edit investment's percentage"
                    @click="updatePercentage(
                      grouped_contribution?.id,
                      grouped_contribution?.investment?.percentage
                    )"
                  >
                    <i class="fa fa-pencil edit-investment-icon" aria-hidden="true" />
                  </button>
                </section>
              </td>
            </tr>
            <tr class="border-top">
              <td>{{ contributions?.total?.name }}</td>
              <td />
              <td />
              <td />
              <td>
                {{ formatNumber(contributions?.total?.area) }}<span class="pl-1">km<sup>2</sup></span>
              </td>
              <td>&euro;{{ formatNumber(contributions?.total?.amount) }}</td>
              <td>&euro;{{ formatNumber(contributions?.total?.committed_amount) }}</td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import ProgressBar from '../../../components/shared/ProgressBar.vue';
import { fallbackApi } from '../../../api/config';

export default {
  components: { ProgressBar },
  props: {
    modelPluralize: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: true
    },
    viewId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      contributions: [],
      editInvestment: null,
      percentageValue: null,
      errorPercentage: null
    };
  },
  watch: {
    editInvestment: {
      handler() {
        if (!this.editInvestment) {
          this.percentageValue = null;
          this.errorPercentage = null;
        }
      }
    },
    viewId: {
      handler() {
        this.fetchData();
      }
    },
    percentageValue: {
      handler(newValue) {
        let value = parseFloat(newValue);
        if (value < 0 ) {
          this.percentageValue = 0;
        } else if (value > 100) {
          this.percentageValue = 100;
        } else {
          this.percentageValue = value;
        }
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    updatePercentage(id, value) {
      this.percentageValue = parseFloat(value);
      this.editInvestment = id;
    },
    fetchData() {
      const url = this.viewId ? `?view_id=${this.viewId}` : '';
      fallbackApi({
        url: `${this.modelPluralize}/${this.modelId}/grouped_contributions${url}`,
        dataSetter: ({ contributions }) => {
          this.contributions = contributions;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    updateInvestment(id) {
      if (0 > this.percentageValue || this.percentageValue > 100) return;
      fallbackApi({
        url: `investments/${id}`,
        httpVerb: 'patch',
        payload: { json: { percentage: this.percentageValue } },
        dataSetter: (data) => {
          const contribution = this.contributions.contributions.find(
            (contribution) => contribution.investment.id === id
          );
          contribution.investment = data.investment;

          this.percentageValue = null;
          this.editInvestment = null;
          this.errorPercentage = null;
        },
        loadingSetter: (loading) => this.loading = loading
      });
    },
    formatNumber(value) {
      return Number(parseFloat(value).toFixed(2)).toLocaleString();
    }
  }
};
</script>

<style scoped>
.no-link {
  color: inherit !important;
  cursor: default !important;
}

.w-147px {
  width: 147px;
}

.table-no-dividers thead th,
.table-no-dividers tbody td {
  border-bottom: none;
  border-top: none;
}

.edit-investment-icon {
  font-size: 13px !important;
  color: #616374 !important;
}

.edit-investment-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0px;
  margin: 0px 5px;
}

.edit-investment-button:hover, .edit-investment-button:focus {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
</style>
